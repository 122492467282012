import React, { useState } from "react";
import {
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import ProviderStatus from "./ProviderStatus";
import ProviderCategory from "./ProviderCategory";
import { generalSetting } from "../../constants/moduleNames";
import { useRBAC } from "../../services/customHook";
import LeadDealSettings from "./LeadDealSettings";

const tabsType = ["statuses", "categories", "lead-deal-general-setting"];
const GeneralSettings = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(() => {
    let type = new URLSearchParams(location.search).get("type");
    if (type) {
      return tabsType.indexOf(type) < 0 ? 0 : tabsType.indexOf(type);
    } else return 0;
  });
  const onTabChange = (e) => {
    setSelectedTab(e);
    navigate(`/settings/general?type=${tabsType[e]}`);
  };

  const { hasActionPermission } = useRBAC();
  let moduleName = generalSetting;
  let permissions = {
    add: hasActionPermission(moduleName, "add"),
    edit: hasActionPermission(moduleName, "edit"),
    view: hasActionPermission(moduleName, "view"),
    delete: hasActionPermission(moduleName, "delete"),
  };

  return (
    <div className="general-settings">
      <Tabs
        index={selectedTab}
        id={selectedTab}
        position="relative"
        variant="unstyled"
        onChange={onTabChange}
      >
        <TabList
          boxShadow="0px 2px 4px 0px #E5E7EB"
          borderBottom={"1px solid #E7FAFD"}
          gap={"24px"}
          px={"24px"}
          bgColor={"var(--chakra-colors-white)"}
          pos={"relative"}
          zIndex={99}
        >
          <Tab
            py={"18px"}
            px={0}
            fontSize={"14px"}
            fontWeight={500}
            color={selectedTab === 0 ? "#0C94AC" : "#7E8792"}
            isSelected={selectedTab === 0}
            panelId="pStatus"
          >
            Provider Statuses
          </Tab>
          <Tab
            py={"18px"}
            px={0}
            fontSize={"14px"}
            fontWeight={500}
            color={selectedTab === 1 ? "#0C94AC" : "#7E8792"}
            isSelected={selectedTab === 1}
            panelId="pCategories"
          >
            Provider Types
          </Tab>
          <Tab
            py={"18px"}
            px={0}
            fontSize={"14px"}
            fontWeight={500}
            color={selectedTab === 2 ? "#0C94AC" : "#7E8792"}
            isSelected={selectedTab === 2}
            panelId="pCategories"
          >
            Leads/Deals Settings
          </Tab>
        </TabList>
        <TabIndicator
          height="3px"
          bg="#0C94AC"
          top={"55px"}
          borderRadius="2px 2px 0px 0px"
          zIndex={199}
        />
        <TabPanels pl="24px" mt="24px" pr="24px">
          <TabPanel p="0" className="provider-status">
            {selectedTab === 0 && <ProviderStatus permissions={permissions} />}
          </TabPanel>
          <TabPanel p="0" className="provider-categories">
            {selectedTab === 1 && (
              <ProviderCategory permissions={permissions} />
            )}
          </TabPanel>
          <TabPanel p="0" className="lead-deal-setting">
            {selectedTab === 2 && (
              <LeadDealSettings permissions={permissions} />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default GeneralSettings;
