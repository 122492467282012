// SoftwareSpecialisationSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  addSoftwareSpecialisation,
  updateSoftwareSpecialisation,
  getSoftwareSpecialisation,
  addConfiguration,
  updateConfiguration,
  getConfigurationList,
  deleteConfiguration,
  requestToAddSoftware,
  getAllSoftwareList,
} from "./softwareSpecialisationActions";

const initialState = {
  loading: false,
  error: null,
  success: null,
  softwareSpecialisation: null,
  configurationList: [],
  softwareSpecializationList: null,
};

const softwareSpecialisationSlice = createSlice({
  name: "softwareSpecialisation",
  initialState,
  reducers: {
    addConfigurationToList: (state, action) => {
      state.configurationList = state.configurationList.concat(action.payload);
    },
    removeConfigurationFromList: (state, action) => {
      let bundleId = action.payload;
      state.configurationList = state.configurationList.filter(
        (bundle) => bundle.id !== bundleId
      );
    },
    updateConfigurationFromList: (state, action) => {
      let bundleId = action.payload.id;
      state.configurationList = state.configurationList.map((bundle) => {
        return bundle.id === bundleId
          ? { ...bundle, ...action.payload }
          : bundle;
      });
    },
    addSoftwareReducer: (state, action) => {
      let category = action.payload.category;
      let softwareSpecialisation = { ...state.softwareSpecialisation };
      softwareSpecialisation[category] = action.payload.data.softwareList;

      state.softwareSpecialisation = softwareSpecialisation;
    },
    updateSoftwareReducer: (state, action) => {
      let category = action.payload.category;
      let softwareSpecialisation = { ...state.softwareSpecialisation };

      if (action.payload?.data?.softwareList) {
        softwareSpecialisation[category] = action.payload.data.softwareList;
      } else {
        if (softwareSpecialisation[category]) {
          delete softwareSpecialisation[category];
        }
      }
      state.softwareSpecialisation = softwareSpecialisation;
    },
    updateSoftwareSpecialisationList: (state, action) => {
      state.softwareSpecializationList = action.payload;
    },
    clearMessage: (state) => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: {
    // add software specialisation
    [addSoftwareSpecialisation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addSoftwareSpecialisation.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [addSoftwareSpecialisation.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // get software specialisation
    [getSoftwareSpecialisation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getSoftwareSpecialisation.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
      if (payload.data && payload.success) {
        state.softwareSpecialisation =
          payload.data?.softwareSpecialization?.softwareSpecializationList ||
          null;
      }
    },
    [getSoftwareSpecialisation.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload?.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // update software specialisation
    [updateSoftwareSpecialisation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateSoftwareSpecialisation.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [updateSoftwareSpecialisation.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    [addConfiguration.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addConfiguration.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [addConfiguration.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    [updateConfiguration.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateConfiguration.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [updateConfiguration.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    [getConfigurationList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getConfigurationList.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
      if (payload.data && payload.success) {
        state.configurationList = payload.data?.softwareBundleList || [];
      }
    },
    [getConfigurationList.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    [deleteConfiguration.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteConfiguration.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
    },
    [deleteConfiguration.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // Request To Add Software
    [requestToAddSoftware.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [requestToAddSoftware.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [requestToAddSoftware.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },

    // get software specialisation
    [getAllSoftwareList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllSoftwareList.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
      if (payload.data && payload.success) {
        state.softwareSpecializationList = payload.data?.softwareList || null;
      }
    },
    [getAllSoftwareList.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
  },
});

export const {
  clearMessage,
  addConfigurationToList,
  removeConfigurationFromList,
  updateConfigurationFromList,
  addSoftwareReducer,
  updateSoftwareReducer,
  updateSoftwareSpecialisationList,
} = softwareSpecialisationSlice.actions;

export default softwareSpecialisationSlice.reducer;
