import { axiosInstance } from "../../services/axiosInstance";
import { handleError } from "../../services/utility";

export const getDashboardsCharts = async (type) => {
  try {
    const { data } = await axiosInstance.get("dashboard");
    if (
      data?.data?.dashboard &&
      data?.data?.dashboard?.find((dash) => dash.dashboardType === type)
    ) {
      return {
        data: data?.data?.dashboard?.find(
          (dash) => dash.dashboardType === type
        ),
      };
    }
    return { data: null };
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const updateDashboardCharts = async (requestData) => {
  try {
    const data = await axiosInstance.post("dashboard/update", requestData);
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
    return { data: null };
  }
};

export const getLeadsData = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/performance/leads",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getLeadTypeData = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/performance/leadType",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getDealTypeData = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/performance/dealType",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getSalesPipelineData = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/performance/salesPipeline",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getLeadByStatusData = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/performance/leadByStatus",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getDealByStatusData = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/performance/dealByStatus",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getRevenueData = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "/sp/dashboard/finance/revenue",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getTotalRevenueData = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "/sp/dashboard/insight/totalRevenue",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getLeadToWinData = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "/sp/dashboard/finance/leadToWin",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getTotalCustomers = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "/sp/dashboard/insight/totalCustomers",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getPerformanceLeaderBoardData = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "/sp/dashboard/performance/leaderBoardData",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getPerformanceLeadConversionRate = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "/sp/dashboard/performance/leadConversionRate",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getPerformanceDealWinData = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "/sp/dashboard/performance/dealsWonRate",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getPerformanceDealLostData = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "/sp/dashboard/performance/dealsLostRate",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getPerformanceLeadTrendDynamics = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/performance/leadTrends",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getPerformanceDealTrendDynamics = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/performance/dealTrends",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getLeadRegionData = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "/sp/dashboard/performance/leadsByRegion",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getDealRegionData = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "/sp/dashboard/performance/dealsByRegion",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getTopSellingProducts = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "/sp/dashboard/performance/topSellingProducts",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

// export const getAllManagersUsersGroupsTiers = createAsyncThunk(
//   "get/getAllManagersUsersGroupsTiers",
//   async ({ rejectWithValue }) => {
//     try {
//       const PartnerCategoryList = await getGeneralSettings("spCategory");
//       const PartnerTierList = await getGeneralSettings("tier");
//       const PartnerGroupList = await getGeneralSettings("group");
//       const users = await getAllUsers();
//       return { PartnerCategoryList, PartnerTierList, PartnerGroupList, users };
//     } catch (error) {
//       return rejectWithValue(getErrorMessage(error));
//     }
//   }
// );

export const performanceBaseTarget = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/insight/targetChart",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const performanceBaseRevenue = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/insight/revenue",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const performanceBaseTier = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/insight/tier",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const performanceBaseQuota = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/insight/quota",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};
