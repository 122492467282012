import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../services/axiosInstance";
import { getErrorMessage } from "../../../services/utility";

export const addVideos = createAsyncThunk(
  "add/videos",
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("/video/add", requestData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateVideos = createAsyncThunk(
  "update/videos",
  async ({ videoId, requestParams }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(
        `/video/${videoId}`,
        requestParams
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getPartnerVideoList = createAsyncThunk(
  "get/partner/videos",
  async (partnerTenantId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("/video", {
        params: {
          limit: 100,
          partnerTenantId,
        },
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateToStarVideo = createAsyncThunk(
  "update/videos",
  async ({ videoId, spTenantId }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(`/video/star/${videoId}`, {
        spTenantId,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
