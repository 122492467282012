export const referralStatusList = [
  "Unclaimed",
  "Claimed",
  "Contacted",
  "Inprocess",
  "Won",
  "Lost",
  "Unqualified",
  "Archived",
];

export const referraltatus = {
  // Unclaimed: ["Unclaimed", "Archived"],
  Claimed: ["Claimed", "Contacted", "Inprocess", "Won", "Lost", "Unqualified"],
  Contacted: ["Contacted", "Claimed", "Inprocess", "Unqualified"],
  Inprocess: ["Inprocess", "Contacted", "Won", "Lost", "Unqualified"],
  Won: ["Won", "Inprocess", "Lost", "Archived"],
  Lost: ["Lost", "Inprocess", "Won", "Archived"],
  Unqualified: ["Unqualified", "Archived"],
};

export const defaultReferralSource = "Coachbar";

export const employeeSizeOptions = [
  { value: "Self-employed", label: "Self-employed" },
  { value: "1-10 employees", label: "1-10 employees" },
  { value: "11-50 employees", label: "11-50 employees" },
  { value: "51-200 employees", label: "51-200 employees" },
  { value: "201-500 employees", label: "201-500 employees" },
  { value: "501-1000 employees", label: "501-1000 employees" },
  { value: "More than 1000 employees", label: "More than 1000 employees" },
];
export const durationOptions = [
  { value: "Less than 2 weeks", label: "Less than 2 weeks" },
  { value: "2 - 4 weeks", label: "2 - 4 weeks" },
  { value: "4 - 6 weeks", label: "4 - 6 weeks" },
  { value: "6 - 9 weeks", label: "6 - 9 weeks" },
  { value: "9 - 12 weeks", label: "9 - 12 weeks" },
  { value: "12 - 16 weeks", label: "12 - 16 weeks" },
  { value: "More than 16 weeks", label: "More than 16 weeks" },
];
export const deploymentOptions = [
  { value: "SaaS", label: "SaaS" },
  { value: "Desktop", label: "Desktop" },
  { value: "Mobile", label: "Mobile" },
  { value: "On-Premises", label: "On-Premises" },
  { value: "Hybrid", label: "Hybrid (combination of on-premises and cloud)" },
];
export const waitingTimeOptions = [
  { value: "Less than a week", label: "Less than a week" },
  { value: "1 - 2 weeks", label: "1 - 2 weeks" },
  { value: "2 - 4 weeks", label: "2 - 4 weeks" },
  { value: "4 - 6 weeks", label: "4 - 6 weeks" },
  { value: "6 - 9 weeks", label: "6 - 9 weeks" },
  { value: "9 - 12 weeks", label: "9 - 12 weeks" },
  { value: "More than 12 weeks", label: "More than 12 weeks" },
];
