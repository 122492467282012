import { CloseIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Button } from "@coachbar/shared-components";
import CustomButton from "@coachbar/shared-components/src/components/Button/Button";
import CustomDrawer from "@coachbar/shared-components/src/components/Drawer/Drawer";
import MultiCheckboxSelector from "@coachbar/shared-components/src/components/MultiCheckboxSelector/MultiCheckboxSelector";
import { scrollbarCSS } from "@coachbar/shared-components/src/constants/constantValues";
import {
  filterLabels,
  filtersKeys,
  partnerFilters,
} from "@coachbar/shared-components/src/constants/dashboard";
import React, { useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";

const AccordionItemContent = ({
  name,
  title,
  Options,
  isRadio = false,
  selectedFilter,
  allowSearch = false,
  moduleForId = "",
}) => {
  const { onOpen: isFilterSelectionOpen } = useDisclosure();
  return (
    <AccordionItem className="filter">
      <AccordionButton>
        <Box
          as="span"
          flex="1"
          textAlign="left"
          fontSize={"14px"}
          fontWeight={"600"}
        >
          {title}
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        {!isRadio ? (
          <Scrollbars autoHeight autoHeightMax={"250px"} autoHide>
            <MultiCheckboxSelector
              name={name}
              handleCheckBoxChange={(_, opt, p) => selectedFilter(p, opt)}
              showAllSelect={true}
              onSelectAll={(opt, p) => selectedFilter(p, opt)}
              customHeight={"180px"}
              itemList={Options}
              isPopoverOpen={isFilterSelectionOpen}
              allowSearch={allowSearch}
              moduleForId={moduleForId}
            />
          </Scrollbars>
        ) : (
          <></>
          //   <Box m={"8px 0"}>
          //     <Scrollbars autoHeight autoHeightMax={"85px"} autoHide>
          //       <CustomRadioGroup
          //         title={name}
          //         childList={Options}
          //         direction="column"
          //         size="sm"
          //         onChange={(value) => {
          //           let updatedValues = Options.map((x) => ({
          //             ...x,
          //             isChecked: x.value === value,
          //           }));
          //           selectedFilter(name, updatedValues);
          //         }}
          //         value={
          //           Options?.find((x) => x.isChecked)
          //             ? Options?.find((x) => x.isChecked).value
          //             : ""
          //         }
          //       />
          //     </Scrollbars>
          //   </Box>
        )}
      </AccordionPanel>
    </AccordionItem>
  );
};

const FiltersDrawer = ({
  isOpen,
  onModalClose,
  setFilterValues,
  filters,
  platformJson,
  moduleForId,
  ...props
}) => {
  const [currentFilters, setCurrentFilters] = useState({ ...filters });

  const handleSelectedFilter = (name, value) => {
    let newFilters = JSON.parse(JSON.stringify(currentFilters));
    newFilters[name] = JSON.parse(JSON.stringify([...value]));
    setCurrentFilters(newFilters);
  };

  const handleSaveData = () => {
    setFilterValues(currentFilters);
    onModalClose();
  };

  const clearFilterValues = () => {
    let newFilters = JSON.parse(JSON.stringify(currentFilters));
    Object.keys(newFilters).forEach((x) => {
      newFilters[x] = newFilters[x].map((x) => ({ ...x, isChecked: false }));
    });
    setCurrentFilters(newFilters);
  };

  const openSelectedFilters = React.useMemo(() => {
    const selectedAccordion = Object.values(currentFilters)
      ?.filter((arr) => arr.length > 0)
      ?.flatMap((arr, index) =>
        arr.filter((obj) => obj.isChecked).map(() => index)
      );
    return selectedAccordion?.length ? selectedAccordion : [0];
  }, [currentFilters]);

  return (
    <CustomDrawer
      size="xs"
      isOpen={isOpen}
      onClose={onModalClose}
      displayFooter={false}
      saveButtonTitle="Submit"
      discardTitle="Cancel"
      bodyPadding="0"
      title={
        <Flex w="100%" justifyContent={"space-between"} alignItems={"center"}>
          <Text fontSize={"16px"} fontWeight={"600px"}>
            Filters
          </Text>
          <Box p={"6px 12px"} cursor={"pointer"} onClick={onModalClose}>
            <CloseIcon w="12px" display={"inline-block"} color={"#5E6977"} />
          </Box>
        </Flex>
      }
    >
      <Box
        style={{ maxHeight: `calc(100vh - 130px)` }}
        overflow={"auto"}
        css={scrollbarCSS}
      >
        <Flex justifyContent={"flex-end"} py={1} px={6}>
          <Button
            border={"none"}
            bg={"none"}
            color={"#0C94AC"}
            variant="newThemePrimary"
            title={"Clear all"}
            onClick={clearFilterValues}
            padding="0"
            id="more-filter-clear-all"
          />
        </Flex>
        <Divider />
        <Accordion
          py={2}
          px={6}
          allowMultiple={true}
          defaultIndex={openSelectedFilters}
        >
          {Object.keys(currentFilters).map((x) => {
            let showFilter = true;
            if (props.partnerId && partnerFilters.includes(x))
              showFilter = false;
            if (showFilter && currentFilters[x].length > 0) {
              let label =
                x === filtersKeys.partners
                  ? platformJson?.partnerTitle
                  : filterLabels[x];
              return (
                <AccordionItemContent
                  name={label}
                  title={label}
                  Options={currentFilters[x]}
                  selectedFilter={(_, values) => {
                    handleSelectedFilter(x, values);
                  }}
                  moduleForId={`filter-drawer-${moduleForId}-${
                    typeof label === "string"
                      ? label?.split(" ").join("-").toLowerCase()
                      : ""
                  }`}
                />
              );
            }
          })}
        </Accordion>
      </Box>
      <Divider />
      <Box position={"absolute"} bottom={"10px"} width="100%" px={6}>
        <CustomButton
          bg="#0C94AC"
          border="none"
          rounded="4px"
          variant="newThemePrimary"
          title="Apply Filter"
          width="full"
          onClick={handleSaveData}
          id={`${moduleForId}-apply-more-filters`}
        />
      </Box>
    </CustomDrawer>
  );
};

export default FiltersDrawer;
