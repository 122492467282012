import { Box, Flex, Grid, GridItem, SimpleGrid, Text } from "@chakra-ui/react";
import ValuesChangeCard from "@coachbar/shared-components/src/components/Charts/ValuesChangeCard";
import ChartContainer from "@coachbar/shared-components/src/screens/dashboard/ChartContainer";
import ChartHeader from "@coachbar/shared-components/src/screens/dashboard/ChartHeader";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getLitmusData } from "../insightActions";
import { useChartsProvider } from "@coachbar/shared-components/src/screens/dashboard/chartContext";
import UserTable from "./LitmosUserList";

const TrainingAndCertification = () => {
  const { partnerId } = useChartsProvider();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  let initialize = false;
  useEffect(() => {
    if (!initialize) {
      initialize = true;
      fetchData();
    }
  }, [partnerId]);

  const fetchData = async () => {
    setLoading(true);
    let { data } = await getLitmusData(partnerId);
    setLoading(false);
    if (data) {
      setData(data);
    }
  };

  return (
    <Box mb="20px">
      <ChartContainer>
        <ChartHeader title={"Training & Courses"} showDelete />
        <SimpleGrid columns={6} spacing={"20px"}>
          <ValuesChangeCard
            title={"Total Users"}
            value={
              data?.hasOwnProperty("totalUser") ? `${data?.totalUser}` : ""
            }
            valueSuffix=""
            loading={loading}
          />
          <ValuesChangeCard
            title={"Courses Assigned"}
            value={
              data.hasOwnProperty("totalCourseAssigned")
                ? `${data?.totalCourseAssigned}`
                : ""
            }
            valueSuffix=""
            loading={loading}
          />
          <ValuesChangeCard
            title={"Courses Completed"}
            value={
              data.hasOwnProperty("totalCourseCompleted")
                ? `${data?.totalCourseCompleted}`
                : ""
            }
            loading={loading}
          />
        </SimpleGrid>
        <Box mt="20px">
          <UserTable userList={data?.userCourses} />
        </Box>
      </ChartContainer>
    </Box>
  );
};

export default TrainingAndCertification;
