import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../services/axiosInstance";
import { getErrorMessage, getUserInfo } from "../../../services/utility";
export const getProfileCompletionStatus = createAsyncThunk(
  "get/profileCompletionStatus",
  async (id, { rejectWithValue }) => {
    let userInfo = getUserInfo();
    try {
      const { data } = await axiosInstance.get(
        `/profile/status?userType=${userInfo.userType}`
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getPartnerProfileRequirementPercentage = async () => {
  try {
    const { data } = await axiosInstance.get(`/status/requirement/percentage`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getPartnerProfileCompletionPercentage = async () => {
  try {
    const { data } = await axiosInstance.get(`/status/provider/percentage`);
    return data;
  } catch (error) {
    console.log(error);
  }
};
