// UrlDirectory.js
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Notification from "../../../components/Notification";
import {
  addUrlDirectory,
  getAllUrlDirectory,
  updateUrlDirectory,
  deleteUrlDirectory,
} from "./urlDirectoryActions";
import {
  addUrlDirectoryToList,
  updateUrlDirectoryFromList,
  removeUrlDirectoryFromList,
  clearMessage,
} from "./urlDirectorySlice";
import {
  Button,
  TextBox,
  Modal,
  Table,
  AlertDialog,
  Link,
  Loader,
  ActionIconButton,
  ACTION_ICON_BUTTON_TYPE,
  TableTextWithEllipsis,
} from "@coachbar/shared-components";
import {
  requiredField,
  invalidUrl,
} from "../../../constants/validationMessages";
import { urlValidationPattern } from "../../../constants/validationPatterns";
import {
  useDisclosure,
  Stack,
  Flex,
  HStack,
  Box,
  Image,
  Text,
  Spacer,
} from "@chakra-ui/react";
import { getProfileCompletionStatus } from "../profileMetrics/profileMetricsAction";
import {
  appendHttpsToUrl,
  removeHttpsFromUrl,
} from "../../../services/utility";
import CustomTooltip from "@coachbar/shared-components/src/components/Tooltip/CustomTooltip";
import { urlDirectoryTip } from "../../../constants/tooltips";
import InfoOutline from "../../../image/Info-outline.svg";
import ProfileNoRecordsFound from "../../../components/ProfileNoRecordsFound";
import emptyURL from "../../../image/emptyURL.png";
import {
  buttonVariants,
  scrollbarCSS,
} from "@coachbar/shared-components/src/constants/constantValues";
import {
  permissionTypes,
  userRoleEnums,
} from "../../../constants/usersAndRoles";

const PLATFORM_LABEL = "Website Name";
const LISTING_LABEL = "Listing Url";
const tableHead = [[PLATFORM_LABEL, LISTING_LABEL]];

const UrlDirectory = ({ permissions }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const { loading, error, urlDirectoryList, success } = useSelector(
    (state) => state.urlDirectory
  );

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const [tableBody, setTableBody] = useState([]);

  useEffect(() => {
    let tBody = [];
    for (let index = 0; index < urlDirectoryList.length; index++) {
      let directoryItem = [];
      directoryItem.push(getWebsiteName(urlDirectoryList[index]["title"]));
      directoryItem.push(getUrl(urlDirectoryList[index]["url"]));
      if (permissions.edit || permissions.delete)
        directoryItem.push(getActions(urlDirectoryList[index], index));
      tBody.push(directoryItem);
    }
    setTableBody([...tBody]);
    //eslint-disable-next-line
  }, [urlDirectoryList, permissions]);

  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      //eslint-disable-next-line
      initialized = true;
      dispatch(getAllUrlDirectory());
    }
  }, []);

  const getWebsiteName = (siteName) => (
    <TableTextWithEllipsis maxChars={25} text={siteName} />
  );

  const getUrl = (url) => {
    return (
      <Link
        to={url}
        target="_blank"
        style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
        fontSize={"14px"}
        lineHeight={"20px"}
        fontWeight={500}
        color={"#0C94AC"}
        decoration={"none !important"}
      >
        <TableTextWithEllipsis maxChars={112} text={url} />
      </Link>
    );
  };

  const getActions = (urlDirectoryData = null, index) => {
    return (
      <HStack key={"HStack-" + index} float="right" gap={"20px"}>
        {permissions.edit && (
          <ActionIconButton
            type={ACTION_ICON_BUTTON_TYPE.EDIT}
            onClickAction={(e) => {
              editUrlDirectoryData(e, urlDirectoryData);
            }}
          />
        )}
        {permissions.delete && (
          <ActionIconButton
            type={ACTION_ICON_BUTTON_TYPE.DELETE}
            onClickAction={(e) => {
              onAlertOpenAction(e, urlDirectoryData);
            }}
            containerProps={{ marginLeft: "0px !important" }}
          />
        )}
      </HStack>
    );
  };

  const submitForm = (data) => {
    let slectedId = selectedUrlDirectory || data.id;
    data.url = appendHttpsToUrl(data.url);
    if (slectedId) {
      dispatch(
        updateUrlDirectory({
          urlDirectoryId: slectedId,
          requestParams: data,
        })
      ).then((responseData) => {
        if (responseData.payload.success && responseData.payload.data) {
          if (
            !urlDirectoryList ||
            (urlDirectoryList && urlDirectoryList?.length < 1)
          ) {
            dispatch(getProfileCompletionStatus());
          }
          dispatch(
            updateUrlDirectoryFromList(responseData.payload.data.urlDirectory)
          );
          onModalClose();
        }
      });
    } else {
      dispatch(addUrlDirectory(data)).then((responseData) => {
        if (responseData.payload.success && responseData.payload.data) {
          if (
            !urlDirectoryList ||
            (urlDirectoryList && urlDirectoryList?.length < 1)
          ) {
            dispatch(getProfileCompletionStatus());
          }
          dispatch(
            addUrlDirectoryToList(responseData.payload.data?.urlDirectory)
          );
          onModalClose();
        }
      });
    }
  };

  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    onOpen: onAlertOpen,
    isOpen: isAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  const [selectedUrlDirectory, setSelectedUrlDirectory] = useState(null);

  const editUrlDirectoryData = (event, urlDirectoryData) => {
    let skipKeys = ["modified", "created", "id", "tenantId"];
    setSelectedUrlDirectory(urlDirectoryData?.id);
    for (const key in urlDirectoryData) {
      if (urlDirectoryData.hasOwnProperty(key) && !skipKeys.includes(key)) {
        setValue(
          key,
          key === "url"
            ? removeHttpsFromUrl(urlDirectoryData[key])
            : urlDirectoryData[key]
        );
      }
    }
    onOpen();
  };

  const onModalClose = () => {
    if (selectedUrlDirectory) {
      setSelectedUrlDirectory(null);
    }
    reset();
    onClose();
  };

  const [urlDirectoryIdToDelete, setUrlDirectoryToDelete] = useState(null);

  const onAlertCloseAction = () => {
    if (urlDirectoryIdToDelete) {
      dispatch(deleteUrlDirectory(urlDirectoryIdToDelete)).then((response) => {
        if (response.payload.success && response.payload.data) {
          dispatch(getProfileCompletionStatus());
          dispatch(removeUrlDirectoryFromList(urlDirectoryIdToDelete));
          setUrlDirectoryToDelete(null);
        }
      });
    }
    onAlertClose();
  };

  const onAlertOpenAction = (event, urlDirectoryDetails) => {
    setUrlDirectoryToDelete(urlDirectoryDetails?.id);
    onAlertOpen();
  };
  let headers = tableHead[0];
  if (permissions.edit || permissions.delete) headers = [...headers, ...[""]];

  const hasPermission = (permissionType) => {
    if (userInfo.role === userRoleEnums.manager) {
      return false;
    } else if (permissions[permissionType]) return true;
    return false;
  };

  return (
    <Box
      bg={"white"}
      p={"16px 0 24px"}
      w={"100%"}
      h={"calc(100vh - 216px)"}
      rounded={"8px"}
      overflow={"auto"}
      boxShadow={"1px 0px 4px #E5E7EB"}
      css={scrollbarCSS}
    >
      {error || success ? (
        <Notification
          title={error ? error : success}
          status={error ? "error" : "success"}
          onCloseToast={() => dispatch(clearMessage())}
        />
      ) : (
        ""
      )}
      <Flex alignItems={"center"} justifyContent={"space-between"} p="0 24px">
        <Text
          color={"var(--title-black-text)"}
          fontSize={"14px"}
          lineHeight={"20px"}
          fontWeight={"700"}
          mr={"8px"}
        >
          URL Directory
        </Text>
        <CustomTooltip content={urlDirectoryTip}>
          <Image
            display={"inline-block"}
            cursor={"pointer"}
            src={InfoOutline}
            w={"20px"}
            mt={"6px"}
          />
        </CustomTooltip>
        <Spacer />
        {hasPermission(permissionTypes.add) && urlDirectoryList.length > 0 && (
          <Button
            title={"Add URL"}
            onClick={onOpen}
            variant={buttonVariants.brandPrimary}
            id="profile-setup-url-add"
          />
        )}
      </Flex>
      {loading ? (
        <Loader />
      ) : urlDirectoryList && urlDirectoryList.length > 0 ? (
        <Box m={"16px 24px 0"}>
          <Table
            tHead={[headers]}
            tBody={tableBody}
            headingStyles={{
              backgroundColor: "#F9FAFB",
              color: "#5E6977 !important",
            }}
            keepBordersRounded
            keepLastColumnLeftAligned={!permissions.edit || !permissions.delete}
          />
        </Box>
      ) : (
        <Flex
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          flex={1}
        >
          <ProfileNoRecordsFound
            title="No URL Found"
            description={
              hasPermission(permissionTypes.add)
                ? "Try adding URLs of company using below Call to Action button."
                : ""
            }
            imageUrl={emptyURL}
          />
          {hasPermission(permissionTypes.add) && (
            <Button
              title="Add URL"
              onClick={onOpen}
              variant={buttonVariants.brandPrimary}
              rounded="8px"
              id="profile-setup-url-add"
            />
          )}
        </Flex>
      )}

      <Modal
        isOpen={isOpen}
        onClose={onModalClose}
        title={selectedUrlDirectory ? "Update Listing URL" : "Add Listing URL"}
        allowCloseButton={true}
        displayFooter={true}
        size={"md"}
        onSaveClick={handleSubmit(submitForm)}
        loading={loading}
        saveButtonTitle="Save URL"
      >
        <Stack spacing={2}>
          <form noValidate>
            <Flex>
              <TextBox
                type="text"
                name={PLATFORM_LABEL}
                placeholder={PLATFORM_LABEL}
                maxLength="100"
                required
                innerref={register("title", {
                  required: requiredField.replace("$Field$", PLATFORM_LABEL),
                })}
                validationErrors={errors}
                widthFull={true}
              />
            </Flex>
            <Flex>
              <TextBox
                type="text"
                name={LISTING_LABEL}
                placeholder={LISTING_LABEL}
                maxLength="256"
                required
                innerref={register("url", {
                  required: requiredField.replace("$Field$", LISTING_LABEL),
                  pattern: {
                    value: urlValidationPattern,
                    message: invalidUrl,
                  },
                })}
                leftAddOn="https://"
                validationErrors={errors}
                widthFull={true}
              />
            </Flex>
          </form>
        </Stack>
      </Modal>
      <AlertDialog
        title="Delete URL Directory"
        actionButtonName="Delete"
        isAlertOpen={isAlertOpen}
        onAlertClose={onAlertClose}
        onAlertCloseAction={onAlertCloseAction}
      />
    </Box>
  );
};
export default UrlDirectory;
