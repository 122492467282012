import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../services/axiosInstance";
import {
  getErrorMessage,
  getUserInfo,
  handleError,
  notification,
} from "../../services/utility";

export const addServiceOffering = createAsyncThunk(
  "add/serviceOffering",
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        "/serviceOffering/add",
        requestData
      );
      if (data?.code === 200 || 201) notification(data.message);
      return data;
    } catch (error) {
      // return custom error message from API if any
      handleError(error);
    }
  }
);

export const getServiceOffering = createAsyncThunk(
  "get/serviceOffering",
  async (partnerTenantId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/serviceOffering?partnerTenantId=${partnerTenantId}`
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      handleError(error);
    }
  }
);

export const updateServiceOffering = createAsyncThunk(
  "update/serviceOffering",
  async ({ serviceOfferingId, requestParams }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(
        `/serviceOffering/${serviceOfferingId}`,
        requestParams
      );
      if (data?.code === 200 || 201) notification(data.message);
      return data;
    } catch (error) {
      // return custom error message from API if any
      handleError(error);
    }
  }
);

export const deleteServiceOffering = createAsyncThunk(
  "delete/serviceOffering",
  async (serviceOfferingId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(
        `/serviceOffering/${serviceOfferingId}`
      );
      if (data?.code === 200 || 201) notification(data.message);
      return data;
    } catch (error) {
      // return custom error message from API if any
      handleError(error);
    }
  }
);

export const addServiceIndustry = createAsyncThunk(
  "add/serviceIndustry",
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("/service/add", requestData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getServiceIndustry = createAsyncThunk(
  "get/serviceIndustry",
  async (serviceOfferingId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/service/v1`);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateServiceIndustry = createAsyncThunk(
  "update/serviceIndustry",
  async ({ serviceOfferingId, requestParams }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(
        `/service/${serviceOfferingId}`,
        requestParams
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getIndustriesList = async () => {
  try {
    const { data } = await axiosInstance.get(`service/list`);
    return data.data.industryList;
  } catch (error) {
    console.log(error);
  }
};

export const getActiveRequirements = async (spTenantId) => {
  try {
    const { data } = await axiosInstance.get(
      `/requirement/active/${spTenantId}`
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const requestToAddNewIndustry = async (requestData) => {
  try {
    let userInfo = getUserInfo();
    let updatedFinalRequestData = { ...requestData };
    updatedFinalRequestData.userType = userInfo.userType;
    const { data } = await axiosInstance.post(
      `/service/addIndustryRequest`,
      updatedFinalRequestData
    );
    return data;
  } catch (error) {
    handleError(error);
  }
};
