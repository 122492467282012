import React from "react";
import { Box, Tooltip } from "@chakra-ui/react";

const TableTextWithEllipsis = ({
  text = "",
  maxChars = 20,
  tooltipProps = {},
  textContainerProps = {},
}) => {
  if (!text || typeof text !== "string") return;
  const isOverflowing = text.length > maxChars;
  const displayText = isOverflowing ? text.slice(0, maxChars) + "..." : text;

  return (
    <Tooltip
      isDisabled={!isOverflowing}
      width="unset"
      mb="1px"
      padding={"12px"}
      placement={"top"}
      borderRadius={"4px"}
      label={text}
      openDelay={100}
      textAlign={"left"}
      hasArrow
      {...tooltipProps}
    >
      <Box
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        {...textContainerProps}
      >
        {displayText}
      </Box>
    </Tooltip>
  );
};

export default TableTextWithEllipsis;
