import { Center, Flex, Image, Text } from "@chakra-ui/react";
import noRecordsFoundImg from "../../images/match-make-empty.png";

const NoRecordsFound = ({ title, description, minHeight, imageUrl }) => {
  return (
    <Center minH={minHeight ? minHeight : "240px"}>
      <Flex
        direction={"column"}
        gap={4}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Image src={imageUrl || noRecordsFoundImg} h={"100px"} />
        <Text
          color={"var(--title-black-text)"}
          fontSize={"16px"}
          lineHeight={"24px"}
          fontWeight={700}
        >
          {title || "No records found!"}
        </Text>
        <Text
          color={"#7E8792"}
          fontSize={"14px"}
          lineHeight={"20px"}
          fontWeight={400}
        >
          {description || ""}
        </Text>
      </Flex>
    </Center>
  );
};

export default NoRecordsFound;
