import {
  Box,
  Center,
  Flex,
  SimpleGrid,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { Button, Loader, MoreFilterButton } from "@coachbar/shared-components";
import DashboardDateFilter from "@coachbar/shared-components/src/components/Charts/components/DashboardDateFilter";
import EmptyDashboard from "@coachbar/shared-components/src/components/Charts/components/EmptyDashboard";
import FiltersDrawer from "@coachbar/shared-components/src/components/Charts/components/FiltersDrawer";
import WidgetModal from "@coachbar/shared-components/src/components/Charts/components/WidgetModal";
import { scrollbarCSS } from "@coachbar/shared-components/src/constants/constantValues";
import {
  defaultPerformanceWidgetDataSc,
  defaultPerformanceWidgetsDataCommon,
  slugConstants,
} from "@coachbar/shared-components/src/constants/dashboard";
import {
  ChartsProvider,
  useChartsProvider,
} from "@coachbar/shared-components/src/screens/dashboard/chartContext";
import { getVisibleChartsFromDb } from "@coachbar/shared-components/src/services/dashboardUtility/utility";
import { deepCopyObject } from "@coachbar/shared-components/src/services/utility";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getGeneralSettings } from "../../generalSettings/generalSettingsActions";
import { getPartnerProviderList } from "../../myProviders/providersList/providersListActions";
import { getAllUsers } from "../../settings/usersAndRoles/usersAndRolesActions";
import {
  getDashboardsCharts,
  updateDashboardCharts,
} from "../dashboardActions";
import DealByStatus from "./charts/DealByStatus";
import DealLostRate from "./charts/DealLostRate";
import DealTrends from "./charts/DealTrends";
import DealTypes from "./charts/DealTypes";
import DealWinRate from "./charts/DealWinRate";
import DealsByRegion from "./charts/DealsByRegion";
import LeadByStatus from "./charts/LeadByStatus";
import LeadConversionRate from "./charts/LeadConversionRate";
import LeadTrends from "./charts/LeadTrends";
import LeadTypes from "./charts/LeadTypes";
import Leads from "./charts/Leads";
import LeadsByRegion from "./charts/LeadsByRegion";
import SalesPipeline from "./charts/SalesPipeline";
import TopSellingProducts from "./charts/TopSellingProducts";
const dashboardType = "Performance";
const PerformanceComp = () => {
  const {
    selectedDate,
    setSelectedDate,
    chartList,
    setChartList,
    filters,
    onFiltersSubmit,
    userInfo,
    dashboardData,
    setDashboardData,
    platformJson,
  } = useChartsProvider();

  const {
    onOpen: onWidgetModalOpen,
    onClose: onWidgetModalClose,
    isOpen: isWidgetModalOpen,
  } = useDisclosure();

  const {
    onOpen: onFilterDrawerOpen,
    onClose: onFilterDrawerClose,
    isOpen: isFilterDrawerOpen,
  } = useDisclosure();

  const onDateChange = (date) => {
    if (JSON.stringify(date) !== JSON.stringify(selectedDate)) {
      // setLoading(true);
      setSelectedDate(date);
    }
  };

  const onChartsUpdate = async (updateCharts, onFail) => {
    let requestData = {
      id: dashboardData?.id,
      dashboardType: dashboardType,
      widgetList: Object.keys(updateCharts)
        .filter((chartKey) => updateCharts[chartKey].isVisible)
        .map((x) => x),
    };
    let { data } = await updateDashboardCharts(requestData);
    if (data?.code === 200) {
      setDashboardData(data?.data?.dashboard);
      setChartList(updateCharts);
      onWidgetModalClose();
    } else {
      onFail();
    }
  };

  let filtersCount = useMemo(() => {
    let count = 0;
    Object.keys(filters).map((x) => {
      if (filters[x].find((x) => x.isChecked)) {
        count++;
      }
    });
    return count;
  }, [filters]);

  let isAllChartsRemoved = useMemo(() => {
    return !Object.values(chartList).some((x) => x.isVisible);
  }, [chartList]);

  const smallSizedCharts = {
    [slugConstants.leads]: Leads,
    [slugConstants.leadTypes]: LeadTypes,
    [slugConstants.dealTypes]: DealTypes,
    [slugConstants.leadConversionRate]: LeadConversionRate,
    [slugConstants.dealsWonRate]: DealWinRate,
    [slugConstants.dealsLostRate]: DealLostRate,
  };

  const mediumSizedCharts = {
    [slugConstants.leadByStatuses]: LeadByStatus,
    [slugConstants.dealByStatuses]: DealByStatus,
    [slugConstants.leadTrends]: LeadTrends,
    [slugConstants.dealTrends]: DealTrends,
    [slugConstants.leadByRegions]: LeadsByRegion,
    [slugConstants.dealByRegions]: DealsByRegion,
  };

  const visibleSmallSizedCharts = Object.keys(smallSizedCharts).filter(
    (key) => chartList[key]?.isVisible
  );

  const visibleMediumSizedCharts = Object.keys(mediumSizedCharts).filter(
    (key) => chartList[key]?.isVisible
  );

  if (isAllChartsRemoved) {
    return (
      <>
        <EmptyDashboard
          onWidgetModalOpen={onWidgetModalOpen}
          moduleForId="dashboard-performance-empty"
        />
        ;
        <WidgetModal
          chartList={chartList}
          setChartList={onChartsUpdate}
          isOpen={isWidgetModalOpen}
          onClose={onWidgetModalClose}
          moduleForId="dashboard-performance-empty"
        />
      </>
    );
  }

  return (
    <Box p={"24px"} h={"calc(100vh - 64px)"} bg={"#F9FAFB"}>
      {userInfo?.role !== "User" && (
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          mb={"20px"}
        >
          <Flex alignItems={"center"} gap={"20px"} justifyContent={"start"}>
            <Stack pos={"relative"}>
              <DashboardDateFilter
                bg="#FFF"
                handleDateChange={onDateChange}
                selectedDateRange={selectedDate}
                showLastMonthData
                customPopoverTop="50px"
                customPopoverRight="-470px"
                moduleForId="dashboard-performance"
              />
            </Stack>
            <MoreFilterButton
              appliedMoreFilterCount={filtersCount}
              setShowMoreFilters={() =>
                isFilterDrawerOpen
                  ? onFilterDrawerClose()
                  : onFilterDrawerOpen()
              }
              showMoreFilters={isFilterDrawerOpen}
            />
          </Flex>
          <Button
            variant="newColorThemePrimary"
            onClick={onWidgetModalOpen}
            title="Add Widget"
            id="performance-dashboard-add-widget-open"
          />
        </Flex>
      )}

      <Box maxH={"calc(100vh - 174px)"} overflow={"auto"} css={scrollbarCSS}>
        {(chartList[slugConstants.leaderBoard]?.isVisible ||
          chartList[slugConstants.salesPipeline]?.isVisible) && (
          <SimpleGrid
            columns={2}
            gap={"20px"}
            mb={visibleSmallSizedCharts?.length > 0 ? "20px" : ""}
          >
            {chartList[slugConstants.salesPipeline]?.isVisible && (
              <SalesPipeline
                slug={slugConstants.salesPipeline}
                chartData={chartList[slugConstants.salesPipeline]}
              />
            )}
            {chartList[slugConstants.scTopSellingProducts]?.isVisible && (
              <TopSellingProducts
                slug={slugConstants.scTopSellingProducts}
                chartData={chartList[slugConstants.scTopSellingProducts]}
              />
            )}
          </SimpleGrid>
        )}

        {visibleSmallSizedCharts.length > 0 && (
          <SimpleGrid
            columns={3}
            gap={"20px"}
            mb={visibleMediumSizedCharts?.length > 0 ? "20px" : ""}
          >
            {visibleSmallSizedCharts.map((key) => {
              const ChartComponent = smallSizedCharts[key];
              return (
                <ChartComponent
                  key={key}
                  slug={key}
                  chartData={chartList[key]}
                />
              );
            })}
          </SimpleGrid>
        )}

        {visibleMediumSizedCharts.length > 0 && (
          <SimpleGrid columns={2} gap={"20px"}>
            {visibleMediumSizedCharts.map((key) => {
              const ChartComponent = mediumSizedCharts[key];
              return (
                <ChartComponent
                  key={key}
                  slug={key}
                  chartData={chartList[key]}
                />
              );
            })}
          </SimpleGrid>
        )}
      </Box>
      <WidgetModal
        chartList={chartList}
        setChartList={onChartsUpdate}
        isOpen={isWidgetModalOpen}
        onClose={onWidgetModalClose}
        moduleForId="dashboard-performance-chart-list"
      />
      <FiltersDrawer
        isOpen={isFilterDrawerOpen}
        isProvider={true}
        onModalClose={onFilterDrawerClose}
        filters={filters}
        platformJson={platformJson}
        setFilterValues={(newFilters) => {
          onFilterDrawerClose();
          if (JSON.stringify(filters) !== JSON.stringify(newFilters)) {
            // setLoading(true);
            onFiltersSubmit(newFilters);
          }
        }}
        moduleForId="dashboard-performance"
      />
    </Box>
  );
};

const Performance = () => {
  const { userInfo } = useSelector((state) => state.auth);
  let thisCharts = {
    ...defaultPerformanceWidgetDataSc,
    ...defaultPerformanceWidgetsDataCommon,
  };
  delete thisCharts[slugConstants.leaderBoard];
  const [fetchedAllData, setFetchedAllData] = useState(false);
  const [partnerManagers, setPartnerManagers] = useState([]);
  const [partnerTypes, setPartnerTypes] = useState([]);
  const [partners, setPartners] = useState([]);
  const [dashData, setDashData] = useState({});
  const [charts, setCharts] = useState(deepCopyObject(thisCharts));
  let initialize = false;
  useEffect(() => {
    if (!initialize) {
      initialize = true;
      fetchAllData();
    }
  }, []);

  const fetchAllData = async () => {
    const [dashData, partnerCategoryList, users, partnersList] =
      await Promise.all([
        getDashboardsCharts(dashboardType),
        getGeneralSettings("scCategory"),
        getAllUsers(),
        getPartnerProviderList(),
      ]);

    if (partnerCategoryList?.data?.spPartnerCategoryList) {
      setPartnerTypes(partnerCategoryList?.data?.spPartnerCategoryList);
    }
    if (users) {
      setPartnerManagers(users);
    }
    setPartners(partnersList.partnerList);
    if (dashData?.data) {
      setDashData(deepCopyObject(dashData?.data));
      setCharts(getVisibleChartsFromDb(dashData?.data?.widgetList, charts));
    }
    setFetchedAllData(true);
  };
  if (!fetchedAllData) {
    return (
      <Center minH={"calc(100vh - 64px)"}>
        <Loader />
      </Center>
    );
  }
  return (
    <ChartsProvider
      charts={charts}
      partners={partners}
      partnerManagers={partnerManagers}
      partnerTypes={partnerTypes}
      // partnerGroups={partnerGroups}
      // partnerTiers={partnerTiers}
      userInfo={userInfo}
      dashboardData={dashData}
      platform="sc"
      updateDashboardApi={updateDashboardCharts}
      dashboardType={dashboardType}
    >
      <PerformanceComp />
    </ChartsProvider>
  );
};

export default Performance;
