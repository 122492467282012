import { axiosInstance } from "../../services/axiosInstance";
import { getErrorMessage } from "../../services/utility";

export const purchaseService = async (requestData) => {
  try {
    const data = await axiosInstance.post(
      `/subscription/creditUsage`,
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    return error;
  }
};

export const getPaymentUpdateUrl = async () => {
  try {
    const data = await axiosInstance.get(`/subscription/getPaymentUpdateUrl`);
    return data;
  } catch (error) {
    // return custom error message from API if any
    return getErrorMessage(error);
  }
};

export const purchaseAddOnCredits = async (requestData) => {
  try {
    const data = await axiosInstance.post(
      `/subscription/purchaseAddOnCredits`,
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    return getErrorMessage(error);
  }
};

export const cancelSubscription = async (requestData) => {
  try {
    const data = await axiosInstance.post(
      `/subscription/cancelSubscription`,
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    return getErrorMessage(error);
  }
};

export const getSubscriptionDetails = async () => {
  try {
    const data = await axiosInstance.get(`/subscription`);
    return data;
  } catch (error) {
    // return custom error message from API if any
    return getErrorMessage(error);
  }
};

export const upgradePlan = async (requestData) => {
  try {
    const data = await axiosInstance.post(
      `/subscription/updateSubscription`,
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    return getErrorMessage(error);
  }
};

export const reactivateSubscription = async (requestData) => {
  try {
    const data = await axiosInstance.post(
      `/subscription/reactivateSubscription`
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    return getErrorMessage(error);
  }
};

export const confirmSubscription = async (requestData) => {
  try {
    // configure header's Content-Type as JSON
    const { data } = await axiosInstance.post(
      "/subscription/confirmSubscription",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return error.response.data;
    } else {
      return error.message;
    }
  }
};
