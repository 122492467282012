import { getUserInfo } from "../services/utility";
import {
  currentStackAbTip,
  currentStackTip,
  waitingTimeIcpAbTip,
  waitingTimeIcpTip,
} from "./tooltips";

export const icpRequirements = [
  {
    id: "projectBudget",
    type: "dropdownWithCheckbox",
    label: "Project Budget",
    placeholder: "Select Project Budget",
    serialNumber: 1,
  },
  {
    id: "serviceCategory",
    type: "dropdownWithCheckbox",
    label: "Service Category",
    placeholder: "Select Service Category",
    serialNumber: 2,
  },
  {
    id: "waitingTime",
    type: "dropdownWithCheckbox",
    label: "Turnaround Time",
    placeholder: "Select Turnaround Time",
    serialNumber: 3,
    tooltip:
      getUserInfo()?.userType === 3 ? waitingTimeIcpAbTip : waitingTimeIcpTip,
  },
  {
    id: "companyRevenue",
    type: "dropdownWithCheckbox",
    label: "Company Revenue",
    placeholder: "Select Revenue",
    serialNumber: 4,
  },
  {
    id: "projectDuration",
    type: "dropdownWithCheckbox",
    label: "Project Duration",
    placeholder: "Select Timeline",
    serialNumber: 5,
  },
  {
    id: "systemGrid",
    type: "simpleDropdown",
    label: "System Grid",
    placeholder: "Select System Grid",
    serialNumber: 6,
  },
  {
    id: "currentApplicationsStack",
    type: "dropdownWithCheckboxAndSearch",
    label: "Current Applications Stack",
    tooltip:
      getUserInfo()?.userType === 3 ? currentStackAbTip : currentStackTip,
    placeholder: "Select Apps Stack",
    serialNumber: 7,
  },
  {
    id: "industry",
    type: "dropdownWithCheckboxAndSearch",
    label: "Industry",
    placeholder: "Select Industries",
    serialNumber: 8,
  },
  {
    id: "country",
    type: "dropdownWithCheckboxAndSearch",
    label: "Country",
    placeholder: "Select Countries",
    serialNumber: 9,
  },
  {
    id: "companySize",
    type: "dropdownWithCheckbox",
    label: "Company Size",
    placeholder: "Select Company Size",
    serialNumber: 10,
  },
  {
    id: "deployment",
    type: "dropdownWithCheckbox",
    label: "Implementation Strategy",
    placeholder: "Select Implementation Strategy",
    tooltip: "Approach to implement the Software.",
    serialNumber: 11,
  },
];

export const waitingTimeOptions = [
  {
    name: "Less than a week",
    isChecked: false,
  },
  {
    name: "1 - 2 weeks",
    isChecked: false,
  },
  {
    name: "2 - 4 weeks",
    isChecked: false,
  },
  {
    name: "4 - 6 weeks",
    isChecked: false,
  },
  {
    name: "6 - 9 weeks",
    isChecked: false,
  },
  {
    name: "9 - 12 weeks",
    isChecked: false,
  },
  {
    name: "More than 12 weeks",
    isChecked: false,
  },
];

export const projectDurationOptions = [
  {
    name: "Less than 2 weeks",
    isChecked: false,
  },
  {
    name: "2 - 4 weeks",
    isChecked: false,
  },
  {
    name: "4 - 6 weeks",
    isChecked: false,
  },
  {
    name: "6 - 9 weeks",
    isChecked: false,
  },
  {
    name: "9 - 12 weeks",
    isChecked: false,
  },
  {
    name: "12 - 16 weeks",
    isChecked: false,
  },
  {
    name: "More than 16 weeks",
    isChecked: false,
  },
];

export const companySizeOptions = [
  {
    name: "Self-employed",
    isChecked: false,
  },
  {
    name: "1-10 employees",
    isChecked: false,
  },
  {
    name: "11-50 employees",
    isChecked: false,
  },
  {
    name: "51-200 employees",
    isChecked: false,
  },
  {
    name: "201-500 employees",
    isChecked: false,
  },
  {
    name: "501-1000 employees",
    isChecked: false,
  },
  {
    name: "More than 1000 employees",
    isChecked: false,
  },
];

export const deploymentOptions = [
  { name: "SaaS", isChecked: false },
  { name: "Desktop", isChecked: false },
  { name: "Mobile", isChecked: false },
];

export const icpTitle = "Ideal Customer Profile (ICP)";
export const noDataText = `No ${icpTitle} added yet.`;
