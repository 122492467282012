import { axiosInstance } from "../../../services/axiosInstance";
import { handleError } from "../../../services/utility";

// login API for litmos
export const litmosLogin = async () => {
  try {
    const { data } = await axiosInstance.get(`/sp/litmos/sso`);
    return data;
  } catch (error) {
    handleError(error);
  }
};
