import { cardAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(cardAnatomy.keys);

// define custom styles for funky variant
const variants = {
  bar: definePartsStyle({
    container: {
      borderWidth: "1px",
      borderColor: "brandGray.20",
      bg: "brandGray.100",
      borderRadius: "10px",
      _hover: {
        boxShadow: "0px 4px 11px rgba(0, 0, 0, 0.06)",
      },
    },
  }),
  form: definePartsStyle({
    container: {
      bg: "brandGray.100",
      borderWidth: "1px",
      borderColor: "brandGray.20",
      borderRadius: "12px",
      boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)",
    },
  }),
  plan: definePartsStyle({
    container: {
      borderWidth: "1px",
      borderColor: "brandGray.20",
      borderRadius: "12px",
      _hover: {
        boxShadow: "0px 4px 11px rgba(0, 0, 0, 0.06)",
      },
    },
  }),
  activePlan: definePartsStyle({
    container: {
      borderWidth: "2px",
      borderColor: "brand.50",
      borderRadius: "12px",
      _hover: {
        boxShadow: "0px 4px 11px rgba(0, 0, 0, 0.06)",
      },
    },
  }),
  list: definePartsStyle({
    container: {
      borderRadius: "12px",
      border: "1px solid",
      borderColor: "brandGray.20 !important",
      boxShadow: "0px 1px 0px rgba(0, 0, 0, 0.25)",
    },
  }),
  roadmapCard: definePartsStyle({
    container: {
      bg: "#E2E8F0",
      borderRadius: "6px",
    },
  }),
};

// export variants in the component theme
export const cardTheme = defineMultiStyleConfig({ variants });
