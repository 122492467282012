import React, { useState, useCallback } from "react";
import update from "immutability-helper";
import SoftwareGrid from "./SoftwareGrid";
import { Flex, SimpleGrid } from "@chakra-ui/react";

const SoftwareGridContainer = ({
  setSelectedSoftware,
  selectedSoftware,
  onClickOpenModal,
  removeSelectedSoftware,
}) => {
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setSelectedSoftware((prevCards) => {
      const updatedCards = [...prevCards];

      // Swap the positions of the cards at dragIndex and hoverIndex
      [updatedCards[dragIndex], updatedCards[hoverIndex]] = [
        updatedCards[hoverIndex],
        updatedCards[dragIndex],
      ];

      return updatedCards;
    });
  }, []);

  const renderCard = useCallback((selectedSoftware, index) => {
    return (
      <SoftwareGrid
        key={index}
        index={index}
        moveCard={moveCard}
        onClickOpenModal={onClickOpenModal}
        data={selectedSoftware}
        removeSelectedSoftware={removeSelectedSoftware}
      />
    );
  }, []);

  return (
    <>
      <Flex
        className={"system-grid-wrap"}
        h={"calc(100% - 40px)"}
        rounded={"8px"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <SimpleGrid
          w="calc(460px + 1rem)"
          columns={3}
          spacing={"30px"}
          p={5}
          borderRadius={"8px"}
        >
          {Array.from({ length: 9 }).map((_, i) => {
            if (i < selectedSoftware.length) {
              return renderCard(selectedSoftware[i], i);
            } else {
              return renderCard({}, i);
            }
          })}
          {/* {selectedSoftware.map((card, i) => renderCard(card, i))} */}
        </SimpleGrid>
      </Flex>
    </>
  );
};

export default SoftwareGridContainer;
