import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import NewPassword from "./NewPassword";
import VerifyOtp from "./VerifyOtp";
import {
  AuthLeftPanel,
  AuthRightPanel,
  Button,
} from "@coachbar/shared-components";
import {
  AbsoluteCenter,
  Box,
  Flex,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import SuccessCheck from "@coachbar/shared-components/src/images/success-check.png";
import FrameBg from "@coachbar/shared-components/src/images/frame-bg.png";
import Notification from "../../components/Notification";
import { clearMessage } from "./authSlice";
import { decryptData } from "../../services/utility";

const ResetPassword = () => {
  const [isOtpVerified, setisOtpVerified] = useState(false);
  const [isResetPasswordSuccess, setIsResetPasswordSuccess] = useState(false);
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const email = decryptData(token);
  const { userInfo, error, success } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo) {
      navigate("/dashboard");
    }
    if (!token) navigate("/forgot-password");
    if (!email) navigate("/forgot-password");
  }, [navigate, userInfo, token, email]);

  const getResetPasswordPage = () => {
    if (isResetPasswordSuccess) return <ResetPasswordSuccess />;

    if (isOtpVerified)
      return (
        <NewPassword
          token={token}
          email={email}
          userInfo={userInfo}
          setIsResetPasswordSuccess={setIsResetPasswordSuccess}
        />
      );

    return (
      <VerifyOtp
        token={token}
        email={email}
        userInfo={userInfo}
        setisOtpVerified={setisOtpVerified}
      />
    );
  };

  return (
    <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
      {error || success ? (
        <Notification
          title={error ? error : success}
          status={error ? "error" : "success"}
          onCloseToast={() => dispatch(clearMessage())}
        />
      ) : (
        ""
      )}
      <AuthLeftPanel />
      <AuthRightPanel width={"1 1 calc(100% - 410px)"} position="relative">
        {getResetPasswordPage()}
      </AuthRightPanel>
    </Stack>
  );
};

const ResetPasswordSuccess = () => {
  const navigate = useNavigate();
  return (
    <Flex
      direction={"column"}
      maxW={"350px"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box position={"relative"} mb={"8px"}>
        <Image src={FrameBg} h={"135px"} />
        <AbsoluteCenter>
          <Image src={SuccessCheck} h={"70px"} />
        </AbsoluteCenter>
      </Box>
      <Text
        fontSize="20px"
        lineHeight={"20px"}
        fontWeight="700"
        mb="8px !important"
        color="#111A29"
        textAlign="center"
      >
        Password Reset!
      </Text>
      <Text
        color="#7E8792"
        fontSize="14px"
        fontWeight={400}
        textAlign="center"
        mb="32px !important"
        p="0px 18px"
      >
        Your password has been successfully reset. Click below to log in
        magically.
      </Text>
      <Button
        title="Continue"
        width="100%"
        onClick={() => navigate("/login")}
      />
    </Flex>
  );
};

export default ResetPassword;
