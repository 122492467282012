import CryptoJS from "crypto-js";
import axios from "axios";
import { axiosInstance } from "./axiosInstance";
import { getPlanDetail, getUserDetails } from "../features/auth/authActions";
import { parseISO, isAfter } from "date-fns";
import { getPublishedSystemGrid } from "../features/icpSetup/icpActions";
import { updateListOptions } from "../features/icpSetup/icpSlice";
import { createStandaloneToast } from "@chakra-ui/react";
import sc from "states-cities-db";
import { CountryFlag } from "@coachbar/shared-components";
import {
  dealsColumnOrder,
  dealsVisibleColumns,
} from "@coachbar/shared-components/src/constants/deals";
import {
  leadsColumnOrder,
  leadsVisibleColumns,
} from "@coachbar/shared-components/src/constants/lead";
import {
  currenciesList,
  notificationIcons,
} from "@coachbar/shared-components/src/constants/constantValues";
import { userRoleEnums } from "../constants/usersAndRoles";
const { toast } = createStandaloneToast();

const COUNTRIES = sc.getCountries();
const priorityCountriesData = [];
const otherCountriesData = [];
const priorityCountries = ["AUS", "CAN", "SGP", "GBR", "USA", "NZL"];

COUNTRIES.forEach((country) => {
  if (priorityCountries.includes(country.iso)) {
    priorityCountriesData.push(country);
  } else {
    otherCountriesData.push(country);
  }
});
otherCountriesData.sort((a, b) => a.name.localeCompare(b.name));
const sortedCountries = priorityCountriesData.concat(otherCountriesData);

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(
    data,
    process.env.REACT_APP_SECRET_PASSPHRASE
  ).toString();
};

export const decryptData = (data) => {
  const bytes = CryptoJS.AES.decrypt(
    data,
    process.env.REACT_APP_SECRET_PASSPHRASE
  );
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const setUserToken = (token) => {
  let tokenString = JSON.stringify(token);
  let encryptedToken = encryptData(tokenString);
  localStorage.setItem("userToken", encryptedToken);
};

export const getUserToken = () => {
  let encryptedToken = localStorage.getItem("userToken");
  if (encryptedToken) {
    let tokenString = decryptData(encryptedToken);
    return JSON.parse(tokenString);
  }
  return null;
};

export const setUserInfo = (userData) => {
  localStorage.setItem("userInfo", JSON.stringify(userData));
};

export const getUserInfo = () => {
  let userData = localStorage.getItem("userInfo");
  if (userData) {
    return JSON.parse(userData);
  }
  return null;
};

export const deleteSessionStorage = () => {
  localStorage.removeItem("userToken");
  localStorage.removeItem("userInfo");
  localStorage.removeItem("roadmapStatus");
  localStorage.removeItem("visibleColumns");
  localStorage.removeItem("providerListColumnOrder");
  localStorage.removeItem("currency");
  localStorage.removeItem("rewardsColumnOrder");
  localStorage.removeItem("rewardsVisibleColumns");
  localStorage.removeItem("estimatedRewardsVisibleColumns");
  localStorage.removeItem("estimatedRewardsColumnOrder");
  localStorage.removeItem(dealsVisibleColumns);
  localStorage.removeItem(dealsColumnOrder);
  localStorage.removeItem(leadsVisibleColumns);
  localStorage.removeItem(leadsColumnOrder);
  deleteSubscriptionStorage();
};

export const setSubscriptionStorage = (planId) => {
  localStorage.setItem("planId", planId);
};

export const deleteSubscriptionStorage = () => {
  localStorage.removeItem("planId");
};

export const getPreSignedUrl = async (imageName) => {
  try {
    // configure header's Content-Type as JSON
    const data = await axiosInstance.get(
      `/awsService/preSignedUrl/image?objectName=${imageName}`
    );
    // store user's token in local storage
    // localStorage.setItem("userToken", data.userToken);
    return data;
  } catch (error) {
    // return custom error message from API if any
    return getErrorMessage(error);
  }
};

export const uploadImage = async (fileObj, preSignedUrl) => {
  try {
    const data = await axios.put(preSignedUrl, fileObj, {
      headers: { "Content-Type": fileObj.type },
    });
    return data;
  } catch (error) {
    // return custom error message from API if any
    return getErrorMessage(error);
  }
};

export const getErrorMessage = (error) => {
  if (error.response && error.response.data) {
    return error.response.data?.message || error.response.data?.error;
  } else {
    return error.message;
  }
};

export const getSubscriptionPlanDetails = (dispatch) => {
  dispatch(getPlanDetail()).then((responseData) => {
    const response = responseData.payload;
    if (
      response &&
      response.success &&
      response.data &&
      response.data.subscriptionPlans
    ) {
      const subscriptionPlan = response.data.subscriptionPlans;
      const selectedPlan = subscriptionPlan.find(
        (plan) => plan.planId === JSON.parse(localStorage.getItem("planId"))
      );
      if (selectedPlan) {
        window.open(selectedPlan.publicSignUpPage, "_self");
      }
    }
    return null;
  });
};

export const convertDate = (dateString) => {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear());

  return `${day}/${month}/${year}`;
};

export const getThumbnailFromVideoUrl = (videoUrl = "") => {
  let videoThumbnail = "";

  if (videoUrl.indexOf("www.youtube.com") !== -1) {
    let embedId = null;
    if (videoUrl.indexOf("embed") !== -1) {
      embedId = videoUrl.split("/embed/")[1] || "";
    } else if (videoUrl.indexOf("watch") !== -1) {
      const urlParams = new URL(videoUrl).searchParams;
      embedId = urlParams.get("v");
    }
    videoThumbnail = `https://img.youtube.com/vi/${embedId}/hqdefault.jpg`;
  } else if (videoUrl.indexOf("youtu.be") !== -1) {
    // Extract video ID from the short URL format
    const videoId = videoUrl.split("/").pop();

    // Construct the thumbnail URL
    videoThumbnail = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  } else if (videoUrl.indexOf("vimeo.com") !== -1) {
    let vimeoId = videoUrl.split("/vimeo.com/")[1] || "";
    if (vimeoId) {
      vimeoId = vimeoId.split("?")[0] || "";
    }
    videoThumbnail = `https://vumbnail.com/${vimeoId}.jpg`;
  } else if (videoUrl.indexOf("www.loom.com") !== -1) {
    let loomUrl = videoUrl.split("?")[0];
    let loomId = loomUrl.split("/share/")[1] || "";
    videoThumbnail = `https://cdn.loom.com/sessions/thumbnails/${loomId}-00001.jpg`;
  }

  return videoThumbnail;
};

export const getDateString = (date = "", isTime = true) => {
  const dateObj = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  let timeString = dateObj.toLocaleTimeString();
  let dateString = dateObj.toLocaleDateString(undefined, options);
  if (isTime) {
    return dateString + " | " + timeString;
  } else {
    return dateString;
  }
};

// Calculate the total used credits based on service quantities and credits - Roadmap
export const calculateUsedCredits = (sectionsData) => {
  return sectionsData?.sections?.reduce((acc, section) => {
    return (
      acc +
      section?.services?.reduce((sectionAcc, service) => {
        return (
          sectionAcc +
          (typeof service.credit === "number"
            ? service.credit * service.quantity
            : 0)
        );
      }, 0)
    );
  }, 0);
};

// Function to compare two date strings
export const compareDates = (date1Str, date2Str) => {
  const date1 = parseISO(date1Str);
  const date2 = parseISO(date2Str);
  return isAfter(date1, date2);
};

// Function helpful in Drag and Drop preview.
export const getEmptyImage = () => {
  let emptyImage = new Image();
  emptyImage.src =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
  return emptyImage;
};

export const removeHttpsFromUrl = (url) => {
  if (url.length > 0 && url.includes("https://")) {
    return url.replace(/^https?:\/\//, "");
  }
  return url;
};

export const appendHttpsToUrl = (url) => {
  if (url.length > 0) {
    if (url.startsWith("https://")) return url;
    else return `https://${url}`;
  } else {
    return url;
  }
};

export function updateSystemGridData(dispatch) {
  let fetchedSystemGridOptions;
  dispatch(getPublishedSystemGrid())
    .then((systemGridResponse) => {
      if (systemGridResponse?.payload?.data?.softwareBundleList) {
        fetchedSystemGridOptions =
          systemGridResponse.payload.data.softwareBundleList.map((grid) => ({
            label: grid?.title,
            value: grid?.title,
          }));
      }
    })
    .then(() => {
      dispatch(
        updateListOptions({
          systemGridOptions: fetchedSystemGridOptions,
        })
      );
    });
}

export const getCountriesWithCode = () => {
  let countryOptions = [];
  for (let index = 0; index < sortedCountries.length; index++) {
    let prefix = sortedCountries[index]["prefix"];
    countryOptions.push({
      label: `${sortedCountries[index]["iso"]}(${prefix})`,
      value: `${sortedCountries[index]["iso"]}(${prefix})`,
    });
  }
  return countryOptions;
};

export const getCountryList = (isMultiple = false) => {
  const countryOptions = [];
  sortedCountries.map((country) => {
    if (isMultiple) {
      countryOptions.push({
        name: country.name,
        customLogo: <CountryFlag country={country.name} />,
        isChecked: false,
      });
    } else {
      countryOptions.push({
        label: country.name,
        value: country.name,
      });
    }
  });
  return countryOptions;
};

export const notification = (
  title,
  status = "success",
  description = "",
  time = 3000,
  onCloseToast
) => {
  toast({
    title: "",
    description: title || "",
    status: status || "success",
    position: "top",
    duration: 3000,
    isClosable: true,
    time: time,
    onCloseToast: onCloseToast,
    icon: <img src={notificationIcons[status]} />,
  });
};

export const handleError = (error) => {
  if (error.response && error.response.data.message) {
    notification(error.response.data.message, "error");
  } else {
    notification(error.message, "error");
  }
};

// export const updateRoleData = async (callBack = () => {}) => {
//   let userInfo = store.getState().auth.userInfo;
//   console.log(store.getState());
//   let userToken = store.getState().auth.userToken;
//   await getUserDetails().then(async (res) => {
//     if (res.code === 200) {
//       if (userInfo.roleId !== res.data.user.roleId) {
//         await getRoleDetails({ id: res.data.user.roleId }, userToken).then(
//           (response) => {
//             let updatedUserInfo = {
//               ...res.data.user,
//               roleId: res.data.user.roleId,
//               roleData: response.roles,
//               role: res.data.user.role,
//             };
//             setUserInfo(updatedUserInfo);
//             store.dispatch(setUserData());
//             callBack(updatedUserInfo);
//           }
//         );
//       } else {
//         let updatedUserInfo = {
//           ...userInfo,
//           ...res.data.user,
//         };
//         setUserInfo(updatedUserInfo);
//         store.dispatch(setUserData());
//         callBack(updatedUserInfo);
//       }
//     }
//   });
// };

export function downloadFromUrl(uri, name, target = null) {
  var link = document.createElement("a");
  link.download = name;
  link.href = uri;
  if (target) link.setAttribute("target", target);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  // delete link;
}

export const getCurrentPartnerData = (spTenantId, partnerList) => {
  if (spTenantId === process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID)
    return { scCompanyName: "Coachbar", assignedUser: "" };
  return partnerList.find(
    (partner) => partner.referenceTenantId === spTenantId
  );
};

const isAssignedPartner = (userId, spTenantId, partnerList) => {
  const currentPartnerData = getCurrentPartnerData(spTenantId, partnerList);
  return userId === currentPartnerData?.assignedUser;
};

export const hasPermissionManagerSpecialCase = (
  permissionType,
  spTenantId,
  userInfo,
  permissions,
  partnerList
) => {
  if (userInfo.role === userRoleEnums.manager) {
    if (permissions.assigned && permissions[permissionType])
      return isAssignedPartner(userInfo.id, spTenantId, partnerList);
  } else if (permissions[permissionType]) return true;
  return false;
};
