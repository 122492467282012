import {
  Stack,
  Checkbox,
  Text,
  Flex,
  Spacer,
  Image,
  Divider,
  Center,
} from "@chakra-ui/react";
import { Button } from "@coachbar/shared-components";
import { useEffect, useMemo, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import {
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
} from "react-virtualized";
import NoData from "../components/NoData/NoData";
import NoResult from "../images/no-results.svg";
import SearchBar from "../components/SearchBar/SearchBar";

const SearchList = (props) => {
  const { moduleForId } = props;

  const [searchString, setSearchString] = useState("");
  const [searchParam] = useState(["name"]);
  const [itemList, setItemList] = useState([]);
  const [scrollToTop, setScrollToTop] = useState(false);
  const [userType, setUserType] = useState(0);

  const scrollbarsRef = useRef();
  const listRef = useRef();

  window.scrollbarsRef = scrollbarsRef;
  window.listRef = listRef;

  useEffect(() => {
    let userData = localStorage.getItem("userInfo");
    setUserType(JSON?.parse(userData)?.userType);
    setItemList(props?.itemList);
  }, [props.itemList]);

  useEffect(() => {
    setSearchString("");
    if (props.hasOwnProperty("isPopoverOpen") && props.isPopoverOpen)
      setItemList(props.itemList);
  }, [props.isPopoverOpen]);

  const search = (items) => {
    return items?.filter((item) => {
      if (item.hasOwnProperty("name")) {
        return searchParam?.some((newItem) => {
          return (
            item[newItem]
              .toString()
              .toLowerCase()
              .indexOf(searchString.toLowerCase()) > -1
          );
        });
      } else {
        return item;
      }
    });
  };
  const onCheckBoxClick = (e) => {
    let itemName = e.target?.id ? e.target?.id : e.target?.name;
    let selectedItem = null;
    let updatedItemList = itemList.map((item) => {
      if (item.name === itemName) {
        selectedItem = { ...item, isChecked: !item.isChecked };
        return selectedItem;
      }
      return item;
    });
    setItemList(updatedItemList);
    if (props.handleCheckBoxChange) {
      props.handleCheckBoxChange(selectedItem, updatedItemList, props);
    }
  };

  useEffect(() => {
    setScrollToTop(!scrollToTop);
  }, [searchString]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollToPosition(0);
    }
    if (scrollbarsRef.current) {
      scrollbarsRef.current.scrollTop(0);
    }
  }, [scrollToTop]);

  const onSelectAll = (isAllChecked) => {
    let upDatedList = itemList.map((x) => ({ ...x, isChecked: isAllChecked }));
    setItemList(upDatedList);
    if (props.handleCheckBoxChange) {
      props.onSelectAll(upDatedList);
    }
  };

  const isAllSelected = useMemo(() => {
    return itemList.every((x) => x.isChecked);
  }, [itemList]);

  return itemList.length > 0 ? (
    <Stack spacing={4}>
      {props.allowSearch ? (
        <SearchBar
          value={searchString}
          onChange={(value) => {
            setSearchString(value);
          }}
          placeholder="Search items..."
          maxWidth={"auto"}
          bottomSpacing="8px"
        />
      ) : (
        ""
      )}
      {props.showAllSelect && (
        <>
          <Flex
            key={"all-select"}
            id={"all-select"}
            cursor={"pointer"}
            onClick={() => onSelectAll(!isAllSelected)}
            pt="2"
            pb="2"
            _hover={{
              bg: `brandGray.10`,
            }}
          >
            <Text ml={"10px"} fontWeight={"500"} pointerEvents={"none"}>
              All
            </Text>
            <Spacer pointerEvents={"none"} />
            <Checkbox
              pr={5}
              size="md"
              value={"all-select"}
              name={"all-select"}
              isChecked={isAllSelected}
              pointerEvents={"none"}
            />
          </Flex>
          <Divider style={{ marginTop: "0px" }} />
        </>
      )}
      <Stack
        spacing={4}
        style={{ marginTop: props.showAllSelect ? "0px" : "auto" }}
        width="full"
      >
        {search(itemList).length > 0 ? (
          getList(
            search(itemList),
            onCheckBoxClick,
            props.fallbackImage,
            props,
            scrollbarsRef,
            listRef,
            props.hasOwnProperty("hasPermission") ? props.hasPermission : true
          )
        ) : (
          <>
            {props.requestToAddSoftware ? (
              <Center flexDirection={"column"} p={10}>
                <Image src={NoResult} h={"130px"} mb={3} />
                <Text
                  fontSize={"16px"}
                  fontWeight={700}
                  color={"brand.100"}
                  mb={"15px !important"}
                >
                  Sorry! No results found 😞
                </Text>
                {/* {userType !== 2 && (
                  <>
                    <Text
                      fontSize={"14px"}
                      fontWeight={400}
                      color={"#7284A0"}
                      textAlign={"center"}
                      mb={"15px !important"}
                    >
                      We couldn't find what you were looking for. Please try
                      searching with another term or request a new one using the
                      button below.
                    </Text>
                    <Button
                      variant="specializationButton"
                      title={"Add Software"}
                      h={"auto"}
                      w={"auto"}
                      px={5}
                      py={2}
                      onClick={props.handleRequestSoftware}
                      id={`${moduleForId}-search-add-software`}
                    />
                  </>
                )} */}
              </Center>
            ) : (
              <NoData
                description={props.message ? props.message : "No data found"}
              />
            )}
          </>
        )}
      </Stack>
      {props.modalBtnClick && (
        <>
          <Divider />
          <Button
            mt={"7px !important"}
            mx={"auto !important"}
            p="4px"
            variant="link"
            title={props.btnText ? props.btnText : "Add Client"}
            onClick={() => {
              props.modalBtnClick(itemList);
            }}
            isDisabled={props.isModalBtnDisabled}
            style={{ textDecoration: "none", border: "none" }}
            id={`${moduleForId}-search-modal-btn`}
          />
        </>
      )}
    </Stack>
  ) : (
    <>
      <NoData description={props.message ? props.message : "No data found"} />
      {props.modalBtnClick && (
        <>
          <Divider />
          <Button
            mt={"7px !important"}
            mx={"auto !important"}
            variant="link"
            display={"block"}
            title="Add Client"
            onClick={props.modalBtnClick}
            isDisabled={props.isModalBtnDisabled}
            id={`${moduleForId}-search-modal-btn`}
          />
        </>
      )}
    </>
  );
};
// ./packages/shared-components

const getList = (
  itemList,
  onCheckChange,
  image,
  props,
  scrollbarsRef,
  listRef,
  hasPermission
) => {
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 100,
  });
  return (
    <Stack
      width="full"
      minH={itemList?.length < 4 && itemList?.length > 2 ? 56 : 28}
      height={
        itemList?.length < 2
          ? "auto"
          : itemList?.length < 4
          ? "auto"
          : itemList?.length <= 7
          ? itemList?.length * 40
          : props?.customHeight || 320
      }
    >
      <AutoSizer>
        {({ width, height }) => (
          <Scrollbars
            autoHide
            style={{ width, height }}
            ref={scrollbarsRef}
            onScroll={({ target }) => {
              const { scrollTop, scrollLeft } = target;
              if (listRef.current) {
                listRef.current.Grid.handleScrollEvent({
                  scrollTop,
                  scrollLeft,
                });
              }
            }}
          >
            <List
              style={{ overflowX: false, overflowY: false }}
              ref={listRef}
              height={height}
              width={width}
              deferredMeasurementCache={cache}
              rowHeight={cache.rowHeight}
              rowCount={itemList.length}
              rowRenderer={({ index, style, key, parent }) => {
                let item = itemList[index];
                return (
                  <CellMeasurer
                    key={key}
                    cache={cache}
                    parent={parent}
                    columnIndex={0}
                    rowIndex={index}
                  >
                    {({ registerChild }) => (
                      <Flex
                        key={item?.name}
                        id={item?.name}
                        cursor={"pointer"}
                        onClick={(e) => {
                          if (hasPermission) onCheckChange(e);
                        }}
                        pointerEvents={
                          props.disable && !item.isChecked ? "none" : ""
                        }
                        pt="2"
                        pb="2"
                        style={style}
                        ref={registerChild}
                        _hover={{
                          bg: `brandGray.10`,
                        }}
                      >
                        {props.showImage ? (
                          <Image
                            boxSize="30px"
                            objectFit="contain"
                            border={"1px solid"}
                            borderColor={"brandGray.50"}
                            borderRadius={"4px"}
                            src={item.logo}
                            alt={item.name}
                            fallbackSrc={image}
                            pointerEvents={"none"}
                          />
                        ) : (
                          ""
                        )}
                        {item.customLogo ? item.customLogo : ""}

                        <Text
                          ml={"10px"}
                          fontWeight={"500"}
                          pointerEvents={"none"}
                        >
                          {item.name}
                        </Text>
                        <Spacer pointerEvents={"none"} />
                        <Checkbox
                          isDisabled={
                            props.disable && !item.isChecked
                              ? props.disable
                              : false
                          }
                          pr={5}
                          size="md"
                          value={item.name}
                          name={item.name}
                          isChecked={item.isChecked}
                          onChange={onCheckChange}
                          pointerEvents={"none"}
                        />
                      </Flex>
                    )}
                  </CellMeasurer>
                );
              }}
            />
          </Scrollbars>
        )}
      </AutoSizer>
    </Stack>
  );
};

export default SearchList;
