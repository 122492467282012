import React, { Fragment, useMemo } from "react";
import { Button, Loader } from "@coachbar/shared-components";
import {
  Box,
  Divider,
  Flex,
  HStack,
  Image,
  SimpleGrid,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import CustomDrawer from "@coachbar/shared-components/src/components/Drawer/Drawer";
import { CloseIcon } from "@chakra-ui/icons";
import MessageIcon from "@coachbar/shared-components/src/images/message-icon.svg";
import IndustryIcon from "../../../image/building-icon.svg";
import RocketIcon from "../../../image/rocket-icon.svg";
import { buttonVariants } from "@coachbar/shared-components/src/constants/constantValues";

const INVITATION_STATUSES = {
  SENT: "Sent",
  ACCEPTED: "Accepted",
  REJECTED: "Rejected",
  EXPIRED: "Expired",
};

const EVENT_TYPES = {
  ACCEPT: "Accept",
  REJECT: "Reject",
  DELETE: "Delete",
};

const InvitationDetails = ({
  isOpen,
  isDetailModalLoading,
  data,
  partnerProgramContent,
  permissions,
  handleModalClose,
  replacePlaceholders,
  setEventType,
  setSelectedRecords,
  handleUpdateInviteRequest,
  partnerTier,
}) => {
  const onClose = () => {
    handleModalClose();
  };

  if (!data) return;

  return (
    <CustomDrawer
      size="xl"
      isOpen={isOpen}
      onClose={onClose}
      displayFooter={false}
      saveButtonTitle="Submit"
      discardTitle="Cancel"
      headerPadding="12px 24px"
      title={
        <Flex w="100%" justifyContent={"space-between"} alignItems={"center"}>
          <HStack>
            <Text as="h4">Invitation Details</Text>
            <InvitationStatusPill inviteStatus={data?.inviteStatus || ""} />
          </HStack>
          <Flex alignItems={"center"} gap="8px">
            {permissions.edit &&
              data.inviteStatus === INVITATION_STATUSES.SENT && (
                <React.Fragment>
                  <Button
                    onClick={() => {
                      handleUpdateInviteRequest(
                        [{ original: data }],
                        INVITATION_STATUSES.ACCEPTED
                      );
                    }}
                    title={EVENT_TYPES.ACCEPT}
                    variant={buttonVariants.brandPrimaryOutline}
                    id="my-provider-invitation-details-accept"
                  />
                  <Button
                    onClick={() => {
                      setSelectedRecords([{ original: data }]);
                      setEventType(EVENT_TYPES.REJECT);
                    }}
                    title={EVENT_TYPES.REJECT}
                    variant={buttonVariants.dangerOutline}
                    id="my-provider-invitation-details-reject"
                  />
                </React.Fragment>
              )}
            {permissions.delete &&
              (data.inviteStatus === INVITATION_STATUSES.REJECTED ||
                data.inviteStatus === INVITATION_STATUSES.EXPIRED) && (
                <Button
                  onClick={() => {
                    setSelectedRecords([{ original: data }]);
                    setEventType(EVENT_TYPES.DELETE);
                  }}
                  title={EVENT_TYPES.DELETE}
                  variant={buttonVariants.dangerFill}
                  id="my-provider-invitation-details-delete"
                />
              )}
          </Flex>
          <Box
            h="40px"
            w="40px"
            borderLeftRadius={"4px"}
            bg="#111A29"
            position={"absolute"}
            left="-41px"
            top="0"
            cursor={"pointer"}
            onClick={handleModalClose}
            display={"flex"}
            justifyContent={"center"}
            verticalAlign={"center"}
            p="10px"
          >
            <CloseIcon
              w="20px"
              display={"inline-block"}
              color="#fff !important"
            />
          </Box>
        </Flex>
      }
    >
      {isDetailModalLoading ? (
        <Loader />
      ) : (
        <Box>
          <Stack>
            <SectionHeading
              icon={MessageIcon}
              heading={"Contact Information"}
            />
            <SimpleGrid mb="24px" columns={3} columnGap={8} rowGap={4}>
              <GridColumn label={"Name"} value={data?.spName} />
              <GridColumn label={"Email Address"} value={data?.spEmail} />
            </SimpleGrid>
          </Stack>

          <Stack m={"2rem 0"}>
            <SectionHeading
              icon={IndustryIcon}
              heading={"Company Information"}
            />
            <SimpleGrid mb="24px" columns={3} columnGap={8} rowGap={4}>
              <GridColumn label={"Company Name"} value={data?.spCompanyName} />
            </SimpleGrid>
          </Stack>

          <Stack m={"1rem 0"}>
            <SectionHeading icon={RocketIcon} heading={"Invitation Overview"} />
            <SimpleGrid mb="24px" columns={3} columnGap={8} rowGap={4}>
              <GridColumn
                label={"Partner Type"}
                value={partnerProgramContent?.name || "-"}
              />
              <GridColumn
                label={"Partner Tier"}
                value={partnerTier?.title || "-"}
              />
              <GridColumn
                label={"Is Featured?"}
                value={data?.isFeatured || "-"}
                capitalized={true}
              />
            </SimpleGrid>
          </Stack>

          <Stack mt={"1.5rem"}>
            <Text
              style={{
                fontSize: "12px",
                fontWeight: "500",
                color: "#5E6977",
              }}
            >
              Invitation Email Preview
            </Text>
            <Box bgColor={"#F9FAFB"} p={"1rem"}>
              <Flex alignItems={"center"} gap={"4px"}>
                <Text
                  as={"span"}
                  fontSize={"14px"}
                  fontWeight={"500"}
                  color={"#5E6977"}
                >
                  Subject :
                </Text>
                <Text as={"span"} fontSize={"14px"} fontWeight={"500"}>
                  {data?.emailSubject}
                </Text>
              </Flex>
              <div
                style={{
                  marginTop: ".5rem",
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "#111A29",
                }}
                dangerouslySetInnerHTML={{
                  __html: data?.emailBody
                    ? replacePlaceholders(data?.emailBody, data)
                    : "",
                }}
              />
            </Box>
          </Stack>
        </Box>
      )}
    </CustomDrawer>
  );
};

const GridColumn = ({ label, value, capitalized }) => (
  <Box>
    <Text
      pb={1}
      style={{ fontSize: "12px", fontWeight: "500", color: "#5E6977" }}
    >
      {label}
    </Text>
    <Text style={{ fontSize: "14px", fontWeight: "600", color: "#111A29" }}>
      {(capitalized && !!value
        ? value[0].toUpperCase() + value.slice(1)
        : value) ?? ""}
    </Text>
  </Box>
);

const SectionHeading = ({ icon, heading }) => (
  <Fragment>
    <Flex justifyContent={"flex-start"} alignItems={"center"} gap={"4px"}>
      <Image src={icon} mr={1} />
      <Text fontWeight={"bold"} fontSize={"14px"} color="#111A29">
        {heading}
      </Text>
    </Flex>
    <Divider />
  </Fragment>
);

export const InvitationStatusPill = ({ inviteStatus }) => {
  const currInvitationStatus = inviteStatus;
  switch (currInvitationStatus) {
    case INVITATION_STATUSES.ACCEPTED:
      return (
        <Tag colorScheme={"green"} borderRadius="full">
          {INVITATION_STATUSES.ACCEPTED}
        </Tag>
      );
    case INVITATION_STATUSES.REJECTED:
      return (
        <Tag colorScheme={"red"} borderRadius="full">
          {INVITATION_STATUSES.REJECTED}
        </Tag>
      );
    case INVITATION_STATUSES.SENT:
      return (
        <Tag colorScheme={"blue"} borderRadius="full">
          New
        </Tag>
      );
    case INVITATION_STATUSES.EXPIRED:
      return (
        <Tag colorScheme={"red"} borderRadius="full">
          {INVITATION_STATUSES.EXPIRED}
        </Tag>
      );

    default:
      return "";
  }
};

export default InvitationDetails;
