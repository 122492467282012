// ProtectedRoute.js
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useNavigate, useLocation, Navigate } from "react-router-dom";
import SidebarWithHeader from "../components/Sidebar";
import BreadCrumb from "../components/BreadCrumb";
import ErrorBoundary from "@coachbar/shared-components/src/screens/Errorboundry";
import Roadmap from "../features/roadmap/Roadmap";
import { compareDates, setUserInfo, updateRoleData } from "../services/utility";
import ClaimProfile from "../features/profileSetup/companyProfile/claimPfofile";
import { axiosInstance } from "../services/axiosInstance";
import { useRBAC } from "../services/customHook";
import { coachbarServicesModule } from "../constants/moduleNames";
import { profileRoute } from "../constants/redirectionRoutes";
import { getUserDetails, updateCurrency } from "../features/auth/authActions";
import { getRoleDetails } from "../features/settings/usersAndRoles/usersAndRolesActions";
import { setUserData } from "../features/auth/authSlice";
import {
  getSelectedCurrencyObj,
  setCurrency,
} from "@coachbar/shared-components/src/services/utility";
import AskCurrencyModel from "@coachbar/shared-components/src/components/CurrencyDropDown/AskCurrencyModel";

const ProtectedRoute = (props) => {
  const { userInfo, userToken } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // useCheckRoadmapStatus();

  const { hasActionPermission } = useRBAC();
  let moduleName = coachbarServicesModule;
  let permissions = {
    add: hasActionPermission(moduleName, "add"),
    edit: hasActionPermission(moduleName, "edit"),
    view: hasActionPermission(moduleName, "view"),
    delete: hasActionPermission(moduleName, "delete"),
  };
  let initialized = false;

  useEffect(() => {
    if (!initialized && userInfo) {
      initialized = true;
      updateRoleData();
    }
  }, []);
  // const roadmapValidationDate = process.env.REACT_APP_ROADMAP_VALIDATION_DATE;
  // const planStartDate = userInfo?.subscriptionStore?.planStartDate;
  // // Checking whether to show Roadmap page or not based on the planStartDate for existing clients
  // const showRoadmap = compareDates(planStartDate, roadmapValidationDate);

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
  }, [userInfo, navigate]);

  // useEffect(() => {
  //   if (userInfo && userInfo.planId !== 1 && showRoadmap) {
  //     fetchRoadmapStatus(userInfo);
  //   }
  // }, [userInfo]);

  const updateRoleData = async () => {
    await getUserDetails().then(async (res) => {
      if (res.code === 200) {
        // if (userInfo?.currencyCode !== res?.data?.user?.currencyCode) {
        setCurrency(getSelectedCurrencyObj(res?.data?.user?.currencyCode));
        // }
        if (userInfo.roleId !== res.data.user.roleId) {
          await getRoleDetails({ id: res.data.user.roleId }, userToken).then(
            (response) => {
              let updatedUserInfo = {
                ...res.data.user,
                roleId: res.data.user.roleId,
                roleData: response.roles,
                role: res.data.user.role,
              };
              setUserInfo(updatedUserInfo);
              dispatch(setUserData());
            }
          );
        } else {
          let updatedUserInfo = {
            ...userInfo,
            ...res.data.user,
          };
          setUserInfo(updatedUserInfo);
          dispatch(setUserData());
        }
      }
    });
  };

  // const fetchRoadmapStatus = async (userInfo) => {
  //   try {
  //     const { data } = await axiosInstance.get(`/roadmap/${userInfo?.planId}`);
  //     // Check if the roadmap is not set
  //     if (!data?.data?.roadmap) {
  //       if (permissions.add && permissions.edit) {
  //         navigate("/roadmap");
  //       }
  //     } else {
  //       if (location.pathname === "/roadmap") {
  //         navigate(profileRoute);
  //       } else {
  //         navigate(`${window.location.pathname}${window.location.search}`);
  //       }
  //     }
  //   } catch (error) {
  //     console.error("Error fetching roadmap status:", error);
  //   }
  // };

  if (!userInfo) return <Navigate to="/" />;

  // if (
  //   location.pathname === "/roadmap" &&
  //   userInfo?.planId !== 1 &&
  //   showRoadmap
  // ) {
  //   return (
  //     <ErrorBoundary isProtectedRoute={true}>
  //       <Roadmap />
  //     </ErrorBoundary>
  //   );
  // }

  if (
    userInfo?.id === userInfo?.tenantId &&
    userInfo?.hasOwnProperty("isClaimed") &&
    userInfo?.isClaimed === false
  ) {
    return (
      <ErrorBoundary isProtectedRoute={true}>
        <ClaimProfile />
      </ErrorBoundary>
    );
  }

  if (["Admin", "Owner"].includes(userInfo?.role) && !userInfo?.currencyCode) {
    return (
      <ErrorBoundary isProtectedRoute={true}>
        <AskCurrencyModel
          onSuccess={(res) => {
            let newUserInfo = { ...userInfo };
            newUserInfo.currencyCode = res?.data?.user?.currencyCode;
            setUserInfo(newUserInfo);
            dispatch(setUserData());
            setCurrency(getSelectedCurrencyObj(res?.data?.user?.currencyCode));
          }}
          updateCurrency={updateCurrency}
          moduleForId="ask-currency"
        />
      </ErrorBoundary>
    );
  }
  // if (
  //   showRoadmap &&
  //   location.pathname === "/roadmap" &&
  //   userInfo.planId !== 1
  // ) {
  //   return null;
  // }

  return (
    <SidebarWithHeader routes={props.routes}>
      {/* <BreadCrumb routes={props.routes} /> */}
      <ErrorBoundary isProtectedRoute={true}>
        <Outlet />
      </ErrorBoundary>
    </SidebarWithHeader>
  );
};

export default ProtectedRoute;
