import sc from "states-cities-db";
import { createStandaloneToast } from "@chakra-ui/react";
import {
  budgetOptionsObj,
  budgetValueObj,
  companyRevenueObj,
  companyRevenueValueObj,
  currenciesList,
  notificationIcons,
} from "../constants/constantValues";
import { axiosInstance } from "../../../partner-app/src/services/axiosInstance";
const { toast } = createStandaloneToast();

const COUNTRIES = sc.getCountries();
const priorityCountriesData = [];
const otherCountriesData = [];
const priorityCountries = ["AUS", "CAN", "SGP", "GBR", "USA", "NZL"];

COUNTRIES.forEach((country) => {
  if (priorityCountries.includes(country.iso)) {
    priorityCountriesData.push(country);
  } else {
    otherCountriesData.push(country);
  }
});
otherCountriesData.sort((a, b) => a.name.localeCompare(b.name));
const sortedCountries = priorityCountriesData.concat(otherCountriesData);

export const formatPriceInUsd = (amount) => {
  const usdFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  });
  const formattedAmount = usdFormat.format(amount);
  return formattedAmount;
};

export const getCountrySlug = (countryName) => {
  return countryName.toLowerCase().replace(/\s+/g, "-");
};

export const getCountryIso2 = (countryName) => {
  const slug = getCountrySlug(countryName);
  return sc.getBySlug("country", slug)?.iso2 || "";
};

export const getCountryIso3 = (countryName) => {
  const slug = getCountrySlug(countryName);
  return sc.getBySlug("country", slug)?.iso || "";
};

export const getCountryList = () => {
  const countryOptions = [];
  sortedCountries.map((country) => {
    countryOptions.push({
      label: country.name,
      value: country.name,
    });
  });
  return countryOptions;
};
export function downloadFromUrl(uri, name, target = null) {
  var link = document.createElement("a");
  link.download = name;
  link.href = uri;
  if (target) link.setAttribute("target", target);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  // delete link;
}

export const notification = (
  title,
  status = "success",
  description = "",
  time = 3000,
  onCloseToast
) => {
  toast({
    title: "",
    description: title || "",
    status: status || "success",
    position: "top",
    duration: time,
    isClosable: true,
    onCloseToast: onCloseToast,
    icon: <img src={notificationIcons[status]} alt="toast" />,
  });
};

export const removeBlankKeys = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key]) && obj[key].length === 0) {
      delete obj[key]; // Remove empty arrays
    } else if (typeof obj[key] === "string" && obj[key].trim() === "") {
      delete obj[key]; // Remove empty strings
    }
  });
  return obj;
};

export const appendHttpsToUrl = (url) => {
  if (url?.length > 0) {
    if (url.startsWith("https://")) return url;
    else return `https://${url}`;
  } else {
    return url;
  }
};

export const getCountriesWithCode = () => {
  let countryOptions = [];
  for (let index = 0; index < sortedCountries.length; index++) {
    let prefix = sortedCountries[index]["prefix"];
    countryOptions.push({
      label: `${sortedCountries[index]["iso"]}(${prefix})`,
      value: `${sortedCountries[index]["iso"]}(${prefix})`,
    });
  }
  return countryOptions;
};

export const getCurrencyRate = async (date, toCurrency, fromCurrency) => {
  try {
    return fromCurrency === toCurrency
      ? 1
      : await fetch(
          `https://api.frankfurter.app/latest?amount=1&from=${fromCurrency}&to=${toCurrency}`
        )
          .then((res) => res.json())
          .then((data) => {
            return data?.rates[toCurrency] || 1;
          });
  } catch (error) {
    return 1;
  }
};

export const setCurrency = (currencyObj) => {
  localStorage.setItem("currency", JSON.stringify(currencyObj));
};

export const getCurrency = () => {
  let currencyObj = localStorage.getItem("currency");
  if (currencyObj) {
    return JSON.parse(currencyObj);
  }
  let obj = getSelectedCurrencyObj();
  return obj;
};

export const getSelectedCurrencyObj = (currencyCode = "USD") => {
  let obj = currenciesList.find((x) => x.code === currencyCode);
  return obj;
};

export const replaceCurrencyInLabel = (label) => {
  let currencyObj = getCurrency();
  return label?.replaceAll("#cur*", currencyObj.symbol);
};

export const budgetConversion = async (budgets, partnerCurrency) => {
  let date = `${new Date().getFullYear()}-${
    new Date().getDate() > 9 ? "" : "0"
  }${new Date().getMonth() + 1}-${
    new Date().getDate() > 9 ? "" : "0"
  }${new Date().getDate()}`;

  if (partnerCurrency === getCurrency().code) return budgets;

  let rate = await getCurrencyRate(date, getCurrency().code, partnerCurrency);
  let budgetsResult = [];
  budgets.forEach((budget) => {
    if (budgetCalculation(budget, rate, budgetsResult))
      budgetsResult.push(budgetCalculation(budget, rate, budgetsResult));
  });
  return budgetsResult;
};

export const budgetCalculation = (budget, rate, arr) => {
  if (budgetOptionsObj[budget]) {
    let amount = budgetValueObj[budget] * rate;
    if (!arr.includes("Less than 5,000") && amount <= 5000) {
      return "Less than 5,000";
    } else if (!arr.includes("More than 50,000") && amount > 50000) {
      return "More than 50,000";
    } else if (
      !arr.includes("5,000 - 10,000") &&
      amount > 5000 &&
      amount <= 10000
    ) {
      return "5,000 - 10,000";
    } else if (
      !arr.includes("10,000 - 30,000") &&
      amount > 10000 &&
      amount <= 30000
    ) {
      return "10,000 - 30,000";
    } else if (
      !arr.includes("30,000 - 50,000") &&
      amount > 30000 &&
      amount <= 50000
    ) {
      return "30,000 - 50,000";
    }
  }
};

export const companyRevenueConversion = async (revenues, partnerCurrency) => {
  let date = `${new Date().getFullYear()}-${
    new Date().getDate() > 9 ? "" : "0"
  }${new Date().getMonth() + 1}-${
    new Date().getDate() > 9 ? "" : "0"
  }${new Date().getDate()}`;

  if (partnerCurrency === getCurrency().code) return revenues;
  let rate = await getCurrencyRate(date, getCurrency().code, partnerCurrency);
  let revenuesResult = [];
  revenues.forEach((budget) => {
    if (revenueCalculate(budget, rate, revenuesResult))
      revenuesResult.push(revenueCalculate(budget, rate, revenuesResult));
  });
  return revenuesResult;
};

export const revenueCalculate = (rev, rate, arr) => {
  if (companyRevenueObj[rev]) {
    let amount = companyRevenueValueObj[rev] * rate;
    if (!arr.includes("Less than 1M/Year") && amount <= 1) {
      return "Less than 1M/Year";
    } else if (!arr.includes("More than 25M/Year") && amount > 25) {
      return "More than 25M/Year";
    } else if (!arr.includes("1-5M/Year") && amount > 1 && amount <= 5) {
      return "1-5M/Year";
    } else if (!arr.includes("5-10M/Year") && amount > 5 && amount <= 10) {
      return "5-10M/Year";
    } else if (!arr.includes("10-25M/Year") && amount > 10 && amount <= 25) {
      return "10-25M/Year";
    }
  }
};

export const getCountryByIp = async () => {
  return await fetch("https://ipinfo.io/json?token=83dba443c92111")
    .then((resp) => resp.json())
    .then((data) => {
      if (data?.country) {
        return (
          currenciesList.find((x) => x.countryCode === data?.country)?.code ||
          "USD"
        );
      }
      return "USD";
    });
};

export const deepCopyObject = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const getCountryByIPOtherAPI = async () => {
  return await fetch("https://api.ipregistry.co/?key=tryout")
    .then((resp) => resp.json())
    .then((data) => {
      return "USD";
    });
};

export const getConvertedCurrencyRange = async (
  rangeArray,
  fromCurrency,
  toCurrency,
  type
) => {
  let { data } = await axiosInstance.post("directory/convert", {
    type: type,
    toBeConvertedList: rangeArray,
    fromCurrencyCode: fromCurrency,
    toCurrencyCode: toCurrency,
  });
  return data?.data?.convertedList || [];
};

export const sortNameColumn = (rowA, rowB) => {
  let name1 = `${rowA.original?.firstName} ${rowA.original?.lastName}` || "";
  let name2 = `${rowB.original?.firstName} ${rowB.original?.lastName}` || "";
  return name1.localeCompare(name2);
};

export const fetchLeadDealStatus = async (portal, type, requestParams) => {
  try {
    const URL = `${
      portal === "sp" ? "sc" : ""
    }/${type}/acceptReject/${requestParams}`;
    const { data } = await axiosInstance.get(URL);
    return data;
  } catch (err) {
    if (err?.response?.data?.code !== 400) {
      notification(err?.response?.data?.message, "error");
    }
    return err?.response?.data;
  }
};

export const getRoundedAmount = (value) => {
  return Number.isInteger(value) ? value : parseFloat(value).toFixed(2);
};
