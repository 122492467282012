import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../services/axiosInstance";
import { getErrorMessage } from "../../services/utility";

export const getTransactionHistory = createAsyncThunk(
  "get/transactionHistory",
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        "/subscription/activity",
        requestData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
