import { axiosInstance } from "../../../services/axiosInstance";
import { handleError } from "../../../services/utility";

export const getListOfAwards = async () => {
  try {
    const { data } = await axiosInstance.get(`/award`);
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};

export const addAward = async (requestParams) => {
  try {
    const { data } = await axiosInstance.post(`/award/add`, {
      ...requestParams,
    });
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};

export const editAward = async (id, requestParams) => {
  try {
    const { data } = await axiosInstance.put(`/award/${id}`, {
      ...requestParams,
    });
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};

export const deleteAward = async (id) => {
  try {
    const { data } = await axiosInstance.delete(`/award/${id}`);
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};
