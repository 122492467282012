import React from "react";
import {
  Textarea,
  FormErrorMessage,
  FormControl,
  FormLabel,
  FormHelperText,
  HStack,
} from "@chakra-ui/react";
import CustomTooltip from "../Tooltip/CustomTooltip";

const TextArea = ({
  validationErrors,
  innerref,
  hideLabel,
  helperText,
  helperTextPosition,
  ...props
}) => {
  let keys = innerref && innerref.name && innerref.name.split(".");
  const validation =
    validationErrors &&
    (keys.length > 1
      ? validationErrors?.[keys[0]]?.[keys[1]]
      : validationErrors?.[keys[0]]);
  return (
    <React.Fragment>
      <FormControl
        name={props.name}
        isInvalid={validation}
        isRequired={props.required}
      >
        {!hideLabel ? (
          <HStack>
            <FormLabel
              textTransform={"capitalize"}
              as="legend"
              fontSize={"12px"}
              fontWeight={600}
              color={"#5E6977"}
              mr={props?.content ? "0px" : "auto"}
            >
              {props.name || props.placeholder}
            </FormLabel>
            {props.content && (
              <>
                <CustomTooltip
                  verticalAlign="inherit"
                  content={props.content}
                />
              </>
            )}
          </HStack>
        ) : null}
        <Textarea
          border={"1px solid #E6E7E9"}
          focusBorderColor={"#0C94AC"}
          boxShadow={"none !important"}
          fontSize={"14px"}
          {...props}
          {...innerref}
          width="100%"
          placeholder={props.placeholder ? props.placeholder : ""}
          css={{
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#E6E7E9",
              borderRadius: "4px",
            },
          }}
        />
        {validation ? (
          <Errors errors={validation} />
        ) : props.hideHelperText ? (
          ""
        ) : (
          <FormHelperText float={helperTextPosition || "none"}>
            {helperText || ""}&nbsp;
          </FormHelperText>
        )}
      </FormControl>
    </React.Fragment>
  );
};

const Errors = ({ errors }) => {
  if (errors.types && Object.entries(errors.types).length > 0) {
    return Object.entries(errors.types).map(([type, message]) => (
      <FormErrorMessage key={type}>{message}</FormErrorMessage>
    ));
  } else {
    return <FormErrorMessage>{errors.message}</FormErrorMessage>;
  }
};

export default TextArea;
