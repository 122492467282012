// ChangePassword.js
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { newPassword } from "./authActions";
import {
  Button,
  TextBox,
  PasswordStrengthIndicator2,
  ShowHidePassword,
} from "@coachbar/shared-components";
import {
  requiredField,
  passwordMisMatch,
} from "../../constants/validationMessages";
import {
  upperCaseValidationPattern,
  lowerCaseValidationPattern,
  specialCharacterValidationPattern,
} from "../../constants/validationPatterns";
import { Stack, Box, Text, InputGroup } from "@chakra-ui/react";
import { decryptData } from "../../services/utility";
import { useClearState } from "../../services/customHook.js";

const NewPassword = ({ setIsResetPasswordSuccess, token }) => {
  const passwordValidationMessages = {
    passwordLength: "Be between 8 and 16 characters",
    specialCharacter: "A special character (#?!@$%^&*-)",
    upperCaseLetter: "An uppercase character",
    lowerCaseLetter: "A lowercase character",
  };
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    watch,
    trigger,
  } = useForm({ mode: "onChange", criteriaMode: "all" });
  const password = useRef({});
  password.current = watch("password", "");
  const confirmPassword = watch("confirmPassword", "");
  useClearState();

  const submitForm = (data) => {
    if (token) {
      const email = decryptData(token);
      let requestParams = {
        newPassword: data.password,
        email: email,
      };
      dispatch(newPassword(requestParams)).then((responseData) => {
        if (responseData.payload && responseData.payload.success) {
          setIsResetPasswordSuccess(true);
        }
      });
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);
  return (
    <form onSubmit={handleSubmit(submitForm)} noValidate>
      <Stack>
        <Text
          fontSize="20px"
          lineHeight={"20px"}
          fontWeight="700"
          mb="8px"
          color="#111A29"
          textAlign="center"
        >
          Create a new password
        </Text>
        <Text
          color="#7E8792"
          fontSize="14px"
          fontWeight={400}
          textAlign="center"
          mb="32px"
          p="0px 18px"
        >
          Please choose a password that hasn’t been used before.
        </Text>

        <InputGroup>
          <TextBox
            type={showPassword ? "text" : "password"}
            name="New Password"
            maxLength="100"
            placeholder="New Password"
            required
            innerref={register("password", {
              required: requiredField.replace("$Field$", "New Password"),
              validate: {
                hasSpecialCharacter: (value) =>
                  specialCharacterValidationPattern.test(value),
                hasUpperCaseLetter: (value) =>
                  upperCaseValidationPattern.test(value),
                hasLowerCaseLetter: (value) =>
                  lowerCaseValidationPattern.test(value),
                hasProperLendth: (value) =>
                  value.length >= 8 && value.length <= 16,
                validateConfirmPass: (value) => {
                  touchedFields.confirmPassword && trigger("confirmPassword");
                  return true;
                },
              },
            })}
            validationErrors={errors}
            errorSpace={false}
          />
          <ShowHidePassword
            handlePasswordVisibility={handlePasswordVisibility}
            showPassword={showPassword}
            id="auth-new-password-visiblity"
          />
        </InputGroup>
        <PasswordStrengthIndicator2
          setMarginTop="4px"
          value={password.current}
          validationErrors={errors.password}
          validationMessages={passwordValidationMessages}
        />
        <Box mt="20px !important">
          <InputGroup>
            <TextBox
              type={showConfirmPassword ? "text" : "password"}
              maxLength="100"
              name="Confirm New Password"
              placeholder="Confirm New Password"
              required
              innerref={register("confirmPassword", {
                required: requiredField.replace("$Field$", "Confirm Password"),
                validate: (value) => {
                  if (!value) return true;
                  if (value === password.current) return true;
                  return passwordMisMatch;
                },
              })}
              validationErrors={errors}
              hideHelperText={
                confirmPassword !== "" && confirmPassword === password.current
              }
            />
            <ShowHidePassword
              handlePasswordVisibility={handleConfirmPasswordVisibility}
              showPassword={showConfirmPassword}
              id="auth-confirm-password-visiblity"
            />
          </InputGroup>
          {confirmPassword !== "" && confirmPassword === password.current && (
            <Text
              color={"palmGreen.600"}
              fontSize={"12px"}
              lineHeight={"18px"}
              fontWeight={450}
            >
              Password matched
            </Text>
          )}
        </Box>
        <Stack spacing={4}>
          <Button
            type="submit"
            variant={"newColorThemePrimary"}
            isLoading={loading}
            title="Set New Password"
            id="new-password-change-password-submit"
          />
        </Stack>
      </Stack>
    </form>
  );
};
export default NewPassword;
