// transactionHistorySlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  getPublishedSystemGrid,
  addIcp,
  getIcps,
  deleteIcp,
  updateIcpStatus,
  getIcp,
  updateIcp,
} from "./icpActions";

const initialState = {
  icpList: [],
  systemGridOptions: [],
  // appStackOptions: [],
  industryListOptions: [],
  statusLoading: false,
  loading: false,
  error: null,
  success: null,
};

const icpSlice = createSlice({
  name: "icp",
  initialState,
  reducers: {
    addIcpToList: (state, action) => {
      state?.icpList?.unshift(action.payload);
    },
    updateListOptions: (state, action) => {
      state.systemGridOptions = action?.payload?.systemGridOptions;
      // state.appStackOptions = action?.payload?.appStackOptions;
      state.industryListOptions = action?.payload?.industriesOptions;
    },
    removeIcpFromList: (state, action) => {
      let icpId = action.payload;
      state.icpList = state.icpList.filter((icp) => icp.id !== icpId);
    },
    clearMessage: (state) => {
      state.success = null;
      state.error = null;
    },
    setError: (state, action) => {
      let errorMsg = action.payload;
      state.error = errorMsg;
    },
    setSuccess: (state, action) => {
      let successMsg = action.payload;
      state.success = successMsg;
    },
  },
  extraReducers: {
    // Get Published System Grid
    [getPublishedSystemGrid.pending]: (state) => {
      // state.loading = true;
      state.error = null;
    },
    [getPublishedSystemGrid.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
    },
    [getPublishedSystemGrid.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload?.code && payload?.code >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },

    // Add Icp
    [addIcp.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addIcp.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
    },
    [addIcp.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },

    // Get All Icps
    [getIcps.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getIcps.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
      if (payload.data && payload.success) {
        state.icpList = payload.data.icpList;
      }
    },
    [getIcps.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },

    // Delete Icp Details
    [deleteIcp.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteIcp.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [deleteIcp.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },

    // Update Icp Status
    [updateIcpStatus.pending]: (state) => {
      // state.loading = true;
      state.error = null;
    },
    [updateIcpStatus.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
      if (payload.data && payload.success) {
        const icpId = payload.data.icp?.id;
        state.icpList = state.icpList.map((icp) => {
          if (icp.id === icpId) {
            icp.active = !icp.active;
          }
          return icp;
        });
      }
    },
    [updateIcpStatus.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },

    // Get Icp Details
    [getIcp.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getIcp.fulfilled]: (state, { payload }) => {
      // state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [getIcp.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },

    // Update Icp
    [updateIcp.pending]: (state) => {
      // state.loading = true;
      state.error = null;
    },
    [updateIcp.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
      if (payload.data && payload.success) {
        const icpData = payload.data?.icp;
        const icpId = payload.data?.icp?.id;
        removeIcpFromList(icpId);
        addIcpToList(icpData);
      }
    },
    [updateIcp.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
  },
});

export const {
  setError,
  setSuccess,
  clearMessage,
  addIcpToList,
  removeIcpFromList,
  updateListOptions,
} = icpSlice.actions;

export default icpSlice.reducer;
