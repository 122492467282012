export const syncDirectionOption = [
  {
    label: "Data syncs between HubSpot and Coachbar",
    value: "Hubspot and Coachbar",
  },
  {
    label: "Data syncs only to HubSpot from Coachbar",
    value: "Coachbar to Hubspot",
  },
  {
    label: "Data syncs only to Coachbar from HubSpot",
    value: "Hubspot to Coachbar",
  },
];

export const directionTextMapping = {
  "Hubspot and Coachbar":
    "Data syncs between Hubspot and Coachbar means that all new and updated object information will be synced between Coachbar and HubSpot. Properties will be merged for records that already exist in both platforms.",
  "Coachbar to Hubspot":
    "Data syncs only to HubSpot from Coachbar means that all new and updated object information will be synced from Coachbar to HubSpot.",
  "Hubspot to Coachbar":
    "Data syncs only to Coachbar from HubSpot means that all new and updated object information will be synced from HubSpot to Coachbar.",
};

export const sourceOption = [
  {
    label: "Default to Coachbar",
    value: "Coachbar",
  },
  {
    label: "Default to HubSpot",
    value: "Hubspot",
  },
];

export const sourceTextMapping = {
  Hubspot:
    "Default HubSpot means that all data in the Coachbar will be overwritten by data from HubSpot.",
  Coachbar:
    "Default Coachbar means that Coachbar data will not be overwritten by HubSpot data. Only new referrals will be created.",
};

export const coachbarFields = [
  { label: "Industry", value: "industry" },
  { label: "Business Type", value: "businessType" },
  { label: "Contact Role", value: "contactRole" },
  { label: "Waiting Time", value: "waitingTime" },
  { label: "Service Required", value: "keyFeatures" },
  { label: "Deployment Preferences", value: "deployment" },
  { label: "Challenges", value: "reason" },
  { label: "Project Duration", value: "timeline" },
  { label: "Evaluated Software", value: "evaluated" },
  { label: "Action Required", value: "actionRequired" },
  { label: "Follow Up Instruction", value: "instructions" },
  { label: "Availability", value: "availability" },
  { label: "Best Way To Approach", value: "approach" },
  { label: "Requirement", value: "overview" },
  { label: "Budget", value: "budget" },
  { label: "Current App Stack", value: "appStack" },
];
