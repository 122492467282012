export const pendingStatus = "Pending";
export const acceptedStatus = "Accepted";
export const rejectedStatus = "Rejected";

export const leadImportFolderName = "/import";
export const leadImportFileNameSc = "Leads+Import+Partner.csv";
export const leadImportFileNameSp = "Leads+Import+Provider.csv";

export const leadsColumnOrder = "leadsColumnOrder";
export const leadsVisibleColumns = "leadsVisibleColumns";

export const LEAD_STATUS = {
  NEW: "New",
  QUALIFIED: "Qualified",
  UNQUALIFIED: "Unqualified",
  WON: "Won",
  LOST: "Lost",
};

export const leadStatuses = [
  LEAD_STATUS.NEW,
  LEAD_STATUS.QUALIFIED,
  LEAD_STATUS.UNQUALIFIED,
  LEAD_STATUS.WON,
  LEAD_STATUS.LOST,
];

export const insidePortalSources = [
  "Manual",
  "Coachbar",
  "Manual Import",
  "Hubspot",
  "Service Request",
];

export const leadStatusColorCodesObj = {
  [LEAD_STATUS.NEW]: {
    status: LEAD_STATUS.NEW,
    background: "var(--chakra-colors-midNight-50)",
    color: "var(--chakra-colors-midNight-700)",
    borderColor: "var(--chakra-colors-midNight-100)",
  },
  [LEAD_STATUS.QUALIFIED]: {
    status: LEAD_STATUS.QUALIFIED,
    background: "var(--chakra-colors-sunRise-50)",
    color: "var(--chakra-colors-sunRise-700)",
    borderColor: "var(--chakra-colors-sunRise-100)",
  },
  [LEAD_STATUS.UNQUALIFIED]: {
    status: LEAD_STATUS.UNQUALIFIED,
    background: "var(--chakra-colors-brandGray-51)",
    color: "var(--chakra-colors-brandGray-700)",
    borderColor: "var(--chakra-colors-brandGray-101)",
  },
  [LEAD_STATUS.WON]: {
    status: LEAD_STATUS.WON,
    background: "var(--chakra-colors-palmGreen-50)",
    color: "var(--chakra-colors-palmGreen-700)",
    borderColor: "var(--chakra-colors-palmGreen-100)",
  },
  [LEAD_STATUS.LOST]: {
    status: LEAD_STATUS.LOST,
    background: "var(--chakra-colors-cherryRed-50)",
    color: "var(--chakra-colors-cherryRed-700)",
    borderColor: "var(--chakra-colors-cherryRed-100)",
  },
};

export const leadStatusColorCodes = [
  leadStatusColorCodesObj[LEAD_STATUS.NEW],
  leadStatusColorCodesObj[LEAD_STATUS.QUALIFIED],
  leadStatusColorCodesObj[LEAD_STATUS.UNQUALIFIED],
  leadStatusColorCodesObj[LEAD_STATUS.WON],
  leadStatusColorCodesObj[LEAD_STATUS.LOST],
];

export const LEAD_DEAL_TEMPLATE_FOLDER_NAME = "LeadDeal";

export const LEAD_TEMPLATE_PARAMETER = [
  "",
  "[Lead ID]",
  "[Lead Name]",
  "[Lead Email]",
  "[Lead Company]",
  "[Lead Source]",
  "[Lead Created Date]",
  "[Lead Status]",
  "[Lead Owner]",
  "[Partner User First/Last Name]",
  "[Partner Company Name]",
  "[Accept Link]",
  "[Reject Link]",
];
