import { Flex, Stack } from "@chakra-ui/react";

const AuthRightPanel = (props) => {
  return (
    <Flex
      flex={props.width ? props.width : "1 1 65%"}
      align={"center"}
      justify={"center"}
      position={props.position || "static"}
    >
      <Stack
        spacing={4}
        w={"full"}
        maxW={props.widthXl ? props.widthXl : "md"}
        m="10px"
      >
        {props.children}
      </Stack>
    </Flex>
  );
};

export default AuthRightPanel;
