import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Text,
  Stack,
  Box,
  InputGroup,
  Image,
  Alert,
  AlertDescription,
} from "@chakra-ui/react";
import {
  Button,
  TextBox,
  Link,
  Loader,
  ShowHidePassword,
  PasswordStrengthIndicator,
  AuthRightPanel,
  AuthLeftPanel,
} from "@coachbar/shared-components";
import {
  emailValidationPattern,
  upperCaseValidationPattern,
  lowerCaseValidationPattern,
  specialCharacterValidationPattern,
} from "../../constants/validationPatterns";
import {
  requiredField,
  invalidEmail,
  passwordMisMatch,
  lowerCaseLetter,
  upperCaseLetter,
  specialCharacter,
  passwordMinLength,
  passwordMaxLength,
} from "../../constants/validationMessages";
import AuthPanelRight from "@coachbar/shared-components/src/screens/AuthPanelRight";
import linkExpired from "../../image/link-expired.png";
import iconWarning from "../../image/icon-warning.png";
import { acceptInvite, verifyInvite } from "./authActions";
import { notification } from "../../services/utility";

const AcceptInvitation = () => {
  const { loading: authLoader, userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    criteriaMode: "all",
    mode: "onChange",
  });
  const passwordValidationMessages = {
    lowerCaseLetter,
    upperCaseLetter,
    specialCharacter,
    passwordMinLength,
    passwordMaxLength,
  };
  const password = useRef({});
  password.current = watch("password", "");
  const search = useLocation().search;
  const code = new URLSearchParams(search).get("code");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [verifyInviteData, setVerifyInviteData] = useState();
  const [isLinkValid, setIsLinkValid] = useState(true);
  const [loading, setLoading] = useState(true);

  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  const handleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      setLoading(true);
      verifyInvite({ id: code }).then((responseData) => {
        if (responseData.code && responseData.code === 200) {
          setVerifyInviteData(responseData.data);
          setValue("email", responseData.data.email);
          setLoading(false);
        } else if (responseData.code && responseData.code === 404) {
          notification(responseData.data.message, "error");
          setIsLinkValid(false);
          setLoading(false);
        } else if (responseData.data.code && responseData.data.code === 400) {
          notification(responseData.data.message, "error");
          sessionStorage.setItem(
            "spFirstName",
            responseData.data.data?.firstName
          );
          navigate("/login");
        }
      });
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      navigate("/dashboard");
    }
  }, [navigate, userInfo]);

  const submitForm = (data) => {
    let requestData = {
      email: verifyInviteData.email,
      password: data.confirmPassword,
      id: verifyInviteData.id,
    };
    dispatch(acceptInvite(requestData));
  };

  return (
    <>
      <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
        <AuthLeftPanel />
        <AuthRightPanel width={"1 1 calc(100% - 430px)"} widthXl="470px">
          {loading ? (
            <Loader />
          ) : isLinkValid ? (
            <form onSubmit={handleSubmit(submitForm)} noValidate>
              <Stack spacing={1}>
                <Text
                  fontSize="20px"
                  lineHeight={"20px"}
                  fontWeight="700"
                  mb={"32px !important"}
                  color="var(--title-black-text)"
                  textAlign="center"
                >
                  Welcome, {verifyInviteData?.firstName}!
                </Text>
                <TextBox
                  type="text"
                  name="Work email"
                  isDisabled={true}
                  bg="brandGray.50"
                  opacity={"1 !important"}
                  innerref={register("email")}
                />

                <InputGroup>
                  <TextBox
                    type={showPassword ? "text" : "password"}
                    name="Create Password"
                    maxLength="100"
                    placeholder="Create a Password"
                    mb={password.current && errors.password ? "1rem" : "none"}
                    required
                    innerref={register("password", {
                      required: requiredField.replace("$Field$", "Password"),
                      validate: {
                        hasSpecialCharacter: (value) =>
                          specialCharacterValidationPattern.test(value),
                        hasUpperCaseLetter: (value) =>
                          upperCaseValidationPattern.test(value),
                        hasLowerCaseLetter: (value) =>
                          lowerCaseValidationPattern.test(value),
                        hasMinLength: (value) => value.length >= 8,
                        hasMaxLength: (value) => value.length <= 16,
                      },
                    })}
                    validationErrors={errors}
                  />
                  <ShowHidePassword
                    handlePasswordVisibility={handlePasswordVisibility}
                    showPassword={showPassword}
                    id="accept-invitation-create-password-visiblity"
                  />
                </InputGroup>
                {password.current ? (
                  <PasswordStrengthIndicator
                    value={password.current}
                    validationErrors={errors.password}
                    validationMessages={passwordValidationMessages}
                  />
                ) : null}

                <InputGroup>
                  <TextBox
                    type={showConfirmPassword ? "text" : "password"}
                    maxLength="100"
                    placeholder="Confirm Password"
                    required
                    innerref={register("confirmPassword", {
                      required: requiredField.replace(
                        "$Field$",
                        "Confirm Password"
                      ),
                      validate: (value) =>
                        value === password.current || passwordMisMatch,
                    })}
                    validationErrors={errors}
                  />
                  <ShowHidePassword
                    handlePasswordVisibility={handleConfirmPasswordVisibility}
                    showPassword={showConfirmPassword}
                    id="accept-invitation-confirm-password-visiblity"
                  />
                </InputGroup>
                <Button
                  type="submit"
                  isLoading={authLoader}
                  title="Login"
                  variant={"newColorThemePrimary"}
                  id="accept-invitation-login-submit"
                />
              </Stack>
            </form>
          ) : (
            <Box textAlign={"center"}>
              <Image
                src={linkExpired}
                alt={"link expired"}
                w={"160px"}
                mx={"auto"}
                mb={"30px"}
              />
              <Text
                fontSize="20px"
                lineHeight={"20px"}
                fontWeight="700"
                mb={"32px !important"}
              >
                Ups, we are sorry, but the invitation is has expired!
              </Text>
              <Alert status="error" bg={"#FDE8E8"} borderRadius={"4px"}>
                <Image
                  src={iconWarning}
                  alt={"link expired"}
                  w={"16px"}
                  mr={2}
                />
                <AlertDescription
                  fontSize={"12px"}
                  fontWeight={500}
                  color={"#E02424"}
                >
                  Invitation link has expired. Please contact your company owner
                  or <Link to="/login" title="Login" />
                </AlertDescription>
              </Alert>
            </Box>
          )}
        </AuthRightPanel>
      </Stack>
    </>
  );
};

export default AcceptInvitation;
