import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  FormControl,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { TextBox } from "@coachbar/shared-components";
import { useForm } from "react-hook-form";
import {
  PauseCircle,
  PlayCircle,
} from "@coachbar/shared-components/src/images";
import {
  buttonVariants,
  customStylesForReactSelect,
  menuItemStyles,
} from "@coachbar/shared-components/src/constants/constantValues";
import { DayPicker } from "react-day-picker";
import { addDays, format } from "date-fns";
import CustomModal from "@coachbar/shared-components/src/components/Modal/Modal";
import useDebounce from "@coachbar/shared-components/src/hooks/useDebounce";
import {
  addGeneralSettings,
  addPartnerAvailability,
  editGeneralSettings,
  GENERAL_SETTING_API_ROUTES,
  getGeneralSettings,
  getPartnerAvailability,
  updatePartnerAvailability,
} from "./generalSettingsActions";
import { notification } from "@coachbar/shared-components/src/services/utility";
import CustomReactSelect from "@coachbar/shared-components/src/components/Select/CustomReactSelect";
import CustomTooltip from "@coachbar/shared-components/src/components/Tooltip/CustomTooltip";
import InfoOutline from "../../image/Info-outline.svg";

const RESPONSE_TIME = [
  { label: "Less than a week", value: "Less than a week" },
  { label: "1 - 2 weeks", value: "1 - 2 weeks" },
  { label: "2 - 4 weeks", value: "2 - 4 weeks" },
  { label: "4 - 6 weeks", value: "4 - 6 weeks" },
  { label: "6 - 9 weeks", value: "6 - 9 weeks" },
  { label: "9 - 12 weeks", value: "9 - 12 weeks" },
  { label: "More than 12 weeks", value: "More than 12 weeks" },
];

const LeadDealSettings = () => {
  const [leadDealGeneralSetting, setLeadDealGeneralSetting] = useState({});
  const [partnerAvailability, setPartnerAvailability] = useState({});
  const [fetchedData, setFetchedData] = useState(false);
  const [selectedResponseTime, setSelectedResponseTime] = useState([]);
  const [settingLoading, setSettingLoading] = useState(false);
  const [availabilityLoader, setAvailabilityLoader] = useState(null);

  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      leadDealCapacity: "",
      pauseDurationDate: new Date(),
    },
  });

  const {
    isOpen: isPauseBtnOpen,
    onClose: onPauseBtnClose,
    onOpen: onPauseBtnOpen,
  } = useDisclosure();
  const {
    isOpen: isCustomDateOpen,
    onClose: onCustomDateClose,
    onOpen: onCustomDateOpen,
  } = useDisclosure();

  const leadDealCapacity = watch("leadDealCapacity");
  const pauseDurationDate = watch("pauseDurationDate");

  const delayedLeadDealCapacity = useDebounce(leadDealCapacity, 300);

  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      getLeadDealSettingData();
      getPartnerAvailabilityData();
    }
  }, []);

  useEffect(() => {
    if (
      fetchedData &&
      leadDealGeneralSetting?.leadDealCapacityPerMonth !=
        delayedLeadDealCapacity
    )
      handleLeadDealCapacity(delayedLeadDealCapacity);
  }, [delayedLeadDealCapacity]);

  const getLeadDealSettingData = () => {
    getGeneralSettings(GENERAL_SETTING_API_ROUTES.LEAD_DEAL)
      .then((res) => {
        if (res?.data?.leadDealSetting)
          setLeadDealGeneralSetting(res.data.leadDealSetting);
        setLeadDealGeneralSettingData(res.data.leadDealSetting);
      })
      .finally(() => setFetchedData(true));
  };

  const setLeadDealGeneralSettingData = (leadDealGeneralSetting) => {
    if (leadDealGeneralSetting?.id) {
      if (leadDealGeneralSetting?.leadDealCapacityPerMonth)
        setValue(
          "leadDealCapacity",
          leadDealGeneralSetting.leadDealCapacityPerMonth
        );
      if (Array.isArray(leadDealGeneralSetting?.leadDealResponseTimes))
        setSelectedResponseTime(
          leadDealGeneralSetting.leadDealResponseTimes.map((responseTime) => ({
            label: responseTime,
            value: responseTime,
          }))
        );
    }
  };

  const getPartnerAvailabilityData = () => {
    getPartnerAvailability().then((res) => {
      if (res?.data?.partnerAvailabilityDetail) {
        setPartnerAvailability(res.data.partnerAvailabilityDetail);
      } else setPartnerAvailability({});
      setAvailabilityLoader(false);
    });
  };

  const handleLeadDealCapacity = async (capacity) => {
    setSettingLoading(true);
    if (!errors.leadDealCapacity) {
      const requestParams = getLeadDealSettingPayload(
        capacity,
        selectedResponseTime
      );
      let res = {};
      if (requestParams?.id) {
        res = await editGeneralSettings(
          GENERAL_SETTING_API_ROUTES.LEAD_DEAL,
          requestParams.id,
          requestParams
        );
      } else {
        res = await addGeneralSettings(
          GENERAL_SETTING_API_ROUTES.LEAD_DEAL,
          requestParams
        );
      }
      if (res?.success) {
        notification(res.message);
        setLeadDealGeneralSetting(res.data.leadDealSetting);
      }
    }
    setSettingLoading(false);
  };

  const handleResponseTime = async (selectedResponseTimes) => {
    setSelectedResponseTime(selectedResponseTimes);
    setSettingLoading(true);

    const requestParams = getLeadDealSettingPayload(
      delayedLeadDealCapacity,
      selectedResponseTimes
    );
    let res = {};
    if (requestParams?.id) {
      res = await editGeneralSettings(
        GENERAL_SETTING_API_ROUTES.LEAD_DEAL,
        requestParams.id,
        requestParams
      );
    } else {
      res = await addGeneralSettings(
        GENERAL_SETTING_API_ROUTES.LEAD_DEAL,
        requestParams
      );
    }
    if (res?.success) {
      notification(res.message);
      setLeadDealGeneralSetting(res.data.leadDealSetting);
    }
    setSettingLoading(false);
  };

  const handleAvalability = async (date) => {
    setAvailabilityLoader(true);
    let res = {};
    if (date) {
      const formatedDate = format(date, "yyyy-MM-dd HH:mm:ss");
      const requestParams = {
        isPaused: true,
        pauseStartDate: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        pauseEndDate: formatedDate,
      };
      if (partnerAvailability?.id) {
        res = await updatePartnerAvailability(
          partnerAvailability.id,
          requestParams
        );
      } else {
        res = await addPartnerAvailability(requestParams);
      }
    } else {
      res = await updatePartnerAvailability(partnerAvailability.id, {
        isPaused: false,
      });
    }
    if (res?.success) {
      notification(res.message);
      if (date) {
        res?.data?.partnerAvailabilityDetail &&
          setPartnerAvailability(res?.data?.partnerAvailabilityDetail);
      } else setPartnerAvailability({});
    }
    setAvailabilityLoader(false);
  };

  const getLeadDealSettingPayload = (capacity, responseTimes) => {
    const requestParams = {};
    if (leadDealGeneralSetting?.id)
      requestParams.id = leadDealGeneralSetting?.id;
    if (responseTimes?.length)
      requestParams.leadDealResponseTimes = responseTimes.map(
        (item) => item.value
      );
    if (capacity) requestParams.leadDealCapacityPerMonth = capacity;
    return requestParams;
  };

  return (
    <React.Fragment>
      <Card p="16px 24px 24px">
        <CardHeader
          display={"flex"}
          p="0px"
          pb="16px"
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text
            fontSize={"14px"}
            lineHeight={"20px"}
            color={"atlantic.700"}
            fontWeight={700}
          >
            Capacity Settings
          </Text>
          {availabilityLoader !== null &&
            (partnerAvailability?.isPaused ? (
              <Button
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                variant={buttonVariants.brandPrimaryOutline}
                gap={"8px"}
                height={"36px"}
                fontWeight={600}
                onClick={() => handleAvalability(null)}
                isDisabled={availabilityLoader}
              >
                <PlayCircle
                  fill="none"
                  stroke="var(--chakra-colors-cyprus-500)"
                  height="16px"
                  width="16px"
                />
                Resume Leads/Deals Acceptance
              </Button>
            ) : (
              <Menu
                isOpen={isPauseBtnOpen}
                onClose={onPauseBtnClose}
                onOpen={onPauseBtnOpen}
                placement="bottom-end"
              >
                <MenuButton>
                  <Button
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    variant={buttonVariants.dangerOutline}
                    gap={"8px"}
                    height={"36px"}
                    fontWeight={600}
                    isDisabled={availabilityLoader}
                  >
                    <PauseCircle
                      fill="none"
                      stroke="var(--chakra-colors-cherryRed-700)"
                    />
                    Pause Leads/Deals Acceptance
                  </Button>
                </MenuButton>
                <MenuList
                  zIndex={"popover"}
                  fontSize={"14px"}
                  letterSpacing={"0.14px"}
                  color={"#111A29"}
                  border={"1px solid #E6E7E9"}
                  rounded={"4px"}
                  p={"6px"}
                  w={"255px"}
                >
                  <MenuItem
                    {...menuItemStyles}
                    onClick={() => {
                      handleAvalability(addDays(new Date(), 1));
                    }}
                  >
                    Pause for 1 day
                  </MenuItem>
                  <MenuItem
                    {...menuItemStyles}
                    onClick={() => {
                      handleAvalability(addDays(new Date(), 7));
                    }}
                  >
                    Pause for 1 week
                  </MenuItem>
                  <MenuItem {...menuItemStyles} onClick={onCustomDateOpen}>
                    Custom
                  </MenuItem>
                </MenuList>
              </Menu>
            ))}
        </CardHeader>
        <Divider
          borderRadius={"5px"}
          opacity={0.6}
          backgroundColor={"brandGray.101"}
          height={"2px"}
          border={"none"}
        />
        <CardBody p={0}>
          <Box
            m="24px 0 0"
            mb={errors.leadDealCapacity ? "1.6px" : "16px"}
            width={{ base: "100%", lg: "50%" }}
            pos={"relative"}
          >
            <TextBox
              name="Monthly Lead and Deals Capacity"
              innerref={register("leadDealCapacity", {
                validate: (val) => {
                  const capacity = parseFloat(val);
                  if (capacity > 2147483647) return "Maximum limit exceed.";
                  if (
                    val === "" ||
                    (capacity >= 0 &&
                      capacity <= 2147483647 &&
                      Math.floor(capacity) === Math.ceil(capacity))
                  )
                    return true;
                  return "Enter positive number.";
                },
              })}
              validation={true}
              validationErrors={errors}
              showPlaceHolder={true}
              placeholder={"Enter number of leads you can handle per month"}
              type="number"
              width={{ base: "100%", lg: "50%" }}
              disabled={settingLoading || !fetchedData}
              hideHelperText
            />
            {settingLoading && (
              <Box position={"absolute"} top="32px" right={"12px"}>
                <Spinner size="sm" color="#0C94AC" />
              </Box>
            )}
          </Box>
          <FormControl
            isInvalid={true}
            width={{ base: "100%", lg: "50%" }}
            css={{
              span: {
                height: "16px",
              },
            }}
          >
            <CustomReactSelect
              closeMenuOnSelect={false}
              options={RESPONSE_TIME}
              value={selectedResponseTime}
              onChange={handleResponseTime}
              styles={customStylesForReactSelect}
              name={"responseTime"}
              label={
                <Flex gap={"4px"} alignItems={"center"}>
                  <Text>Lead/Deal Response Time</Text>

                  <CustomTooltip
                    content={
                      "Choose the time frame within which you can contact a lead after receiving it."
                    }
                    placement="right"
                    width="auto"
                  >
                    <Image
                      display={"inline-block"}
                      cursor={"pointer"}
                      src={InfoOutline}
                      w={"16px"}
                    />
                  </CustomTooltip>
                </Flex>
              }
              customPlaceholder={"Select response time"}
              isSearchable={true}
              isDisabled={!fetchedData}
              isMulti
            />
          </FormControl>
        </CardBody>
      </Card>
      <CustomModal
        isOpen={isCustomDateOpen}
        onClose={onCustomDateClose}
        title={"Pause Leads/Deals Acceptance Until"}
        allowCloseButton={true}
        displayFooter={false}
        size={"sm"}
        padding="8px 24px 24px"
        moduleForId={"lead-deal-setting"}
      >
        <DayPicker
          required
          mode={"single"}
          selected={pauseDurationDate}
          onSelect={(e) => {
            setValue("pauseDurationDate", e);
          }}
          disabled={{ before: new Date() }}
        />
        <HStack justifyContent={"center"} mb={"1rem"}>
          <Button
            onClick={() => {
              onCustomDateClose();
              handleAvalability(pauseDurationDate);
            }}
            variant={"specializationButton"}
            id={"pause-lead-deal-duration-apply"}
          >
            Apply
          </Button>
          <Button
            onClick={onCustomDateClose}
            variant={"specializationButton"}
            id={"pause-lead-deal-duration-apply-cancel"}
          >
            Cancel
          </Button>
        </HStack>
      </CustomModal>
    </React.Fragment>
  );
};

export default LeadDealSettings;
