export const companyProfile = "companyProfile";
export const referral = "scReferral";
export const subUser = "subUser";
export const systemGrid = "systemGrid";
export const serviceIndustries = "serviceIndustries";
export const services = "services";
export const clientsModule = "client";
export const icpModule = "icp";
export const specializationModule = "specialization";
export const providers = "partnerProviderList";
export const subscriptionModule = "subscription";
export const coachbarServicesModule = "coachbarService";
export const bankModule = "bank";
export const award = "award";
export const affiliation = "affiliation";
export const partnerStatus = "partnerStatus";
export const invitationsModule = "scInvitation";
export const settingModule = "setting";
export const generalSetting = "generalSetting";
export const integration = "integration";
export const transactionHistory = "transactionHistory";
export const publishProfileAccess = "publishProfile";
export const leadsModule = "Leads";
export const dealsModule = "Deals";
export const lmsModule = "Lms";
export const steps = [
  { title: "Basic Information" },
  { title: "Company Location" },
  { title: "Video Gallery" },
  { title: "Media Gallery" },
  { title: "URL Directory" },
  { title: "Affiliations" },
  { title: "List Of Awards" },
  { title: "Partner Status" },
];
export const incentivesModule = "incentives";
export const contractsModule = "contract";
export const rewardsModule = "commission";
