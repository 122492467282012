import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Button, Loader, Modal } from "@coachbar/shared-components";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { subscriptionModule } from "../../constants/moduleNames";
import {
  planRoute,
  subscriptionRoute,
} from "../../constants/redirectionRoutes";
import CheckVerified from "../../image/check-verified-new.png";
import { useRBAC } from "../../services/customHook";
import {
  getErrorMessage,
  getUserInfo,
  setSubscriptionStorage,
  setUserInfo,
} from "../../services/utility";
import { getPlanDetail } from "../auth/authActions";
import { setUserData } from "../auth/authSlice";
import { getPredefinedCredit } from "../roadmap/roadmapActions";
import { purchaseService } from "../subscription/subscriptionActions";

const RedeemService = (props) => {
  const { hasActionPermission } = useRBAC();
  let moduleName = subscriptionModule;
  let subscriptionPermissions = {
    add: hasActionPermission(moduleName, "add"),
    edit: hasActionPermission(moduleName, "edit"),
    view: hasActionPermission(moduleName, "view"),
    delete: hasActionPermission(moduleName, "delete"),
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [planDetails, setPlanDetails] = useState([]);
  const [creditType, setCreditType] = useState("Extra");
  const { prebookCredit } = useSelector((state) => state.roadmap);
  const subscriptionDetails = userInfo.subscriptionStore;
  const remainingCredits =
    subscriptionDetails?.allocatedCredits -
    (subscriptionDetails?.usedCredits +
      (subscriptionDetails?.expiredCredits ?? 0));

  let initialized = false;

  useEffect(() => {
    if (!initialized) {
      initialized = true;
      if (userInfo?.planId && userInfo?.planId === 1) {
        dispatch(getPlanDetail()).then((responseData) => {
          const response = responseData.payload;
          if (
            response &&
            response.success &&
            response.data &&
            response.data.subscriptionPlans
          ) {
            setPlanDetails(response.data.subscriptionPlans);
          }
        });
      }
    }
  }, [dispatch]);

  const buyService = () => {
    setIsLoading(true);
    const totalCredit =
      props?.selectedSevice?.quantity * props.selectedSevice.credit;
    let requestData = {
      creditUsage: [
        {
          componentId: props.selectedSevice.id,
          componentName: props.selectedSevice.title,
          quantity: props?.selectedSevice?.quantity || 1,
          creditPrice: props.selectedSevice.credit,
          type: props.selectedSevice.type,
        },
      ],
      totalQuantity: props?.selectedSevice?.quantity || 1,
      totalCreditPrice: props.selectedSevice?.quantity ? totalCredit : 1,
    };
    if (props.selectedSevice.referralId) {
      requestData.creditUsage[0].referralId = props.selectedSevice.referralId;
    }
    if ([7, 8].includes(props.selectedSevice.id) && creditType === "Prebook") {
      requestData.UsePrebookCredit = true;
    }
    if (props.selectedSevice.id == 5) {
      requestData.UsePrebookCredit = true;
    }
    purchaseService(requestData)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.success &&
          response.data.data
        ) {
          let userDetails = getUserInfo();
          userDetails["subscriptionStore"] =
            response.data.data.subscriptionStore;
          setUserInfo(userDetails);
          dispatch(setUserData());
          if (props.selectedSevice.setSuccess) {
            dispatch(props.selectedSevice.setSuccess(response?.data?.message));
          }
          if (props.performExtraAction) {
            props.performExtraAction();
          }
          if (requestData.UsePrebookCredit) {
            dispatch(getPredefinedCredit());
          }
        } else if (
          response &&
          response.response &&
          response.response.data &&
          response.response.data.code &&
          response.response.data.code === 402
        ) {
          if (props.selectedSevice.setError) {
            dispatch(
              props.selectedSevice.setError(
                getCreditErrorMessage(response.response.data.message)
              )
            );
          }
        } else {
          if (props.selectedSevice.setError) {
            dispatch(props.selectedSevice.setError(getErrorMessage(response)));
          }
        }
        setIsLoading(false);
        props.onClose();
      })
      .catch(() => {
        setIsLoading(false);
        props.onClose();
      });
  };

  const { price, leaderPlanBnplPrice } = useMemo(() => {
    const leaderPlan = planDetails.find((plan) => plan.planId === 10);
    const leaderPlanBnpl = planDetails.find((plan) => plan.planId === 11);

    return {
      price: leaderPlan?.price || 0,
      leaderPlanBnplPrice: leaderPlanBnpl?.price || 0,
    };
  }, [planDetails]);

  const getCreditErrorMessage = () => {
    return (
      <Text>
        {subscriptionPermissions.add
          ? "There aren't enough credits to redeem. Please purchase "
          : "There aren't enough credits to redeem. Please contact to owner of this account."}
        {subscriptionPermissions.add ? (
          <>
            <Text
              as="span"
              cursor={"pointer"}
              textDecoration={"underline"}
              onClick={() => {
                redirectToSubscription(subscriptionRoute);
              }}
            >
              {" "}
              additional credits
            </Text>{" "}
            {userInfo?.planId < 10 && (
              <>
                or{" "}
                <Text
                  as="span"
                  cursor={"pointer"}
                  textDecoration={"underline"}
                  onClick={() => {
                    redirectToSubscription(planRoute);
                  }}
                >
                  upgrade
                </Text>
                your subscription plan
              </>
            )}
          </>
        ) : (
          ""
        )}
      </Text>
    );
  };

  const redirectToSubscription = (path) => {
    navigate(path);
  };

  const changeplan = (selectedPlan) => {
    if (selectedPlan.publicSignUpPage) {
      setSubscriptionStorage(selectedPlan.planId);
      window.open(selectedPlan.publicSignUpPage, "_self");
      props.onClose();
      // navigate(subscriptionRoute);
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={
        userInfo?.planId &&
        userInfo?.planId === 1 &&
        subscriptionPermissions.add
          ? ""
          : `Redeem ${props.selectedSevice.title}`
      }
      saveButtonTitle={"Redeem"}
      allowCloseButton={userInfo?.planId === 1 ? false : true}
      headerWithIcon={
        userInfo?.planId &&
        userInfo?.planId === 1 &&
        subscriptionPermissions.add
          ? false
          : true
      }
      headerIcon={CheckVerified}
      displayFooter={
        userInfo?.planId &&
        userInfo?.planId === 1 &&
        subscriptionPermissions.add
          ? false
          : true
      }
      size={
        userInfo?.planId &&
        userInfo?.planId === 1 &&
        subscriptionPermissions.add
          ? "xs"
          : "md"
      }
      onSaveClick={buyService}
      loading={isLoading}
      padding={userInfo?.planId === 1 ? "0px" : ""}
      applyMaxContent
    >
      {userInfo?.planId &&
      userInfo?.planId === 1 &&
      subscriptionPermissions.add ? (
        <Flex justifyContent={"center"} gap={"20px"} wrap={["wrap", "nowrap"]}>
          {planDetails.length > 0 ? (
            planDetails.map((plan) => {
              return plan.planId !== 1 && plan.isBNPL === false ? (
                <Flex
                  key={plan.planId}
                  p={"16px"}
                  rounded={"8px"}
                  flexDirection={"column"}
                >
                  <Flex
                    mb={"16px"}
                    justifyContent={"space-between"}
                    alignItems={"flex-start"}
                  >
                    <Text
                      fontSize={"16px"}
                      fontWeight={700}
                      color={"var(--title-black-text)"}
                    >
                      {plan.name}
                    </Text>
                    <CloseIcon
                      boxSize={"12px"}
                      cursor={"pointer"}
                      onClick={props.onClose}
                    />
                  </Flex>
                  <Box>
                    <Flex alignItems={"baseline"} mb={"10px"}>
                      <Text
                        fontSize={"24px"}
                        fontWeight={700}
                        mr={"8px"}
                        color={"var(--title-black-text)"}
                      >
                        $
                        {(plan.planId === 10 && leaderPlanBnplPrice) ||
                          plan?.price}
                      </Text>
                      <Text fontSize="12px" fontWeight={400} color={"#5E6977"}>
                        per year
                      </Text>
                    </Flex>
                    <Text
                      display={"inline-block"}
                      fontSize={"12px"}
                      fontWeight={600}
                      color={"var(--title-black-text)"}
                      border={"1px solid #DEE8F8"}
                      bg={"#F3F7FF"}
                      rounded={"16px"}
                      p={"4px 10px"}
                    >
                      {plan.credit} Credits Included
                    </Text>
                    <Divider border={"1px solid #E6E7E9"} mt={"16px"} />
                    {plan.planId !== userInfo.planId &&
                    (userInfo.planId > 5
                      ? plan.planId > userInfo.planId - 4
                      : plan.planId > userInfo.planId) ? (
                      <Flex flexGrow={1} alignItems={"flex-end"} mt={"16px"}>
                        <Box w={"full"}>
                          <Button
                            type="submit"
                            title={`Buy Now & Save $${
                              leaderPlanBnplPrice - price
                            }`}
                            variant={"brandPrimary"}
                            w="100%"
                            onClick={() => changeplan(plan)}
                            id="redeem-service-buy-now"
                          />
                          <Text
                            color={"var(--chakra-colors-cyprus-500)"}
                            fontSize={"14px"}
                            fontWeight={700}
                            textAlign={"center"}
                            cursor={"pointer"}
                            mt={"16px"}
                            onClick={() =>
                              // for now we are only showing one plan so we are sending BNPL plan data by adding static condition
                              changeplan(
                                planDetails?.find((plan) => plan?.planId === 11)
                              )
                            }
                          >
                            Upgrade Now & Pay Later
                          </Text>
                        </Box>
                      </Flex>
                    ) : (
                      ""
                    )}
                  </Box>
                </Flex>
              ) : (
                ""
              );
            })
          ) : (
            <Loader />
          )}
        </Flex>
      ) : (
        <Box>
          <Text
            color={"var(--title-black-text)"}
            fontSize={"14px"}
            fontWeight={400}
          >
            Are you sure you want to redeem this coachbar service for{" "}
            {props.selectedSevice?.quantity
              ? props.selectedSevice?.credit * props.selectedSevice?.quantity
              : 1}{" "}
            credit(s) ?
          </Text>
          <Text
            color={"var(--title-black-text)"}
            fontSize={"14px"}
            fontWeight={400}
            mt={"16px"}
          >
            Remaining Credits:{" "}
            <span
              style={{
                color: remainingCredits < 2 ? "#E02424" : "#0C94AC",
                fontWeight: 600,
              }}
            >
              {remainingCredits}
            </span>
          </Text>

          {(props.selectedSevice?.id === 7 &&
            prebookCredit?.verifiedReferral > 0) ||
          (props.selectedSevice?.id === 8 &&
            prebookCredit?.unVerifiedReferral > 0) ? (
            <RadioGroup onChange={setCreditType} value={creditType} mt={"16px"}>
              <Stack spacing={5} direction="row">
                <Radio value="Extra">
                  <Text
                    color={"var(--title-black-text)"}
                    fontSize={"14px"}
                    fontWeight={400}
                  >
                    Redeem with {props.selectedSevice?.credit} credit(s)
                  </Text>
                </Radio>
                <Radio value="Prebook">
                  <Text
                    color={"var(--title-black-text)"}
                    fontSize={"14px"}
                    fontWeight={400}
                  >
                    Utilize booked referral
                  </Text>
                </Radio>
              </Stack>
            </RadioGroup>
          ) : (
            ""
          )}
        </Box>
      )}
    </Modal>
  );
};

export default RedeemService;
