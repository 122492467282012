import { Box, Center } from "@chakra-ui/react";
import { Loader } from "@coachbar/shared-components";
import ChartLegends from "@coachbar/shared-components/src/components/Charts/ChartLegends";
import CustomFunnel from "@coachbar/shared-components/src/components/Charts/CustomFunnel";
import ChartContainer from "@coachbar/shared-components/src/screens/dashboard/ChartContainer";
import ChartHeader from "@coachbar/shared-components/src/screens/dashboard/ChartHeader";
import { useChartsProvider } from "@coachbar/shared-components/src/screens/dashboard/chartContext";
import {
  addPreviousValues,
  calculateDaysBetweenDates,
  compareInnerAndGeneralFilters,
  formatDateRange,
  getAppliedFilters,
  getPreviousDateRange,
  getSpecificInnerFilters,
  removePreviousValue,
} from "@coachbar/shared-components/src/services/dashboardUtility/utility";
import { deepCopyObject } from "@coachbar/shared-components/src/services/utility";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getSalesPipelineData } from "../../dashboardActions";
import NoDataChart from "@coachbar/shared-components/src/screens/dashboard/NoDataChart";

const salesPipelineData = [
  {
    id: "prospect",
    label: "Prospect(s)",
    value: 0,
    legend: "Prospect(s)",
    color: "#EBF6F1",
    width: "100%",
  },
  {
    id: "qualified",
    label: "Qualified",
    value: 0,
    legend: "Qualified",
    color: "#D7EEE3",
    width: "90%",
  },
  {
    id: "negotiation",
    label: "Negotiation",
    value: 0,
    legend: "Negotiation",
    color: "#AFDDC7",
    width: "80%",
  },
  {
    id: "won",
    label: "Won",
    value: 0,
    legend: "Won",
    color: "#87CCAB",
    width: "70%",
  },
];

const SalesPipeline = ({ slug, chartData }) => {
  const { selectedDate, appliedFilters, fixFilters } = useChartsProvider();

  let initialized = false;
  const widgetInfo = chartData;
  const [isLoading, setIsLoading] = useState(false);
  const [pipelineData, setPipelineData] = useState(
    deepCopyObject(salesPipelineData)
  );
  const [isCompared, setIsCompared] = useState(false);
  const [prevDateRange, setPrevDateRange] = useState(undefined);
  const [daysBetweenDates, setDaysBetweenDates] = useState(undefined);
  const [innerFilters, setInnerFilters] = useState(
    getSpecificInnerFilters(fixFilters, chartData.filters)
  );
  const [innerDate, setInnerDate] = useState(deepCopyObject({}));

  const [formattedDateForChart, formattedPrevDateForChart] = useMemo(() => {
    let conDate = deepCopyObject(
      innerDate?.to && innerDate?.from ? innerDate : selectedDate
    );
    // Different date format to show in chart tooltip
    const formattedDate = formatDateRange(conDate || null, "chart");
    const formattedPrevDate = formatDateRange(prevDateRange || null, "chart");
    return [formattedDate, formattedPrevDate];
  }, [innerDate, prevDateRange, selectedDate]);

  let innerAppliedFilters = useMemo(() => {
    return getAppliedFilters(innerFilters);
  }, [innerFilters]);

  const constructRequestData = ({ date }) => {
    let requestData = compareInnerAndGeneralFilters(
      appliedFilters,
      innerAppliedFilters,
      Object.keys(fixFilters),
      date,
      selectedDate
    );

    return requestData;
  };

  const clearPreviousData = () => {
    setIsCompared(false);
    const copyOfPipelineData = [...pipelineData];
    setPipelineData(removePreviousValue(copyOfPipelineData));
    setPrevDateRange(null);
    setDaysBetweenDates(null);
  };

  const onToogleCompare = () => {
    if (isCompared) {
      clearPreviousData();
    } else {
      setIsCompared(true);
      let conDate = deepCopyObject(
        innerDate?.to && innerDate?.from ? innerDate : selectedDate
      );

      const previousDateRange = getPreviousDateRange(conDate);
      const numberOfDays = calculateDaysBetweenDates(conDate);
      setDaysBetweenDates(numberOfDays);
      setPrevDateRange(previousDateRange);
      const requestData = constructRequestData({ date: previousDateRange });
      getData(requestData, true, pipelineData);
    }
  };

  const getData = useCallback(
    async (requestData, isCompared = false, pipelineData) => {
      setIsLoading(true);
      let { data } = await getSalesPipelineData(requestData);
      setIsLoading(false);

      if (data?.dashboard) {
        let values = salesPipelineData.map((x) => {
          return { ...x, value: data?.dashboard?.[x.id] };
        });

        if (isCompared) {
          const updatedData = addPreviousValues(data?.dashboard, pipelineData);
          setPipelineData(deepCopyObject(updatedData));
        } else {
          setPipelineData(deepCopyObject(values));
        }
      }
    },
    []
  );

  useEffect(() => {
    if (!initialized) {
      initialized = true;
      clearPreviousData();
      const requestData = constructRequestData({ date: innerDate });
      getData(requestData);
    }
  }, [getData, innerDate, selectedDate, appliedFilters, innerAppliedFilters]);

  return (
    <>
      {widgetInfo?.isVisible && (
        <ChartContainer>
          <ChartHeader
            title={widgetInfo?.title}
            showFilters
            // showCompare
            showDelete
            chartData={chartData}
            filters={innerFilters}
            onSubmitFilters={(newFilters, date) => {
              setInnerFilters(newFilters);
              setInnerDate(date);
            }}
            onToogleCompare={onToogleCompare}
            isCompared={isCompared}
            date={innerDate}
            generalDate={selectedDate}
          />
          {isLoading ? (
            <Center minH={"305px"}>
              <Loader />
            </Center>
          ) : !pipelineData ? (
            <Box h={"250px"}>
              <NoDataChart />
            </Box>
          ) : (
            <>
              <CustomFunnel
                isCompared={isCompared}
                data={pipelineData}
                selectedDate={formattedDateForChart}
                prevDate={formattedPrevDateForChart}
                daysBetweenDates={daysBetweenDates}
              />
              <ChartLegends data={pipelineData} />
            </>
          )}
        </ChartContainer>
      )}
    </>
  );
};

export default SalesPipeline;
