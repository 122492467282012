import React, { useMemo } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { MinusIcon, AddIcon } from "@chakra-ui/icons";

const QuantityModifier = ({ service, quantity, onIncrement, onDecrement }) => {
  const serviceQuantity = useMemo(() => {
    const foundQuantity = quantity?.find((qty) => qty.id === service?.id);
    return foundQuantity?.quantity || 0;
  }, [quantity, service?.id]);

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      border="1px solid #E2E8F0"
      rounded={"6px"}
      h={"100%"}
      maxH={"45px"}
    >
      <Box
        p={"6px 10px"}
        borderRight="1px solid #E2E8F0"
        borderLeftRadius={"6px"}
        bg={serviceQuantity <= 1 ? "#F9FAFB" : "transparent"}
        cursor={serviceQuantity <= 1 ? "not-allowed" : "pointer"}
        onClick={() => onDecrement(service?.id)}
      >
        <MinusIcon color={serviceQuantity <= 1 ? "brandGray.40" : "brand.50"} />
      </Box>
      <Text
        color={"#111A29"}
        fontSize={"14px"}
        fontWeight={600}
        textAlign={"center"}
        px={"12px"}
      >
        {serviceQuantity}
      </Text>
      <Box
        p={"6px 10px"}
        borderLeft="1px solid #E2E8F0"
        cursor={"pointer"}
        onClick={() => onIncrement(service?.id)}
      >
        <AddIcon color={"brand.50"} />
      </Box>
    </Flex>
  );
};

export default QuantityModifier;
