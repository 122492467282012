// ForgotPassword.js
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "./authActions";
import { Button, TextBox, Link } from "@coachbar/shared-components";
import { Text, Stack, Flex } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import {
  requiredField,
  invalidEmail,
} from "../../constants/validationMessages";
import { emailValidationPattern } from "../../constants/validationPatterns";
import { encryptData } from "../../services/utility";
import { useClearState } from "../../services/customHook.js";
import { AuthLeftPanel } from "@coachbar/shared-components";
import { AuthRightPanel } from "@coachbar/shared-components";
import { profileRoute } from "../../constants/redirectionRoutes";
import { clearMessage } from "./authSlice";
import Notification from "../../components/Notification";

const ForgotPassword = () => {
  const { loading, userInfo, error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const navigate = useNavigate();
  useClearState();

  useEffect(() => {
    if (userInfo) {
      navigate(profileRoute);
    }
  }, [navigate, userInfo]);

  const submitForm = (data) => {
    dispatch(forgotPassword(data)).then((responseData) => {
      if (responseData.payload && responseData.payload.success) {
        const encryptedData = encryptData(data.email);
        let params = new URLSearchParams({ token: encryptedData }).toString();
        navigate("/reset-password?" + params);
      }
    });
  };

  return (
    <>
      {error && (
        <Notification
          title={error}
          status="error"
          onCloseToast={() => dispatch(clearMessage())}
        />
      )}
      <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
        <AuthLeftPanel />
        <AuthRightPanel width={"1 1 calc(100% - 430px)"}>
          <form onSubmit={handleSubmit(submitForm)} noValidate>
            <div className="form-group">
              <Text
                fontSize="20px"
                lineHeight={"20px"}
                fontWeight="700"
                mb="8px !important"
                color="var(--title-black-text)"
                textAlign="center"
              >
                Reset your password
              </Text>
              <Text
                color="#7E8792"
                fontSize="14px"
                fontWeight={400}
                textAlign="center"
                mb="32px !important"
              >
                Please enter your registered email address to reset your
                password.
              </Text>
              <TextBox
                type="text"
                placeholder="Registered Email"
                required
                innerref={register("email", {
                  required: requiredField.replace("$Field$", "Email"),
                  pattern: {
                    value: emailValidationPattern,
                    message: invalidEmail,
                  },
                })}
                validationErrors={errors}
              />
            </div>
            <Stack spacing={4} mt={2}>
              <Button
                type="submit"
                isLoading={loading}
                title="Get code"
                variant={"newColorThemePrimary"}
                id="forgot-password-get-code-submit"
              />
              <Flex alignItems={"center"} justifyContent={"center"}>
                <ArrowBackIcon mr={1} />
                <Link
                  to="/login"
                  title="Back to Login"
                  decoration="none"
                  color="var(--title-black-text)"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight={1}
                />
              </Flex>
            </Stack>
          </form>
        </AuthRightPanel>
      </Stack>
    </>
  );
};
export default ForgotPassword;
