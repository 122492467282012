import { createSlice } from "@reduxjs/toolkit";
import { addBank, getBanks, updateBank } from "./bankAction";

const initialState = {
  banksLoading: false,
  error: null,
  success: null,
  selectedBanks: [],
};

const bankSlice = createSlice({
  name: "bank",
  initialState,
  reducers: {
    clearMessage: (state) => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: {
    //add Bank
    [addBank.pending]: (state) => {
      state.banksLoading = true;
      state.error = null;
    },
    [addBank.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.banksLoading = false;
      if (payload.data && payload.success) {
        state.selectedBanks = payload.data?.bank || null;
      }
    },
    [addBank.rejected]: (state, { payload }) => {
      state.banksLoading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // get Banks
    [getBanks.pending]: (state) => {
      state.banksLoading = true;
      state.error = null;
    },
    [getBanks.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.banksLoading = false;
      if (payload.data && payload.success) {
        state.selectedBanks = payload.data?.bank || null;
      }
    },
    [getBanks.rejected]: (state, { payload }) => {
      state.banksLoading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // update Bank
    [updateBank.pending]: (state) => {
      state.banksLoading = true;
      state.error = null;
    },
    [updateBank.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.banksLoading = false;
      if (payload.data && payload.success) {
        state.selectedBanks = payload.data?.bank || null;
      }
    },
    [updateBank.rejected]: (state, { payload }) => {
      state.banksLoading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
  },
});

export const { clearMessage } = bankSlice.actions;

export default bankSlice.reducer;
