import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import OverviewDetails from "./overviewDetails";
import {
  getCommissionDetails,
  getPlanNamesByCommissionId,
} from "./rewardsActions";

const RewardDetails = () => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const paramSpTenantId = new URLSearchParams(search).get("spTenantId");
  let { rewardId } = useParams();
  const [commissionDetail, setCommissionDetail] = useState({});
  const [planNameList, setPlanNameList] = useState([]);
  const [commissionDetailLoader, setCommissionDetailLoader] = useState(false);
  const [planNameListLoader, setPlanNameListLoader] = useState(false);

  const fetchCommissionDetails = async (id, spTenantId) => {
    setCommissionDetailLoader(true);
    await getCommissionDetails(id, spTenantId).then((data) => {
      if (data) {
        setCommissionDetail(data?.commissionDetail || {});
        fetchPlanNamesByCommissionId(
          data?.commissionDetail?.commissionIds,
          data?.commissionDetail?.spTenantId
        );
        setCommissionDetailLoader(false);
      } else {
        setCommissionDetailLoader(false);
      }
    });
  };

  const fetchPlanNamesByCommissionId = async (commissionIds, spTenantId) => {
    setPlanNameListLoader(true);
    await getPlanNamesByCommissionId(spTenantId, {
      commissionIds: commissionIds,
    }).then((data) => {
      if (data) {
        setPlanNameList(data?.commissionPlanNameList || []);
        setPlanNameListLoader(false);
      } else {
        setPlanNameListLoader(false);
      }
    });
  };

  let initialized = false;
  useEffect(() => {
    if (!initialized && rewardId && paramSpTenantId) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      initialized = true;
      fetchCommissionDetails(rewardId, paramSpTenantId);
    }
  }, []);

  return (
    <OverviewDetails
      commissionDetailLoader={commissionDetailLoader}
      planNameListLoader={planNameListLoader}
      commissionDetail={commissionDetail}
      planNameList={planNameList}
      fetchCommissionDetails={fetchCommissionDetails}
    />
  );
};

export default RewardDetails;
