import { Box, Card, IconButton, Image, Stack, Text } from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
import fallbackImage from "../../../image/fallbackImage.png";
import { getThumbnailFromVideoUrl } from "../../../services/utility";
import {
  Edit02,
  Star01,
  Trash03,
} from "@coachbar/shared-components/src/images";
import { TableTextWithEllipsis } from "@coachbar/shared-components";
import { color } from "framer-motion";

const VideoCard = ({
  video,
  onAlertOpenAction,
  permissions,
  editVideo,
  handleUpdateToStarVideo,
}) => {
  const getImage = (url) => {
    let videoThumbnail = getThumbnailFromVideoUrl(url);
    return (
      <Image
        src={videoThumbnail}
        fallbackSrc={fallbackImage}
        objectFit="cover"
        width={"100%"}
        height={"120px"}
        borderTopRadius={"8px"}
      />
    );
  };

  return (
    <Card
      key={video.id}
      variant={"outline"}
      size="md"
      overflow={"hidden"}
      border={"1px solid #E6E7E9"}
      boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1)"
      borderRadius="10px"
      _hover={{
        ".hover-box": {
          display: "flex",
        },
      }}
    >
      {video.isStarVideo ? (
        <Box position="absolute" right="0px" zIndex={1}>
          <Stack
            direction="row"
            alignItems="center"
            justify="center"
            spacing={1}
            background="brand.50"
            fontSize="10px"
            fontWeight="600"
            lineHeight="12px"
            color="brandGray.100"
            padding="4px"
            borderRadius="6px"
            borderBottomRightRadius="0"
            borderTopLeftRadius="0"
          >
            <StarIcon />
            <Text>PRIMARY</Text>
          </Stack>
        </Box>
      ) : (
        ""
      )}
      <Box pos={"relative"}>
        {getImage(video?.url)}
        <Box
          className="hover-box"
          display={"none"}
          bg={"rgba(17, 26, 41, .7)"}
          pos={"absolute"}
          inset={0}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"12px"}
          zIndex={2}
        >
          {permissions.edit && (
            <>
              <IconButton
                variant="ghost"
                _hover={{ backgroundColor: video.isStarVideo ? "" : "#0C94AC" }}
                _active={{
                  backgroundColor: video.isStarVideo ? "" : "#0C94AC",
                }}
                onClick={() => handleUpdateToStarVideo(video?.id)}
                border={"none"}
                isDisabled={video.isStarVideo}
                _disabled={{ opacity: 1, bg: "none" }}
                id={`company-location-list-star-${video?.id}`}
              >
                <Star01
                  stroke={
                    video.isStarVideo
                      ? "var(--chakra-colors-cyprus-400)"
                      : "var(--chakra-colors-white)"
                  }
                  fill={
                    video.isStarVideo
                      ? "var(--chakra-colors-cyprus-400)"
                      : "none"
                  }
                  width="24px"
                  height="24px"
                />
              </IconButton>
              <IconButton
                variant="ghost"
                _hover={{ backgroundColor: "#0C94AC" }}
                _active={{ backgroundColor: "#0C94AC" }}
                onClick={() => editVideo(video)}
                border={"none"}
                id={`company-location-list-edit-${video?.id}`}
              >
                <Edit02
                  stroke="var(--chakra-colors-white)"
                  fill="none"
                  width="24px"
                  height="24px"
                />
              </IconButton>
            </>
          )}
          {permissions.delete && (
            <IconButton
              variant="ghost"
              _hover={{ backgroundColor: "#0C94AC" }}
              _active={{ backgroundColor: "#0C94AC" }}
              onClick={() => onAlertOpenAction(video)}
              border={"none"}
              id={`company-location-list-delete-${video?.id}`}
            >
              <Trash03
                stroke="var(--chakra-colors-white)"
                fill="none"
                width="24px"
                height="24px"
              />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box p={"12px"}>
        <TableTextWithEllipsis
          maxChars={40}
          text={video?.title}
          textContainerProps={{
            color: "#1F2937",
            fontSize: "12px",
            lineHeight: "20px",
            fontWeight: 500,
          }}
        />
      </Box>
    </Card>
  );
};

export default VideoCard;
