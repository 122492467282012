import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import {
  Modal,
  TextArea,
  TextBox,
  TextEditor,
} from "@coachbar/shared-components";
import { Controller, useForm } from "react-hook-form";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  requiredField,
  whiteSpace,
} from "@coachbar/shared-components/src/constants/validationMessages";
import { whiteSpacePattern } from "@coachbar/shared-components/src/constants/validationPatterns";
import Select from "react-select";
import {
  customStylesForReactSelect,
  scrollbarCSS,
} from "@coachbar/shared-components/src/constants/constantValues";
import { useDispatch } from "react-redux";
import { updateGeneralNotification } from "./generalNotificationActions";
import { notification } from "@coachbar/shared-components/src/services/utility";
import { updateNotificationRecord } from "./generalNotificationSlice";
import { roleOptions, schedulePeriods } from "../../constants/notifications";

const INTERNAL_NOTIFICATION_MAX_LEN = 565;
const EMAIL_SUB_MAX_LEN = 565;
const EMAIL_BODY_MAX_LEN = 2000;

const submittedKeys = [
  "lead submitted to software provider(s)",
  "deal submitted to software provider(s)",
];
const receivedKeys = ["lead received", "deal received", ...submittedKeys];

const NotificatiFormModal = ({
  currNotification,
  onClose,
  insertParameter,
  customForm = [],
}) => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      active: true,
      isEnableInternal: true,
      isEnableEmail: true,
      internalNotification: "",
      subject: "",
      emailNotificationBody: "",
      sentTo: [],
    },
  });

  const [internalNotificationLength, setInternalNotificationLength] =
    useState(0);
  const [emailBodyLength, setEmailBodyLength] = useState(0);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currNotification) {
      const {
        active,
        isEnableInternal,
        isEnableEmail,
        internalNotification,
        emailNotification: { subject, body, sentTo },
      } = currNotification;
      setValue("active", active);
      setValue("isEnableInternal", isEnableInternal);
      setValue("isEnableEmail", isEnableEmail);
      setValue("internalNotification", internalNotification);
      setValue("subject", subject);
      setValue("emailNotificationBody", body);
      setValue(
        "sentTo",
        sentTo?.map((role) => ({ label: role, value: role }))
      );
      return reset;
    }
  }, [currNotification, reset, setValue]);

  const internalNotificationValue = watch("internalNotification");
  const emailNotificationValue = watch("emailNotificationBody");
  const active = watch("active");
  const isEnableInternal = watch("isEnableInternal");
  const isEnableEmail = watch("isEnableEmail");

  useEffect(() => {
    const internalNotificationValueWordCount =
      internalNotificationValue?.length || 0;
    if (internalNotificationValueWordCount > INTERNAL_NOTIFICATION_MAX_LEN) {
      setInternalNotificationLength(INTERNAL_NOTIFICATION_MAX_LEN);
      setValue(
        "internalNotification",
        internalNotificationValue.slice(0, INTERNAL_NOTIFICATION_MAX_LEN),
        { shouldValidate: true }
      );
    } else setInternalNotificationLength(internalNotificationValueWordCount);
  }, [internalNotificationValue]);

  useEffect(() => {
    const emailNotificationValueWordCount = emailNotificationValue?.length || 0;
    if (emailNotificationValueWordCount > EMAIL_BODY_MAX_LEN) {
      setEmailBodyLength(EMAIL_BODY_MAX_LEN);
      setValue(
        "emailNotificationBody",
        emailNotificationValue.slice(0, EMAIL_BODY_MAX_LEN),
        { shouldValidate: true }
      );
    } else setEmailBodyLength(emailNotificationValueWordCount);
  }, [emailNotificationValue]);

  const onSubmit = async (data) => {
    const {
      active,
      isEnableEmail,
      isEnableInternal,
      internalNotification,
      subject,
      emailNotificationBody,
      sentTo,
      period,
    } = data;
    const apiData = {
      ...currNotification,
      active,
      isEnableEmail,
      isEnableInternal,
      internalNotification,
      emailNotification: {
        subject,
        body: emailNotificationBody,
        sentTo: sentTo?.map((role) => role.value),
      },
    };
    if (currNotification.isSchedule) {
      apiData.period = period.value;
    }
    delete apiData.notificationsModulesKey;
    setIsFormSubmitting(true);
    const apiRes = await updateGeneralNotification(apiData);
    if (apiRes.success) {
      notification(apiRes.message);
      onClose();
      dispatch(
        updateNotificationRecord({
          notificationsModulesKey: currNotification.notificationsModulesKey,
          notificationId: currNotification.id,
          data: apiRes.data.notificationSetting,
        })
      );
    }
    setIsFormSubmitting(false);
  };

  const handleEmailNotification = (content) => {
    let charCount = content.replace(/(<([^>]+)>)/gi, "").length;
    if (charCount > EMAIL_BODY_MAX_LEN) {
      setValue(
        "emailNotificationBody",
        content.slice(0, EMAIL_BODY_MAX_LEN, { shouldValidate: true })
      );
      return null;
    } else {
      if (content && content !== "<p><br></p>") {
        setValue("emailNotificationBody", content, { shouldValidate: true });
      } else {
        setValue("emailNotificationBody", "", { shouldValidate: true });
      }
    }
  };

  const insertMessageParams = (value) => {
    const text = `${internalNotificationValue}[${value}]`;
    setValue("internalNotification", text, { shouldValidate: true });
  };

  if (currNotification) {
    const messageParams =
      insertParameter[currNotification?.eventName?.toLowerCase()];

    const emailParams = [
      "",
      ...(insertParameter[currNotification?.eventName?.toLowerCase()]
        ? insertParameter[currNotification?.eventName?.toLowerCase()]
        : []
      )?.map((item) => `[${item}]`),
    ];

    if (submittedKeys.includes(currNotification?.eventName?.toLowerCase())) {
      customForm
        ?.map((x) => x.name)
        ?.forEach((item) => emailParams.push(`[${item}]`));
    }

    if (
      [...receivedKeys, ...submittedKeys].includes(
        currNotification?.eventName?.toLowerCase()
      )
    ) {
      ["Accept Link", "Reject Link"].forEach((item) =>
        emailParams.push(`[${item}]`)
      );
    }

    return (
      <Modal
        isOpen={!!currNotification}
        onClose={onClose}
        title={currNotification?.eventName}
        allowCloseButton={true}
        displayFooter={true}
        size={"3xl"}
        discardTitle="cancel"
        padding="0px"
        onSaveClick={handleSubmit(onSubmit)}
        loading={isFormSubmitting}
      >
        <form noValidate>
          <Divider />
          <Box padding={"24px"} mt="0px !important">
            <Controller
              name="active"
              control={control}
              defaultValue={currNotification.active}
              render={({ field: { onChange, value } }) => {
                return (
                  <CustomSwitchWithLabel
                    label={"Is Active?"}
                    isChecked={value}
                    onChange={onChange}
                  />
                );
              }}
            />
            <Divider variant={"dashed"} m="16px 0" />
            <Stack>
              <Text color={"#7E8792"} fontSize={"14px"} fontWeight={600}>
                Delivery
              </Text>
              <HStack gap={16} mt={"12px !important"}>
                <Controller
                  name="isEnableInternal"
                  control={control}
                  defaultValue={currNotification.isEnableInternal}
                  render={({ field: { onChange, value } }) => (
                    <CustomSwitchWithLabel
                      label={"Internal"}
                      isChecked={value}
                      onChange={onChange}
                    />
                  )}
                />
                <Controller
                  name="isEnableEmail"
                  control={control}
                  defaultValue={currNotification.isEnableEmail}
                  render={({ field: { onChange, value } }) => (
                    <CustomSwitchWithLabel
                      label={"Email"}
                      isChecked={value}
                      onChange={onChange}
                    />
                  )}
                />
              </HStack>
            </Stack>
            {currNotification?.isSchedule && (
              <>
                <Divider variant={"dashed"} m="16px 0" />
                <Stack>
                  <Text
                    color={"#7E8792"}
                    fontSize={"14px"}
                    fontWeight={600}
                    mb="12px"
                  >
                    Schedule
                  </Text>
                  <Text
                    color={"#7E8792"}
                    fontSize={"12px"}
                    fontWeight={500}
                    mb="4px"
                  >
                    Period
                  </Text>
                  <Controller
                    name="period"
                    control={control}
                    defaultValue={{
                      label: currNotification?.period,
                      value: currNotification?.period,
                    }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Select
                          closeMenuOnSelect
                          value={value}
                          options={schedulePeriods}
                          onChange={(val) => {
                            onChange({
                              target: { name: "period", value: val },
                            });
                          }}
                          styles={customStylesForReactSelect}
                          isSearchable={true}
                        />
                      );
                    }}
                  />
                </Stack>
              </>
            )}
          </Box>
          <Box>
            <Tabs
              defaultIndex={0}
              borderBottom={
                "1px solid var(--chakra-colors-chakra-border-color)"
              }
            >
              <TabList
                p={"0 24px"}
                gap={"20px"}
                borderBottom={
                  "1px solid var(--chakra-colors-chakra-border-color)"
                }
              >
                <StyledTab name={"Internal"} />
                <StyledTab name={"Email"} />
              </TabList>

              <TabPanels bg={"#F9FAFB"}>
                <TabPanel p={"24px"}>
                  <Stack>
                    <Text color={"#7E8792"} fontSize={"12px"} fontWeight={500}>
                      Message Text{" "}
                      <span style={{ fontSize: "12px", color: "red" }}>*</span>
                    </Text>
                    <Box
                      bg={"#fff"}
                      border={"1px solid #E6E7E9"}
                      borderRadius={"4px"}
                    >
                      {messageParams?.length > 0 && (
                        <Box
                          bg={"#F9FAFB"}
                          border={"1px solid #E6E7E9"}
                          borderRadius={"4px 4px 0 0"}
                        >
                          <Menu gutter={0}>
                            <MenuButton
                              as={Button}
                              border={"none"}
                              fontWeight={500}
                              fontSize={"12px"}
                              bg={"transparent"}
                              cursor={"pointer"}
                              color={"#5E6977"}
                              _hover={{ color: "#0C94AC" }}
                              rightIcon={<ChevronDownIcon />}
                              isDisabled={!isEnableInternal}
                            >
                              {"Insert Parameters"}
                            </MenuButton>
                            <MenuList
                              p={0}
                              maxH={200}
                              overflow={"auto"}
                              css={scrollbarCSS}
                            >
                              {insertParameter[
                                currNotification?.eventName?.toLowerCase()
                              ].map((menu, i) => (
                                <MenuItem
                                  key={i}
                                  color={"#111A29"}
                                  fontSize={"14px"}
                                  fontWeight={450}
                                  onClick={() => insertMessageParams(menu)}
                                >
                                  {menu}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </Menu>
                        </Box>
                      )}
                      <TextArea
                        border="none"
                        value={internalNotificationValue}
                        validationErrors={errors}
                        helperText={`${internalNotificationLength}/${INTERNAL_NOTIFICATION_MAX_LEN}`}
                        helperTextPosition="right"
                        innerref={register("internalNotification", {
                          required: requiredField.replace(
                            "$Field$",
                            "Message body"
                          ),
                        })}
                        disabled={!isEnableInternal}
                        isRequired
                      />
                    </Box>
                  </Stack>
                </TabPanel>
                <TabPanel p={"24px"}>
                  <>
                    <Box mb={"16px"}>
                      <Heading label="" />
                      <Text
                        fontSize={"12px"}
                        color={"#7E8792"}
                        fontWeight={600}
                        textTransform={"capitalize"}
                        mb={"4px !important"}
                      >
                        Send To
                      </Text>
                      <Controller
                        name="sentTo"
                        control={control}
                        defaultValue={currNotification?.emailNotification?.sentTo?.map(
                          (role) => ({ label: role, value: role })
                        )}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              closeMenuOnSelect={false}
                              value={value}
                              options={roleOptions}
                              onChange={(val) => {
                                onChange({
                                  target: { name: "sentTo", value: val },
                                });
                              }}
                              styles={customStylesForReactSelect}
                              isSearchable={true}
                              isDisabled={!isEnableEmail}
                              isMulti
                            />
                          );
                        }}
                      />
                    </Box>
                    <TextBox
                      required
                      name="Email Subject"
                      validation={true}
                      validationErrors={errors}
                      showPlaceHolder={true}
                      labelColor="#7E8792"
                      innerref={register("subject", {
                        required: requiredField.replace(
                          "$Field$",
                          "Email Subject"
                        ),
                        maxLength: {
                          value: EMAIL_SUB_MAX_LEN,
                          message: `Character limit of ${EMAIL_SUB_MAX_LEN} exceeds.`,
                        },
                        pattern: {
                          value: whiteSpacePattern,
                          message: whiteSpace,
                        },
                      })}
                      disabled={!isEnableEmail}
                    />
                    <TextEditor
                      required
                      placeholder="Email Body"
                      content={emailNotificationValue}
                      handleEditorChange={handleEmailNotification}
                      labelColor="#7E8792"
                      innerref={register("emailNotificationBody", {
                        required: requiredField.replace(
                          "$Field$",
                          "Email Body"
                        ),
                      })}
                      validationErrors={errors}
                      characterCount={emailBodyLength}
                      maxLength={EMAIL_BODY_MAX_LEN}
                      isDisabled={!isEnableEmail}
                      insertParameters={emailParams}
                    />
                  </>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </form>
      </Modal>
    );
  }
  return null;
};

const CustomSwitchWithLabel = ({ label, isChecked, onChange }) => (
  <HStack gap={1}>
    <Text color={"#111A29"} fontSize={"14px"} fontWeight={500}>
      {label}
    </Text>
    <Switch
      colorScheme="brand"
      isChecked={isChecked}
      onChange={onChange}
      isFocusable={false}
    />
  </HStack>
);

const StyledTab = ({ name }) => (
  <Tab
    color={"#7E8792"}
    fontSize={"14px"}
    fontWeight={500}
    _selected={{ color: "#0C94AC", borderBottom: "2px solid #0C94AC" }}
    p="0 0 6px 0"
  >
    {name}
  </Tab>
);

export default NotificatiFormModal;
