import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Input,
  Skeleton,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Tfoot,
} from "@chakra-ui/react";
import {
  scrollbarCSS,
  tableCss,
} from "@coachbar/shared-components/src/constants/constantValues";
import { getCommissionPerformanceDetailList } from "./rewardsActions";
import SendInquiryModal from "./sendInquiryModal";
import { getCurrency } from "@coachbar/shared-components/src/services/utility";

const OverviewDetailsPerformanceListing = ({
  fetchInquiryList,
  commissionDetail,
  programOverviewDetails,
  programOverviewDetailsLoader,
  planId,
  performanceDetail,
  setPerformanceLoading,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(true);
  const [nextToken, setNextToken] = useState(null);
  const [inquiryData, setInquiryData] = useState({});
  const [performanceList, setPerformanceList] = useState([]);

  const getCommissionPerformanceList = async (
    parameterName,
    parameterList,
    product
  ) => {
    setLoading(true);
    try {
      const res = await getCommissionPerformanceDetailList({
        limit: 250,
        commissionIds: commissionDetail?.commissionIds,
        tenantId: commissionDetail?.spTenantId ?? "",
        planId: planId ?? "",
        parameter: parameterName ?? "",
        productId: product?.id ?? "",
        nextToken: nextToken,
      });
      if (res) {
        setPerformanceList((prevList) => {
          const newObject = {
            title: parameterName,
            parameterList: parameterList,
            productName: product?.name,
            details: res?.commissionDetailList,
            total: res?.commissionTotal,
          };

          if (parameterName === "Product Specific Targets") {
            const updatedProdList =
              prevList?.map((item) => {
                if (
                  item?.title === parameterName &&
                  item?.productName === product?.name
                ) {
                  return newObject;
                }
                return item;
              }) || [];

            return updatedProdList.some(
              (item) =>
                item?.title === parameterName &&
                item?.productName === product?.name
            )
              ? updatedProdList
              : [...updatedProdList, newObject];
          } else {
            const updatedList =
              prevList?.map((item) =>
                item?.title === parameterName ? newObject : item
              ) || [];

            return updatedList.some((item) => item?.title === parameterName)
              ? updatedList
              : [...updatedList, newObject];
          }
        });
      }
    } catch (error) {
      console.error("Error fetching data for", error);
      // Handle error as needed
    } finally {
      setLoading(false);
    }
  };

  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      const titleList = programOverviewDetails?.outcomeBaseObject?.titles;
      const parameterList =
        programOverviewDetails?.outcomeBaseObject?.parameters;
      const prodIdList = programOverviewDetails?.outcomeBaseObject?.rates
        ?.find((x) => x.title === "Performance: Product Target")
        ?.productTarget?.map((y) => ({
          id: y?.productId,
          name: y?.productName,
        }));

      if (performanceDetail) {
        setPerformanceList([]);
      }

      const fetchDataSequentially = async () => {
        setPerformanceLoading(true);
        try {
          for (let i = 0; i < titleList?.length; i++) {
            const element = titleList[i];
            if (element === "Product Specific Targets") {
              for (let j = 0; j < prodIdList?.length; j++) {
                const prod = prodIdList[j];
                await getCommissionPerformanceList(
                  element,
                  parameterList,
                  prod
                );
              }
            } else {
              await getCommissionPerformanceList(element, parameterList);
            }
          }
        } catch (error) {
          console.error("Error during data fetch:", error);
        } finally {
          setPerformanceLoading(false);
        }
      };

      // Call the async function to start fetching sequentially
      fetchDataSequentially();
    }
  }, [planId]);

  const onClickSendInquiry = (data) => {
    setInquiryData(data);
    onOpen();
  };

  const rateTypes = programOverviewDetails?.outcomeBaseObject?.rates?.reduce(
    (acc, rate) => {
      if (rate?.rateType) {
        switch (rate?.title) {
          case "Performance: Revenue":
            acc.revenueRateType = rate.rateType;
            break;
          case "Performance: Deal Source Quota":
            acc.sourceQuotaRateType = rate.rateType;
            break;
          case "Performance: Leads Number":
            acc.leadsNumberRateType = rate.rateType;
            break;
          case "Performance: Deals Number":
            acc.dealsNumberRateType = rate.rateType;
            break;
          case "Performance: Product Target":
            acc.productTargetRateType = rate.rateType;
            break;
          case "Performance: Tiered":
            acc.tieredRateType = rate.rateType;
            break;
          default:
            break;
        }
      }
      return acc;
    },
    {}
  );

  const {
    revenueRateType,
    sourceQuotaRateType,
    leadsNumberRateType,
    dealsNumberRateType,
    productTargetRateType,
    tieredRateType,
  } = rateTypes;

  return (
    <Box>
      {loading || programOverviewDetailsLoader ? (
        <Table variant="simple" css={tableCss} border={"1px solid #E6E7E9"}>
          <Thead>
            <Tr>
              <Th>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Th>
              <Th>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Th>
              <Th>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Th>
              <Th>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Th>
              <Th>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Th>
              <Th>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td borderColor={"#E6E7E9"}>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Td>
              <Td borderColor={"#E6E7E9"}>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Td>
              <Td borderColor={"#E6E7E9"}>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Td>
              <Td borderColor={"#E6E7E9"}>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Td>
              <Td borderColor={"#E6E7E9"}>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Td>
              <Td borderColor={"#E6E7E9"}>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      ) : (
        <>
          <Accordion defaultIndex={[0]} allowToggle>
            {performanceList?.map((item) => {
              let titleParameter = "";
              if (item?.title === "Quota" && item?.parameterList?.quota) {
                titleParameter = `- ${item?.parameterList?.quota}`;
              }
              if (item?.title === "Revenue" && item?.parameterList?.revenue) {
                titleParameter = `- ${item?.parameterList?.revenue}`;
              }

              if (item?.details?.length > 0) {
                return (
                  <AccordionItem mb={"24px"} border={0}>
                    <h2>
                      <AccordionButton
                        p={"16px 24px"}
                        bg={"#fff"}
                        border={"1px solid #E6E7E9"}
                      >
                        <Box
                          as="span"
                          flex="1"
                          textAlign="left"
                          color={"#111A29"}
                          fontSize={"14px"}
                          fontWeight={600}
                        >
                          {item?.title !== "Product Specific Targets"
                            ? `Base: ${item?.title} ${titleParameter} Parameters`
                            : `Product Specific Targets: ${item?.productName}`}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel p={0} border={"1px solid #E6E7E9"}>
                      <Table css={tableCss}>
                        <Thead>
                          <Tr>
                            <Th>Deal ID</Th>
                            {item?.title === "Quota" &&
                              item?.parameterList?.quota === "Source" && (
                                <Th>Deal Source</Th>
                              )}
                            <Th>Lead Name</Th>
                            <Th>Deal Amount</Th>
                            <Th>Rate</Th>
                            <Th>Reward</Th>
                            <Th>Transactions</Th>
                            <Th>Due</Th>
                            <Th>Status</Th>
                            <Th></Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {item?.details?.map((data) => {
                            return (
                              <Tr key={data?.id}>
                                <Td>{data?.dealReadableId ?? "-"}</Td>
                                {item?.title === "Quota" &&
                                  item?.parameterList?.quota === "Source" && (
                                    <Td>
                                      {data?.parameter?.replace(
                                        "Source-",
                                        ""
                                      ) ?? "-"}
                                    </Td>
                                  )}
                                <Td>
                                  <Text
                                    color={"#111A29 !important"}
                                    fontSize={"14px"}
                                    lineHeight={1}
                                  >
                                    {data?.leadName}
                                  </Text>
                                  <Text
                                    color={"#5E6977 !important"}
                                    fontSize={"12px"}
                                    lineHeight={1}
                                  >
                                    {data?.leadEmail}
                                  </Text>
                                </Td>
                                <Td>
                                  {data?.scDealAmount !== undefined &&
                                  data?.scDealAmount !== null
                                    ? `${
                                        getCurrency().symbol
                                      }${data?.scDealAmount?.toFixed(2)}`
                                    : "-"}
                                </Td>
                                <Td>
                                  {!item?.total?.scDealAmount ? (
                                    <>
                                      {data?.scRateAmount !== undefined &&
                                      data?.scRateAmount !== null &&
                                      ((item?.title === "Revenue" &&
                                        revenueRateType === "Fixed Amount") ||
                                        (item?.title === "Tiered" &&
                                          tieredRateType === "Fixed Amount") ||
                                        (item?.title === "Quota" &&
                                          item?.parameterList?.quota ===
                                            "Source" &&
                                          sourceQuotaRateType ===
                                            "Fixed Amount") ||
                                        (item?.title === "Quota" &&
                                          item?.parameterList?.quota ===
                                            "Leads Number" &&
                                          leadsNumberRateType ===
                                            "Fixed Amount") ||
                                        (item?.title === "Quota" &&
                                          item?.parameterList?.quota ===
                                            "Deals Number" &&
                                          dealsNumberRateType ===
                                            "Fixed Amount") ||
                                        (item?.title ===
                                          "Product Specific Targets" &&
                                          productTargetRateType ===
                                            "Fixed Amount"))
                                        ? `${
                                            getCurrency().symbol
                                          }${data?.scRateAmount?.toFixed(2)}`
                                        : `${data?.scRateAmount?.toFixed(2)}%`}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </Td>
                                <Td color={"#2C885C !important"}>
                                  {!item?.total?.scDealAmount ? (
                                    <>
                                      {data?.scRewardAmount !== undefined &&
                                      data?.scRewardAmount !== null
                                        ? `${
                                            getCurrency().symbol
                                          }${data?.scRewardAmount?.toFixed(2)}`
                                        : "-"}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </Td>
                                <Td>
                                  {data?.scTransaction?.toFixed(2) ?? "-"}
                                </Td>
                                <Td color={"#c27803 !important"}>
                                  {!item?.total?.scDealAmount ? (
                                    <>
                                      {data?.scDueAmount !== undefined &&
                                      data?.scDueAmount !== null
                                        ? `${
                                            getCurrency().symbol
                                          }${data?.scDueAmount?.toFixed(2)}`
                                        : "-"}
                                    </>
                                  ) : (
                                    "-"
                                  )}
                                </Td>
                                <Td>
                                  {data?.status === "To Approve"
                                    ? "Pending Approval"
                                    : data?.status}
                                </Td>
                                <Td>
                                  <Text
                                    color={"#0C94AC"}
                                    fontSize={"14px"}
                                    pl={"24px"}
                                    fontWeight={700}
                                    cursor={"pointer"}
                                    borderLeft={"2px solid #E6E7E9"}
                                    onClick={() => onClickSendInquiry(data)}
                                  >
                                    Send Inquiry
                                  </Text>
                                </Td>
                              </Tr>
                            );
                          })}
                        </Tbody>
                        {item?.total?.scDealAmount && (
                          <Tfoot>
                            <Tr
                              bgColor={"rgba(243, 247, 255, 0.40) !important"}
                            >
                              <Th
                                colSpan={
                                  item?.title === "Quota" &&
                                  item?.parameterList?.quota === "Source"
                                    ? 3
                                    : 2
                                }
                                textAlign={"right"}
                                py={"20px"}
                                px={"16px"}
                                fontSize={"14px"}
                                fontWeight={600}
                                color={"#111A29"}
                                textTransform={"capitalize"}
                              >
                                Total:
                              </Th>
                              <Th py={"20px"} px={"16px"}>
                                {item?.total?.scDealAmount !== undefined &&
                                item?.total?.scDealAmount !== null
                                  ? `${
                                      getCurrency().symbol
                                    }${item?.total?.scDealAmount?.toFixed(2)}`
                                  : "-"}
                              </Th>
                              <Th py={"20px"} px={"16px"}>
                                {item?.total?.scRateAmount !== undefined &&
                                item?.total?.scRateAmount !== null &&
                                ((item?.title === "Revenue" &&
                                  revenueRateType === "Fixed Amount") ||
                                  (item?.title === "Tiered" &&
                                    tieredRateType === "Fixed Amount") ||
                                  (item?.title === "Quota" &&
                                    item?.parameterList?.quota === "Source" &&
                                    sourceQuotaRateType === "Fixed Amount") ||
                                  (item?.title === "Quota" &&
                                    item?.parameterList?.quota ===
                                      "Leads Number" &&
                                    leadsNumberRateType === "Fixed Amount") ||
                                  (item?.title === "Quota" &&
                                    item?.parameterList?.quota ===
                                      "Deals Number" &&
                                    dealsNumberRateType === "Fixed Amount") ||
                                  (item?.title === "Product Specific Targets" &&
                                    productTargetRateType === "Fixed Amount"))
                                  ? `${getCurrency().symbol}${
                                      item?.total?.scRateAmount
                                        ? item?.total?.scRateAmount?.toFixed(2)
                                        : "-"
                                    }`
                                  : item?.total?.scRateAmount
                                  ? `${item?.total?.scRateAmount?.toFixed(2)}%`
                                  : "-"}
                              </Th>
                              <Th
                                py={"20px"}
                                px={"16px"}
                                color={"#2C885C !important"}
                              >
                                {item?.total?.scRewardAmount !== undefined &&
                                item?.total?.scRewardAmount !== null
                                  ? `${
                                      getCurrency().symbol
                                    }${item?.total?.scRewardAmount?.toFixed(2)}`
                                  : "-"}
                              </Th>
                              <Th py={"20px"} px={"16px"}>
                                {item?.total?.scTransaction !== undefined &&
                                item?.total?.scTransaction !== null
                                  ? `${
                                      getCurrency().symbol
                                    }${item?.total?.scTransaction?.toFixed(2)}`
                                  : "-"}
                              </Th>
                              <Th
                                py={"20px"}
                                px={"16px"}
                                colSpan={2}
                                color={"#c27803 !important"}
                              >
                                {item?.total?.scDueAmount !== undefined &&
                                item?.total?.scDueAmount !== null
                                  ? `${
                                      getCurrency().symbol
                                    }${item?.total?.scDueAmount?.toFixed(2)}`
                                  : "-"}
                              </Th>
                            </Tr>
                          </Tfoot>
                        )}
                      </Table>
                    </AccordionPanel>
                  </AccordionItem>
                );
              }
            })}
          </Accordion>
        </>
      )}
      <SendInquiryModal
        planId={planId}
        fetchInquiryList={fetchInquiryList}
        requestData={inquiryData}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Box>
  );
};

export default OverviewDetailsPerformanceListing;
