import React, { useEffect, useState } from "react";
import { Checkbox, Flex, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { notification } from "../../services/utility";
import { useForm } from "react-hook-form";
import {
  AlertDialog,
  TextArea,
  TextBox,
  Modal,
} from "@coachbar/shared-components";
import { requiredField } from "@coachbar/shared-components/src/constants/validationMessages";
import {
  addGeneralSettings,
  deleteGeneralSettings,
  editGeneralSettings,
  getGeneralSettings,
} from "./generalSettingsActions";
import ProviderCommonContent from "./ProviderCommonContent";
import { whiteSpacePattern } from "../../constants/validationPatterns";
import { whiteSpace } from "../../constants/validationMessages";

const ProviderStatus = (props) => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    onOpen: onAlertOpen,
    isOpen: isAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [selectedData, setSelectedData] = useState(null);
  const [isDefault, setIsDefault] = useState(false);
  const [rowLoading, setRowLoading] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });

  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      fetchProviderStatus();
    }
  }, []);

  const fetchProviderStatus = async () => {
    setLoading(true);
    await getGeneralSettings("status").then((res) => {
      setLoading(false);
      if (res?.code === 200) {
        setDataList(res?.data?.partnerStatusList || []);
        setIsDefault(res?.data?.partnerStatusList.length === 0 ? true : false);
      }
    });
  };

  const editData = (data) => {
    setSelectedData(data);
    setValue("name", data.name);
    setValue("description", data.description);
    setDescriptionLength(data?.description?.length || 0);
    setIsDefault(data.isDefault);
    onOpen();
  };

  const onDeleteAlertOpenAction = (data) => {
    setSelectedData(data);
    onAlertOpen();
  };

  const onSuccess = (res) => {
    notification(res.message);
    setSelectedData(null);
    onModalClose();
    onAlertClose();
    fetchProviderStatus();
  };

  const submitForm = async (data) => {
    setLoading(true);

    try {
      if (selectedData) {
        editGeneralSettings("status", selectedData.id, data).then((res) => {
          setLoading(false);
          if (res?.code === 200) {
            onSuccess(res);
          }
        });
      } else {
        await addGeneralSettings("status", data).then((res) => {
          setLoading(false);
          if (res?.code === 200) {
            onSuccess(res);
          }
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      setLoading(true);
      onAlertClose();
      await deleteGeneralSettings("status", selectedData.id).then((res) => {
        if (res?.code === 200) {
          onSuccess(res);
        }
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const onMarkDefault = (data) => {
    setRowLoading(data.id);
    editGeneralSettings("status", data.id, {
      ...data,
      isDefault: true,
    }).then((res) => {
      setRowLoading("");
      if (res?.code === 200) {
        let newData = dataList.map((x) => {
          if (x.id === data.id) {
            return { ...x, isDefault: true };
          }
          return { ...x, isDefault: false };
        });
        setDataList(newData);
      }
    });
  };

  const onModalClose = () => {
    setSelectedData(null);
    setDescriptionLength(0);
    setIsDefault(false);
    reset();
    onClose();
  };

  const onAlertCloseAction = () => {
    onDelete();
  };

  const handleDescriptionChange = (event) => {
    setDescriptionLength(event.target.value.length);
  };
  return (
    <div>
      <ProviderCommonContent
        tableHead={[
          props.permissions.edit || props.permissions.delete
            ? ["Status Name", "Description", ""]
            : ["Status Name", "Description"],
        ]}
        onEdit={editData}
        onAdd={() => {
          onOpen();
        }}
        title="Status"
        onDelete={onDeleteAlertOpenAction}
        loading={loading}
        dataList={dataList}
        onMarkDefault={onMarkDefault}
        rowLoading={rowLoading}
        permissions={props.permissions}
      />
      <Modal
        isOpen={isOpen}
        onClose={onModalClose}
        title={selectedData ? "Update Status" : "Add Status"}
        allowCloseButton={true}
        displayFooter={true}
        size={"md"}
        onSaveClick={handleSubmit(submitForm)}
        loading={loading}
        saveButtonTitle="Save Status"
        discardTitle="cancel"
      >
        <Stack spacing={2}>
          <form noValidate>
            <Flex>
              <TextBox
                type="text"
                name={"Status Name"}
                placeholder={"Status Name"}
                maxLength="56"
                required
                innerref={register("name", {
                  required: requiredField.replace("$Field$", "Status Name"),
                  pattern: {
                    value: whiteSpacePattern,
                    message: whiteSpace,
                  },
                })}
                validationErrors={errors}
                widthFull={true}
              />
            </Flex>
            <Flex>
              <TextArea
                placeholder={"Description"}
                innerref={register("description", {
                  onChange: (e) => handleDescriptionChange(e),
                })}
                maxLength={256}
                helperText={`${descriptionLength}/256`}
                validationErrors={errors}
                helperTextPosition={"right"}
                focusBorderColor="#0C94AC"
              />
            </Flex>
            <Flex>
              <Checkbox
                name={"isDefault"}
                isChecked={dataList.length === 0 ? true : isDefault}
                {...register("isDefault")}
                bgColor={"#"}
                onChange={(event) => setIsDefault(event.target.checked)}
                _checked={{
                  "& .chakra-checkbox__control": {
                    background: "#0C94AC",
                    border: "#0C94AC",
                  },
                }}
              >
                <Text
                  ml="5px"
                  fontSize={"14px"}
                  fontWeight={400}
                  color={"var(--title-black-text)"}
                >
                  Mark as default
                </Text>
              </Checkbox>
            </Flex>
          </form>
        </Stack>
      </Modal>
      <AlertDialog
        title="Delete Status"
        actionButtonName="Delete Status"
        isAlertOpen={isAlertOpen}
        body={
          <>
            Are you sure you want to delete {selectedData?.name}? You cannot
            undo this action afterward.
            <br />
            <br />
            <b>Note:</b> The default status will be assigned to all records
            currently bearing this status.
          </>
        }
        onAlertClose={() => {
          onAlertClose();
          onModalClose();
        }}
        onAlertCloseAction={onAlertCloseAction}
      />
    </div>
  );
};

export default ProviderStatus;
