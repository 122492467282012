import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, MoreFilterButton, MoreFilterDrawer } from "../..";
import CustomAlertDialog from "../../components/AlertDialog/CustomAlertDialog";
import CustomPopover from "../../components/CustomPopover/CustomPopover";
import DateFilter from "../../components/DateFilter/DateFilter";
import MultiCheckboxSelector from "../../components/MultiCheckboxSelector/MultiCheckboxSelector";
import SearchBar from "../../components/SearchBar/SearchBar";
import SelectFilterWithIcon from "../../components/Select/SelectFilterWithIcon";
import { menuItemStyles } from "../../constants/constantValues";
import partnerIcon from "../../images/user-plus.svg";
import AssetTable from "./AssetTable";
import AddNewFolderModal from "./modals/AddNewFolderModal";
import UploadFileModal from "./modals/UploadFileModal";
import UploadFolderModal from "./modals/UploadFolderModal";
import { updateRequestData } from "./utility";

const AssetListing = ({
  portal,
  userInfo,
  permissions,
  fetchPartnerOrProviderList,
  fetchAssetList,
  addNewFolder,
  deleteAsset,
  updateAsset,
  getPartnerTypes,
  getPartnerGroups,
  getPartnersByTypes,
  shareAsset,
  uploadFolder,
  downloadFolder,
  downloadFile,
  removeSharedPartners,
  getPreSignedUrl,
  uploadImage,
  saveAssetData,
  getTagListBySearch,
  createTag,
  updateAssetTagList,
}) => {
  const {
    onOpen: onSharedWithFilterOpen,
    onClose: onSharedWithFilterClose,
    isOpen: isSharedWithFilterOpen,
  } = useDisclosure();

  const {
    onOpen: onNewFolderModalOpen,
    onClose: onNewFolderModalClose,
    isOpen: isNewFolderModalOpen,
  } = useDisclosure();

  const {
    onOpen: onFolderUploadModalOpen,
    onClose: onFolderUploadModalClose,
    isOpen: isFolderUploadModalOpen,
  } = useDisclosure();

  const {
    onOpen: onFileUploadModalOpen,
    onClose: onFileUploadModalClose,
    isOpen: isFileUploadModalOpen,
  } = useDisclosure();

  const {
    onOpen: onDeleteAlertOpen,
    onClose: onDeleteAlertClose,
    isOpen: isDeleteAlertOpen,
  } = useDisclosure();

  let intialized = false;
  const [nextToken, setNextToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [addFolderLoading, setAddFolderLoading] = useState(false);
  const [deleteFolderLoading, setDeleteFolderLoading] = useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState({ to: "", from: "" });
  // Below given 2 states are for (Partners/Providers) based on the portal in which this component is used
  const [entityOptions, setEntityOptions] = useState([]);
  const [entitysList, setEntitysList] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [breadCrumbs, setBreadCrumbs] = useState([{ name: "Assets", id: 0 }]);
  // extra filters
  const [extraFilters, setExtraFilters] = useState({ systemTags: [] });
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [appliedExtraFilterCount, setAppliedExtraFilterCount] = useState(0);
  const [systemTags, setSystemTags] = useState([]);

  const getPartnerList = useCallback(async () => {
    const fetchedData = await fetchPartnerOrProviderList();
    // const partnerOrProviderList = fetchedData?.partnerList;
    const partnerOrProviderList = fetchedData?.partnerList?.filter(
      (partner) => partner.partnerStatus !== "Draft"
    );
    if (partnerOrProviderList && partnerOrProviderList.length > 0) {
      setEntitysList(partnerOrProviderList);
      const fetchedEntityOptions = partnerOrProviderList?.map((list) => ({
        name: portal === "sp" ? list?.scCompanyName : list?.spCompanyName,
        id: list?.referenceTenantId,
        isChecked: false,
      }));
      setEntityOptions(fetchedEntityOptions);
    }
  }, [fetchPartnerOrProviderList]);

  useEffect(() => {
    if (!intialized) {
      getPartnerList();
      fetchSystemTags();
      intialized = true;
    }
  }, [getPartnerList, portal]);

  useEffect(() => {
    setNextToken(null);
    setRefetch((prev) => !prev);
    let extraFilterCount = 0;
    if (extraFilters.systemTags?.length) extraFilterCount++;
    setAppliedExtraFilterCount(extraFilterCount);
  }, [extraFilters]);

  const fetchSystemTags = async () => {
    await getTagListBySearch().then((data) => setSystemTags(data));
  };

  let selectedSharedWithUsers = useMemo(() => {
    return entityOptions.filter((x) => x.isChecked);
  }, [entityOptions]);

  const onDateChange = (date) => {
    if (JSON.stringify(date) !== JSON.stringify(selectedDate)) {
      setLoading(true);
      setSelectedDate(date);
      setNextToken(null);
    }
  };

  const onFilterChange = (type, values) => {
    if (type === "sharedWithUser") {
      setEntityOptions([...values]);
      setLoading(true);
    }
  };

  const onDeleteClick = (data) => {
    setSelectedData(data);
    onDeleteAlertOpen();
  };

  const onCloseDelete = () => {
    setSelectedData(null);
    onDeleteAlertClose();
  };

  const onDeleteAsset = async () => {
    setLoading(true);
    setDeleteFolderLoading(true);
    try {
      await deleteAsset(selectedData?.id);
      onCloseDelete();
      setRefetch((prev) => !prev);
    } catch (error) {
      console.log("Error while deleting Asset", error);
    } finally {
      setDeleteFolderLoading(false);
    }
  };

  const canEditRecord = (record) => {};

  const canDeleteRecord = () => {};

  const handleAddEditAssetOrFolder = async (
    name,
    id,
    isEditing = false,
    assetType,
    description
  ) => {
    setAddFolderLoading(true);
    setLoading(true);
    try {
      let requestData = {
        name,
        createdById: userInfo?.id,
        createdByName: `${userInfo?.firstName} ${userInfo?.lastName}`,
      };
      if (assetType === "Asset") {
        requestData.description = description;
        updateRequestData(breadCrumbs, name, requestData, false);
      } else {
        updateRequestData(breadCrumbs, name, requestData);
      }

      if (isEditing) {
        await updateAsset(requestData, id);
      } else {
        await addNewFolder(requestData);
      }
      onNewFolderModalClose();
      setNextToken(null);
      setRefetch((prev) => !prev);
    } catch (error) {
      console.log("Error in Adding New Folder");
    } finally {
      setAddFolderLoading(false);
      setLoading(false);
    }
  };

  const handleBreadcrumbClick = (clickedBreadcrumbId) => {
    setLoading(true);
    setNextToken(null);
    setBreadCrumbs((prevCrumbs) => {
      const activeIndex = prevCrumbs.findIndex(
        (bc) => bc.id === clickedBreadcrumbId
      );
      if (activeIndex !== -1) {
        // Return a slice from the beginning up to the clicked breadcrumb (excluding it)
        return prevCrumbs.slice(0, activeIndex + 1);
      }
      return prevCrumbs; // Return the original array if activeIndex is not found
    });
    setRefetch((prev) => !prev);
  };

  const onSearchChangeOperations = () => {
    setLoading(true);
    setNextToken(null);
    setBreadCrumbs([{ name: "Assets", id: 0 }]);
  };

  const handleMoreFilterModalClose = () => {
    setShowMoreFilters(false);
  };

  return (
    <Box>
      <Flex w="100%" mb="16px" gap={"6px"} alignItems={"center"}>
        {breadCrumbs.map((x, i, all) => {
          return (
            <Flex alignItems={"center"} gap={"6px"} key={x.id}>
              <Text
                color={i === all.length - 1 ? "#111A29" : "#7E8792"}
                fontSize={"12px"}
                fontWeight={500}
                lineHeight={"18px"}
                cursor={"pointer"}
                textTransform={"capitalize"}
                onClick={() => handleBreadcrumbClick(x.id)}
              >
                {x.name}
              </Text>
              {i !== all.length - 1 && <ChevronRightIcon color={"#7E8792"} />}
            </Flex>
          );
        })}
      </Flex>

      <Flex w={"100%"} alignItems={"center"} gap={"20px"}>
        <SearchBar
          debounce
          value={globalFilterValue}
          onChange={(value) => {
            setGlobalFilterValue(value);
          }}
          onSearchChangeOperations={onSearchChangeOperations}
          placeholder="Search Items..."
        />
        <Divider bg="#E6E7E9" width={"2px"} h="38px" />
        <Stack position={"relative"}>
          <DateFilter
            customPopoverRight={"unset"}
            customPopoverTop={"calc(100% + 5px)"}
            handleDateChange={onDateChange}
            moduleForId="assets-list"
            showByDefaultAllTimeOption
          />
        </Stack>
        <Box position={"relative"}>
          <CustomPopover
            contentWidth="180px"
            labelComponent={
              <Flex
                bg={"#fff"}
                lineHeight={1}
                p="6px 12px"
                border="1px solid #E6E7E9"
                alignItems={"center"}
                borderRadius={"4px"}
                cursor={"pointer"}
                height={"36px"}
              >
                <SelectFilterWithIcon
                  icon={partnerIcon}
                  title={portal === "sp" ? "All Partners" : "All Providers"}
                  items={entityOptions}
                  fontWeight={450}
                />
              </Flex>
            }
            onOpen={onSharedWithFilterOpen}
            onClose={onSharedWithFilterClose}
            showPopover={isSharedWithFilterOpen}
            placement="bottom-start"
            bodyStyle={{ padding: "4px 6px" }}
            offset={[0, 5]}
            id="assets-list-shared-with-filter-popover"
          >
            <MultiCheckboxSelector
              showAllSelect={true}
              onSelectAll={() => {}}
              itemList={entityOptions}
              modalBtnClick={
                entityOptions.length === 0
                  ? false
                  : (newItems) => {
                      if (
                        JSON.stringify(newItems) !==
                        JSON.stringify(entityOptions)
                      ) {
                        onFilterChange("sharedWithUser", newItems);
                      }
                      onSharedWithFilterClose();
                    }
              }
              isModalBtnDisabled={false}
              isPopoverOpen={isSharedWithFilterOpen}
              btnText="Done"
            />
          </CustomPopover>
        </Box>
        <MoreFilterButton
          appliedMoreFilterCount={appliedExtraFilterCount}
          setShowMoreFilters={setShowMoreFilters}
          showMoreFilters={showMoreFilters}
          moduleForId="asset-list"
        />
        <Spacer />
        {portal === "sp" && permissions.add && (
          <Stack>
            <Menu placement="bottom-end" id="assets-add-new-dropdown">
              {({ isOpen }) => (
                <>
                  <Box display={"flex"}>
                    <Button
                      variant={portal === "sp" ? "newColorThemePrimary" : null}
                      onClick={() => {
                        setSelectedData(null);
                        onNewFolderModalOpen();
                      }}
                      ml="16px"
                      title="New"
                      borderRightRadius="0"
                      id="assets-add-new-modal-open"
                    />
                    <MenuButton isActive={isOpen}>
                      <IconButton
                        borderLeftRadius="0"
                        ml="1px"
                        color={"#fff"}
                        variant={
                          portal === "sp" ? "newColorThemePrimary" : null
                        }
                        icon={
                          isOpen ? (
                            <ChevronUpIcon h="25px" w="25px" />
                          ) : (
                            <ChevronDownIcon h="25px" w="25px" />
                          )
                        }
                        id="assets-add-new-modal-up-down"
                      />
                    </MenuButton>
                  </Box>
                  <MenuList
                    minW={"140px"}
                    zIndex={"popover"}
                    fontSize={"14px"}
                    letterSpacing={"0.14px"}
                    color={"#111A29"}
                    border={"1px solid #E6E7E9"}
                    rounded={"4px"}
                    p={"6px"}
                  >
                    <MenuItem
                      onClick={onNewFolderModalOpen}
                      {...menuItemStyles}
                      id="assets-add-new-folder"
                    >
                      Add Folder
                    </MenuItem>
                    <MenuItem
                      onClick={onFolderUploadModalOpen}
                      {...menuItemStyles}
                      id="assets-upload-new-folder"
                    >
                      Upload Folder
                    </MenuItem>
                    <MenuItem
                      onClick={onFileUploadModalOpen}
                      {...menuItemStyles}
                      id="assets-upload-new-file"
                    >
                      Upload File
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
          </Stack>
        )}
      </Flex>
      <AssetTable
        portal={portal}
        selectedDate={selectedDate}
        refetch={refetch}
        setRefetch={setRefetch}
        selectedSharedWithUsers={selectedSharedWithUsers}
        globalFilterValue={globalFilterValue}
        setGlobalFilterValue={setGlobalFilterValue}
        nextToken={nextToken}
        setNextToken={setNextToken}
        onDeleteClick={onDeleteClick}
        entitysList={entitysList}
        fetchAssetList={fetchAssetList}
        breadCrumbs={breadCrumbs}
        setBreadCrumbs={setBreadCrumbs}
        onNewFolderModalOpen={onNewFolderModalOpen}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        getPartnerTypes={getPartnerTypes}
        getPartnerGroups={getPartnerGroups}
        getPartnersByTypes={getPartnersByTypes}
        downloadFolder={downloadFolder}
        downloadFile={downloadFile}
        shareAsset={shareAsset}
        removeSharedPartners={removeSharedPartners}
        userInfo={userInfo}
        loading={loading}
        setLoading={setLoading}
        permissions={permissions}
        getTagListBySearch={getTagListBySearch}
        createTag={createTag}
        updateAssetTagList={updateAssetTagList}
        extraFilters={extraFilters}
        fetchPartnerOrProviderList={fetchPartnerOrProviderList}
      />
      <AddNewFolderModal
        isOpen={isNewFolderModalOpen}
        onClose={onNewFolderModalClose}
        portal={portal}
        data={isNewFolderModalOpen ? selectedData : null}
        setSelectedData={setSelectedData}
        handleAddEditAssetOrFolder={handleAddEditAssetOrFolder}
        addFolderLoading={addFolderLoading}
      />
      <UploadFileModal
        isOpen={isFileUploadModalOpen}
        onClose={onFileUploadModalClose}
        portal={portal}
        userInfo={userInfo}
        setRefetch={setRefetch}
        breadCrumbs={breadCrumbs}
        setNextToken={setNextToken}
        setLoading={setLoading}
        getPreSignedUrl={getPreSignedUrl}
        uploadImage={uploadImage}
        saveAssetData={saveAssetData}
      />
      <UploadFolderModal
        isOpen={isFolderUploadModalOpen}
        onClose={onFolderUploadModalClose}
        portal={portal}
        uploadFolder={uploadFolder}
        userInfo={userInfo}
        setRefetch={setRefetch}
        breadCrumbs={breadCrumbs}
        setNextToken={setNextToken}
        setLoading={setLoading}
        getPreSignedUrl={getPreSignedUrl}
        uploadImage={uploadImage}
        saveAssetData={saveAssetData}
        moduleForId="asset-list"
      />
      <CustomAlertDialog
        title="Delete "
        spDesign
        actionButtonName="Delete "
        isAlertOpen={isDeleteAlertOpen}
        onAlertClose={onCloseDelete}
        body={
          <>
            Are you sure you want to delete {selectedData?.name}? You cannot
            undo this action afterward.
          </>
        }
        onAlertCloseAction={() => onDeleteAsset()}
        loading={deleteFolderLoading}
      />
      <MoreFilterDrawer
        isOpen={showMoreFilters}
        onModalClose={handleMoreFilterModalClose}
        setFilters={setExtraFilters}
        moreFilters={[
          {
            title: "Tags",
            key: "systemTags",
            options: systemTags.map((item) => ({
              name: item.name,
              value: item.id,
              isChecked: extraFilters.systemTags?.includes(item.id)
                ? true
                : false,
            })),
            allowSearch: true,
          },
        ]}
        moduleForId="assets"
      />
    </Box>
  );
};

export default AssetListing;
