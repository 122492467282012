import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import React from "react";

const CustomPopover = ({
  popoverRef,
  showPopover,
  onClose,
  onOpen,
  labelComponent,
  labelComponentWidth = undefined,
  ...props
}) => {
  return (
    <Popover
      initialFocusRef={popoverRef}
      isOpen={showPopover}
      closeOnBlur={true}
      onClose={onClose}
      offset={props.offset ? props.offset : [0, 0]}
      {...props}
    >
      <PopoverTrigger>
        <button
          type="button"
          style={{ width: labelComponentWidth || "100%" }}
          onClick={(e) => {
            e.preventDefault();
            showPopover ? onClose() : onOpen();
          }}
          disabled={props.isDisabled ? props.isDisabled : false}
        >
          {labelComponent}
        </button>
      </PopoverTrigger>
      <Portal appendToParentPortal={false}>
        <PopoverContent
          zIndex={"popover"}
          width={`${props.contentWidth || "200px"} !important`}
        >
          <PopoverBody style={props.bodyStyle}>{props.children}</PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default CustomPopover;
