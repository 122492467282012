// install yarn add @nivo/line (please try to align the version of installed @nivo packages)
import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import { DotsItem, useTheme } from "@nivo/core";
import { ResponsiveLine } from "@nivo/line";
import { useMemo } from "react";
import { regionLabelMap } from "../../constants/dashboard";
import {
  normalizeSingleLineChartArray,
  convertToLabel,
  findCorrespondingTrendValue,
  formatTrenDate,
  formatYAxisTicks,
} from "../../services/dashboardUtility/utility";
import { getCurrency } from "../../services/utility";

// make sure parent container have a defined height when using responsive component, otherwise height will be 0 and no chart will be rendered
const Line = ({
  // prevData,
  // selectedDate,
  // daysBetweenDates,
  data,
  isCompared,
  bottomLegend,
  leftLegend,
  chartFor,
}) => {
  const lineChartData = useMemo(() => {
    const firstDataset =
      data.filter((obj) => obj.data.some((item) => item.y !== 0))[
        data.length - 1
      ]?.data || [];
    const hasSingleDataPoint = data.length > 0 && firstDataset?.length === 1;
    if (hasSingleDataPoint) {
      const updatedData = normalizeSingleLineChartArray(
        firstDataset[0].x,
        firstDataset
      );
      data[data.length - 1].data = updatedData;
    }
    return data;
  }, [data]);

  const formatValue = (value, isCurrency = false) => {
    const formattedValue = formatYAxisTicks(value) || 0;
    return isCurrency
      ? `${getCurrency().symbol}${formattedValue}`
      : formattedValue;
  };

  const getTooltipText = (id, isPrev, labelMap, useConvertToLabel) => {
    const processedId = isPrev ? id.slice(5) : id;
    if (labelMap) {
      return labelMap[processedId];
    }
    if (useConvertToLabel) {
      return convertToLabel(processedId);
    }
    return processedId;
  };

  const tooltipTextMap = (id, isPrev, currentValue, previousValue) => {
    const commonProps = {
      formattedCurrentValue: formatValue(
        currentValue,
        chartFor.includes("Revenue") || chartFor.includes("Commission")
      ),
      formattedPreviousValue: formatValue(
        previousValue,
        chartFor.includes("Revenue") || chartFor.includes("Commission")
      ),
    };

    switch (chartFor) {
      case "Leads":
      case "Deals":
        return {
          ...commonProps,
          tooltip: `${getTooltipText(id, isPrev)} ${chartFor} Count`,
          prevTooltip: "Previous Count",
        };

      case "RevenueRegionTrend":
        return {
          ...commonProps,
          tooltip: `${getTooltipText(id, isPrev, regionLabelMap)} Revenue`,
          prevTooltip: "Previous Revenue",
        };

      case "RevenueProductTrend":
        return {
          ...commonProps,
          tooltip: `${getTooltipText(id, isPrev)} Revenue`,
          prevTooltip: "Previous Revenue",
        };

      case "RecurringRevenueTrend":
      case "OpenDealRevenue":
      case "WonDealRevenue":
        return {
          ...commonProps,
          tooltip: getTooltipText(id, isPrev, null, true),
          prevTooltip: "Previous Revenue",
        };

      case "AvgOnboardingTime":
      case "PartnerCount":
        return {
          ...commonProps,
          tooltip: getTooltipText(id, isPrev, null, true),
          prevTooltip: `Previous ${
            chartFor === "AvgOnboardingTime" ? "Onboarding Time" : "Count"
          }`,
        };

      case "LeadWinTime":
        return {
          ...commonProps,
          tooltip: `${getTooltipText(id, isPrev, null, true)}`,
          prevTooltip: "Previous Period",
        };
      case "CommissionTimePeriod":
        return {
          ...commonProps,
          tooltip: `${getTooltipText(id, isPrev, null, true)}`,
          prevTooltip: "Previous Period Commission",
        };

      default:
        return id;
    }
  };

  const DashedLine = ({
    lineGenerator,
    xScale,
    yScale,
    isInteractive,
    ...props
  }) => {
    return props.series.map((chartData) => {
      const customStyle = chartData?.isPrev
        ? {
            strokeDasharray: "6, 6",
            strokeWidth: 2,
          }
        : {
            strokeWidth: 2,
          };
      return (
        <path
          key={chartData?.id}
          d={lineGenerator(
            chartData?.data.map((d) => ({
              x: xScale(d.data.x),
              y: yScale(d.data.y),
            }))
          )}
          fill="none"
          stroke={chartData?.color}
          style={customStyle}
        />
      );
    });
  };

  const ActivePoint = (props) => {
    const theme = useTheme();
    if (!props.currentPoint) return;

    const { currentPoint } = props;

    return (
      <g>
        <DotsItem
          key={currentPoint?.id}
          x={currentPoint?.x}
          y={currentPoint?.y}
          datum={currentPoint.data}
          size={12}
          color={"#fff"}
          borderWidth={"2px"}
          borderColor={currentPoint?.color}
          theme={theme}
        />
      </g>
    );
  };

  return (
    <ResponsiveLine
      data={lineChartData}
      margin={{ top: 20, right: 45, bottom: 95, left: 75 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: false,
        reverse: false,
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 8,
        tickRotation: -45,
        truncateTickAt: 25,
        legend: `${bottomLegend || "Date"} \u{1F862}`,
        legendPosition: "middle",
        legendOffset: 85,
        format: (e) => formatTrenDate(e),
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 13,
        tickRotation: 0,
        truncateTickAt: 12,
        legend: `${leftLegend || "Count"} \u{1F862}`,
        legendPosition: "middle",
        legendOffset: -70,
        format: (e) => formatYAxisTicks(e),
      }}
      colors={(e) => e.color}
      enableCrosshair
      crosshairType="bottom"
      useMesh={true}
      tooltip={(e) => {
        const { data, serieId, serieColor } = e?.point;
        const counterPartValue = findCorrespondingTrendValue(
          serieId,
          data?.x,
          lineChartData
        );
        const isPrev = serieId?.includes("prev-");
        const currentValue = isPrev ? counterPartValue ?? 0 : data?.y ?? 0;
        const previousValue = isPrev ? data?.y ?? 0 : counterPartValue ?? 0;
        const {
          tooltip,
          prevTooltip,
          formattedCurrentValue,
          formattedPreviousValue,
        } = tooltipTextMap(serieId, isPrev, currentValue, previousValue);

        return (
          <Box padding="8px 12px" rounded="4px" bg="#1C2C44">
            {/* <Text
              color={"#B7C8E1"}
              fontSize={"12px"}
              fontWeight={700}
              mb={"4px"}
            >
              {data?.xFormatted}
            </Text> */}
            <Flex alignItems={"center"} gap={"4px"}>
              <Box bg={serieColor} boxSize={"8px"} rounded={"1px"}></Box>
              <Text color="white" fontSize="12px" fontWeight={500}>
                {tooltip}
              </Text>
              <Spacer />
              <Text color="white" fontSize="14px" fontWeight={600} ml={"20px"}>
                {formattedCurrentValue}
              </Text>
            </Flex>
            {isCompared && (
              <Flex alignItems={"center"} gap={"4px"}>
                <Box
                  bg="transparent"
                  boxSize="8px"
                  rounded="1px"
                  border={`1px dashed ${serieColor}`}
                ></Box>
                <Text color="white" fontSize="12px" fontWeight={500}>
                  {prevTooltip}
                </Text>
                <Spacer />
                <Text color="white" fontSize="14px" fontWeight={600} ml="20px">
                  {formattedPreviousValue}
                </Text>
              </Flex>
            )}
          </Box>
        );
      }}
      layers={[
        "grid",
        "crosshair",
        "markers",
        "areas",
        "slices",
        "axes",
        DashedLine,
        ActivePoint,
        "legends",
        "mesh",
      ]}
      theme={{
        grid: {
          line: {
            stroke: "#F3F4F6",
            strokeWidth: "1.4px",
            strokeDasharray: "3, 3",
          },
        },
        axis: {
          ticks: {
            text: {
              fontSize: "12px",
              fontWeight: 500,
              fill: "#7E8792",
            },
          },
          domain: {
            line: {
              stroke: "#E6E7E9",
              strokeWidth: "1px",
            },
          },
          legend: {
            text: {
              fontSize: "12px",
              fontWeight: 600,
              fill: "#5E6977",
            },
          },
        },
        crosshair: {
          line: {
            stroke: "#E6E7E9",
            strokeWidth: "2px",
            strokeDasharray: "3,3",
          },
        },
        legends: {
          text: {
            fontSize: "12px",
            fontWeight: 550,
            fill: "#5E6977",
          },
        },
      }}
    />
  );
};

export default Line;
