import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../services/axiosInstance";
import { getErrorMessage } from "../../services/utility";

export const addSoftwareSpecialisation = createAsyncThunk(
  "add/softwareSpecialisation",
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        "/softwareSpecialization/add",
        requestData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getSoftwareSpecialisation = createAsyncThunk(
  "get/softwareSpecialisation",
  async (referenceTenantId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        referenceTenantId
          ? `/softwareSpecialization?partnerTenantId=${referenceTenantId}`
          : "/softwareSpecialization"
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateSoftwareSpecialisation = createAsyncThunk(
  "update/softwareSpecialisation",
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(
        `/softwareSpecialization`,
        requestData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const deleteSoftwareSpecialisation = createAsyncThunk(
  "update/softwareSpecialisation",
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(`/softwareSpecialization`, {
        data: requestData,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getSoftwareList = async (token) => {
  try {
    // configure header's Content-Type as JSON
    const { data } = await axiosInstance.get(`/softwareSpecialization/list`);
    return data;
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return error.response.data;
    } else {
      return error.message;
    }
  }
};

export const getAllSoftwareList = createAsyncThunk(
  "get/getAllSoftwareList",
  async (softwareSpecialisationId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/softwareSpecialization/list`);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const addConfiguration = createAsyncThunk(
  "add/configuration",
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        "/softwareBundle/add",
        requestData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

// export const getConfigurationList = async () => {
//   try {
//     // configure header's Content-Type as JSON
//     const { data } = await axiosInstance.get(`/softwareBundle`);
//     return data;
//   } catch (error) {
//     // return custom error message from API if any
//     if (error.response && error.response.data.message) {
//       return error.response.data;
//     } else {
//       return error.message;
//     }
//   }
// };

export const getConfigurationList = createAsyncThunk(
  "get/configuration",
  async (referenceTenantId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        referenceTenantId
          ? `/softwareBundle?partnerTenantId=${referenceTenantId}`
          : `/softwareBundle`
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateConfiguration = createAsyncThunk(
  "update/configuration",
  async ({ configurationId, requestData }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(
        `/softwareBundle/${configurationId}`,
        requestData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const deleteConfiguration = createAsyncThunk(
  "delete/configuration",
  async (configurationId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(
        `/softwareBundle/${configurationId}`
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const requestToAddSoftware = createAsyncThunk(
  "softwareSpecialization/addSoftwareRequest",
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        "/softwareSpecialization/addSoftwareRequest",
        requestData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
