import {
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  subDays,
  subMonths,
  subQuarters,
  subWeeks,
} from "date-fns";
import ErrorNoti from "../images/error-noti.svg";
import InfoNoti from "../images/info-noti.svg";
import SuccessNoti from "../images/success-noti.svg";
import WarningNoti from "../images/warning-noti.svg";

export const userRoleEnums = {
  manager: "Manager",
  owner: "Owner",
  admin: "Admin",
  user: "User",
};

// This week
export const thisWeekStart = startOfWeek(new Date());
export const thisWeekEnd = endOfWeek(new Date());

// This month
export const thisMonthStart = startOfMonth(new Date());
export const thisMonthEnd = endOfMonth(new Date());

// This quarter
export const thisQuarterStart = startOfQuarter(new Date());
export const thisQuarterEnd = endOfQuarter(new Date());

// Last week
export const lastWeekStart = startOfWeek(subWeeks(new Date(), 1));
export const lastWeekEnd = endOfWeek(subWeeks(new Date(), 1));

// Last month
export const lastMonthStart = startOfMonth(subMonths(new Date(), 1));
export const lastMonthEnd = endOfMonth(subMonths(new Date(), 1));

// Last quarter
export const lastQuarterStart = startOfQuarter(subQuarters(new Date(), 1));
export const lastQuarterEnd = endOfQuarter(subQuarters(new Date(), 1));

// Last 30 days
export const last30DaysStart = subDays(new Date(), 30);
export const last30DaysEnd = new Date();

export const dateConstants = [
  {
    label: "Last 30 days",
    dateObj: { startDate: last30DaysStart, endDate: last30DaysEnd },
  },
  {
    label: "This week",
    dateObj: { startDate: thisWeekStart, endDate: thisWeekEnd },
  },
  {
    label: "This month",
    dateObj: { startDate: thisMonthStart, endDate: thisMonthEnd },
  },
  {
    label: "This quarter",
    dateObj: { startDate: thisQuarterStart, endDate: thisQuarterEnd },
  },
  {
    label: "Last week",
    dateObj: { startDate: lastWeekStart, endDate: lastWeekEnd },
  },
  {
    label: "Last month",
    dateObj: { startDate: lastMonthStart, endDate: lastMonthEnd },
  },
  {
    label: "Last quarter",
    dateObj: { startDate: lastQuarterStart, endDate: lastQuarterEnd },
  },
];

export const dateOptions = [
  { label: "This Week", value: "this-week" },
  { label: "This Month", value: "this-month" },
  { label: "Last Week", value: "last-week" },
  { label: "Last Month", value: "last-month" },
  { label: "This Quarter", value: "this-quarter" },
  { label: "Last Quarter", value: "last-quarter" },
  { label: "Custom", value: "custom" },
];

export const statusColors = [
  {
    background: "#E3FAF7",
    color: "#064293",
  },
  {
    background: "#E3FAF7",
    color: "#06A293",
  },
  {
    background: "#FEF9E5",
    color: "#C39E00",
  },
  {
    background: "#F9FAFB",
    color: "#5E6977",
  },
  {
    background: "#EBF5FF",
    color: "#1C64F2",
  },
  {
    background: "#FDF2F2",
    color: "#E02424",
  },
  {
    background: "#FCECEF",
    color: "#B3324E",
  },
  {
    background: "#FFF8F1",
    color: "#D03801",
  },
  {
    background: "#FDFDEA",
    color: "#9F580A",
  },
  {
    background: "#E7FAFD",
    color: "#0A768A",
  },
];
export const budgetOptionsObj = {
  "Less than 5,000": "Less than #cur* 5,000",
  "5,000 - 10,000": "#cur* 5,000 - #cur* 10,000",
  "10,000 - 30,000": "#cur* 10,000 - #cur* 30,000",
  "30,000 - 50,000": "#cur* 30,000 - #cur* 50,000",
  "More than 50,000": "More than #cur* 50,000",
};

export const budgetValueObj = {
  "Less than 5,000": 5000,
  "5,000 - 10,000": 10000,
  "10,000 - 30,000": 30000,
  "30,000 - 50,000": 50000,
  "More than 50,000": 50000,
};
export const dropdownBudgetOptions = [
  {
    label: budgetOptionsObj["Less than 5,000"],
    value: "Less than 5,000",
  },
  {
    label: budgetOptionsObj["5,000 - 10,000"],
    value: "5,000 - 10,000",
  },
  {
    label: budgetOptionsObj["10,000 - 30,000"],
    value: "10,000 - 30,000",
  },
  {
    label: budgetOptionsObj["30,000 - 50,000"],
    value: "30,000 - 50,000",
  },
  {
    label: budgetOptionsObj["More than 50,000"],
    value: "More than 50,000",
  },
];

export const serviceCategoryOptions = [
  { label: "Software Integration", value: "Software Integration" },
  { label: "Software Implementation", value: "Software Implementation" },
  { label: "Business Consulting", value: "Business Consulting" },
  { label: "Accounting Services", value: "Accounting Services" },
  {
    label: "Strategic Planning Technology",
    value: "Strategic Planning Technology",
  },
  {
    label: "Assessment and Recommendations",
    value: "Assessment and Recommendations",
  },
  {
    label: "Legacy System Modernization",
    value: "Legacy System Modernization",
  },
  {
    label: "Data Analytics and Business Intelligence",
    value: "Data Analytics and Business Intelligence",
  },
  {
    label: "Cloud Services and Migration",
    value: "Cloud Services and Migration",
  },
  {
    label: "Vendor / Software Selection",
    value: "Vendor / Software Selection",
  },
  { label: "Project Management", value: "Project Management" },
  {
    label: "Custom Software Development",
    value: "Custom Software Development",
  },
  {
    label: "Quality Assurance and Testing",
    value: "Quality Assurance and Testing",
  },
  { label: "Cybersecurity Consulting", value: "Cybersecurity Consulting" },
  { label: "Performance Optimization", value: "Performance Optimization" },
  {
    label: "Software Maintenance and Support",
    value: "Software Maintenance and Support",
  },
  {
    label: "Market Research and Feasibility Studies",
    value: "Market Research and Feasibility Studies",
  },
  { label: "Data Migration", value: "Data Migration" },
  { label: "Designing Services", value: "Designing Services" },
  { label: "Training", value: "Training" },
  { label: "Mobile App Development", value: "Mobile App Development" },
  { label: "Bookkeeping", value: "Bookkeeping" },
];

export const companySizeOptions = [
  {
    label: "Self-employed",
    value: "Self-employed",
  },
  {
    label: "1-10 employees",
    value: "1-10 employees",
  },
  {
    label: "11-50 employees",
    value: "11-50 employees",
  },
  {
    label: "51-200 employees",
    value: "51-200 employees",
  },
  {
    label: "201-500 employees",
    value: "201-500 employees",
  },
  {
    label: "501-1000 employees",
    value: "501-1000 employees",
  },
  {
    label: "More than 1000 employees",
    value: "More than 1000 employees",
  },
];

export const serviceTimingOptions = [
  {
    label: "Less than in 2 weeks",
    value: "Less than in 2 weeks",
  },
  {
    label: "In 2 - 4 weeks",
    value: "In 2 - 4 weeks",
  },
  {
    label: "In 4 - 6 weeks",
    value: "In 4 - 6 weeks",
  },
  {
    label: "In 6 - 9 weeks",
    value: "In 6 - 9 weeks",
  },
  {
    label: "In 9 - 12 weeks",
    value: "In 9 - 12 weeks",
  },
  {
    label: "In 12 - 16 weeks",
    value: "In 12 - 16 weeks",
  },
  {
    label: "More than in 16 weeks",
    value: "More than in 16 weeks",
  },
];

export const companyRevenueObj = {
  "Less than 1M/Year": "Less than #cur*1M/Year",
  "1-5M/Year": "#cur*1-5M/Year",
  "5-10M/Year": "#cur*5-10M/Year",
  "10-25M/Year": "#cur*10-25M/Year",
  "More than 25M/Year": "More than #cur*25M/Year",
};
export const companyRevenueValueObj = {
  "Less than 1M/Year": 1,
  "1-5M/Year": 5,
  "5-10M/Year": 10,
  "10-25M/Year": 25,
  "More than 25M/Year": 25,
};
export const companyRevenueOptions = [
  {
    label: companyRevenueObj["Less than 1M/Year"],
    value: "Less than 1M/Year",
  },
  {
    label: companyRevenueObj["1-5M/Year"],
    value: "1-5M/Year",
  },
  {
    label: companyRevenueObj["5-10M/Year"],
    value: "5-10M/Year",
  },
  {
    label: companyRevenueObj["10-25M/Year"],
    value: "10-25M/Year",
  },
  {
    label: companyRevenueObj["More than 25M/Year"],
    value: "More than 25M/Year",
  },
];

export const communicationTypeOptions = [
  { label: "Email", value: "Email" },
  { label: "Phone", value: "Phone" },
  { label: "Offline", value: "Offline" },
];

export const languageOptions = [
  { label: "English", value: "en" },
  { label: "Spanish", value: "es" },
  { label: "Portuguese", value: "pt" },
  { label: "French", value: "fr" },
  { label: "German", value: "de" },
  { label: "Italian", value: "it" },
  { label: "Japanese", value: "ja" },
  { label: "Hindi", value: "hi" },
  { label: "Arabic", value: "ar" },
  { label: "Chinese", value: "zh" },
];

export const csvFileFormat = "text/csv";
export const csvFileFormatFireFox = "application/vnd.ms-excel";

export const notificationIcons = {
  success: SuccessNoti,
  error: ErrorNoti,
  warning: WarningNoti,
  info: InfoNoti,
};
export const profileStatuses = ["Published", "Not Published"];

export const scrollbarCSS = {
  "&::-webkit-scrollbar": { width: "8px", height: "8px" },
  "&::-webkit-scrollbar-track": { width: "8px" },
  "&::-webkit-scrollbar-thumb": { background: "#E6E7E9", borderRadius: "8px" },
};

export const leadColumnsPartnerCard = [
  "select",
  "type",
  "firstName",
  "lastName",
  "companyName",
  "source",
  "spLeadOwner",
  "status",
  "accepted-on",
  "convert",
];
export const dealColumnsPartnerCard = [
  "select",
  "type",
  "firstName",
  "lastName",
  "companyName",
  "source",
  "dealOwner",
  "stage",
  "accepted-on",
];

export const buttonVariants = {
  brandPrimary: "brandPrimary",
  brandPrimaryOutline: "brandPrimaryOutline",
  brandPrimaryLinkButton: "brandPrimaryLinkButton",
  greyFill: "greyFill",
  greyOutline: "greyOutline",
  greyLinkButton: "greyLinkButton",
  dangerFill: "dangerFill",
  dangerOutline: "dangerOutline",
};

export const customStylesForReactSelect = {
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      fontSize: "14px",
      color: "#718096",
      paddingLeft: "4px",
    };
  },
  control: (styles, state) => ({
    ...styles,
    boxShadow: "none",
    paddingLeft: "4px",
    border: `1px solid ${
      state.isFocused
        ? "var(--chakra-colors-cyprus-500)"
        : "var(--chakra-colors-brandGray-101)"
    }`,
    "&:hover": {
      borderColor: "#CBD5E0",
    },
  }),
  multiValue: (provided, state) => ({
    ...provided,
    color: "var(--chakra-colors-atlantic-700)",
    fontSize: "12px",
    fontWeight: 500,
    backgroundColor: "var(--chakra-colors-atlantic-50)",
    border: "1px solid var(--chakra-colors-atlantic-100)",
    borderRadius: "16px",
    overflow: "hidden",
    padding: "2px 4px",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "var(--chakra-colors-atlantic-700)",
    fontSize: "12px",
    fontWeight: 500,
    backgroundColor: "var(--chakra-colors-atlantic-50)",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: "var(--chakra-colors-brandGray-700)",
    backgroundColor: "var(--chakra-colors-atlantic-50)",
    ":hover": {
      backgroundColor: "transparent",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "var(--chakra-colors-atlantic-700)",
    fontSize: "14px",
    fontWeight: 400,
    overflow: "hidden",
    padding: "2px 4px",
  }),
  option: (provided, { isDisabled, isFocused }) => ({
    ...provided,
    backgroundColor: isDisabled
      ? "#F9FAFB"
      : isFocused
      ? "#E7FAFD"
      : "transparent",
    borderRadius: "4px",
    color: "var(--chakra-colors-atlantic-700)",
    fontSize: "14px",
    fontWeight: 400,
    cursor: isDisabled ? "not-allowed" : "default",
  }),
  menu: (provided, state) => ({
    ...provided,
    padding: "4px",
    borderRadius: "4px",
  }),
  menuList: (provided, state) => ({
    ...provided,
    overflow: "auto",
    padding: "2px",
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "4px",
    },
    "&::-webkit-scrollbar-track": {
      width: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#E6E7E9",
      borderRadius: "4px",
    },
  }),
};

export const singleSelectStyles = {
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled
      ? undefined
      : isSelected
      ? "#E7FAFD"
      : isFocused
      ? "#E7FAFD"
      : undefined,
    color: "#111A29",
    ":active": {
      ...styles[":active"],
      backgroundColor: !isDisabled
        ? isSelected
          ? "#E7FAFD"
          : "#fff"
        : undefined,
    },
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      fontSize: "14px",
      color: "#718096",
    };
  },
  control: (styles) => ({
    ...styles,
    boxShadow: "none",
    border: `1px solid #E6E7E9`,
    "&:active": {
      borderColor: "#0C94AC",
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#111A29",
    fontSize: "14px",
  }),
};

export const tableCss = {
  thead: {
    tr: {
      th: {
        color: "#5E6977",
        fontSize: "12px",
        fontWeight: 600,
        textTransform: "capitalize",
        padding: "12px 16px",
        whiteSpace: "nowrap",
        background: "#F9FAFB",
      },
    },
  },
  tbody: {
    tr: {
      td: {
        padding: "16px",
        fontSize: "14px",
        fontWeight: 450,
        lineHeight: "20px",
        color: "var(--chakra-colors-atlantic-700)",
      },
    },
  },
  "& tr td:first-of-type, & tr th:first-of-type": {
    paddingLeft: "24px",
  },
  "& tr td:last-of-type, & tr th:last-of-type": {
    paddingRight: "24px",
  },
  "& tr": {
    backgroundColor: "var(--chakra-colors-white)",
    "&:hover": {
      backgroundColor: "var(--chakra-colors-brandGray-51)",
    },
  },
};

export const menuItemStyles = {
  color: "#111A29",
  rounded: "4px",
  p: "6px 8px",
  fontSize: "14px",
  lineHeight: "20px",
  fontWeight: 450,
  _hover: { bg: "#E7FAFD" },
  _focus: { bg: "#E7FAFD" },
};

export const currenciesList = [
  {
    id: 1,
    name: "United States Dollar",
    code: "USD",
    symbol: "$",
    countryCode: "US",
    country: "United States",
  },
  {
    id: 2,
    name: "Japanese Yen",
    code: "JPY",
    symbol: "¥",
    countryCode: "JP",
    country: "Japan",
  },
  {
    id: 3,
    name: "Bulgarian Lev",
    code: "BGN",
    symbol: "лв",
    countryCode: "BG",
    country: "Bulgaria",
  },
  {
    id: 4,
    name: "Czech Koruna",
    code: "CZK",
    symbol: "Kč",
    countryCode: "CZ",
    country: "Czech Republic",
  },
  {
    id: 5,
    name: "Danish Krone",
    code: "DKK",
    symbol: "kr",
    countryCode: "DK",
    country: "Denmark",
  },
  {
    id: 6,
    name: "British Poundsterling",
    code: "GBP",
    symbol: "£",
    countryCode: "UK",
    country: "United Kingdom",
  },
  {
    id: 7,
    name: "Hungarian Forint",
    code: "HUF",
    symbol: "Ft",
    countryCode: "HU",
    country: "Hungary",
  },
  {
    id: 8,
    name: "Polish Złoty",
    code: "PLN",
    symbol: "zł",
    countryCode: "PL",
    country: "Poland",
  },
  {
    id: 9,
    name: "Romanian Leu",
    code: "RON",
    symbol: "lei",
    countryCode: "RO",
    country: "Romania",
  },
  {
    id: 10,
    name: "Swedish Krona",
    code: "SEK",
    symbol: "kr",
    countryCode: "SE",
    country: "Sweden",
  },
  {
    id: 11,
    name: "Swiss Franc",
    code: "CHF",
    symbol: "CHF",
    countryCode: "CH",
    country: "Switzerland",
  },
  {
    id: 12,
    name: "Icelandic Króna",
    code: "ISK",
    symbol: "kr",
    countryCode: "IS",
    country: "Iceland",
  },
  {
    id: 13,
    name: "Norwegian Krone",
    code: "NOK",
    symbol: "kr",
    countryCode: "NO",
    country: "Norway",
  },
  {
    id: 14,
    name: "Turkish Lira",
    code: "TRY",
    symbol: "₺",
    countryCode: "TR",
    country: "Turkey",
  },
  {
    id: 15,
    name: "Australian Dollar",
    code: "AUD",
    symbol: "A$",
    countryCode: "AU",
    country: "Australia",
  },
  {
    id: 16,
    name: "Brazilian Real",
    code: "BRL",
    symbol: "R$",
    countryCode: "BR",
    country: "Brazil",
  },
  {
    id: 17,
    name: "Canadian Dollar",
    code: "CAD",
    symbol: "CA$",
    countryCode: "CA",
    country: "Canada",
  },
  {
    id: 18,
    name: "Chinese Renminbi",
    code: "CNY",
    symbol: "¥",
    countryCode: "CN",
    country: "China",
  },
  {
    id: 19,
    name: "Hong Kong Dollar",
    code: "HKD",
    symbol: "HK$",
    countryCode: "HK",
    country: "Hong Kong",
  },
  {
    id: 20,
    name: "Indonesian Rupiah",
    code: "IDR",
    symbol: "Rp",
    countryCode: "ID",
    country: "Indonesia",
  },
  {
    id: 21,
    name: "Israeli New Shekel",
    code: "ILS",
    symbol: "₪",
    countryCode: "IL",
    country: "Israel",
  },
  {
    id: 22,
    name: "Indian Rupee",
    code: "INR",
    symbol: "₹",
    countryCode: "IN",
    country: "India",
  },
  {
    id: 23,
    name: "South Korean Won",
    code: "KRW",
    symbol: "₩",
    countryCode: "KR",
    country: "South Korea",
  },
  {
    id: 24,
    name: "Mexican Peso",
    code: "MXN",
    symbol: "Mex$",
    countryCode: "MX",
    country: "Mexico",
  },
  {
    id: 25,
    name: "Malaysian Ringgit",
    code: "MYR",
    symbol: "RM",
    countryCode: "MY",
    country: "Malaysia",
  },
  {
    id: 26,
    name: "New Zealand Dollar",
    code: "NZD",
    symbol: "NZ$",
    countryCode: "NZ",
    country: "New Zealand",
  },
  {
    id: 27,
    name: "Philippine Peso",
    code: "PHP",
    symbol: "₱",
    countryCode: "PH",
    country: "Philippines",
  },
  {
    id: 28,
    name: "Singapore Dollar",
    code: "SGD",
    symbol: "S$",
    countryCode: "SG",
    country: "Singapore",
  },
  {
    id: 29,
    name: "Thai Baht",
    code: "THB",
    symbol: "฿",
    countryCode: "TH",
    country: "Thailand",
  },
  {
    id: 30,
    name: "South African Rand",
    code: "ZAR",
    symbol: "R",
    countryCode: "ZA",
    country: "South Africa",
  },
  {
    id: 31,
    name: "European Union Euro",
    code: "EUR",
    symbol: "€",
    countryCode: "eu",
    country: "European Union",
  },
];

export const nameAvatarProps = {
  fontSize: "10px",
  mr: "8px",
  bg: "cyprus.50",
  color: "cyprus.600",
  fontWeight: 700,
  size: "xs",
  pt: "1px",
  border: "1px solid var(--chakra-colors-cyprus-100)",
};

export const PARTNER_STATUS = {
  ACTIVE: "Active",
  DRAFT: "Draft",
};
