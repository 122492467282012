import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  Avatar,
  Box,
  Card,
  CardBody,
  CardFooter,
  Flex,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  Button,
  TabIndicator,
} from "@chakra-ui/react";
import {
  Button as CustomButton,
  Loader,
  TipsLinkButton,
} from "@coachbar/shared-components";
import CreateConfiguration from "./CreateConfiguration";
import {
  deleteConfiguration,
  getConfigurationList,
  updateConfiguration,
} from "./softwareSpecialisationActions";
import {
  removeConfigurationFromList,
  updateConfigurationFromList,
} from "./softwareSpecialisationSlice";
import NoData from "../../components/NoData";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { noSpecialisationConfiguration } from "../../constants/infoMessages";
import Notification from "../../components/Notification";
import { clearMessage } from "./softwareSpecialisationSlice";
import { clearMessage as clearMessageClient } from "../clientsInformation/clientsInformationSlice";
import IconConnection from "../../image/connection.png";
import { getProfileCompletionStatus } from "../profileSetup/profileMetrics/profileMetricsAction";
import {
  hasPermissionManagerSpecialCase,
  updateSystemGridData,
} from "../../services/utility";
import CustomTooltip from "@coachbar/shared-components/src/components/Tooltip/CustomTooltip";
import { createConfigAbTip } from "../../constants/tooltips";
import { useRBAC } from "../../services/customHook";
import { systemGrid } from "../../constants/moduleNames";
import { getPartnerProviderList } from "../myProviders/providersList/providersListActions";
import SmallLogo from "../../image/coachbarDarkSmallLogo.svg";
import AlertOctagonIcon from "../../image/alert-octagon.png";
import ProfileFooter from "../profileSetup/ProfileFooter";
import {
  buttonVariants,
  scrollbarCSS,
} from "@coachbar/shared-components/src/constants/constantValues";
import { permissionTypes } from "../../constants/usersAndRoles";

const TIPS_LINK_PAGE_SYSTEM_GRID =
  "https://support.coachbar.io/support/solutions/articles/153000018988-getting-started-guide-software-consultant#System-Grid%C2%A0";

const SoftwareConfiguration = ({ software }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const { hasActionPermission } = useRBAC();
  let moduleName = systemGrid;
  let permissions = {
    add: hasActionPermission(moduleName, "add"),
    edit: hasActionPermission(moduleName, "edit"),
    view: hasActionPermission(moduleName, "view"),
    delete: hasActionPermission(moduleName, "delete"),
    assigned: hasActionPermission(moduleName, "assigned"),
  };
  const { configurationList, loading, error, success } = useSelector(
    (state) => state.softwareSpecialisation
  );
  const { error: errorClientList, success: successClientList } = useSelector(
    (state) => state.clientInfo
  );
  const [showConfigurationForm, setShowConfigurationForm] = useState(false);
  const [bundleList, setBundleList] = useState([]);
  const [providersList, setProvidersList] = useState([]);
  const [selectedConfiguration, setSelectedConfiguration] = useState({});
  const [spTenantId, setSpTenantId] = useState("");
  const dispatch = useDispatch();
  let initialized = false;

  const getProvider = async () => {
    const fetchedData = await getPartnerProviderList({});
    if (fetchedData?.partnerList && fetchedData?.partnerList.length > 0) {
      dispatch(
        getConfigurationList(process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID)
      );
    } else {
      dispatch(getConfigurationList());
    }
    setProvidersList(fetchedData?.partnerList || []);
  };

  useEffect(() => {
    if (!initialized) {
      initialized = true;
      getProvider();
    }
  }, []);

  useEffect(() => {
    if (configurationList && configurationList.length > 0) {
      const groupedByStatus = configurationList.reduce((acc, obj) => {
        const status = obj.bundleStatus;
        if (!acc[status]) {
          acc[status] = [];
        }
        acc[status].push(obj);
        return acc;
      }, {});
      setBundleList(groupedByStatus);
    } else {
      setBundleList([]);
    }
  }, [configurationList]);

  const editConfiguration = (configuration, spId) => {
    setSpTenantId(spId);
    setSelectedConfiguration(configuration);
    handleCreateClick();
  };

  const handleFormClose = () => {
    setSelectedConfiguration({});
    handleCreateClick();
    dispatch(getConfigurationList(spTenantId));
  };

  const handleCreateClick = () => {
    setShowConfigurationForm(!showConfigurationForm);
  };

  const changeBundleStatus = (id, status, type, spId) => {
    let requestData = { bundleStatus: status };
    if (spId) {
      requestData.spTenantId = spId;
    } else {
      requestData.spTenantId = process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID;
    }
    dispatch(
      updateConfiguration({
        configurationId: id,
        requestData,
      })
    ).then((responseData) => {
      dispatch(getProfileCompletionStatus());
      if (
        responseData.payload &&
        responseData.payload.success &&
        responseData.payload.data &&
        responseData.payload.data.softwareBundle
      ) {
        updateSystemGridData(dispatch);
        dispatch(
          updateConfigurationFromList(responseData.payload.data.softwareBundle)
        );
      }
    });
  };

  const {
    onOpen: onAlertOpen,
    isOpen: isAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  const onAlertCloseAction = () => {
    if (bundleIdToDelete) {
      dispatch(deleteConfiguration(bundleIdToDelete)).then((responseData) => {
        if (responseData.payload && responseData.payload.success) {
          dispatch(getProfileCompletionStatus());
          dispatch(removeConfigurationFromList(bundleIdToDelete));
          setBundleIdToDelete();
        }
      });
    }
    onAlertClose();
  };
  const [bundleIdToDelete, setBundleIdToDelete] = useState(null);
  const onAlertOpenAction = (event, id) => {
    setBundleIdToDelete(id);
    onAlertOpen();
  };

  const getConfiguration = (list, type, spId) => {
    return loading ? (
      <Flex
        alignItems={"center"}
        justifyContent={"center"}
        h={
          providersList && providersList?.length > 0
            ? "calc(100vh - 348px)"
            : "calc(100vh - 308px)"
        }
      >
        <Loader />
      </Flex>
    ) : (
      <Box
        h={
          providersList && providersList?.length > 0
            ? "calc(100vh - 348px)"
            : "calc(100vh - 308px)"
        }
        overflow={"auto"}
        css={scrollbarCSS}
      >
        <Flex alignItems={"center"} justifyContent={"flex-end"}>
          {/* {type === "Published" && (
            <Text fontWeight={"500"} fontSize={"1.125rem"} lineHeight={"21px"}>
              {activeSpecialisation} &nbsp;&nbsp;
              <Text
                as={"span"}
                fontWeight={"400"}
                color={"var(--text-font-color)"}
                fontSize={"1rem"}
              >
                {list.length}/10
              </Text>
            </Text>
          )}

          {type === "Saved" && (
            <Text fontWeight={"500"} fontSize={"1.125rem"} lineHeight={"21px"}>
              Saved System Grids &nbsp;&nbsp;
              <Text
                as={"span"}
                fontWeight={"400"}
                color={"var(--text-font-color)"}
                fontSize={"1rem"}
              >
                {list.length}/10
              </Text>
            </Text>
          )}

          {type === "Archived" && (
            <Text fontWeight={"500"} fontSize={"1.125rem"} lineHeight={"21px"}>
              {`${list.length} Archived Configuration`}
            </Text>
          )} */}

          {hasPermissionManagerSpecialCase(
            permissionTypes.add,
            spTenantId,
            userInfo,
            permissions,
            providersList
          ) && type === "Saved" ? (
            <CustomTooltip
              content={userInfo.userType === 3 ? createConfigAbTip : false}
            >
              <CustomButton
                title="Create System Grid"
                onClick={() => {
                  setSpTenantId(spId);
                  handleCreateClick();
                }}
                variant={buttonVariants.brandPrimary}
                isDisabled={list.length >= 10 ? true : false}
                mb={"20px"}
                id="software-configuration-create-system-grid"
              />
            </CustomTooltip>
          ) : (
            ""
          )}
        </Flex>
        {list && list.length > 0 ? (
          list.map((bundle) => {
            return (
              <Card
                w={"100%"}
                boxShadow={"none"}
                border="1px solid #E6E7E9"
                borderRadius={"8px"}
                mb={"16px"}
              >
                <CardBody p={"20px"}>
                  <HStack spacing={4} alignItems={"flex-start"}>
                    <Box
                      bg={"#F9FAFB"}
                      minH={"100px"}
                      minW={"100px"}
                      border={"1px solid #F3F7FF"}
                      borderRadius={"4px"}
                      p={"4px"}
                    >
                      <SimpleGrid columns={3} spacing={1}>
                        {bundle?.softwareList?.map((software, index) => {
                          return (
                            <Box position={"relative"}>
                              {index % 3 !== 2 &&
                                index < bundle?.softwareList?.length - 1 && (
                                  <Image
                                    position={"absolute"}
                                    transform={"rotate(90deg)"}
                                    src={IconConnection}
                                    h={"13px"}
                                    top={"5px"}
                                    left={"24px"}
                                    zIndex={1}
                                  />
                                )}
                              {index < bundle?.softwareList?.length - 3 && (
                                <Image
                                  position={"absolute"}
                                  src={IconConnection}
                                  h={"13px"}
                                  top={"18px"}
                                  left={"10px"}
                                  zIndex={1}
                                />
                              )}
                              <Image
                                rounded={"4px"}
                                border={"1px solid #73F5CB"}
                                src={software?.logo}
                                w={"24px"}
                                h={"24px"}
                              />
                            </Box>
                          );
                        })}
                      </SimpleGrid>
                    </Box>
                    <Stack spacing={"10px"} w={"100%"}>
                      <Text
                        fontWeight={"600"}
                        fontSize={"14px"}
                        color={"var(--title-black-text)"}
                      >
                        {bundle.title}
                      </Text>

                      <Text
                        fontWeight={"500"}
                        fontSize={"12px"}
                        color={"#5E6977"}
                      >
                        {bundle.description}
                      </Text>
                    </Stack>
                  </HStack>
                </CardBody>
                <CardFooter
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  borderTop="2px solid #F9FAFB"
                  p={"16px 24px"}
                >
                  <Text fontSize={"12px"} fontWeight={"500"} color={"#9EA5AD"}>
                    {bundle?.softwareList?.length || 0} Products Included
                  </Text>
                  <HStack spacing={"20px"}>
                    {hasPermissionManagerSpecialCase(
                      permissionTypes.edit,
                      spTenantId,
                      userInfo,
                      permissions,
                      providersList
                    ) &&
                    (type === "Published" || type === "Saved") ? (
                      <Flex
                        alignItems={"center"}
                        cursor={"pointer"}
                        onClick={() => editConfiguration(bundle, spId)}
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1.45801 12.5415L4.69508 11.2965C4.90213 11.2169 5.00565 11.177 5.10251 11.125C5.18854 11.0789 5.27056 11.0256 5.3477 10.9657C5.43454 10.8983 5.51298 10.8199 5.66984 10.663L12.2497 4.08319C12.894 3.43885 12.894 2.39418 12.2497 1.74985C11.6054 1.10552 10.5607 1.10552 9.91636 1.74985L3.33651 8.32969C3.17964 8.48655 3.10121 8.56498 3.03382 8.65183C2.97396 8.72897 2.92066 8.81098 2.87448 8.89702C2.82249 8.99387 2.78267 9.0974 2.70303 9.30445L1.45801 12.5415ZM1.45801 12.5415L2.65858 9.42008C2.74449 9.19671 2.78744 9.08502 2.86112 9.03387C2.92551 8.98916 3.00518 8.97225 3.08218 8.98695C3.17029 9.00378 3.2549 9.08839 3.42412 9.25762L4.74193 10.5754C4.91115 10.7446 4.99576 10.8293 5.01259 10.9174C5.02729 10.9944 5.01038 11.074 4.96568 11.1384C4.91452 11.2121 4.80283 11.2551 4.57947 11.341L1.45801 12.5415Z"
                            stroke="#0C94AC"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <Text
                          color={"#0C94AC"}
                          fontSize={"14px"}
                          fontWeight={500}
                          lineHeight={1}
                          ml={"8px"}
                        >
                          Edit
                        </Text>
                      </Flex>
                    ) : (
                      ""
                    )}
                    {hasPermissionManagerSpecialCase(
                      permissionTypes.edit,
                      spTenantId,
                      userInfo,
                      permissions,
                      providersList
                    ) &&
                    (type === "Published" || type === "Saved") ? (
                      <Flex
                        alignItems={"center"}
                        cursor={"pointer"}
                        onClick={() =>
                          changeBundleStatus(
                            bundle.id,
                            "Archived",
                            "Archive",
                            spId
                          )
                        }
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.9587 4.66667V9.45C11.9587 10.4301 11.9587 10.9201 11.7679 11.2945C11.6001 11.6238 11.3324 11.8915 11.0031 12.0593C10.6288 12.25 10.1388 12.25 9.15866 12.25H4.84199C3.8619 12.25 3.37185 12.25 2.99751 12.0593C2.66823 11.8915 2.40051 11.6238 2.23273 11.2945C2.04199 10.9201 2.04199 10.4301 2.04199 9.45V4.66667M2.10033 1.75H11.9003C12.227 1.75 12.3904 1.75 12.5152 1.81358C12.6249 1.86951 12.7142 1.95874 12.7701 2.06851C12.8337 2.19329 12.8337 2.35664 12.8337 2.68333V3.73333C12.8337 4.06003 12.8337 4.22338 12.7701 4.34816C12.7142 4.45792 12.6249 4.54716 12.5152 4.60309C12.3904 4.66667 12.227 4.66667 11.9003 4.66667H2.10033C1.77363 4.66667 1.61028 4.66667 1.4855 4.60309C1.37574 4.54716 1.2865 4.45792 1.23057 4.34816C1.16699 4.22338 1.16699 4.06003 1.16699 3.73333V2.68333C1.16699 2.35664 1.16699 2.19329 1.23057 2.06851C1.2865 1.95874 1.37574 1.86951 1.4855 1.81358C1.61028 1.75 1.77363 1.75 2.10033 1.75ZM5.60033 6.70833H8.40033C8.72702 6.70833 8.89037 6.70833 9.01515 6.77191C9.12491 6.82784 9.21415 6.91708 9.27008 7.02684C9.33366 7.15162 9.33366 7.31497 9.33366 7.64167V8.10833C9.33366 8.43503 9.33366 8.59838 9.27008 8.72316C9.21415 8.83292 9.12491 8.92216 9.01515 8.97809C8.89037 9.04167 8.72702 9.04167 8.40033 9.04167H5.60033C5.27363 9.04167 5.11028 9.04167 4.9855 8.97809C4.87574 8.92216 4.7865 8.83292 4.73057 8.72316C4.66699 8.59838 4.66699 8.43503 4.66699 8.10833V7.64167C4.66699 7.31497 4.66699 7.15162 4.73057 7.02684C4.7865 6.91708 4.87574 6.82784 4.9855 6.77191C5.11028 6.70833 5.27363 6.70833 5.60033 6.70833Z"
                            stroke="#C81E1E"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>

                        <Text
                          color={"#C81E1E"}
                          fontSize={"14px"}
                          fontWeight={500}
                          lineHeight={1}
                          ml={"8px"}
                        >
                          Archive
                        </Text>
                      </Flex>
                    ) : (
                      hasPermissionManagerSpecialCase(
                        permissionTypes.delete,
                        spTenantId,
                        userInfo,
                        permissions,
                        providersList
                      ) && (
                        <Flex
                          alignItems={"center"}
                          cursor={"pointer"}
                          onClick={(e) => {
                            onAlertOpenAction(e, bundle.id, spId);
                          }}
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.33333 3.49996V3.03329C9.33333 2.3799 9.33333 2.0532 9.20617 1.80364C9.09432 1.58411 8.91584 1.40564 8.69632 1.29379C8.44676 1.16663 8.12006 1.16663 7.46667 1.16663H6.53333C5.87994 1.16663 5.55324 1.16663 5.30368 1.29379C5.08416 1.40564 4.90568 1.58411 4.79383 1.80364C4.66667 2.0532 4.66667 2.3799 4.66667 3.03329V3.49996M5.83333 6.70829V9.62496M8.16667 6.70829V9.62496M1.75 3.49996H12.25M11.0833 3.49996V10.0333C11.0833 11.0134 11.0833 11.5034 10.8926 11.8778C10.7248 12.2071 10.4571 12.4748 10.1278 12.6426C9.75347 12.8333 9.26342 12.8333 8.28333 12.8333H5.71667C4.73657 12.8333 4.24653 12.8333 3.87218 12.6426C3.5429 12.4748 3.27518 12.2071 3.10741 11.8778C2.91667 11.5034 2.91667 11.0134 2.91667 10.0333V3.49996"
                              stroke="#C81E1E"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          <Text
                            color={"#C81E1E"}
                            fontSize={"14px"}
                            fontWeight={500}
                            lineHeight={1}
                            ml={"8px"}
                          >
                            Delete
                          </Text>
                        </Flex>
                      )
                    )}
                    {hasPermissionManagerSpecialCase(
                      permissionTypes.edit,
                      spTenantId,
                      userInfo,
                      permissions,
                      providersList
                    ) &&
                      type === "Saved" &&
                      bundle?.title &&
                      bundle?.description &&
                      bundle?.softwareList?.length &&
                      bundle?.industry?.length && (
                        <Flex
                          alignItems={"center"}
                          cursor={"pointer"}
                          onClick={() =>
                            changeBundleStatus(
                              bundle.id,
                              "Published",
                              "Publish",
                              spId
                            )
                          }
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.99967 8.74998L5.24967 6.99998M6.99967 8.74998C7.8145 8.44008 8.59619 8.04924 9.33301 7.58332M6.99967 8.74998V11.6666C6.99967 11.6666 8.76717 11.3458 9.33301 10.5C9.96301 9.55498 9.33301 7.58332 9.33301 7.58332M5.24967 6.99998C5.56009 6.19465 5.95096 5.42269 6.41634 4.69582C7.09603 3.60906 8.04245 2.71426 9.16559 2.09654C10.2887 1.47881 11.5512 1.1587 12.833 1.16665C12.833 2.75332 12.378 5.54165 9.33301 7.58332M5.24967 6.99998H2.33301C2.33301 6.99998 2.65384 5.23248 3.49967 4.66665C4.44467 4.03665 6.41634 4.66665 6.41634 4.66665M2.62467 9.62498C1.74967 10.36 1.45801 12.5416 1.45801 12.5416C1.45801 12.5416 3.63967 12.25 4.37467 11.375C4.78884 10.885 4.78301 10.1325 4.32217 9.67748C4.09544 9.46107 3.79676 9.33602 3.48347 9.32633C3.17018 9.31664 2.86435 9.423 2.62467 9.62498Z"
                              stroke="#0C94AC"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <Text
                            color={"#0C94AC"}
                            fontSize={"14px"}
                            fontWeight={500}
                            lineHeight={1}
                            ml={"8px"}
                          >
                            Publish
                          </Text>
                        </Flex>
                      )}
                    {hasPermissionManagerSpecialCase(
                      permissionTypes.edit,
                      spTenantId,
                      userInfo,
                      permissions,
                      providersList
                    ) &&
                      type === "Archived" && (
                        <Flex
                          alignItems={"center"}
                          cursor={"pointer"}
                          onClick={() =>
                            changeBundleStatus(
                              bundle.id,
                              "Saved",
                              "Restore",
                              spId
                            )
                          }
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.16699 5.70366C1.16699 5.70366 2.39812 3.93306 3.39829 2.8766C4.39847 1.82013 5.7806 1.16663 7.30734 1.16663C10.3594 1.16663 12.8337 3.7783 12.8337 6.99996C12.8337 10.2216 10.3594 12.8333 7.30734 12.8333C4.7879 12.8333 2.66224 11.0537 1.99702 8.62033M1.16699 5.70366V1.81477M1.16699 5.70366H4.8512"
                              stroke="#0C94AC"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>

                          <Text
                            color={"#0C94AC"}
                            fontSize={"14px"}
                            fontWeight={500}
                            lineHeight={1}
                            ml={"8px"}
                          >
                            Restore
                          </Text>
                        </Flex>
                      )}
                  </HStack>
                </CardFooter>
              </Card>
            );
          })
        ) : (
          <NoData mt="100px" description={noSpecialisationConfiguration} />
        )}
      </Box>
    );
  };

  const closeConfigurationForm = () => {
    if (showConfigurationForm) {
      setShowConfigurationForm(false);
    }
    setSelectedConfiguration({});
  };

  return (
    <>
      {error || success ? (
        <Notification
          title={error ? error : success}
          status={error ? "error" : "success"}
          onCloseToast={() => dispatch(clearMessage())}
        />
      ) : (
        ""
      )}
      {errorClientList || successClientList ? (
        <Notification
          title={errorClientList ? errorClientList : successClientList}
          status={errorClientList ? "error" : "success"}
          onCloseToast={() => dispatch(clearMessageClient())}
        />
      ) : (
        ""
      )}
      <DndProvider backend={HTML5Backend}>
        <Box p={"24px"} overflow={"hidden"}>
          {showConfigurationForm ? (
            <Box
              rounded={"8px"}
              boxShadow="0px 0px 4px 0px #E6E7E9"
              bg={"#fff"}
            >
              <Flex
                p={"24px"}
                borderBottom={"2px solid #E6E7E999"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Text
                  color={"var(--title-black-text)"}
                  fontSize={"14px"}
                  fontWeight={700}
                >
                  {selectedConfiguration.hasOwnProperty("id") ? "Edit" : "New"}{" "}
                  System Grid
                </Text>

                <Button
                  onClick={handleFormClose}
                  variant={buttonVariants.brandPrimaryLinkButton}
                  p="0px"
                  h="100%"
                  id="software-specialisation-configuration-back"
                >
                  <Flex alignItems={"center"} onClick={handleFormClose}>
                    <ChevronLeftIcon fontSize={"20px"} />
                    <Text>Back</Text>
                  </Flex>
                </Button>
              </Flex>

              <CreateConfiguration
                closeCreateForm={handleFormClose}
                selectedConfiguration={selectedConfiguration}
                spTenantId={spTenantId}
              />
            </Box>
          ) : (
            <Box
              // m={"24px"}
              rounded={"8px"}
              boxShadow="0px 0px 4px 0px #E6E7E9"
              bg={"#fff"}
            >
              {providersList && providersList?.length > 0 && (
                <>
                  <Flex
                    p="16px 24px 8px"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Text
                      color={"var(--title-black-text)"}
                      fontSize={"14px"}
                      fontWeight={700}
                    >
                      System Grid
                    </Text>
                    <TipsLinkButton to={TIPS_LINK_PAGE_SYSTEM_GRID} />
                  </Flex>
                  <Tabs
                    defaultIndex={
                      spTenantId
                        ? providersList.findIndex(
                            (item) => item.referenceTenantId === spTenantId
                          ) + 1
                        : 0
                    }
                    position="relative"
                    variant="unstyled"
                  >
                    <TabList
                      width={"100%"}
                      overflow={"auto"}
                      ps="20px"
                      css={{
                        "&::-webkit-scrollbar": {
                          width: "6px",
                          height: "6px",
                        },
                        "&::-webkit-scrollbar-track": {
                          width: "6px",
                          height: "6px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: "#E6E7E9",
                          borderRadius: "4px",
                        },
                      }}
                      borderBottom={"1px solid #E7FAFD"}
                      pos={"relative"}
                    >
                      <Tab
                        whiteSpace={"nowrap"}
                        fontSize={"14px"}
                        fontWeight={600}
                        color={"#7284A0"}
                        marginBottom={0}
                        borderBottom="2px solid transparent"
                        _selected={{ color: "#0C94AC" }}
                        onClick={() => {
                          setSpTenantId(
                            process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
                          );
                          dispatch(
                            getConfigurationList(
                              process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
                            )
                          );
                        }}
                      >
                        <Avatar
                          size="xs"
                          name="Coachbar"
                          src={SmallLogo}
                          mr={"4px"}
                        />
                        Coachbar
                      </Tab>
                      {providersList?.map((item) => {
                        return (
                          <Tab
                            whiteSpace={"nowrap"}
                            fontSize={"14px"}
                            fontWeight={600}
                            color={"#7284A0"}
                            marginBottom={0}
                            borderBottom="2px solid transparent"
                            _selected={{ color: "#0C94AC" }}
                            onClick={() => {
                              setSpTenantId(item.referenceTenantId);
                              dispatch(
                                getConfigurationList(item.referenceTenantId)
                              );
                            }}
                          >
                            <Avatar
                              size="xs"
                              name={item.spCompanyName}
                              src={item?.logo}
                              mr={"4px"}
                            />
                            {item.spCompanyName}
                          </Tab>
                        );
                      })}
                    </TabList>
                    <TabIndicator
                      height="3px"
                      bg="#0C94AC"
                      top={"39px"}
                      borderRadius="2px 2px 0px 0px"
                      zIndex={99}
                    />
                    {loading ? (
                      <Flex
                        alignItems={"center"}
                        justifyContent={"center"}
                        h={
                          providersList && providersList?.length > 0
                            ? "calc(100vh - 300px)"
                            : "calc(100vh - 260px)"
                        }
                      >
                        <Loader />
                      </Flex>
                    ) : (
                      <TabPanels>
                        <TabPanel p={0}>
                          <Tabs orientation="vertical" variant={"unstyled"}>
                            <TabList
                              width={"22%"}
                              alignItems="start"
                              borderRight="2px solid #E6E7E999"
                              p={"20px 24px"}
                              gap={"4px"}
                            >
                              <Tab
                                justifyContent={"left"}
                                fontWeight={"500"}
                                color={"#7284A0"}
                                fontSize={"14px"}
                                p={"8px 12px"}
                                rounded={"4px"}
                                w={"100%"}
                                _selected={{
                                  bg: "#E7FAFD",
                                  color: "#0C94AC",
                                  fontWeight: 600,
                                }}
                                onClick={closeConfigurationForm}
                              >
                                {"Saved" +
                                  ` (${
                                    bundleList && bundleList["Saved"]
                                      ? bundleList["Saved"].length
                                      : 0
                                  })`}
                              </Tab>
                              <Tab
                                justifyContent={"left"}
                                fontWeight={"500"}
                                color={"#7284A0"}
                                fontSize={"14px"}
                                p={"8px 12px"}
                                rounded={"4px"}
                                w={"100%"}
                                _selected={{
                                  bg: "#E7FAFD",
                                  color: "#0C94AC",
                                  fontWeight: 600,
                                }}
                                onClick={closeConfigurationForm}
                              >
                                {"Published" +
                                  ` (${
                                    bundleList && bundleList["Published"]
                                      ? bundleList["Published"].length
                                      : 0
                                  })`}
                              </Tab>
                              <Tab
                                justifyContent={"left"}
                                fontWeight={"500"}
                                color={"#7284A0"}
                                fontSize={"14px"}
                                p={"8px 12px"}
                                rounded={"4px"}
                                w={"100%"}
                                _selected={{
                                  bg: "#E7FAFD",
                                  color: "#0C94AC",
                                  fontWeight: 600,
                                }}
                              >
                                {"Archived" +
                                  ` (${
                                    bundleList && bundleList["Archived"]
                                      ? bundleList["Archived"].length
                                      : 0
                                  })`}
                              </Tab>
                            </TabList>
                            <TabPanels>
                              <TabPanel p={"24px"}>
                                {getConfiguration(
                                  bundleList && bundleList["Saved"]
                                    ? bundleList["Saved"]
                                    : [],
                                  "Saved",
                                  process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
                                )}
                              </TabPanel>
                              <TabPanel p={"24px"}>
                                {getConfiguration(
                                  bundleList && bundleList["Published"]
                                    ? bundleList["Published"]
                                    : [],
                                  "Published",
                                  process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
                                )}
                              </TabPanel>
                              <TabPanel p={"24px"}>
                                {getConfiguration(
                                  bundleList && bundleList["Archived"]
                                    ? bundleList["Archived"]
                                    : [],
                                  "Archived",
                                  process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
                                )}
                              </TabPanel>
                            </TabPanels>
                          </Tabs>
                        </TabPanel>
                        {providersList?.map((item) => {
                          return (
                            <TabPanel p={0}>
                              <Tabs orientation="vertical" variant={"unstyled"}>
                                <TabList
                                  width={"22%"}
                                  alignItems="start"
                                  borderRight="2px solid #E6E7E999"
                                  p={"20px 24px"}
                                  gap={"4px"}
                                >
                                  <Tab
                                    justifyContent={"left"}
                                    fontWeight={"500"}
                                    color={"#7284A0"}
                                    fontSize={"14px"}
                                    p={"8px 12px"}
                                    rounded={"4px"}
                                    w={"100%"}
                                    _selected={{
                                      bg: "#E7FAFD",
                                      color: "#0C94AC",
                                      fontWeight: 600,
                                    }}
                                    onClick={closeConfigurationForm}
                                  >
                                    {"Saved" +
                                      ` (${
                                        bundleList && bundleList["Saved"]
                                          ? bundleList["Saved"].length
                                          : 0
                                      })`}
                                  </Tab>
                                  <Tab
                                    justifyContent={"left"}
                                    fontWeight={"500"}
                                    color={"#7284A0"}
                                    fontSize={"14px"}
                                    p={"8px 12px"}
                                    rounded={"4px"}
                                    w={"100%"}
                                    _selected={{
                                      bg: "#E7FAFD",
                                      color: "#0C94AC",
                                      fontWeight: 600,
                                    }}
                                    onClick={closeConfigurationForm}
                                  >
                                    {"Published" +
                                      ` (${
                                        bundleList && bundleList["Published"]
                                          ? bundleList["Published"].length
                                          : 0
                                      })`}
                                  </Tab>
                                  <Tab
                                    justifyContent={"left"}
                                    fontWeight={"500"}
                                    color={"#7284A0"}
                                    fontSize={"14px"}
                                    p={"8px 12px"}
                                    rounded={"4px"}
                                    w={"100%"}
                                    _selected={{
                                      bg: "#E7FAFD",
                                      color: "#0C94AC",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {"Archived" +
                                      ` (${
                                        bundleList && bundleList["Archived"]
                                          ? bundleList["Archived"].length
                                          : 0
                                      })`}
                                  </Tab>
                                </TabList>
                                <TabPanels>
                                  <TabPanel p={"24px"}>
                                    {getConfiguration(
                                      bundleList && bundleList["Saved"]
                                        ? bundleList["Saved"]
                                        : [],
                                      "Saved",
                                      item.referenceTenantId
                                    )}
                                  </TabPanel>
                                  <TabPanel p={"24px"}>
                                    {getConfiguration(
                                      bundleList && bundleList["Published"]
                                        ? bundleList["Published"]
                                        : [],
                                      "Published",
                                      item.referenceTenantId
                                    )}
                                  </TabPanel>
                                  <TabPanel p={"24px"}>
                                    {getConfiguration(
                                      bundleList && bundleList["Archived"]
                                        ? bundleList["Archived"]
                                        : [],
                                      "Archived",
                                      item.referenceTenantId
                                    )}
                                  </TabPanel>
                                </TabPanels>
                              </Tabs>
                            </TabPanel>
                          );
                        })}
                      </TabPanels>
                    )}
                  </Tabs>
                </>
              )}

              {!providersList?.length && (
                <>
                  <Box p={"24px"} borderBottom={"2px solid #E6E7E999"}>
                    <Text
                      color={"var(--title-black-text)"}
                      fontSize={"14px"}
                      fontWeight={700}
                    >
                      System Grid
                    </Text>
                  </Box>
                  <Tabs orientation="vertical" variant={"unstyled"}>
                    <TabList
                      width={"22%"}
                      alignItems="start"
                      borderRight="2px solid #E6E7E999"
                      p={"20px 24px"}
                      gap={"4px"}
                    >
                      <Tab
                        justifyContent={"left"}
                        fontWeight={"500"}
                        color={"#7284A0"}
                        fontSize={"14px"}
                        p={"8px 12px"}
                        rounded={"4px"}
                        w={"100%"}
                        _selected={{
                          bg: "#E7FAFD",
                          color: "#0C94AC",
                          fontWeight: 600,
                        }}
                        onClick={closeConfigurationForm}
                      >
                        {"Saved" +
                          ` (${
                            bundleList && bundleList["Saved"]
                              ? bundleList["Saved"].length
                              : 0
                          })`}
                      </Tab>
                      <Tab
                        justifyContent={"left"}
                        fontWeight={"500"}
                        color={"#7284A0"}
                        fontSize={"14px"}
                        p={"8px 12px"}
                        rounded={"4px"}
                        w={"100%"}
                        _selected={{
                          bg: "#E7FAFD",
                          color: "#0C94AC",
                          fontWeight: 600,
                        }}
                        onClick={closeConfigurationForm}
                      >
                        {"Published" +
                          ` (${
                            bundleList && bundleList["Published"]
                              ? bundleList["Published"].length
                              : 0
                          })`}
                      </Tab>
                      <Tab
                        justifyContent={"left"}
                        fontWeight={"500"}
                        color={"#7284A0"}
                        fontSize={"14px"}
                        p={"8px 12px"}
                        rounded={"4px"}
                        w={"100%"}
                        _selected={{
                          bg: "#E7FAFD",
                          color: "#0C94AC",
                          fontWeight: 600,
                        }}
                      >
                        {"Archived" +
                          ` (${
                            bundleList && bundleList["Archived"]
                              ? bundleList["Archived"].length
                              : 0
                          })`}
                      </Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel p={"24px"}>
                        {getConfiguration(
                          bundleList && bundleList["Saved"]
                            ? bundleList["Saved"]
                            : [],
                          "Saved"
                        )}
                      </TabPanel>
                      <TabPanel p={"24px"}>
                        {getConfiguration(
                          bundleList && bundleList["Published"]
                            ? bundleList["Published"]
                            : [],
                          "Published"
                        )}
                      </TabPanel>
                      <TabPanel p={"24px"}>
                        {getConfiguration(
                          bundleList && bundleList["Archived"]
                            ? bundleList["Archived"]
                            : [],
                          "Archived"
                        )}
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </>
              )}
            </Box>
          )}

          <Modal isOpen={isAlertOpen} onClose={onAlertClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader px={"24px"} pt={"24px"} pb={0}>
                <Flex alignItems={"center"}>
                  <Image boxSize={"20px"} mr={"8px"} src={AlertOctagonIcon} />
                  <Text
                    fontSize={"16px"}
                    fontWeight={600}
                    color={"var(--title-black-text)"}
                  >
                    Delete Configuration
                  </Text>
                </Flex>
              </ModalHeader>
              <ModalBody p={"24px"}>
                <Text
                  fontSize={"14px"}
                  fontWeight={400}
                  color={"var(--title-black-text)"}
                  mb={"16px"}
                >
                  Are you sure you want to delete this item? You cannot undo
                  this action afterward.
                </Text>
              </ModalBody>

              <ModalFooter borderTop={"1px solid #F3F7FF"} p={"16px 24px"}>
                <CustomButton
                  title={"Cancel"}
                  onClick={onAlertClose}
                  variant={buttonVariants.greyLinkButton}
                  mr={"20px"}
                  id="software-configuration-delete-cancel"
                />
                <CustomButton
                  title={"Delete"}
                  onClick={onAlertCloseAction}
                  variant={buttonVariants.dangerFill}
                  id="software-configuration-delete-confirm"
                />
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
        {!showConfigurationForm && (
          <ProfileFooter
            activeStep={0}
            permissions={permissions}
            moduleName={moduleName}
          />
        )}
      </DndProvider>
    </>
  );
};

export default SoftwareConfiguration;
