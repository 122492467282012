import React, { useState } from "react";
import { Stack } from "@chakra-ui/react";
import { Modal } from "@coachbar/shared-components";
import FolderUpload from "../../../components/FolderUpload/FolderUpload";
import { invalidaFileTypes } from "../../../constants/assets";
import { notification } from "../../../services/utility";
import { getParentFolderId, onUploadProgress } from "../utility";

const UploadFolderModal = ({
  isOpen,
  onClose,
  portal,
  setRefetch,
  breadCrumbs,
  setNextToken,
  setLoading: setGridLoading,
  getPreSignedUrl,
  uploadImage,
  saveAssetData,
  moduleForId,
}) => {
  const maxFolderSize = 100000000; // 100MB in bytes
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [validFiles, setValidFiles] = useState([]);
  const [invalidFiles, setInvalidFiles] = useState([]);
  const [rootFolderNames, setRootFolderNames] = useState([]);
  const [uploadedCount, setUploadedCount] = useState(undefined);
  const [assetErrorMsg, setAssetErrorMsg] = useState(undefined);
  const [usingDrop, setUsingDrop] = useState(false);

  const submitForm = async () => {
    if (!selectedFiles || selectedFiles?.length < 1) {
      setAssetErrorMsg("Folder is required");
    } else {
      setGridLoading(true);
      setLoading(true);
      uploadFile(validFiles);
      discardClick();
      notification(
        // "Upload has been started successfully. You will be notified once it is completed.",
        "Upload has been started successfully.",
        "success",
        "",
        5000
      );
    }
  };

  const discardClick = () => {
    setLoading(false);
    onClose();
    clearUploadedFolderData();
    setAssetErrorMsg(null);
    setSelectedFiles([]);
    setUsingDrop(false);
  };

  const clearUploadedFolderData = () => {
    setValidFiles([]);
    setInvalidFiles([]);
    setRootFolderNames([]);
    setSelectedFiles([]);
    setUsingDrop(false);
  };

  const distinguishValidAndInvalidFiles = (fileList) => {
    const vFiles = [];
    const ivFiles = [];

    for (let i = 0; i < fileList.length; i++) {
      let file = fileList[i];
      const fileName = file?.name;
      const fileExtension = (fileName?.split(".").pop() || "").toUpperCase();

      // Distinguish Vaild and Invaild File Types
      if (invalidaFileTypes.includes(fileExtension)) {
        const existingFileData = fileList[i];
        existingFileData.reason = `${fileExtension} is invalid file extension`;
        ivFiles.push(existingFileData);
      } else {
        vFiles.push(file);
      }
    }
    setValidFiles(vFiles);
    setInvalidFiles(ivFiles);
    return vFiles;
  };

  const validateFolderSize = (fileList) => {
    if (!fileList) return false;
    // Calculate total size of all files
    const totalSize = Object.values(fileList)?.reduce(
      (acc, file) => acc + file.size,
      0
    );

    // Validate total size against 100MB limit
    const isSizeValid = totalSize <= maxFolderSize;

    if (!isSizeValid) {
      return false;
    }
    return true;
  };

  const getRootFolderNames = (fileList) => {
    const rootFolderNames = new Set();

    for (const file of fileList) {
      const path = file.path;
      const pathParts = path.split("/").filter(Boolean);
      const folderName = pathParts[0];
      rootFolderNames.add(folderName);
    }

    setRootFolderNames(Array.from(rootFolderNames));
    return Array.from(rootFolderNames);
  };

  const validateFiles = (fileList, usingDrop = false) => {
    if (!fileList || fileList.length < 1) {
      setAssetErrorMsg("Folder is required");
      return;
    }
    setAssetErrorMsg(null);
    setSelectedFiles(fileList);
    setUsingDrop(usingDrop);

    const isFolderSizeValid = validateFolderSize(fileList);

    if (!isFolderSizeValid) {
      setAssetErrorMsg("Max folder size allowed is 100MB");
      return;
    } else {
      const validFileList = distinguishValidAndInvalidFiles(fileList);
      if (validFileList?.length < 1) {
        setAssetErrorMsg("Please upload at least one valid file");
        return;
      }
      getRootFolderNames(validFileList);
    }
  };

  const uploadFile = (fileList) => {
    let uploadedCount = 0;
    const totalFiles = fileList?.length;

    const batchSize = 6;
    let batchIndex = 0;

    const processFileUploadBatch = (fileList) => {
      if (batchIndex >= totalFiles) return;

      const batch = fileList.slice(batchIndex, batchIndex + batchSize);
      batchIndex += batchSize;
      const filePathBatch = batch.map((file) =>
        usingDrop ? file.path.slice(1) : file.path
      );

      let preSignedUrls = [];

      const parentFolderId = getParentFolderId(breadCrumbs);
      const requestData = {
        parentFolderId,
        objectNameList: filePathBatch,
      };

      getPreSignedUrl(requestData).then(({ data }) => {
        if (data && data?.serviceOfferingList) {
          const presignedUrlBatch = data.serviceOfferingList;
          preSignedUrls = presignedUrlBatch.map(({ path, preSignUrl }) => ({
            path,
            preSignUrl,
          }));

          const uploadPromises = [];

          batch.forEach((file) => {
            const filePath = usingDrop ? file.path.slice(1) : file.path;
            const matchingUrl = preSignedUrls.find(
              (item) => item.path === filePath
            );

            if (matchingUrl) {
              const { preSignUrl } = matchingUrl;
              uploadPromises.push(
                uploadImage(file, preSignUrl, onUploadProgress).then(
                  ({ status }) => {
                    if (status === 200) {
                      // make API call to store respective file data to DB
                      uploadedCount++;
                      setUploadedCount(uploadedCount);
                    }
                  }
                )
              );
            }
          });

          Promise.all(uploadPromises).then(() => {
            saveAssetData({ assets: presignedUrlBatch }).then((res) => {
              setNextToken(null);
              setRefetch((prev) => !prev);
              if (batchIndex < totalFiles) {
                setTimeout(processFileUploadBatch, 1000, fileList); // Wait for some seconds before processing the next batch
              } else {
                // All files have been processed
                // You can add any finalization logic here, like updating the UI or resetting the state
              }
            });
          });
        }
      });
    };

    processFileUploadBatch(fileList);
  };

  const downloadInvalidFiles = () => {
    const invalidFilesData = invalidFiles
      .map((file) => `${file.name} (${file?.reason})`)
      .join("\n");
    const blob = new Blob([invalidFilesData], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "invalid_files.txt";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  return (
    <>
      <Modal
        title={"Upload Folder"}
        isOpen={isOpen}
        onClose={discardClick}
        displayFooter={true}
        allowCloseButton={true}
        loading={loading}
        onSaveClick={submitForm}
        saveButtonTitle="Upload Folder"
        discardTitle="Cancel"
        size="xl"
        spDesign={portal === "sp" ? true : false}
        moduleForId={moduleForId}
        isCentered
      >
        <form noValidate>
          <Stack>
            <FolderUpload
              name="Upload Folder"
              onChange={validateFiles}
              onDrop={validateFiles}
              value={validFiles}
              acceptedFileTypes="*"
              assetErrorMsg={assetErrorMsg}
              ratio={5 / 2}
              isLoading={loading}
              validFileList={validFiles}
              invalidFileList={invalidFiles}
              rootFolderNames={rootFolderNames}
              downloadInvalidFiles={downloadInvalidFiles}
              onDeleteClick={clearUploadedFolderData}
              note={
                "Supported file types include images, videos, documents, designs, presentations, and multimedia files, with a maximum allowable size of 100MB."
              }
              isMultiple
              allowFoldersOnly
              required
            />
          </Stack>
        </form>
      </Modal>
    </>
  );
};

export default UploadFolderModal;
