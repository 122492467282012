// App.js
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { authRoutes, protectedRoutes } from "./routing/routes";
import ProtectedRoute from "./routing/ProtectedRoute";
import "./App.css";
import ErrorBoundary from "@coachbar/shared-components/src/screens/Errorboundry";
import { useSelector } from "react-redux";
import { useEffect } from "react";

function getRoutes(routes) {
  return routes.map((route) => {
    if (route.path && !route.directLink) {
      return (
        <Route key={route.path} path={route.path} element={route.component} />
      );
    }
    if (route.parentRoute) {
      return (
        <Route key={route.name} element={route.component}>
          {getRoutes(route.subComponents)}
        </Route>
      );
    }
  });
}

function App() {
  const { userInfo } = useSelector((state) => state.auth);
  useEffect(() => {
    if (userInfo && userInfo.id && userInfo.tenantId && userInfo.email) {
      window.pendo.initialize({
        visitor: {
          id: userInfo.id,
          email: userInfo.email, // Recommended if using Pendo Feedback, or NPS Email
          full_name: userInfo?.firstName + "" + userInfo?.lastName, // Recommended if using Pendo Feedback
          role: userInfo?.role ?? "", // Optional

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },

        account: {
          id: userInfo.tenantId, // Highly recommended, required if using Pendo Feedback
          name:
            userInfo?.companyName ||
            userInfo?.firstName + "" + userInfo?.lastName,
          // is_paying:    // Recommended if using Pendo Feedback
          // monthly_value:// Recommended if using Pendo Feedback
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional

          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        },
      });
      if (window.fcWidget) {
        window.fcWidget.setExternalId(userInfo.id);
        window.fcWidget.user.setFirstName(
          userInfo.firstName + userInfo.lastName
        );
        window.fcWidget.user.setEmail(userInfo.email);
      }
    }
  }, [userInfo]);
  return (
    <Router>
      <main className="container content">
        <Routes>
          {authRoutes.map((route) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <ErrorBoundary isProtectedRoute={false}>
                    {route.component}
                  </ErrorBoundary>
                }
              />
            );
          })}
          <Route element={<ProtectedRoute routes={protectedRoutes} />}>
            {getRoutes(protectedRoutes)}
          </Route>
        </Routes>
      </main>
    </Router>
  );
}
export default App;
