import { defineStyleConfig, extendTheme } from "@chakra-ui/react";
import { buttonTheme } from "./Button/theme";
import { closeButtonTheme } from "./CloseButton/theme";
import { cardTheme } from "./Card/theme";
import { textBoxTheme } from "./TextBox/theme";
import { textAreaTheme } from "./TextArea/theme";
import { selectTheme } from "./Select/theme";

const formTextCss = {
  fontSize: "0.75rem",
  mt: "0px",
};
const breakpoints = {
  sm: "30em", // 480px
  md: "48em", // 768px
  lg: "62em", // 992px
  xl: "80em", // 1280px
  "2xl": "96em", // 1536px
  // "xxl": "120em", // 1920px
};
const extendedTheme = (type = "default") => {
  return extendTheme({
    breakpoints,
    colors: {
      brand: {
        10: "#04FCC4",
        50: "#0C94AC",
        100: "#1C2C44",
        200: "#047A6E",
        250: "#06A293",
        300: "#012925",
        500: "#0C94AC",
      },
      brandGray: {
        10: "#EDF2F7",
        20: "#D4DCE3",
        30: "#C4CCD8",
        40: "#A0AEC0",
        50: "#ACB4BC",
        51: "#F9FAFB",
        100: "#F9FCFF",
        101: "#E6E7E9",
        200: "#878F99",
        201: "#BFC3C9",
        300: "#9EA5AD",
        400: "#7E8792",
        500: "#5E6977",
        600: "#4B545F",
        700: "#383F47",
      },
      cyprus: {
        50: "#E7FAFD",
        100: "#CEEAEE",
        400: "#3DA9BD",
        500: "#0C94AC",
        600: "#0A768A",
      },
      cherryRed: {
        50: "#FDF2F2",
        100: "#FDE8E8",
        200: "#FBD5D5",
        500: "#F05252",
        600: "#E02424",
        700: "#C81E1E",
      },
      atlantic: {
        50: "#F3F7FF",
        100: "#DEE8F8",
        700: "#111A29",
      },
      brandDanger: {
        700: "#C81E1E",
      },
      palmGreen: {
        50: "#EBF6F1",
        100: "#D7EEE3",
        200: "#AFDDC7",
        600: "#2C885C",
        700: "#216645",
      },
      sunset: {
        300: "#EC8CA1",
      },
      midNight: {
        50: "#EBF5FF",
        100: "#E1EFFE",
        700: "#1A56DB",
      },
      sunRise: {
        50: "#FEF9E5",
        100: "#FDF3CC",
        700: "#927600",
      },
      brandOrange: {
        50: "#FFF8F1",
        100: "#FEECDC",
        600: "#D03801",
      },
      miamiGreen: {
        50: "#E3FAF7",
        100: "#CDF5F1",
        600: "#06A293",
      },
      brandYellow: {
        500: "#C27803",
        600: "#9F580A",
      },
    },
    styles: {
      global: {
        ":root": {
          // define your custom css variable starting with double hyphen (--).
          // '--primary-gradient': 'linear-gradient(121.49deg, rgba(28, 44, 68, 0.89) 29.39%, rgba(12, 148, 172, 0.95) 94.25%)', // gradient with rgba
          "--primary-gradient":
            "linear-gradient(121.49deg, #1c2c44 29.39%, #0c94ac 94.25%)", // gradient with hex code
          "--new-primary-gradient":
            "linear-gradient(150.07deg, #06A293 5.64%, #07CBB8 53.75%, #012925 98.95%)", // gradient with hex code,
          "--heading-font-color": "#1C2C44",
          "--text-font-color": "#5E6977",
          "--text-black-color": "#000000",
          "--title-black-text": "#111A29",
          "--bg-theme-primary": "var(--chakra-colors-brandGray-51)",
        },
      },
    },
    components: {
      Button: buttonTheme,
      CloseButton: closeButtonTheme,
      Card: cardTheme,
      Input: textBoxTheme,
      FormError: {
        baseStyle: () => ({
          text: formTextCss,
        }),
      },
      Form: {
        baseStyle: () => ({
          helperText: formTextCss,
        }),
      },
      FormLabel: {
        baseStyle: () => ({
          color: "var(--text-font-color)",
          fontSize: "12px",
        }),
      },
      Textarea: textAreaTheme,
      Select: selectTheme,
      Popover: defineStyleConfig({
        zIndex: 1700,
      }),
    },
  });
};

export default extendedTheme;
