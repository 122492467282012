import React from "react";
import { Button } from "@chakra-ui/react";

const CustomButton = (props) => {
  return (
    <Button
      {...props}
      title={typeof props?.title === "string" ? props.title : ""}
    >
      {props.title}
    </Button>
  );
};

export default CustomButton;
