import { Box, Center } from "@chakra-ui/react";
import { Loader } from "@coachbar/shared-components";
import Pie from "@coachbar/shared-components/src/components/Charts/Pie";
import { leadDealTypeColorMap } from "@coachbar/shared-components/src/constants/dashboard";
import ChartContainer from "@coachbar/shared-components/src/screens/dashboard/ChartContainer";
import ChartGrid from "@coachbar/shared-components/src/screens/dashboard/ChartGrid";
import ChartHeader from "@coachbar/shared-components/src/screens/dashboard/ChartHeader";
import NoDataChart from "@coachbar/shared-components/src/screens/dashboard/NoDataChart";
import { useChartsProvider } from "@coachbar/shared-components/src/screens/dashboard/chartContext";
import {
  calculateDaysBetweenDates,
  compareInnerAndGeneralFilters,
  formatDateRange,
  getAppliedFilters,
  getPreviousDateRange,
  getSpecificInnerFilters,
  prepareLeadAndDealTypeData,
} from "@coachbar/shared-components/src/services/dashboardUtility/utility";
import { deepCopyObject } from "@coachbar/shared-components/src/services/utility";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getLeadTypeData } from "../../dashboardActions";

const LeadTypes = ({ slug, chartData }) => {
  const { selectedDate, partnerId, appliedFilters, fixFilters } =
    useChartsProvider();

  let initialized = false;
  const widgetInfo = chartData;
  const [isLoading, setIsLoading] = useState(false);
  const [chartType, setChartType] = useState("");
  const [leadTypeData, setLeadTypeData] = useState([]);
  const [prevLeadTypeData, setPrevLeadTypeData] = useState([]);
  const [leadsTotal, setLeadsTotal] = useState(null);
  const [prevLeadsTotal, setPrevLeadsTotal] = useState(null);
  const [prevDateRange, setPrevDateRange] = useState(undefined);
  const [daysBetweenDates, setDaysBetweenDates] = useState(undefined);
  const [innerFilters, setInnerFilters] = useState(
    getSpecificInnerFilters(fixFilters, chartData.filters)
  );
  const [innerDate, setInnerDate] = useState(deepCopyObject({}));

  const [
    formattedDateForGrid,
    formattedPrevDateForGrid,
    formattedDateForChart,
  ] = useMemo(() => {
    let conDate = deepCopyObject(
      innerDate?.to && innerDate?.from ? innerDate : selectedDate
    );
    // Date format to show in Grid
    const date = formatDateRange(conDate || null, "grid");
    const prevDate = formatDateRange(prevDateRange || null, "grid");
    // Different date format to show in chart tooltip
    const formattedDate = formatDateRange(conDate || null, "chart");
    return [date, prevDate, formattedDate];
  }, [innerDate, selectedDate, prevDateRange]);

  const formattedLeadTypeDataForGrid = useMemo(
    () =>
      leadTypeData.map((data) => {
        const prevData = prevLeadTypeData?.find((prev) => prev.id === data.id);
        return {
          id: data.id,
          value: data.value,
          previousValue: !prevData ? undefined : prevData.value,
        };
      }),
    [leadTypeData, prevLeadTypeData]
  );

  let innerAppliedFilters = useMemo(() => {
    return getAppliedFilters(innerFilters);
  }, [innerFilters]);

  const constructRequestData = ({ date }) => {
    let requestData = compareInnerAndGeneralFilters(
      appliedFilters,
      innerAppliedFilters,
      Object.keys(fixFilters),
      date,
      selectedDate
    );
    if (partnerId) {
      requestData.partners = [partnerId];
    }
    delete requestData?.type;
    delete requestData?.partnerManagers;

    return requestData;
  };

  const onToogleCompare = () => {
    let conDate = deepCopyObject(
      innerDate?.to && innerDate?.from ? innerDate : selectedDate
    );
    const previousDateRange = getPreviousDateRange(conDate);
    const numberOfDays = calculateDaysBetweenDates(conDate);
    setDaysBetweenDates(numberOfDays);
    setPrevDateRange(previousDateRange);
    const requestData = constructRequestData({ date: previousDateRange });
    fetchLeadTypeData(requestData, true);
  };

  const fetchLeadTypeData = useCallback(
    async (requestData, isCompared = false) => {
      setIsLoading(true);
      const leadTypeRes = await getLeadTypeData(requestData);
      const fetchedLeadTypeData = leadTypeRes?.data?.dashboard;
      setIsLoading(false);

      if (fetchedLeadTypeData) {
        const preparedLeadTypeData = prepareLeadAndDealTypeData(
          fetchedLeadTypeData,
          leadDealTypeColorMap
        );

        if (isCompared) {
          setPrevLeadTypeData(preparedLeadTypeData);
          setPrevLeadsTotal(
            fetchedLeadTypeData?.total === 0 ? null : fetchedLeadTypeData?.total
          );
        } else {
          setLeadTypeData(preparedLeadTypeData);
          setLeadsTotal(fetchedLeadTypeData?.total);
        }
      }
    },
    []
  );

  useEffect(() => {
    if (!initialized) {
      initialized = true;
      setPrevLeadTypeData([]);
      setPrevLeadsTotal(null);
      setDaysBetweenDates(null);
      const requestData = constructRequestData({ date: innerDate });
      fetchLeadTypeData(requestData);
    }
  }, [
    fetchLeadTypeData,
    innerDate,
    selectedDate,
    appliedFilters,
    innerAppliedFilters,
    partnerId,
  ]);

  return (
    <>
      {widgetInfo?.isVisible && (
        <ChartContainer>
          <ChartHeader
            title={widgetInfo?.title}
            showAll
            chartData={chartData}
            chartType={chartType}
            onChartTypeChange={() => {
              if (chartType === "") {
                setChartType("table");
              } else {
                setChartType("");
              }
            }}
            onToogleCompare={() => {
              if (prevLeadTypeData?.length > 0) {
                setPrevLeadTypeData([]);
                setPrevLeadsTotal(null);
                setPrevDateRange(null);
                setDaysBetweenDates(null);
              } else {
                onToogleCompare();
              }
            }}
            isCompared={prevLeadTypeData?.length > 0 ? true : false}
            filters={innerFilters}
            onSubmitFilters={(newFilters, date) => {
              setInnerFilters(newFilters);
              setInnerDate(date);
            }}
            date={innerDate}
            generalDate={selectedDate}
          />
          {isLoading ? (
            <Center h={"80%"}>
              <Loader />
            </Center>
          ) : !prevLeadsTotal && !leadsTotal ? (
            <Box h={"150px"}>
              <NoDataChart />
            </Box>
          ) : chartType === "table" ? (
            <ChartGrid
              data={formattedLeadTypeDataForGrid}
              headings={
                prevLeadTypeData?.length > 0
                  ? [["Type", formattedDateForGrid, formattedPrevDateForGrid]]
                  : [["Type", formattedDateForGrid]]
              }
            />
          ) : (
            <Box h={"280px"}>
              <Pie
                data={leadTypeData}
                prevData={prevLeadTypeData}
                total={leadsTotal}
                selectedDate={formattedDateForChart}
                daysBetweenDates={daysBetweenDates}
              />
            </Box>
          )}
        </ChartContainer>
      )}
    </>
  );
};

export default LeadTypes;
