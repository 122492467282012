import React from "react";
import { Flex, Image, Text } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

const SelectFilterWithIcon = ({
  title,
  icon,
  items,
  fontWeight,
  labelColor,
  labelTextProps = {},
  dropDownBtnColor = "",
  dropDownBtn,
}) => {
  const getTitle = (name, list) => {
    const some = list?.some((x) => x.isChecked);
    const every = list?.every((x) => x.isChecked);
    const length = list?.filter((x) => x.isChecked)?.length ?? "";
    return some ? `${name} (${every ? "All" : length})` : name;
  };

  return (
    <Flex w={"full"} gap={"8px"} alignItems={"center"}>
      <Image src={icon} alt={title} />
      <Text
        color={labelColor || "var(--chakra-colors-brandGray-400)"}
        fontSize={"14px"}
        fontWeight={fontWeight}
        whiteSpace={"nowrap"}
        {...labelTextProps}
      >
        {getTitle(title, items)}
      </Text>
      <ChevronDownIcon
        h={"16px"}
        w={"16px"}
        color={dropDownBtnColor || "var(--chakra-colors-brandGray-300)"}
      />
    </Flex>
  );
};

export default SelectFilterWithIcon;
