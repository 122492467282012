import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Button as CustomButton,
  Select as CustomSelect,
  TextArea,
  TipsLinkButton,
} from "@coachbar/shared-components";
import { useEffect, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import Select from "react-select";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from "react-virtualized";
import Notification from "../../../sc-app/src/components/Notification";
import { urlValidationPattern } from "../../../sc-app/src/constants/validationPatterns";
import AlertOctagonIcon from "../images/alert-octagon.png";
import iconCertificate from "../images/certificate.png";
import iconCircledClose from "../images/circledCloseIcon.png";
import iconGrayEdit from "../images/gray-pencil.png";
import NoResult from "../images/no-results.svg";
import iconTrash from "../images/trash-icon.svg";
import iconViewCheck from "../images/view-check.svg";
import { appendHttpsToUrl } from "../services/utility";
import { buttonVariants } from "../constants/constantValues";

const TIPS_LINK_PAGE_SPECIALIZATION =
  "https://support.coachbar.io/support/solutions/articles/153000018988-getting-started-guide-software-consultant#Specialization";

const SearchSpecialization = ({
  permissions,
  systemGridPermission,
  spTenantId,
  providersList,
  ...props
}) => {
  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 20,
      fixedHeight: false,
    })
  );
  const { onOpen, isOpen, onClose } = useDisclosure();
  const isCoreSoftwareAlert = useDisclosure();
  const systemGridListModal = useDisclosure();
  const addSoftware = useDisclosure();
  const deleteAlert = useDisclosure();
  const resellerAlert = useDisclosure();
  const addSoftwareToProvider = useDisclosure();
  const [searchString, setSearchString] = useState("");
  const [searchParam] = useState(["name"]);
  const [itemList, setItemList] = useState([]);
  const [addedItemList, setAddedItemList] = useState([]);
  const [totalItem, setTotalItem] = useState([]);
  const [editItem, setEditItem] = useState(null);
  const [isReadMore, setIsReadMore] = useState("");
  const [systemGridList, setSystemGridList] = useState([]);
  const [selectedSoftware, setSelectedSoftware] = useState(null);
  const [deleteNotification, setDeleteNotification] = useState("");
  const [editItemName, setEditItemName] = useState("");
  const [editItemValue, setEditItemValue] = useState("");
  const [addSoftwareInput, setAddSoftwareInput] = useState("");
  const [addSoftwareInputError, setAddSoftwareInputError] = useState("");
  const [softwareLogoInput, setSoftwareLogoInput] = useState("");
  const [softwareLogoInputError, setsoftwareLogoInputError] = useState("");
  const [addCategoryShow, setAddCategoryShow] = useState(false);
  const [addSoftwareCommentInput, setAddSoftwareCommentInput] = useState("");
  const [scrollToTop, setScrollToTop] = useState(false);
  const [isGridCheckedData, setIsGridCheckedData] = useState(false);
  const [disabledGridCheckboxes, setDisabledGridCheckboxes] = useState([]);
  const [disabledIsCoreSoftware, setDisabledIsCoreSoftware] = useState(false);
  const [selectedGrid, setSelectedGrid] = useState();
  const [storeIndex, setStoreIndex] = useState();
  const [selectedReseller, setSelectedReseller] = useState(null);
  const [selectedResellerItem, setSelectedResellerItem] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategoryInput, setSelectedCategoryInput] = useState("");
  const [selectedCategoryInputError, setSelectedCategoryInputError] =
    useState("");
  const [partnerList, setPartnerList] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState([]);

  const scrollbarsRef = useRef();
  const listRef = useRef();

  window.scrollbarsRef = scrollbarsRef;
  window.listRef = listRef;
  let userData = JSON.parse(localStorage.getItem("userInfo"));

  useEffect(() => {
    if (userData.role === "Manager") {
      const temp = [];
      const managerProvider = providersList?.filter(
        (x) => x?.assignedUser === userData.id
      );
      const filteredList = managerProvider?.filter((item) =>
        selectedSoftware?.partnerTenantIdList?.includes(item.referenceTenantId)
      );

      filteredList?.forEach((item) => {
        temp.push({
          label: item.spCompanyName,
          value: item.referenceTenantId,
        });
      });
      setSelectedPartner(temp);
    } else {
      const temp = [];
      if (
        selectedSoftware?.partnerTenantIdList?.includes(
          process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
        )
      ) {
        temp.push({
          label: "Coachbar",
          value: process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID,
        });
      }
      const filteredList = providersList?.filter((item) =>
        selectedSoftware?.partnerTenantIdList?.includes(item.referenceTenantId)
      );

      filteredList?.forEach((item) => {
        temp.push({
          label: item.spCompanyName,
          value: item.referenceTenantId,
        });
      });
      setSelectedPartner(temp);
    }
  }, [selectedSoftware]);

  useEffect(() => {
    if (userData.role === "Manager") {
      const managerProvider = providersList?.filter(
        (x) => x?.assignedUser === userData.id
      );
      const mUpdatedList = managerProvider?.map((item) => ({
        label: item.spCompanyName,
        value: item.referenceTenantId,
      }));
      setPartnerList(mUpdatedList);
    } else {
      const updatedList = providersList?.map((item) => ({
        label: item.spCompanyName,
        value: item.referenceTenantId,
      }));
      if (updatedList) {
        setPartnerList([
          {
            label: "Coachbar",
            value: process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID,
          },
          ...updatedList,
        ]);
      }
    }
  }, [providersList]);

  useEffect(() => {
    if (props.itemList) {
      props.itemList.sort((a, b) => {
        if (a.isChecked && !b.isChecked) {
          return -1;
        }
        if (!a.isChecked && b.isChecked) {
          return 1;
        }
        return 0;
      });
      setTotalItem(props.itemList);
    }
  }, [props.itemList]);

  useEffect(() => {
    if (props.categoryList) {
      const optionList = props.categoryList
        .filter((i) => i !== "All")
        .map((item) => {
          return { value: item, label: item };
        });
      setCategoryList(optionList);
    }
  }, [props.categoryList]);

  useEffect(() => {
    // if (totalItem.length) {
    const addedItemList = totalItem.filter((item) => item.isChecked);
    const itemList = totalItem.filter((item) => !item.isChecked);
    const filteredSystemGridList = addedItemList.filter(
      (item) => item.bundleStatus !== "Archived"
    );
    setAddedItemList(filteredSystemGridList);
    setItemList(itemList);
    const systemGridList = props.systemGridList;

    if (systemGridList) {
      const updatedAddedItemList = getSoftwareListWithInAllGrid(
        systemGridList,
        addedItemList
      );
      setAddedItemList(updatedAddedItemList);
    }
  }, [totalItem]);

  const setGridData = () => {
    const systemGridList = props.systemGridList;
    const filteredSystemGridList = systemGridList.filter(
      (item) => item.bundleStatus !== "Archived"
    );
    const filteredSoftwareList = filteredSystemGridList.map((item) => {
      const removeBlankObj = item?.softwareList?.filter((item) => item.name);
      return { ...item, softwareList: removeBlankObj };
    });
    return filteredSoftwareList;
  };

  useEffect(() => {
    const filteredSoftwareList = setGridData();
    const addedItemList = totalItem.filter((item) => item.isChecked);

    const updatedAddedItemList = getSoftwareListWithInAllGrid(
      filteredSoftwareList,
      addedItemList
    );
    setAddedItemList(updatedAddedItemList);
    setSystemGridList(filteredSoftwareList);
    setIsGridCheckedData(false);
    systemGridListModal.onClose();
  }, [props.systemGridList]);

  const changeDescription = (itemName, value) => {
    setEditItemName(itemName);
    setEditItemValue(value);
  };

  useEffect(() => {
    const itemListResult = search(totalItem);
    // const addedItemResult = itemListResult.filter(
    //   (item) => item.isChecked === true
    // );
    const itemList = itemListResult.filter((item) => !item.isChecked);
    const systemGridList = props.systemGridList;
    if (systemGridList) {
      const updatedAddedItemList = getSoftwareListWithInAllGrid(
        systemGridList,
        addedItemList
      );
      setAddedItemList(updatedAddedItemList);
    }
    setItemList(itemList);
    setScrollToTop(!scrollToTop);
  }, [searchString]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollToPosition(0);
    }
    if (scrollbarsRef.current) {
      scrollbarsRef.current.scrollTop(0);
    }
  }, [scrollToTop]);

  useEffect(() => {
    cache.current.clearAll();
  }, [selectedSoftware, totalItem]);

  const search = (items) => {
    cache.current.clearAll();
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem]
            .toString()
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) > -1
        );
      });
    });
  };

  const getSoftwareListWithInAllGrid = (gridList, listOfSoftwares) => {
    return listOfSoftwares.map((added) => {
      let isInAllSystemGrid =
        gridList.length > 0
          ? gridList.some((item) => {
              if (item.hasOwnProperty("softwareList")) {
                return item?.softwareList?.find(
                  (software) => software.name === added.name
                );
              }
              return false;
            })
          : false;
      return {
        ...added,
        isInSytemGrid: isInAllSystemGrid ? isInAllSystemGrid : false,
      };
    });
  };

  const updateSoftwareList = (selected, type, selectedPartner) => {
    let itemName = selected.name;
    let selectedItem = null;
    let updatedItemList = totalItem.map((item) => {
      if (item.name === itemName) {
        selectedItem = { ...item, isChecked: !item.isChecked };
        return selectedItem;
      }
      return item;
    });
    if (props.handleCheckBoxChange) {
      props.handleCheckBoxChange(
        selectedItem,
        updatedItemList,
        type,
        selectedPartner
      );
      // props.submitForm(selected.category);
    }
    setIsReadMore("");
    cache.current.clearAll();
  };

  const onButtonClick = (item, type) => {
    setSelectedSoftware(item);
    if (type === "delete") {
      const matchItem = addedItemList.find(
        (addedItem) => addedItem.name === item.name
      );
      if (matchItem.isInSytemGrid) {
        setDeleteNotification(
          "The software is added to System Grid. Please remove it from system grid before removing from specialization."
        );
      } else {
        deleteAlert.onOpen();
      }
      return;
    } else {
      const isAnyItemMatching = providersList?.some((item) =>
        selectedSoftware?.partnerTenantIdList?.includes(item.referenceTenantId)
      );

      if (
        selectedSoftware?.partnerTenantIdList?.includes(
          process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
        ) ||
        isAnyItemMatching
      ) {
        updateSoftwareList(item, "EDIT", selectedPartner);
      } else {
        updateSoftwareList(item, "ADD", selectedPartner);
      }
    }
  };

  const deleteSoftware = () => {
    updateSoftwareList(selectedSoftware, "DELETE");
    deleteAlert.onClose();
  };

  const editModal = (item) => {
    setEditItem(item);
    onOpen();
  };

  const isReadMoreClick = (name) => {
    if (isReadMore === name) {
      setIsReadMore("");
    } else {
      setIsReadMore(name);
    }
    cache.current.clearAll();
  };

  const handleDisableCoreSoftware = (grid) => {
    const isCoreDisabledData = setGridData();
    const isDisabledIsCoreSoftware = isCoreDisabledData.some((item) => {
      if (grid.id === item.id) {
        return item?.softwareList?.some((item) => {
          return selectedSoftware?.name === item.name && item.index === 4;
        });
      }
      return false;
    });
    return isDisabledIsCoreSoftware;
  };

  const systemGridModal = (software) => {
    setSelectedSoftware(software);
    const updatedList = systemGridList.map((grid) => {
      const updatedSoftwareList = grid.softwareList?.map((item) => ({
        ...item,
        isChecked: item.name === software.name,
      }));

      const checkKey = updatedSoftwareList?.some((item) => item.isChecked);
      const coreKey = updatedSoftwareList?.filter(
        (item) => item.name === software.name
      );

      const updatedGrid = {
        ...grid,
        softwareList: updatedSoftwareList,
        isChecked: checkKey,
        isCoreSoftwareIndex:
          coreKey?.length > 0 ? coreKey[0]?.index : undefined,
      };

      return updatedGrid;
    });
    setSystemGridList(updatedList);
    updatedList.map((item) => {
      if (item?.softwareList && item?.softwareList?.length >= 9) {
        let isDisabled = true;
        for (let index = 0; index < item?.softwareList?.length; index++) {
          if (item?.softwareList[index].name === software.name) {
            isDisabled = false;
            break;
          }
        }
        if (isDisabled) {
          setDisabledGridCheckboxes((prev) => [...prev, item?.id]);
        }
      } else if (item?.softwareList && item?.softwareList?.length === 1) {
        item?.softwareList?.map((sl) => {
          if (sl?.name === software.name) {
            setDisabledGridCheckboxes((prev) => [...prev, item?.id]);
          }
        });
        return;
      }
    });
    systemGridListModal.onOpen();
  };

  const handleSystemGridCheck = (grid, e) => {
    const existingGridData = [];
    const updatedGridList = systemGridList.map((item) => {
      existingGridData.push({
        id: item?.id,
        title: item?.title,
        isChecked: item?.isChecked,
      });

      if (!isGridCheckedData) {
        props?.setExistingGridCheckedData(existingGridData);
      }
      setIsGridCheckedData(true);

      if (item.id === grid.id) {
        const selectedSoftwareName = selectedSoftware.name;
        const softwareListUpdated = item?.softwareList?.filter(
          (software) => software.name !== selectedSoftwareName
        );

        const findNextAvailableIndex = () => {
          const usedIndices = new Set(
            softwareListUpdated?.map((software) => software.index)
          );
          let newIndex = 0;
          while (usedIndices.has(newIndex)) {
            newIndex++;
          }
          return newIndex;
        };
        const newAvailableIndex = findNextAvailableIndex();

        if (!softwareListUpdated) {
          return {
            ...item,
            softwareList: [{ ...selectedSoftware, index: 4 }],
            isChecked: e?.target?.checked,
          };
        }

        if (softwareListUpdated?.length === item?.softwareList?.length) {
          softwareListUpdated?.push({
            ...selectedSoftware,
            index: newAvailableIndex,
          });
        }

        return {
          ...item,
          softwareList: softwareListUpdated,
          isCoreSoftwareIndex: undefined,
          isChecked: !item.isChecked,
        };
      } else {
        return item;
      }
    });

    setSystemGridList(updatedGridList);
  };

  const handleIsCoreSoftwareCheck = (grid, e) => {
    setSelectedGrid(grid);
    const updatedGridList = systemGridList.map((item) => {
      if (grid.id === item.id) {
        const findIsCoreSoftware = item?.softwareList?.some(
          (item) => item.index === 4
        );
        if (e.target.checked && findIsCoreSoftware) {
          isCoreSoftwareAlert.onOpen();
          return item;
        } else {
          const updatedSoftwareList = item.softwareList?.map((software) => {
            if (selectedSoftware.name === software.name) {
              const targetSoftware = item.softwareList?.find(
                (s) => s.index === storeIndex
              );
              if (targetSoftware) {
                targetSoftware.index = software.index;
                software.index = storeIndex;
              }
            }
            return software;
          });
          if (e.target.checked) {
            const isSoftwareInList = updatedSoftwareList?.some(
              (item) => item.name === selectedSoftware.name
            );

            const findNextAvailableIndex = () => {
              const usedIndices = new Set(
                updatedSoftwareList?.map((software) => software.index)
              );
              let newIndex = 0;
              while (usedIndices.has(newIndex)) {
                newIndex++;
              }
              return newIndex;
            };
            const newAvailableIndex = findNextAvailableIndex();

            if (!isSoftwareInList) {
              for (let i = 0; i < updatedSoftwareList?.length; i++) {
                if (updatedSoftwareList[i].index === 4) {
                  updatedSoftwareList[i].index = newAvailableIndex;
                }
              }
            }

            return {
              ...item,
              isChecked: e.target.checked,
              isCoreSoftwareIndex: 4,
              softwareList: isSoftwareInList
                ? updatedSoftwareList
                : updatedSoftwareList
                ? [...updatedSoftwareList, { ...selectedSoftware, index: 4 }]
                : [{ ...selectedSoftware, index: 4 }],
            };
          } else {
            const isCoreGrid = setGridData();
            const isCoreList = isCoreGrid.find((item) => item.id === grid.id);

            return {
              ...item,
              isChecked: isCoreList.isChecked,
              isCoreSoftwareIndex: undefined,
              softwareList: isCoreList.softwareList,
            };
          }
        }
      } else return item;
    });
    setSystemGridList(updatedGridList);
  };

  const handleCoreSoftwareAlertAction = (grid) => {
    const updatedGridList = systemGridList.map((item) => {
      if (grid.id === item.id) {
        const updatedSoftwareList = [...item.softwareList]; // Create a shallow copy of the original list
        const selectedSoftwareIndex = updatedSoftwareList.findIndex(
          (item) => item.name === selectedSoftware.name
        );
        const targetSoftwareIndex = updatedSoftwareList.findIndex(
          (item) => item.index === 4
        );

        if (selectedSoftwareIndex !== -1 && targetSoftwareIndex !== -1) {
          setStoreIndex(updatedSoftwareList[selectedSoftwareIndex].index);
          // Swap the index values using new objects
          const tempIndex = updatedSoftwareList[selectedSoftwareIndex].index;
          updatedSoftwareList[selectedSoftwareIndex] = {
            ...updatedSoftwareList[selectedSoftwareIndex],
            index: updatedSoftwareList[targetSoftwareIndex].index,
          };
          updatedSoftwareList[targetSoftwareIndex] = {
            ...updatedSoftwareList[targetSoftwareIndex],
            index: tempIndex,
          };

          // Update the state or item.softwareList with the new array
          item.softwareList = updatedSoftwareList;
        }

        const isSoftwareInList = updatedSoftwareList?.some(
          (item) => item.name === selectedSoftware.name
        );

        const findNextAvailableIndex = () => {
          const usedIndices = new Set(
            updatedSoftwareList?.map((software) => software.index)
          );
          let newIndex = 0;
          while (usedIndices.has(newIndex)) {
            newIndex++;
          }
          return newIndex;
        };
        const newAvailableIndex = findNextAvailableIndex();

        if (!isSoftwareInList) {
          for (let i = 0; i < updatedSoftwareList?.length; i++) {
            if (updatedSoftwareList[i].index === 4) {
              updatedSoftwareList[i].index = newAvailableIndex;
            }
          }
        }

        return {
          ...item,
          isChecked: true,
          isCoreSoftwareIndex: 4,
          softwareList: isSoftwareInList
            ? updatedSoftwareList
            : updatedSoftwareList
            ? [...updatedSoftwareList, { ...selectedSoftware, index: 4 }]
            : [{ ...selectedSoftware, index: 4 }],
        };
      } else return item;
    });
    setSystemGridList(updatedGridList);
    isCoreSoftwareAlert.onClose();
  };

  const handleSubmitEditModal = (selectedPartner) => {
    let temp = [];
    selectedPartner?.forEach((item) => {
      temp.push({
        label: item,
        value: item,
      });
    });

    let selectedItem = totalItem.find((item) => item.name === editItemName);
    selectedItem = { ...selectedItem, description: editItemValue };
    if (props.handleDescriptionChange) {
      props.handleDescriptionChange(selectedItem, temp);
    }
    // props.submitForm(editItem?.category);
    onClose();
  };

  useEffect(() => {
    if (props.requestSoftwareModalAction) {
      addSoftwareModalClose();
    }
  }, [props.requestSoftwareModalAction]);

  const handleRequestSoftwareSave = () => {
    if (!addSoftwareInput || addSoftwareInput.trim() === "") {
      setAddSoftwareInputError("Software name is required");
    } else {
      setAddSoftwareInputError("");
    }
    if (!softwareLogoInput || softwareLogoInput.trim() === "") {
      setsoftwareLogoInputError("Software logo is required");
    } else {
      setsoftwareLogoInputError("");
    }
    if (!selectedCategoryInput || selectedCategoryInput.trim() === "") {
      setSelectedCategoryInputError("Software category is required");
    } else {
      setSelectedCategoryInputError("");
    }
    if (
      addSoftwareInput.trim() !== "" &&
      softwareLogoInput.trim() !== "" &&
      selectedCategoryInput.trim() !== ""
    ) {
      props.handleRequestSoftware(
        addSoftwareInput,
        appendHttpsToUrl(softwareLogoInput),
        selectedCategoryInput,
        addSoftwareCommentInput,
        addSoftwareModalClose()
      );
    }
  };

  const handleAddSoftwareInputChange = (e) => {
    setAddSoftwareInput(e.target.value);
    if (e.target.value) {
      setAddSoftwareInputError("");
    } else {
      setAddSoftwareInputError("Software name is required");
    }
  };

  const onChangeReseller = (reseller, item) => {
    setSelectedReseller(reseller);
    setSelectedResellerItem(item);
    if (!reseller) {
      resellerAlert.onOpen();
    } else {
      props.onChangeReseller(reseller, item);
      resellerAlert.onClose();
    }
  };

  const resellerAlertClose = () => {
    const listUpdated = addedItemList.map((item) => {
      if (item.name === selectedReseller.name) {
        return { ...item, reseller: selectedResellerItem.reseller };
      } else {
        return item;
      }
    });
    setAddedItemList(listUpdated);
    resellerAlert.onClose();
  };

  const handleAddCategoryInputChange = (e) => {
    setSelectedCategoryInput(e.target.value);
    if (e.target.value) {
      setSelectedCategoryInputError("");
    } else {
      setSelectedCategoryInputError("Software category is required");
    }
  };

  const handleSoftwareLogoInputChange = (e) => {
    setSoftwareLogoInput(e.target.value);
    if (e.target.value) {
      if (e.target.value.match(urlValidationPattern)) {
        setsoftwareLogoInputError("");
      } else {
        setsoftwareLogoInputError("Please enter a valid URL");
      }
    } else {
      setsoftwareLogoInputError("Software logo is required");
    }
  };

  const customStyles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontSize: "14px",
        color: "#718096",
      };
    },
    control: (styles, state) => ({
      ...styles,
      boxShadow: "none",
      border: `1px solid ${state.isFocused ? "#0C94AC" : "#E6E7E9"}`,
      "&:hover": {
        borderColor: "#CBD5E0",
      },
    }),
    multiValue: (provided, state) => ({
      ...provided,
      color: "var(--title-black-text)",
      fontSize: "12px",
      fontWeight: 500,
      backgroundColor: "#F3F7FF",
      border: "1px solid #DEE8F8",
      borderRadius: "16px",
      overflow: "hidden",
      padding: "2px 4px",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: "var(--title-black-text)",
      fontSize: "12px",
      fontWeight: 500,
      backgroundColor: "#F3F7FF",
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: "#7E8792",
      backgroundColor: "#F3F7FF",
      ":hover": {
        backgroundColor: "transparent",
      },
    }),
  };

  const addSoftwareModalClose = () => {
    setAddSoftwareInput("");
    setAddSoftwareInputError("");
    setAddSoftwareCommentInput("");
    setSoftwareLogoInput("");
    setsoftwareLogoInputError("");
    setSelectedCategoryInput("");
    setSelectedCategoryInputError("");
    addSoftware.onClose();
  };

  return (
    <Stack>
      {deleteNotification ? (
        <Notification
          title={deleteNotification}
          status={"error"}
          onCloseToast={() => setDeleteNotification("")}
        />
      ) : (
        ""
      )}
      <Flex
        justifyContent={"flex-end"}
        w={"fit-content"}
        position={"absolute"}
        top={"-52px"}
        right={"0px"}
      >
        <TipsLinkButton to={TIPS_LINK_PAGE_SPECIALIZATION} />
        {permissions.add && (
          <CustomButton
            title="Add Software"
            onClick={() => addSoftware.onOpen()}
            ml="20px"
            id="search-specialization-add-software"
            variant={buttonVariants.brandPrimary}
          />
        )}
      </Flex>
      <InputGroup mb={"20px !important"}>
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="#BFC3C9" />
        </InputLeftElement>
        <Input
          type="text"
          placeholder="Search"
          onChange={(e) => setSearchString(e.target.value)}
          value={searchString}
          color={"var(--title-black-text)"}
          fontSize={"16px"}
          fontWeight={400}
          border={"1px solid #E6E7E9"}
          rounded={"4px"}
          _placeholder={{ color: "#BFC3C9" }}
          _focus={{ boxShadow: "none" }}
        />
      </InputGroup>

      {(addedItemList?.length > 0 || itemList.length > 0) && (
        <>
          {getList(
            props.category,
            props.loading,
            search([...addedItemList, ...itemList]),
            onButtonClick,
            editModal,
            props.fallbackImage,
            isReadMore,
            isReadMoreClick,
            changeDescription,
            systemGridModal,
            scrollbarsRef,
            listRef,
            itemList.length,
            addedItemList.length,
            cache.current,
            props.addCertificateModal,
            props.deleteCertificateModal,
            onChangeReseller,
            permissions,
            systemGridPermission,
            addSoftwareToProvider,
            setSelectedSoftware,
            providersList,
            spTenantId,
            addSoftware,
            props.containerHeight,
            props?.forPartner
          )}
        </>
      )}

      {/* Software Edit Description Modal*/}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            p={"16px 24px"}
            fontSize={"16px"}
            fontWeight={600}
            color={"var(--title-black-text)"}
            borderBottom={"1px solid #DEE8F8"}
          >
            Edit Software
          </ModalHeader>
          <ModalBody p={"24px"}>
            <HStack spacing={"16px"} mb={"16px"}>
              <Flex
                border={"1px solid #E6E7E9"}
                bg={"#F9FAFB"}
                p={"12px"}
                alignItems={"center"}
                justifyContent={"center"}
                rounded={"4px"}
                w={"100px"}
              >
                <Image
                  h="34px"
                  objectFit="cover"
                  borderRadius={"4px"}
                  src={editItem?.logo}
                  alt={editItem?.name}
                  // fallbackSrc={image}
                />
              </Flex>

              <Text
                fontSize={"14px"}
                fontWeight={600}
                color={"var(--title-black-text)"}
              >
                {editItem?.name}
              </Text>
            </HStack>

            <Box>
              <Text
                color={"#5E6977"}
                fontSize={"12px"}
                fontWeight={500}
                mb={"4px !important"}
              >
                Software Description
              </Text>
              <TextArea
                minHeight="120px"
                defaultValue={editItem?.description}
                fontSize={"14px"}
                fontWeight={"400"}
                color={"var(--title-black-text)"}
                onChange={(e) =>
                  changeDescription(editItem?.name, e.target.value)
                }
                hideLabel={true}
                hideHelperText={true}
              />
            </Box>
          </ModalBody>

          <ModalFooter borderTop={"1px solid #F3F7FF"} p={"16px 24px"}>
            <Text
              color="#5E6977"
              fontSize={"14px"}
              fontWeight={600}
              mr={"20px"}
              p={"8px 16px"}
              onClick={onClose}
              cursor={"pointer"}
              id="search-specialization-modal-save-changes-cancel"
            >
              Cancel
            </Text>
            <Button
              border={"none"}
              bg={"#0C94AC"}
              variant="newThemePrimary"
              rounded={"4px"}
              fontSize={"14px"}
              fontWeight={600}
              isDisabled={props.loading}
              onClick={() =>
                handleSubmitEditModal(editItem?.partnerTenantIdList)
              }
              id="search-specialization-modal-save-changes"
            >
              Save Changes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Add Software Modal*/}
      <Modal isOpen={addSoftware.isOpen} onClose={addSoftwareModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            p={"16px 24px"}
            fontSize={"16px"}
            fontWeight={600}
            color={"var(--title-black-text)"}
            borderBottom={"1px solid #DEE8F8"}
          >
            Add Software
          </ModalHeader>
          <ModalBody p={"24px"}>
            <Box>
              <Text
                fontSize={"12px"}
                fontWeight={400}
                pb={"16px"}
                color={"var(--title-black-text)"}
              >
                Complete the form to add software and start using it right away.
                Please be aware that the software logo and description can be
                adjusted after moderation.
              </Text>
              <FormControl mb={4}>
                <FormLabel
                  fontSize={"12px"}
                  fontWeight={500}
                  color={"#5E6977"}
                  mb={"4px"}
                >
                  Software Name
                  <span style={{ color: "#E53E3E", fontWeight: "500" }}>*</span>
                </FormLabel>
                <Input
                  border={"1px solid #E6E7E9"}
                  fontSize={"14px"}
                  color={"var(--title-black-text)"}
                  _focus={{ boxShadow: "none", border: "1px solid #0C94AC" }}
                  type="text"
                  value={addSoftwareInput}
                  onChange={(e) => handleAddSoftwareInputChange(e)}
                />
                {addSoftwareInputError && (
                  <FormHelperText color={"red.500"}>
                    {addSoftwareInputError}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl mb={4}>
                <FormLabel
                  fontSize={"12px"}
                  fontWeight={500}
                  color={"#5E6977"}
                  mb={"4px"}
                >
                  Software Logo URL
                  <span style={{ color: "#E53E3E", fontWeight: "500" }}>*</span>
                </FormLabel>
                <InputGroup>
                  <InputLeftAddon color={"#7E8792"}>https://</InputLeftAddon>
                  <Input
                    border={"1px solid #E6E7E9"}
                    fontSize={"14px"}
                    color={"var(--title-black-text)"}
                    _focus={{ boxShadow: "none", border: "1px solid #0C94AC" }}
                    type="text"
                    value={softwareLogoInput}
                    onChange={(e) => handleSoftwareLogoInputChange(e)}
                  />
                </InputGroup>
                {softwareLogoInputError && (
                  <FormHelperText color={"red.500"}>
                    {softwareLogoInputError}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl mb={4}>
                <Flex justifyContent={"space-between"} mb={"4px"}>
                  <FormLabel
                    fontSize={"12px"}
                    fontWeight={500}
                    color={"#5E6977"}
                    mb={"0"}
                  >
                    Software Category
                    <span style={{ color: "#E53E3E", fontWeight: "500" }}>
                      *
                    </span>
                  </FormLabel>
                  <Text
                    fontSize={"12px"}
                    fontWeight={500}
                    color={"#0C94AC"}
                    cursor={"pointer"}
                    onClick={() => {
                      setSelectedCategoryInput("");
                      setAddCategoryShow(!addCategoryShow);
                    }}
                  >
                    {addCategoryShow ? "Select From List" : "Add Custom"}
                  </Text>
                </Flex>
                {!addCategoryShow ? (
                  <CustomSelect
                    value={selectedCategoryInput}
                    placeholder={"Select Software Category"}
                    hideLabel={true}
                    onChange={handleAddCategoryInputChange}
                    rounded={"md"}
                    hideHelperText
                    border="1px solid #D4DCE3"
                    borderRadius="4px"
                    options={categoryList}
                    name="softwareCategory"
                  />
                ) : (
                  <Input
                    border={"1px solid #E6E7E9"}
                    fontSize={"14px"}
                    color={"var(--title-black-text)"}
                    _focus={{ boxShadow: "none", border: "1px solid #0C94AC" }}
                    type="text"
                    value={selectedCategoryInput}
                    onChange={(e) => handleAddCategoryInputChange(e)}
                  />
                )}
                {selectedCategoryInputError && (
                  <FormHelperText color={"red.500"}>
                    {selectedCategoryInputError}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl>
                <FormLabel
                  fontSize={"12px"}
                  fontWeight={500}
                  color={"#5E6977"}
                  mb={"4px"}
                >
                  Description
                </FormLabel>
                <TextArea
                  minHeight="120px"
                  value={addSoftwareCommentInput}
                  onChange={(e) => setAddSoftwareCommentInput(e.target.value)}
                  hideLabel={true}
                  hideHelperText={true}
                />
              </FormControl>
            </Box>
          </ModalBody>

          <ModalFooter borderTop={"1px solid #DEE8F8"} p={"16px 24px"}>
            <Text
              color="#5E6977"
              fontSize={"14px"}
              fontWeight={600}
              mr={"20px"}
              p={"8px 16px"}
              onClick={addSoftwareModalClose}
              cursor={"pointer"}
              id="search-specialization-modal-add-software-cancel"
            >
              Cancel
            </Text>
            <Button
              border={"none"}
              bg={"#0C94AC"}
              variant="newThemePrimary"
              rounded={"4px"}
              fontSize={"14px"}
              fontWeight={600}
              isDisabled={props.loading}
              onClick={() => handleRequestSoftwareSave()}
              id="search-specialization-modal-add-software-confirm"
            >
              Add software
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* System Grid List Modal */}
      <Modal
        isOpen={systemGridListModal.isOpen}
        onClose={() => {
          const filteredSoftwareList = setGridData();
          setSystemGridList(filteredSoftwareList);
          setDisabledGridCheckboxes([]);
          systemGridListModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent maxW={"700px"}>
          <ModalHeader
            p={"16px 24px"}
            fontSize={"14px"}
            fontWeight={700}
            color={"var(--title-black-text)"}
          >
            Add to System Grid
          </ModalHeader>
          <ModalBody px={"24px"} py={"24px"} pt={0}>
            {systemGridList && systemGridList?.length > 0 ? (
              <TableContainer>
                <Table variant="unstyled" border={"1px solid #E6E7E9"}>
                  <Thead>
                    <Tr bg={"#F9FAFB"} borderBottom={"1px solid #E6E7E9"}>
                      <Th p={"12px"}></Th>
                      <Th
                        fontSize={"12px"}
                        color={"#5E6977"}
                        fontWeight={600}
                        p={"12px"}
                        textTransform={"capitalize"}
                      >
                        Title
                      </Th>
                      <Th
                        fontSize={"12px"}
                        color={"#5E6977"}
                        fontWeight={600}
                        p={"12px"}
                        textTransform={"capitalize"}
                      >
                        No. of Software
                      </Th>
                      <Th
                        fontSize={"12px"}
                        color={"#5E6977"}
                        fontWeight={600}
                        p={"12px"}
                        textTransform={"capitalize"}
                      >
                        Is Core Software
                      </Th>
                      <Th
                        fontSize={"12px"}
                        color={"#5E6977"}
                        fontWeight={600}
                        p={"12px"}
                        textTransform={"capitalize"}
                      >
                        Status
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {systemGridList?.map((grid) => {
                      return (
                        <>
                          <Tooltip
                            label={
                              grid?.softwareList?.length === 9
                                ? "Up to nine software can be added to a system grid."
                                : "At least one software must remain, Removal of all software is not allowed."
                            }
                            aria-label="A tooltip"
                            isDisabled={
                              disabledGridCheckboxes.includes(grid?.id)
                                ? false
                                : true
                            }
                            placement="auto-start"
                            zIndex={1401}
                          >
                            <Tr borderBottom={"1px solid #E6E7E9"}>
                              <Td p={"12px"}>
                                <Checkbox
                                  onChange={(e) =>
                                    handleSystemGridCheck(grid, e)
                                  }
                                  value={grid?.id}
                                  defaultChecked={grid?.isChecked}
                                  isChecked={grid?.isChecked}
                                  isDisabled={
                                    disabledGridCheckboxes.includes(grid?.id) ||
                                    handleDisableCoreSoftware(grid)
                                  }
                                />
                              </Td>
                              <Td
                                p={"12px"}
                                fontSize={"14px"}
                                color={"var(--title-black-text)"}
                                fontWeight={500}
                              >
                                {grid?.title}
                              </Td>
                              <Td
                                p={"12px"}
                                fontSize={"14px"}
                                color={"var(--title-black-text)"}
                                fontWeight={500}
                              >
                                {!grid?.softwareList
                                  ? "0"
                                  : grid?.softwareList?.length}
                              </Td>
                              <Td
                                p={"12px"}
                                fontSize={"14px"}
                                color={"var(--title-black-text)"}
                                fontWeight={500}
                              >
                                <Checkbox
                                  onChange={(e) =>
                                    handleIsCoreSoftwareCheck(grid, e)
                                  }
                                  value={selectedSoftware?.name}
                                  defaultChecked={
                                    grid?.isCoreSoftwareIndex === 4
                                      ? true
                                      : false
                                  }
                                  isChecked={
                                    grid?.isCoreSoftwareIndex === 4
                                      ? true
                                      : false
                                  }
                                  isDisabled={
                                    handleDisableCoreSoftware(grid) ||
                                    disabledGridCheckboxes.includes(grid?.id)
                                  }
                                />
                              </Td>
                              <Td
                                p={"12px"}
                                fontSize={"14px"}
                                color={
                                  grid?.bundleStatus === "Saved"
                                    ? "#7E8792"
                                    : "#2C885C"
                                }
                                fontWeight={500}
                              >
                                {grid?.bundleStatus}
                              </Td>
                            </Tr>
                          </Tooltip>
                        </>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            ) : (
              <Flex alignItems={"center"} justifyContent={"center"} py={"50px"}>
                <Text
                  color={"var(--title-black-text)"}
                  fontSize={"14px"}
                  fontWeight={600}
                >
                  No System Grid Found
                </Text>
              </Flex>
            )}

            <Modal
              isOpen={isCoreSoftwareAlert.isOpen}
              onClose={isCoreSoftwareAlert.onClose}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader px={"24px"} pt={"24px"} pb={0}>
                  <Flex alignItems={"center"}>
                    <Image boxSize={"20px"} mr={"8px"} src={AlertOctagonIcon} />
                    <Text
                      fontSize={"16px"}
                      fontWeight={600}
                      color={"var(--title-black-text)"}
                    >
                      Confirmation message
                    </Text>
                  </Flex>
                </ModalHeader>
                <ModalBody p={"24px"}>
                  <Text
                    fontSize={"14px"}
                    fontWeight={400}
                    color={"var(--title-black-text)"}
                    mb={"16px !important"}
                  >
                    This system grid already features core software. Would you
                    like to change the core software?
                  </Text>
                </ModalBody>

                <ModalFooter borderTop={"1px solid #F3F7FF"} p={"16px 24px"}>
                  <Text
                    color="#5E6977"
                    fontSize={"14px"}
                    fontWeight={600}
                    mr={"20px"}
                    p={"8px 16px"}
                    onClick={isCoreSoftwareAlert.onClose}
                    cursor={"pointer"}
                    id="search-specialization-change-core-software-confirm-no"
                  >
                    No
                  </Text>
                  <Button
                    border={"none"}
                    bg={"#C81E1E"}
                    color={"white"}
                    variant="newThemePrimary"
                    rounded={"4px"}
                    fontSize={"14px"}
                    fontWeight={600}
                    onClick={() => handleCoreSoftwareAlertAction(selectedGrid)}
                    id="search-specialization-change-core-software-confirm-yes"
                  >
                    Yes
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </ModalBody>

          <ModalFooter borderTop={"1px solid #F3F7FF"} p={"16px 24px"}>
            <Text
              color="#5E6977"
              fontSize={"14px"}
              fontWeight={600}
              mr={"20px"}
              p={"8px 16px"}
              onClick={() => {
                const filteredSoftwareList = setGridData();
                setSystemGridList(filteredSoftwareList);
                setDisabledGridCheckboxes([]);
                systemGridListModal.onClose();
              }}
              cursor={"pointer"}
              id="search-specialization-add-to-system-grid-cancel"
            >
              Cancel
            </Text>
            <Button
              border={"none"}
              bg={"#0C94AC"}
              variant="newThemePrimary"
              rounded={"4px"}
              fontSize={"14px"}
              fontWeight={600}
              onClick={() => props.addToSystemGrid(systemGridList)}
              isDisabled={!systemGridList?.length > 0 || props.loading}
              id="search-specialization-add-to-system-grid-save"
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Remove software from specialization */}
      <Modal isOpen={deleteAlert.isOpen} onClose={deleteAlert.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader px={"24px"} pt={"24px"} pb={0}>
            <Flex alignItems={"center"}>
              <Image boxSize={"20px"} mr={"8px"} src={AlertOctagonIcon} />
              <Text
                fontSize={"16px"}
                fontWeight={600}
                color={"var(--title-black-text)"}
              >
                Remove Software
              </Text>
            </Flex>
          </ModalHeader>
          <ModalBody p={"24px"}>
            <Text
              fontSize={"14px"}
              fontWeight={400}
              color={"var(--title-black-text)"}
              mb={"16px !important"}
            >
              This action cannot be Undone. This will permanently remove the
              software from your specialization.
            </Text>
            <Text
              fontSize={"14px"}
              fontWeight={400}
              color={"var(--title-black-text)"}
            >
              Are you sure you want to remove this software?
            </Text>
          </ModalBody>

          <ModalFooter borderTop={"1px solid #F3F7FF"} p={"16px 24px"}>
            <Text
              color="#5E6977"
              fontSize={"14px"}
              fontWeight={600}
              mr={"20px"}
              p={"8px 16px"}
              onClick={deleteAlert.onClose}
              cursor={"pointer"}
              id="search-specialization-remove-software-cancel"
            >
              Cancel
            </Text>
            <Button
              border={"none"}
              bg={"#C81E1E"}
              color={"white"}
              variant="newThemePrimary"
              rounded={"4px"}
              fontSize={"14px"}
              fontWeight={600}
              onClick={deleteSoftware}
              id="search-specialization-remove-software-confirm"
            >
              Remove Software
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={resellerAlert.isOpen} onClose={resellerAlertClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader px={"24px"} pt={"24px"} pb={0}>
            <Flex alignItems={"center"}>
              <Image boxSize={"20px"} mr={"8px"} src={AlertOctagonIcon} />
              <Text
                fontSize={"16px"}
                fontWeight={600}
                color={"var(--title-black-text)"}
              >
                Reseller Deactivate
              </Text>
            </Flex>
          </ModalHeader>
          <ModalBody p={"24px"}>
            <Text
              fontSize={"14px"}
              fontWeight={400}
              color={"var(--title-black-text)"}
              mb={"16px !important"}
            >
              Are you sure you want to deactivate the reseller?
            </Text>
          </ModalBody>

          <ModalFooter borderTop={"1px solid #F3F7FF"} p={"16px 24px"}>
            <Text
              color="#5E6977"
              fontSize={"14px"}
              fontWeight={600}
              mr={"20px"}
              p={"8px 16px"}
              onClick={resellerAlertClose}
              cursor={"pointer"}
              id="search-specialization-reseller-deactivate-cancel"
            >
              No
            </Text>
            <Button
              border={"none"}
              bg={"#C81E1E"}
              color={"white"}
              variant="newThemePrimary"
              rounded={"4px"}
              fontSize={"14px"}
              fontWeight={600}
              onClick={() => {
                props.onChangeReseller(selectedReseller, selectedResellerItem);
                resellerAlert.onClose();
              }}
              id="search-specialization-reseller-deactivate-confirm"
            >
              Yes
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={addSoftwareToProvider.isOpen}
        onClose={addSoftwareToProvider.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            p={"24px"}
            color={"var(--title-black-text)"}
            fontSize={"16px"}
            fontWeight={600}
          >
            Add to Specialization
          </ModalHeader>
          <ModalBody pt={0}>
            <Text
              fontSize={"12px"}
              color={"#5E6977"}
              fontWeight={500}
              mb={"4px !important"}
            >
              Add it to
            </Text>
            <Select
              closeMenuOnSelect={false}
              options={partnerList}
              value={selectedPartner}
              onChange={(e) => {
                setSelectedPartner(e);
              }}
              styles={customStyles}
              placeholder={"Select"}
              isSearchable={true}
              isMulti
            />
          </ModalBody>

          <ModalFooter>
            <Text
              onClick={() => {
                setSelectedPartner([]);
                setSelectedSoftware(null);
                addSoftwareToProvider.onClose();
              }}
              color={"#5E6977"}
              fontSize={"14px"}
              fontWeight={500}
              cursor={"pointer"}
              p={"8px 16px"}
              id="search-specialization-add-to-specialization-cancel"
            >
              Cancel
            </Text>
            <Button
              isDisabled={
                props.loading || (selectedPartner && !selectedPartner.length)
              }
              variant={"newColorThemePrimary"}
              ml={"20px"}
              onClick={() => {
                onButtonClick(selectedSoftware, "save");
                addSoftwareToProvider.onClose();
              }}
              id="search-specialization-add-to-specialization-confirm"
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

const getList = (
  selectedCategory,
  loading,
  itemList,
  onButtonClick,
  editModal,
  image,
  isReadMore,
  isReadMoreClick,
  changeDescription,
  systemGridModal,
  scrollbarsRefVirtual,
  listRefVirtual,
  allListCount,
  selectedListCount,
  cache,
  addCertificateModal,
  deleteCertificateModal,
  onChangeReseller,
  permissions,
  systemGridPermission,
  addSoftwareToProvider,
  setSelectedSoftware,
  providersList,
  spTenantId,
  addSoftware,
  containerHeight,
  forPartner = null
) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <Stack
      height={
        containerHeight
          ? containerHeight
          : forPartner
          ? "calc(100vh - 375px)"
          : "calc(100vh - 430px)"
      }
      width="full"
      spacing={0}
    >
      {itemList?.length > 0 ? (
        <AutoSizer>
          {({ width, height }) => (
            <Scrollbars
              autoHide
              style={{ width, height }}
              ref={scrollbarsRefVirtual}
              onScroll={({ target }) => {
                const { scrollTop, scrollLeft } = target;
                if (listRefVirtual.current) {
                  listRefVirtual.current.Grid.handleScrollEvent({
                    scrollTop,
                    scrollLeft,
                  });
                }
              }}
            >
              <List
                style={{ overflowX: false, overflowY: false }}
                ref={listRefVirtual}
                height={height}
                width={width}
                deferredMeasurementCache={cache}
                rowHeight={cache.rowHeight}
                rowCount={itemList.length}
                rowRenderer={({ index, style, key, parent }) => {
                  if (index % 3 === 0) {
                    let item1 = itemList[index];
                    let item2 = itemList[index + 1];
                    let item3 = itemList[index + 2];
                    return (
                      <CellMeasurer
                        key={key}
                        cache={cache}
                        parent={parent}
                        columnIndex={0}
                        rowIndex={index}
                      >
                        {({ registerChild }) => (
                          <>
                            <Grid
                              key={`${item1?.name}-${item1.category}`}
                              id={`${item1?.name}-${item1.category}`}
                              style={style}
                              ref={registerChild}
                              templateColumns="repeat(3, 1fr)"
                              gap={"24px"}
                            >
                              <GridItem key={item1.name} w={"100%"}>
                                <Flex
                                  h={"100%"}
                                  boxShadow="0px 0px 6px 0px #E6E7E9"
                                  border={"1px solid #F9FAFB"}
                                  rounded={"8px"}
                                  overflow={"hidden"}
                                  flexDirection={"column"}
                                  _hover={{ border: "1px solid #9ED4DE" }}
                                >
                                  <Box
                                    bg={"#F9FAFB"}
                                    p={"20px"}
                                    position={"relative"}
                                  >
                                    <Image
                                      height={"60px"}
                                      objectFit="contain"
                                      src={item1.logo}
                                      alt={item1.name}
                                      fallbackSrc={image}
                                      mx={"auto"}
                                    />
                                    {item1.isChecked && (
                                      <HStack
                                        position={"absolute"}
                                        top={"12px"}
                                        right={"12px"}
                                        justifyContent="space-evenly"
                                        spacing={"4px"}
                                      >
                                        {(userInfo.userType === 3 ||
                                          userInfo.userType === 4) &&
                                          permissions.add &&
                                          permissions.edit && (
                                            <>
                                              {(!item1.certificationList ||
                                                item1?.certificationList
                                                  ?.length < 10) && (
                                                <Tooltip
                                                  label={"Add Certificate"}
                                                  placement="top"
                                                  hasArrow
                                                >
                                                  <Flex
                                                    alignItems={"center"}
                                                    justifyContent={"center"}
                                                    h={"24px"}
                                                    w={"24px"}
                                                    rounded={"50%"}
                                                    border={
                                                      "1px dashed transparent"
                                                    }
                                                    cursor={
                                                      loading ||
                                                      !permissions.edit
                                                        ? "not-allowed"
                                                        : "pointer"
                                                    }
                                                    _hover={{
                                                      border:
                                                        "1px dashed #0C94AC",
                                                      bg: "#E6E7E9",
                                                    }}
                                                    onClick={() =>
                                                      addCertificateModal(item1)
                                                    }
                                                  >
                                                    <Image
                                                      boxSize="16px"
                                                      objectFit="cover"
                                                      src={iconCertificate}
                                                      alt={"certificate"}
                                                    />
                                                  </Flex>
                                                </Tooltip>
                                              )}
                                            </>
                                          )}

                                        {permissions.edit && (
                                          <>
                                            <Tooltip
                                              label={"Edit Software"}
                                              placement="top"
                                              hasArrow
                                            >
                                              <Flex
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                                h={"24px"}
                                                w={"24px"}
                                                rounded={"50%"}
                                                border={
                                                  "1px dashed transparent"
                                                }
                                                cursor={
                                                  loading || !permissions.edit
                                                    ? "not-allowed"
                                                    : "pointer"
                                                }
                                                _hover={{
                                                  border: "1px dashed #BFC3C9",
                                                  bg: "#E6E7E9",
                                                }}
                                                onClick={() => editModal(item1)}
                                              >
                                                <Image
                                                  boxSize="16px"
                                                  objectFit="cover"
                                                  src={iconGrayEdit}
                                                  alt={"edit"}
                                                />
                                              </Flex>
                                            </Tooltip>

                                            <Tooltip
                                              label={"Remove Software"}
                                              placement="top"
                                              hasArrow
                                            >
                                              <Flex
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                                h={"24px"}
                                                w={"24px"}
                                                rounded={"50%"}
                                                border={
                                                  "1px dashed transparent"
                                                }
                                                cursor={
                                                  loading || !permissions.edit
                                                    ? "not-allowed"
                                                    : "pointer"
                                                }
                                                _hover={{
                                                  border: "1px dashed #FBD5D5",
                                                  bg: "#FDE8E8",
                                                }}
                                                onClick={() =>
                                                  onButtonClick(item1, "delete")
                                                }
                                              >
                                                <Image
                                                  boxSize="16px"
                                                  objectFit="cover"
                                                  src={iconTrash}
                                                  alt={"Delete"}
                                                />
                                              </Flex>
                                            </Tooltip>
                                          </>
                                        )}
                                      </HStack>
                                    )}
                                  </Box>
                                  <Box p={"12px 20px"}>
                                    <Text
                                      fontSize={"14px"}
                                      fontWeight={"600"}
                                      color={"var(--title-black-text)"}
                                    >
                                      {item1.name.replace(/"/g, "")}
                                    </Text>
                                    {selectedCategory !== "All" && (
                                      <Text
                                        fontSize="12px"
                                        fontWeight={"400"}
                                        color={"#556781"}
                                      >
                                        {item1.category}{" "}
                                      </Text>
                                    )}
                                    <Text fontSize={"12px"} mt={"8px"}>
                                      {isReadMore !== item1.name ? (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: item1?.description
                                              ?.slice(0, 130)
                                              .replace(/"/g, ""),
                                          }}
                                          style={{ wordBreak: "break-word" }}
                                        />
                                      ) : (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: item1?.description?.replace(
                                              /"/g,
                                              ""
                                            ),
                                          }}
                                          style={{ wordBreak: "break-word" }}
                                        />
                                      )}
                                      {item1.description?.length > 130 && (
                                        <span
                                          onClick={() =>
                                            isReadMoreClick(item1.name)
                                          }
                                          style={{
                                            cursor: "pointer",
                                            color: "#0C94AC",
                                          }}
                                        >
                                          {isReadMore !== item1.name
                                            ? " ...Show More"
                                            : " ...Show Less"}
                                        </span>
                                      )}
                                    </Text>
                                    {(userInfo.userType === 3 ||
                                      userInfo.userType === 4) &&
                                      permissions.add &&
                                      permissions.edit && (
                                        <>
                                          {item1?.certificationList && (
                                            <Box mt={"18px"}>
                                              <Text
                                                fontSize={"12px"}
                                                color={"#1C2C44"}
                                                fontWeight={600}
                                                mb={"8px !important"}
                                              >
                                                Certificates:
                                              </Text>
                                              <HStack>
                                                {item1?.certificationList?.map(
                                                  (certificate) => {
                                                    return (
                                                      <Tooltip
                                                        label={
                                                          certificate.title
                                                        }
                                                        hasArrow
                                                        placement={"bottom"}
                                                      >
                                                        <Box
                                                          position={"relative"}
                                                          _hover={{
                                                            ".close-icon-show":
                                                              {
                                                                display:
                                                                  "inline-block",
                                                              },
                                                          }}
                                                        >
                                                          <Image
                                                            src={
                                                              certificate.url
                                                            }
                                                            h={"32px"}
                                                            w={"32px"}
                                                            border={
                                                              "1px solid #DEE8F8"
                                                            }
                                                            borderRadius={"4px"}
                                                            objectFit={
                                                              "contain"
                                                            }
                                                          />
                                                          {permissions.delete && (
                                                            <Image
                                                              className={
                                                                "close-icon-show"
                                                              }
                                                              onClick={() =>
                                                                deleteCertificateModal(
                                                                  item1,
                                                                  certificate.title
                                                                )
                                                              }
                                                              display={"none"}
                                                              src={
                                                                iconCircledClose
                                                              }
                                                              h={"12px"}
                                                              cursor={"pointer"}
                                                              position={
                                                                "absolute"
                                                              }
                                                              top={"-6px"}
                                                              right={"-6px"}
                                                            />
                                                          )}
                                                        </Box>
                                                      </Tooltip>
                                                    );
                                                  }
                                                )}
                                              </HStack>
                                            </Box>
                                          )}
                                        </>
                                      )}
                                  </Box>

                                  <Flex
                                    p={"12px 20px"}
                                    mt={"auto"}
                                    justifyContent={
                                      userInfo.userType === 3 ||
                                      userInfo.userType === 4
                                        ? "space-between"
                                        : "center"
                                    }
                                    borderTop={"1px solid #F3F7FF"}
                                  >
                                    {providersList?.length > 0 &&
                                    !spTenantId ? (
                                      <>
                                        <Button
                                          variant={"specializationButton"}
                                          color={"#0C94AC"}
                                          border={"none"}
                                          width={"100%"}
                                          h={"auto"}
                                          onClick={() => {
                                            if (
                                              providersList &&
                                              providersList.length > 0
                                            ) {
                                              setSelectedSoftware(item1);
                                              addSoftwareToProvider.onOpen();
                                            } else {
                                              onButtonClick(item1, "save");
                                            }
                                          }}
                                          isDisabled={
                                            loading ||
                                            !(permissions.add
                                              ? true
                                              : permissions.assigned)
                                          }
                                          id="search-specialization-add-to-specialization-plus"
                                        >
                                          + Add to Specialization
                                        </Button>
                                      </>
                                    ) : (
                                      <>
                                        {!item1.isChecked ? (
                                          <Button
                                            variant={"specializationButton"}
                                            color={"#0C94AC"}
                                            border={"none"}
                                            width={"100%"}
                                            h={"auto"}
                                            onClick={() => {
                                              if (
                                                providersList &&
                                                providersList.length > 0
                                              ) {
                                                setSelectedSoftware(item1);
                                                addSoftwareToProvider.onOpen();
                                              } else {
                                                onButtonClick(item1, "save");
                                              }
                                            }}
                                            isDisabled={
                                              loading ||
                                              !(permissions.add
                                                ? true
                                                : permissions.assigned)
                                            }
                                            id="search-specialization-add-to-specialization-plus"
                                          >
                                            + Add to Specialization
                                          </Button>
                                        ) : (
                                          <>
                                            {(userInfo.userType === 3 ||
                                              userInfo.userType === 4) && (
                                              <Flex alignItems={"center"}>
                                                <Text
                                                  color={"#1C2C44"}
                                                  fontSize={"14px"}
                                                  fontWeight={600}
                                                  mr={2}
                                                >
                                                  Reseller
                                                </Text>
                                                <Switch
                                                  isDisabled={
                                                    loading ||
                                                    !(
                                                      permissions.edit &&
                                                      permissions.add
                                                    )
                                                  }
                                                  defaultChecked={
                                                    item1?.reseller
                                                  }
                                                  isChecked={item1?.reseller}
                                                  onChange={(event) =>
                                                    onChangeReseller(
                                                      event.target.checked,
                                                      item1
                                                    )
                                                  }
                                                  size="md"
                                                  colorScheme="teal"
                                                />
                                              </Flex>
                                            )}

                                            {userInfo.userType !== 3 && (
                                              <Button
                                                variant={"specializationButton"}
                                                border={"none"}
                                                w={"auto"}
                                                h={"auto"}
                                                borderRadius={0}
                                                ml={"10px"}
                                                isDisabled={
                                                  loading ||
                                                  !(
                                                    systemGridPermission.add &&
                                                    systemGridPermission.edit
                                                  )
                                                }
                                                onClick={() =>
                                                  systemGridModal(item1)
                                                }
                                                id="search-specialization-add-to-system-grid-plus"
                                              >
                                                {item1.isInSytemGrid ? (
                                                  <>
                                                    <Image
                                                      boxSize="16px"
                                                      src={iconViewCheck}
                                                      alt={"edit"}
                                                      mr={1}
                                                    />
                                                    View in System Grid
                                                  </>
                                                ) : (
                                                  "+ Add to system grid"
                                                )}
                                              </Button>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </Flex>
                                </Flex>
                              </GridItem>
                              {item2 && (
                                <GridItem key={item2.name} w={"100%"}>
                                  <Flex
                                    h={"100%"}
                                    boxShadow="0px 0px 6px 0px #E6E7E9"
                                    border={"1px solid #F9FAFB"}
                                    rounded={"8px"}
                                    overflow={"hidden"}
                                    flexDirection={"column"}
                                    _hover={{ border: "1px solid #9ED4DE" }}
                                  >
                                    <Box
                                      bg={"#F9FAFB"}
                                      p={"20px"}
                                      position={"relative"}
                                    >
                                      <Image
                                        height={"60px"}
                                        objectFit="contain"
                                        src={item2.logo}
                                        alt={item2.name}
                                        fallbackSrc={image}
                                        mx={"auto"}
                                      />
                                      {item2.isChecked && (
                                        <HStack
                                          position={"absolute"}
                                          top={"12px"}
                                          right={"12px"}
                                          justifyContent="space-evenly"
                                          spacing={"4px"}
                                        >
                                          {(userInfo.userType === 3 ||
                                            userInfo.userType === 4) &&
                                            permissions.add &&
                                            permissions.edit && (
                                              <>
                                                {(!item2.certificationList ||
                                                  item2?.certificationList
                                                    ?.length < 10) && (
                                                  <Tooltip
                                                    label={"Add Certificate"}
                                                    placement="top"
                                                    hasArrow
                                                  >
                                                    <Flex
                                                      alignItems={"center"}
                                                      justifyContent={"center"}
                                                      h={"24px"}
                                                      w={"24px"}
                                                      rounded={"50%"}
                                                      border={
                                                        "1px dashed transparent"
                                                      }
                                                      cursor={
                                                        loading ||
                                                        !permissions.edit
                                                          ? "not-allowed"
                                                          : "pointer"
                                                      }
                                                      _hover={{
                                                        border:
                                                          "1px dashed #0C94AC",
                                                        bg: "#E6E7E9",
                                                      }}
                                                      onClick={() =>
                                                        addCertificateModal(
                                                          item2
                                                        )
                                                      }
                                                    >
                                                      <Image
                                                        boxSize="16px"
                                                        objectFit="cover"
                                                        src={iconCertificate}
                                                        alt={"certificate"}
                                                      />
                                                    </Flex>
                                                  </Tooltip>
                                                )}
                                              </>
                                            )}

                                          {permissions.edit && (
                                            <>
                                              <Tooltip
                                                label={"Edit Software"}
                                                placement="top"
                                                hasArrow
                                              >
                                                <Flex
                                                  alignItems={"center"}
                                                  justifyContent={"center"}
                                                  h={"24px"}
                                                  w={"24px"}
                                                  rounded={"50%"}
                                                  border={
                                                    "1px dashed transparent"
                                                  }
                                                  cursor={
                                                    loading || !permissions.edit
                                                      ? "not-allowed"
                                                      : "pointer"
                                                  }
                                                  _hover={{
                                                    border:
                                                      "1px dashed #BFC3C9",
                                                    bg: "#E6E7E9",
                                                  }}
                                                  onClick={() =>
                                                    editModal(item2)
                                                  }
                                                >
                                                  <Image
                                                    boxSize="16px"
                                                    objectFit="cover"
                                                    src={iconGrayEdit}
                                                    alt={"edit"}
                                                  />
                                                </Flex>
                                              </Tooltip>

                                              <Tooltip
                                                label={"Remove Software"}
                                                placement="top"
                                                hasArrow
                                              >
                                                <Flex
                                                  alignItems={"center"}
                                                  justifyContent={"center"}
                                                  h={"24px"}
                                                  w={"24px"}
                                                  rounded={"50%"}
                                                  border={
                                                    "1px dashed transparent"
                                                  }
                                                  cursor={
                                                    loading || !permissions.edit
                                                      ? "not-allowed"
                                                      : "pointer"
                                                  }
                                                  _hover={{
                                                    border:
                                                      "1px dashed #FBD5D5",
                                                    bg: "#FDE8E8",
                                                  }}
                                                  onClick={() =>
                                                    onButtonClick(
                                                      item2,
                                                      "delete"
                                                    )
                                                  }
                                                >
                                                  <Image
                                                    boxSize="16px"
                                                    objectFit="cover"
                                                    src={iconTrash}
                                                    alt={"Delete"}
                                                  />
                                                </Flex>
                                              </Tooltip>
                                            </>
                                          )}
                                        </HStack>
                                      )}
                                    </Box>
                                    <Box p={"12px 20px"}>
                                      <Text
                                        fontSize={"14px"}
                                        fontWeight={"600"}
                                        color={"var(--title-black-text)"}
                                      >
                                        {item2.name.replace(/"/g, "")}
                                      </Text>
                                      {selectedCategory !== "All" && (
                                        <Text
                                          fontSize="12px"
                                          fontWeight={"400"}
                                          color={"#556781"}
                                        >
                                          {item2.category}{" "}
                                        </Text>
                                      )}
                                      <Text fontSize={"12px"} mt={"8px"}>
                                        {isReadMore !== item2.name ? (
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: item2?.description
                                                ?.slice(0, 130)
                                                .replace(/"/g, ""),
                                            }}
                                            style={{ wordBreak: "break-word" }}
                                          />
                                        ) : (
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                item2?.description?.replace(
                                                  /"/g,
                                                  ""
                                                ),
                                            }}
                                            style={{ wordBreak: "break-word" }}
                                          />
                                        )}
                                        {item2.description?.length > 130 && (
                                          <span
                                            onClick={() =>
                                              isReadMoreClick(item2.name)
                                            }
                                            style={{
                                              cursor: "pointer",
                                              color: "#0C94AC",
                                            }}
                                          >
                                            {isReadMore !== item2.name
                                              ? " ...Show More"
                                              : " ...Show Less"}
                                          </span>
                                        )}
                                      </Text>
                                      {(userInfo.userType === 3 ||
                                        userInfo.userType === 4) && (
                                        <>
                                          {item2?.certificationList && (
                                            <Box mt={"18px"}>
                                              <Text
                                                fontSize={"12px"}
                                                color={"#1C2C44"}
                                                fontWeight={600}
                                                mb={"8px !important"}
                                              >
                                                Certificates:
                                              </Text>
                                              <HStack>
                                                {item2?.certificationList?.map(
                                                  (certificate) => {
                                                    return (
                                                      <Tooltip
                                                        label={
                                                          certificate.title
                                                        }
                                                        hasArrow
                                                        placement={"bottom"}
                                                      >
                                                        <Box
                                                          position={"relative"}
                                                          _hover={{
                                                            ".close-icon-show":
                                                              {
                                                                display:
                                                                  "inline-block",
                                                              },
                                                          }}
                                                        >
                                                          <Image
                                                            src={
                                                              certificate.url
                                                            }
                                                            h={"32px"}
                                                            w={"32px"}
                                                            border={
                                                              "1px solid #DEE8F8"
                                                            }
                                                            borderRadius={"4px"}
                                                            objectFit={
                                                              "contain"
                                                            }
                                                          />
                                                          {permissions.delete && (
                                                            <Image
                                                              className={
                                                                "close-icon-show"
                                                              }
                                                              onClick={() =>
                                                                deleteCertificateModal(
                                                                  item2,
                                                                  certificate.title
                                                                )
                                                              }
                                                              display={"none"}
                                                              src={
                                                                iconCircledClose
                                                              }
                                                              h={"12px"}
                                                              cursor={"pointer"}
                                                              position={
                                                                "absolute"
                                                              }
                                                              top={"-6px"}
                                                              right={"-6px"}
                                                            />
                                                          )}
                                                        </Box>
                                                      </Tooltip>
                                                    );
                                                  }
                                                )}
                                              </HStack>
                                            </Box>
                                          )}
                                        </>
                                      )}
                                    </Box>

                                    <Flex
                                      p={"12px 20px"}
                                      mt={"auto"}
                                      justifyContent={
                                        userInfo.userType === 3 ||
                                        userInfo.userType === 4
                                          ? "space-between"
                                          : "center"
                                      }
                                      borderTop={"1px solid #F3F7FF"}
                                    >
                                      {providersList?.length > 0 &&
                                      !spTenantId ? (
                                        <>
                                          <Button
                                            variant={"specializationButton"}
                                            color={"#0C94AC"}
                                            border={"none"}
                                            width={"100%"}
                                            h={"auto"}
                                            onClick={() => {
                                              if (
                                                providersList &&
                                                providersList.length > 0
                                              ) {
                                                setSelectedSoftware(item2);
                                                addSoftwareToProvider.onOpen();
                                              } else {
                                                onButtonClick(item2, "save");
                                              }
                                            }}
                                            isDisabled={
                                              loading ||
                                              !(permissions.add
                                                ? true
                                                : permissions.assigned)
                                            }
                                            id="search-specialization-add-to-specialization-plus-02"
                                          >
                                            + Add to Specialization
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          {!item2.isChecked ? (
                                            <Button
                                              variant={"specializationButton"}
                                              color={"#0C94AC"}
                                              border={"none"}
                                              width={"100%"}
                                              h={"auto"}
                                              onClick={() => {
                                                if (
                                                  providersList &&
                                                  providersList.length > 0
                                                ) {
                                                  setSelectedSoftware(item2);
                                                  addSoftwareToProvider.onOpen();
                                                } else {
                                                  onButtonClick(item2, "save");
                                                }
                                              }}
                                              isDisabled={
                                                loading ||
                                                !(permissions.add
                                                  ? true
                                                  : permissions.assigned)
                                              }
                                              id="search-specialization-add-to-specialization-plus-02"
                                            >
                                              + Add to Specialization
                                            </Button>
                                          ) : (
                                            <>
                                              {(userInfo.userType === 3 ||
                                                userInfo.userType === 4) && (
                                                <Flex alignItems={"center"}>
                                                  <Text
                                                    color={"#1C2C44"}
                                                    fontSize={"14px"}
                                                    fontWeight={600}
                                                    mr={2}
                                                  >
                                                    Reseller
                                                  </Text>
                                                  <Switch
                                                    isDisabled={
                                                      loading ||
                                                      !(
                                                        permissions.edit &&
                                                        permissions.add
                                                      )
                                                    }
                                                    defaultChecked={
                                                      item2?.reseller
                                                    }
                                                    isChecked={item2?.reseller}
                                                    onChange={(event) =>
                                                      onChangeReseller(
                                                        event.target.checked,
                                                        item2
                                                      )
                                                    }
                                                    size="md"
                                                    colorScheme="teal"
                                                  />
                                                </Flex>
                                              )}

                                              {userInfo.userType !== 3 && (
                                                <Button
                                                  variant={
                                                    "specializationButton"
                                                  }
                                                  border={"none"}
                                                  w={"auto"}
                                                  h={"auto"}
                                                  borderRadius={0}
                                                  ml={"10px"}
                                                  isDisabled={
                                                    loading ||
                                                    !(
                                                      systemGridPermission.add &&
                                                      systemGridPermission.edit
                                                    )
                                                  }
                                                  onClick={() =>
                                                    systemGridModal(item2)
                                                  }
                                                  id="search-specialization-add-to-system-grid-plus-02"
                                                >
                                                  {item2.isInSytemGrid ? (
                                                    <>
                                                      <Image
                                                        boxSize="16px"
                                                        src={iconViewCheck}
                                                        alt={"edit"}
                                                        mr={1}
                                                      />
                                                      View in System Grid
                                                    </>
                                                  ) : (
                                                    "+ Add to system grid"
                                                  )}
                                                </Button>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </Flex>
                                  </Flex>
                                </GridItem>
                              )}
                              {item3 && (
                                <GridItem key={item3.name} w={"100%"}>
                                  <Flex
                                    h={"100%"}
                                    boxShadow="0px 0px 6px 0px #E6E7E9"
                                    border={"1px solid #F9FAFB"}
                                    rounded={"8px"}
                                    overflow={"hidden"}
                                    flexDirection={"column"}
                                    _hover={{ border: "1px solid #9ED4DE" }}
                                  >
                                    <Box
                                      bg={"#F9FAFB"}
                                      p={"20px"}
                                      position={"relative"}
                                    >
                                      <Image
                                        height={"60px"}
                                        objectFit="contain"
                                        src={item3.logo}
                                        alt={item3.name}
                                        fallbackSrc={image}
                                        mx={"auto"}
                                      />
                                      {item3.isChecked && (
                                        <HStack
                                          position={"absolute"}
                                          top={"12px"}
                                          right={"12px"}
                                          justifyContent="space-evenly"
                                          spacing={"4px"}
                                        >
                                          {(userInfo.userType === 3 ||
                                            userInfo.userType === 4) &&
                                            permissions.add &&
                                            permissions.edit && (
                                              <>
                                                {(!item3.certificationList ||
                                                  item3?.certificationList
                                                    ?.length < 10) && (
                                                  <Tooltip
                                                    label={"Add Certificate"}
                                                    placement="top"
                                                    hasArrow
                                                  >
                                                    <Flex
                                                      alignItems={"center"}
                                                      justifyContent={"center"}
                                                      h={"24px"}
                                                      w={"24px"}
                                                      rounded={"50%"}
                                                      border={
                                                        "1px dashed transparent"
                                                      }
                                                      cursor={
                                                        loading ||
                                                        !permissions.edit
                                                          ? "not-allowed"
                                                          : "pointer"
                                                      }
                                                      _hover={{
                                                        border:
                                                          "1px dashed #0C94AC",
                                                        bg: "#E6E7E9",
                                                      }}
                                                      onClick={() =>
                                                        addCertificateModal(
                                                          item3
                                                        )
                                                      }
                                                    >
                                                      <Image
                                                        boxSize="16px"
                                                        objectFit="cover"
                                                        src={iconCertificate}
                                                        alt={"certificate"}
                                                      />
                                                    </Flex>
                                                  </Tooltip>
                                                )}
                                              </>
                                            )}

                                          {permissions.edit && (
                                            <>
                                              <Tooltip
                                                label={"Edit Software"}
                                                placement="top"
                                                hasArrow
                                              >
                                                <Flex
                                                  alignItems={"center"}
                                                  justifyContent={"center"}
                                                  h={"24px"}
                                                  w={"24px"}
                                                  rounded={"50%"}
                                                  border={
                                                    "1px dashed transparent"
                                                  }
                                                  cursor={
                                                    loading || !permissions.edit
                                                      ? "not-allowed"
                                                      : "pointer"
                                                  }
                                                  _hover={{
                                                    border:
                                                      "1px dashed #BFC3C9",
                                                    bg: "#E6E7E9",
                                                  }}
                                                  onClick={() =>
                                                    editModal(item3)
                                                  }
                                                >
                                                  <Image
                                                    boxSize="16px"
                                                    objectFit="cover"
                                                    src={iconGrayEdit}
                                                    alt={"edit"}
                                                  />
                                                </Flex>
                                              </Tooltip>
                                              <Tooltip
                                                label={"Remove Software"}
                                                placement="top"
                                                hasArrow
                                              >
                                                <Flex
                                                  alignItems={"center"}
                                                  justifyContent={"center"}
                                                  h={"24px"}
                                                  w={"24px"}
                                                  rounded={"50%"}
                                                  border={
                                                    "1px dashed transparent"
                                                  }
                                                  cursor={
                                                    loading || !permissions.edit
                                                      ? "not-allowed"
                                                      : "pointer"
                                                  }
                                                  _hover={{
                                                    border:
                                                      "1px dashed #FBD5D5",
                                                    bg: "#FDE8E8",
                                                  }}
                                                  onClick={() =>
                                                    onButtonClick(
                                                      item3,
                                                      "delete"
                                                    )
                                                  }
                                                >
                                                  <Image
                                                    boxSize="16px"
                                                    objectFit="cover"
                                                    src={iconTrash}
                                                    alt={"Delete"}
                                                  />
                                                </Flex>
                                              </Tooltip>
                                            </>
                                          )}
                                        </HStack>
                                      )}
                                    </Box>
                                    <Box p={"12px 20px"}>
                                      <Text
                                        fontSize={"14px"}
                                        fontWeight={"600"}
                                        color={"var(--title-black-text)"}
                                      >
                                        {item3.name.replace(/"/g, "")}
                                      </Text>
                                      {selectedCategory !== "All" && (
                                        <Text
                                          fontSize="12px"
                                          fontWeight={"400"}
                                          color={"#556781"}
                                        >
                                          {item3.category}{" "}
                                        </Text>
                                      )}
                                      <Text fontSize={"12px"} mt={"8px"}>
                                        {isReadMore !== item3.name ? (
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: item3?.description
                                                ?.slice(0, 130)
                                                .replace(/"/g, ""),
                                            }}
                                            style={{ wordBreak: "break-word" }}
                                          />
                                        ) : (
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                item3?.description?.replace(
                                                  /"/g,
                                                  ""
                                                ),
                                            }}
                                            style={{ wordBreak: "break-word" }}
                                          />
                                        )}
                                        {item3.description?.length > 130 && (
                                          <span
                                            onClick={() =>
                                              isReadMoreClick(item3.name)
                                            }
                                            style={{
                                              cursor: "pointer",
                                              color: "#0C94AC",
                                            }}
                                          >
                                            {isReadMore !== item3.name
                                              ? " ...Show More"
                                              : " ...Show Less"}
                                          </span>
                                        )}
                                      </Text>
                                      {(userInfo.userType === 3 ||
                                        (userInfo.userType === 4 &&
                                          permissions.add &&
                                          permissions.edit)) && (
                                        <>
                                          {item3?.certificationList && (
                                            <Box mt={"18px"}>
                                              <Text
                                                fontSize={"12px"}
                                                color={"#1C2C44"}
                                                fontWeight={600}
                                                mb={"8px !important"}
                                              >
                                                Certificates:
                                              </Text>
                                              <HStack>
                                                {item3?.certificationList?.map(
                                                  (certificate) => {
                                                    return (
                                                      <Tooltip
                                                        label={
                                                          certificate.title
                                                        }
                                                        hasArrow
                                                        placement={"bottom"}
                                                      >
                                                        <Box
                                                          position={"relative"}
                                                          _hover={{
                                                            ".close-icon-show":
                                                              {
                                                                display:
                                                                  "inline-block",
                                                              },
                                                          }}
                                                        >
                                                          <Image
                                                            src={
                                                              certificate.url
                                                            }
                                                            h={"32px"}
                                                            w={"32px"}
                                                            border={
                                                              "1px solid #DEE8F8"
                                                            }
                                                            borderRadius={"4px"}
                                                            objectFit={
                                                              "contain"
                                                            }
                                                          />
                                                          {permissions.delete && (
                                                            <Image
                                                              className={
                                                                "close-icon-show"
                                                              }
                                                              onClick={() =>
                                                                deleteCertificateModal(
                                                                  item3,
                                                                  certificate.title
                                                                )
                                                              }
                                                              display={"none"}
                                                              src={
                                                                iconCircledClose
                                                              }
                                                              h={"12px"}
                                                              cursor={"pointer"}
                                                              position={
                                                                "absolute"
                                                              }
                                                              top={"-6px"}
                                                              right={"-6px"}
                                                            />
                                                          )}
                                                        </Box>
                                                      </Tooltip>
                                                    );
                                                  }
                                                )}
                                              </HStack>
                                            </Box>
                                          )}
                                        </>
                                      )}
                                    </Box>

                                    <Flex
                                      p={"12px 20px"}
                                      mt={"auto"}
                                      justifyContent={
                                        userInfo.userType === 3 ||
                                        userInfo.userType === 4
                                          ? "space-between"
                                          : "center"
                                      }
                                      borderTop={"1px solid #F3F7FF"}
                                    >
                                      {providersList?.length > 0 &&
                                      !spTenantId ? (
                                        <>
                                          <Button
                                            variant={"specializationButton"}
                                            color={"#0C94AC"}
                                            border={"none"}
                                            width={"100%"}
                                            h={"auto"}
                                            onClick={() => {
                                              if (
                                                providersList &&
                                                providersList.length > 0
                                              ) {
                                                setSelectedSoftware(item3);
                                                addSoftwareToProvider.onOpen();
                                              } else {
                                                onButtonClick(item3, "save");
                                              }
                                            }}
                                            isDisabled={
                                              loading ||
                                              !(permissions.add
                                                ? true
                                                : permissions.assigned)
                                            }
                                            id="search-specialization-add-to-specialization-plus-03"
                                          >
                                            + Add to Specialization
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          {!item3.isChecked ? (
                                            <Button
                                              variant={"specializationButton"}
                                              color={"#0C94AC"}
                                              border={"none"}
                                              width={"100%"}
                                              h={"auto"}
                                              onClick={() => {
                                                if (
                                                  providersList &&
                                                  providersList.length > 0
                                                ) {
                                                  setSelectedSoftware(item3);
                                                  addSoftwareToProvider.onOpen();
                                                } else {
                                                  onButtonClick(item3, "save");
                                                }
                                              }}
                                              isDisabled={
                                                loading ||
                                                !(permissions.add
                                                  ? true
                                                  : permissions.assigned)
                                              }
                                              id="search-specialization-add-to-specialization-plus-03"
                                            >
                                              + Add to Specialization
                                            </Button>
                                          ) : (
                                            <>
                                              {(userInfo.userType === 3 ||
                                                userInfo.userType === 4) && (
                                                <Flex alignItems={"center"}>
                                                  <Text
                                                    color={"#1C2C44"}
                                                    fontSize={"14px"}
                                                    fontWeight={600}
                                                    mr={2}
                                                  >
                                                    Reseller
                                                  </Text>
                                                  <Switch
                                                    isDisabled={
                                                      loading ||
                                                      !(
                                                        permissions.edit &&
                                                        permissions.add
                                                      )
                                                    }
                                                    defaultChecked={
                                                      item3?.reseller
                                                    }
                                                    isChecked={item3?.reseller}
                                                    onChange={(event) =>
                                                      onChangeReseller(
                                                        event.target.checked,
                                                        item3
                                                      )
                                                    }
                                                    size="md"
                                                    colorScheme="teal"
                                                  />
                                                </Flex>
                                              )}

                                              {userInfo.userType !== 3 && (
                                                <Button
                                                  variant={
                                                    "specializationButton"
                                                  }
                                                  border={"none"}
                                                  w={"auto"}
                                                  h={"auto"}
                                                  borderRadius={0}
                                                  ml={"10px"}
                                                  isDisabled={
                                                    loading ||
                                                    !(
                                                      systemGridPermission.add &&
                                                      systemGridPermission.edit
                                                    )
                                                  }
                                                  onClick={() =>
                                                    systemGridModal(item3)
                                                  }
                                                  id="search-specialization-add-to-system-grid-plus-03"
                                                >
                                                  {item3.isInSytemGrid ? (
                                                    <>
                                                      <Image
                                                        boxSize="16px"
                                                        src={iconViewCheck}
                                                        alt={"edit"}
                                                        mr={1}
                                                      />
                                                      View in System Grid
                                                    </>
                                                  ) : (
                                                    "+ Add to system grid"
                                                  )}
                                                </Button>
                                              )}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </Flex>
                                  </Flex>
                                </GridItem>
                              )}
                            </Grid>
                          </>
                        )}
                      </CellMeasurer>
                    );
                  }
                }}
              />
            </Scrollbars>
          )}
        </AutoSizer>
      ) : (
        <Center flexDirection={"column"} p={10}>
          <Image src={NoResult} h={"130px"} mb={3} />
          <Text
            fontSize={"16px"}
            fontWeight={700}
            color={"brand.100"}
            mb={"15px !important"}
          >
            Sorry! No result found 😞
          </Text>
          <Text
            fontSize={"14px"}
            fontWeight={400}
            color={"#7284A0"}
            textAlign={"center"}
            mb={"15px !important"}
          >
            Software is not found. Please try searching with another term or
            request a new one using the "Add Software" button
          </Text>
          {permissions.add && (
            <CustomButton
              variant={"newColorThemePrimary"}
              bg={"transparent"}
              color={"#0C94AC"}
              border={"1px solid #0C94AC"}
              p={"8px 16px"}
              h={"auto"}
              title="Add Software"
              onClick={() => addSoftware.onOpen()}
              id="search-specialization-add-software-empty"
            />
          )}
        </Center>
      )}
    </Stack>
  );
};

export default SearchSpecialization;
