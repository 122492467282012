import React, { useEffect, useState } from "react";
import { Table } from "@coachbar/shared-components";
import { Avatar, Box, Flex, Progress, Text } from "@chakra-ui/react";
// import Medal from "../../../image/medal-1.svg";

const UserTable = ({ userList }) => {
  const [tableBody, setTableBody] = useState([]);
  const tableHead = [
    [
      "Users",
      "Courses Assigned",
      "Complete",
      // "Points Earned", "Badges Earned"
    ],
  ];

  useEffect(() => {
    if (!userList || userList.length === 0) return;

    const updatedTableBody = userList.map((user) => {
      const bodyItem = [];

      if (user && user.firstName && user.lastName) {
        bodyItem.push(
          <Flex alignItems="center" gap={2}>
            <Avatar
              size="xs"
              border="1px solid #CEEAEE"
              bg="#E7FAFD"
              color="#0A768A"
              name={`${user.firstName} ${user.lastName}`}
            />
            <Text
              fontSize="14px"
              fontWeight={500}
              color="#111A29"
            >{`${user.firstName} ${user.lastName}`}</Text>
          </Flex>
        );
      }

      if (user && String(user.totalCourses)) {
        bodyItem.push(
          <Text fontSize="14px" fontWeight={450} color="#111A29">
            {String(user.totalCourses)}
          </Text>
        );
      }

      if (user && user.averageCompletionPercentage) {
        bodyItem.push(
          <Flex alignItems="center" justifyContent="space-evenly">
            <Progress
              max={100}
              w="76px"
              h="6px"
              borderRadius="full"
              colorScheme="green"
              value={user.averageCompletionPercentage ?? 0}
            />
            <Text fontSize="12px" fontWeight={450} color="#111A29">
              {user.averageCompletionPercentage}%
            </Text>
          </Flex>
        );

        // if (userList[index]?.pointsEarned) {
        //   bodyItem.push(
        //     <Text fontSize={"14px"} fontWeight={450} color={"#111A29"}>
        //       {userList[index]?.pointsEarned}
        //     </Text>
        //   );
        // }

        // if (userList[index]?.badgesEarned || !userList[index]?.badgesEarned) {
        //   const medals = [];
        //   for (let j = 0; j < userList[index]?.badgesEarned; j++) {
        //     medals.push(<Image key={j} src={Medal} alt={`medal-${j}`} />);
        //   }
        //   bodyItem.push(<Flex gap={"2px"}>{medals}</Flex>);
        // }
      }

      return bodyItem;
    });

    setTableBody(updatedTableBody);
  }, [userList]);

  return (
    <Box
      css={{
        ".custom-table": {
          borderRadius: "6px",
        },
      }}
    >
      <Table
        tHead={tableHead}
        tBody={tableBody}
        keepBordersRounded
        // keepLastColumnLeftAligned={!permissions.edit || !permissions.delete}
        headerTextStyle={{
          background: "#F9FAFB",
          color: "#5E6977",
          fontSize: "12px",
          fontWeight: 600,
          // borderRadius: "6px, 6px, 0px, 0px",
          border: "1px solid #E6E7E9",
          textAlign: "left",
        }}
        bodyTextStyle={{
          background: "#fff",
          // borderRadius: "6px, 6px, 0px, 0px",
          border: "1px solid #E6E7E9",
        }}
      />
    </Box>
  );
};

export default UserTable;
