import { Box, Center } from "@chakra-ui/react";
import { Loader } from "@coachbar/shared-components";
import ChartLegends from "@coachbar/shared-components/src/components/Charts/ChartLegends";
import GeoGraph from "@coachbar/shared-components/src/components/Charts/GeoGraph";
import {
  regionColors,
  regionKeys,
  regionLabels,
} from "@coachbar/shared-components/src/constants/dashboard";
import ChartContainer from "@coachbar/shared-components/src/screens/dashboard/ChartContainer";
import ChartGrid from "@coachbar/shared-components/src/screens/dashboard/ChartGrid";
import ChartHeader from "@coachbar/shared-components/src/screens/dashboard/ChartHeader";
import { useChartsProvider } from "@coachbar/shared-components/src/screens/dashboard/chartContext";
import {
  calculatePercentageBasedOnData,
  compareInnerAndGeneralFilters,
  formatDateRange,
  generateRegionWiseDataForRegionMap,
  getAppliedFilters,
  getPreviousDateRange,
  getSpecificInnerFilters,
  regionCompareData,
} from "@coachbar/shared-components/src/services/dashboardUtility/utility";
import { deepCopyObject } from "@coachbar/shared-components/src/services/utility";
import { useEffect, useMemo, useState } from "react";
import { getDealRegionData } from "../../dashboardActions";

const DealsByRegion = ({ slug, chartData }) => {
  const { selectedDate, appliedFilters, fixFilters } = useChartsProvider();

  const widgetInfo = chartData;
  const [chartType, setChartType] = useState("");
  const [record, setRecords] = useState([]);
  const [prevDateRange, setPrevDateRange] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [innerFilters, setInnerFilters] = useState(
    getSpecificInnerFilters(fixFilters, chartData.filters)
  );
  const [comparedRecords, setCompareRecords] = useState([]);
  const [isCompared, setIsCompared] = useState(false);
  const [innerDate, setInnerDate] = useState({});

  let innerAppliedFilters = useMemo(() => {
    return getAppliedFilters(innerFilters);
  }, [innerFilters]);

  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      clearPreviousData();
      let requestData = constructRequestData(innerDate);
      fetchData(requestData);
    }
  }, [innerDate, selectedDate, appliedFilters, innerAppliedFilters]);

  const fetchData = async (requestData, isCompared = false) => {
    setIsLoading(true);
    let { data } = await getDealRegionData(requestData);
    setIsLoading(false);
    if (isCompared) {
      setCompareRecords(generateRegionWiseDataForRegionMap(data.dashboard));
    } else {
      setRecords(
        deepCopyObject(generateRegionWiseDataForRegionMap(data.dashboard))
      );
    }
  };

  const clearPreviousData = () => {
    setIsCompared(false);
    setCompareRecords([]);
    setPrevDateRange(null);
  };

  const constructRequestData = ({ date }) => {
    let requestData = compareInnerAndGeneralFilters(
      appliedFilters,
      innerAppliedFilters,
      Object.keys(fixFilters),
      date,
      selectedDate
    );

    return requestData;
  };

  const onToogleCompare = () => {
    if (isCompared) {
      clearPreviousData();
    } else {
      setIsCompared(true);
      let conDate = deepCopyObject(
        innerDate?.to && innerDate?.from ? innerDate : selectedDate
      );
      const previousDateRange = getPreviousDateRange(conDate);
      setPrevDateRange(previousDateRange);
      const requestData = constructRequestData({ date: previousDateRange });
      fetchData(requestData, true);
    }
  };

  const formattedDateRange = useMemo(() => {
    let conDate = deepCopyObject(
      innerDate?.to && innerDate?.from ? innerDate : selectedDate
    );
    return formatDateRange(conDate || null);
  }, [selectedDate, innerDate]);

  const [formattedPrevDateForGrid] = useMemo(() => {
    // let conDate = deepCopyObject(
    //   innerDate?.to && innerDate?.from ? innerDate : selectedDate
    // );
    // Date format to show in Grid
    // const date = formatDateRange(conDate || null, "grid");
    const prevDate = formatDateRange(prevDateRange || null, "grid");
    // Different date format to show in chart tooltip
    // const formattedDate = formatDateRange(conDate || null, "chart");
    // const formattedPrevDate = formatDateRange(prevDateRange || null, "chart");
    return [prevDate];
  }, [prevDateRange]);

  const geoDataWithPercentage = useMemo(() => {
    if (isCompared) {
      return calculatePercentageBasedOnData(
        regionCompareData(record, comparedRecords)
      );
    } else {
      return calculatePercentageBasedOnData(record);
    }
  }, [record, comparedRecords, isCompared]);

  const formattedDataForGrid = useMemo(() => {
    let gridData = {};
    geoDataWithPercentage.forEach((data) => {
      if (!gridData[data?.legend]) {
        gridData[data?.legend] = {
          id: data.legend,
          value: data.value,
        };
        if (data?.hasOwnProperty("previousValue")) {
          gridData[data?.legend].previousValue = data?.previousValue;
        }
      }
    });
    return Object.values(gridData);
  }, [geoDataWithPercentage]);

  return (
    <>
      {widgetInfo?.isVisible && (
        <ChartContainer>
          <ChartHeader
            title={widgetInfo?.title}
            showFilters
            showDelete
            showConvertToGrid
            showCompare
            chartData={chartData}
            onToogleCompare={onToogleCompare}
            isCompared={isCompared}
            chartType={chartType}
            onChartTypeChange={() => {
              if (chartType === "") {
                setChartType("table");
              } else {
                setChartType("");
              }
            }}
            filters={innerFilters}
            onSubmitFilters={(newFilters, date) => {
              setInnerFilters(newFilters);
              setInnerDate(date);
            }}
            date={innerDate}
            generalDate={selectedDate}
          />

          {isLoading ? (
            <Center h={"80%"}>
              <Loader />
            </Center>
          ) : (
            <>
              {chartType === "table" ? (
                <ChartGrid
                  data={formattedDataForGrid}
                  headings={
                    isCompared
                      ? [
                          [
                            "Region",
                            formattedDateRange,
                            formattedPrevDateForGrid,
                          ],
                        ]
                      : [["Region", formattedDateRange]]
                  }
                />
              ) : (
                <Box h={"450px"}>
                  <GeoGraph data={geoDataWithPercentage} />
                  <Box mt="30px">
                    <ChartLegends
                      data={Object.keys(regionKeys).map((x) => ({
                        legend: regionLabels[x],
                        color: regionColors[x],
                      }))}
                    />
                  </Box>
                </Box>
              )}
            </>
          )}
        </ChartContainer>
      )}
    </>
  );
};

export default DealsByRegion;
