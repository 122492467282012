// CompanyProfile.js
import { useState, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import Notification from "../../../components/Notification";
import {
  addCompanyProfile,
  getCompanyProfile,
  updateCompanyProfile,
} from "./companyProfileActions";
import { setUserData } from "../../auth/authSlice";
import {
  appendHttpsToUrl,
  getPreSignedUrl,
  removeHttpsFromUrl,
  uploadImage,
} from "../../../services/utility";
import {
  TextBox,
  TextEditor,
  Loader,
  Link,
  TipsLinkButton,
} from "@coachbar/shared-components";
import {
  HStack,
  Divider,
  Box,
  Text,
  Flex,
  Center,
  Tab,
  TabList,
  Tabs,
  Image,
  VStack,
  Spacer,
  Tooltip,
  Avatar,
} from "@chakra-ui/react";
import {
  requiredField,
  maxFileSize,
  fileTypeAllowed,
  invalidUrl,
  minYearAllowed,
  maxYearAllowed,
  whiteSpace,
} from "../../../constants/validationMessages";
import {
  urlValidationPattern,
  whiteSpacePattern,
} from "../../../constants/validationPatterns";
import { setUserInfo, getUserInfo } from "../../../services/utility";
import {
  clearMessage,
  LOADER_TYPE,
  setCompanyProfileLoader,
  updateTriggerSubmit,
} from "./companyProfileSlice";
import { clearRoadMapMessage } from "../../roadmap/roadmapSlice";
import { getProfileCompletionStatus } from "../profileMetrics/profileMetricsAction";
import { useNavigate } from "react-router-dom";
import CustomPhoneInput from "@coachbar/shared-components/src/screens/PhoneInput";
import { getCountryIso3 } from "@coachbar/shared-components/src/services/utility";
import FileUpload from "@coachbar/shared-components/src/components/SPFileUpload/FileUpload";
import { ReactComponent as Copy03 } from "../../../image/copy-03.svg";
import { ReactComponent as Check } from "../../../image/check.svg";

// temp icon to show partner software for sc
import CoachBarLogoIcon from "../../../image/coachbar-logo-icon.png";
import { getPartnerProviderList } from "../../myProviders/providersList/providersListActions";
import { scrollbarCSS } from "@coachbar/shared-components/src/constants/constantValues";
import { getActiveRequirements } from "../../serviceOffering/serviceOfferingActions";
import { TIPS_LINK_PAGE_COMPANY_PROFILE } from "../../../constants/profileSetup";

const LOGO_LABEL = "logo";
const BANNER_LABEL = "bannerImage";
const MAX_FILE_SIZE_BANNER = 5000; // in KB;
const MAX_FILE_SIZE_LOGO = 200; // in KB;
const VALUE_MISSION_MAX_LENGTH = 2000;
const ABOUT_MAX_LENGTH = 2000;

const CompanyProfile = ({ permissions, setActiveStep }) => {
  const navigate = useNavigate();
  const { companyProfile, loading, error, success, triggerSubmit } =
    useSelector((state) => state.companyProfile);
  const { roadMapSuccess } = useSelector((state) => state.roadmap);
  const { userInfo } = useSelector((state) => state.auth);

  const [passwordMessage, setPasswordMessage] = useState(false);
  const [countryData, setCountryData] = useState({});
  const [partnerProviderList, setPartnerProviderList] = useState([]);

  const {
    register,
    resetField,
    handleSubmit,
    setValue,
    watch,
    control,
    trigger,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });
  const dispatch = useDispatch();

  const submitForm = async (data) => {
    let requestData = { ...data, ...imagePath };
    requestData.companyName = requestData?.companyName?.trim();
    if (countryData?.name) {
      let iso3 = getCountryIso3(countryData.name);
      requestData.countryCode = `+${countryData?.dialCode}`;
      requestData.countryISO = iso3;
    } else {
      requestData.countryCode = companyProfile?.countryCode || "";
      requestData.countryISO = companyProfile?.countryISO || "";
    }
    requestData.website = appendHttpsToUrl(requestData.website);
    requestData.linkedInProfile = appendHttpsToUrl(requestData.linkedInProfile);
    requestData.twitterProfile = appendHttpsToUrl(requestData.twitterProfile);
    if (providersData?.length) {
      const parentData = providersData[0];
      requestData.companyDetails = parentData.details;
      requestData.valueAndMission = parentData.mission;
      requestData.profileManagementList = providersData.slice(1) || [];

      if (
        partnerProviderList.length !== requestData.profileManagementList?.length
      ) {
        const profileManagementListData = partnerProviderList.map((partner) => {
          const profileManagementRecord =
            requestData.profileManagementList.find(
              (partnerRecord) =>
                partnerRecord.spTenantId === partner.referenceTenantId
            );
          if (profileManagementRecord)
            return getProfileManagementObj(profileManagementRecord);
          return getProfileManagementObj({
            spTenantId: partner.referenceTenantId,
          });
        });
        requestData.profileManagementList = profileManagementListData;
      }
    }
    if (companyProfile && companyProfile.id) {
      dispatch(
        updateCompanyProfile({
          companyId: companyProfile.id,
          requestParams: requestData,
        })
      ).then((res) => {
        let userDetails = getUserInfo();
        userDetails["companyName"] = res.payload.data.profile.companyName;
        setUserInfo(userDetails);
        dispatch(getProfileCompletionStatus());
        setActiveStep(1);
      });
    } else {
      dispatch(addCompanyProfile(requestData)).then((responseData) => {
        if (responseData.payload.success && responseData.payload.data) {
          let userDetails = getUserInfo();
          userDetails["profileCompleted"] = true;
          setUserInfo(userDetails);
          dispatch(setUserData());
          dispatch(getProfileCompletionStatus());
          setActiveStep(1);
        }
      });
    }
  };

  const [isoValue, setISOValue] = useState("");
  const [imagePath, setimagePath] = useState({
    [BANNER_LABEL]: "",
    [LOGO_LABEL]: "",
  });

  let initialized = useRef(false);

  useEffect(() => {
    if (triggerSubmit) {
      handleSubmit(submitForm, handleError)();
      dispatch(updateTriggerSubmit());
    }
  }, [triggerSubmit]);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      setValue("companyName", userInfo?.companyName || "");
      dispatch(getCompanyProfile());
      handlePartnerProviders();
      register("keepForAllAboutCompany", { value: false });
      register("keepForAllValueAndMission", { value: false });
      // dispatch(updateTriggerSubmit());
    }
    return () => {
      dispatch(clearMessage());
      dispatch(clearRoadMapMessage());
      setAboutCompanyTabIndex(0);
      setValueAndMissionTabIndex(0);
    };
  }, [dispatch]);

  let messageInitialized = false;
  useEffect(() => {
    if (!messageInitialized && localStorage.getItem("isRedirect")) {
      messageInitialized = true;
      const notificationTimeout = setTimeout(() => {
        setPasswordMessage(true);
      }, 1000);
      return () => clearTimeout(notificationTimeout);
    }
  }, []);

  useEffect(() => {
    if (companyProfile) {
      let skipKeys = [
        LOGO_LABEL,
        BANNER_LABEL,
        "countryCode",
        "countryISO",
        "modified",
        "created",
        "id",
        "tenantId",
      ];
      setimagePath((prevState) => ({
        ...prevState,
        [LOGO_LABEL]: companyProfile[LOGO_LABEL]
          ? companyProfile[LOGO_LABEL]
          : "",
        [BANNER_LABEL]: companyProfile[BANNER_LABEL]
          ? companyProfile[BANNER_LABEL]
          : "",
      }));
      if (companyProfile.countryCode && companyProfile.countryISO) {
        let country = `${companyProfile.countryISO}(${companyProfile.countryCode})`;
        setISOValue(country);
        setValue("country", country);
      }
      for (const key in companyProfile) {
        if (companyProfile.hasOwnProperty(key) && !skipKeys.includes(key)) {
          setValue(key, companyProfile[key]);
        }
      }
      if (companyProfile.website)
        setValue("website", removeHttpsFromUrl(companyProfile.website));
      if (companyProfile.linkedInProfile)
        setValue(
          "linkedInProfile",
          removeHttpsFromUrl(companyProfile.linkedInProfile)
        );
      if (companyProfile.twitterProfile)
        setValue(
          "twitterProfile",
          removeHttpsFromUrl(companyProfile.twitterProfile)
        );
      const currProviderData = [
        {
          spTenantId: process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID,
          details: companyProfile.companyDetails,
          mission: companyProfile.valueAndMission,
        },
      ];
      if (companyProfile?.profileManagementList?.length) {
        companyProfile.profileManagementList.forEach((partnerData) => {
          currProviderData.push(getProfileManagementObj(partnerData));
        });
      }
      setProvidersData(currProviderData);
    }
  }, [companyProfile]);

  let fieldType = "";
  const [isBannerLoading, setIsBannerLoading] = useState(false);
  const [isLogoLoading, setIsLogoLoading] = useState(false);

  const validateLogo = (value) => {
    if ((!value || value.length < 1) && !imagePath[LOGO_LABEL]) {
      return requiredField.replace("$Field$", "Company Logo");
    }
    fieldType = "logo";
    return validateFile(value);
  };

  const validateBanner = (value) => {
    fieldType = "banner";
    return validateFile(value);
  };

  const validateFile = (files) => {
    const fileObj = files && files[0];
    if (!fileObj || !fileObj.type) {
      return true;
    }

    const [type] = fileObj.type.split("/");
    if (!type || type !== "image") {
      return fileTypeAllowed;
    }

    const fsMb = fileObj.size / 1024;
    let maxAllowedFileSize = MAX_FILE_SIZE_BANNER;
    if (fieldType === "logo") {
      maxAllowedFileSize = MAX_FILE_SIZE_LOGO;
    }

    if (fsMb > maxAllowedFileSize) {
      if (fieldType === "logo") {
        setValue(LOGO_LABEL, "");
      }
      return maxFileSize.replace("$MB$", maxAllowedFileSize + "KB");
    }
    if (!isSubmitting) {
      uploadFile(fileObj);
    }
    return true;
  };

  const uploadFile = (fileObj) => {
    let fileExt = fileObj.name.split(".").pop();
    let filePath = fieldType + "-" + Date.now() + "." + fileExt;
    dispatch(setCompanyProfileLoader({ key: LOADER_TYPE.IMAGE, value: true }));
    if (fieldType === "logo") {
      setIsLogoLoading(true);
    }
    if (fieldType === "banner") {
      setIsBannerLoading(true);
    }
    getPreSignedUrl(filePath).then((responseData) => {
      if (responseData.data && responseData.data.success) {
        const preSignedUrl = responseData.data?.data?.signedUrl;
        uploadImageFileToS3(fileObj, preSignedUrl);
      }
    });
  };

  const uploadImageFileToS3 = async (fileObj, preSignedUrl) => {
    uploadImage(fileObj, preSignedUrl).then((responseData) => {
      if (responseData.status === 200) {
        let imageUrl = preSignedUrl.split("?")[0];
        if (fieldType === "logo") {
          setimagePath((prevState) => ({
            ...prevState,
            [LOGO_LABEL]: imageUrl,
          }));
          setIsLogoLoading(false);
        }
        if (fieldType === "banner") {
          setimagePath((prevState) => ({
            ...prevState,
            [BANNER_LABEL]: imageUrl,
          }));
          setIsBannerLoading(false);
        }
        fieldType = "";
      } else {
        if (isBannerLoading) {
          setIsBannerLoading(false);
        }
        if (isLogoLoading) {
          setIsLogoLoading(false);
        }
      }
      dispatch(
        setCompanyProfileLoader({ key: LOADER_TYPE.IMAGE, value: false })
      );
    });
  };

  const handleError = (errors) => {
    if (errors && errors[BANNER_LABEL]) {
      resetField(BANNER_LABEL);
      handleSubmit(submitForm)();
    }
    if (errors && errors[LOGO_LABEL]) {
      resetField(LOGO_LABEL);
      handleSubmit(submitForm)();
    }
  };

  const onBannerDeleteClick = () => {
    setimagePath((prevState) => ({
      ...prevState,
      [BANNER_LABEL]: "",
    }));
    resetField(BANNER_LABEL);
  };

  const onLogoDeleteClick = () => {
    setimagePath((prevState) => ({
      ...prevState,
      [LOGO_LABEL]: "",
    }));
    resetField(LOGO_LABEL);
  };

  const passwordMsg = () => {
    return (
      <Text>
        We belive you have signed up by google.
        <Text
          as="span"
          cursor={"pointer"}
          textDecoration={"underline"}
          onClick={() => {
            navigate("/my-account");
            setPasswordMessage(false);
            localStorage.removeItem("isRedirect");
          }}
        >
          Please click here to set your password
        </Text>
      </Text>
    );
  };

  const [providersData, setProvidersData] = useState([]);

  // about company states and methods
  const [aboutCompanyCount, setAboutCompanyCount] = useState(0);
  const [aboutCompanyTabIndex, setAboutCompanyTabIndex] = useState(0);
  const [
    aboutCompCurrPartnerRequirements,
    setAboutCompCurrPartnerRequirements,
  ] = useState([]);
  const editorContentCompany = watch("companyDetails");
  const keepForAllAboutCompany = watch("keepForAllAboutCompany");

  useEffect(() => {
    const { details, spTenantId } = getCurrentPartnerData(aboutCompanyTabIndex);
    setValue("companyDetails", details);
    getAboutCompCurrPartnerRequirements(spTenantId);
    details.replace(/(<([^>]+)>)/gi, "").length && trigger("companyDetails");
  }, [aboutCompanyTabIndex]);

  useEffect(() => {
    const charCount =
      editorContentCompany?.replace(/(<([^>]+)>)/gi, "").length || 0;
    setAboutCompanyCount(charCount);
    setValue("keepForAllAboutCompany", false);
  }, [editorContentCompany, setValue]);

  useEffect(() => {
    if (keepForAllAboutCompany)
      setProvidersData((prevProvidersData) =>
        prevProvidersData.map((providerData) => ({
          ...providerData,
          details: editorContentCompany,
        }))
      );
  }, [keepForAllAboutCompany]);

  const handleCompanyAboutChange = (content = "") => {
    let charCount = content.replace(/(<([^>]+)>)/gi, "").length;
    const currentPartnerData = getCurrentPartnerData(aboutCompanyTabIndex);
    if (charCount > ABOUT_MAX_LENGTH) {
      setValue("companyDetails", currentPartnerData.details);
      return null;
    } else {
      if (content && content !== "<p><br></p>") {
        setValue("companyDetails", content);
        setProvidersData((prev) =>
          getUpdatedPartnerData(
            { ...currentPartnerData, details: content },
            prev
          )
        );
      } else {
        setValue("companyDetails", "");
        setProvidersData((prev) =>
          getUpdatedPartnerData({ ...currentPartnerData, details: "" }, prev)
        );
      }
    }
    trigger("companyDetails");
  };

  // value and mission states and methods
  const [valueAndMissionCount, setvalueAndMissionCount] = useState(0);
  const [valueAndMissionTabIndex, setValueAndMissionTabIndex] = useState(0);
  const editorContentValueAndMission = watch("valueAndMission");
  const keepForAllValueAndMission = watch("keepForAllValueAndMission");

  useEffect(() => {
    const { mission } = getCurrentPartnerData(valueAndMissionTabIndex);
    setValue("valueAndMission", mission);
    mission.replace(/(<([^>]+)>)/gi, "").length && trigger("valueAndMission");
  }, [valueAndMissionTabIndex]);

  useEffect(() => {
    const charCount =
      editorContentValueAndMission?.replace(/(<([^>]+)>)/gi, "").length || 0;
    setvalueAndMissionCount(charCount);
    setValue("keepForAllValueAndMission", false);
  }, [trigger, editorContentValueAndMission, setValue]);

  useEffect(() => {
    if (keepForAllValueAndMission)
      setProvidersData((prevProvidersData) =>
        prevProvidersData.map((providerData) => ({
          ...providerData,
          mission: editorContentValueAndMission,
        }))
      );
  }, [keepForAllValueAndMission]);

  const handleCompanyValueAndMission = (content = "") => {
    let charCount = content.replace(/(<([^>]+)>)/gi, "").length;
    const currentPartnerData = getCurrentPartnerData(valueAndMissionTabIndex);
    if (charCount > VALUE_MISSION_MAX_LENGTH) {
      setValue("valueAndMission", currentPartnerData.mission);
      return null;
    } else {
      if (content && content !== "<p><br></p>") {
        setValue("valueAndMission", content);
        setProvidersData((prev) =>
          getUpdatedPartnerData(
            { ...currentPartnerData, mission: content },
            prev
          )
        );
      } else {
        setValue("valueAndMission", "");
        setProvidersData((prev) =>
          getUpdatedPartnerData({ ...currentPartnerData, mission: "" }, prev)
        );
      }
    }

    trigger("valueAndMission");
  };

  const handlePartnerProviders = async () => {
    const partnerProviderData = await getPartnerProviderList({});
    setPartnerProviderList([...partnerProviderData.partnerList]);
  };

  const getCurrentPartnerData = (index) => {
    if (index === 0)
      return providersData[0]
        ? getProfileManagementObj({
            ...providersData[0],
            spTenantId: process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID,
          })
        : getProfileManagementObj({
            spTenantId: process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID,
          });
    const currentProviderTenantId =
      partnerProviderList[index - 1].referenceTenantId;
    const currProviderData = providersData.find(
      (providerData) => providerData.spTenantId === currentProviderTenantId
    );
    if (currProviderData) return getProfileManagementObj(currProviderData);
    return getProfileManagementObj({ spTenantId: currentProviderTenantId });
  };

  const getUpdatedPartnerData = (updatedPartnerData, partnersData) => {
    let isProviderDataAvailable = false;
    const updatedPartnersData = partnersData.map((partnerData) => {
      if (partnerData.spTenantId === updatedPartnerData.spTenantId) {
        isProviderDataAvailable = true;
        return { ...updatedPartnerData };
      }
      return { ...partnerData };
    });

    if (!isProviderDataAvailable) updatedPartnersData.push(updatedPartnerData);

    return updatedPartnersData;
  };

  const getProfileManagementObj = (currentObj) => ({
    spTenantId: currentObj?.spTenantId || "",
    mission: currentObj?.mission || "",
    details: currentObj?.details || "",
  });

  const getAboutCompCurrPartnerRequirements = async (tenantId) => {
    const companyData = await getActiveRequirements(tenantId);
    const companyRquirements = companyData?.data?.requirement;
    if (companyRquirements)
      setAboutCompCurrPartnerRequirements(companyRquirements);
    else setAboutCompCurrPartnerRequirements(null);
  };

  return (
    <Box
      bg={"white"}
      rounded={"8px"}
      w={"100%"}
      h="calc(100vh - 216px)"
      overflow={"auto"}
      boxShadow={"1px 0px 4px #E5E7EB"}
      css={scrollbarCSS}
      p="16px 24px 8px"
    >
      {error || success || roadMapSuccess ? (
        <Notification
          title={error ? error : roadMapSuccess ? roadMapSuccess : success}
          status={error ? "error" : "success"}
          onCloseToast={() =>
            roadMapSuccess
              ? dispatch(clearRoadMapMessage())
              : dispatch(clearMessage())
          }
        />
      ) : (
        ""
      )}

      {passwordMessage ? (
        <Notification
          title={passwordMsg()}
          status={"warning"}
          time={5000}
          onCloseToast={() => {
            setPasswordMessage(false);
            localStorage.removeItem("isRedirect");
          }}
        />
      ) : (
        ""
      )}
      {loading ? (
        <Loader />
      ) : (
        <form onSubmit={handleSubmit(submitForm, handleError)} noValidate>
          <Flex
            borderBottom={"2px solid var(--chakra-colors-brandGray-101)"}
            pb="16px"
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text
              color={"var(--title-black-text)"}
              fontSize={"14px"}
              lineHeight={"20px"}
              fontWeight={700}
            >
              Basic Information
            </Text>
            <TipsLinkButton to={TIPS_LINK_PAGE_COMPANY_PROFILE} />
          </Flex>
          <Flex pt="24px">
            <Flex w={"70%"} direction={"column"}>
              <HStack columnGap={"16px"}>
                <TextBox
                  type="text"
                  placeholder="Company Name"
                  name="Company Name"
                  maxLength="100"
                  required
                  innerref={register("companyName", {
                    required: requiredField.replace("$Field$", "Company Name"),
                    pattern: {
                      value: whiteSpacePattern,
                      message: whiteSpace,
                    },
                  })}
                  validationErrors={errors}
                />
                <TextBox
                  type="text"
                  placeholder="www.yourcompany.com"
                  name="Company Website"
                  maxLength="256"
                  innerref={register("website", {
                    pattern: {
                      value: urlValidationPattern,
                      message: invalidUrl,
                    },
                  })}
                  validationErrors={errors}
                  leftAddOn="https://"
                />
              </HStack>
              <HStack columnGap={"16px"}>
                <Box pb="16px" w="100%">
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field }) => (
                      <CustomPhoneInput
                        value={field.value}
                        onChange={(value, country, event, formattedValue) => {
                          setCountryData(country);
                          field.onChange(formattedValue);
                        }}
                        error={errors.phoneNumber?.message}
                        required
                        countryOrder={["us", "ca", "gb", "au", "nz", "sg"]}
                      />
                    )}
                    rules={{ required: "Phone number is required" }}
                  />
                </Box>
                <TextBox
                  type="number"
                  name="Founded In (Year)"
                  placeholder="YYYY"
                  showPlaceHolder={true}
                  innerref={register("foundedInYear", {
                    min: {
                      value: 1800,
                      message: minYearAllowed.replace("$Year$", 1800),
                    },
                    max: {
                      value: new Date().getFullYear(),
                      message: maxYearAllowed.replace(
                        "$Year$",
                        new Date().getFullYear()
                      ),
                    },
                  })}
                  validationErrors={errors}
                />
              </HStack>
              <VStack columnGap={"16px"} h="100%">
                <Box w="100%">
                  <Flex justifyContent={"space-between"} mb="4px">
                    <Text
                      fontWeight={600}
                      color={"#5E6977"}
                      as="legend"
                      fontSize="12px"
                    >
                      About Company{" "}
                      <Text color="red.500" display="inline-block">
                        *
                      </Text>
                    </Text>

                    {aboutCompCurrPartnerRequirements?.recommendation && (
                      <Tooltip
                        label={aboutCompCurrPartnerRequirements?.recommendation}
                        placement="left"
                      >
                        <Text
                          color="cyprus.500"
                          fontWeight={500}
                          fontSize={"12px"}
                          lineHeight={"18px"}
                        >
                          Recommendations
                        </Text>
                      </Tooltip>
                    )}
                  </Flex>
                  <Tabs
                    variant="enclosed"
                    w="100%"
                    index={aboutCompanyTabIndex}
                    onChange={setAboutCompanyTabIndex}
                  >
                    <TabList
                      mb="0px"
                      overflow="auto"
                      css={scrollbarCSS}
                      borderBottom={"0px"}
                    >
                      <Tooltip label={"Coachbar"} placement="top">
                        <Tab
                          marginBottom={0}
                          borderBottom={
                            aboutCompanyTabIndex === 0
                              ? "0.8px solid transparent"
                              : "0.8px solid #E6E7E9"
                          }
                          borderBottomColor={
                            aboutCompanyTabIndex === 0
                              ? "transparent !important"
                              : "#E6E7E9"
                          }
                        >
                          <Image
                            src={CoachBarLogoIcon}
                            style={{ height: "22px", minWidth: "22px" }}
                            maxHeight="40px"
                            objectFit={"contain"}
                          />
                        </Tab>
                      </Tooltip>
                      {partnerProviderList.map((company, i) => (
                        <Tooltip
                          key={company.referenceTenantId + i}
                          label={company.spCompanyName}
                          placement="top"
                        >
                          <Tab
                            marginBottom={0}
                            borderBottom={
                              i === aboutCompanyTabIndex - 1
                                ? "0.8px solid transparent"
                                : "0.8px solid #E6E7E9"
                            }
                            borderBottomColor={
                              i === aboutCompanyTabIndex - 1
                                ? "transparent !important"
                                : "#E6E7E9"
                            }
                          >
                            <Avatar
                              size="xs"
                              name={company.spCompanyName}
                              src={company.logo}
                            />
                          </Tab>
                        </Tooltip>
                      ))}
                      <Spacer borderBottom="0.8px solid #E6E7E9" />
                    </TabList>
                  </Tabs>
                  <Box pos="relative">
                    <TextEditor
                      placeholder="Share your company profile or about us"
                      maxLength={ABOUT_MAX_LENGTH}
                      required
                      characterCount={aboutCompanyCount}
                      content={editorContentCompany}
                      handleEditorChange={handleCompanyAboutChange}
                      innerref={register("companyDetails", {
                        required: requiredField.replace(
                          "$Field$",
                          "About Company"
                        ),
                      })}
                      validationErrors={errors}
                      hideLabel
                      editorClassName="custom-quill-editor"
                      errorMsgClassName="position-absolute"
                    ></TextEditor>
                    {aboutCompCurrPartnerRequirements?.url && (
                      <Tooltip
                        label={appendHttpsToUrl(
                          aboutCompCurrPartnerRequirements?.url
                        )}
                        placement="left"
                      >
                        <Text
                          color={"cyprus.500"}
                          fontWeight={500}
                          fontSize={"12px"}
                          lineHeight={"18px"}
                          pos="absolute"
                          right="16px"
                          bottom="13px"
                        >
                          <Link
                            to={appendHttpsToUrl(
                              aboutCompCurrPartnerRequirements?.url
                            )}
                            textDecoration="none"
                            target="_blank"
                          >
                            Guidelines
                          </Link>
                        </Text>
                      </Tooltip>
                    )}
                  </Box>
                  {userInfo.userType !== 3 && (
                    <Box display={"flex"} mt="12px">
                      {keepForAllAboutCompany ? (
                        <Flex
                          gap={"4px"}
                          fill="none"
                          stroke={"palmGreen.600"}
                          alignItems={"center"}
                        >
                          <Check />
                          <Text
                            fontSize="14px"
                            fontWeight={600}
                            color={"palmGreen.600"}
                          >
                            Description applied to all profiles
                          </Text>
                        </Flex>
                      ) : (
                        <Flex
                          gap={"4px"}
                          fill="none"
                          stroke={"cyprus.500"}
                          alignItems={"center"}
                          cursor={"pointer"}
                          onClick={() =>
                            setValue("keepForAllAboutCompany", true)
                          }
                        >
                          <Copy03 />
                          <Text
                            fontSize="14px"
                            fontWeight={600}
                            color={"cyprus.500"}
                          >
                            Apply description to all profiles
                          </Text>
                        </Flex>
                      )}
                    </Box>
                  )}
                </Box>
                <Box w="100%">
                  <Text
                    fontWeight={600}
                    color={"#5E6977"}
                    as="legend"
                    mb="4px"
                    fontSize="12px"
                  >
                    Mission & Vision
                  </Text>
                  <Tabs
                    variant="enclosed"
                    w="100%"
                    index={valueAndMissionTabIndex}
                    onChange={setValueAndMissionTabIndex}
                  >
                    <TabList
                      mb="0px"
                      overflow="auto"
                      css={scrollbarCSS}
                      borderBottom={"0px"}
                    >
                      <Tooltip label={"Coachbar"} placement="top">
                        <Tab
                          marginBottom={0}
                          borderBottom={
                            valueAndMissionTabIndex === 0
                              ? "0.8px solid transparent"
                              : "0.8px solid #E6E7E9"
                          }
                          borderBottomColor={
                            valueAndMissionTabIndex === 0
                              ? "transparent !important"
                              : "#E6E7E9"
                          }
                        >
                          <Image
                            src={CoachBarLogoIcon}
                            style={{ height: "22px", minWidth: "22px" }}
                            maxHeight="40px"
                            objectFit={"contain"}
                          />
                        </Tab>
                      </Tooltip>
                      {partnerProviderList.map((company, i) => (
                        <Tooltip
                          key={company.referenceTenantId + i}
                          label={company.spCompanyName}
                          placement="top"
                        >
                          <Tab
                            marginBottom={0}
                            borderBottom={
                              i === valueAndMissionTabIndex - 1
                                ? "0.8px solid #fff"
                                : "0.8px solid #E6E7E9"
                            }
                            borderBottomColor={
                              i === valueAndMissionTabIndex - 1
                                ? "transparent !important"
                                : "#E6E7E9"
                            }
                          >
                            <Avatar
                              size="xs"
                              name={company.spCompanyName}
                              src={company.logo}
                            />
                          </Tab>
                        </Tooltip>
                      ))}
                      <Spacer borderBottom="0.8px solid #E6E7E9" />
                    </TabList>
                  </Tabs>
                  <TextEditor
                    placeholder="Your company's mission and vision"
                    maxLength={VALUE_MISSION_MAX_LENGTH}
                    required
                    characterCount={valueAndMissionCount}
                    content={editorContentValueAndMission}
                    handleEditorChange={handleCompanyValueAndMission}
                    innerref={register("valueAndMission")}
                    hideLabel
                    editorClassName="custom-quill-editor"
                  ></TextEditor>
                  {userInfo.userType !== 3 && (
                    <Box display={"flex"} mt="12px">
                      {keepForAllValueAndMission ? (
                        <Flex
                          gap={"4px"}
                          fill="none"
                          stroke={"palmGreen.600"}
                          alignItems={"center"}
                        >
                          <Check />
                          <Text
                            fontSize="14px"
                            fontWeight={600}
                            color={"palmGreen.600"}
                          >
                            Description applied to all profiles
                          </Text>
                        </Flex>
                      ) : (
                        <Flex
                          gap={"4px"}
                          fill="none"
                          stroke={"cyprus.500"}
                          alignItems={"center"}
                          cursor={"pointer"}
                          onClick={() =>
                            setValue("keepForAllValueAndMission", true)
                          }
                        >
                          <Copy03 />
                          <Text
                            fontSize="14px"
                            fontWeight={600}
                            color={"cyprus.500"}
                          >
                            Apply description to all profiles
                          </Text>
                        </Flex>
                      )}
                    </Box>
                  )}
                </Box>
              </VStack>
              <HStack columnGap={"16px"} mt={"0.5rem"}>
                <TextBox
                  name="LinkedIn Profile Link"
                  placeholder="www.linkedin.com/yourprofile"
                  maxLength="256"
                  innerref={register("linkedInProfile", {
                    pattern: {
                      value: urlValidationPattern,
                      message: invalidUrl,
                    },
                  })}
                  leftAddOn="https://"
                  validationErrors={errors}
                />
                <TextBox
                  name="X (Formerly Twitter) Profile Link"
                  placeholder="www.twitter.com/yourprofile"
                  maxLength="256"
                  innerref={register("twitterProfile", {
                    pattern: {
                      value: urlValidationPattern,
                      message: invalidUrl,
                    },
                  })}
                  leftAddOn="https://"
                  validationErrors={errors}
                />
              </HStack>
            </Flex>
            <Center p="0px 24px 16px">
              <Divider
                border={"1px dashed #E6E7E9"}
                height="100%"
                orientation="vertical"
              />
            </Center>
            <Flex
              alignItems={"flex-start"}
              gap={"16px"}
              direction={"column"}
              w={"30%"}
            >
              <Box w={"100%"}>
                <FileUpload
                  name="Logo"
                  boxWidth="100%"
                  ratio={30 / 9}
                  acceptedFileTypes="image/*"
                  required
                  innerref={register(LOGO_LABEL, {
                    validate: validateLogo,
                  })}
                  validationErrors={errors}
                  isDisabled={!permissions.edit}
                  isLoading={isLogoLoading}
                  imgSrc={imagePath[LOGO_LABEL]}
                  allowDelete
                  onDeleteClick={onLogoDeleteClick}
                  note={
                    "SVG, PNG, JPG/JPEG files are supported & Max 200KB file size allowed."
                  }
                />
              </Box>
              {/* <Box w={"100%"}>
                <FileUpload
                  name="Company Web Banner"
                  boxWidth="100%"
                  ratio={30 / 9}
                  acceptedFileTypes="image/*"
                  innerref={register(BANNER_LABEL, {
                    validate: validateBanner,
                  })}
                  validationErrors={errors}
                  isLoading={isBannerLoading}
                  imgSrc={imagePath[BANNER_LABEL]}
                  allowDelete={true}
                  isDisabled={!permissions.edit}
                  onDeleteClick={onBannerDeleteClick}
                />
              </Box> */}
            </Flex>
          </Flex>
        </form>
      )}
    </Box>
  );
};

export default CompanyProfile;
