import { useRef } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Image,
} from "@chakra-ui/react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import ColumnMoveSvg from "../../images/column-move.svg";
import { scrollbarCSS, tableCss } from "../../constants/constantValues";

const CustomTable = (props) => {
  const DndRowTable = () => {
    const DraggableRow = ({ index, items }) => {
      const dropRef = useRef(null);
      const dragRef = useRef(null);
      const [, dropb] = useDrop({
        accept: "ROW",
        drop: (item, x, b) => {
          // Update rows state to reflect the new order
          if (item?.index !== index) {
            props.onDrop(item.index, item.items[0].props["data-rowId"], index);
          }
        },
      });

      const [{ isDragging }, dragb, preview] = useDrag({
        type: "ROW",
        item: { index, items },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      });

      preview(dropb(dropRef));
      dragb(dragRef);

      return (
        <Tr
          ref={dropRef}
          style={{ opacity: isDragging ? 0.5 : 1 }}
          key={"tBody-" + index}
          className="table-row"
          _hover={{ ".show-on-hover": { visibility: "unset" } }}
          data-rowId={items[0].props["data-rowId"]}
        >
          <Td w="20px" pl="12px" pr="0px">
            <Image
              ref={dragRef}
              cursor={"pointer"}
              minH={"16px"}
              minW={"16px"}
              src={ColumnMoveSvg}
            />
          </Td>
          {items.map((item, itemIndex) => {
            return (
              <Td
                key={"tBody-Col-" + itemIndex}
                isNumeric={
                  props.keepLastColumnLeftAligned
                    ? false
                    : items.length === itemIndex + 1
                    ? true
                    : false
                }
              >
                {item ? item : "-"}
              </Td>
            );
          })}
        </Tr>
      );
    };
    return (
      <>
        <Table
          variant="simple"
          rounded={props.keepBordersRounded ? "8px" : ""}
          overflowY={"auto !important"}
          css={tableCss}
        >
          {!props.keepBordersRounded && (
            <TableCaption>{props.caption}</TableCaption>
          )}
          {props.tHead ? (
            <Thead style={{ ...props.headingStyles }}>
              {props.tHead.map((items, index) => (
                <Tr key={"tHead-" + index} className="table-head">
                  <Th></Th>
                  {items.map((item, itemIndex) => (
                    <Th
                      textTransform={"capitalize"}
                      key={"tHead-Col-" + itemIndex}
                      isNumeric={
                        props.keepLastColumnLeftAligned
                          ? false
                          : items.length === itemIndex + 1
                          ? true
                          : false
                      }
                      style={props?.headerTextStyle || {}}
                    >
                      {item}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
          ) : null}

          {props.tBody ? (
            <Tbody>
              {props.tBody.map((items, index) => (
                <DraggableRow items={items} index={index} />
              ))}
            </Tbody>
          ) : null}

          {props.tFoot ? (
            <Tfoot>
              {props.tFoot.map((items, index) => (
                <Tr key={"tFoot-" + index}>
                  {items.map((item, itemIndex) => (
                    <Th
                      key={"tFoot-Col-" + itemIndex}
                      isNumeric={items.length === itemIndex + 1 ? true : false}
                    >
                      {item}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Tfoot>
          ) : null}
        </Table>
      </>
    );
  };

  return (
    <TableContainer
      border={props.keepBordersRounded ? "1px solid #E2E8F0" : ""}
      borderTopRadius={props.keepBordersRounded ? "8px" : ""}
      overflowY={"auto !important"}
      className="custom-table"
      css={{ ...scrollbarCSS, ...(props.customTableCss || {}) }}
    >
      {props.DraggableRow ? (
        <DndProvider backend={HTML5Backend}>
          <DndRowTable />
        </DndProvider>
      ) : (
        <Table
          variant="simple"
          rounded={props.keepBordersRounded ? "8px" : ""}
          overflowY={"auto !important"}
          css={tableCss}
        >
          {!props.keepBordersRounded && (
            <TableCaption>{props.caption}</TableCaption>
          )}
          {props.tHead ? (
            <Thead style={{ ...props.headingStyles }}>
              {props.tHead.map((items, index) => (
                <Tr key={"tHead-" + index} className="table-head">
                  {items.map((item, itemIndex) => (
                    <Th
                      borderRight={
                        items.length !== itemIndex + 1 &&
                        props?.showTableBorders &&
                        "1px solid #E6E7E9"
                      }
                      textTransform={"capitalize"}
                      key={"tHead-Col-" + itemIndex}
                      isNumeric={
                        props.keepLastColumnLeftAligned
                          ? false
                          : items.length === itemIndex + 1
                          ? true
                          : false
                      }
                      style={props?.headerTextStyle || {}}
                    >
                      {item}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
          ) : null}

          {props.tBody ? (
            <Tbody>
              {props.tBody.map((items, index) => (
                <Tr
                  key={"tBody-" + index}
                  className="table-row"
                  _hover={{ ".show-on-hover": { visibility: "unset" } }}
                  role={props.isHovered ? "group" : ""}
                >
                  {items.map((item, itemIndex) => (
                    <Td
                      borderRight={
                        items.length !== itemIndex + 1 &&
                        props?.showTableBorders &&
                        "1px solid #E6E7E9"
                      }
                      key={"tBody-Col-" + itemIndex}
                      isNumeric={
                        props.keepLastColumnLeftAligned
                          ? false
                          : items.length === itemIndex + 1
                          ? true
                          : false
                      }
                      style={{
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-word",
                        ...props?.bodyTextStyle,
                      }}
                    >
                      {item ? item : "-"}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          ) : null}

          {props.tFoot ? (
            <Tfoot>
              {props.tFoot.map((items, index) => (
                <Tr key={"tFoot-" + index}>
                  {items.map((item, itemIndex) => (
                    <Th
                      key={"tFoot-Col-" + itemIndex}
                      isNumeric={items.length === itemIndex + 1 ? true : false}
                    >
                      {item}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Tfoot>
          ) : null}
        </Table>
      )}
    </TableContainer>
  );
};

export default CustomTable;
