import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Tag,
  Tooltip,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Link } from "@coachbar/shared-components";
import { getUserInfo } from "../services/utility";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { planRoute } from "../constants/redirectionRoutes";
import {
  getCompanyProfile,
  profilePublishRequest,
} from "../features/profileSetup/companyProfile/companyProfileActions";
import { useState } from "react";
import ProfileSetup from "../features/profileSetup/ProfileSetup";
import { useRBAC } from "../services/customHook";
import { publishProfileAccess } from "../constants/moduleNames";

const itemStyle = {
  color: "#7E8792",
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 600,
};

const activeItemStyle = {
  ...itemStyle,
  color: "var(--title-black-text)",
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 700,
};

const BreadCrumb = ({ routes }) => {
  const { hasActionPermission } = useRBAC();
  let moduleName = publishProfileAccess;
  let permissions = {
    add: hasActionPermission(moduleName, "add"),
    edit: hasActionPermission(moduleName, "edit"),
    view: hasActionPermission(moduleName, "view"),
    delete: hasActionPermission(moduleName, "delete"),
  };
  const { companyProfile } = useSelector((state) => state.companyProfile);
  let location = useLocation();
  let activePaths = location.pathname.split("/").slice(1);
  let userInfo = getUserInfo();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const publishProfile = () => {
    if (userInfo?.planId && userInfo?.planId === 1 && !userInfo?.isGuestUser) {
      navigate(planRoute);
    } else {
      setLoading(true);
      profilePublishRequest({ profileStatus: "Awaiting" }).then((response) => {
        if (response.success) {
          dispatch(getCompanyProfile());
        }
        setLoading(false);
      });
    }
  };

  let spCompanyName = location?.state?.selectedCommission?.spCompanyName;

  return (
    <Flex px="20px" justifyContent="space-between">
      <Breadcrumb display="inherit">
        {getActiveRoutes(
          routes,
          activePaths,
          location?.pathname,
          spCompanyName
        )}
      </Breadcrumb>
    </Flex>
  );
};

const getActiveRoutes = (routes, activePaths, pathname = "", spCompanyName) => {
  let path = "/";
  let itemList = [];
  const currentRouteData = routes.find((route) => route.path === pathname);

  if (currentRouteData?.hideBreadcrumb) return null;

  for (let pathIndex = 0; pathIndex < activePaths.length; pathIndex++) {
    path = path + activePaths[pathIndex] + "/";
    for (let routeIndex = 0; routeIndex < routes.length; routeIndex++) {
      if (
        routes[routeIndex]["path"] &&
        routes[routeIndex]["path"] === path.slice(0, -1)
      ) {
        let isCurrentPage = activePaths.length === pathIndex + 1 ? true : false;
        itemList.push(getBreadCrumbItem(routes, routeIndex, isCurrentPage));
        break;
      } else if (routes[routeIndex]["parentRoute"] && pathIndex > 0) {
        itemList.push(
          getActiveRoutes(routes[routeIndex]["subComponents"], [
            path.slice(1, -1),
          ]),
          spCompanyName
        );
        break;
      } else if (
        routes[routeIndex]["path"] &&
        routes[routeIndex]["path"].indexOf("/:") !== -1
      ) {
        let activePathStr = path.split("/").slice(0, -2).join();
        let routeStr = routes[routeIndex]["path"]
          .split("/")
          .slice(0, -1)
          .join();
        let isCurrentPage = true;
        if (activePathStr === routeStr) {
          itemList.push(
            getBreadCrumbItem(routes, routeIndex, isCurrentPage, spCompanyName)
          );
          break;
        }
      }
    }
  }

  return itemList;
};

const getBreadCrumbItem = (
  routes,
  routeIndex,
  isCurrentPage,
  spCompanyName
) => {
  return (
    <BreadcrumbItem
      key={routes[routeIndex]["path"] + routeIndex}
      isCurrentPage={isCurrentPage}
    >
      {isCurrentPage ? (
        <BreadcrumbLink style={activeItemStyle}>
          {spCompanyName ?? routes[routeIndex]["name"]}
        </BreadcrumbLink>
      ) : (
        <BreadcrumbLink
          as={Link}
          to={
            routes[routeIndex].parentMenu
              ? routes[routeIndex]["parentPath"]
              : routes[routeIndex]["path"]
          }
          style={itemStyle}
        >
          {routes[routeIndex]["name"]}
        </BreadcrumbLink>
      )}
    </BreadcrumbItem>
  );
};

export default BreadCrumb;
