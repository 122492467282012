import {
  Box,
  Center,
  Flex,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Button, Loader, MoreFilterButton } from "@coachbar/shared-components";
import DashboardDateFilter from "@coachbar/shared-components/src/components/Charts/components/DashboardDateFilter";
import EmptyDashboard from "@coachbar/shared-components/src/components/Charts/components/EmptyDashboard";
import FiltersDrawer from "@coachbar/shared-components/src/components/Charts/components/FiltersDrawer";
import WidgetModal from "@coachbar/shared-components/src/components/Charts/components/WidgetModal";
import { insightDashboardIcon } from "@coachbar/shared-components/src/components/Icons/SvgsConstants";
import {
  customStylesForReactSelect,
  scrollbarCSS,
} from "@coachbar/shared-components/src/constants/constantValues";
import {
  defaultInsightWidgetsDataSc,
  partnerCardWidgets,
  slugConstants,
} from "@coachbar/shared-components/src/constants/dashboard";
import {
  ChartsProvider,
  useChartsProvider,
} from "@coachbar/shared-components/src/screens/dashboard/chartContext";
import {
  formatYAxisTicks,
  getVisibleChartsFromDb,
} from "@coachbar/shared-components/src/services/dashboardUtility/utility";
import {
  deepCopyObject,
  getCurrency,
} from "@coachbar/shared-components/src/services/utility";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { getGeneralSettings } from "../../generalSettings/generalSettingsActions";
import { getPartnerProviderList } from "../../myProviders/providersList/providersListActions";
import { getAllUsers } from "../../settings/usersAndRoles/usersAndRolesActions";
import {
  getDashboardsCharts,
  getLeadToWinData,
  getTotalCustomers,
  getTotalRevenueData,
  updateDashboardCharts,
} from "../dashboardActions";
import DealTypes from "../performance/charts/DealTypes";
import DealWinRate from "../performance/charts/DealWinRate";
import LeadConversionRate from "../performance/charts/LeadConversionRate";
import LeadTypes from "../performance/charts/LeadTypes";
import Leads from "../performance/charts/Leads";
import PeriodSummary from "./components/PeriodSummary";
import StatBox from "./components/StatBox";
import TrainingAndCertification from "./components/TrainingAndCertification";

const dashboardType = "Insights";

const InsightsComp = ({
  selectedPartner,
  setSelectedPartner,
  partnerOptions,
}) => {
  const {
    selectedDate,
    setSelectedDate,
    chartList,
    setChartList,
    filters,
    onFiltersSubmit,
    userInfo,
    partnerId,
    dashboardData,
    setDashboardData,
    platformJson,
  } = useChartsProvider();

  const {
    onOpen: onWidgetModalOpen,
    onClose: onWidgetModalClose,
    isOpen: isWidgetModalOpen,
  } = useDisclosure();

  const {
    onOpen: onFilterDrawerOpen,
    onClose: onFilterDrawerClose,
    isOpen: isFilterDrawerOpen,
  } = useDisclosure();

  const [totCust, setTotCust] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [leadToWin, setLeadToWin] = useState(0);
  const [totRevLoad, setTotRevLoad] = useState(false);
  const [leadWinLoad, setLeadWinLoad] = useState(false);
  const [totCustLoad, setTotCustLoad] = useState(false);
  const currencySymbol = getCurrency()?.symbol;

  let initialize = false;
  useEffect(() => {
    if (!initialize) {
      initialize = true;
      fetchRevenue();
      fetchLeadToWin();
      fetchTotalCustomer();
    }
  }, [partnerId]);

  const fetchRevenue = async () => {
    setTotRevLoad(true);
    let { data } = await getTotalRevenueData({
      partners: [partnerId],
      partnerManagers:
        userInfo?.role === "Manager" ? [userInfo?.id] : undefined,
    });
    setTotRevLoad(false);
    setTotalRevenue(data?.dashboard || 0);
  };

  const fetchLeadToWin = async () => {
    setLeadWinLoad(true);
    let { data } = await getLeadToWinData({
      partners: [partnerId],
      partnerManagers:
        userInfo?.role === "Manager" ? [userInfo?.id] : undefined,
    });
    setLeadWinLoad(false);
    setLeadToWin(data?.dashboard?.leadToWinAverageTime || 0);
  };

  const fetchTotalCustomer = async () => {
    setTotCustLoad(true);
    let { data } = await getTotalCustomers({
      partners: [partnerId],
      partnerManagers:
        userInfo?.role === "Manager" ? [userInfo?.id] : undefined,
    });
    setTotCustLoad(false);
    setTotCust(data?.dashboard?.customerCount || 0);
  };

  const onDateChange = (date) => {
    if (JSON.stringify(date) !== JSON.stringify(selectedDate)) {
      // setLoading(true);
      setSelectedDate(date);
    }
  };

  const onChartsUpdate = async (updateCharts, onFail) => {
    let requestData = {
      id: dashboardData?.id,
      dashboardType: dashboardType,
      widgetList: Object.keys(updateCharts)
        .filter((chartKey) => updateCharts[chartKey].isVisible)
        .map((x) => x),
    };
    let { data } = await updateDashboardCharts(requestData);
    if (data?.code === 200) {
      setDashboardData(data?.data?.dashboard);
      setChartList(updateCharts);
      onWidgetModalClose();
    } else {
      onFail();
    }
  };

  let filtersCount = useMemo(() => {
    let count = 0;
    Object.keys(filters).map((x) => {
      if (filters[x].find((x) => x.isChecked)) {
        count++;
      }
    });
    return count;
  }, [filters]);

  let isAllChartsRemoved = useMemo(() => {
    return !Object.values(chartList).some((x) => x.isVisible);
  }, [chartList]);

  if (isAllChartsRemoved) {
    return (
      <>
        <EmptyDashboard onWidgetModalOpen={onWidgetModalOpen} />;
        <WidgetModal
          chartList={chartList}
          setChartList={onChartsUpdate}
          isOpen={isWidgetModalOpen}
          onClose={onWidgetModalClose}
        />
      </>
    );
  }

  return (
    <Box p={"24px"} h={"calc(100vh - 64px)"} bg={"#F9FAFB"}>
      {userInfo?.role !== "User" && (
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          mb={"20px"}
        >
          <Flex
            alignItems={"center"}
            gap={"20px"}
            justifyContent={"start"}
            w={"100%"}
          >
            <Stack pos={"relative"}>
              <DashboardDateFilter
                bg="#FFF"
                color={"#111A29"}
                handleDateChange={onDateChange}
                selectedDateRange={selectedDate}
                showLastMonthData
                customPopoverTop="50px"
                customPopoverRight="-470px"
              />
            </Stack>
            <Box w={"100%"} maxW={"165px"}>
              <Select
                options={partnerOptions}
                value={selectedPartner}
                onChange={setSelectedPartner}
                styles={{
                  ...customStylesForReactSelect,
                  indicatorSeparator: (provided) => ({
                    ...provided,
                    display: "none",
                  }),
                }}
                placeholder="Select Provider"
                isSearchable={true}
                menuPlacement="auto"
              />
            </Box>
            <MoreFilterButton
              appliedMoreFilterCount={filtersCount}
              setShowMoreFilters={() =>
                isFilterDrawerOpen
                  ? onFilterDrawerClose()
                  : onFilterDrawerOpen()
              }
              showMoreFilters={isFilterDrawerOpen}
            />
          </Flex>
          <Button
            variant="newColorThemePrimary"
            onClick={onWidgetModalOpen}
            title="Add Widget"
          />
        </Flex>
      )}

      <Box maxH={"calc(100vh - 174px)"} overflow={"auto"} css={scrollbarCSS}>
        <SimpleGrid columns={3} gap={"20px"} mb={"20px"}>
          {userInfo.role !== "User" && (
            <StatBox
              title="Total Revenue"
              isLoading={totRevLoad}
              value={formatYAxisTicks(totalRevenue)}
              valuePrefix={currencySymbol}
            />
          )}
          <StatBox
            title="Total Customer(s)"
            isLoading={totCustLoad}
            value={formatYAxisTicks(totCust)}
          />
          <StatBox
            title="Avg Lead-to-Win Time"
            isLoading={leadWinLoad}
            value={leadToWin}
            valueSuffix=" days"
          />
        </SimpleGrid>

        {chartList[slugConstants.trainingAndCourses]?.isVisible && (
          <TrainingAndCertification />
        )}

        {chartList[slugConstants.periodSummary]?.isVisible && (
          <PeriodSummary chartData={chartList[slugConstants.periodSummary]} />
        )}

        {(chartList[slugConstants.leads]?.isVisible ||
          chartList[slugConstants.leadTypes]?.isVisible ||
          chartList[slugConstants.dealTypes]?.isVisible) && (
          <SimpleGrid columns={3} gap={"20px"} mb={"20px"}>
            {chartList[slugConstants.leads]?.isVisible && (
              <Leads chartData={chartList[slugConstants.leads]} />
            )}
            {chartList[slugConstants.leadTypes]?.isVisible && (
              <LeadTypes chartData={chartList[slugConstants.leadTypes]} />
            )}
            {chartList[slugConstants.dealTypes]?.isVisible && (
              <DealTypes chartData={chartList[slugConstants.dealTypes]} />
            )}
          </SimpleGrid>
        )}

        {(chartList[slugConstants.leadConversionRate]?.isVisible ||
          chartList[slugConstants.dealsWonRate]?.isVisible) && (
          <SimpleGrid columns={2} gap={"20px"}>
            {chartList[slugConstants.leadConversionRate]?.isVisible && (
              <LeadConversionRate
                chartData={chartList[slugConstants.leadConversionRate]}
              />
            )}
            {chartList[slugConstants.dealsWonRate]?.isVisible && (
              <DealWinRate chartData={chartList[slugConstants.dealsWonRate]} />
            )}
          </SimpleGrid>
        )}
      </Box>
      <WidgetModal
        chartList={chartList}
        setChartList={onChartsUpdate}
        isOpen={isWidgetModalOpen}
        onClose={onWidgetModalClose}
      />
      <FiltersDrawer
        isOpen={isFilterDrawerOpen}
        isProvider={true}
        onModalClose={onFilterDrawerClose}
        filters={filters}
        platformJson={platformJson}
        partnerId={partnerId}
        setFilterValues={(newFilters) => {
          onFilterDrawerClose();
          if (JSON.stringify(filters) !== JSON.stringify(newFilters)) {
            // setLoading(true);
            onFiltersSubmit(newFilters);
          }
        }}
      />
    </Box>
  );
};

const Insights = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const chartsObj = {
    ...partnerCardWidgets,
    ...defaultInsightWidgetsDataSc,
  };

  let initialize = false;
  const [fetchedAllData, setFetchedAllData] = useState(false);
  const [partnerManagers, setPartnerManagers] = useState([]);
  const [partnerTypes, setPartnerTypes] = useState([]);
  const [partners, setPartners] = useState([]);
  const [dashData, setDashData] = useState({});
  const [charts, setCharts] = useState(deepCopyObject(chartsObj));
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState(undefined);

  useEffect(() => {
    if (!initialize) {
      initialize = true;
      fetchAllData();
    }
  }, []);

  const fetchAllData = async () => {
    const partnerCategoryList = await getGeneralSettings("scCategory");
    if (partnerCategoryList?.data?.spPartnerCategoryList) {
      setPartnerTypes(partnerCategoryList?.data?.spPartnerCategoryList);
    }
    const users = await getAllUsers();
    if (users) {
      setPartnerManagers(users);
    }
    const partnersListRes = await getPartnerProviderList();
    setPartners(partnersListRes.partnerList);
    setPartnerOptions(() => {
      return partnersListRes.partnerList?.map((partner) => {
        return {
          label: partner?.spCompanyName,
          value: partner?.referenceTenantId,
        };
      });
    });
    const dashData = await getDashboardsCharts(dashboardType);
    if (dashData?.data) {
      setDashData(deepCopyObject(dashData?.data));
      setCharts(getVisibleChartsFromDb(dashData?.data?.widgetList, charts));
    }
    setFetchedAllData(true);
  };

  if (!fetchedAllData) {
    return (
      <Center minH={"calc(100vh - 64px)"}>
        <Loader />
      </Center>
    );
  }

  return (
    <>
      {!selectedPartner ? (
        <Center minH={"calc(100vh - 64px)"}>
          <Flex direction={"column"} alignItems={"center"} gap={"24px"}>
            <Box boxSize={"42px"}>{insightDashboardIcon}</Box>
            <Flex direction={"column"} alignItems={"center"} gap={"4px"}>
              <Text color={"#111A29"} fontSize={"16px"} fontWeight={700}>
                Insights Dashboard
              </Text>
              <Text color={"#7E8792"} fontSize={"14px"} fontWeight={500}>
                To view insights, you need to select the provider from below
              </Text>
            </Flex>
            <Flex w={"100%"} maxW={"300px"} direction={"column"}>
              <Text color={"#5E6977"} fontSize={"12px"} fontWeight={500}>
                Software Provider
                <span style={{ color: "#E02424", textAlign: "start" }}>*</span>
              </Text>
              <Select
                options={partnerOptions}
                value={selectedPartner}
                onChange={setSelectedPartner}
                styles={{
                  ...customStylesForReactSelect,
                  indicatorSeparator: (provided) => ({
                    ...provided,
                    display: "none",
                  }),
                }}
                placeholder="Select Provider"
                isSearchable={true}
                menuPlacement="auto"
              />
            </Flex>
          </Flex>
        </Center>
      ) : (
        <ChartsProvider
          charts={charts}
          partners={partners}
          partnerManagers={partnerManagers}
          partnerTypes={partnerTypes}
          userInfo={userInfo}
          dashboardData={dashData}
          platform="sc"
          updateDashboardApi={updateDashboardCharts}
          dashboardType={dashboardType}
          partnerId={selectedPartner?.value}
        >
          <InsightsComp
            selectedPartner={selectedPartner}
            setSelectedPartner={setSelectedPartner}
            partnerOptions={partnerOptions}
          />
        </ChartsProvider>
      )}
    </>
  );
};

export default Insights;
