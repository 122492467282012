// urlDirectorySlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  addUrlDirectory,
  updateUrlDirectory,
  getAllUrlDirectory,
  deleteUrlDirectory,
} from "./urlDirectoryActions";

const initialState = {
  loading: false,
  error: null,
  success: null,
  companLocation: null,
  urlDirectoryList: [],
};

const urlDirectorySlice = createSlice({
  name: "urlDirectory",
  initialState,
  reducers: {
    addUrlDirectoryToList: (state, action) => {
      state.urlDirectoryList = state.urlDirectoryList.concat(action.payload);
    },
    removeUrlDirectoryFromList: (state, action) => {
      let locationId = action.payload;
      state.urlDirectoryList = state.urlDirectoryList.filter(
        (location) => location.id !== locationId
      );
    },
    updateUrlDirectoryFromList: (state, action) => {
      let locationId = action.payload.id;
      state.urlDirectoryList = state.urlDirectoryList.map((location) => {
        return location.id === locationId
          ? { ...location, ...action.payload }
          : location;
      });
    },
    clearMessage: (state) => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: {
    // add url directory
    [addUrlDirectory.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addUrlDirectory.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
      if (payload.data && payload.success) {
        // state.companyLocation = payload.data.profile;
      }
    },
    [addUrlDirectory.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // get all url directory
    [getAllUrlDirectory.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllUrlDirectory.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
      if (payload.data && payload.success) {
        state.urlDirectoryList = payload.data?.urlDirectoryList || [];
      }
    },
    [getAllUrlDirectory.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // update url directory
    [updateUrlDirectory.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateUrlDirectory.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [updateUrlDirectory.rejected]: (state, { payload }) => {
      state.loading = false;
      state.success = null;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // delete url directory
    [deleteUrlDirectory.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteUrlDirectory.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [deleteUrlDirectory.rejected]: (state, { payload }) => {
      state.loading = false;
      state.success = null;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
  },
});

export const {
  addUrlDirectoryToList,
  removeUrlDirectoryFromList,
  updateUrlDirectoryFromList,
  clearMessage,
} = urlDirectorySlice.actions;

export default urlDirectorySlice.reducer;
