// referralSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  updateReferral,
  getReferralDetails,
  addReferral,
  deleteReferral,
} from "./referralActions";

const initialState = {
  loading: false,
  error: null,
  success: null,
  errorDelete: null,
  successDelete: null,
  addError: null,
  addSuccess: null,
  referralList: {},
  referralDetails: null,
};

const referralSlice = createSlice({
  name: "referral",
  initialState,
  reducers: {
    clearMessage: (state) => {
      state.success = null;
      state.error = null;
      state.addError = null;
      state.addSuccess = null;
      state.errorDelete = null;
      state.successDelete = null;
    },
    setError: (state, action) => {
      let errorMsg = action.payload;
      state.error = errorMsg;
    },
    setSuccess: (state, action) => {
      let successMsg = action.payload;
      state.success = successMsg;
    },
  },
  extraReducers: {
    // get referral details
    [getReferralDetails.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getReferralDetails.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
      if (payload.data && payload.success) {
        state.referralDetails = payload.data?.referralDetail || null;
      }
    },
    [getReferralDetails.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // update referral
    [updateReferral.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateReferral.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [updateReferral.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // delete referral
    [deleteReferral.pending]: (state) => {
      state.loading = true;
      state.errorDelete = null;
    },
    [deleteReferral.fulfilled]: (state, { payload }) => {
      state.successDelete = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [deleteReferral.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.errorDelete = payload.error;
      } else {
        state.errorDelete = payload;
      }
    },
    // add referral
    [addReferral.pending]: (state) => {
      state.loading = true;
      state.addError = null;
    },
    [addReferral.fulfilled]: (state, { payload }) => {
      state.addSuccess = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [addReferral.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.addError = payload.error;
      } else {
        state.addError = payload;
      }
    },
  },
});

export const { clearMessage, setError, setSuccess } = referralSlice.actions;

export default referralSlice.reducer;
