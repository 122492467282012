import React from "react";
import CompanyProfile from "./companyProfile/CompanyProfile";
import CompanyLocation from "./companyLocation/CompanyLocation";
import Videos from "./videos/Videos";
import Media from "./media/Media";
import UrlDirectory from "./urlDirectory/UrlDirectory";
import { useSelector } from "react-redux";
import { Stack, VStack, useSteps } from "@chakra-ui/react";
import ListOfAwards from "./listOfAwards/ListOfAwards";
import PartnerStatus from "./partnerStatus/PartnerStatus";
import Affiliations from "./affiliations/Affiliations";
import { useRBAC } from "../../services/customHook";
import { companyProfile, steps } from "../../constants/moduleNames";
import ProfileStepper from "@coachbar/shared-components/src/components/Stepper/ProfileStepper";
import ProfileFooter from "./ProfileFooter";

const getProfileSteps = (userType) => {
  if (userType === 3 || userType === 4) return steps;
  return steps.slice(0, -3);
};

const ProfileSetup = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const userProfileSteps = getProfileSteps(userInfo?.userType);
  const { activeStep, setActiveStep, isCompleteStep } = useSteps({
    index: 0,
    count: userProfileSteps?.length,
  });
  const { hasActionPermission } = useRBAC();
  let moduleName = companyProfile;
  let permissions = {
    add: hasActionPermission(moduleName, "add"),
    edit: hasActionPermission(moduleName, "edit"),
    view: hasActionPermission(moduleName, "view"),
    delete: hasActionPermission(moduleName, "delete"),
    assigned: hasActionPermission(moduleName, "assigned"),
  };

  return (
    <VStack w={"full"} h={"100%"}>
      <Stack px={"24px"} pb={4} bg={"#F9FAFB"} w={"full"}>
        <ProfileStepper
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          isCompleteStep={isCompleteStep}
          steps={userProfileSteps}
          containerPadding={
            userProfileSteps.length > 5 ? "16px 0 0 0" : "16px 0 8px 0"
          }
        />
        {activeStep === 0 && (
          <CompanyProfile
            permissions={permissions}
            setActiveStep={setActiveStep}
          />
        )}
        {activeStep === 1 && <CompanyLocation permissions={permissions} />}
        {activeStep === 2 && <Videos permissions={permissions} />}
        {activeStep === 3 && <Media permissions={permissions} />}
        {activeStep === 4 && <UrlDirectory permissions={permissions} />}
        {[3, 4].includes(userInfo?.userType) ? (
          <React.Fragment>
            {activeStep === 5 && <Affiliations permissions={permissions} />}
            {activeStep === 6 && <ListOfAwards permissions={permissions} />}
            {activeStep === 7 && <PartnerStatus permissions={permissions} />}
          </React.Fragment>
        ) : null}
      </Stack>
      <ProfileFooter
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        permissions={permissions}
        totalStep={userProfileSteps.length}
      />
    </VStack>
  );
};

export default ProfileSetup;
