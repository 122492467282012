import { extensionsToIcons } from "../../constants/assets";
import fileIcon from "../../images/file-icon.png";

// Utility function to get sharedWithUserList
export const getSharedWithUserList = (data, entityList) => {
  const sharedWithUserList = [];
  if (data && data?.length > 0) {
    data?.map((entityId) => {
      const sharedWithUser = entityList?.find(
        (list) => list?.referenceTenantId === entityId
      );
      sharedWithUserList.push(sharedWithUser);
    });
  }
  return sharedWithUserList;
};

export const getAssetIconByExtension = (fileExtension) => {
  let assetType;

  if (extensionsToIcons) {
    if (extensionsToIcons.IMAGE && extensionsToIcons.IMAGE[fileExtension]) {
      assetType = "IMAGE";
    } else if (
      extensionsToIcons.VIDEO &&
      extensionsToIcons.VIDEO[fileExtension]
    ) {
      assetType = "VIDEO";
    } else if (
      extensionsToIcons.PRESENTATION &&
      extensionsToIcons.PRESENTATION[fileExtension]
    ) {
      assetType = "PRESENTATION";
    } else if (
      extensionsToIcons.DOCUMENT &&
      extensionsToIcons.DOCUMENT[fileExtension]
    ) {
      assetType = "DOCUMENT";
    } else if (
      extensionsToIcons.DESIGN &&
      extensionsToIcons.DESIGN[fileExtension]
    ) {
      assetType = "DESIGN";
    }
  }

  if (assetType) {
    return extensionsToIcons[assetType][fileExtension];
  } else {
    return fileIcon;
  }
};

export const updateRequestData = (
  breadCrumbs,
  name,
  requestData,
  isFolder = true,
  isUploading = false
) => {
  if (breadCrumbs?.length > 1) {
    const updatedBreadcrumbs = [...breadCrumbs];
    updatedBreadcrumbs.shift();
    const assetPath = updatedBreadcrumbs.map((crumb) => crumb.id).join("/");
    const breadCrumbString = updatedBreadcrumbs
      .map((crumb) => crumb.name)
      .join("/");

    if (assetPath && breadCrumbString) {
      requestData.assetPath = `/${assetPath}`;
      requestData.breadCrumbs = `${assetPath && "/"}${breadCrumbString}${
        isFolder && !isUploading ? `/${name}` : ""
      }`;
    }

    const parentFolder = updatedBreadcrumbs.pop();
    if (parentFolder) {
      requestData.parentFolderId = parentFolder.id;
    }
  } else if (isFolder && !isUploading) {
    requestData.breadCrumbs = `/${name}`;
  }
};

export const getParentFolderId = (breadCrumbs) => {
  if (breadCrumbs?.length > 1) {
    const updatedBreadcrumbs = [...breadCrumbs];

    const parentFolder = updatedBreadcrumbs.pop();
    if (parentFolder) {
      return parentFolder.id;
    }
  }
  return null;
};

export const onUploadProgress = (progressEvent) => {
  const percentCompleted = Math.round(
    (progressEvent.loaded * 100) / progressEvent.total
  );
  return percentCompleted;
  // console.log(`${percentCompleted}% uploaded`);
};
