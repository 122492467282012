import { Box, SimpleGrid } from "@chakra-ui/react";
import ValuesChangeCard from "@coachbar/shared-components/src/components/Charts/ValuesChangeCard";
import ChartContainer from "@coachbar/shared-components/src/screens/dashboard/ChartContainer";
import ChartHeader from "@coachbar/shared-components/src/screens/dashboard/ChartHeader";
import { useChartsProvider } from "@coachbar/shared-components/src/screens/dashboard/chartContext";
import {
  calculateDaysBetweenDates,
  calculatePercentageDifference,
  compareInnerAndGeneralFilters,
  getAppliedFilters,
  getPreviousDateRange,
} from "@coachbar/shared-components/src/services/dashboardUtility/utility";
import {
  deepCopyObject,
  getCurrency,
} from "@coachbar/shared-components/src/services/utility";
import React, { useEffect, useMemo, useState } from "react";
import { getLeadToWinData } from "../../../dashboardActions";
import AvgLeadWinTime from "./AvgLeadWinTime";

const LeadWinTime = ({ chartData }) => {
  const { selectedDate, appliedFilters, fixFilters } = useChartsProvider();

  let currencySymbol = getCurrency().symbol;
  const [chartType, setChartType] = useState("");
  const [isCompared, setIsCompared] = useState(false);
  const [leadToWinData, setLeadToWinData] = useState({});
  const [winDataLoading, setWinDataLoading] = useState(false);
  const [innerFilters, setInnerFilters] = useState(deepCopyObject(fixFilters));
  const [innerDate, setInnerDate] = useState(deepCopyObject({}));

  let innerAppliedFilters = useMemo(() => {
    return getAppliedFilters(innerFilters);
  }, [innerFilters]);

  useEffect(() => {
    setIsCompared(false);
    fetchRevenueData();
  }, [innerDate, innerAppliedFilters, selectedDate, appliedFilters]);

  const fetchRevenueData = async () => {
    setWinDataLoading(true);
    let filtersPass = compareInnerAndGeneralFilters(
      appliedFilters,
      innerAppliedFilters,
      Object.keys(fixFilters),
      innerDate,
      selectedDate
    );
    let requestData = { ...filtersPass };
    let { data } = await getLeadToWinData(requestData);
    let resultData = data.dashboard;
    setWinDataLoading(false);
    setLeadToWinData(resultData);
  };

  let onCompareClick = async () => {
    if (isCompared) {
      let newData = deepCopyObject(leadToWinData);
      delete newData?.leadInNewAverageTimePercentChange;
      delete newData?.leadToWinAverageTimePercentChange;
      setLeadToWinData(deepCopyObject(newData));
      setIsCompared((prev) => !prev);
    } else {
      setWinDataLoading(true);
      setIsCompared((prev) => !prev);
      let filtersPass = compareInnerAndGeneralFilters(
        appliedFilters,
        innerAppliedFilters,
        Object.keys(fixFilters),
        innerDate,
        selectedDate
      );
      let requestData = { ...filtersPass };
      let conDate = deepCopyObject(
        innerDate?.to && innerDate?.from ? innerDate : selectedDate
      );
      let prevDateRange = getPreviousDateRange(conDate);
      requestData.fromDate = prevDateRange.from;
      requestData.toDate = prevDateRange.to;
      let compareData = await getLeadToWinData(requestData);
      let newData = deepCopyObject(leadToWinData);

      newData.leadInNewAverageTimePercentChange = calculatePercentageDifference(
        compareData?.data.dashboard?.leadInNewAverageTime,
        newData?.leadInNewAverageTime
      );
      newData.leadToWinAverageTimePercentChange = calculatePercentageDifference(
        compareData?.data.dashboard?.leadToWinAverageTime,
        newData?.leadToWinAverageTime
      );
      setWinDataLoading(false);
      setLeadToWinData(newData);
    }
  };

  let dayDiff = useMemo(() => {
    let conDate = deepCopyObject(
      innerDate?.to && innerDate?.from ? innerDate : selectedDate
    );
    return calculateDaysBetweenDates(conDate);
  }, [innerDate, selectedDate]);
  return (
    <Box>
      <ChartContainer>
        <ChartHeader
          title={chartData?.title}
          showAll
          chartData={chartData}
          filters={innerFilters}
          onSubmitFilters={(newFilters, date) => {
            setInnerFilters(newFilters);
            setInnerDate(date);
          }}
          date={innerDate}
          generalDate={selectedDate}
          chartType={chartType}
          onChartTypeChange={() => {
            if (chartType === "") {
              setChartType("table");
            } else {
              setChartType("");
            }
          }}
          onToogleCompare={onCompareClick}
          isCompared={isCompared}
        />
        <SimpleGrid
          className="top-tiles"
          columns={6}
          spacing={"20px"}
          mb="20px"
        >
          <ValuesChangeCard
            title={"Avg. Time in Lead: New"}
            vsText={`prev ${dayDiff} days`}
            value={
              leadToWinData.hasOwnProperty("leadInNewAverageTime")
                ? leadToWinData.leadInNewAverageTime + " days"
                : ""
            }
            changePercent={leadToWinData.leadInNewAverageTimePercentChange}
            loading={winDataLoading}
          />
          <ValuesChangeCard
            title={"Avg. Lead-to-Win Time"}
            vsText={`prev ${dayDiff} days`}
            value={
              leadToWinData.hasOwnProperty("leadToWinAverageTime")
                ? leadToWinData.leadToWinAverageTime + " days"
                : ""
            }
            changePercent={leadToWinData.leadToWinAverageTimePercentChange}
            loading={winDataLoading}
          />
        </SimpleGrid>
        <SimpleGrid columns={2} spacing={"20px"}>
          <AvgLeadWinTime
            appliedFilters={appliedFilters}
            selectedDate={selectedDate}
            innerAppliedFilters={innerAppliedFilters}
            innerDate={innerDate}
            fixFilters={fixFilters}
            chartType={chartType}
            isCompared={isCompared}
          />
          {/* <AvgTimeInStatus
            appliedFilters={appliedFilters}
            selectedDate={selectedDate}
            innerAppliedFilters={innerAppliedFilters}
            innerDate={innerDate}
            fixFilters={fixFilters}
            chartType={""}
          /> */}
        </SimpleGrid>
      </ChartContainer>
    </Box>
  );
};

export default LeadWinTime;
