import { ChevronLeftIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Switch,
  Text,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import DraggableCard from "../../components/DragAndDrop/DraggableCard";
import TipsLinkButton from "../../components/TipsLinkButton/TipsLinkButton";
import { ReactComponent as SearchIcon } from "../../images/search-icon.svg";

const TIPS_LINK_PAGE_CUSTOM_FORM =
  "https://coachbar.freshdesk.com/support/solutions/articles/153000063142#Custom-Forms";

const Sidebar = ({
  formPropertyList,
  addFormProperty,
  formProperties,
  handleFormSearch,
  searchTerm,
  setSearchTerm,
  mandatoryProperties,
  dispatch,
  updateActiveSubMenu,
  updateInputList,
  activeSubMenu,
  inputList,
  type,
  dragFrom,
}) => {
  const [isPageDetailsOpen, setIsPageDetailsOpen] = useState(true);
  const [activeSubMenuId, setActiveSubMenuId] = useState(undefined);
  const [subMenuData, setSubMenuData] = useState(undefined);
  const renderFormProperties = useCallback(
    (card, index) => {
      return (
        <DraggableCard
          index={index}
          id={card.id}
          name={card.name}
          added={card.added}
          onAdd={addFormProperty}
          type={type}
          dragFrom={dragFrom}
        />
      );
    },
    [formPropertyList, formProperties]
  );

  useEffect(() => {
    if (activeSubMenu) {
      setIsPageDetailsOpen(false);
      setActiveSubMenuId(activeSubMenu);
      const activeSubMenuData = inputList.find(
        (list) => list.id === activeSubMenu
      );
      setSubMenuData(activeSubMenuData);
    } else {
      if (activeSubMenuId) {
        setIsPageDetailsOpen(true);
        setActiveSubMenuId(undefined);
      }
    }
  }, [activeSubMenu, inputList]);

  return (
    <Box
      borderRight={"1px solid #CEEAEE"}
      minH={"calc(100vh - 65px)"}
      w={"350px"}
      maxW={"350px"}
      bgColor={"var(--chakra-colors-white)"}
    >
      {isPageDetailsOpen && (
        <Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            my={"10px"}
            mx={5}
            justifyContent={"space-between"}
            gap="20px"
          >
            <Text
              color={"var(--title-black-text)"}
              fontSize={"14px"}
              fontWeight={600}
              whiteSpace={"nowrap"}
            >
              Lead Form
            </Text>
            <TipsLinkButton to={TIPS_LINK_PAGE_CUSTOM_FORM} />
          </Box>

          <Box px={"20px"} mb={"24px"}>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                maxH={"32px"}
                css={{
                  svg: {
                    path: {
                      stroke: "#BFC3C9",
                      strokeWidth: "3",
                    },
                  },
                }}
              >
                <SearchIcon height={"14px"} width={"14px"} />
              </InputLeftElement>
              <Input
                type="text"
                placeholder="Search Properties..."
                maxH={"32px"}
                borderColor={"#DEE8F8"}
                fontSize={"12px"}
                fontWeight={450}
                onChange={handleFormSearch}
                value={searchTerm}
                _focus={{
                  borderColor: "#0C94AC !important",
                  boxShadow: "none",
                }}
                _placeholder={{
                  color: "brandGray.201",
                }}
              />
              {searchTerm && (
                <InputRightElement
                  h="auto"
                  w="auto"
                  top="50%"
                  right="16px"
                  transform="translateY(-50%)"
                  cursor={"pointer"}
                  onClick={() => setSearchTerm("")}
                >
                  <CloseIcon h={"10px"} color={"#9EA5AD"} />
                </InputRightElement>
              )}
            </InputGroup>
          </Box>
          <Box
            px={"20px"}
            maxH={"calc(100vh - 181px)"}
            overflow={"auto"}
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#E6E7E9",
                borderRadius: "4px",
              },
            }}
          >
            {formPropertyList.map((card, i) => renderFormProperties(card, i))}
          </Box>
        </Box>
      )}
      {activeSubMenuId && subMenuData && (
        <Box>
          <Box
            display={"inline-flex"}
            alignItems={"center"}
            cursor={"pointer"}
            p={"20px"}
            onClick={() => {
              setIsPageDetailsOpen(true);
              dispatch(updateActiveSubMenu(undefined));
              setActiveSubMenuId(null);
            }}
          >
            <ChevronLeftIcon
              color={"var(--title-black-text)"}
              fontSize={"22px"}
            />
            <Text
              color={"var(--title-black-text)"}
              fontSize={"14px"}
              fontWeight={600}
            >
              Edit Property
            </Text>
          </Box>

          {!mandatoryProperties.includes(subMenuData?.id) && (
            <Flex alignItems={"center"} mb={"24px"} px={"24px"}>
              <Switch
                size={"sm"}
                mr={"8px"}
                isChecked={subMenuData?.isRequired}
                colorScheme="brand"
                onChange={(e) => {
                  const value = e.target.checked || "";
                  setSubMenuData({
                    ...subMenuData,
                    isRequired: value,
                  });
                  dispatch(
                    updateInputList({
                      id: subMenuData?.id,
                      changes: {
                        isRequired: value,
                      },
                    })
                  );
                }}
              />
              <Text
                fontSize={"12px"}
                fontWeight={500}
                color={"#7E8792"}
                mr={"4px"}
              >
                Make this field Required
              </Text>
            </Flex>
          )}

          <Box px={"24px"} mb={"16px"}>
            <Text color={"#7E8792"} fontSize={"12px"} fontWeight={500}>
              Property Name
            </Text>
            <Text
              color={"var(--title-black-text)"}
              fontSize={"14px"}
              fontWeight={400}
            >
              {subMenuData?.name}
            </Text>
          </Box>

          <Box mb={"16px"} px={"24px"}>
            <Flex alignItems={"center"} gap={1}>
              <Text
                color={"#7E8792"}
                fontSize={"12px"}
                fontWeight={500}
                mb={"4px"}
              >
                Label
              </Text>
            </Flex>
            <Input
              border={"1px solid #E6E7E9"}
              p={"8px 12px"}
              borderRadius={"4px"}
              fontSize={"14px"}
              color={"var(--title-black-text)"}
              maxLength={90}
              value={subMenuData?.name}
              onChange={(e) => {
                const value = e.target.value || "";
                setSubMenuData({
                  ...subMenuData,
                  name: value,
                });
                dispatch(
                  updateInputList({
                    id: subMenuData?.id,
                    changes: {
                      name: value,
                    },
                  })
                );
              }}
            />
          </Box>

          {subMenuData?.placeholder && (
            <Box mb={"16px"} px={"24px"}>
              <Flex alignItems={"center"} gap={1}>
                <Text
                  color={"#7E8792"}
                  fontSize={"12px"}
                  fontWeight={500}
                  mb={"4px"}
                >
                  Placeholder/Help Text
                </Text>
              </Flex>
              <Input
                border={"1px solid #E6E7E9"}
                p={"8px 12px"}
                borderRadius={"4px"}
                fontSize={"14px"}
                color={"var(--title-black-text)"}
                maxLength={90}
                value={subMenuData?.placeholder}
                onChange={(e) => {
                  const value = e.target.value || "";
                  setSubMenuData({
                    ...subMenuData,
                    placeholder: value,
                  });
                  dispatch(
                    updateInputList({
                      id: subMenuData?.id,
                      changes: {
                        placeholder: value,
                      },
                    })
                  );
                }}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Sidebar;
