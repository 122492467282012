import {
  Box,
  Center,
  Flex,
  SimpleGrid,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { Button, Loader, MoreFilterButton } from "@coachbar/shared-components";
import DashboardDateFilter from "@coachbar/shared-components/src/components/Charts/components/DashboardDateFilter";
import EmptyDashboard from "@coachbar/shared-components/src/components/Charts/components/EmptyDashboard";
import FiltersDrawer from "@coachbar/shared-components/src/components/Charts/components/FiltersDrawer";
import WidgetModal from "@coachbar/shared-components/src/components/Charts/components/WidgetModal";
import { scrollbarCSS } from "@coachbar/shared-components/src/constants/constantValues";
import {
  defaultFinancialWidgetsDataCommon,
  slugConstants,
} from "@coachbar/shared-components/src/constants/dashboard";
import {
  ChartsProvider,
  useChartsProvider,
} from "@coachbar/shared-components/src/screens/dashboard/chartContext";
import { getVisibleChartsFromDb } from "@coachbar/shared-components/src/services/dashboardUtility/utility";
import { deepCopyObject } from "@coachbar/shared-components/src/services/utility";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getGeneralSettings } from "../../generalSettings/generalSettingsActions";
import { getPartnerProviderList } from "../../myProviders/providersList/providersListActions";
import { getAllUsers } from "../../settings/usersAndRoles/usersAndRolesActions";
import {
  getDashboardsCharts,
  updateDashboardCharts,
} from "../dashboardActions";
import LeadWinTime from "./charts/LeadWinCharts/LeadWinTime";
import LeaderBoard from "./charts/LeaderBoard";
import RecurringRevenueTrend from "./charts/RecurringRevenueTrend";
import Revenue from "./charts/RevenueChart/Revenue";
import RevenueCustomerType from "./charts/RevenueCustomerType";
import RevenueProduct from "./charts/RevenueProduct";
import RevenueProductTrend from "./charts/RevenueProductTrend";
import RevenueRegion from "./charts/RevenueRegion";
import RevenueRegionTrend from "./charts/RevenueRegionTrend";
import SalesPipeline from "./charts/SalesPipeline";
import Commission from "./charts/CommissionCharts/Commission";
const dashboardType = "Financial";
const FinancialComp = () => {
  const {
    selectedDate,
    setSelectedDate,
    chartList,
    setChartList,
    filters,
    onFiltersSubmit,
    userInfo,
    dashboardData,
    setDashboardData,
    platformJson,
  } = useChartsProvider();

  const {
    onOpen: onWidgetModalOpen,
    onClose: onWidgetModalClose,
    isOpen: isWidgetModalOpen,
  } = useDisclosure();

  const {
    onOpen: onFilterDrawerOpen,
    onClose: onFilterDrawerClose,
    isOpen: isFilterDrawerOpen,
  } = useDisclosure();

  const onDateChange = (date) => {
    if (JSON.stringify(date) !== JSON.stringify(selectedDate)) {
      // setLoading(true);
      setSelectedDate(date);
    }
  };

  const onChartsUpdate = async (updateCharts, onFail) => {
    let requestData = {
      id: dashboardData?.id,
      dashboardType: dashboardType,
      widgetList: Object.keys(updateCharts)
        .filter((chartKey) => updateCharts[chartKey].isVisible)
        .map((x) => x),
    };
    let { data } = await updateDashboardCharts(requestData);
    if (data?.code === 200) {
      setDashboardData(data?.data?.dashboard);
      setChartList(updateCharts);
      onWidgetModalClose();
    } else {
      onFail();
    }
  };

  let filtersCount = useMemo(() => {
    let count = 0;
    Object.keys(filters).map((x) => {
      if (filters[x].find((x) => x.isChecked)) {
        count++;
      }
    });
    return count;
  }, [filters]);

  let isAllChartsRemoved = useMemo(() => {
    return !Object.values(chartList).some((x) => x.isVisible);
  }, [chartList]);

  const mediumSizedCharts = {
    [slugConstants.finRevenueRegion]: RevenueRegion,
    [slugConstants.finRevenueRegionTrend]: RevenueRegionTrend,
    [slugConstants.finRevenueProduct]: RevenueProduct,
    [slugConstants.finRevenueCustomerType]: RevenueCustomerType,
    [slugConstants.finRevenueProductTrend]: RevenueProductTrend,
    [slugConstants.finRecurringRevenueTrend]: RecurringRevenueTrend,
  };

  const visibleMediumSizedCharts = Object.keys(mediumSizedCharts).filter(
    (key) => chartList[key]?.isVisible
  );

  if (isAllChartsRemoved) {
    return (
      <>
        <EmptyDashboard
          onWidgetModalOpen={onWidgetModalOpen}
          moduleForId="dashboard-financial-empty"
        />
        ;
        <WidgetModal
          chartList={chartList}
          setChartList={onChartsUpdate}
          isOpen={isWidgetModalOpen}
          onClose={onWidgetModalClose}
          moduleForId="dashboard-financial-empty"
        />
      </>
    );
  }

  return (
    <Box p={"24px"} h={"calc(100vh - 64px)"} bg={"#F9FAFB"}>
      {userInfo?.role !== "User" && (
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          mb={"20px"}
        >
          <Flex alignItems={"center"} gap={"20px"} justifyContent={"start"}>
            <Stack pos={"relative"}>
              <DashboardDateFilter
                bg="#FFF"
                handleDateChange={onDateChange}
                selectedDateRange={selectedDate}
                showLastMonthData
                customPopoverTop="50px"
                customPopoverRight="-470px"
                moduleForId="dashboard-financial"
              />
            </Stack>
            <MoreFilterButton
              appliedMoreFilterCount={filtersCount}
              setShowMoreFilters={() =>
                isFilterDrawerOpen
                  ? onFilterDrawerClose()
                  : onFilterDrawerOpen()
              }
              showMoreFilters={isFilterDrawerOpen}
            />
          </Flex>
          <Button
            variant="newColorThemePrimary"
            onClick={onWidgetModalOpen}
            title="Add Widget"
            id="financial-dashboard-add-widget-open"
          />
        </Flex>
      )}

      <Box maxH={"calc(100vh - 174px)"} overflow={"auto"} css={scrollbarCSS}>
        {(chartList[slugConstants.finLeaderBoard]?.isVisible ||
          chartList[slugConstants.finSalesPipeline]?.isVisible) && (
          <SimpleGrid templateColumns={"1fr 40%"} gap={"20px"} mb={"20px"}>
            {chartList[slugConstants.finLeaderBoard]?.isVisible && (
              <LeaderBoard
                slug={slugConstants.finLeaderBoard}
                chartData={chartList[slugConstants.finLeaderBoard]}
              />
            )}
            {userInfo.role !== "User" &&
              chartList[slugConstants.finSalesPipeline]?.isVisible && (
                <SalesPipeline
                  slug={slugConstants.finSalesPipeline}
                  chartData={chartList[slugConstants.finSalesPipeline]}
                />
              )}
          </SimpleGrid>
        )}

        {userInfo.role !== "User" &&
          chartList[slugConstants.finRevenue]?.isVisible && (
            <SimpleGrid columns={1} gap={"20px"} mb={"20px"}>
              <Revenue chartData={chartList[slugConstants.finRevenue]} />
            </SimpleGrid>
          )}

        {userInfo.role !== "User" &&
          chartList[slugConstants.finCommission]?.isVisible && (
            <SimpleGrid columns={1} gap={"20px"} mb={"20px"}>
              <Commission chartData={chartList[slugConstants.finCommission]} />
            </SimpleGrid>
          )}

        {chartList[slugConstants.finLeadWinTime]?.isVisible && (
          <SimpleGrid columns={1} gap={"20px"} mb={"20px"}>
            <LeadWinTime chartData={chartList[slugConstants.finLeadWinTime]} />
          </SimpleGrid>
        )}

        {userInfo.role !== "User" && visibleMediumSizedCharts.length > 0 && (
          <SimpleGrid columns={2} gap={"20px"}>
            {visibleMediumSizedCharts.map((key) => {
              const ChartComponent = mediumSizedCharts[key];
              return (
                <ChartComponent
                  key={key}
                  slug={key}
                  chartData={chartList[key]}
                />
              );
            })}
          </SimpleGrid>
        )}
      </Box>
      <WidgetModal
        chartList={chartList}
        setChartList={onChartsUpdate}
        isOpen={isWidgetModalOpen}
        onClose={onWidgetModalClose}
        moduleForId="dashboard-financial-chart-list"
      />
      <FiltersDrawer
        filterType="deal"
        isOpen={isFilterDrawerOpen}
        isProvider={true}
        platformJson={platformJson}
        onModalClose={onFilterDrawerClose}
        filters={filters}
        setFilterValues={(newFilters) => {
          onFilterDrawerClose();
          if (JSON.stringify(filters) !== JSON.stringify(newFilters)) {
            // setLoading(true);
            onFiltersSubmit(newFilters);
          }
        }}
        moduleForId="dashboard-financial"
      />
    </Box>
  );
};

const Financial = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [fetchedAllData, setFetchedAllData] = useState(false);
  const [partnerManagers, setPartnerManagers] = useState([]);
  const [partnerTiers, setPartnerTiers] = useState([]);
  const [partnerTypes, setPartnerTypes] = useState([]);
  const [partnerGroups, setPartnerGroups] = useState([]);
  const [partners, setPartners] = useState([]);
  const [dashData, setDashData] = useState({});
  const [charts, setCharts] = useState(
    deepCopyObject(defaultFinancialWidgetsDataCommon)
  );
  let initialize = false;
  useEffect(() => {
    if (!initialize) {
      initialize = true;
      fetchAllData();
    }
  }, []);

  const fetchAllData = async () => {
    const [dashData, partnerCategoryList, users, partnersList] =
      await Promise.all([
        getDashboardsCharts(dashboardType),
        getGeneralSettings("scCategory"),
        getAllUsers(),
        getPartnerProviderList(),
      ]);

    if (partnerCategoryList?.data?.spPartnerCategoryList) {
      setPartnerTypes(partnerCategoryList?.data?.spPartnerCategoryList);
    }
    if (users) {
      setPartnerManagers(users);
    }
    setPartners(partnersList.partnerList);
    if (dashData?.data) {
      setDashData(deepCopyObject(dashData?.data));
      setCharts(getVisibleChartsFromDb(dashData?.data?.widgetList, charts));
    }
    setFetchedAllData(true);
  };
  if (!fetchedAllData) {
    return (
      <Center minH={"calc(100vh - 64px)"}>
        <Loader />
      </Center>
    );
  }
  return (
    <ChartsProvider
      charts={charts}
      partners={partners}
      partnerManagers={partnerManagers}
      partnerTypes={partnerTypes}
      partnerGroups={partnerGroups}
      partnerTiers={partnerTiers}
      userInfo={userInfo}
      dashboardData={dashData}
      platform="sc"
      dashboardType={dashboardType}
      updateDashboardApi={updateDashboardCharts}
    >
      <FinancialComp />
    </ChartsProvider>
  );
};

export default Financial;
