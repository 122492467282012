// transactionHistorySlice.js
import { createSlice } from "@reduxjs/toolkit";
import { getProfileCompletionStatus } from "./profileMetricsAction";

const initialState = {
  profileCompletionData: {},
  loading: false,
  error: null,
  success: null,
};

const profileSlice = createSlice({
  name: "profileMetrics",
  initialState,
  reducers: {
    clearMessage: (state) => {
      state.success = null;
      state.error = null;
    },
    setError: (state, action) => {
      let errorMsg = action.payload;
      state.error = errorMsg;
    },
    setSuccess: (state, action) => {
      let successMsg = action.payload;
      state.success = successMsg;
    },
  },
  extraReducers: {
    // Get Profile Completion Status
    [getProfileCompletionStatus.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getProfileCompletionStatus.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
      if (payload.data && payload.success) {
        state.profileCompletionData = payload.data.status;
      }
    },
    [getProfileCompletionStatus.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload?.code && payload?.code >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
  },
});

export const { setError, setSuccess, clearMessage } = profileSlice.actions;

export default profileSlice.reducer;
