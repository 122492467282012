import { SimpleGrid } from "@chakra-ui/react";
import { Modal } from "@coachbar/shared-components";
import { scrollbarCSS } from "@coachbar/shared-components/src/constants/constantValues";
import ChartWidget from "@coachbar/shared-components/src/screens/dashboard/ChartWidget";
import { deepCopyObject } from "@coachbar/shared-components/src/services/utility";
import { useEffect, useState } from "react";

const WidgetModal = ({
  chartList,
  setChartList,
  isOpen,
  onClose,
  moduleForId = "",
}) => {
  const [updatedChartData, setUpdatedChartData] = useState(
    deepCopyObject(chartList)
  );

  useEffect(() => {
    setUpdatedChartData(chartList);
  }, [chartList]);

  return (
    <Modal
      title={"Add pre-defined widget(s) to your dashboard"}
      isOpen={isOpen}
      onClose={onClose}
      displayFooter={true}
      allowCloseButton={true}
      // loading={addFolderLoading}
      onSaveClick={() => {
        setChartList(updatedChartData, () => {
          setUpdatedChartData(chartList);
        });
      }}
      saveButtonTitle={"Add to Dashboard"}
      discardTitle="Cancel"
      size="3xl"
      moduleForId={moduleForId}
      spDesign
      isCentered
    >
      <SimpleGrid
        columns={[1, 2, 3]}
        gap={"20px"}
        pr={"6px"}
        maxH={"446px"}
        overflow={"auto"}
        css={scrollbarCSS}
      >
        {updatedChartData &&
          Object.values(updatedChartData)?.map((chart) => {
            return (
              <ChartWidget
                chartData={chart}
                key={chart?.title}
                updatedChartData={updatedChartData}
                setUpdatedChartData={setUpdatedChartData}
              />
            );
          })}
      </SimpleGrid>
    </Modal>
  );
};

export default WidgetModal;
