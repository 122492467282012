import {
  barChartIcon,
  funnelChartIcon,
  geoGraphIcon,
  lineChartIcon,
  pieChartIcon,
  speedoMeterIcon,
} from "../components/Icons/SvgsConstants";
import { getCurrency } from "../services/utility";

export const chartIconsTypes = {
  funnelChartIcon: "funnelChartIcon",
  pieChartIcon: "pieChartIcon",
  barChartIcon: "barChartIcon",
  speedoMeterIcon: "speedoMeterIcon",
  lineChartIcon: "lineChartIcon",
  geoGraphIcon: "geoGraphIcon",
  periodSummaryIcon: "periodSummaryIcon",
  trainingAndCoursesIcon: "trainingAndCoursesIcon",
};

export const regionKeys = {
  northAmerica: "northAmerica",
  europe: "europe",
  asiaPacific: "asiaPacific",
  southAmerica: "southAmerica",
  middleEastandAfrica: "middleEastandAfrica",
  Others: "Others",
};

export const regionColors = {
  [regionKeys.northAmerica]: "#3F83F8",
  [regionKeys.europe]: "#EBF5FF",
  [regionKeys.asiaPacific]: "#C3DDFD",
  [regionKeys.southAmerica]: "#76A9FA",
  [regionKeys.middleEastandAfrica]: "#A4CAFE",
  [regionKeys.Others]: "#EFF0F2",
};

export const regionLabels = {
  [regionKeys.northAmerica]: "North America",
  [regionKeys.europe]: "Europe",
  [regionKeys.asiaPacific]: "Asia-Pacific",
  [regionKeys.southAmerica]: "South America",
  [regionKeys.middleEastandAfrica]: "Middle East and Africa",
  [regionKeys.Others]: "Other",
};

export const regionWiseCountryCodes = {
  [regionKeys.europe]: [
    "RUS",
    "DEU",
    "GBR",
    "FRA",
    "ITA",
    "ESP",
    "POL",
    "UKR",
    "ROU",
    "NLD",
    "BEL",
    "SWE",
    "CZE",
    "GRC",
    "PRT",
    "HUN",
    "BLR",
    "AUT",
    "CHE",
    "SRB",
    "BGR",
    "DNK",
    "SVK",
    "FIN",
    "NOR",
    "IRL",
    "HRV",
    "MDA",
    "BIH",
    "ALB",
    "LTU",
    "SVN",
    "MKD",
    "LVA",
    "EST",
    "LUX",
    "MNE",
    "MLT",
    "ISL",
    "AND",
    "LIE",
    "MCO",
    "SMR",
    "VAT",
  ],
  [regionKeys.asiaPacific]: [
    "CHN",
    "JPN",
    "KOR",
    "IND",
    "AUS",
    "IDN",
    "THA",
    "PHL",
    "MYS",
    "VNM",
  ],
  [regionKeys.northAmerica]: ["USA", "CAN", "MEX"],
  [regionKeys.southAmerica]: ["BRA", "ARG", "COL"],
  [regionKeys.middleEastandAfrica]: ["SAU", "ARE", "EGY", "NGA", "ZAF"],
  [regionKeys.Others]: [],
};

export const regions = [
  regionLabels.northAmerica,
  regionLabels.europe,
  regionLabels.asiaPacific,
  regionLabels.southAmerica,
  regionLabels.middleEastandAfrica,
  regionLabels.Others,
];

export const regionColorsArray = [
  {
    id: regionKeys.northAmerica,
    label: regionLabels[regionKeys.northAmerica],
    color: regionColors[regionKeys.northAmerica],
  },
  {
    id: regionKeys.europe,
    label: regionLabels[regionKeys.europe],
    color: regionColors[regionKeys.europe],
  },
  {
    id: regionKeys.asiaPacific,
    label: regionLabels[regionKeys.asiaPacific],
    color: regionColors[regionKeys.asiaPacific],
  },
  {
    id: regionKeys.southAmerica,
    label: regionLabels[regionKeys.southAmerica],
    color: regionColors[regionKeys.southAmerica],
  },
  {
    id: regionKeys.middleEastandAfrica,
    label: regionLabels[regionKeys.middleEastandAfrica],
    color: regionColors[regionKeys.middleEastandAfrica],
  },
  {
    id: regionKeys.Others,
    label: regionLabels[regionKeys.Others],
    color: regionColors[regionKeys.Others],
  },
];

export const leadDealType = ["Received", "Submitted"];

export const leadDealSource = [
  "Manual",
  // "Service Request",
  "Hubspot",
  "Import",
  "Auto",
  // "Coachbar",
];

export const filtersKeys = {
  period: "period",
  type: "type",
  partnerManagers: "partnerManagers",
  partnerGroups: "partnerGroups",
  partnerTiers: "partnerTiers",
  partnerTypes: "partnerTypes",
  partners: "partners",
  regions: "regions",
  source: "source",
};

export const partnerFilters = [
  filtersKeys.partnerManagers,
  filtersKeys.partnerGroups,
  filtersKeys.partnerTiers,
  filtersKeys.partnerTypes,
  filtersKeys.partners,
];

export const filterLabels = {
  [filtersKeys.type]: "Lead/Deal Type",
  [filtersKeys.partnerManagers]: "Partner Managers",
  [filtersKeys.partnerGroups]: "Partner Group",
  [filtersKeys.partnerTiers]: "Partner Tier",
  [filtersKeys.partnerTypes]: "Partner Type",
  [filtersKeys.partners]: "Partners",
  [filtersKeys.regions]: "Regions",
  [filtersKeys.source]: "Lead/Deal Source",
};

/*
  fin initial is for financial page charts
*/
export const slugConstants = {
  leaderBoard: "leaderBoard",
  salesPipeline: "salesPipeline",
  leads: "leads",
  leadTypes: "leadTypes",
  dealTypes: "dealTypes",
  leadByStatuses: "leadByStatuses",
  dealByStatuses: "dealByStatuses",
  leadConversionRate: "leadConversionRate",
  dealsWonRate: "dealsWonRate",
  dealsLostRate: "dealsLostRate",
  leadTrends: "leadTrends",
  dealTrends: "dealTrends",
  leadByRegions: "leadByRegions",
  dealByRegions: "dealByRegions",
  periodSummary: "periodSummary",
  finLeaderBoard: "finLeaderBoard",
  finSalesPipeline: "finSalesPipeline",
  finRevenue: "finRevenue",
  finLeadWinTime: "finLeadWinTime",
  finCommission: "finCommission",
  finRevenueRegion: "finRevenueRegion",
  finRevenueRegionTrend: "finRevenueRegionTrend",
  finRevenueProduct: "finRevenueProduct",
  finRevenueCustomerType: "finRevenueCustomerType",
  finRevenueProductTrend: "finRevenueProductTrend",
  finRecurringRevenueTrend: "finRecurringRevenueTrend",
  scTopSellingProducts: "scTopSellingProducts",
  engPartnersByRegion: "engPartnersByRegion",
  engActivePartnersRate: "engActivePartnersRate",
  engCourseCompletionRate: "engCourseCompletionRate",
  engOnboarding: "engOnboarding",
  trainingAndCourses: "trainingAndCourses",
  engLeaderBoard: "engLeaderBoard",
};

export const defaultPerformanceWidgetsDataCommon = {
  [slugConstants.leaderBoard]: {
    slug: slugConstants.leaderBoard,
    isVisible: true,
    title: "LeaderBoard",
    description: "Displays the top 5 performers among Partners.",
    chartType: "grid",
    iconType: chartIconsTypes.funnelChartIcon,
  },
  [slugConstants.salesPipeline]: {
    slug: slugConstants.salesPipeline,
    isVisible: true,
    isCompared: false,
    title: "Sales Pipeline",
    description: "Displays the accepted leads & deals count.",
    chartType: "funnel",
    iconType: chartIconsTypes.funnelChartIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerManagers,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.type,
      filtersKeys.source,
    ],
  },
  [slugConstants.leads]: {
    slug: slugConstants.leads,
    isVisible: true,
    isCompared: false,
    isGridViewEnabled: false,
    title: "Leads",
    description: "Displays the total number of leads per period selected.",
    chartType: "pie",
    iconType: chartIconsTypes.pieChartIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.type,
      filtersKeys.source,
    ],
  },
  [slugConstants.leadTypes]: {
    slug: slugConstants.leadTypes,
    isVisible: true,
    isCompared: false,
    isGridViewEnabled: false,
    title: "Leads Types",
    description: "Displays the total number of leads per period.",
    chartType: "pie",
    iconType: chartIconsTypes.pieChartIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.source,
    ],
  },
  [slugConstants.dealTypes]: {
    slug: slugConstants.dealTypes,
    isVisible: true,
    isCompared: false,
    isGridViewEnabled: false,
    title: "Deals Types",
    description: "Displays the total number of deals per period selected.",
    chartType: "pie",
    iconType: chartIconsTypes.pieChartIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.source,
    ],
  },
  [slugConstants.leadByStatuses]: {
    slug: slugConstants.leadByStatuses,
    isVisible: true,
    isCompared: false,
    isGridViewEnabled: false,
    title: "Leads by Statuses",
    description:
      "Displays the total number of accepted leads grouped by status.",
    chartType: "horizontalBar",
    iconType: chartIconsTypes.barChartIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerManagers,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.type,
      filtersKeys.source,
    ],
  },
  [slugConstants.dealByStatuses]: {
    slug: slugConstants.dealByStatuses,
    isVisible: true,
    isCompared: false,
    isGridViewEnabled: false,
    title: "Deals by Statuses",
    description:
      "Displays the total number of accepted deals grouped by status.",
    chartType: "horizontalBar",
    iconType: chartIconsTypes.barChartIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerManagers,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.type,
      filtersKeys.source,
    ],
  },
  [slugConstants.leadConversionRate]: {
    slug: slugConstants.leadConversionRate,
    isVisible: true,
    isCompared: false,
    title: "Leads Conversion Rate",
    description:
      "% of leads that successfully convert into leads with Won status.",
    chartType: "speedometer",
    iconType: chartIconsTypes.speedoMeterIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerManagers,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.type,
      filtersKeys.source,
    ],
  },
  [slugConstants.dealsWonRate]: {
    slug: slugConstants.dealsWonRate,
    isVisible: true,
    isCompared: false,
    title: "Deals Won Rate",
    description:
      "% of deals that successfully convert into deals with Closed: Won status.",
    chartType: "speedometer",
    iconType: chartIconsTypes.speedoMeterIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerManagers,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.type,
      filtersKeys.source,
    ],
  },
  [slugConstants.dealsLostRate]: {
    slug: slugConstants.dealsLostRate,
    isVisible: true,
    isCompared: false,
    title: "Deals Lost Rate",
    description: "% of deals that convert into deals with Closed: Lost status.",
    chartType: "speedometer",
    iconType: chartIconsTypes.speedoMeterIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerManagers,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.type,
      filtersKeys.source,
    ],
  },
  [slugConstants.leadTrends]: {
    slug: slugConstants.leadTrends,
    isVisible: true,
    isCompared: false,
    isGridViewEnabled: false,
    title: "Leads Dynamics",
    description:
      "Leads By Statuses with the split of the whole period selected.",
    chartType: "line",
    iconType: chartIconsTypes.lineChartIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerManagers,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.type,
      filtersKeys.source,
    ],
  },
  [slugConstants.dealTrends]: {
    slug: slugConstants.dealTrends,
    isVisible: true,
    isCompared: false,
    isGridViewEnabled: false,
    title: "Deals Dynamics",
    description:
      "Deals By Statuses with the split of the whole period selected.",
    chartType: "line",
    iconType: chartIconsTypes.lineChartIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerManagers,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.type,
      filtersKeys.source,
    ],
  },
  [slugConstants.leadByRegions]: {
    slug: slugConstants.leadByRegions,
    isVisible: true,
    isCompared: false,
    title: "Leads By Region",
    description:
      "Displays total number of Accepted leads created during selected period.",
    chartType: "geo",
    iconType: chartIconsTypes.geoGraphIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerManagers,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.type,
      filtersKeys.source,
    ],
  },
  [slugConstants.dealByRegions]: {
    slug: slugConstants.dealByRegions,
    isVisible: true,
    isCompared: false,
    title: "Deals By Region",
    description:
      "Displays total number of Accepted deals created during selected period.",
    chartType: "geo",
    iconType: chartIconsTypes.geoGraphIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerManagers,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.type,
      filtersKeys.source,
    ],
  },
};

export const defaultPerformanceWidgetDataSc = {
  [slugConstants.scTopSellingProducts]: {
    slug: slugConstants.scTopSellingProducts,
    isVisible: true,
    isCompared: false,
    title: "Top Selling Products",
    description: "Displays Top Selling Products.",
    chartType: "bar",
    iconType: chartIconsTypes.lineChartIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerManagers,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.type,
      filtersKeys.source,
    ],
  },
};

export const defaultFinancialWidgetsDataCommon = {
  [slugConstants.finLeaderBoard]: {
    slug: slugConstants.finLeaderBoard,
    isVisible: true,
    title: "Leaderboard By Revenue",
    description: "Displays the top 5 performers among Partners.",
    chartType: "grid",
    iconType: chartIconsTypes.funnelChartIcon,
  },
  [slugConstants.finSalesPipeline]: {
    slug: slugConstants.finSalesPipeline,
    isVisible: true,
    isCompared: false,
    title: "Sales Pipeline",
    description:
      "Sales Pipeline displays the total revenue accepted deals on the Current date.",
    chartType: "funnel",
    iconType: chartIconsTypes.funnelChartIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerManagers,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.type,
      filtersKeys.source,
    ],
  },
  [slugConstants.finRevenue]: {
    slug: slugConstants.finRevenue,
    isVisible: true,
    isCompared: false,
    isGridViewEnabled: false,
    title: "Revenue",
    description: "Displays the total of Revenue per period.",
    chartType: "line",
    iconType: chartIconsTypes.lineChartIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.type,
      filtersKeys.source,
    ],
  },
  [slugConstants.finCommission]: {
    slug: slugConstants.finCommission,
    isVisible: true,
    isCompared: false,
    isGridViewEnabled: false,
    title: "Commissions",
    description: "Displays the total of Commission per period.",
    chartType: "line",
    iconType: chartIconsTypes.lineChartIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.type,
      filtersKeys.source,
    ],
  },
  [slugConstants.finLeadWinTime]: {
    slug: slugConstants.finLeadWinTime,
    isVisible: true,
    isCompared: false,
    isGridViewEnabled: false,
    title: "Lead-To-Win Time",
    description: "Displays the total number of leads per period.",
    chartType: "pie",
    iconType: chartIconsTypes.lineChartIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.source,
      filtersKeys.partnerManagers,
      filtersKeys.type,
    ],
  },
  [slugConstants.finRevenueRegion]: {
    slug: slugConstants.finRevenueRegion,
    isVisible: true,
    isCompared: false,
    isGridViewEnabled: false,
    title: "Revenue By Region",
    description: "Revenue By Region displays total revenue for Closed: Won.",
    chartType: "geo",
    iconType: chartIconsTypes.geoGraphIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.source,
    ],
  },
  [slugConstants.finRevenueRegionTrend]: {
    slug: slugConstants.finRevenueRegionTrend,
    isVisible: true,
    isCompared: false,
    isGridViewEnabled: false,
    title: "Revenue By Region Trends",
    description: "Displays the change of the revenue by region.",
    chartType: "line",
    iconType: chartIconsTypes.lineChartIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerManagers,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.type,
      filtersKeys.source,
    ],
  },
  [slugConstants.finRevenueProduct]: {
    slug: slugConstants.finRevenueProduct,
    isVisible: true,
    isCompared: false,
    title: "Revenue by Product",
    description:
      "Revenue by product displays the total amount of revenue for the exact product in all deals.",
    chartType: "horizontalBar",
    iconType: chartIconsTypes.barChartIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerManagers,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.type,
      filtersKeys.source,
    ],
  },
  [slugConstants.finRevenueCustomerType]: {
    slug: slugConstants.finRevenueCustomerType,
    isVisible: true,
    isCompared: false,
    isGridViewEnabled: false,
    title: "Revenue by Customer Type",
    description:
      "Revenue by Customer Type displays the total amount of revenue for the existing customers vs new customers.",
    chartType: "horizontalBar",
    iconType: chartIconsTypes.barChartIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerManagers,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.type,
      filtersKeys.source,
    ],
  },
  [slugConstants.finRevenueProductTrend]: {
    slug: slugConstants.finRevenueProductTrend,
    isVisible: true,
    isCompared: false,
    title: "Revenue by Product Trends",
    description: "Revenue by Product Trends displays the Revenue by products.",
    chartType: "",
    iconType: chartIconsTypes.lineChartIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerManagers,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.type,
      filtersKeys.source,
    ],
  },
  [slugConstants.finRecurringRevenueTrend]: {
    slug: slugConstants.finRecurringRevenueTrend,
    isVisible: true,
    isCompared: false,
    title: "Recurring vs New Revenue Trends",
    description:
      "Recurring vs New Revenue Trends displays the Revenue trends split by new and existing customers.",
    chartType: "speedometer",
    iconType: chartIconsTypes.lineChartIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerManagers,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.type,
      filtersKeys.source,
    ],
  },
};

export const defaultEngagementWidgetsDataCommon = {
  [slugConstants.engLeaderBoard]: {
    slug: slugConstants.engLeaderBoard,
    isVisible: true,
    title: "Leaderboard",
    description:
      "Displays the top 5 performers based on the number of courses passed",
    chartType: "grid",
    iconType: chartIconsTypes.funnelChartIcon,
  },
  [slugConstants.engPartnersByRegion]: {
    slug: slugConstants.engPartnersByRegion,
    isVisible: true,
    isCompared: false,
    isGridViewEnabled: false,
    title: "Partners By Region",
    description:
      "Displays the total number of accepted deals grouped by status.",
    chartType: "geo",
    iconType: chartIconsTypes.geoGraphIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
      filtersKeys.partnerManagers,
    ],
  },
  [slugConstants.engActivePartnersRate]: {
    slug: slugConstants.engActivePartnersRate,
    isVisible: true,
    isCompared: false,
    title: "Active Partners Rate",
    description: "Displays the total number of deals per period selected.",
    chartType: "speedometer",
    iconType: chartIconsTypes.speedoMeterIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerTiers,
      filtersKeys.partnerManagers,
    ],
  },
  [slugConstants.engCourseCompletionRate]: {
    slug: slugConstants.engCourseCompletionRate,
    isVisible: true,
    isCompared: false,
    title: "Courses Completion Rate",
    description: "Displays the total number of deals per period selected.",
    chartType: "speedometer",
    iconType: chartIconsTypes.speedoMeterIcon,
    filters: [
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerManagers,
      filtersKeys.partnerTiers,
      filtersKeys.partners,
    ],
  },
  [slugConstants.engOnboarding]: {
    slug: slugConstants.engOnboarding,
    isVisible: true,
    isCompared: false,
    isGridViewEnabled: false,
    title: "Onboarding",
    description: "Average number of days needed to activate the partner.",
    chartType: "line",
    iconType: chartIconsTypes.lineChartIcon,
    filters: [
      filtersKeys.period,
      filtersKeys.regions,
      filtersKeys.partnerTypes,
      filtersKeys.partnerGroups,
      filtersKeys.partnerTiers,
      filtersKeys.partnerManagers,
    ],
  },
};

export const defaultGeneralWidgetsDataCommon = {
  [slugConstants.finSalesPipeline]:
    defaultFinancialWidgetsDataCommon[slugConstants.finSalesPipeline],
  [slugConstants.finRevenueRegion]:
    defaultFinancialWidgetsDataCommon[slugConstants.finRevenueRegion],
  [slugConstants.leadConversionRate]:
    defaultPerformanceWidgetsDataCommon[slugConstants.leadConversionRate],
  [slugConstants.dealsWonRate]:
    defaultPerformanceWidgetsDataCommon[slugConstants.dealsWonRate],
  [slugConstants.finRevenue]:
    defaultFinancialWidgetsDataCommon[slugConstants.finRevenue],
};

export const defaultGeneralWidgetsDataSp = {
  [slugConstants.engActivePartnersRate]:
    defaultEngagementWidgetsDataCommon[slugConstants.engActivePartnersRate],
  [slugConstants.engOnboarding]:
    defaultEngagementWidgetsDataCommon[slugConstants.engOnboarding],
  [slugConstants.engPartnersByRegion]:
    defaultEngagementWidgetsDataCommon[slugConstants.engPartnersByRegion],
  [slugConstants.engCourseCompletionRate]:
    defaultEngagementWidgetsDataCommon[slugConstants.engCourseCompletionRate],
};

export const defaultInsightWidgetsDataSc = {
  [slugConstants.trainingAndCourses]: {
    slug: slugConstants.trainingAndCourses,
    isVisible: true,
    title: "Training & Courses",
    description: "Displays detailed analysis of the training and courses.",
    chartType: "grid",
    iconType: chartIconsTypes.trainingAndCoursesIcon,
  },
  [slugConstants.leadTypes]:
    defaultPerformanceWidgetsDataCommon[slugConstants.leadTypes],
  [slugConstants.dealTypes]:
    defaultPerformanceWidgetsDataCommon[slugConstants.dealTypes],
  [slugConstants.leads]:
    defaultPerformanceWidgetsDataCommon[slugConstants.leads],
  [slugConstants.leadConversionRate]:
    defaultPerformanceWidgetsDataCommon[slugConstants.leadConversionRate],
  [slugConstants.dealsWonRate]:
    defaultPerformanceWidgetsDataCommon[slugConstants.dealsWonRate],
};

export const leadDealTypeColorMap = {
  received: "#B7C8E1",
  submitted: "#87CCAB",
};

export const leadDealByStatusColorMap = {
  New: "#A4CAFE",
  Lost: "#F8B4B4",
  Won: "#87CCAB",
  Unqualified: "#EBF6F1",
  Qualified: "#FCD9BD",
  "Closed: Lost": "#F8B4B4",
  "Closed: Won": "#87CCAB",
  "Qualified to Buy": "#FCD9BD",
};

export const dealLabelAndLegendMap = {
  New: "New",
  "Closed: Lost": "Closed & Lost",
  "Closed: Won": "Closed & Won",
  "Qualified to Buy": "Qualified to Buy",
};

export const partnerCardWidgets = {
  [slugConstants.periodSummary]: {
    slug: slugConstants.periodSummary,
    isVisible: true,
    title: "Period Summary",
    description: "Displays the data for the selected time frame.",
    iconType: chartIconsTypes.periodSummaryIcon,
  },
};

export const iconMap = (type) => {
  switch (type) {
    case "funnel":
      return {
        label: "Funnel",
        icon: funnelChartIcon,
      };
    case "pie":
      return {
        label: "Pie",
        icon: pieChartIcon,
      };
    case "horizontalBar":
      return {
        label: "Bar",
        icon: barChartIcon,
      };
    case "speedometer":
      return {
        label: "Speedometer",
        icon: speedoMeterIcon,
      };
    case "line":
      return {
        label: "Line",
        icon: lineChartIcon,
      };
    case "geo":
      return {
        label: "Geo",
        icon: geoGraphIcon,
      };
    default:
      return {
        label: "Bar",
        icon: barChartIcon,
      };
  }
};

export const colors = [
  "#A4CAFE",
  "#F8B4B4",
  "#87CCAB",
  "#EBF6F1",
  "#FCD9BD",
  "#F8B4B4",
  "#87CCAB",
  "#FCD9BD",
];

export const regionLabelMap = {
  northAmerica: "North America",
  europe: "Europe",
  asiaPacific: "Asia Pacific",
  southAmerica: "South America",
  middleEastandAfrica: "Middle East and Africa",
  Others: "Others",
};

export const revenueLabelWithCurrencySymbol = `Revenue (in ${
  getCurrency().symbol
})`;

export const finManagerFilterForRevenueChart = [
  slugConstants.finRecurringRevenueTrend,
  slugConstants.finRevenue,
  slugConstants.finRevenueCustomerType,
  slugConstants.finRevenueProduct,
  slugConstants.finRevenueProductTrend,
  slugConstants.finRevenueRegion,
  slugConstants.finRevenueRegionTrend,
  slugConstants.finCommission,
  slugConstants.finSalesPipeline,
];
