import { Box, Center, Flex } from "@chakra-ui/react";
import { Loader } from "@coachbar/shared-components";
import CustomBarChart from "@coachbar/shared-components/src/components/Charts/CustomBarChart";
import ChartGrid from "@coachbar/shared-components/src/screens/dashboard/ChartGrid";
import InnerChartContainer from "@coachbar/shared-components/src/screens/dashboard/InnerChartContainer";
import NoDataChart from "@coachbar/shared-components/src/screens/dashboard/NoDataChart";
import {
  addPreviousValuesRevenueProduct,
  calculateDaysBetweenDates,
  compareInnerAndGeneralFilters,
  formatDataRevenueProduct,
  formatDateRange,
  formatIncentivePlanData,
  getAppliedFilters,
  getPreviousDateRange,
} from "@coachbar/shared-components/src/services/dashboardUtility/utility";
import {
  deepCopyObject,
  getCurrency,
} from "@coachbar/shared-components/src/services/utility";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getCommissionByIncentiveProgram } from "../../financialActions";

const CommissionIncentiveProgram = ({
  appliedFilters,
  selectedDate,
  innerDate,
  fixFilters,
  innerAppliedFilters,
  chartType,
  isCompared,
}) => {
  let initialized = false;
  const [isLoading, setIsLoading] = useState(false);
  const [allRecords, setAllRecords] = useState([]);
  const [compareRecords, setCompareRecords] = useState([]);
  const [prevDateRange, setPrevDateRange] = useState(undefined);
  const [daysBetweenDates, setDaysBetweenDates] = useState(undefined);

  const clearPreviousData = () => {
    setCompareRecords([]);
    setPrevDateRange(null);
    setDaysBetweenDates(null);
  };

  let conDate = useMemo(() => {
    clearPreviousData();
    return deepCopyObject(
      innerDate?.to && innerDate?.from ? innerDate : selectedDate
    );
  }, [selectedDate, innerDate]);

  const [
    formattedDateForGrid,
    formattedPrevDateForGrid,
    formattedDateForChart,
    formattedPrevDateForChart,
  ] = useMemo(() => {
    // Date format to show in Grid
    const date = formatDateRange(conDate || null, "grid");
    const prevDate = formatDateRange(prevDateRange || null, "grid");
    // Different date format to show in chart tooltip
    const formattedDate = formatDateRange(conDate || null, "chart");
    const formattedPrevDate = formatDateRange(prevDateRange || null, "chart");
    return [date, prevDate, formattedDate, formattedPrevDate];
  }, [conDate, prevDateRange]);

  const constructRequestData = ({ date }) => {
    let requestData = compareInnerAndGeneralFilters(
      appliedFilters,
      innerAppliedFilters,
      Object.keys(fixFilters),
      date,
      selectedDate
    );

    return requestData;
  };

  const onToogleCompare = () => {
    if (!isCompared) {
      // setIsCompared(false);
      clearPreviousData();
    } else {
      // setIsCompared(true);
      const previousDateRange = getPreviousDateRange(conDate);
      const numberOfDays = calculateDaysBetweenDates(conDate);
      setDaysBetweenDates(numberOfDays);
      setPrevDateRange(previousDateRange);
      const requestData = constructRequestData({ date: previousDateRange });
      fetchData(requestData, true, allRecords);
    }
  };

  const fetchData = useCallback(
    async (requestData, isCompared = false, allRecords) => {
      setIsLoading(true);
      const res = await getCommissionByIncentiveProgram(requestData);
      setIsLoading(false);
      const data = res?.data?.dashboard?.planWiseCommission;

      if (data) {
        if (isCompared) {
          setCompareRecords(formatIncentivePlanData(data));
        } else {
          setAllRecords(formatIncentivePlanData(data));
        }
      }
    },
    []
  );
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      clearPreviousData();
      const requestData = constructRequestData({ date: innerDate });
      fetchData(requestData);
    }
  }, [fetchData, innerDate, selectedDate, appliedFilters, innerAppliedFilters]);

  useEffect(() => {
    onToogleCompare();
  }, [isCompared]);

  let finalRecords = useMemo(() => {
    if (isCompared) {
      const updatedData = addPreviousValuesRevenueProduct(
        allRecords,
        compareRecords
      );
      return updatedData;
    } else {
      return allRecords;
    }
  }, [allRecords, compareRecords]);

  const formattedLeadStatusDataForGrid = useMemo(
    () =>
      finalRecords.map((data) => {
        return {
          id: data.name,
          value: data.value,
          previousValue: data.previousValue || 0,
        };
      }),
    [finalRecords]
  );

  return (
    <>
      <InnerChartContainer
        chartHeader={`Commission by Incentive Program (in ${
          getCurrency().symbol
        })`}
      >
        {isLoading ? (
          <Center h={"80%"}>
            <Loader />
          </Center>
        ) : finalRecords.length <= 0 ? (
          <Box h={"304px"}>
            <NoDataChart />
          </Box>
        ) : chartType === "table" ? (
          <ChartGrid
            marginTop={4}
            data={formattedLeadStatusDataForGrid}
            headings={
              isCompared
                ? [
                    [
                      "Incentive Plan",
                      formattedDateForGrid,
                      formattedPrevDateForGrid,
                    ],
                  ]
                : [["Incentive Plan", formattedDateForGrid]]
            }
          />
        ) : (
          <Flex h={"400px"} direction={"column"} justifyContent={"center"}>
            <Box h={"360px"} overflow={"visible"}>
              <CustomBarChart
                currentDate={conDate}
                data={finalRecords}
                isCompared={isCompared}
                dataType="Commission"
              />
            </Box>
          </Flex>
        )}
      </InnerChartContainer>
    </>
  );
};

export default CommissionIncentiveProgram;
