import {
  Stack,
  Heading,
  SimpleGrid,
  Divider,
  HStack,
  Box,
  Text,
  Flex,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import closeIcon from "../../image/closeBundle.png";
import {
  requiredField,
  invalidEmail,
  invalidUrl,
} from "../../constants/validationMessages";
import {
  urlValidationPattern,
  emailValidationPattern,
} from "../../constants/validationPatterns";
import { useForm, Controller } from "react-hook-form";
import {
  TextBox,
  Select,
  TextArea,
  Button,
  SearchList,
  Loader,
} from "@coachbar/shared-components";
import Notification from "../../components/Notification";
import { clearMessage } from "./referralSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { addReferral } from "./referralActions";
import { useNavigate } from "react-router-dom";
import fallbackImage from "../../image/fallbackImage.png";
import { getAllSoftwareList } from "../softwareSpecialisation/softwareSpecialisationActions";
import { getIndustriesList } from "../serviceOffering/serviceOfferingActions";
import {
  employeeSizeOptions,
  durationOptions,
  deploymentOptions,
  waitingTimeOptions,
} from "../../constants/referralStatus";
import {
  appendHttpsToUrl,
  getCountriesWithCode,
  getCountryList,
  getUserInfo,
} from "../../services/utility";
import { serviceCategoryOptions } from "@coachbar/shared-components/src/constants/icpRequirements";
import CustomTooltip from "@coachbar/shared-components/src/components/Tooltip/CustomTooltip";
import {
  actionRequiredTip,
  bestWayApproachTip,
  currentStackAbTip,
  currentStackTip,
  deploymentPreTip,
  evaluateSoftwareTip,
  requirementOverviewAbTip,
  requirementOverviewTip,
  serviceRequiredAbTip,
  serviceRequiredTip,
  waitingTimeReferralAbTip,
  waitingTimeReferralTip,
} from "../../constants/tooltips";
import CustomPhoneInput from "@coachbar/shared-components/src/screens/PhoneInput";
import CountryDropdown from "@coachbar/shared-components/src/components/CountryDropdown/CountryDropdown";
import {
  companyRevenueOptions,
  dropdownBudgetOptions,
  scrollbarCSS,
} from "@coachbar/shared-components/src/constants/constantValues";
import { replaceCurrencyInLabel } from "@coachbar/shared-components/src/services/utility";

const DEFAULT_REFERRAL_STATUS = "Contacted";
const countryPrefixOptions = getCountriesWithCode();
const countryOptions = getCountryList();

const NewReferral = () => {
  const { referralDetails, loading, addError, addSuccess } = useSelector(
    (state) => state.referral
  );
  const { softwareSpecializationList } = useSelector(
    (state) => state.softwareSpecialisation
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const [countryData, setCountryData] = useState({});

  const submitForm = (data) => {
    const softwareCommaSeparated = selectedSoftware
      .map((feature) => feature.name)
      .join(", ");
    const keyFeaturesCommaSeparated = selectedServiceOptions
      .map((feature) => feature.name)
      .join(", ");
    const EvaluatedCommaSeparated = selectedEvaluatedSoftware
      .map((feature) => feature.name)
      .join(", ");
    data.appStack = softwareCommaSeparated;
    data.requirements = {
      ...data.requirements,
      keyFeatures: keyFeaturesCommaSeparated,
      evaluated: EvaluatedCommaSeparated,
    };
    data.verified = true;
    data.source = "Manual";
    data.status = DEFAULT_REFERRAL_STATUS;
    data.companyProfile.website = appendHttpsToUrl(data.companyProfile.website);
    if (countryData?.name) {
      data.contactInformation.countryCode = `+${countryData?.dialCode}`;
    }
    if (data.contactInformation.country) {
      data.contactInformation.country =
        data?.contactInformation?.country?.value;
    }
    dispatch(addReferral({ requestParams: data })).then((responseData) => {
      if (
        responseData &&
        responseData?.payload?.success &&
        responseData?.payload?.data
      ) {
        localStorage.setItem("referralTab", DEFAULT_REFERRAL_STATUS);
        navigate("/referrals");
      }
    });
  };

  let userInfo = getUserInfo();

  const [reqDescriptionLength, setReqDescriptionLength] = useState(0);
  const [featureDescriptionLength, setFeatureDescriptionLength] = useState(0);
  const [challangesDescriptionLength, setChallangesDescriptionLength] =
    useState(0);
  const [appStackDescriptionLength, setAppStackDescriptionLength] = useState(0);
  const [evaluateDescriptionLength, setEvaluateDescriptionLength] = useState(0);

  const handleReqDescriptionChange = (event) => {
    setReqDescriptionLength(event.target.value.length);
  };
  const handleFeatureDescriptionChange = (event) => {
    setFeatureDescriptionLength(event.target.value.length);
  };
  const handleChallengesDescriptionChange = (event) => {
    setChallangesDescriptionLength(event.target.value.length);
  };
  const handleAppStackDescriptionChange = (event) => {
    setAppStackDescriptionLength(event.target.value.length);
  };
  const handleEvaluateDescriptionChange = (event) => {
    setEvaluateDescriptionLength(event.target.value.length);
  };

  const defaultCountry = countryPrefixOptions.filter(
    (item) => "USA(+1)" === item.value
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isoValue, setISOValue] = useState(defaultCountry[0]["value"]);
  const [industryValue, setIndustryValue] = useState("");
  const [deploymentValue, setDeploymentValue] = useState("");
  const [priceValue, setPriceValue] = useState("");
  const [durationValue, setDurationValue] = useState("");
  const [employeeSizeValue, setEmployeeSizeValue] = useState("");
  const [annualRevenueValue, setAnnualRevenueValue] = useState("");
  const [countrValue, setCountryValue] = useState("");
  const [waitingTimeValue, setWaitingTimeValue] = useState("");
  const [selectedServiceOptions, setSelectedServiceOptions] = useState([]);
  const [serviceOptionList, setServiceOptionList] = useState(
    serviceCategoryOptions
  );
  const [softwareList, setSoftwareList] = useState([]);
  const [softwareEvaluatedList, setSoftwareEvaluatedList] = useState([]);
  const [selectedSoftware, setSelectedSoftware] = useState([]);
  const [selectedEvaluatedSoftware, setSelectedEvaluatedSoftware] = useState(
    []
  );
  const [industriesOptions, setIndustriesOptions] = useState([]);

  useEffect(() => {
    if (softwareSpecializationList) {
      prepareSoftwareList(softwareSpecializationList);
    } else {
      setIsLoading(true);
      dispatch(getAllSoftwareList())
        .then((responseData) => {
          const response = responseData.payload;
          if (response.success && response.data && response.data.softwareList) {
            prepareSoftwareList(response.data.softwareList);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // setIsLoading(true);
    // getSoftwareList()
    //   .then((response) => {
    //     if (response.success && response.data && response.data.softwareList) {
    //       var allSoftwareList = [];
    //       for (const key in response.data.softwareList) {
    //         const listArray = response.data.softwareList[key].map(
    //           (software) => {
    //             return {
    //               ...software,
    //               category: key,
    //             };
    //           }
    //         );
    //         allSoftwareList = [...allSoftwareList, ...listArray];
    //       }
    //       setSoftwareList(allSoftwareList);
    //       setSoftwareEvaluatedList(allSoftwareList);
    //       setIsLoading(false);
    //     } else {
    //       setIsLoading(false);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    getIndustriesList().then((industryResponse) => {
      const industriesOptions = industryResponse?.map((industry) => {
        return {
          // name: industry.name,
          // isChecked: false,
          value: industry.name,
          label: industry.name,
        };
      });
      setIndustriesOptions(industriesOptions);
      setIsLoading(false);
    });
  }, []);

  const prepareSoftwareList = (softwareList) => {
    var allSoftwareList = [];
    const seenNames = new Set();
    // for (const key in softwareList) {
    //   const listArray = softwareList[key].map((software) => {
    //     return {
    //       ...software,
    //       category: key,
    //     };
    //   });
    //   allSoftwareList = [...allSoftwareList, ...listArray];
    // }
    for (const key in softwareList) {
      softwareList[key].map((software) => {
        const softwareWithCategory = {
          ...software,
          category: key,
        };

        // Check if the software name is already seen
        if (!seenNames.has(software.name)) {
          allSoftwareList.push(softwareWithCategory);
          seenNames.add(software.name);
        }

        return softwareWithCategory;
      });
    }
    setSoftwareList(allSoftwareList);
    setSoftwareEvaluatedList(allSoftwareList);
  };

  const onCountryChange = (e) => {
    let value = e.target.value;
    setCountryValue(value);
  };
  const onIndustryChange = (e) => {
    let value = e.target.value;
    setIndustryValue(value);
  };
  const onEmployeeSizeChange = (e) => {
    let value = e.target.value;
    setEmployeeSizeValue(value);
  };
  const onAnnualRevenueChange = (e) => {
    let value = e.target.value;
    setAnnualRevenueValue(value);
  };
  const onDurationChange = (e) => {
    let value = e.target.value;
    setDurationValue(value);
  };
  const onDeploymentChange = (e) => {
    let value = e.target.value;
    setDeploymentValue(value);
  };
  const onPriceChange = (e) => {
    let value = e.target.value;
    setPriceValue(value);
  };
  const onWaitingTimeChange = (e) => {
    let value = e.target.value;
    setWaitingTimeValue(value);
  };

  const onCancel = () => {
    navigate("/referrals");
  };

  const handleCheckboxChange = (newItem, updatedOptions) => {
    setSelectedServiceOptions((prevItems) => {
      const existingItemIndex = prevItems.findIndex(
        (item) => item.name === newItem.name
      );
      if (existingItemIndex !== -1) {
        const updatedItems = [...prevItems];
        updatedItems.splice(existingItemIndex, 1);
        return updatedItems;
      } else {
        return [...prevItems, newItem];
      }
    });
    setServiceOptionList(updatedOptions);
  };

  const handleCheckboxSoftwareChange = (newItem, updatedOptions) => {
    setSelectedSoftware((prevItems) => {
      const existingItemIndex = prevItems.findIndex(
        (item) => item.name === newItem.name
      );
      if (existingItemIndex !== -1) {
        const updatedItems = [...prevItems];
        updatedItems.splice(existingItemIndex, 1);
        return updatedItems;
      } else {
        return [...prevItems, newItem];
      }
    });
    setSoftwareList(updatedOptions);
  };

  const handleCheckboxSoftwareEvaluatedChange = (newItem, updatedOptions) => {
    setSelectedEvaluatedSoftware((prevItems) => {
      const existingItemIndex = prevItems.findIndex(
        (item) => item.name === newItem.name
      );
      if (existingItemIndex !== -1) {
        const updatedItems = [...prevItems];
        updatedItems.splice(existingItemIndex, 1);
        return updatedItems;
      } else {
        return [...prevItems, newItem];
      }
    });
    setSoftwareEvaluatedList(updatedOptions);
  };

  const removeSelection = (
    key,
    value,
    selectedState,
    selectedStateToUpdate,
    listState,
    listStateToUpdate
  ) => {
    const updatedSelection = selectedState.filter(
      (item) => item[key] !== value
    );
    selectedStateToUpdate(updatedSelection);
    const updatedList = listState.map((item) => {
      if (item[key] === value) {
        return { ...item, isChecked: false };
      }
      return item;
    });
    listStateToUpdate(updatedList);
  };

  const popOverContainer = (
    lable,
    tooltip,
    placeHolder,
    dataList,
    functionToCall,
    disable,
    message = "",
    modalBtnClick,
    isModalBtnDisabled = false,
    required = false,
    showImage = true,
    allowSearch = true
  ) => {
    return (
      <Popover
        id={`referrals-new-referral-${
          typeof lable === "string"
            ? lable?.split(" ").join("-").toLowerCase()
            : ""
        }`}
      >
        <PopoverTrigger>
          <button type="button" style={{ width: "100%" }}>
            <Box w="100%" textAlign={"left"}>
              <HStack spacing={1}>
                <Text
                  color={"var(--text-font-color)"}
                  fontSize="12px"
                  fontWeight={"500"}
                >
                  {lable}
                </Text>
                {required ? (
                  <Text color={"#E53E3E"} fontWeight={"500"}>
                    *
                  </Text>
                ) : (
                  ""
                )}
                {tooltip.length > 0 && (
                  <CustomTooltip verticalAlign="sub" content={tooltip} />
                )}
              </HStack>
              <Box
                h={"40px"}
                w={"100%"}
                border={"1px solid"}
                borderColor={"brandGray.20"}
                borderRadius={"4px"}
                p={"5px 10px"}
                cursor={"pointer"}
                bg={"#FFFFFF"}
              >
                <Flex justifyContent={"space-between"}>
                  <Text color={"brandGray.40"}>{placeHolder}</Text>
                  <ChevronDownIcon h={"1.5em"} w={"1.2rem"} />
                </Flex>
              </Box>
            </Box>
          </button>
        </PopoverTrigger>
        <PopoverContent w={"inherit"}>
          <PopoverBody className="full-width-popovers">
            <SearchList
              itemList={dataList}
              handleCheckBoxChange={functionToCall}
              disable={disable}
              message={message}
              showImage={showImage}
              allowSearch={allowSearch}
              fallbackImage={fallbackImage}
              modalBtnClick={modalBtnClick}
              isModalBtnDisabled={isModalBtnDisabled}
              showCategory={true}
              moduleForId={`referral-${
                typeof lable === "string"
                  ? lable?.split(" ").join("-").toLowerCase()
                  : ""
              }`}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  };

  let budgetOptions = dropdownBudgetOptions.map((x) => ({
    ...x,
    label: replaceCurrencyInLabel(x.label),
  }));
  let revenueOptions = companyRevenueOptions.map((x) => ({
    ...x,
    label: replaceCurrencyInLabel(x.label),
  }));

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Stack
          spacing="20px"
          p="24px"
          align="stretch"
          className="full-width-popovers"
          height={"calc(100vh - 64px)"}
          overflowY={"auto"}
          css={scrollbarCSS}
        >
          {addError ? (
            <Notification
              title={addError}
              status={addError && "error"}
              onCloseToast={() => dispatch(clearMessage())}
            />
          ) : (
            ""
          )}
          <form onSubmit={handleSubmit(submitForm)} noValidate>
            <Heading size="md" py={4}>
              Company Profile
            </Heading>
            <SimpleGrid columns={3} spacing={8}>
              <TextBox
                type="text"
                placeholder="Company Name"
                name="Name"
                maxLength="100"
                required
                innerref={register("companyName", {
                  required: requiredField.replace("$Field$", "Company Name"),
                  validate: (value) =>
                    value.trim() !== "" || "Whitespace is not allowed.",
                })}
                validationErrors={errors}
              />
              <TextBox
                type="text"
                placeholder="https://yourcompany.com"
                name="Website"
                maxLength="256"
                innerref={register("companyProfile.website", {
                  pattern: {
                    value: urlValidationPattern,
                    message: invalidUrl,
                  },
                })}
                leftAddOn="https://"
                validationErrors={errors}
              />
              <TextBox
                type="text"
                placeholder="Business Type"
                name="Business Type"
                maxLength="100"
                innerref={register("companyProfile.businessType")}
              />
            </SimpleGrid>
            <SimpleGrid columns={3} spacing={8}>
              <Select
                height={"full"}
                name="Industry"
                value={industryValue}
                placeholder="Industry"
                options={industriesOptions}
                onChange={onIndustryChange}
                style={{ paddingInlineStart: "0.5rem" }}
                innerref={register("companyProfile.industry")}
              />
              <Select
                height={"full"}
                name="Employee Size"
                value={employeeSizeValue}
                placeholder="Employee Size"
                options={employeeSizeOptions}
                onChange={onEmployeeSizeChange}
                style={{ paddingInlineStart: "0.5rem" }}
                innerref={register("companyProfile.companySize")}
              />
              <Select
                height={"full"}
                name="Annual Revenue"
                value={annualRevenueValue}
                placeholder="Annual Revenue"
                options={revenueOptions}
                onChange={onAnnualRevenueChange}
                style={{ paddingInlineStart: "0.5rem" }}
                innerref={register("companyProfile.annualRevenue")}
              />
            </SimpleGrid>
            <Divider borderWidth={"1px"} mt={4} />
            <Heading size="md" py={4}>
              Contact Information
            </Heading>
            <SimpleGrid columns={2} spacing={8}>
              <TextBox
                type="text"
                placeholder="Contact Name"
                name="Name"
                maxLength="100"
                required
                innerref={register("contactInformation.contactName", {
                  required: requiredField.replace("$Field$", "Contact Name"),
                  validate: (value) =>
                    value.trim() !== "" || "Whitespace is not allowed.",
                })}
                validationErrors={errors}
              />
              <TextBox
                type="text"
                placeholder="https://yourcompany.com"
                name="Email Address"
                maxLength="100"
                required
                innerref={register("email", {
                  required: "$Field$ is required".replace(
                    "$Field$",
                    "Email Address"
                  ),
                  pattern: {
                    value: emailValidationPattern,
                    message: invalidEmail,
                  },
                })}
                validationErrors={errors}
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={8}>
              {/* <HStack>
                    <Box minW="125px">
                      <Select
                        height={"full"}
                        name="Country Code"
                        value={isoValue}
                        placeholder="Country Code"
                        options={countryPrefixOptions}
                        onChange={onISOChange}
                        style={{ paddingInlineStart: "0.5rem" }}
                        innerref={register("contactInformation.countryCode")}
                      />
                    </Box>
                    <TextBox
                      type="tel"
                      placeholder="Phone Number"
                      maxLength="15"
                      innerref={register("contactInformation.phone", {
                        pattern: {
                          value: numberPattern,
                          message: numberAllowed,
                        },
                        maxLength: 15,
                      })}
                      validationErrors={errors}
                    />
                  </HStack> */}
              <Controller
                name="contactInformation.phone"
                control={control}
                render={({ field }) => (
                  <CustomPhoneInput
                    value={field.value}
                    onChange={(value, country, event, formattedValue) => {
                      setCountryData(country);
                      field.onChange(formattedValue);
                    }}
                    countryOrder={["us", "ca", "gb", "au", "nz", "sg"]}
                  />
                )}
              />
              <TextBox
                type="text"
                placeholder="Address"
                name="Address"
                maxLength="300"
                innerref={register("contactInformation.address")}
              />
            </SimpleGrid>
            <SimpleGrid columns={3} spacing={8}>
              {/* <Select
                    height={"full"}
                    name="Country"
                    value={countrValue}
                    placeholder="Country"
                    options={countryOptions}
                    onChange={onCountryChange}
                    style={{ paddingInlineStart: "0.5rem" }}
                    innerref={register("contactInformation.country")}
                  /> */}
              <Controller
                control={control}
                name="contactInformation.country"
                defaultValue={null}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CountryDropdown
                    options={countryOptions}
                    placeholder="Country"
                    onChange={(selectedCountry) => {
                      onChange(selectedCountry);
                    }}
                    name={name}
                    size="md"
                    value={value}
                    innerRef={ref}
                  />
                )}
              />
              <TextBox
                type="text"
                placeholder="Contact Role"
                name="Contact Role"
                maxLength="100"
                innerref={register("contactInformation.contactRole")}
              />
              <Select
                height={"full"}
                name="Turnaround Time"
                value={waitingTimeValue}
                placeholder="Turnaround Time"
                options={waitingTimeOptions}
                onChange={onWaitingTimeChange}
                style={{ paddingInlineStart: "0.5rem" }}
                innerref={register("contactInformation.waitingTime")}
                tooltip={
                  userInfo.userType === 3
                    ? waitingTimeReferralAbTip
                    : waitingTimeReferralTip
                }
              />
            </SimpleGrid>
            <Divider borderWidth={"1px"} mt={4} />
            <Heading size="md" py={4}>
              Requirement Overview
              {/* <CustomTooltip
                    content={
                      userInfo.userType === 3
                        ? requirementOverviewAbTip
                        : requirementOverviewTip
                    }
                  /> */}
            </Heading>
            <SimpleGrid columns={1} spacing={8}>
              <TextArea
                name="Requirement"
                // required
                innerref={register("overview", {
                  // required: requiredField.replace("$Field$", "Requirements"),
                  onChange: (e) => handleReqDescriptionChange(e),
                })}
                maxLength={1000}
                helperText={`${reqDescriptionLength}/1000`}
                helperTextPosition={"right"}
                validationErrors={errors}
                content={
                  userInfo.userType === 3
                    ? requirementOverviewAbTip
                    : requirementOverviewTip
                }
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={8}>
              {serviceOptionList && serviceOptionList.length > 0 && (
                <Box position={"relative"} w={"100%"}>
                  {popOverContainer(
                    "Service Required",
                    userInfo?.userType === 3
                      ? serviceRequiredAbTip
                      : serviceRequiredTip,
                    "Service Required",
                    serviceOptionList,
                    handleCheckboxChange,
                    false,
                    "",
                    null,
                    false,
                    false,
                    false,
                    true
                  )}
                  <Flex
                    display={
                      selectedServiceOptions &&
                      selectedServiceOptions?.length > 0
                        ? "flex"
                        : "none"
                    }
                    gap={"1rem"}
                    my={2}
                    flexWrap="wrap"
                  >
                    {selectedServiceOptions.map((currentOption) => {
                      return (
                        <Box
                          position="relative"
                          display="inline-block"
                          key={currentOption.name}
                        >
                          <Box
                            bg="#E2E8F0"
                            height="29px"
                            p="3px 10px 0px 10px"
                            borderRadius="6px"
                            flex="1 0 auto"
                            maxWidth="max-content"
                          >
                            {currentOption.name}
                          </Box>
                          <Image
                            src={closeIcon}
                            w={"1rem"}
                            h={"1rem"}
                            size="sm"
                            variant="ghost"
                            colorScheme="gray"
                            position="absolute"
                            top={"-7px"}
                            right={"-7px"}
                            cursor={"pointer"}
                            onClick={() =>
                              removeSelection(
                                "name",
                                currentOption.name,
                                selectedServiceOptions,
                                setSelectedServiceOptions,
                                serviceOptionList,
                                setServiceOptionList
                              )
                            }
                          />
                        </Box>
                      );
                    })}
                  </Flex>
                </Box>
              )}
              <TextArea
                name="Challenges"
                // required
                innerref={register("requirements.reason", {
                  // required: requiredField.replace("$Field$", "Challenges"),
                  onChange: (e) => handleChallengesDescriptionChange(e),
                })}
                maxLength={2500}
                helperText={`${challangesDescriptionLength}/2500`}
                helperTextPosition={"right"}
                validationErrors={errors}
                content="Refers to the Referral’s Pain points that needs to be addressed"
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={8}>
              {softwareList && softwareList.length > 0 && (
                <Box position={"relative"} w={"100%"}>
                  {popOverContainer(
                    "Current App Stack",
                    userInfo?.userType === 3
                      ? currentStackAbTip
                      : currentStackTip,
                    "Current App Stack",
                    softwareList,
                    handleCheckboxSoftwareChange,
                    false,
                    "",
                    null,
                    false,
                    false,
                    true,
                    true
                  )}
                  <Flex
                    display={
                      selectedSoftware && selectedSoftware?.length > 0
                        ? "flex"
                        : "none"
                    }
                    gap={"1rem"}
                    my={2}
                    flexWrap="wrap"
                  >
                    {selectedSoftware.map((currentOption) => {
                      return (
                        <Box
                          position="relative"
                          display="inline-block"
                          key={currentOption.name}
                        >
                          <Box
                            bg="#E2E8F0"
                            height="29px"
                            p="3px 10px 0px 10px"
                            borderRadius="6px"
                            flex="1 0 auto"
                            maxWidth="max-content"
                          >
                            {currentOption.name}
                          </Box>
                          <Image
                            src={closeIcon}
                            w={"1rem"}
                            h={"1rem"}
                            size="sm"
                            variant="ghost"
                            colorScheme="gray"
                            position="absolute"
                            top={"-7px"}
                            right={"-7px"}
                            cursor={"pointer"}
                            onClick={() =>
                              removeSelection(
                                "name",
                                currentOption.name,
                                selectedSoftware,
                                setSelectedSoftware,
                                softwareList,
                                setSoftwareList
                              )
                            }
                          />
                        </Box>
                      );
                    })}
                  </Flex>
                </Box>
              )}
              {softwareEvaluatedList && softwareEvaluatedList.length > 0 && (
                <Box position={"relative"} w={"100%"}>
                  {popOverContainer(
                    "Evaluated Software",
                    evaluateSoftwareTip,
                    "Evaluated Software",
                    softwareEvaluatedList,
                    handleCheckboxSoftwareEvaluatedChange,
                    false,
                    "",
                    null,
                    false,
                    false,
                    true,
                    true
                  )}
                  <Flex
                    display={
                      selectedEvaluatedSoftware &&
                      selectedEvaluatedSoftware?.length > 0
                        ? "flex"
                        : "none"
                    }
                    gap={"1rem"}
                    my={2}
                    flexWrap="wrap"
                  >
                    {selectedEvaluatedSoftware.map((currentOption) => {
                      return (
                        <Box
                          position="relative"
                          display="inline-block"
                          key={currentOption.name}
                        >
                          <Box
                            bg="#E2E8F0"
                            height="29px"
                            p="3px 10px 0px 10px"
                            borderRadius="6px"
                            flex="1 0 auto"
                            maxWidth="max-content"
                          >
                            {currentOption.name}
                          </Box>
                          <Image
                            src={closeIcon}
                            w={"1rem"}
                            h={"1rem"}
                            size="sm"
                            variant="ghost"
                            colorScheme="gray"
                            position="absolute"
                            top={"-7px"}
                            right={"-7px"}
                            cursor={"pointer"}
                            onClick={() =>
                              removeSelection(
                                "name",
                                currentOption.name,
                                selectedEvaluatedSoftware,
                                setSelectedEvaluatedSoftware,
                                softwareEvaluatedList,
                                setSoftwareEvaluatedList
                              )
                            }
                          />
                        </Box>
                      );
                    })}
                  </Flex>
                </Box>
              )}
            </SimpleGrid>
            <SimpleGrid columns={3} spacing={8} mt={4}>
              <Select
                height={"full"}
                name="Project Duration"
                value={durationValue}
                placeholder="Project Duration"
                options={durationOptions}
                onChange={onDurationChange}
                style={{ paddingInlineStart: "0.5rem" }}
                innerref={register("requirements.timeline")}
              />
              <Select
                height={"full"}
                name="budget"
                value={priceValue}
                placeholder="Budget"
                options={budgetOptions}
                onChange={onPriceChange}
                style={{ paddingInlineStart: "0.5rem" }}
                innerref={register("budget")}
              />
              <Select
                height={"full"}
                name="Implementation Strategy Preferences"
                value={deploymentValue}
                placeholder="Implementation Strategy Preferences"
                options={deploymentOptions}
                onChange={onDeploymentChange}
                style={{ paddingInlineStart: "0.5rem" }}
                innerref={register("requirements.deployment")}
                tooltip={deploymentPreTip}
              />
            </SimpleGrid>
            <Divider borderWidth={"1px"} mt={4} />
            <Heading size="md" py={4}>
              Next Step
            </Heading>
            <SimpleGrid columns={2} spacing={8}>
              <TextBox
                type="text"
                placeholder="Action Required"
                name="Action Required"
                maxLength="250"
                innerref={register("nextStep.actionRequired")}
                tooltip={userInfo?.userType === 3 ? false : actionRequiredTip}
              />
              <TextBox
                type="text"
                placeholder="Follow Up Instruction"
                name="Follow Up Instruction"
                maxLength="250"
                innerref={register("nextStep.instructions")}
              />
            </SimpleGrid>
            <SimpleGrid columns={2} spacing={8}>
              <TextBox
                type="text"
                placeholder="Availability"
                name="Availability"
                maxLength="100"
                innerref={register("nextStep.availability")}
              />
              <TextBox
                type="text"
                placeholder="Best Way to Approach"
                name="Best Way to Approach"
                maxLength="100"
                innerref={register("nextStep.approach")}
                tooltip={userInfo?.userType === 3 ? false : bestWayApproachTip}
              />
            </SimpleGrid>
            <HStack justifyContent={"end"} gap={"20px"}>
              <Button
                onClick={onCancel}
                variant={"discard"}
                isLoading={loading}
                title="Cancel"
                id="referral-form-cancel"
              />
              <Button
                type="submit"
                isLoading={loading}
                title="Save Referral"
                m="0px !important"
                id="referral-form-submit"
              />
            </HStack>
          </form>
        </Stack>
      )}
    </>
  );
};

export default NewReferral;
