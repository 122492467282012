export const noLocationsAdded = "No locations added yet.";
export const noVideoAdded = "No video added yet.";
export const noMediaAdded = "No media assets added yet.";
export const noListingURLAdded = "No listing URL added yet.";
export const noClientsAdded = "No clients added yet.";
export const noServicesAdded = "No services added yet.";
export const noIndustriesSelected = "No industries selected yet.";
export const noReferralsStatus = "No referrals in this status yet.";
export const noTransactionsCreated = "No transactions created yet.";
export const noSoftwareConsultants = "No software consultants found yet.";
export const noSpecialisationConfiguration =
  "No Specialization Configuration available.";
export const industriesSupport = "Select the industries which you support.";
export const activeSpecialisation = "Published System Grids";
export const changePasswordMsg =
  "Password changed successfully. Please log in again.";
export const setPasswordMsg = "Your password has been changed successfully!";
