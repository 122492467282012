import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../services/axiosInstance";
import { getErrorMessage } from "../../../services/utility";

export const addMedia = createAsyncThunk(
  "add/media",
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("/media/add", requestData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getAllMedia = createAsyncThunk(
  "get/allMedia",
  async (mediaId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/media`);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateMedia = createAsyncThunk(
  "update/media",
  async ({ mediaId, requestParams }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(
        `/media/${mediaId}`,
        requestParams
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const deleteMedia = createAsyncThunk(
  "delete/media",
  async (mediaId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(`/media/${mediaId}`);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getPartnerMediaList = createAsyncThunk(
  "get/partner/media",
  async (partnerTenantId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("/media", {
        params: {
          limit: 100,
          partnerTenantId,
        },
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
