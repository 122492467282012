import { axiosInstance } from "../../../services/axiosInstance";
import { handleError } from "../../../services/utility";

export const getFinancialLeaderBoardData = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "/sp/dashboard/finance/leaderboard",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getSalesPipelineData = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "/sp/dashboard/finance/salesPipeline",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getRevenueRegion = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "/sp/dashboard/finance/region",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getRevenueRegionTrend = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/finance/revenueByRegionTrend",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getRevenueProduct = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "/sp/dashboard/finance/revenueByProducts",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getRevenueCustomerType = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "/sp/dashboard/finance/revenueByCustomerType",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getRevenueProductTrend = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/finance/revenueByProductTrend",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getRecurringRevenueTrend = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/finance/recurringAndNewRevenueTrend",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getOpenDealRevenueTrend = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/finance/openDealsRevenueTrend",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getWonDealRevenueTrend = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/finance/wonDealsRevenueTrend",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getAvgLeadWinTrend = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "/sp/dashboard/finance/leadToWinTrends",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getEstimatedCommission = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/finance/totalEstimatedCommission",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getAvgLeadDealCommission = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/finance/avgCommissionLeadDeal",
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};

export const getCommissionByIncentiveProgram = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/finance/commissionByIncentiveProgramForSc",
      requestData
    );
    return data;
  } catch (error) {}
};

export const getCommissionTrendByTimePeriod = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/finance/commissionTrendByTimePeriod",
      requestData
    );
    return data;
  } catch (error) {}
};

export const getClawBackData = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/finance/clawbackAmount",
      requestData
    );
    return data;
  } catch (error) {}
};

export const getRevenueWithEstimatedCommission = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/finance/revenueWithEstimatedCommission",
      requestData
    );
    return data;
  } catch (error) {}
};

export const getTotalCommission = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      "dashboard/finance/totalCommission",
      requestData
    );
    return data;
  } catch (error) {}
};
