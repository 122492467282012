import { useEffect, useState } from "react";
import {
  AspectRatio,
  Box,
  Container,
  Input,
  Stack,
  Text,
  FormLabel,
  FormControl,
  Image,
  HStack,
  Center,
  Flex,
} from "@chakra-ui/react";
import { fromEvent } from "file-selector";
import fileUploadIcon from "../../images/fileUploadIcon.png";
import spinnerIcon from "../../images/spinnerIcon.png";
import folderIcon from "../../images/brand-folder-icon.png";
import checkedCircle from "../../images/check-circle.png";
import warningIcon from "../../images/warningIcon.png";
import deleteIcon from "../../images/delete.svg";
import downloadIcon from "../../images/download.png";

export default function FolderUpload({
  assetErrorMsg,
  note,
  hideLabel,
  validFile,
  fileIcon,
  value,
  validFileList,
  invalidFileList,
  rootFolderNames,
  downloadInvalidFiles,
  onDeleteClick,
  onChange,
  onDrop,
  required,
  ...props
}) {
  // const validation = validationErrors && validationErrors?.[innerref?.name];
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    let intervalId;
    if (props?.isLoading) {
      intervalId = setInterval(() => {
        setRotation((prevRotation) => (prevRotation + 15) % 360);
      }, 100);
    } else {
      setRotation(0);
    }
    return () => clearInterval(intervalId); // Clear the interval on component unmount or when isLoading is false
  }, [props?.isLoading]);

  return (
    <Container
      pl={"0px"}
      pr={"0px"}
      m={"0px"}
      maxWidth={props.boxWidth ? props.boxWidth : "100%"}
      cursor={props.isDisabled ? "not-allowed" : "pointer"}
      opacity={props.isDisabled ? 0.5 : 1}
    >
      <FormControl
        name={props.name}
        isInvalid={assetErrorMsg ? true : false}
        isRequired={required}
        pointerEvents={props.isDisabled ? "none" : "all"}
      >
        {!hideLabel && (
          <FormLabel
            as="legend"
            color={"#5E6977"}
            fontWeight={600}
            mb={"4px"}
            fontSize={"12px"}
          >
            {props.name}
          </FormLabel>
        )}
        <AspectRatio
          width={props.boxWidth ? props.boxWidth : "100%"}
          ratio={props.ratio ? props.ratio : 21 / 9}
        >
          <Box
            borderColor={
              assetErrorMsg
                ? "#C81E1E"
                : rootFolderNames?.length
                ? "#9ED4DE"
                : "#BFC3C9"
            }
            borderStyle="dashed"
            borderWidth="1px"
            rounded="4px"
            shadow="sm"
            role="group"
            transition="all 150ms ease-in-out"
            initial="rest"
            animate="rest"
            bg={rootFolderNames?.length ? "#E7FAFD" : "white"}
          >
            {props.isLoading ? (
              <Center height={"inherit"} width={"inherit"}>
                <Image
                  src={spinnerIcon}
                  boxSize={"18px"}
                  transform={`rotate(${rotation}deg)`}
                  transition="transform 0.1s ease-in-out"
                />
              </Center>
            ) : assetErrorMsg ? (
              <Flex
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={2}
              >
                <Image h={"16px"} src={warningIcon} />
                <Text fontSize={"12px"} fontWeight={500} color={"#C81E1E"}>
                  {assetErrorMsg}
                </Text>
              </Flex>
            ) : !validFile && (!validFileList || validFileList?.length < 1) ? (
              <Stack alignItems={"center"} px={2}>
                <Image src={fileUploadIcon} boxSize={"18px"} />
                <HStack>
                  <Text
                    color={"#0C94AC"}
                    fontWeight={500}
                    fontSize={"12px"}
                    lineHeight={"18px"}
                    mt={"0 !important"}
                    mr={1}
                  >
                    Click to Upload
                  </Text>
                  <Text
                    color={"#BFC3C9"}
                    fontWeight={500}
                    fontSize={"12px"}
                    lineHeight={"18px"}
                    mt={"0 !important"}
                    ml={"0 !important"}
                  >
                    or Drag & Drop
                  </Text>
                </HStack>
                {note && (
                  <Text
                    color={"#9EA5AD"}
                    fontSize={"10px"}
                    lineHeight={"16px"}
                    fontWeight={400}
                    textAlign={"center"}
                  >
                    {note}
                  </Text>
                )}
              </Stack>
            ) : (
              <Flex
                justifyContent={"center"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Flex
                  alignItems={rootFolderNames?.length === 1 ? "center" : "end"}
                  flexDirection={
                    rootFolderNames?.length === 1 ? "column" : "row"
                  }
                  mb={"16px"}
                  gap={rootFolderNames?.length === 1 ? "" : "6px"}
                >
                  <Image
                    h={"24px"}
                    src={fileIcon || folderIcon}
                    mb={rootFolderNames?.length === 1 ? "8px" : ""}
                  />
                  <Text fontSize={"14px"} fontWeight={500} color={"#111A29"}>
                    {rootFolderNames?.length === 1
                      ? rootFolderNames[0]
                      : `${rootFolderNames?.length} Folders Selected`}
                  </Text>
                </Flex>
                {!fileIcon && (
                  <Flex justifyContent={"center"} mb={"16px"} gap={"4px"}>
                    <Image h={"16px"} src={checkedCircle} />
                    <Text
                      fontSize={"12px"}
                      fontWeight={500}
                      color={"#2C885C"}
                      mr={"12px"}
                    >
                      Successfully imported:{" "}
                      <span style={{ color: "#111A29" }}>
                        {validFileList?.length || 0}
                      </span>
                    </Text>
                    <Image h={"16px"} src={warningIcon} />
                    <Text fontSize={"12px"} fontWeight={500} color={"#C81E1E"}>
                      Skipped Files:{" "}
                      <span style={{ color: "#111A29" }}>
                        {invalidFileList?.length || 0}
                      </span>
                    </Text>
                    {invalidFileList?.length > 0 && (
                      <Image
                        ml={"4px"}
                        h={"16px"}
                        src={downloadIcon}
                        onClick={downloadInvalidFiles}
                      />
                    )}
                  </Flex>
                )}

                <Flex
                  justifyContent={"center"}
                  gap={"4px"}
                  onClick={onDeleteClick}
                >
                  <Image h={"16px"} src={deleteIcon} />
                  <Text fontSize={"12px"} fontWeight={500} color={"#5E6977"}>
                    {`Remove ${fileIcon ? "file" : "folder"}`}
                  </Text>
                </Flex>
              </Flex>
            )}
            {(!rootFolderNames || rootFolderNames?.length === 0) && (
              <Input
                disabled={props.isLoading}
                type="file"
                height="100%"
                width="100%"
                position="absolute"
                top="0"
                left="0"
                opacity="0 !important"
                aria-hidden="true"
                value={value}
                onChange={async (e) => {
                  const files = await fromEvent(e);
                  onChange(files);
                }}
                onDrop={async (e) => {
                  const files = await fromEvent(e);
                  onDrop(files, true);
                }}
                cursor={"pointer"}
                accept={
                  props.acceptedFileTypes ? props.acceptedFileTypes : "image/*"
                }
                multiple={props.isMultiple ? true : false}
                directory={props?.allowFoldersOnly ? "" : null}
                webkitdirectory={props?.allowFoldersOnly ? "" : null}
                isDisabled={props.isDisabled ? props.isDisabled : false}
              />
            )}
          </Box>
        </AspectRatio>
      </FormControl>
    </Container>
  );
}
