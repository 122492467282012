// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  addCompanyProfile,
  getCompanyProfile,
  updateCompanyProfile,
  searchCompanyProfile,
} from "./companyProfileActions";

export const LOADER_TYPE = {
  IMAGE: "imageUploading",
};

const initialState = {
  triggerSubmit: false,
  loading: false,
  [LOADER_TYPE.IMAGE]: false,
  error: null,
  success: null,
  companyProfile: null,
  companyProfileMatch: [],
};

const companyProfileSlice = createSlice({
  name: "companyProfile",
  initialState,
  reducers: {
    clearMessage: (state) => {
      state.error = null;
      state.success = null;
    },
    updateTriggerSubmit: (state) => {
      state.triggerSubmit = !state.triggerSubmit;
    },
    updateWorldWideValue: (state, { payload }) => {
      if (state.companyProfile) state.companyProfile.worldwide = payload;
    },
    setCompanyProfileLoader: (state, { payload: { key, value } }) => {
      if (key) state[key] = value;
    },
  },
  extraReducers: {
    // add company profile
    [addCompanyProfile.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addCompanyProfile.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
      if (payload.data && payload.success) {
        state.companyProfile = payload.data.profile;
      }
    },
    [addCompanyProfile.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get company profile
    [getCompanyProfile.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCompanyProfile.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
      if (payload.data && payload.success) {
        state.companyProfile = payload.data?.profile || null;
      }
    },
    [getCompanyProfile.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // update company profile
    [updateCompanyProfile.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateCompanyProfile.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
      if (payload.data && payload.success) {
        state.companyProfile = payload.data.profile;
      }
    },
    [updateCompanyProfile.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // search company profile
    [searchCompanyProfile.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [searchCompanyProfile.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
      if (payload.data && payload.success) {
        state.companyProfile = payload.data?.profiles || null;
      }
    },
    [searchCompanyProfile.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
  },
});

export const {
  clearMessage,
  updateTriggerSubmit,
  updateWorldWideValue,
  setCompanyProfileLoader,
} = companyProfileSlice.actions;

export default companyProfileSlice.reducer;
