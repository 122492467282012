import {
  Text,
  HStack,
  Switch,
  useDisclosure,
  Box,
  Flex,
  Image,
  Spacer,
} from "@chakra-ui/react";
import {
  ACTION_ICON_BUTTON_TYPE,
  ActionIconButton,
  AlertDialog,
  Button,
  Loader,
  Table,
  TableTextWithEllipsis,
  TipsLinkButton,
} from "@coachbar/shared-components";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, removeIcpFromList } from "./icpSlice";
import { deleteIcp, getIcps, updateIcpStatus } from "./icpActions";
import Notification from "../../components/Notification";
import { convertDate } from "../../services/utility";
import { icpTitle, noDataText } from "../../constants/icpRequirements";
import { getProfileCompletionStatus } from "../profileSetup/profileMetrics/profileMetricsAction";
import CustomTooltip from "@coachbar/shared-components/src/components/Tooltip/CustomTooltip";
import { icpTitleTip } from "../../constants/tooltips";
import { useRBAC } from "../../services/customHook";
import { icpModule } from "../../constants/moduleNames";
import {
  buttonVariants,
  scrollbarCSS,
} from "@coachbar/shared-components/src/constants/constantValues";
import InfoOutline from "../../image/Info-outline.svg";
import ProfileNoRecordsFound from "../../components/ProfileNoRecordsFound";
import emptyURL from "../../image/emptyURL.png";

const tableHead = [["Name", "Created", "Last Modified", " Is Active"]];
const TIPS_LINK_PAGE_ICP =
  "https://support.coachbar.io/support/solutions/articles/153000018988-getting-started-guide-software-consultant#ICP-(Ideal-Customer-profile)";

const IcpList = () => {
  const { hasActionPermission } = useRBAC();
  let moduleName = icpModule;
  let permissions = {
    add: hasActionPermission(moduleName, "add"),
    edit: hasActionPermission(moduleName, "edit"),
    view: hasActionPermission(moduleName, "view"),
    delete: hasActionPermission(moduleName, "delete"),
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error, icpList, success } = useSelector(
    (state) => state.icp
  );
  const {
    onOpen: onAlertOpen,
    isOpen: isAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  const [tableBody, setTableBody] = useState([]);
  const [icpIdToDelete, setIcpIdToDelete] = useState(null);
  const [disabledSwitches, setDisabledSwitches] = useState([]);

  const onAlertCloseAction = () => {
    if (icpIdToDelete) {
      dispatch(deleteIcp(icpIdToDelete)).then((response) => {
        if (response.payload.success && response.payload.data) {
          dispatch(removeIcpFromList(icpIdToDelete));
          dispatch(getProfileCompletionStatus());
          setIcpIdToDelete(null);
        }
      });
    }
    onAlertClose();
  };

  const onAlertOpenAction = (event, icpDetails) => {
    setIcpIdToDelete(icpDetails?.id);
    onAlertOpen();
  };

  const handleStatusChange = (icpId, value) => {
    setDisabledSwitches([...disabledSwitches, icpId]);

    dispatch(
      updateIcpStatus({
        icpId,
        requestParams: {
          active: value,
        },
      })
    ).then((response) => {
      if (response.payload.success && response.payload.data) {
        setDisabledSwitches(disabledSwitches.filter((id) => id !== icpId));
      }
    });
  };

  const getActions = (icpData = null, index) => {
    return (
      <HStack key={"HStack-" + index} float="right" gap={"20px"}>
        {permissions.edit && (
          <ActionIconButton
            type={ACTION_ICON_BUTTON_TYPE.EDIT}
            onClickAction={(e) =>
              navigate(`/profile-setup/icp/edit/${icpData?.id}`)
            }
          />
        )}
        {permissions.delete && (
          <ActionIconButton
            type={ACTION_ICON_BUTTON_TYPE.DELETE}
            onClickAction={(e) => onAlertOpenAction(e, icpData)}
            containerProps={{ marginLeft: "0px !important" }}
          />
        )}
      </HStack>
    );
  };

  const getSwitch = (icpData = null) => {
    const icpId = icpData?.id;
    const isSwitchDisabled = disabledSwitches.includes(icpId);
    return (
      <Switch
        size="md"
        isChecked={icpData?.active}
        isDisabled={isSwitchDisabled || !permissions.edit}
        onChange={(e) => handleStatusChange(icpId, e?.target?.checked)}
        colorScheme="brand"
      />
    );
  };

  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      dispatch(getIcps());
    }
  }, []);

  useEffect(() => {
    let tBody = [];
    for (let index = 0; index < icpList?.length; index++) {
      let icpItem = [];
      const convertedCreatedAtDate = convertDate(icpList[index]["created"]);
      const convertedModifiedAtDate = convertDate(icpList[index]["modified"]);
      icpItem.push(
        <TableTextWithEllipsis maxChars={78} text={icpList[index]["title"]} />
      );
      icpItem.push(<Text whiteSpace={"nowrap"}>{convertedCreatedAtDate}</Text>);
      icpItem.push(
        <Text whiteSpace={"nowrap"}>{convertedModifiedAtDate}</Text>
      );
      icpItem.push(getSwitch(icpList[index]));
      if (permissions.edit || permissions.delete)
        icpItem.push(getActions(icpList[index], index));
      tBody.push(icpItem);
    }
    setTableBody([...tBody]);
  }, [icpList, disabledSwitches]);

  let headers = tableHead[0];
  if (permissions.edit || permissions.delete) headers = [...headers, ""];

  return (
    <Box px={"24px"} py={"24px"} bg={"#F9FAFB"} w={"full"}>
      <Box
        bg={"white"}
        p={"16px 0 24px"}
        w={"100%"}
        h={"calc(100vh - 112px)"}
        rounded={"8px"}
        overflow={"auto"}
        boxShadow={"1px 0px 4px #E5E7EB"}
        css={scrollbarCSS}
      >
        {error || success ? (
          <Notification
            title={error ? error : success}
            status={error ? "error" : "success"}
            onCloseToast={() => dispatch(clearMessage())}
          />
        ) : (
          ""
        )}
        <Flex
          alignItems={"center"}
          justifyContent={"space-between"}
          p="0 24px"
          mb={"16px"}
        >
          <Text
            color={"var(--title-black-text)"}
            fontSize={"14px"}
            lineHeight={"20px"}
            fontWeight={"700"}
            mr={"8px"}
          >
            {icpTitle}
          </Text>
          <CustomTooltip content={icpTitleTip}>
            <Image
              display={"inline-block"}
              cursor={"pointer"}
              src={InfoOutline}
              w={"20px"}
              mt={"6px"}
            />
          </CustomTooltip>
          <Text
            as={"span"}
            fontWeight={"500"}
            color={"var(--text-font-color)"}
            fontSize={"12px"}
            pt={"2px"}
            ml="6px"
          >
            (Maximum limit 10)
          </Text>
          <Spacer />
          <TipsLinkButton to={TIPS_LINK_PAGE_ICP} />
          {tableBody.length > 0 && permissions.add && (
            <Button
              title={"Add ICP"}
              onClick={() => navigate("/profile-setup/icp/add")}
              isDisabled={tableBody.length >= 10 ? true : false}
              isLoading={loading}
              variant={buttonVariants.brandPrimary}
              ml="20px"
              id="icp-add-form-redirect"
            />
          )}
        </Flex>
        {loading ? (
          <Loader />
        ) : tableBody.length < 1 ? (
          <Flex
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            flex={1}
          >
            <ProfileNoRecordsFound
              title="No ICP Found"
              description={permissions.add ? noDataText : ""}
              imageUrl={emptyURL}
            />
            {permissions.add && (
              <Button
                title="Add ICP"
                onClick={() => navigate("/profile-setup/icp/add")}
                variant={buttonVariants.brandPrimary}
                rounded="8px"
                id="icp-add-form-redirect"
              />
            )}
          </Flex>
        ) : (
          <Box m={"8px 24px 0"}>
            <Table
              tHead={[headers]}
              tBody={tableBody}
              headingStyles={{
                backgroundColor: "#F9FAFB",
                color: "#5E6977 !important",
              }}
              keepBordersRounded
              keepLastColumnLeftAligned={
                !permissions.edit || !permissions.delete
              }
            />
          </Box>
        )}
        <AlertDialog
          title={`Delete ${icpTitle}`}
          actionButtonName="Delete"
          isAlertOpen={isAlertOpen}
          onAlertClose={onAlertClose}
          onAlertCloseAction={onAlertCloseAction}
        />
      </Box>
    </Box>
  );
};

export default IcpList;
