// CreateAccount.js
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button, TextBox, Link } from "@coachbar/shared-components";
import { Text, HStack, Divider, Box } from "@chakra-ui/react";
import {
  requiredField,
  invalidEmail,
} from "../../constants/validationMessages";
import { emailValidationPattern } from "../../constants/validationPatterns";
import { checkEmailExists, registerUserViaGoogle } from "./authActions";
import { useClearState } from "../../services/customHook.js";
import { clearMessage } from "./authSlice";
import Notification from "../../components/Notification";
import {
  claimProfileRoute,
  profileRoute,
} from "../../constants/redirectionRoutes";
import { GoogleLogin } from "@react-oauth/google";

const CreateAccount = (props) => {
  const { loading, userInfo, error } = useSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useClearState();

  useEffect(() => {
    if (
      userInfo &&
      userInfo.hasOwnProperty("isClaimed") &&
      userInfo.isClaimed === false
    ) {
      navigate(claimProfileRoute);
    } else if (userInfo) {
      navigate(profileRoute);
    }
  }, [navigate, userInfo]);

  const submitForm = (data) => {
    let email = data.email || "";
    dispatch(checkEmailExists(email)).then((responseData) => {
      if (responseData.payload.success && responseData.payload.code === 200) {
        props.setUserData(data);
      }
    });
  };

  const createUserViaGoogle = (data) => {
    dispatch(registerUserViaGoogle(data)).then((responseData) => {});
  };

  const responseMessage = (response) => {
    if (response && response.credential) {
      createUserViaGoogle({ googleIdToken: response.credential });
    }
  };
  return (
    <form onSubmit={handleSubmit(submitForm)} noValidate>
      {error ? (
        <Notification
          time={error ? 7000 : 3000}
          title={error}
          status={error ? "error" : "success"}
          onCloseToast={() => dispatch(clearMessage())}
        />
      ) : (
        ""
      )}
      <Box>
        <Text
          fontSize="20px"
          lineHeight={"30px"}
          fontWeight="700"
          mb={"8px !important"}
          color="var(--title-black-text)"
          textAlign="center"
        >
          Get started with Coachbar
        </Text>
        <Text
          color="#7E8792"
          textAlign="center"
          fontSize={"14px"}
          fontWeight={400}
          lineHeight={"20px"}
          mb={"32px !important"}
        >
          Enter your details below to start your account and get started.
        </Text>
        <GoogleLogin
          onSuccess={responseMessage}
          width={400}
          shape="square"
          theme="outline"
          logo_alignment="center"
          text="signup_with"
        />
        <HStack spacing={2} my={"36px !important"}>
          <Divider borderColor={"#d4e1fb"} />
          <Text
            whiteSpace="nowrap"
            fontWeight="500"
            color="#7E8792"
            fontSize={"14px"}
          >
            Or continue with
          </Text>
          <Divider borderColor={"#d4e1fb"} />
        </HStack>
        <TextBox
          type="text"
          placeholder="Email"
          required
          innerref={register("email", {
            required: requiredField.replace("$Field$", "Email"),
            pattern: {
              value: emailValidationPattern,
              message: invalidEmail,
            },
          })}
          validationErrors={errors}
        />
        <Box mt={"20px"}>
          <Button
            colorScheme="Brand.100"
            type="submit"
            isLoading={loading}
            title="Continue With Email"
            w={"100%"}
            id="create-account-with-email-submit"
          />
          <Text
            mt={"16px"}
            color="#7E8792"
            fontSize="12px"
            fontWeight={400}
            textAlign="center"
          >
            By signing up, you agree to{" "}
            <Link
              to={"https://coachbar.io/terms-and-conditions"}
              target="_blank"
              title="terms of services"
            />
          </Text>
          <Text
            color="var(--title-black-text)"
            fontWeight={400}
            fontSize="16px"
            textAlign="center"
            mt={"40px"}
          >
            Already have an account?&nbsp;
            <Link color="#0C94AC" fontWeight={600} to="/login" title="Login" />
          </Text>
        </Box>
      </Box>
    </form>
  );
};
export default CreateAccount;
