import { Component } from "react";
import { Button } from "@coachbar/shared-components";
import { Center, Image, Text, SimpleGrid, Box, Link } from "@chakra-ui/react";
import sorryImg from "../images/sorry.png";

export default class ErrorBoundary extends Component {
  state = {
    error: "",
    errorInfo: "",
    hasError: false,
    url: "",
    showErrorPage: true
  };
  static getDerivedStateFromError(error) {
    return { hasError: true, error, url: window.location.href };
  }
  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    this.setState({ errorInfo });
  }
  render() {
    if (this.state.hasError && this.state.url !== window.location.href) {
      window.location.reload(false);
    }

    if (this.state.error && this.state.error?.name === "ChunkLoadError") {
      const isAlreadyFullReloaded = JSON.parse(localStorage.getItem('has-chunk-error') || 'false');
      if (!isAlreadyFullReloaded) {
        localStorage.setItem("has-chunk-error", 'true');
        this.setState({ showErrorPage: false })
        window.location.reload();
        return <></>
      } else { setTimeout(() => this.setState({ showErrorPage: true }), 2000); return <></> }
    }

    if (this.state.hasError && this.state.showErrorPage) {
      return (
        <Center
          minH={this.props.isProtectedRoute ? "calc(100vh - 110px)" : "100vh"}
          p={[5, 5, 5, 0]}
        >
          <SimpleGrid alignItems={"center"} columns={[1, 1, 2]} maxW={"1024px"}>
            <Box
              order={[1, 1, 0]}
              pr={[0, 0, 0, "145px"]}
              textAlign={["center", "center", "left"]}
            >
              <Text fontSize="40px" fontWeight={500}>
                It’s not you, it’s us
              </Text>
              <Text fontSize="18px" color={"brandGray.200"} mt={"35px"}>
                There was an error in loading this page, Please refresh page
              </Text>
              <Button
                title="Refresh"
                w={"120px"}
                mb={"50px"}
                mt={"25px"}
                variant={"serviceButton"}
                onClick={() => window.location.reload()}
                id="error-boundry-refresh"
              />
              <Text fontSize="15px" color={"brandGray.200"}>
                Still facing the issue, Please let us know at
              </Text>
              <Link
                fontSize="15px"
                color={"brand.50"}
                href="mailto:support@coachbar.io"
              >
                support@coachbar.io
              </Link>
            </Box>
            <Box mb={[5, 5, 0]}>
              <Image src={sorryImg} w={"100%"} alt={"Error page"} />
            </Box>
          </SimpleGrid>
        </Center>
      );
    } else {
      localStorage.setItem("has-chunk-error", 'false');
      // next code block goes here
      return this.props.children;
    }
  }
}
