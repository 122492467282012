import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  HStack,
  Image,
  Spinner,
  Stack,
  Tag,
  Text,
} from "@chakra-ui/react";
import {
  ACTION_ICON_BUTTON_TYPE,
  ActionIconButton,
  Button,
  Loader,
  Table,
  TableTextWithEllipsis,
  TipsLinkButton,
} from "@coachbar/shared-components";
import emptyLocation from "../../image/empty-general-settings.svg";
import {
  buttonVariants,
  tableCss,
} from "@coachbar/shared-components/src/constants/constantValues";

const TIPS_LINK_PAGE_SETTING_GENERAL =
  "https://support.coachbar.io/support/solutions/articles/153000018988-getting-started-guide-software-consultant#General";

const ProviderCommonContent = ({
  rowLoading,
  dataList,
  loading,
  tableHead,
  permissions,
  ...props
}) => {
  // const [dataList, setDataList] = useState(dumyData);
  const [tableBody, setTableBody] = useState([]);
  const [readMoreData, setReadMoreData] = useState({});

  useEffect(() => {
    let tBody = [];
    for (let index = 0; index < dataList.length; index++) {
      let directoryItem = [];
      directoryItem.push(
        <Flex
          color={"var(--title-black-text)"}
          fontSize={"14px"}
          fontWeight={500}
          alignItems={"center"}
        >
          <TableTextWithEllipsis maxChars={25} text={dataList[index]["name"]} />
          {dataList[index]["isDefault"] ? (
            <Tag ml="8px" bgColor="#EBF6F1" color="#37AA73">
              Default
            </Tag>
          ) : (
            permissions.edit && (
              <Tag
                ml="8px"
                bgColor="#FFF"
                color="#9EA5AD"
                className="show-on-hover"
                borderColor={"E6E7E9"}
                border={"1px"}
                borderRadius={"4px"}
                cursor={"pointer"}
                visibility={"hidden"}
                whiteSpace={"nowrap"}
                onClick={() => {
                  props.onMarkDefault(dataList[index]);
                }}
              >
                Mark as Default
              </Tag>
            )
          )}
          {rowLoading === dataList[index].id && (
            <span>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="brand.100"
                size={"xs"}
                ml="4px"
              />
            </span>
          )}
        </Flex>
      );
      if (tableHead[0].includes("Description"))
        directoryItem.push(
          getDescription(dataList[index]["description"], dataList[index])
        );
      if (permissions.edit || permissions.delete)
        directoryItem.push(getActions(dataList[index], index));
      tBody.push(directoryItem);
    }
    setTableBody([...tBody]);
    //eslint-disable-next-line
  }, [dataList, readMoreData, rowLoading, permissions]);

  const editData = (data) => {
    props.onEdit(data);
  };

  const onDelete = (data) => {
    props.onDelete(data);
  };

  const onReadMoreClick = (data) => {
    let newReadMoreData = { ...readMoreData };
    if (newReadMoreData[data.id]) {
      delete newReadMoreData[data.id];
    } else {
      newReadMoreData[data.id] = true;
    }
    setReadMoreData(newReadMoreData);
  };

  const getDescription = (desc, data) => {
    if (desc) {
      let wrapedDesc = desc;
      if (desc.length > 92 && !readMoreData[data.id]) {
        wrapedDesc = desc.slice(0, 92) + "...";
      }
      return (
        <span style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
          {wrapedDesc}{" "}
          {desc.length > 0 && (
            <span
              style={{ cursor: "pointer", color: "#0C94AC" }}
              onClick={() => onReadMoreClick(data)}
            >
              {desc.length > 92 &&
                (readMoreData[data.id] ? `Read Less` : `Read More`)}
            </span>
          )}
        </span>
      );
    } else {
      return "";
    }
  };

  const getActions = (data = null, index) => {
    return (
      <HStack
        gap={"20px"}
        key={"HStack-" + index}
        justifyContent={"end"}
        alignItems={"center"}
      >
        {permissions.edit && (
          <ActionIconButton
            type={ACTION_ICON_BUTTON_TYPE.EDIT}
            onClickAction={() => editData(data)}
          />
        )}
        {permissions.delete && (
          <ActionIconButton
            type={ACTION_ICON_BUTTON_TYPE.DELETE}
            onClickAction={() => onDelete(data)}
            containerProps={{ marginLeft: "0px !important" }}
          />
        )}
      </HStack>
    );
  };
  return (
    <div>
      <Stack>
        <Stack>
          {loading ? (
            <Loader />
          ) : dataList && dataList.length > 0 ? (
            <Box>
              <Flex justifyContent={"end"} mb="24px !important" gap="20px">
                <TipsLinkButton to={TIPS_LINK_PAGE_SETTING_GENERAL} />
                {permissions.add && (
                  <Button
                    title={`Add new ${props.title}`}
                    type="submit"
                    onClick={() => {
                      props.onAdd();
                    }}
                    variant={buttonVariants.brandPrimary}
                    id="provider-common-modal-submit"
                  />
                )}
              </Flex>
              <Table
                tHead={tableHead}
                tBody={tableBody}
                keepBordersRounded
                keepLastColumnLeftAligned={
                  !permissions.edit || !permissions.delete
                }
                css={tableCss}
              />
            </Box>
          ) : (
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
              h={"calc(100vh - 300px)"}
            >
              <Image src={emptyLocation} h={"100px"} mb={"24px"} />
              <Text
                fontSize={"16px"}
                color={"var(--title-black-text)"}
                fontWeight={700}
                mb={"8px !important"}
              >
                No {props.title} Found
              </Text>
              <Text
                fontSize={"14px"}
                color={"#7E8792"}
                fontWeight={500}
                mb={"20px !important"}
              >
                Try adding new configurations to see the data using below Call
                to Action button.
              </Text>
              {permissions.add && (
                <Button
                  variant={buttonVariants.brandPrimary}
                  onClick={() => {
                    props.onAdd();
                  }}
                  title={`Add new ${props.title}`}
                  id="provider-common-modal-submit"
                />
              )}
            </Flex>
          )}
        </Stack>
      </Stack>
    </div>
  );
};

export default ProviderCommonContent;
