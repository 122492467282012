import UserProfile from "./UserProfile";
import ChangePassword from "./ChangePassword";
// import { useSelector } from "react-redux";
import { Stack } from "@chakra-ui/react";

const MyAccount = () => {
  //   const { userInfo } = useSelector((state) => state.auth);
  return (
    <Stack spacing="5" m={5}>
      <UserProfile />
      <ChangePassword />
    </Stack>
  );
};

export default MyAccount;
