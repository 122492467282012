import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../services/axiosInstance";
import { getErrorMessage } from "../../services/utility";

export const addCoachbarService = createAsyncThunk(
  "add/coachbarService",
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        "/coachbarService/add",
        requestData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getCoachbarServiceList = createAsyncThunk(
  "get/coachbarServiceList",
  async (coachbarServiceId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/coachbarService/list`);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getCoachbarServicePurchasedList = createAsyncThunk(
  "get/coachbarServicePurchasedList",
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        "/coachbarService",
        requestData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateCoachbarService = createAsyncThunk(
  "update/coachbarService",
  async ({ coachbarServiceId, requestParams }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(
        `/coachbarService/${coachbarServiceId}`,
        requestParams
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const deleteCoachbarService = createAsyncThunk(
  "delete/coachbarService",
  async (coachbarServiceId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(
        `/coachbarService/${coachbarServiceId}`
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
