import { Box, Center } from "@chakra-ui/react";
import { Loader } from "@coachbar/shared-components";
import Bar from "@coachbar/shared-components/src/components/Charts/Bar";
import ChartLegends from "@coachbar/shared-components/src/components/Charts/ChartLegends";
import ChartContainer from "@coachbar/shared-components/src/screens/dashboard/ChartContainer";
import ChartGrid from "@coachbar/shared-components/src/screens/dashboard/ChartGrid";
import ChartHeader from "@coachbar/shared-components/src/screens/dashboard/ChartHeader";
import NoDataChart from "@coachbar/shared-components/src/screens/dashboard/NoDataChart";
import { useChartsProvider } from "@coachbar/shared-components/src/screens/dashboard/chartContext";
import {
  addPreviousValues,
  calculateDaysBetweenDates,
  compareInnerAndGeneralFilters,
  formatDateRange,
  getAppliedFilters,
  getPreviousDateRange,
  getSpecificInnerFilters,
  removePreviousValue,
} from "@coachbar/shared-components/src/services/dashboardUtility/utility";
import { deepCopyObject } from "@coachbar/shared-components/src/services/utility";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getTopSellingProducts } from "../../dashboardActions";

const colors = ["#D7EEE3", "#AFDDC7", "#87CCAB", "#5FBB8F", "#37AA73"];
const TopSellingProducts = ({ chartData }) => {
  const { selectedDate, appliedFilters, fixFilters } = useChartsProvider();

  let initialized = false;
  const widgetInfo = chartData;
  const [isLoading, setIsLoading] = useState(false);
  const [chartType, setChartType] = useState("");
  const [record, setRecord] = useState([]);
  const [isCompared, setIsCompared] = useState(false);
  const [totalDeals, setTotalDeals] = useState([]);
  const [prevDateRange, setPrevDateRange] = useState(undefined);
  const [daysBetweenDates, setDaysBetweenDates] = useState(undefined);
  const [innerFilters, setInnerFilters] = useState(
    getSpecificInnerFilters(fixFilters, chartData.filters)
  );
  const [innerDate, setInnerDate] = useState(deepCopyObject({}));

  const [
    formattedDateForGrid,
    formattedPrevDateForGrid,
    formattedDateForChart,
    formattedPrevDateForChart,
  ] = useMemo(() => {
    let conDate = deepCopyObject(
      innerDate?.to && innerDate?.from ? innerDate : selectedDate
    );
    // Date format to show in Grid
    const date = formatDateRange(conDate || null, "grid");
    const prevDate = formatDateRange(prevDateRange || null, "grid");
    // Different date format to show in chart tooltip
    const formattedDate = formatDateRange(conDate || null, "chart");
    const formattedPrevDate = formatDateRange(prevDateRange || null, "chart");
    return [date, prevDate, formattedDate, formattedPrevDate];
  }, [innerDate, selectedDate, prevDateRange]);

  const formattedDealStatusDataForGrid = useMemo(
    () =>
      record.map((data) => {
        return {
          id: data.id,
          value: data.value,
          previousValue: data.previousValue || 0,
        };
      }),
    [record]
  );

  let innerAppliedFilters = useMemo(() => {
    return getAppliedFilters(innerFilters);
  }, [innerFilters]);

  const constructRequestData = ({ date }) => {
    let requestData = compareInnerAndGeneralFilters(
      appliedFilters,
      innerAppliedFilters,
      Object.keys(fixFilters),
      date,
      selectedDate
    );

    return requestData;
  };

  const clearPreviousData = () => {
    setIsCompared(false);
    const copyOfLeadByStatus = [...record];
    setRecord(removePreviousValue(copyOfLeadByStatus));
    setPrevDateRange(null);
    setDaysBetweenDates(null);
  };

  const onToogleCompare = () => {
    if (isCompared) {
      clearPreviousData();
    } else {
      setIsCompared(true);
      let conDate = deepCopyObject(
        innerDate?.to && innerDate?.from ? innerDate : selectedDate
      );

      const previousDateRange = getPreviousDateRange(conDate);
      const numberOfDays = calculateDaysBetweenDates(conDate);
      setDaysBetweenDates(numberOfDays);
      setPrevDateRange(previousDateRange);
      const requestData = constructRequestData({ date: previousDateRange });
      fetchDealStatusData(requestData, true, record);
    }
  };

  const fetchDealStatusData = useCallback(
    async (requestData, isCompared = false, dealsByStatus) => {
      setIsLoading(true);
      const data = await getTopSellingProducts(requestData);
      const fetchedData = data?.data?.dashboard;
      setIsLoading(false);

      if (fetchedData) {
        let totalValue = 0;

        let result = fetchedData.map((x, i) => {
          totalValue += x.total;
          return {
            color: colors[i],
            id: x.productName,
            label: x.productName,
            legend: x.productName,
            value: x.total,
          };
        });

        if (isCompared) {
          const updatedData = addPreviousValues(result, dealsByStatus);
          setRecord(updatedData);
        } else {
          setTotalDeals(totalValue);
          setRecord(result);
        }
      }
    },
    []
  );

  useEffect(() => {
    if (!initialized) {
      initialized = true;
      clearPreviousData();
      const requestData = constructRequestData({ date: innerDate });
      fetchDealStatusData(requestData);
    }
  }, [
    fetchDealStatusData,
    innerDate,
    selectedDate,
    appliedFilters,
    innerAppliedFilters,
  ]);

  return (
    <>
      {widgetInfo?.isVisible && (
        <ChartContainer>
          <ChartHeader
            title={widgetInfo?.title}
            showAll
            chartData={widgetInfo}
            chartType={chartType}
            onChartTypeChange={() => {
              if (chartType === "") {
                setChartType("table");
              } else {
                setChartType("");
              }
            }}
            onToogleCompare={onToogleCompare}
            isCompared={isCompared}
            filters={innerFilters}
            onSubmitFilters={(newFilters, date) => {
              setInnerFilters(newFilters);
              setInnerDate(date);
            }}
            date={innerDate}
            generalDate={selectedDate}
          />
          {isLoading ? (
            <Center h={"80%"}>
              <Loader />
            </Center>
          ) : !totalDeals || totalDeals <= 0 ? (
            <Box h={"304px"}>
              <NoDataChart />
            </Box>
          ) : chartType === "table" ? (
            <ChartGrid
              data={formattedDealStatusDataForGrid}
              headings={
                isCompared
                  ? [["Status", formattedDateForGrid, formattedPrevDateForGrid]]
                  : [["Status", formattedDateForGrid]]
              }
            />
          ) : (
            <>
              <Box h={"265px"} overflow={"visible"}>
                <Bar
                  chartFor="TopSellingProducts"
                  bottomLegend={"Revenue"}
                  keys={isCompared ? ["value", "previousValue"] : ["value"]}
                  isCompared={isCompared}
                  data={record}
                  selectedDate={formattedDateForChart}
                  prevDate={formattedPrevDateForChart}
                  daysBetweenDates={daysBetweenDates}
                  marginLeft={125}
                />
              </Box>
              <ChartLegends data={record} showPrevLegend={isCompared} />
            </>
          )}
        </ChartContainer>
      )}
    </>
  );
};

export default TopSellingProducts;
