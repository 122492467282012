// Login.js
import {
  Center,
  Divider,
  HStack,
  InputGroup,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  AuthLeftPanel,
  AuthRightPanel,
  Button,
  Link,
  Modal,
  OtpVerification,
  ShowHidePassword,
  TextBox,
} from "@coachbar/shared-components";
import { GoogleLogin } from "@react-oauth/google";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Notification from "../../components/Notification";
import {
  claimProfileRoute,
  dashboardRoute,
} from "../../constants/redirectionRoutes";
import {
  invalidEmail,
  requiredField,
} from "../../constants/validationMessages";
import { emailValidationPattern } from "../../constants/validationPatterns";
import { useClearState } from "../../services/customHook.js";
import { getSubscriptionPlanDetails } from "../../services/utility";
import {
  getInvitationDetails,
  resendConfirmationCode,
  userLogin,
  verifyUser,
} from "./authActions";
import { clearMessage } from "./authSlice";

const Login = () => {
  const { loading, userInfo, error } = useSelector((state) => state.auth);
  const search = useLocation().search;
  const urlEmail = new URLSearchParams(search).get("email");
  const urlPassword = new URLSearchParams(search).get("password");
  const dispatch = useDispatch();
  useClearState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({ mode: "onChange" });
  const navigate = useNavigate();
  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      if (new URLSearchParams(search).get("invitation")) {
        fetchInvitationData(new URLSearchParams(search).get("invitation"));
      }
    }
    if (urlEmail && urlPassword) {
      setValue("email", urlEmail);
      setValue("password", urlPassword);
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      if (
        userInfo.hasOwnProperty("isClaimed") &&
        userInfo.isClaimed === false
      ) {
        navigate(claimProfileRoute);
      }
      // if (localStorage.getItem("isRedirect")) {
      //   navigate("/settings");
      // }
      else {
        navigate(dashboardRoute);
      }
    }
  }, [navigate, userInfo]);

  const fetchInvitationData = async (token) => {
    let { data } = await getInvitationDetails({ id: token });
    setValue("email", data.scEmail);
  };

  const submitForm = (data) => {
    dispatch(userLogin(data)).then(async (responseData) => {
      if (!responseData.payload.success && responseData.payload.code === 412) {
        dispatch(resendConfirmationCode(data.email)).then((response) => {
          if (response.payload && response.payload.success) {
            onOpen();
          }
        });
      }
    });
  };
  const { onOpen, isOpen, onClose, onToggle } = useDisclosure();
  const [otpValue, setOtpValue] = useState("");

  const handleChange = (otpValue) => {
    setOtpValue(otpValue);
  };

  const submitOTP = () => {
    if (otpValue) {
      let email = getValues("email") || "";
      let requestData = { email: email, confirmationCode: otpValue };
      dispatch(verifyUser(requestData)).then((responseData) => {
        if (responseData.payload && responseData.payload.success) {
          if (localStorage.getItem("planId")) {
            getSubscriptionPlanDetails(dispatch);
          }
          onClose();
        }
      });
    }
  };

  const responseMessage = (response) => {
    if (response && response.credential) {
      submitForm({ googleIdToken: response.credential });
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <>
      {localStorage.getItem("session-expired-logout") && (
        <Notification
          title="Session Expired."
          duration={7000}
          status="error"
          onCloseToast={() => {
            localStorage.removeItem("session-expired-logout");
          }}
        />
      )}
      <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
        <AuthLeftPanel />
        <AuthRightPanel width={"1 1 calc(100% - 430px)"} widthXl="400px">
          {error ? (
            <Notification
              time={error ? 7000 : 3000}
              title={error}
              status={error ? "error" : "success"}
              onCloseToast={() => dispatch(clearMessage())}
            />
          ) : (
            ""
          )}
          <form onSubmit={handleSubmit(submitForm)} noValidate>
            <Text
              fontSize="20px"
              lineHeight={"20px"}
              fontWeight="700"
              mb={"8px !important"}
              color="var(--title-black-text)"
              textAlign="center"
            >
              Welcome Back!
            </Text>
            <Text
              color="#7E8792"
              textAlign="center"
              fontSize={"14px"}
              fontWeight={400}
              mb={"32px !important"}
            >
              Please login to access your account.
            </Text>
            <GoogleLogin
              onSuccess={responseMessage}
              width={400}
              shape="square"
              theme="outline"
              logo_alignment="center"
            />
            <HStack spacing={2} my={"36px !important"}>
              <Divider borderColor={"#d4e1fb"} />
              <Text
                whiteSpace="nowrap"
                fontWeight="500"
                color="#7E8792"
                fontSize={"14px"}
              >
                Or continue with
              </Text>
              <Divider borderColor={"#d4e1fb"} />
            </HStack>

            <TextBox
              type="text"
              placeholder="Email"
              required
              innerref={register("email", {
                required: requiredField.replace("$Field$", "Email"),
                pattern: {
                  value: emailValidationPattern,
                  message: invalidEmail,
                },
              })}
              validationErrors={errors}
            />
            <InputGroup mt={"6px"}>
              <TextBox
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                required
                innerref={register("password", {
                  required: requiredField.replace("$Field$", "Password"),
                })}
                validationErrors={errors}
              />
              <ShowHidePassword
                handlePasswordVisibility={handlePasswordVisibility}
                showPassword={showPassword}
                id="login-password-visiblity"
              />
            </InputGroup>
            <HStack justifyContent={"flex-end"}>
              <Link
                to="/forgot-password"
                title="Forgot your password?"
                color="#0C94AC"
                fontSize="12px"
                fontWeight={"400"}
              />
            </HStack>
            <Button
              mt={"20px"}
              w={"100%"}
              type="submit"
              isLoading={loading}
              title="Login"
              id="login-page-submit"
            />
            <Text
              color="var(--title-black-text)"
              fontWeight={400}
              fontSize="16px"
              textAlign="center"
              mt={"40px"}
            >
              Are you new to Coachbar?{" "}
              <Link
                color="#0C94AC"
                fontWeight={600}
                to="/register"
                title="Sign Up"
              />
            </Text>
            <Modal
              isOpen={isOpen}
              onClose={onClose}
              title={"Opsss...Verification Required"}
              alignHeader="center"
            >
              <Stack spacing={6}>
                <Stack spacing={1}>
                  <Text
                    color="var(--text-font-color)"
                    fontSize="16px"
                    textAlign="center"
                  >
                    Please, check your inbox
                  </Text>
                  <Text
                    color="var(--text-font-color)"
                    fontSize="16px"
                    textAlign="center"
                  >
                    For email verification, an OTP has been shared
                  </Text>
                </Stack>
                <Stack spacing={4}>
                  <Center>
                    <OtpVerification onChange={handleChange} value={otpValue} />
                  </Center>
                  <Center>
                    <Button
                      type="submit"
                      onClick={submitOTP}
                      title="Verify"
                      w="180px"
                      isLoading={loading}
                      id="login-page-verify-otp-submit"
                    />
                  </Center>
                  <Link
                    to="#"
                    onClick={onToggle}
                    title="Close"
                    color="var(--text-font-color)"
                    fontSize="16px"
                    textAlign="center"
                  />
                </Stack>
              </Stack>
            </Modal>
          </form>
        </AuthRightPanel>
      </Stack>
    </>
  );
};
export default Login;
