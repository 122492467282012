import React from "react";
import { Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

const CustomLink = ({ title, children, ...props }) => {
  return (
    <Link as={RouterLink} {...props} textDecoration={props.decoration ? props.decoration : "underline"}>
      {children ? children : title}
    </Link>
  );
};

export default CustomLink;
