import React from "react";
import { useNavigate } from "react-router-dom";
import { Stack, Text } from "@chakra-ui/react";
import CustomButton from "../components/Button/Button";
import Img404 from "../images/new-404.png";

const PageNotFound = ({ defaultPath = false }) => {
  const navigate = useNavigate();

  return (
    <Stack
      minH={"calc(100vh - 105px)"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <img width={"400px"} src={Img404} alt="take-me-back" />
      <Text
        fontSize="16px"
        fontWeight={600}
        color={"var(--title-black-text)"}
        mt={"40px !important"}
      >
        Oops! Looks like you've floated too far into space. Let's guide you back
        to Earth.
      </Text>
      <CustomButton
        title="Take me back"
        mt="24px !important"
        w="auto"
        onClick={() =>
          !window.opener && defaultPath ? navigate(defaultPath) : navigate(-1)
        }
        id="page-not-found-take-me-back"
      />
    </Stack>
  );
};

export default PageNotFound;
