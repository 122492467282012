// CompanyLocation.js
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  Button,
  TextBox,
  Modal,
  AlertDialog,
  Loader,
  FileUpload,
  Table,
  ActionIconButton,
  ACTION_ICON_BUTTON_TYPE,
  TableTextWithEllipsis,
} from "@coachbar/shared-components";
import {
  maxFileSize,
  maxYearAllowed,
  mediaTypeAllowed,
  minYearAllowed,
  requiredField,
} from "../../../constants/validationMessages";
import {
  useDisclosure,
  Stack,
  Text,
  HStack,
  Flex,
  Image,
  Box,
  Spacer,
} from "@chakra-ui/react";
import {
  getPreSignedUrl,
  notification,
  uploadImage,
} from "../../../services/utility";
import fallbackImage from "../../../image/fallbackImage.png";
import {
  addAward,
  deleteAward,
  editAward,
  getListOfAwards,
} from "./listOfAwardsActions";
import { useDispatch } from "react-redux";
import { getProfileCompletionStatus } from "../profileMetrics/profileMetricsAction";
import ProfileNoRecordsFound from "../../../components/ProfileNoRecordsFound";
import emptyMedia from "../../../image/emptyMedia.png";
import {
  buttonVariants,
  scrollbarCSS,
} from "@coachbar/shared-components/src/constants/constantValues";

const PLATFORM_LABEL = "Award Year";
const LISTING_LABEL = "Award Title";
const MEDIA_LABEL = "Asset";
const tableHead = [[MEDIA_LABEL, LISTING_LABEL, PLATFORM_LABEL]];
const MAX_FILE_SIZE_MEDIA = 1000; // in KB;
const fieldType = "media-type";

const ListOfAwards = ({ permissions }) => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    onOpen: onAlertOpen,
    isOpen: isAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [awardsList, setAwardsList] = useState([]);
  const [tableBody, setTableBody] = useState([]);
  const [mediaImagePath, setMediaImagePath] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [descriptionLength, setDescriptionLength] = useState(0);
  const [selectedData, setSelectedData] = useState(null);

  const {
    register,
    handleSubmit,
    resetField,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });

  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      fetchAwards();
    }
  }, []);

  useEffect(() => {
    let tBody = [];
    for (let index = 0; index < awardsList.length; index++) {
      let directoryItem = [];
      directoryItem.push(
        getImage(
          awardsList[index]["thumbnailUrl"]
            ? awardsList[index]["thumbnailUrl"]
            : awardsList[index]["url"]
        )
      );
      directoryItem.push(
        <Text whiteSpace={"nowrap"}>{awardsList[index]["title"]}</Text>
      );
      directoryItem.push(awardsList[index]["yearOfAward"]);
      if (permissions.edit || permissions.delete)
        directoryItem.push(getActions(awardsList[index], index));
      tBody.push(directoryItem);
    }
    setTableBody([...tBody]);
    //eslint-disable-next-line
  }, [awardsList, permissions]);

  const fetchAwards = async () => {
    setLoading(true);
    await getListOfAwards().then((res) => {
      setLoading(false);
      if (res?.code === 200) {
        setAwardsList(res?.data?.awardList || []);
      }
    });
  };

  const editMedia = (event, mediaData) => {
    let skipKeys = ["modified", "created", "id", "tenantId"];
    setSelectedData(mediaData);
    setMediaImagePath(mediaData?.url);
    for (const key in mediaData) {
      if (mediaData.hasOwnProperty(key) && !skipKeys.includes(key)) {
        // if (mediaData?.description) {
        //   setDescriptionLength(mediaData?.description.length);
        // }
        setValue(key, mediaData[key]);
      }
    }
    onOpen();
  };

  const validateMedia = (value) => {
    if ((!value || value.length < 1) && !mediaImagePath) {
      return requiredField.replace("$Field$", "Media");
    }
    return validateFile(value);
  };

  const validateFile = (files) => {
    const fileObj = files && files[0];

    if (!fileObj || !fileObj?.type) {
      return true;
    }

    const [type, subType] = fileObj.type.split("/");
    if (!type || (type !== "image" && subType !== "pdf")) {
      return mediaTypeAllowed;
    }

    const fsMb = fileObj.size / 1024;

    if (fsMb > MAX_FILE_SIZE_MEDIA) {
      setValue("url", "");
      return maxFileSize.replace("$MB$", MAX_FILE_SIZE_MEDIA + "KB");
    }
    if (!isSubmitting) {
      uploadFile(fileObj);
    }
    return true;
  };

  const uploadFile = (fileObj) => {
    setIsLoading(true);
    let fileExt = fileObj.name.split(".").pop();
    let filePath = fieldType + "-" + Date.now() + "." + fileExt;
    getPreSignedUrl(filePath).then((responseData) => {
      if (responseData.data && responseData.data.success) {
        const preSignedUrl = responseData.data?.data?.signedUrl;
        uploadImageFileToS3(fileObj, preSignedUrl);
      } else {
        setIsLoading(false);
      }
    });
  };

  const uploadImageFileToS3 = async (fileObj, preSignedUrl) => {
    uploadImage(fileObj, preSignedUrl).then((responseData) => {
      if (responseData.status === 200) {
        let imageUrl = preSignedUrl.split("?")[0];
        setMediaImagePath(imageUrl);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const onAlertOpenAction = (event, mediaDetails) => {
    setSelectedData(mediaDetails);
    onAlertOpen();
  };

  const onSuccess = (res) => {
    notification(res.message);
    setSelectedData(null);
    onModalClose();
    onAlertClose();
    fetchAwards();
  };

  const handleError = (errors) => {
    if (errors && errors["url"]) {
      resetField("url");
      handleSubmit(submitForm)();
    }
  };

  const submitForm = async (data) => {
    data["url"] = mediaImagePath;
    setLoading(true);

    try {
      if (selectedData) {
        editAward(selectedData.id, data).then((res) => {
          setLoading(false);
          if (res?.code === 200) {
            onSuccess(res);
          }
        });
      } else {
        await addAward(data).then((res) => {
          setLoading(false);
          if (res?.code === 200) {
            onSuccess(res);
            dispatch(getProfileCompletionStatus());
          }
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      setLoading(true);
      onAlertClose();
      await deleteAward(selectedData.id).then((res) => {
        if (res?.code === 200) {
          onSuccess(res);
          dispatch(getProfileCompletionStatus());
        }
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const onModalClose = () => {
    setSelectedData(null);
    setMediaImagePath("");
    reset();
    onClose();
  };

  const onAlertCloseAction = () => {
    onDelete();
  };

  const getWebsiteName = (siteName) => (
    <Text
      fontSize={"14px"}
      lineHeight={"20px"}
      fontWeight={500}
      color={"var(--title-black-text)"}
    >
      {siteName}
    </Text>
  );

  const getActions = (mediaData = null, index) => {
    return (
      <HStack key={"HStack-" + index} float="right" gap={"16px"}>
        {permissions.edit && (
          <ActionIconButton
            type={ACTION_ICON_BUTTON_TYPE.EDIT}
            onClickAction={(e) => {
              editMedia(e, mediaData);
            }}
          />
        )}
        {permissions.delete && (
          <ActionIconButton
            type={ACTION_ICON_BUTTON_TYPE.DELETE}
            onClickAction={(e) => {
              onAlertOpenAction(e, mediaData);
            }}
            containerProps={{ marginLeft: "0px !important" }}
          />
        )}
      </HStack>
    );
  };

  const getImage = (url) => {
    return (
      <Image
        src={url}
        fallbackSrc={fallbackImage}
        objectFit="cover"
        boxSize="50px"
      />
    );
  };

  let headers = tableHead[0];
  if (permissions.edit || permissions.delete)
    headers = [...headers, ...["Actions"]];

  return (
    <Box
      bg={"white"}
      p={"24px 0"}
      w={"100%"}
      h={"calc(100vh - 223px)"}
      rounded={"8px"}
      overflow={"auto"}
      boxShadow={"1px 0px 4px #E5E7EB"}
      css={scrollbarCSS}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"} p="0 24px">
        <Text
          color={"var(--title-black-text)"}
          fontSize={"14px"}
          lineHeight={"20px"}
          fontWeight={"700"}
          mr={"8px"}
        >
          Awards
        </Text>
        <Text
          as={"span"}
          fontWeight={"500"}
          color={"var(--text-font-color)"}
          fontSize={"12px"}
          pt={"4px"}
        >
          (Maximum limit 10)
        </Text>
        <Spacer />
        {permissions.add && awardsList.length > 0 && (
          <Button
            title={"Add Award"}
            onClick={onOpen}
            variant={buttonVariants.brandPrimary}
            id="profile-setup-awards-add"
            isDisabled={awardsList.length >= 10 ? true : false}
          />
        )}
      </Flex>
      {loading ? (
        <Loader />
      ) : awardsList && awardsList.length > 0 ? (
        <Box m={"24px"} mb="0">
          <Table
            tHead={[headers]}
            tBody={tableBody}
            keepLastColumnLeftAligned={!permissions.edit || !permissions.delete}
            headingStyles={{
              backgroundColor: "#F9FAFB",
              color: "#5E6977 !important",
            }}
            keepBordersRounded
          />
        </Box>
      ) : (
        <Flex
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          h={"calc(100% - 90px)"}
        >
          <ProfileNoRecordsFound
            title="No Award Found"
            description="Try adding Award of company using below Call to Action button."
            imageUrl={emptyMedia}
          >
            {permissions.add && (
              <Button
                title="Add Award"
                onClick={onOpen}
                variant={buttonVariants.brandPrimary}
                rounded="8px"
                id="profile-setup-awards-add"
                mt="18px"
              />
            )}
          </ProfileNoRecordsFound>
        </Flex>
      )}

      <Modal
        isOpen={isOpen}
        onClose={onModalClose}
        title={selectedData ? "Update Award" : "Add Award"}
        allowCloseButton={true}
        displayFooter={true}
        size={"md"}
        onSaveClick={handleSubmit(submitForm, handleError)}
        loading={loading}
        saveButtonTitle="Save Award"
        isDisabled={isLoading}
        spDesign
      >
        <Stack spacing={2}>
          <form noValidate>
            <Flex>
              <TextBox
                type="text"
                name="Title"
                placeholder="Title"
                showPlaceHolder={true}
                maxLength="100"
                required
                innerref={register("title", {
                  required: requiredField.replace("$Field$", "Title"),
                })}
                validationErrors={errors}
                widthFull={true}
              />
            </Flex>
            <Flex mb="5px">
              <TextBox
                type="number"
                name="Year of Award"
                required
                placeholder="YYYY"
                showPlaceHolder={true}
                innerref={register("yearOfAward", {
                  required: requiredField.replace("$Field$", "Year of Award"),
                  min: {
                    value: 1900,
                    message: minYearAllowed.replace("$Year$", 1900),
                  },
                  max: {
                    value: new Date().getFullYear(),
                    message: maxYearAllowed.replace(
                      "$Year$",
                      new Date().getFullYear()
                    ),
                  },
                })}
                validationErrors={errors}
              />
            </Flex>
            <Flex>
              <FileUpload
                name="Asset"
                acceptedFileTypes="image/*,.pdf"
                required
                innerref={register("url", {
                  validate: validateMedia,
                })}
                validationErrors={errors}
                ratio={5 / 2}
                isLoading={isLoading}
                imgSrc={mediaImagePath}
              />
            </Flex>
          </form>
        </Stack>
      </Modal>
      <AlertDialog
        title="Delete Award"
        actionButtonName="Delete"
        isAlertOpen={isAlertOpen}
        onAlertClose={onAlertClose}
        onAlertCloseAction={onAlertCloseAction}
      />
    </Box>
  );
};
export default ListOfAwards;
