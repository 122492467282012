import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../services/axiosInstance";
import { getErrorMessage } from "../../../services/utility";

export const addCompanyLocation = createAsyncThunk(
  "add/companyLocation",
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("/address/add", requestData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateCompanyLocation = createAsyncThunk(
  "update/companyLocation",
  async ({ addressId, requestParams }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(
        `/address/${addressId}`,
        requestParams
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getPartnerLocationList = createAsyncThunk(
  "get/partner/address",
  async (partnerTenantId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("/address", {
        params: {
          limit: 100,
          partnerTenantId,
        },
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
