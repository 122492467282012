import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../services/axiosInstance";
import { getErrorMessage } from "../../services/utility";

export const fetchReferralList = async (status, limit, nextToken) => {
  try {
    const url =
      status === "Unclaimed"
        ? `/adminReferral/getPublishedReferralList?limit=${limit}&nextToken=${nextToken}`
        : `/referral/list?limit=${limit}&status=${status}&nextToken=${nextToken}`;
    const { data } = await axiosInstance.get(url);
    return data;
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return error.response.data;
    } else {
      return error.message;
    }
  }
};

export const updateReferral = createAsyncThunk(
  "update/referral",
  async ({ referralId, requestParams }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(
        `/referral/${referralId}`,
        requestParams
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getReferralDetails = createAsyncThunk(
  "get/referralDetails",
  async ({ referralId, status }, { rejectWithValue }) => {
    try {
      const url =
        status && status === "Unclaimed"
          ? `/adminReferral/getReferral/${referralId}`
          : `/referral/${referralId}`;
      const { data } = await axiosInstance.get(url);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const addReferral = createAsyncThunk(
  "add/referral",
  async ({ requestParams }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(`/referral/add`, requestParams);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

// export const assignReferral = createAsyncThunk(
//   "assign/referral",
//   async ({ requestParams }, { rejectWithValue }) => {
//     try {
//       const { data } = await axiosInstance.post(
//         `/adminReferral/assign`,
//         requestParams
//       );
//       return data;
//     } catch (error) {
//       // return custom error message from API if any
//       return rejectWithValue(getErrorMessage(error));
//     }
//   }
// );

export const deleteReferral = createAsyncThunk(
  "delete/referral",
  async (requestParams, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(
        `/referral/delete`,
        requestParams
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const importReferral = createAsyncThunk(
  "import/referral",
  async ({ requestParams }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        `/referral/import`,
        requestParams
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
