import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Box,
  Flex,
  Spacer,
  Text,
  IconButton,
  CardHeader,
  CardBody,
  CardFooter,
  Card,
  Image,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import transitionIcon from "../../../image/transition-icon.svg";
import { buttonVariants } from "@coachbar/shared-components/src/constants/constantValues";

const FieldMapping = ({
  leftFieldProperty,
  rightFieldProperty,
  mappingFn,
  mappingError,
  text,
  fieldMapping,
}) => {
  const newMapping = { coachbarField: {}, hubspotField: {} };
  const [fieldMappings, setFieldMappings] = useState(fieldMapping);

  const [availableFieldsLeft, setAvailableFieldsLeft] =
    useState(leftFieldProperty);
  const [availableFieldsRight, setAvailableFieldsRight] =
    useState(rightFieldProperty);

  const addFieldMapping = () => {
    if (
      fieldMappings.length < availableFieldsLeft.length &&
      fieldMappings.length < availableFieldsRight.length
    ) {
      setFieldMappings([...fieldMappings, newMapping]);
      mappingFn([...fieldMappings, newMapping]);
    }
  };
  const removeMapping = (index) => {
    const mappingToRemove = fieldMappings[index];
    const updatedMappings = fieldMappings.filter((_, i) => i !== index);
    setFieldMappings(updatedMappings);
    mappingFn(updatedMappings);
    if (mappingToRemove && mappingToRemove.coachbarField) {
      changeFieldVisiblity(
        availableFieldsLeft,
        setAvailableFieldsLeft,
        false,
        mappingToRemove.coachbarField.value
      );
    }
    if (mappingToRemove && mappingToRemove.hubspotField) {
      changeFieldVisiblity(
        availableFieldsRight,
        setAvailableFieldsRight,
        false,
        mappingToRemove.hubspotField.value
      );
    }
  };

  /**
   *
   * @param {number} index // index of the options
   * @param {string} field // filed name of mapping
   * @param {object} selectedOption // selected option object
   * @param {string} previousField // previous selected option id any, to enable the previous option after selecting the new one
   */

  const handleMappingChange = (index, field, selectedOption, previousField) => {
    const updatedMappings = [...fieldMappings];
    updatedMappings[index][field] = selectedOption;
    setFieldMappings(updatedMappings);
    mappingFn(updatedMappings);

    // Remove the selected fields from available fields
    if (field === "coachbarField") {
      changeFieldVisiblity(
        availableFieldsLeft,
        setAvailableFieldsLeft,
        true,
        selectedOption.value
      );
      if (previousField?.value) {
        changeFieldVisiblity(
          availableFieldsLeft,
          setAvailableFieldsLeft,
          false,
          previousField.value
        );
      }
    }
    if (field === "hubspotField") {
      changeFieldVisiblity(
        availableFieldsRight,
        setAvailableFieldsRight,
        true,
        selectedOption.value
      );
      if (previousField?.value) {
        changeFieldVisiblity(
          availableFieldsRight,
          setAvailableFieldsRight,
          false,
          previousField.value
        );
      }
    }
  };

  /**
   *
   * @param {Object} availableFieldObject // State Object
   * @param {Function} availableFieldObjectToSet //Function to set state
   * @param {Boolean} disable // boolean value to disable or enable field in dropdown
   * @param {string} option // current or previously selected value
   */
  const changeFieldVisiblity = (
    availableFieldObject,
    availableFieldObjectToSet,
    disable,
    option
  ) => {
    const selectedFieldIndex = availableFieldObject.findIndex(
      (obj) => obj.value === option
    );

    if (selectedFieldIndex > -1) {
      const updatedFieldArray = [...availableFieldObject];

      updatedFieldArray[selectedFieldIndex].disable = disable;

      availableFieldObjectToSet(updatedFieldArray);
    }
  };

  return (
    <Box mt={"1rem"} w={"100%"}>
      <Card>
        <CardHeader p={0}>
          <Flex
            alignItems="center"
            bg={"#EBF3FB"}
            borderBottom={"1px solid #E2E8F0"}
            borderRadius={"6px 6px 0px 0px"}
            h={"40px"}
            p={"10px"}
          >
            <Box w={"45%"} pl={"14px"}>
              <Text>Coachbar {text}</Text>
            </Box>
            <Text w="2%" mx={2}></Text>
            <Box w={"45%"}>
              <Text>HubSpot {text}</Text>
            </Box>
          </Flex>
        </CardHeader>
        <CardBody>
          {fieldMappings.map((mapping, index) => (
            <Flex key={index} alignItems="center" mb={"0.5rem"}>
              <Box w={"45%"}>
                <Select
                  options={availableFieldsLeft}
                  value={mapping.coachbarField}
                  isOptionDisabled={(option) => option.disable}
                  onChange={(selectedOption) =>
                    handleMappingChange(
                      index,
                      "coachbarField",
                      selectedOption,
                      mapping.coachbarField
                    )
                  }
                  placeholder="Select Source Field"
                  isSearchable={true}
                />
              </Box>
              <Box w={"5%"} pl={"1rem"} pr={"1rem"}>
                <Image boxSize={"1.5rem"} src={transitionIcon} />
              </Box>
              <Box w={"45%"}>
                <Select
                  options={availableFieldsRight}
                  value={mapping.hubspotField}
                  isOptionDisabled={(option) => option.disable}
                  onChange={(selectedOption) =>
                    handleMappingChange(
                      index,
                      "hubspotField",
                      selectedOption,
                      mapping.hubspotField
                    )
                  }
                  placeholder="Select Target Field"
                  isSearchable={true}
                />
              </Box>
              <IconButton
                icon={<DeleteIcon />}
                aria-label="Remove Mapping"
                variant={buttonVariants.greyLinkButton}
                colorScheme="red"
                onClick={() => removeMapping(index)}
                id="integration-hubspot-remove-mapping"
              />
              <Spacer />
            </Flex>
          ))}
          {mappingError ? (
            <Box p={2} color={"red"}>
              {mappingError}
            </Box>
          ) : (
            ""
          )}
        </CardBody>
        {fieldMappings.length < availableFieldsLeft.length &&
        fieldMappings.length < availableFieldsRight.length ? (
          <CardFooter pt={0}>
            <Flex
              alignItems={"baseline"}
              onClick={addFieldMapping}
              cursor={"pointer"}
            >
              <Box
                boxSize={"1rem"}
                bg={"#0C94AC"}
                borderRadius={"50%"}
                textAlign={"center"}
                mr={"0.5rem"}
              >
                <Text mt={"-4px"} color={"white"} fontSize={"15px"}>
                  +
                </Text>
              </Box>
              <Text>Add New Field</Text>
            </Flex>
          </CardFooter>
        ) : (
          ""
        )}
      </Card>
    </Box>
  );
};

export default FieldMapping;
