import { Text, Center } from "@chakra-ui/react";

const NoData = (props) => {
  return (
    <Center {...props} h="120px">
      <Text fontSize="md" color="brandGray.50">
        {props.description}
      </Text>
    </Center>
  );
};

export default NoData;
