export const emailValidationPattern =
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
export const upperCaseValidationPattern = /[A-Z]/;
export const lowerCaseValidationPattern = /[a-z]/;
export const specialCharacterValidationPattern = /[#?!@$%^&*-]/;
export const urlValidationPattern =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{1,}(\.[a-zA-Z0-9]{1,})(\.[a-zA-Z0-9]{1,})?/;
export const numberPattern = /^\d*$/;
export const whiteSpacePattern = /^(?!\s+$).+/;
export const emailWithCommaSeparatedPattern =
  /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,},\s*)*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
