import {
  Avatar,
  Box,
  Center,
  Flex,
  Image,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { format } from "date-fns";
import { useCallback, useMemo, useRef, useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";

import NoRecordsFound from "../../components/NoData/NoRecordsFound";

import CustomPopover from "../../components/CustomPopover/CustomPopover";
import CustomizeColumns from "../../components/CustomizeColumns/CustomizeColumns";
import Loader from "../../components/Loader/Loader";
import SystemTag from "../../components/Tag/SystemTag/SystemTag";
import {
  assetsColumnOrder,
  assetsVisibleColumns,
} from "../../constants/assets";
import {
  nameAvatarProps,
  scrollbarCSS,
  tableCss,
} from "../../constants/constantValues";
import actionIcon from "../../images/action-btn.svg";
import emptyAssetIcon from "../../images/asset-empty.png";
import folderIcon from "../../images/brand-folder-icon.png";
import columnPosition from "../../images/column-position.svg";
import deleteIcon from "../../images/delete-red.png";
import downloadIcon from "../../images/download.png";
import externalLinkIcon from "../../images/external-link.svg";
import editIcon from "../../images/gray-pencil.png";
import iconPlusColor from "../../images/plus-color.png";
import sortingUpDown from "../../images/sorting.svg";
import sortingDown from "../../images/sortingDown.svg";
import sortingUp from "../../images/sortingUp.svg";
import userIcon from "../../images/user.png";
import ShareWithModal from "./modals/ShareWithModal";
import { getAssetIconByExtension, getSharedWithUserList } from "./utility";
import CustomTooltip from "../../components/Tooltip/CustomTooltip";
import TableTextWithEllipsis from "../../components/Table/TableTextWithEllipsis";

const AssetTable = ({
  selectedDate,
  refetch,
  setRefetch,
  selectedSharedWithUsers,
  globalFilterValue,
  setGlobalFilterValue,
  nextToken,
  setNextToken,
  onDeleteClick,
  portal,
  breadCrumbs,
  setBreadCrumbs,
  entitysList,
  fetchAssetList,
  onNewFolderModalOpen,
  selectedData,
  setSelectedData,
  getPartnerTypes,
  getPartnerGroups,
  getPartnersByTypes,
  shareAsset,
  removeSharedPartners,
  downloadFolder,
  downloadFile,
  userInfo,
  loading,
  setLoading,
  permissions,
  getTagListBySearch,
  createTag,
  updateAssetTagList,
  extraFilters,
  fetchPartnerOrProviderList,
}) => {
  const tableContainerRef = useRef(null);
  const [sorting, setSorting] = useState([]);
  const [openAction, setOpenAction] = useState("");
  const [downloadLoading, setDownloadLoading] = useState(false);

  const {
    onOpen: onColumnSelectionOpen,
    onClose: onColumnSelectionClose,
    isOpen: isColumnSelectionOpen,
  } = useDisclosure();

  const {
    onOpen: onShareWithModalOpen,
    onClose: onShareWithModalClose,
    isOpen: isShareWithModalOpen,
  } = useDisclosure();

  const getAssetIcon = (assetData) => {
    if (assetData) {
      const type = assetData?.assetType;
      if (type === "Folder") {
        return folderIcon;
      } else {
        const fileExtension = (
          assetData.assetUrl?.split(".").pop() || ""
        ).toUpperCase();

        return getAssetIconByExtension(fileExtension);
      }
    }
  };

  const handleBreadCrumbs = (name, id, assetData) => {
    // const isBreadcrumbAlreadyAdded = breadCrumbs?.find((bc) => bc.id === id);
    setLoading(true);
    setNextToken(null);
    if (!globalFilterValue) {
      setBreadCrumbs((prev) => [...prev, { name, id }]);
      setRefetch((prev) => !prev);
    } else {
      const assetPath = `${assetData?.assetPath}/${id}`;
      const fetchedBreadcrumbs = assetData?.breadCrumbs;

      if (assetPath && fetchedBreadcrumbs) {
        const assetIdArray = assetPath?.split("/")?.filter(Boolean);
        const assetBreadcrumbs = fetchedBreadcrumbs.split("/")?.filter(Boolean);
        // Use map to iterate over one of the arrays and construct objects
        const generatedBreadcrumbs = assetIdArray.map((id, index) => ({
          name: assetBreadcrumbs[index],
          id: id,
        }));
        setBreadCrumbs((prev) => [...prev, ...generatedBreadcrumbs]);
        setGlobalFilterValue("");
      }
    }
  };

  const handleTagSubmit = async (updatedTagList, cb, assetId) => {
    const payloadTags = updatedTagList.map((tag) => ({
      id: tag.value,
      name: tag.label,
    }));
    const res = await updateAssetTagList(payloadTags, assetId);
    if (res?.success) {
      setNextToken(null);
      setRefetch((prev) => !prev);
    }
    cb(res);
  };

  const defaultColumns = useMemo(() => {
    let cols = [
      {
        id: "type",
        enableSorting: false,
        header: "Type",
        cell: ({ row }) => {
          let icon = getAssetIcon(row.original);
          return <Image boxSize={"30px"} src={icon} />;
        },
      },
      {
        id: "name",
        accessorKey: "name",
        header: "Name",
        cell: ({ row }) => {
          const folderData = row.original;
          return (
            <TableTextWithEllipsis
              text={row.original.name}
              maxChars={25}
              textContainerProps={{
                cursor: "pointer",
                pointerEvents:
                  folderData?.assetType !== "Folder" ? "none" : "all",
                onClick: () => {
                  handleBreadCrumbs(
                    folderData?.name,
                    folderData?.id,
                    folderData
                  );
                },
              }}
            />
          );
        },
      },
      // {
      //   id: "totalFiles",
      //   accessorKey: "totalFiles",
      //   header: "Total Files",
      //   enableSorting: false,
      //   cell: ({ row }) => {
      //     return (
      //       <Text
      //         color={"#111A29"}
      //         fontSize={"14px"}
      //         fontWeight={450}
      //         lineHeight={"20px"}
      //         cursor={"pointer"}
      //       >
      //         {row.original?.assetType === "Folder"
      //           ? row.original.totalFiles || 0
      //           : "-"}
      //       </Text>
      //     );
      //   },
      // },
      {
        id: "created",
        accessorKey: "created",
        header: "Created",
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <Text
              color={"#111A29"}
              fontSize={"14px"}
              fontWeight={450}
              lineHeight={"20px"}
              whiteSpace={"nowrap"}
            >
              {row.original.created
                ? format(new Date(row.original.created), "MMM dd, yyyy")
                : "-"}
            </Text>
          );
        },
      },
      {
        id: "createdByName",
        accessorKey: "createdByName",
        header: "Created By",
        enableSorting: true,
        cell: ({ row }) => {
          return (
            <Flex alignItems={"center"}>
              <Avatar
                name={row.original.createdByName || ""}
                {...nameAvatarProps}
              />
              <TableTextWithEllipsis
                text={row.original.createdByName || "-"}
                maxChars={20}
              />
            </Flex>
          );
        },
      },
      {
        id: "modified",
        accessorKey: "modified",
        header: "Modified Date",
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <Text
              color={"#111A29"}
              fontSize={"14px"}
              fontWeight={450}
              lineHeight={"20px"}
              whiteSpace={"nowrap"}
            >
              {row.original.modified
                ? format(new Date(row.original.modified), "MMM dd, yyyy")
                : "-"}
            </Text>
          );
        },
      },
      {
        id: "sharedDate",
        accessorKey: "sharedDate",
        header: "Shared Date",
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <Text
              color={"#111A29"}
              fontSize={"14px"}
              fontWeight={450}
              lineHeight={"20px"}
              whiteSpace={"nowrap"}
            >
              {row.original.sharedDate
                ? format(new Date(row.original.sharedDate), "MMM dd, yyyy")
                : "-"}
            </Text>
          );
        },
      },
      {
        id: "sharedByName",
        accessorKey: "sharedByName",
        header: "Shared By",
        enableSorting: true,
        cell: ({ row }) => {
          const sharedByData = entitysList?.find(
            (entity) => entity.referenceTenantId === row.original?.sharedById
          );
          return (
            <>
              {portal === "sp" ? (
                row.original.sharedByName ? (
                  <Flex alignItems={"center"}>
                    <Avatar
                      name={row.original.sharedByName}
                      {...nameAvatarProps}
                    />

                    <TableTextWithEllipsis
                      text={row.original.sharedByName}
                      maxChars={20}
                    />
                  </Flex>
                ) : (
                  "-"
                )
              ) : (
                <Tooltip label={sharedByData?.spCompanyName} hasArrow>
                  <Image
                    h={"24px"}
                    w={"auto"}
                    src={sharedByData?.logo}
                    objectFit={"contain"}
                  />
                </Tooltip>
              )}
            </>
          );
        },
      },
    ];

    if (permissions.view)
      cols.push({
        id: "tags",
        accessorKey: "tags",
        header: "Tags",
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <SystemTag
              getTagListBySearch={getTagListBySearch}
              createTag={createTag}
              handleTagSubmit={(updatedTagList, cb) =>
                handleTagSubmit(updatedTagList, cb, row?.original?.id)
              }
              selectedPartnerTags={
                row.original?.tags?.map((tag) => ({
                  label: tag.name,
                  value: tag.id,
                })) || []
              }
              displayLimit={2}
              permissions={permissions}
              moduleForId="asset-list"
              containerProps={{ wrap: "nowrap" }}
            />
          );
        },
      });

    if (portal === "sp") {
      cols.push({
        id: "sharedWith",
        enableSorting: false,
        accessorKey: "sharedPartners",
        header: "Shared With",
        cell: ({ row }) => {
          const data = row.original.sharedPartners;
          const sharedWithUserList = getSharedWithUserList(data, entitysList);
          return (
            <Flex flexWrap={"wrap"} position={"relative"}>
              {sharedWithUserList &&
                sharedWithUserList?.map((entity, index) => {
                  return (
                    <Tooltip
                      key={`${entity?.referenceTenantId}-${index}`}
                      label={entity?.scCompanyName}
                      hasArrow
                    >
                      <Image
                        border={"1px solid #CEEAEE"}
                        rounded={"50%"}
                        boxSize={"24px"}
                        mr={"-7px"}
                        src={entity?.logo || userIcon}
                        objectFit={"contain"}
                      />
                    </Tooltip>
                  );
                })}
              <Tooltip label={"Select Partners"} hasArrow>
                <Image
                  src={iconPlusColor}
                  ml={data ? "10px" : "0px"}
                  boxSize={"24px"}
                  p={"2px"}
                  rounded={"50%"}
                  cursor={"pointer"}
                  border={"1px dashed #CEEAEE"}
                  onClick={(e) => {
                    setSelectedData(row.original);
                    onShareWithModalOpen();
                  }}
                />
              </Tooltip>
            </Flex>
          );
        },
      });
    }

    if (portal === "sc") {
      cols = cols.filter((col) => col.id !== "createdByName");
      cols.push({
        id: "download",
        accessorKey: "download",
        header: "",
        enableSorting: false,
        cell: ({ row }) => {
          const isExternalLink = row?.original?.isExternalLink;
          return (
            <Flex
              alignItems={"center"}
              gap={"8px"}
              minW={"40px"}
              justifyContent={"end"}
              pr={"12px"}
            >
              <CustomTooltip
                content={isExternalLink ? "Open link" : "Download"}
                placement="top"
                width="max-content"
              >
                <Image
                  cursor={"pointer"}
                  boxSize={"16px"}
                  src={isExternalLink ? externalLinkIcon : downloadIcon}
                  onClick={() => handleDownload(row.original)}
                />
              </CustomTooltip>
              {downloadLoading && (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="brand.100"
                  size={"xs"}
                />
              )}
            </Flex>
          );
        },
      });
    }

    return cols;
  }, [entitysList, onShareWithModalOpen, portal, globalFilterValue]);

  let columns = [...defaultColumns];

  const { data, fetchNextPage, isFetching } = useInfiniteQuery(
    [
      "table-data",
      refetch,
      selectedSharedWithUsers,
      selectedDate,
      globalFilterValue,
    ],
    async ({ pageParam = 0 }) => {
      let requestData = {
        limit: 15,
        nextToken: nextToken,
        search: globalFilterValue,
        filters: {
          fromDate: selectedDate?.from,
          toDate: selectedDate?.to,
        },
      };
      if (portal === "sp") {
        requestData.filters = {
          ...requestData.filters,
          partnerIdList: selectedSharedWithUsers?.map((user) => user?.id),
        };
      } else {
        requestData.filters = {
          ...requestData.filters,
          providerIdList: selectedSharedWithUsers?.map((user) => user?.id),
        };
      }

      if (breadCrumbs?.length > 1) {
        let updatedBreadcrumbs = [...breadCrumbs];
        const parentFolder = updatedBreadcrumbs?.pop();
        if (parentFolder) {
          requestData.parentFolderId = parentFolder?.id;
        }
      }

      if (extraFilters?.systemTags?.length > 0)
        requestData.filters.systemTags = extraFilters.systemTags;

      const fetchedData = await fetchAssetList(requestData); //pretend api call
      setLoading(false);
      const assetList = fetchedData?.data?.assetList;
      if (assetList) {
        setNextToken(assetList?.nextToken || null);
        return fetchedData?.data?.assetList?.assets || [];
      }
      return [];
    },
    {
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: 1000,
    }
  );
  //we must flatten the array of arrays from the useInfiniteQuery hook
  const flatData = useMemo(() => {
    return data?.pages?.flatMap((page) => page) ?? [];
  }, [data]);

  const totalFetched = flatData.length;

  const getSavedColumns = () => {
    if (portal === "sc") return columns?.map(({ id }) => id);
    const lsColumns = localStorage.getItem(assetsColumnOrder)?.split(";") || [];
    const extraColumns = columns
      .filter(({ id }) => !lsColumns.includes(id))
      .map(({ id }) => id);
    let updatedColumn = lsColumns;
    if (extraColumns.length) {
      updatedColumn = lsColumns.concat(extraColumns);
      localStorage.setItem(assetsColumnOrder, updatedColumn.join(";"));
    }
    return updatedColumn; //must start out with populated columnOrderOptions so we can splice
  };

  const getVisibleColumns = () => {
    return localStorage.getItem(assetsVisibleColumns)
      ? JSON.parse(localStorage.getItem(assetsVisibleColumns))
      : {};
  };

  const [columnVisibility, setColumnVisibility] = useState(getVisibleColumns());
  const [displayColumnVisibility, setDisplayColumnVisibility] = useState(
    getVisibleColumns()
  );
  const [displayColumnOrder, setDisplayColumnOrder] = useState(
    getSavedColumns()
  );

  const [columnOrderOptions, setColumnOrder] = useState(getSavedColumns());

  const reorderColumn = (draggedColumnId, targetColumnId, tempColumnOrder) => {
    tempColumnOrder.splice(
      tempColumnOrder.indexOf(targetColumnId),
      0,
      tempColumnOrder.splice(tempColumnOrder.indexOf(draggedColumnId), 1)[0]
    );
    return JSON.parse(JSON.stringify([...tempColumnOrder]));
  };

  const table = useReactTable({
    data: flatData,
    columns,
    state: {
      columnVisibility: displayColumnVisibility,
      columnOrder: displayColumnOrder,
      sorting,
    },
    onColumnOrderChange: setDisplayColumnOrder,
    onSortingChange: setSorting,
    onColumnVisibilityChange: setDisplayColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const columnHandleChange = (checked, column) => {
    let newColumns = { ...columnVisibility };
    newColumns[column.id] = checked;
    setColumnVisibility(newColumns);
  };

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        if (
          scrollHeight - scrollTop - clientHeight < 100 &&
          !isFetching &&
          nextToken
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched]
  );

  const onCancelColumns = () => {
    setColumnOrder([...displayColumnOrder]);
    setColumnVisibility({ ...displayColumnVisibility });
    onColumnSelectionClose();
  };

  const onSaveColumns = () => {
    localStorage.setItem(
      assetsVisibleColumns,
      JSON.stringify(columnVisibility)
    );
    localStorage.setItem(assetsColumnOrder, columnOrderOptions.join(";"));
    setDisplayColumnOrder([...columnOrderOptions]);
    setDisplayColumnVisibility(columnVisibility);
    onColumnSelectionClose();
  };

  const handleDownload = async (assetData) => {
    if (downloadLoading) return;
    try {
      setDownloadLoading(true);
      if (assetData?.id) {
        let downloadRes;
        if (assetData?.assetType === "Folder") {
          downloadRes = await downloadFolder({ id: assetData?.id });
        } else {
          downloadRes = await downloadFile({ id: assetData?.id });
        }
        const url = downloadRes?.data?.Assets?.assetUrl;
        if (url) {
          const a = document.createElement("a");
          a.href = url;
          assetData?.isExternalLink
            ? (a.target = "_blank")
            : (a.download = url);
          a.target = "_blank";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }
    } catch (error) {
      console.log(`Error while downloading folder ${assetData?.name}`, error);
    } finally {
      setDownloadLoading(false);
      setOpenAction("");
    }
  };

  return (
    <Stack
      border={"1px solid #E2E8F0"}
      borderTopRadius={"6px"}
      maxH={"calc(100vh - 200px)"}
      mt={"24px"}
    >
      <Box
        onScroll={(e) => fetchMoreOnBottomReached(e.target)}
        ref={tableContainerRef}
        overflow={"auto"}
        style={{ maxHeight: `calc(100vh - 180px)` }}
        css={scrollbarCSS}
      >
        {loading ? (
          <Center h={"calc(100vh - 210px)"}>
            <Loader />
          </Center>
        ) : table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
          <Table
            variant="simple"
            css={{
              ...tableCss,
              "& tr td:first-of-type, & tr th:first-of-type": {
                paddingLeft: "24px",
              },
              "& tr td:last-of-type, & tr th:last-of-type": {
                paddingRight: "12px",
              },
            }}
          >
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr
                  bg={"#F5F5F5"}
                  key={headerGroup.id}
                  position={"sticky"}
                  top={0}
                  zIndex={12}
                >
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        color={"#667180"}
                        fontSize={"12px"}
                        fontWeight={"700"}
                        lineHeight={"16px"}
                        p="12px"
                        style={header.column.columnDef?.style || {}}
                        bg="#F5F5F5"
                      >
                        {header.isPlaceholder ? null : (
                          <Box display={"flex"}>
                            <Text
                              display={"flex"}
                              position={"relative"}
                              alignItems={"center"}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {header.column.getCanSort() && (
                                <Box
                                  width={"16px"}
                                  height={"16px"}
                                  minW={"16px"}
                                  cursor={"pointer"}
                                  ml="16px"
                                  {...{
                                    onClick:
                                      header.column.getToggleSortingHandler(),
                                  }}
                                >
                                  {{
                                    asc: (
                                      <Image
                                        src={sortingUp}
                                        width={"16px"}
                                        height={"16px"}
                                      />
                                    ),
                                    desc: (
                                      <Image
                                        src={sortingDown}
                                        width={"16px"}
                                        height={"16px"}
                                      />
                                    ),
                                  }[header.column.getIsSorted()] ??
                                    (header.column.getCanSort() ? (
                                      <Image
                                        src={sortingUpDown}
                                        width={"16px"}
                                        height={"16px"}
                                      />
                                    ) : null)}
                                </Box>
                              )}
                            </Text>
                          </Box>
                        )}
                      </Th>
                    );
                  })}
                  {portal === "sp" && (
                    <Th
                      position={"sticky"}
                      right={"0"}
                      p={"12px"}
                      w={"40px"}
                      borderLeft={"1px solid #E6E7E9"}
                      bg="#F5F5F5"
                      zIndex={2}
                    >
                      <CustomizeColumns
                        table={table}
                        isColumnSelectionOpen={isColumnSelectionOpen}
                        onColumnSelectionOpen={onColumnSelectionOpen}
                        onCancelColumns={onCancelColumns}
                        columnPosition={columnPosition}
                        HTML5Backend={HTML5Backend}
                        columnOrderOptions={columnOrderOptions}
                        onSaveColumns={onSaveColumns}
                        reorderColumn={reorderColumn}
                        setColumnOrder={setColumnOrder}
                        columnVisibility={columnVisibility}
                        columnHandleChange={columnHandleChange}
                        hideDefaultCompanyField
                      />
                    </Th>
                  )}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => {
                let isRowRejected = true;
                return (
                  <Tr key={row.id} position={"relative"}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          color={"#2D3748"}
                          fontSize={"14px"}
                          lineHeight={"20px"}
                          fontWeight={"400"}
                          p="16px 12px"
                          style={cell.column?.columnDef?.style || {}}
                          onClick={() => {}}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                    {portal === "sp" && (
                      <Td
                        position={"sticky"}
                        right={"0"}
                        borderLeft={"1px solid #E6E7E9"}
                        bg={"#fff"}
                        p="16px 12px"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {
                          <CustomPopover
                            showPopover={openAction === row.original.id}
                            onOpen={() => {
                              setOpenAction(row.original.id);
                            }}
                            onClose={() => {
                              setOpenAction("");
                            }}
                            contentWidth="fit-content"
                            labelComponent={
                              <Box w="20px" minH={"20px"} minW="20px">
                                {
                                  // (isRecordEditable(row.original) ||
                                  //   isRecordDeletable(row.original)) &&
                                  <Image
                                    h="20px"
                                    cursor={"pointer"}
                                    w="20px"
                                    minH={"20px"}
                                    minW="20px"
                                    src={actionIcon}
                                  />
                                }
                              </Box>
                            }
                            bodyStyle={{ padding: "6px" }}
                            id={`assets-list-record-action-popover-${row.original.id}`}
                          >
                            <Box>
                              <Flex
                                _hover={{ bg: "#E7FAFD" }}
                                p="8px"
                                rounded={"4px"}
                                cursor={
                                  downloadLoading ? "not-allowed" : "pointer"
                                }
                                alignItems={"center"}
                                gap={"8px"}
                                onClick={() => {
                                  handleDownload(row.original);
                                }}
                              >
                                <Image
                                  boxSize={"16px"}
                                  src={
                                    row.original.isExternalLink
                                      ? externalLinkIcon
                                      : downloadIcon
                                  }
                                />
                                <Text
                                  color={"#383F47"}
                                  fontSize={"14px"}
                                  fontWeight={450}
                                  lineHeight={"20px"}
                                >
                                  {row.original.isExternalLink
                                    ? "Open Link"
                                    : "Download"}
                                </Text>
                                {downloadLoading && (
                                  <Spinner
                                    thickness="4px"
                                    speed="0.65s"
                                    emptyColor="gray.200"
                                    color="brand.100"
                                    size={"xs"}
                                  />
                                )}
                              </Flex>
                              {permissions.edit && (
                                <Flex
                                  _hover={{ bg: "#E7FAFD" }}
                                  p="8px"
                                  rounded={"4px"}
                                  cursor={"pointer"}
                                  alignItems={"center"}
                                  gap={"8px"}
                                  onClick={() => {
                                    setSelectedData(row.original);
                                    onNewFolderModalOpen();
                                  }}
                                >
                                  <Image boxSize={"16px"} src={editIcon} />
                                  <Text
                                    color={"#383F47"}
                                    fontSize={"14px"}
                                    fontWeight={450}
                                    lineHeight={"20px"}
                                  >
                                    {row?.original?.assetType === "Folder"
                                      ? "Rename Folder"
                                      : "Edit File"}
                                  </Text>
                                </Flex>
                              )}
                              {permissions.delete && (
                                <Flex
                                  _hover={{ bg: "#E7FAFD" }}
                                  p="8px"
                                  rounded={"4px"}
                                  cursor={"pointer"}
                                  alignItems={"center"}
                                  gap={"8px"}
                                  onClick={() => {
                                    onDeleteClick(row.original);
                                  }}
                                >
                                  <Image boxSize={"16px"} src={deleteIcon} />
                                  <Text
                                    color={"#C81E1E"}
                                    fontSize={"14px"}
                                    fontWeight={450}
                                    lineHeight={"20px"}
                                  >
                                    Delete
                                  </Text>
                                </Flex>
                              )}
                            </Box>
                          </CustomPopover>
                        }
                      </Td>
                    )}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        ) : (
          <Box
            height={`calc(100vh - 250px)`}
            display={"flex"}
            justifyContent={"center"}
          >
            <NoRecordsFound
              title={"No Asset Found"}
              description={
                portal === "sp"
                  ? "Try adding new asset using below Call to Action button."
                  : ""
              }
              imageUrl={emptyAssetIcon}
            />
          </Box>
        )}
      </Box>
      <ShareWithModal
        isOpen={isShareWithModalOpen}
        onClose={onShareWithModalClose}
        portal={portal}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        entitysList={entitysList}
        getPartnerTypes={getPartnerTypes}
        getPartnerGroups={getPartnerGroups}
        getPartnersByTypes={getPartnersByTypes}
        shareAsset={shareAsset}
        removeSharedPartners={removeSharedPartners}
        userInfo={userInfo}
        setRefetch={setRefetch}
        setNextToken={setNextToken}
        setLoading={setLoading}
        fetchPartnerOrProviderList={fetchPartnerOrProviderList}
        moduleForId="asset-list"
      />
    </Stack>
  );
};

export default AssetTable;
