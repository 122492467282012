import React from "react";
import { Spinner } from "@chakra-ui/react";

const CellLoadingComponent = ({ color, size }) => {
  return (
    <Spinner
      speed="0.65s"
      emptyColor="gray.200"
      color={color ? color : "brand.100"}
      size={size ? size : "xs"}
    />
  );
};

export default CellLoadingComponent;
