import { createSlice } from "@reduxjs/toolkit";
import { getGeneralNotificationList } from "./generalNotificationActions";

const initialState = {
  loading: false,
  notificationSettingList: [],
};

const hubSpot = {
  syncLead: "Leads Synced to HubSpot",
  syncDeal: "Deals Synced to HubSpot",
};

const generalNotificationSlice = createSlice({
  name: "generalNotificationSetting",
  initialState,
  reducers: {
    resetGeneralNotificationState: (state) => {
      state.loading = false;
      state.notificationSettingList = {};
    },
    updateNotificationRecord: (state, { payload }) => {
      const { notificationsModulesKey, notificationId, data } = payload;
      const index = state.notificationSettingList[
        notificationsModulesKey
      ].findIndex((notification) => notification.id === notificationId);
      state.notificationSettingList[notificationsModulesKey][index] = data;
    },
  },
  extraReducers: {
    [getGeneralNotificationList.pending]: (state) => {
      state.loading = true;
    },
    [getGeneralNotificationList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const leads = payload?.Lead ?? [];
      const deals = payload?.Deal ?? [];
      state.notificationSettingList = {
        ...payload,
        Lead_And_Deal: [
          ...leads?.filter((deal) => deal.eventName !== hubSpot.syncLead),
          ...deals?.filter((lead) => lead.eventName !== hubSpot.syncDeal),
        ],
      };
    },
    [getGeneralNotificationList.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { resetGeneralNotificationState, updateNotificationRecord } =
  generalNotificationSlice.actions;

export default generalNotificationSlice.reducer;
