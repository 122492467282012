import { Center, Flex, Text } from "@chakra-ui/react";

const NoDataChart = () => {
  return (
    <Center h={"inherit"}>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        direction={"column"}
        h={"100%"}
      >
        <Text color={"#9EA5AD"} fontSize={"14px"} fontWeight={500}>
          No Data Available.
        </Text>
        <Text color={"#9EA5AD"} fontSize={"14px"} fontWeight={500}>
          Please choose a broader date range
        </Text>
      </Flex>
    </Center>
  );
};

export default NoDataChart;
