import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../services/axiosInstance";
import { getErrorMessage } from "../../../services/utility";

export const getHubSpotProperty = createAsyncThunk(
  "get/getHubspotProperties",
  async (integrationId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/hubspot/getHubspotProperties`);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getSyncedReferralsList = async (requestData) => {
  try {
    // configure header's Content-Type as JSON
    const { data } = await axiosInstance.post("/hubspot/list", requestData);
    return data;
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return error.response.data;
    } else {
      return error.message;
    }
  }
};

export const manualSync = createAsyncThunk(
  "post/manualSync",
  async (integrationId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(`/hubspot/sync`, {});
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
