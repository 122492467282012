import React, { useEffect, useMemo, useState } from "react";
import { Flex, Stack } from "@chakra-ui/react";
import { useRBAC } from "../../services/customHook";
import NotificationTable from "./NotificationTable";
import { scrollbarCSS } from "@coachbar/shared-components/src/constants/constantValues";
import { getGeneralNotificationList } from "./generalNotificationActions";
import { useDispatch, useSelector } from "react-redux";
import { resetGeneralNotificationState } from "./generalNotificationSlice";
import { Loader } from "@coachbar/shared-components";
import { generalSetting } from "../../constants/moduleNames";
import NotificatiFormModal from "./NotificatiFormModal";
import {
  insertParameter,
  notificationsModules,
} from "../../constants/notifications";
import { getCustomFormByTenantId } from "../generalSettings/customForms/CustomFormActions";

const GeneralNotifications = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { hasActionPermission } = useRBAC();
  const permissions = useMemo(
    () => ({
      add: hasActionPermission(generalSetting, "add"),
      edit: hasActionPermission(generalSetting, "edit"),
      view: hasActionPermission(generalSetting, "view"),
      delete: hasActionPermission(generalSetting, "delete"),
    }),
    [hasActionPermission]
  );
  const { loading, notificationSettingList } = useSelector(
    (state) => state.generalNotifications
  );
  const [currNotification, setCurrNotification] = useState(null);
  const [customForm, setCustomForm] = useState([]);

  useEffect(() => {
    dispatch(getGeneralNotificationList());
    return () => dispatch(resetGeneralNotificationState());
  }, []);

  let initializeCustomForm = false;
  useEffect(() => {
    if (!initializeCustomForm) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      initializeCustomForm = true;
      const getCustomForm = async () => {
        let { data } = await getCustomFormByTenantId(userInfo.tenantId);
        if (data?.customForm?.formMapping?.length > 0) {
          const filtered =
            data?.customForm?.formMapping?.filter((x) => x.added && x.id > 5) ||
            [];
          setCustomForm(filtered);
        }
      };
      getCustomForm();
    }
  }, []);

  const handleEditClick = (notificationData, notificationsModulesKey) => {
    setCurrNotification({ ...notificationData, notificationsModulesKey });
  };

  const handleCloseForm = () => setCurrNotification(null);

  return (
    <Stack
      padding={"24px"}
      maxH="calc(100vh - 64px)"
      overflowY="scroll"
      css={scrollbarCSS}
    >
      {loading ? (
        <Flex h="80vh" alignItems={"center"} justifyContent={"center"}>
          <Loader />
        </Flex>
      ) : (
        notificationsModules.map((notificationsModule) => (
          <NotificationTable
            key={notificationsModule.key}
            cardTitle={notificationsModule.moduleTitle}
            permissions={permissions}
            moduleNotifications={
              notificationSettingList?.[notificationsModule.key] || []
            }
            handleEditClick={handleEditClick}
            notificationsModulesKey={notificationsModule.key}
          />
        ))
      )}
      <NotificatiFormModal
        currNotification={currNotification}
        onClose={handleCloseForm}
        insertParameter={insertParameter}
        customForm={customForm}
      />
    </Stack>
  );
};

export default GeneralNotifications;
