import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useClearState } from "../../services/customHook";
import { Box, Center, Flex, Stack, Text } from "@chakra-ui/react";
import { Button, Link, OtpVerification } from "@coachbar/shared-components";
import { requiredField } from "@coachbar/shared-components/src/constants/validationMessages";
import { forgotPassword, verifyResetPasswordOtp } from "./authActions";
import { buttonVariants } from "@coachbar/shared-components/src/constants/constantValues";

const VerifyOtp = ({ setisOtpVerified, email, userInfo }) => {
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onChange", criteriaMode: "all" });
  const navigate = useNavigate();
  useClearState();

  useEffect(() => {
    if (!email) {
      navigate("/login");
    }
  }, [email, navigate]);

  const submitForm = () => {
    if (otpValue) {
      let requestParams = {
        email,
        verificationCode: otpValue,
      };
      dispatch(verifyResetPasswordOtp(requestParams)).then((responseData) => {
        if (responseData.payload && responseData.payload.success) {
          setisOtpVerified(true);
        }
      });
    }
  };

  const [otpValue, setOtpValue] = useState("");

  const handleChange = (otpValue) => {
    setOtpValue(otpValue);
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(submitForm)} noValidate>
        <div className="form-group">
          <Text
            fontSize="20px"
            lineHeight={"30px"}
            fontWeight="700"
            mb="16px"
            color="atlantic.700"
            textAlign="center"
          >
            Enter confirmation code
          </Text>
          <Box
            color="brandGray.500"
            fontSize="16px"
            lineHeight="24px"
            fontWeight={450}
            textAlign="center"
            mb="32px"
            px="0px 18px"
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexWrap={"wrap"}
          >
            We sent a code to&nbsp;
            <Box whiteSpace={"nowrap"}>
              <Text
                color="atlantic.700"
                fontSize="16px"
                lineHeight="24px"
                fontWeight={600}
                display={"inline-block"}
                whiteSpace={"pre-wrap"}
                wordBreak={"break-all"}
              >
                {email}
                <Text color={"brandGray.500"} display={"inline-block"}>
                  .
                </Text>
              </Text>
            </Box>
          </Box>
          <Center>
            <OtpVerification
              name="otp"
              onChange={handleChange}
              value={otpValue}
              innerref={register("otp", {
                required: requiredField.replace("$Field$", "OTP"),
                onChange: () => handleChange,
              })}
              validationErrors={errors}
            />
          </Center>
        </div>
        <Stack mt={"16px"}>
          <Button
            type="submit"
            variant={"newColorThemePrimary"}
            isLoading={loading}
            title="Continue"
            id="new-password-change-password-submit"
          />
        </Stack>
        <Flex
          mt="20px"
          alignItems={"center"}
          justifyContent={"center"}
          gap="8px"
        >
          <Text
            color="brandGray.400"
            fontSize="14px"
            lineHeight="20px"
            fontWeight={450}
            textAlign="center"
          >
            Didn’t receive the email?
          </Text>
          <Button
            display={"inline-block"}
            p="0px"
            title="Click to resend"
            height="20px"
            lineHeight="20px"
            isDisabled={loading}
            variant={buttonVariants.brandPrimaryLinkButton}
            onClick={() => dispatch(forgotPassword({ email }))}
          />
        </Flex>
      </form>
      <Flex
        mt="20px"
        position={"absolute"}
        bottom={"60px"}
        left={"50%"}
        transform={"translate(-50%, 0)"}
        alignItems={"center"}
        gap={"8px"}
      >
        <Text
          color="brandGray.400"
          fontSize="14px"
          lineHeight="20px"
          fontWeight={450}
        >
          Still have issue?
        </Text>
        <Link
          decoration="none"
          fontSize={"14px"}
          fontWeight={500}
          color={"cyprus.500"}
          lineHeight={"20px"}
          to="mailto:support@coachbar.io"
        >
          Contact Support
        </Link>
      </Flex>
    </React.Fragment>
  );
};

export default VerifyOtp;
