import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Flex,
  Text,
  useDisclosure,
  Input,
  SimpleGrid,
  Tooltip,
  Image,
  Textarea,
  HStack,
  Center,
  Tabs,
  TabList,
  Tab,
  TabIndicator,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import CustomDrawer from "@coachbar/shared-components/src/components/Drawer/Drawer";
import { CloseIcon } from "@chakra-ui/icons";
import { Button, Link, Loader } from "@coachbar/shared-components";
import SendInquiryModal from "./sendInquiryModal";
import { format } from "date-fns";
import {
  deleteIconSvg,
  downloadIcon,
  editIconSvg,
  errorTriangle,
  noInquiriesIcon,
  uploadIcon,
} from "@coachbar/shared-components/src/components/Icons/SvgsConstants";
import PdfIcon from "@coachbar/shared-components/src/images/pdf-file.svg";
import FileIcon from "../../image/file-icon.png";
import TrashIcon from "@coachbar/shared-components/src/images/trash-grey.svg";
import {
  getPreSignedUrl,
  getUserInfo,
  uploadImage,
} from "../../services/utility";
import CellLoadingComponent from "@coachbar/shared-components/src/components/Loader/CellLoadingComponent";
import { addNotes, deleteNote, editNote } from "./rewardsActions";
import { scrollbarCSS } from "@coachbar/shared-components/src/constants/constantValues";
import { getAllUsers } from "../settings/usersAndRoles/usersAndRolesActions";
const MAX_PDF_SIZE = 10 * 1024 * 1024; /* 10  MB */

const InquiriesDrawer = ({
  planId,
  isInquiryFetching,
  isNotesFetching,
  inquiryListNextToken,
  noteListNextToken,
  requestData,
  commissionId,
  inquiryList,
  noteList,
  fetchNoteList,
  fetchInquiryList,
  isOpen,
  onClose,
}) => {
  const inquiryContainerRef = useRef(null);
  const noteContainerRef = useRef(null);
  const [fileError, setFileError] = useState("");
  const [noteValue, setNoteValue] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [repliesData, setRepliesData] = useState(null);
  const [deleteNoteLoading, setDeleteNoteLoading] = useState("");
  const [selectedNote, setSelectedNote] = useState(null);
  const [userNames, setUserNames] = useState({});
  const [isShowReplay, setIsShowReplay] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [messageId, setMessageId] = useState("");
  const userInfo = getUserInfo();
  const {
    onOpen: onSendInquiryOpen,
    isOpen: isSendInquiryOpen,
    onClose: onSendInquiryClose,
  } = useDisclosure();

  const fetchUsers = async () => {
    const users = await getAllUsers();
    if (users) {
      let result = {};
      users.forEach((x) => {
        result[x.id] = `${x.firstName} ${x.lastName}`;
      });
      setUserNames(result);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const onFileUpload = async (e) => {
    setFileError("");
    let files = [...e.target.files];
    if (files.find((file) => file.type !== "application/pdf")) {
      setFileError("Only Pdf files supported");
      return;
    }
    if (files.find((file) => file.size > MAX_PDF_SIZE)) {
      setFileError("File Size is more then 10 MB");
      return;
    }
    setUploadLoading(true);

    const uploadedFileUrls = await uploadFiles(files);
    setFileList(uploadedFileUrls);
    setUploadLoading(false);
  };

  const uploadImageFileToS3 = async (fileObj, preSignedUrl, onSuccess) => {
    await uploadImage(fileObj, preSignedUrl).then((responseData) => {
      if (responseData.status === 200) {
        let imageUrl = preSignedUrl.split("?")[0];
        onSuccess(imageUrl);
        return imageUrl;
      }
    });
  };

  const uploadFiles = async (files, setIsLoading) => {
    const promises = [];
    let fileData = [];

    for (const fileObj of files) {
      let [fileName, fileExt] = fileObj.name.split(".");
      let filePath = fileName + "-" + Date.now() + "." + fileExt;

      promises.push(
        getPreSignedUrl(filePath)
          .then((responseData) => {
            if (responseData.data && responseData.data.success) {
              const preSignedUrl = responseData.data?.data?.signedUrl;
              return uploadImageFileToS3(fileObj, preSignedUrl, (url) => {
                fileData.push(url);
              });
            }
          })
          .catch((error) => {
            console.log("Error in Presigned Url API", error);
          })
      );
    }

    try {
      await Promise.allSettled(promises);
      // return promises.filter((promise) => promise !== undefined);
      return fileData;
    } catch (error) {
      console.log("Error in uploadFiles", error);
    } finally {
      // setUploadLoading(false);
    }
  };

  const onDeleteClickFile = async (fileUrl, index) => {
    const updatedList = fileList?.filter((x, i) => i !== index);
    setFileList(updatedList);
  };

  const handleSaveNote = async () => {
    setLoading(true);
    if (selectedNote) {
      const res = await editNote(selectedNote?.id, {
        tenantId: userInfo?.tenantId,
        userId: userInfo?.id,
        noteValue: noteValue,
        noteAttachments: fileList,
      });
      if (res && res?.data) {
        setFileList([]);
        setNoteValue("");
        setFileError("");
        fetchNoteList();
        setSelectedNote(null);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      const res = await addNotes({
        tenantId: userInfo?.tenantId,
        userId: userInfo?.id,
        noteValue: noteValue,
        noteType: "User Inquiries",
        referenceTenantId: commissionId,
        noteAttachments: fileList,
      });
      if (res && res?.data) {
        setFileList([]);
        setNoteValue("");
        setFileError("");
        fetchNoteList();
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const handleDeleteNote = async (id) => {
    setDeleteNoteLoading(id);
    await deleteNote(id).then((res) => {
      if (res?.code === 200) {
        setDeleteNoteLoading("");
        fetchNoteList();
      } else {
        setDeleteNoteLoading("");
      }
    });
  };

  const handleEditNote = (note) => {
    if (noteContainerRef?.current) {
      noteContainerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      }); // Scroll to the top
    }
    setSelectedNote(note);
    setFileList(note?.noteAttachments);
    setNoteValue(note?.noteValue);
  };

  const fetchMoreInquiryOnBottomReached = React.useCallback(
    (containerRefElement) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        if (
          scrollHeight - scrollTop - clientHeight < 100 &&
          !isInquiryFetching &&
          inquiryListNextToken
        ) {
          fetchInquiryList(true);
        }
      }
    },
    [inquiryListNextToken, isInquiryFetching]
  );

  const fetchMoreNotesOnBottomReached = React.useCallback(
    (containerRefElement) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        if (
          scrollHeight - scrollTop - clientHeight < 100 &&
          !isNotesFetching &&
          noteListNextToken
        ) {
          fetchNoteList(true);
        }
      }
    },
    [noteListNextToken, isNotesFetching]
  );

  return (
    <React.Fragment>
      <CustomDrawer
        minW={"540px"}
        spacingTop={"65px !important"}
        isOpen={isOpen}
        onClose={() => {
          setFileList([]);
          setNoteValue("");
          setFileError("");
          setSelectedTab(0);
          onClose();
        }}
        displayFooter={false}
        saveButtonTitle="Submit"
        discardTitle="Cancel"
        bodyPadding="0"
        headerPadding="0"
        borderBottomColor={"#E6E7E9"}
        overlayShow={false}
        closeOnOverlayClick={true}
        title={
          <Flex
            w="100%"
            p={"8px 18px"}
            justifyContent={"space-between"}
            alignItems={"center"}
            position={"relative"}
            bg={"#F9FAFB"}
          >
            <Text fontSize={"14px"} fontWeight={"700"} color={"#111A29"}>
              Inquiries
            </Text>
            <Button
              variant={"newColorThemePrimary"}
              border={"1px solid #0C94AC"}
              bg={"transparent"}
              color={"#0C94AC"}
              title={"Send Inquiry"}
              borderRadius="6px"
              onClick={onSendInquiryOpen}
              id="rewards-inquiry-send"
            />
            <Flex
              cursor={"pointer"}
              onClick={() => {
                setFileList([]);
                setNoteValue("");
                setFileError("");
                onClose();
              }}
              position={"absolute"}
              left={"-40px"}
              top={0}
              w="40px"
              h={"40px"}
              bg={"#fff"}
              padding={"15px"}
              color={"#5E6977"}
              borderLeftRadius={"4px"}
              borderRight={"1px solid #E6E7E9"}
            >
              <CloseIcon w={"10px"} h={"10px"} />
            </Flex>
          </Flex>
        }
      >
        <Tabs
          position={"relative"}
          variant="unstyled"
          defaultIndex={0}
          onChange={(ind) => {
            setSelectedTab(ind);
          }}
        >
          <TabList
            boxShadow="0px 2px 4px 0px #E5E7EB"
            borderBottom={"1px solid #E7FAFD"}
            gap={"24px"}
            px={"24px"}
          >
            <Tab
              py={"18px"}
              px={0}
              fontSize={"14px"}
              fontWeight={500}
              color={selectedTab === 0 ? "#0C94AC" : "#7E8792"}
              isSelected={selectedTab === 0}
            >
              Inquiries
            </Tab>
            <Tab
              py={"18px"}
              px={0}
              fontSize={"14px"}
              fontWeight={500}
              color={selectedTab === 1 ? "#0C94AC" : "#7E8792"}
              isSelected={selectedTab === 1}
            >
              Notes
            </Tab>
          </TabList>
          <TabIndicator
            height="3px"
            bg="#0C94AC"
            top={"55px"}
            borderRadius="2px 2px 0px 0px"
          />
          <TabPanels>
            <TabPanel p="0">
              <Box
                p={"24px"}
                onScroll={(e) => fetchMoreInquiryOnBottomReached(e.target)}
                ref={inquiryContainerRef}
                maxH={"calc(100vh - 180px)"}
                overflow={"auto"}
                css={scrollbarCSS}
              >
                {inquiryList?.length > 0 ? (
                  inquiryList?.map((item) => {
                    return (
                      <Box
                        border="1px solid #DEE8F8"
                        rounded={"8px"}
                        p={"12px 16px"}
                        mb={"16px"}
                        key={item?.id}
                      >
                        <HStack justifyContent={"space-between"} mb={"12px"}>
                          <Text
                            fontSize={"14px"}
                            fontWeight={500}
                            color={"#111A29"}
                          >
                            Inquiry{" "}
                            {item?.dealReadableId || item?.leadReadableId
                              ? `on id #${
                                  item?.dealReadableId || item?.leadReadableId
                                }${" "}`
                              : ""}
                            <span style={{ color: "#7E8792" }}>by</span>{" "}
                            {item?.username || "User"}
                          </Text>
                          <Text
                            fontSize={"12px"}
                            fontWeight={400}
                            color={"#7E8792"}
                          >
                            {item?.modified &&
                              format(new Date(item?.modified), "MMM dd, yyyy")}
                          </Text>
                        </HStack>
                        <Text
                          fontSize={"14px"}
                          color={"#7E8792"}
                          fontWeight={400}
                        >
                          {item?.message?.length > 100 ? (
                            <>
                              {messageId === item?.id ? (
                                <>
                                  {item?.message}{" "}
                                  <span
                                    style={{
                                      color: "#0C94AC",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setMessageId("")}
                                  >
                                    Show Less
                                  </span>
                                </>
                              ) : (
                                <>
                                  {item?.message?.slice(0, 100)}...{" "}
                                  <span
                                    style={{
                                      color: "#0C94AC",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setMessageId(item?.id)}
                                  >
                                    View More
                                  </span>
                                </>
                              )}
                            </>
                          ) : (
                            item?.message
                          )}
                        </Text>
                        {item?.attachments?.length > 0 && (
                          <SimpleGrid columns={2} spacing={"16px"} mt={"16px"}>
                            {item?.attachments?.map((fileObj, i, allFiles) => {
                              let fileElement = (file) => {
                                const fileUrl = file?.split("?")[0];
                                const fileName = fileUrl?.split("/")?.pop();
                                const nameWithoutTimestamp = fileName.replace(
                                  /\-\d+/,
                                  ""
                                );

                                return (
                                  <Flex
                                    key={`${fileObj} - ${i}`}
                                    alignItems={"center"}
                                    padding={"8px 12px"}
                                    border={"1px solid #E6E7E9"}
                                    borderRadius={"8px"}
                                  >
                                    <Image src={FileIcon} boxSize={"40px"} />
                                    <Box ml="12px" flexGrow={1}>
                                      <Text
                                        fontWeight={"500"}
                                        fontSize={"14px"}
                                        // mb="4px"
                                        color="#111A29"
                                        css={{
                                          "a,a:hover": {
                                            textDecoration: "none",
                                          },
                                        }}
                                      >
                                        <Link to={fileUrl} target="_blank">
                                          <Tooltip label={nameWithoutTimestamp}>
                                            {nameWithoutTimestamp.length > 14
                                              ? nameWithoutTimestamp.substring(
                                                  0,
                                                  14
                                                ) + "..."
                                              : nameWithoutTimestamp}
                                          </Tooltip>
                                        </Link>
                                      </Text>
                                    </Box>
                                    <Tooltip label={"Download"} placement="top">
                                      <Box
                                        cursor={"pointer"}
                                        onClick={() =>
                                          window.open(fileObj, "_blank")
                                        }
                                      >
                                        {downloadIcon}
                                      </Box>
                                    </Tooltip>
                                  </Flex>
                                );
                              };
                              return (
                                <Box width={"100%"}>
                                  {fileElement(fileObj)}{" "}
                                </Box>
                              );
                            })}
                          </SimpleGrid>
                        )}
                        {isShowReplay === item?.id &&
                          item?.replies?.length > 0 &&
                          item?.replies?.map((x) => {
                            return (
                              <Box
                                mt={"20px"}
                                ml={"8px"}
                                pl={"16px"}
                                borderLeft={"2px solid #E6E7E9"}
                              >
                                <HStack
                                  justifyContent={"space-between"}
                                  mb={"12px"}
                                >
                                  <Text
                                    fontSize={"14px"}
                                    fontWeight={500}
                                    color={"#111A29"}
                                  >
                                    Reply{" "}
                                    <span style={{ color: "#7E8792" }}>by</span>{" "}
                                    {x?.username || "User"}
                                  </Text>
                                  <Text
                                    fontSize={"12px"}
                                    fontWeight={400}
                                    color={"#7E8792"}
                                  >
                                    {x?.modified &&
                                      format(
                                        new Date(x?.modified),
                                        "MMM dd, yyyy"
                                      )}
                                  </Text>
                                </HStack>
                                <Text
                                  fontSize={"14px"}
                                  color={"#7E8792"}
                                  fontWeight={400}
                                  noOfLines={1}
                                >
                                  {x?.message}
                                </Text>
                                {x?.attachments?.length > 0 && (
                                  <SimpleGrid
                                    columns={2}
                                    spacing={"16px"}
                                    mt={"16px"}
                                  >
                                    {x?.attachments?.map(
                                      (fileObj, i, allFiles) => {
                                        let fileElement = (file) => {
                                          const fileUrl = file?.split("?")[0];
                                          const fileName = fileUrl
                                            ?.split("/")
                                            ?.pop();
                                          const nameWithoutTimestamp =
                                            fileName.replace(/\-\d+/, "");

                                          return (
                                            <Flex
                                              key={`${fileObj} - ${i}`}
                                              alignItems={"center"}
                                              padding={"8px 12px"}
                                              border={"1px solid #E6E7E9"}
                                              borderRadius={"8px"}
                                            >
                                              <Image
                                                src={FileIcon}
                                                boxSize={"40px"}
                                              />
                                              <Box ml="12px" flexGrow={1}>
                                                <Text
                                                  fontWeight={"500"}
                                                  fontSize={"14px"}
                                                  // mb="4px"
                                                  color="#111A29"
                                                  css={{
                                                    "a,a:hover": {
                                                      textDecoration: "none",
                                                    },
                                                  }}
                                                >
                                                  <Link
                                                    to={fileUrl}
                                                    target="_blank"
                                                  >
                                                    <Tooltip
                                                      label={
                                                        nameWithoutTimestamp
                                                      }
                                                    >
                                                      {nameWithoutTimestamp.length >
                                                      14
                                                        ? nameWithoutTimestamp.substring(
                                                            0,
                                                            14
                                                          ) + "..."
                                                        : nameWithoutTimestamp}
                                                    </Tooltip>
                                                  </Link>
                                                </Text>
                                              </Box>
                                              <Box
                                                cursor={"pointer"}
                                                onClick={() =>
                                                  window.open(fileObj, "_blank")
                                                }
                                              >
                                                {downloadIcon}
                                              </Box>
                                            </Flex>
                                          );
                                        };
                                        return (
                                          <Box width={"100%"}>
                                            {fileElement(fileObj)}{" "}
                                          </Box>
                                        );
                                      }
                                    )}
                                  </SimpleGrid>
                                )}
                              </Box>
                            );
                          })}
                        <HStack justifyContent={"end"} mt={"4px"}>
                          {item?.replies?.length > 0 ? (
                            <>
                              <Text
                                fontSize={"14px"}
                                fontWeight={700}
                                color={"#0C94AC"}
                                cursor={"pointer"}
                                onClick={() =>
                                  setIsShowReplay(
                                    isShowReplay === item?.id ? "" : item?.id
                                  )
                                }
                              >
                                {isShowReplay === item?.id
                                  ? "Hide Reply"
                                  : "View Reply"}
                              </Text>
                              {isShowReplay === item?.id && (
                                <Text
                                  fontSize={"14px"}
                                  fontWeight={700}
                                  color={"#0C94AC"}
                                  cursor={"pointer"}
                                  onClick={() => {
                                    setRepliesData(item);
                                    onSendInquiryOpen();
                                  }}
                                >
                                  | Add a Reply
                                </Text>
                              )}
                            </>
                          ) : (
                            <Text
                              fontSize={"14px"}
                              fontWeight={700}
                              color={"#0C94AC"}
                              cursor={"pointer"}
                              onClick={() => {
                                setRepliesData(item);
                                onSendInquiryOpen();
                              }}
                            >
                              Add a Reply
                            </Text>
                          )}
                        </HStack>
                      </Box>
                    );
                  })
                ) : (
                  <Flex
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    h={"calc(100vh - 235px)"}
                  >
                    {noInquiriesIcon}
                    <Text
                      fontSize={"16px"}
                      fontWeight={700}
                      color={"#111A29"}
                      mt={"24px"}
                    >
                      No Inquiries Found
                    </Text>
                    <Text fontSize={"14px"} fontWeight={500} color={"#7E8792"}>
                      You don’t have any inquiries right now.
                    </Text>
                  </Flex>
                )}
              </Box>
            </TabPanel>
            <TabPanel p="0">
              <Box
                p={"24px"}
                onScroll={(e) => fetchMoreNotesOnBottomReached(e.target)}
                ref={noteContainerRef}
                maxH={"calc(100vh - 180px)"}
                overflow={"auto"}
                css={scrollbarCSS}
              >
                <Box mb={"24px"} borderBottom={"1px solid #E6E7E9"}>
                  <Text
                    fontSize={"14px"}
                    fontWeight={"700"}
                    color={"#111A29"}
                    mb={"12px"}
                  >
                    Inquiry Notes
                  </Text>
                  <Textarea
                    border={"1px solid #E6E7E9"}
                    focusBorderColor={"#0C94AC"}
                    boxShadow={"none !important"}
                    fontSize={"14px"}
                    value={noteValue}
                    maxLength={656}
                    onChange={(e) => setNoteValue(e.target.value)}
                    placeholder="Add notes...."
                    size="sm"
                  />
                  <HStack justifyContent={"space-between"} mt={"16px"}>
                    <Box
                      pos={"relative"}
                      p="8px 16px"
                      bg={"#F9FAFB"}
                      border="1px solid #E6E7E9"
                      borderRadius={"4px"}
                      cursor={uploadLoading ? "not-allowed" : "pointer"}
                      w={"max-content"}
                    >
                      <Flex
                        color={"#5E6977"}
                        css={{
                          path: { stroke: "#7E8792" },
                        }}
                        justifyContent={"center"}
                      >
                        {uploadIcon}
                        <Text ml="8px" fontSize={"14px"} lineHeight={"20px"}>
                          Browse File
                        </Text>
                      </Flex>
                      {!uploadLoading && (
                        <Input
                          type="file"
                          accept=".pdf"
                          height="100%"
                          width="100%"
                          position="absolute"
                          top="0"
                          left="0"
                          // isDisabled={uploadLoading}
                          onChange={(e) => onFileUpload(e)}
                          cursor={"pointer"}
                          opacity="0 !important"
                          // multiple
                        />
                      )}
                    </Box>

                    <Box>
                      <Button
                        variant={"newColorThemePrimary"}
                        title={"Clear"}
                        color={"#7E8792"}
                        bg={"transparent"}
                        border={"1px solid #E6E7E9"}
                        isLoading={uploadLoading || loading}
                        onClick={() => {
                          setLoading(true);
                          setFileList([]);
                          setNoteValue("");
                          setFileError("");
                          setSelectedNote(null);
                          setLoading(false);
                        }}
                        id="rewards-inquiry-clear"
                      />
                      <Button
                        ml={"16px"}
                        variant={"newColorThemePrimary"}
                        title={"Save Note"}
                        isDisabled={!noteValue?.trim()}
                        isLoading={uploadLoading || loading}
                        onClick={handleSaveNote}
                        id="rewards-inquiry-note-save"
                      />
                    </Box>
                  </HStack>

                  <Text
                    fontWeight={"500"}
                    fontSize={"12px"}
                    color={"#9EA5AD"}
                    mt={"4px"}
                    mb={"16px"}
                  >
                    We support PDFs under 10MB.
                  </Text>
                  {fileError && (
                    <Flex alignItems={"center"} mt="8px">
                      <Box boxSize={"16px"} mr="8px">
                        {errorTriangle}
                      </Box>
                      <Text
                        fontSize={"12px"}
                        color={"#C81E1E"}
                        fontWeight={"500"}
                      >
                        {fileError}
                      </Text>
                    </Flex>
                  )}
                  {uploadLoading ||
                    (fileList?.length > 0 && (
                      <Box className="pdf-file-list" mb="20px">
                        {uploadLoading ? (
                          <Flex gap={"8px"} alignItems={"center"}>
                            <CellLoadingComponent
                              color={"#0C94AC"}
                              size={"sm"}
                            />
                            <Text
                              color={"#556781"}
                              fontSize={"12px"}
                              fontWeight={500}
                            >
                              Uploading file
                            </Text>
                          </Flex>
                        ) : (
                          <SimpleGrid columns={2} spacing={"16px"}>
                            {fileList?.map((fileObj, i, allFiles) => {
                              let fileElement = (file) => {
                                const fileUrl = file?.split("?")[0];
                                const fileName = fileUrl?.split("/")?.pop();
                                const nameWithoutTimestamp = fileName.replace(
                                  /\-\d+/,
                                  ""
                                );

                                return (
                                  <Flex
                                    key={`${fileObj} - ${i}`}
                                    alignItems={"center"}
                                    padding={"8px 12px"}
                                    border={"1px solid #E6E7E9"}
                                    borderRadius={"8px"}
                                    justifyContent={"space-between"}
                                  >
                                    <Flex alignItems={"center"}>
                                      <Image src={PdfIcon} boxSize={"40px"} />
                                      <Box ml="12px">
                                        <Text
                                          fontWeight={"500"}
                                          fontSize={"14px"}
                                          // mb="4px"
                                          color="#111A29"
                                          css={{
                                            "a,a:hover": {
                                              textDecoration: "none",
                                            },
                                          }}
                                        >
                                          <Link to={fileUrl} target="_blank">
                                            <Tooltip
                                              label={nameWithoutTimestamp}
                                            >
                                              {nameWithoutTimestamp.length > 14
                                                ? nameWithoutTimestamp.substring(
                                                    0,
                                                    14
                                                  ) + "..."
                                                : nameWithoutTimestamp}
                                            </Tooltip>
                                          </Link>
                                        </Text>
                                      </Box>
                                    </Flex>
                                    <Box>
                                      <Image
                                        onClick={() =>
                                          onDeleteClickFile(file, i)
                                        }
                                        cursor={"pointer"}
                                        src={TrashIcon}
                                        boxSize={"16px"}
                                      />
                                    </Box>
                                  </Flex>
                                );
                              };
                              return (
                                <Box width={"100%"}>
                                  {fileElement(fileObj)}{" "}
                                </Box>
                              );
                            })}
                          </SimpleGrid>
                        )}
                      </Box>
                    ))}
                </Box>
                {noteList?.map((item) => {
                  return (
                    <Box
                      border="1px solid #DEE8F8"
                      rounded={"8px"}
                      p={"12px 16px"}
                      mb={"16px"}
                    >
                      {deleteNoteLoading === item?.id ? (
                        <Center>
                          <Loader />
                        </Center>
                      ) : (
                        <>
                          <HStack justifyContent={"space-between"} mb={"12px"}>
                            <Text
                              fontSize={"14px"}
                              fontWeight={500}
                              color={"#111A29"}
                            >
                              Note <span style={{ color: "#7E8792" }}>by</span>{" "}
                              {userNames[item?.userId] || "User"}
                            </Text>
                            <Text
                              fontSize={"12px"}
                              fontWeight={400}
                              color={"#7E8792"}
                            >
                              {item?.modified &&
                                format(
                                  new Date(item?.modified),
                                  "MMM dd, yyyy"
                                )}
                            </Text>
                          </HStack>
                          <HStack>
                            <Text
                              flexGrow={1}
                              fontSize={"14px"}
                              color={"#7E8792"}
                              fontWeight={400}
                            >
                              {item?.noteValue}
                            </Text>
                            <HStack pl={"40px"}>
                              {uploadLoading ? (
                                <CellLoadingComponent
                                  color={"#0C94AC"}
                                  size={"sm"}
                                />
                              ) : (
                                <Tooltip label={"Edit"} placement="top">
                                  <Box
                                    cursor={"pointer"}
                                    onClick={() => handleEditNote(item)}
                                  >
                                    {editIconSvg}
                                  </Box>
                                </Tooltip>
                              )}
                              {uploadLoading ? (
                                <CellLoadingComponent
                                  color={"#0C94AC"}
                                  size={"sm"}
                                />
                              ) : (
                                <Tooltip label={"Delete"} placement="top">
                                  <Box
                                    cursor={"pointer"}
                                    onClick={() => handleDeleteNote(item?.id)}
                                  >
                                    {deleteIconSvg}
                                  </Box>
                                </Tooltip>
                              )}
                            </HStack>
                          </HStack>
                          {item?.noteAttachments?.length > 0 && (
                            <SimpleGrid
                              columns={2}
                              spacing={"16px"}
                              mt={"16px"}
                            >
                              {item?.noteAttachments?.map(
                                (fileObj, i, allFiles) => {
                                  let fileElement = (file) => {
                                    const fileUrl = file?.split("?")[0];
                                    const fileName = fileUrl?.split("/")?.pop();
                                    const nameWithoutTimestamp =
                                      fileName.replace(/\-\d+/, "");

                                    return (
                                      <Flex
                                        key={`${fileObj} - ${i}`}
                                        alignItems={"center"}
                                        padding={"8px 12px"}
                                        border={"1px solid #E6E7E9"}
                                        borderRadius={"8px"}
                                      >
                                        <Image src={PdfIcon} boxSize={"40px"} />
                                        <Box ml="12px" flexGrow={1}>
                                          <Text
                                            fontWeight={"500"}
                                            fontSize={"14px"}
                                            // mb="4px"
                                            color="#111A29"
                                            css={{
                                              "a,a:hover": {
                                                textDecoration: "none",
                                              },
                                            }}
                                          >
                                            <Link to={fileUrl} target="_blank">
                                              <Tooltip
                                                label={nameWithoutTimestamp}
                                              >
                                                {nameWithoutTimestamp.length >
                                                14
                                                  ? nameWithoutTimestamp.substring(
                                                      0,
                                                      14
                                                    ) + "..."
                                                  : nameWithoutTimestamp}
                                              </Tooltip>
                                            </Link>
                                          </Text>
                                        </Box>
                                        <Tooltip
                                          label={"Download"}
                                          placement="top"
                                        >
                                          <Box
                                            cursor={"pointer"}
                                            onClick={() =>
                                              window.open(fileObj, "_blank")
                                            }
                                          >
                                            {downloadIcon}
                                          </Box>
                                        </Tooltip>
                                      </Flex>
                                    );
                                  };
                                  return (
                                    <Box width={"100%"}>
                                      {fileElement(fileObj)}{" "}
                                    </Box>
                                  );
                                }
                              )}
                            </SimpleGrid>
                          )}
                        </>
                      )}
                    </Box>
                  );
                })}
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </CustomDrawer>
      <SendInquiryModal
        planId={planId}
        requestData={requestData}
        repliesData={repliesData}
        setRepliesData={setRepliesData}
        fetchInquiryList={fetchInquiryList}
        inquiryType="General"
        isOpen={isSendInquiryOpen}
        onClose={onSendInquiryClose}
      />
    </React.Fragment>
  );
};

export default InquiriesDrawer;
