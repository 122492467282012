import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../services/axiosInstance";
import { getErrorMessage } from "../../../services/utility";

export const getInvitationsList = async (requestData) => {
  try {
    let { data } = await axiosInstance.post(
      `/sp/provider/invite/list`,
      requestData
    );
    return data.data;
  } catch (error) {
    // handleError(error);
    getErrorMessage(error);
  }
};

export const invitationUpdate = createAsyncThunk(
  "sp/partner/acceptInvite",
  async (requestData, { rejectWithValue }) => {
    try {
      let { data } = await axiosInstance.post(`/sp/partner/acceptInvite`, {
        ...requestData,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const invitationDelete = createAsyncThunk(
  "/sp/provider/deleteInvite",
  async (requestData, { rejectWithValue }) => {
    try {
      let { data } = await axiosInstance.post(`/sp/provider/deleteInvite`, {
        ...requestData,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getPartnerProgramDetails = async (requestData) => {
  try {
    let { data } = await axiosInstance.post(`sp/provider/partnerType/detail`, {
      ...requestData,
    });
    return data;
  } catch (error) {}
};

export const getPartnerPrograms = async (tenantId) => {
  try {
    let { data } = await axiosInstance.get(`sp/partner/program/list`, {
      params: { tenantId },
    });
    return data;
  } catch (error) {}
};

export const getPartnerTier = async (tenantId, tierId) => {
  try {
    let { data } = await axiosInstance.post("sp/provider/partnerTier/detail", {
      tenantId,
      id: tierId,
    });
    return data;
  } catch (error) {}
};
