import React, { useEffect, useMemo, useState } from "react";
import CommissionIncentiveProgram from "./CommissionIncentiveProgram";
import CommissionTrendTimePeriod from "./CommissionTrendTimePeriod";
import { Box, SimpleGrid } from "@chakra-ui/react";
import {
  calculateDaysBetweenDates,
  calculatePercentageDifference,
  compareInnerAndGeneralFilters,
  formatYAxisTicks,
  getAppliedFilters,
  getPreviousDateRange,
  getSpecificInnerFilters,
} from "@coachbar/shared-components/src/services/dashboardUtility/utility";
import ValuesChangeCard from "@coachbar/shared-components/src/components/Charts/ValuesChangeCard";
import ChartContainer from "@coachbar/shared-components/src/screens/dashboard/ChartContainer";
import ChartHeader from "@coachbar/shared-components/src/screens/dashboard/ChartHeader";
import {
  deepCopyObject,
  getCurrency,
} from "@coachbar/shared-components/src/services/utility";
import {
  getAvgLeadDealCommission,
  getClawBackData,
  getEstimatedCommission,
} from "../../financialActions";
import { useChartsProvider } from "@coachbar/shared-components/src/screens/dashboard/chartContext";
import Payout from "./Payout";
import RevenueVsPayout from "./RevenueVsPayout";

const Commission = ({ chartData }) => {
  const { selectedDate, appliedFilters, fixFilters } = useChartsProvider();

  let currencySymbol = getCurrency().symbol;
  const [chartType, setChartType] = useState("");
  const [isCompared, setIsCompared] = useState(false);
  const [totComm, setTotComm] = useState({});
  const [totCommLoading, setTotCommDataLoading] = useState(false);
  const [avgComm, setAvgComm] = useState({});
  const [avgCommLoading, setAvgCommDataLoading] = useState(false);
  const [clawBack, setClawBack] = useState({});
  const [clawBackLoading, setClawBackDataLoading] = useState(false);
  const [innerFilters, setInnerFilters] = useState(
    getSpecificInnerFilters(fixFilters, chartData.filters)
  );

  const [innerDate, setInnerDate] = useState(deepCopyObject({}));

  let innerAppliedFilters = useMemo(() => {
    return getAppliedFilters(innerFilters);
  }, [innerFilters]);

  useEffect(() => {
    setIsCompared(false);
    fetchTotComm();
    fetchAvgCommission();
    fetchClawBackData();
  }, [innerDate, innerAppliedFilters, selectedDate, appliedFilters]);

  const fetchTotComm = async () => {
    setTotCommDataLoading(true);
    let filtersPass = compareInnerAndGeneralFilters(
      appliedFilters,
      innerAppliedFilters,
      Object.keys(fixFilters),
      innerDate,
      selectedDate
    );
    let requestData = { ...filtersPass };
    let { data } = await getEstimatedCommission(requestData);
    let resultData = data.dashboard;

    setTotCommDataLoading(false);
    setTotComm(resultData);
  };

  const fetchAvgCommission = async () => {
    setAvgCommDataLoading(true);
    let filtersPass = compareInnerAndGeneralFilters(
      appliedFilters,
      innerAppliedFilters,
      Object.keys(fixFilters),
      innerDate,
      selectedDate
    );
    let requestData = { ...filtersPass };
    let { data } = await getAvgLeadDealCommission(requestData);
    let resultData = data.dashboard;

    setAvgCommDataLoading(false);
    setAvgComm(resultData);
  };

  const fetchClawBackData = async () => {
    setClawBackDataLoading(true);
    let filtersPass = compareInnerAndGeneralFilters(
      appliedFilters,
      innerAppliedFilters,
      Object.keys(fixFilters),
      innerDate,
      selectedDate
    );
    let requestData = { ...filtersPass };
    let { data } = await getClawBackData(requestData);
    let resultData = data.dashboard;

    setClawBackDataLoading(false);
    setClawBack(resultData);
  };

  let onCompareClick = async () => {
    if (isCompared) {
      let newTotComm = deepCopyObject(totComm);
      delete newTotComm?.totalPercentChange;
      setTotComm(deepCopyObject(newTotComm));
      let newAvgComm = deepCopyObject(avgComm);
      delete newAvgComm?.totalPercentChange;
      setAvgComm(deepCopyObject(newAvgComm));
      let newClawBack = deepCopyObject(clawBack);
      delete newClawBack?.totalPercentChange;
      setClawBack(deepCopyObject(newClawBack));
      setIsCompared((prev) => !prev);
    } else {
      setTotCommDataLoading(true);
      setAvgCommDataLoading(true);
      setClawBackDataLoading(true);
      setIsCompared((prev) => !prev);
      let filtersPass = compareInnerAndGeneralFilters(
        appliedFilters,
        innerAppliedFilters,
        Object.keys(fixFilters),
        innerDate,
        selectedDate
      );
      let requestData = { ...filtersPass };
      let conDate = deepCopyObject(
        innerDate?.to && innerDate?.from ? innerDate : selectedDate
      );
      let prevDateRange = getPreviousDateRange(conDate);
      requestData.fromDate = prevDateRange.from;
      requestData.toDate = prevDateRange.to;
      totCommCompare(requestData);
      avgCommCompare(requestData);
      clawBackCompare(requestData);
    }
  };

  const totCommCompare = async (requestData) => {
    let compareData = await getEstimatedCommission(requestData);
    setTotCommDataLoading(false);
    let newTotCom = deepCopyObject(totComm);
    newTotCom.totalPercentChange = calculatePercentageDifference(
      compareData?.data.dashboard?.total,
      newTotCom?.total
    );
    setTotComm(newTotCom);
  };

  const avgCommCompare = async (requestData) => {
    let compareData = await getAvgLeadDealCommission(requestData);
    setAvgCommDataLoading(false);
    let newAvgComm = deepCopyObject(avgComm);
    newAvgComm.totalPercentChange = calculatePercentageDifference(
      compareData?.data.dashboard?.total,
      newAvgComm?.total
    );
    setAvgComm(newAvgComm);
  };

  const clawBackCompare = async (requestData) => {
    let compareData = await getClawBackData(requestData);
    setClawBackDataLoading(false);
    let newClawBack = deepCopyObject(clawBack);
    newClawBack.totalPercentChange = calculatePercentageDifference(
      compareData?.data.dashboard?.total,
      newClawBack?.total
    );
    setClawBack(newClawBack);
  };

  let dayDiff = useMemo(() => {
    let conDate = deepCopyObject(
      innerDate?.to && innerDate?.from ? innerDate : selectedDate
    );
    return calculateDaysBetweenDates(conDate);
  }, [innerDate, selectedDate]);

  return (
    <Box>
      <ChartContainer>
        <ChartHeader
          title={chartData?.title}
          showAll
          chartData={chartData}
          chartType={chartType}
          onChartTypeChange={() => {
            if (chartType === "") {
              setChartType("table");
            } else {
              setChartType("");
            }
          }}
          filters={innerFilters}
          onSubmitFilters={(newFilters, date) => {
            setInnerFilters(newFilters);
            setInnerDate(date);
          }}
          date={innerDate}
          generalDate={selectedDate}
          onToogleCompare={onCompareClick}
          isCompared={isCompared}
        />
        <SimpleGrid
          className="top-tiles"
          columns={6}
          spacing={"20px"}
          mb="20px"
        >
          <ValuesChangeCard
            title={"Avg Estimated Commission"}
            vsText={`prev ${dayDiff} days`}
            value={
              avgComm.hasOwnProperty("total")
                ? `${currencySymbol}${formatYAxisTicks(avgComm.total)}`
                : ""
            }
            changePercent={avgComm.totalPercentChange}
            showCompare={avgComm?.hasOwnProperty("totalPercentChange")}
            loading={avgCommLoading}
          />
          <ValuesChangeCard
            title={"Total Estimated Commission"}
            vsText={`prev ${dayDiff} days`}
            value={
              totComm.hasOwnProperty("total")
                ? `${currencySymbol}${formatYAxisTicks(totComm.total)}`
                : ""
            }
            changePercent={totComm.totalPercentChange}
            showCompare={totComm?.hasOwnProperty("totalPercentChange")}
            loading={totCommLoading}
          />
          <ValuesChangeCard
            title={"Clawback Amount"}
            vsText={`prev ${dayDiff} days`}
            value={
              clawBack.hasOwnProperty("total")
                ? `${currencySymbol}${formatYAxisTicks(clawBack.total)}`
                : ""
            }
            changePercent={clawBack.totalPercentChange}
            showCompare={clawBack?.hasOwnProperty("totalPercentChange")}
            loading={clawBackLoading}
          />
        </SimpleGrid>
        <SimpleGrid columns={2} spacing={"20px"}>
          <CommissionIncentiveProgram
            appliedFilters={appliedFilters}
            selectedDate={selectedDate}
            innerAppliedFilters={innerAppliedFilters}
            innerDate={innerDate}
            fixFilters={fixFilters}
            chartType={chartType}
            isCompared={isCompared}
          />
          <CommissionTrendTimePeriod
            appliedFilters={appliedFilters}
            selectedDate={selectedDate}
            innerAppliedFilters={innerAppliedFilters}
            innerDate={innerDate}
            fixFilters={fixFilters}
            chartType={chartType}
            isCompared={isCompared}
          />
          {/*
          <Payout
            appliedFilters={appliedFilters}
            selectedDate={selectedDate}
            innerAppliedFilters={innerAppliedFilters}
            innerDate={innerDate}
            fixFilters={fixFilters}
            chartType={chartType}
            isCompared={isCompared}
          />
          <RevenueVsPayout
            appliedFilters={appliedFilters}
            selectedDate={selectedDate}
            innerAppliedFilters={innerAppliedFilters}
            innerDate={innerDate}
            fixFilters={fixFilters}
            chartType={chartType}
            isCompared={isCompared}
          /> */}
        </SimpleGrid>
      </ChartContainer>
    </Box>
  );
};

export default Commission;
