import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../services/axiosInstance";
import { getErrorMessage, handleError } from "../../services/utility";
import { notification } from "@coachbar/shared-components/src/services/utility";

export const getGeneralNotificationList = createAsyncThunk(
  "get/generalNotificationList",
  async (actionParams, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/notificationSetting/system`);
      return data?.data?.notificationSetting?.moduleWiseList || {};
    } catch (error) {
      // return custom error message from API if any
      notification(getErrorMessage(error), "error");
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateGeneralNotificationActiveState = async (
  notificationId,
  isActive
) => {
  try {
    const { data } = await axiosInstance.put(
      "/notificationSetting/activeStatus/" + notificationId,
      { active: isActive }
    );
    if (!data.success) throw new Error(data.message);
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const updateGeneralNotification = async (notificationData) => {
  try {
    const { data } = await axiosInstance.put(
      "/notificationSetting/" + notificationData.id,
      notificationData
    );
    if (!data.success) throw new Error(data.message);

    return data;
  } catch (error) {
    handleError(error);
  }
};
