import { useEffect } from "react";
import { clearState } from "../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";

export const useClearState = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);
};

export const useRBAC = () => {
  const { userInfo } = useSelector((state) => state.auth);

  const hasActionPermission = (moduleName, action) => {
    if (!userInfo?.role || !userInfo?.roleId) {
      return true;
    } else {
      if (userInfo?.role === "Owner") return true;
      else
        return userInfo?.roleData?.[moduleName] &&
          (userInfo?.roleData[moduleName]?.["Full_Access"] === true ||
            userInfo?.roleData[moduleName]?.["Full_Access"] === 1 ||
            userInfo?.roleData[moduleName]?.[action] === true ||
            userInfo?.roleData[moduleName]?.[action] === 1)
          ? true
          : false;
    }
  };

  const hasViewPermission = (moduleName) => {
    if (!userInfo?.role || !userInfo?.roleId) {
      return true;
    } else {
      if (userInfo?.role === "Owner") return true;
      else
        return userInfo?.roleData && userInfo?.roleData[moduleName]
          ? true
          : false;
    }
  };

  return { hasActionPermission, hasViewPermission };
};
