import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import { FormControl, FormLabel, FormErrorMessage } from "@chakra-ui/react";

const CustomPhoneInput = ({
  value,
  onChange,
  error,
  required,
  countryOrder,
  name,
}) => {
  return (
    <FormControl name={name} isInvalid={error} isRequired={required}>
      <FormLabel
        textTransform={"capitalize"}
        as="legend"
        color={"#5E6977"}
        fontSize="12px"
        mb={"4px"}
        fontWeight={600}
      >
        Phone Number
      </FormLabel>
      <PhoneInput
        preferredCountries={countryOrder}
        preserveOrder={["preferredCountries"]}
        inputProps={{
          name: "phone",
          required: true,
        }}
        buttonStyle={{
          border: "1px solid #E2E8F0",
          background: "white",
          color: "#9EA5AD",
        }}
        inputStyle={{
          width: "inherit",
          height: "40px",
          border: "1px solid #E2E8F0",
        }}
        value={value}
        onChange={onChange}
        country={"us"}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  );
};

export default CustomPhoneInput;
