import { Box, Center } from "@chakra-ui/react";
import { Loader } from "@coachbar/shared-components";
import ChartLegends from "@coachbar/shared-components/src/components/Charts/ChartLegends";
import Line from "@coachbar/shared-components/src/components/Charts/Line";
import ChartGrid from "@coachbar/shared-components/src/screens/dashboard/ChartGrid";
import InnerChartContainer from "@coachbar/shared-components/src/screens/dashboard/InnerChartContainer";
import NoDataChart from "@coachbar/shared-components/src/screens/dashboard/NoDataChart";
import {
  checkTrendSumsIs0,
  compareInnerAndGeneralFilters,
  convertToLabel,
  formatPrevTrendData,
  formatTrenDate,
  formatTrendData,
  getPreviousDateRange,
  removePrevData,
} from "@coachbar/shared-components/src/services/dashboardUtility/utility";
import { deepCopyObject } from "@coachbar/shared-components/src/services/utility";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getAvgLeadWinTrend } from "../../financialActions";

const AvgLeadWinTime = ({
  selectedDate,
  innerDate,
  appliedFilters,
  innerAppliedFilters,
  fixFilters,
  chartType,
  isCompared,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [trendDataForChart, setTrendDataForChart] = useState([]);
  const [currentTrendData, setCurrentTrendData] = useState({});

  // const [formattedDateForChart] = useMemo(() => {
  //   let conDate = deepCopyObject(
  //     innerDate?.to && innerDate?.from ? innerDate : selectedDate
  //   );
  //   // Different date format to show in chart tooltip
  //   const formattedDate = formatDateRange(conDate || null, "chart");
  //   return [formattedDate];
  // }, [innerDate, selectedDate]);

  const trendTableHeading = useMemo(() => {
    const maxLengthObject = trendDataForChart.reduce((maxObj, currentObj) => {
      return currentObj.data.length > (maxObj.data?.length || 0)
        ? currentObj
        : maxObj;
    }, {});

    return maxLengthObject.data?.map((data) => formatTrenDate(data.x)) || [];
  }, [trendDataForChart]);

  const formattedTrendDataForLegends = useMemo(
    () =>
      trendDataForChart.map((x) => ({
        legend: convertToLabel(x?.id),
        color: x?.color,
        isPrev: x?.isPrev,
      })),
    [trendDataForChart]
  );

  const constructRequestData = ({ date }) => {
    let requestData = compareInnerAndGeneralFilters(
      appliedFilters,
      innerAppliedFilters,
      Object.keys(fixFilters),
      date,
      selectedDate
    );

    return requestData;
  };

  const clearPreviousData = () => {
    const copyOfTrendData = [...trendDataForChart];
    setTrendDataForChart(removePrevData(copyOfTrendData));
    // setDaysBetweenDates(null);
  };

  const onToogleCompare = () => {
    if (!isCompared) {
      clearPreviousData();
    } else {
      let conDate = deepCopyObject(
        innerDate?.to && innerDate?.from ? innerDate : selectedDate
      );
      const previousDateRange = getPreviousDateRange(conDate);
      // const numberOfDays = calculateDaysBetweenDates(conDate);
      // setDaysBetweenDates(numberOfDays);
      const requestData = constructRequestData({ date: previousDateRange });
      fetchData(requestData, trendDataForChart, currentTrendData, true);
    }
  };

  const fetchData = useCallback(
    async (
      requestData,
      trendDataForChart,
      currentTrendData,
      isCompared = false
    ) => {
      setIsLoading(true);
      const data = await getAvgLeadWinTrend(requestData);
      setIsLoading(false);

      const apiData = data?.data?.dashboard?.trends;
      if (apiData) {
        let formattedData;

        if (isCompared) {
          formattedData = formatPrevTrendData(currentTrendData, apiData);

          const mergedData = formattedData.concat(trendDataForChart);
          setTrendDataForChart(mergedData);
          // setPreviousTrendData(deepCopyObject(apiData));
        } else {
          formattedData = formatTrendData(apiData);
          setTrendDataForChart(deepCopyObject(formattedData));
          setCurrentTrendData(deepCopyObject(apiData));
        }
      } else {
        setTrendDataForChart([]);
      }
    },
    []
  );

  let intialized = false;
  useEffect(() => {
    if (!intialized) {
      intialized = true;
      const requestData = constructRequestData({ date: innerDate });
      fetchData(requestData);
    }
  }, [fetchData, innerDate, selectedDate, appliedFilters, innerAppliedFilters]);

  useEffect(() => {
    onToogleCompare();
  }, [isCompared]);

  let isDataEmpty = useMemo(() => {
    return checkTrendSumsIs0(trendDataForChart);
  }, [trendDataForChart]);

  return (
    <InnerChartContainer chartHeader={`Avg. Lead-to-Win Time (in days)`}>
      <Box>
        {isLoading ? (
          <Center h={"380px"}>
            <Loader />
          </Center>
        ) : isDataEmpty ? (
          <Box h={"380px"}>
            <NoDataChart />
          </Box>
        ) : chartType === "table" ? (
          <ChartGrid
            data={trendDataForChart.map((trend) => {
              return {
                ...trend,
                id: convertToLabel(trend.id) || trend.id, // Use the convertToLabel function or keep the original id if not found
              };
            })}
            headings={[["Product", ...trendTableHeading]]}
          />
        ) : (
          <>
            <Box h={"350px"}>
              <Line
                data={trendDataForChart}
                isCompared={isCompared}
                bottomLegend={"Created Date"}
                leftLegend={`Count of leads`}
                chartFor="LeadWinTime"
              />
            </Box>
            <ChartLegends
              data={
                isCompared
                  ? removePrevData(formattedTrendDataForLegends)
                  : formattedTrendDataForLegends
              }
            />
          </>
        )}
      </Box>
    </InnerChartContainer>
  );
};

export default AvgLeadWinTime;
