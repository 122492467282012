import { Box, Tooltip } from "@chakra-ui/react";
import React from "react";
import { Check, Edit02, Eye, Mail02, Trash03, XClose } from "../../images";

export const ACTION_ICON_BUTTON_TYPE = {
  EDIT: "EDIT",
  DELETE: "DELETE",
  SEND_MAIL: "SEND_MAIL",
  PREVIEW: "PREVIEW",
  ACCEPT: "ACCEPT",
  REJECT: "REJECT",
};

export const IconButton = ({
  children,
  containerProps = {},
  onClickAction = () => {},
  tooltipProps = {},
}) => {
  return (
    <Tooltip
      openDelay={300}
      placement="top"
      mb="1px"
      padding={"8px 12px"}
      borderRadius={"4px"}
      textAlign={"left"}
      isDisabled={false}
      hasArrow
      {...tooltipProps}
    >
      <Box
        m={"0px"}
        p={"0px"}
        cursor={"pointer"}
        onClick={onClickAction}
        {...containerProps}
      >
        {children}
      </Box>
    </Tooltip>
  );
};

export const ActionIconButton = ({
  type,
  tooltipLabel,
  onClickAction = () => {},
  containerProps = {},
  tooltipProps = {},
}) => {
  switch (type) {
    case ACTION_ICON_BUTTON_TYPE.EDIT:
      return (
        <IconButton
          containerProps={{
            stroke: "var(--chakra-colors-brandGray-400)",
            fill: "transparent",
            color: "var(--chakra-colors-brandGray-400)",
            bg: "transparent",
            ...containerProps,
          }}
          tooltipProps={{
            label: tooltipLabel || "Edit",
            ...tooltipProps,
          }}
          onClickAction={onClickAction}
        >
          <Edit02 width="16px" height="16px" />
        </IconButton>
      );
    case ACTION_ICON_BUTTON_TYPE.DELETE:
      return (
        <IconButton
          containerProps={{
            stroke: "var(--chakra-colors-brandGray-400)",
            fill: "transparent",
            color: "var(--chakra-colors-brandGray-400)",
            bg: "transparent",
            ...containerProps,
          }}
          tooltipProps={{
            label: tooltipLabel || "Delete",
            ...tooltipProps,
          }}
          onClickAction={onClickAction}
        >
          <Trash03 width="16px" height="16px" />
        </IconButton>
      );

    case ACTION_ICON_BUTTON_TYPE.SEND_MAIL:
      return (
        <IconButton
          containerProps={{
            stroke: "var(--chakra-colors-brandGray-400)",
            fill: "transparent",
            color: "var(--chakra-colors-brandGray-400)",
            bg: "transparent",
            ...containerProps,
          }}
          tooltipProps={{
            label: tooltipLabel || "Send",
            ...tooltipProps,
          }}
          onClickAction={onClickAction}
        >
          <Mail02 width="16px" height="16px" />
        </IconButton>
      );

    case ACTION_ICON_BUTTON_TYPE.PREVIEW:
      return (
        <IconButton
          containerProps={{
            stroke: "var(--chakra-colors-brandGray-400)",
            fill: "transparent",
            color: "var(--chakra-colors-brandGray-400)",
            bg: "transparent",
            ...containerProps,
          }}
          tooltipProps={{
            label: tooltipLabel || "Preview",
            ...tooltipProps,
          }}
          onClickAction={onClickAction}
        >
          <Eye width="16px" height="16px" />
        </IconButton>
      );

    case ACTION_ICON_BUTTON_TYPE.ACCEPT:
      return (
        <IconButton
          containerProps={{
            stroke: "var(--chakra-colors-palmGreen-700)",
            fill: "var(--chakra-colors-palmGreen-50)",
            color: "var(--chakra-colors-palmGreen-700)",
            bg: "var(--chakra-colors-palmGreen-50)",
            border: "1px solid var(--chakra-colors-palmGreen-200)",
            borderRadius: "16px",
            width: "24px",
            height: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...containerProps,
          }}
          tooltipProps={{
            label: tooltipLabel || "Accept",
            ...tooltipProps,
          }}
          onClickAction={onClickAction}
        >
          <Check width="16px" height="16px" />
        </IconButton>
      );

    case ACTION_ICON_BUTTON_TYPE.REJECT:
      return (
        <IconButton
          containerProps={{
            stroke: "var(--chakra-colors-cherryRed-700)",
            fill: "var(--chakra-colors-cherryRed-50)",
            color: "var(--chakra-colors-cherryRed-700)",
            bg: "var(--chakra-colors-cherryRed-50)",
            border: "1px solid var(--chakra-colors-cherryRed-200)",
            borderRadius: "16px",
            width: "24px",
            height: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...containerProps,
          }}
          tooltipProps={{
            label: tooltipLabel || "Reject",
            ...tooltipProps,
          }}
          onClickAction={onClickAction}
        >
          <XClose width="16px" height="16px" />
        </IconButton>
      );

    default:
      return null;
  }
};
