import { createSlice } from "@reduxjs/toolkit";
import { saveIndustries } from "./industryAction";

const initialState = {
  requiredIndustries: {},
  selectedIndustries: {},
  prevIdTenantIdMap: {},
};

const industrySlice = createSlice({
  name: "industry",
  initialState,
  reducers: {
    reset: (state, action) => {
      state.selectedIndustries = {};
    },
    onChecked: (
      state,
      { payload: { name, referenceTenantId, currentlySelected } }
    ) => {
      let current = state.selectedIndustries[name] ?? [];
      if (currentlySelected) {
        current = current.filter((id) => id !== referenceTenantId);
      } else {
        current.push(referenceTenantId);
      }

      state.selectedIndustries[name] = current;
    },
    setData: (state, { payload: { data } }) => {
      state.selectedIndustries = data;
    },
    setRequiredIndustries: (state, { payload: { data } }) => {
      state.requiredIndustries = data;
    },
    addToIdMap: (state, { payload: { id, tenantId } }) => {
      state.prevIdTenantIdMap[tenantId] = id;
    },
    deleteFromIdMap: (state, { payload: { tenantId } }) => {
      state.prevIdTenantIdMap[tenantId] = undefined;
    },
    clearIdMap: (state, action) => {
      state.prevIdTenantIdMap = {};
    },
  },
  extraReducers: {
    // update service offering
    [saveIndustries.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [saveIndustries.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [saveIndustries.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
  },
});

export const {
  onChecked,
  setData,
  setRequiredIndustries,
  addToIdMap,
  deleteFromIdMap,
  clearIdMap,
  reset,
} = industrySlice.actions;

export default industrySlice.reducer;
