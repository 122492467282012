import { Box } from "@chakra-ui/react";

const ChartContainer = ({ children }) => {
  return (
    <Box
      p={"20px 24px 24px 24px"}
      rounded={"8px"}
      bg={"white"}
      boxShadow={"0px 0px 4px 0px #E6E7E9"}
    >
      {children}
    </Box>
  );
};

export default ChartContainer;
