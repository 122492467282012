import { axiosInstance } from "../../../services/axiosInstance";
import { handleError } from "../../../services/utility";

export const getListOfAffiliations = async () => {
  try {
    const { data } = await axiosInstance.get(`/affiliation`);
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};

export const addAffiliation = async (requestParams) => {
  try {
    const { data } = await axiosInstance.post(`/affiliation/add`, {
      ...requestParams,
    });
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};

export const editAffiliation = async (id, requestParams) => {
  try {
    const { data } = await axiosInstance.put(`/affiliation/${id}`, {
      ...requestParams,
    });
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};

export const deleteAffiliation = async (id) => {
  try {
    const { data } = await axiosInstance.delete(`/affiliation/${id}`);
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};
