import { Image } from "@chakra-ui/image";
import { AspectRatio, Box, Text } from "@chakra-ui/layout";
import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import closeIcon from "../../image/closeBundle.png";
import IconConnection1 from "../../image/connection1.svg";
import IconConnection2 from "../../image/connection2.svg";
import IconCirclePlus from "../../image/icon-circle-plus.svg";
import IconCirclePlusColor from "../../image/icon-circle-plus-color.svg";

const SoftwareGrid = ({
  data,
  index,
  moveCard,
  onClickOpenModal,
  removeSelectedSoftware,
}) => {
  const ref = useRef(null);
  const SystemGridType = "SYSTEM_GRID";

  const [{ handlerId }, drop] = useDrop({
    accept: SystemGridType,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    // hover: (item) => {
    //   if (item.index !== index) {
    //     moveCard(item.index, index);
    //     item.index = index;
    //   }
    // },
    drop: (item) => {
      if (item.index !== index) {
        moveCard(item.index, index);
        item.index = index;
      }
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: SystemGridType,
    item: () => {
      return { index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: data?.logo ? true : false,
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Box
      maxW={index === 4 ? "124px" : "100px"}
      height={index === 3 || index === 5 ? "auto" : "100%"}
      width={"100%"}
      justifySelf={"center"}
      alignSelf={"center"}
      borderRadius={"6px"}
      position={"relative"}
      bgColor={"white"}
      //   style={{ opacity }}
    >
      <>
        {(index === 0 || index === 3 || index === 5 || index === 2) && (
          <Image
            position="absolute"
            src={IconConnection1}
            h="36%"
            top={`calc(100% ${index === 3 || index === 5 ? "+" : "-"} 5px)`}
            left={`${index === 2 || index === 5 ? "-50%" : "70%"}`}
            zIndex={1}
            transform={
              index === 2
                ? "scaleX(-1)"
                : index === 3
                ? "scaleY(-1)"
                : index === 5
                ? "scale(-1)"
                : "none"
            }
          />
        )}
        {index === 1 && (
          <Image
            position={"absolute"}
            src={IconConnection2}
            h={"36%"}
            top={"calc(100% - 5px)"}
            left={"calc(50% - 8px)"}
            zIndex={1}
          />
        )}
        {index === 3 && (
          <Image
            position={"absolute"}
            src={IconConnection2}
            h={"36%"}
            transform={"rotate(-90deg)"}
            top={"calc(50% - 17px)"}
            left={"calc(100% + 3px)"}
            zIndex={1}
          />
        )}
        {index === 5 && (
          <Image
            position={"absolute"}
            src={IconConnection2}
            h={"36%"}
            transform={"rotate(90deg)"}
            top={"calc(50% - 17px)"}
            left={"-20px"}
            zIndex={1}
          />
        )}
        {index === 7 && (
          <Image
            position={"absolute"}
            src={IconConnection2}
            h={"36%"}
            transform={"rotate(180deg)"}
            top={"-29px"}
            left={"calc(50% - 8px)"}
            zIndex={1}
          />
        )}
        <AspectRatio
          maxW="130px"
          ratio={1}
          ref={ref}
          data-handler-id={handlerId}
          cursor={"grab"}
        >
          {data?.logo ? (
            <Image
              h={"100%"}
              w={"100%"}
              borderRadius={"6px"}
              objectFit={"contain !important"}
              src={data?.logo}
            />
          ) : (
            <Box
              className={index !== 4 ? "box-normal" : "box-animation"}
              borderRadius={"6px"}
              flexDirection={"column"}
              cursor={"pointer"}
              onClick={onClickOpenModal}
            >
              <Image
                h={"20px"}
                src={index !== 4 ? IconCirclePlus : IconCirclePlusColor}
              />
              <Text
                fontSize={"12px"}
                color={index !== 4 ? "#C0C6CE" : "#0E97AE"}
                fontWeight={500}
              >
                Add Software
              </Text>
              {index === 4 && (
                <Text
                  mt={"0 !important"}
                  fontSize={"10px"}
                  color={"#C0C6CE"}
                  fontWeight={500}
                >
                  {`{Core Product}`}
                </Text>
              )}
            </Box>
          )}
        </AspectRatio>
        {data?.logo && (
          <Image
            src={closeIcon}
            size="sm"
            variant="ghost"
            position="absolute"
            height={"18px"}
            top={"0"}
            right={"-18px"}
            transform={"translate(-50%, -50%)"}
            zIndex={2}
            cursor={"pointer"}
            onClick={() => removeSelectedSoftware(data.name)}
          />
        )}
      </>
    </Box>
  );
};

export default SoftwareGrid;
