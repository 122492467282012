import {
  List,
  ListItem,
  ListIcon,
  Text,
  Box,
  SimpleGrid,
  Flex,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { useMemo } from "react";
import { CheckCircle, XCircle } from "../images";

const ICON_TYPE = {
  DEFAULT: "default",
  SUCCESS: "success",
  ERROR: "error",
};

const PasswordStrengthIndicator = ({
  value,
  validationErrors,
  validationMessages,
  setMarginTop,
}) => {
  const {
    hasSpecialCharacter,
    hasUpperCaseLetter,
    hasLowerCaseLetter,
    hasMinLength,
    hasMaxLength,
  } = validationErrors?.types ? validationErrors.types : {};
  return validationErrors &&
    validationErrors.types &&
    Object.entries(validationErrors.types).length > 0 ? (
    <Box style={{ marginTop: setMarginTop ? setMarginTop : "-14px" }}>
      <Text fontSize="md" color="var(--text-font-color)">
        Password must contain:
      </Text>
      {/* <p className="text-dark"></p> */}
      <List spacing={1}>
        <PasswordStrengthIndicatorItem
          isValid={!hasSpecialCharacter}
          text={validationMessages["specialCharacter"]}
        />
        <PasswordStrengthIndicatorItem
          isValid={!hasUpperCaseLetter}
          text={validationMessages["upperCaseLetter"]}
        />
        <PasswordStrengthIndicatorItem
          isValid={!hasLowerCaseLetter}
          text={validationMessages["lowerCaseLetter"]}
        />
        <PasswordStrengthIndicatorItem
          isValid={!hasMinLength}
          text={validationMessages["passwordMinLength"]}
        />
        <PasswordStrengthIndicatorItem
          isValid={!hasMaxLength}
          text={validationMessages["passwordMaxLength"]}
        />
      </List>
    </Box>
  ) : null;
};

const PasswordStrengthIndicatorItem = ({ isValid, text }) => {
  const highlightColor = isValid
    ? "green.500"
    : isValid !== null
    ? "red.500"
    : "";
  return (
    <ListItem fontSize="sm" color="var(--text-font-color)">
      <ListIcon as={CheckCircleIcon} color={highlightColor} />
      {text}
    </ListItem>
  );
};

export const PasswordStrengthIndicator2 = ({
  value,
  validationErrors,
  validationMessages,
  setMarginTop,
}) => {
  const {
    hasSpecialCharacter,
    hasUpperCaseLetter,
    hasLowerCaseLetter,
    hasProperLendth,
  } = validationErrors?.types ? validationErrors.types : {};

  const defaultState = useMemo(() => !value, [value]);

  return (
    <Box style={{ marginTop: setMarginTop ? setMarginTop : "0px" }}>
      <Text
        fontSize="12px"
        fontWeight={500}
        lineHeight={"18px"}
        color="brandGray.400"
      >
        Password must:
      </Text>
      {/* <p className="text-dark"></p> */}
      <SimpleGrid columns={[2]} spacing={"4px"} mt="4px">
        <PasswordStrengthIndicatorItem2
          isValid={!hasProperLendth}
          text={validationMessages["passwordLength"]}
          defaultState={defaultState}
        />
        <PasswordStrengthIndicatorItem2
          isValid={!hasSpecialCharacter}
          text={validationMessages["specialCharacter"]}
          defaultState={defaultState}
        />
        <PasswordStrengthIndicatorItem2
          isValid={!hasUpperCaseLetter}
          text={validationMessages["upperCaseLetter"]}
          defaultState={defaultState}
        />
        <PasswordStrengthIndicatorItem2
          isValid={!hasLowerCaseLetter}
          text={validationMessages["lowerCaseLetter"]}
          defaultState={defaultState}
        />
      </SimpleGrid>
    </Box>
  );
};

const getIcon = (iconType) => {
  if (iconType === ICON_TYPE.DEFAULT)
    return (
      <CheckCircle
        height="16px"
        width="16px"
        stroke="var(--chakra-colors-brandGray-400)"
        fill="var(--chakra-colors-white)"
      />
    );
  if (iconType === ICON_TYPE.SUCCESS)
    return (
      <CheckCircle
        height="16px"
        width="16px"
        stroke="var(--chakra-colors-palmGreen-600)"
        fill="var(--chakra-colors-white)"
      />
    );
  if (iconType === ICON_TYPE.ERROR)
    return (
      <XCircle
        height="16px"
        width="16px"
        stroke="var(--chakra-colors-cherryRed-600)"
        fill="var(--chakra-colors-white)"
      />
    );
};

const PasswordStrengthIndicatorItem2 = ({ isValid, text, defaultState }) => {
  const iconType = useMemo(() => {
    if (defaultState && isValid) return ICON_TYPE.DEFAULT;
    if (isValid) return ICON_TYPE.SUCCESS;
    return ICON_TYPE.ERROR;
  }, [defaultState, isValid]);

  return (
    <Flex
      fontSize="12px"
      lineHeight={"18px"}
      color="brandGray.400"
      fontWeight={450}
      alignItems={"center"}
      gap={"4px"}
    >
      {getIcon(iconType)}
      {text}
    </Flex>
  );
};

export default PasswordStrengthIndicator;
