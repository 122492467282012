// HubSpot.js
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Notification from "../../../components/Notification";
import { Button, Loader } from "@coachbar/shared-components";
import {
  Card,
  CardHeader,
  CardBody,
  Heading,
  Stack,
  HStack,
  VStack,
  Text,
  Center,
  Image,
  SimpleGrid,
  Tabs,
  TabList,
  Tab,
  useDisclosure,
} from "@chakra-ui/react";
import coachBarIntegrationImage from "../../../image/coachbar-integration.svg";
import hubSpotintegrationImage from "../../../image/hubspot-integratio.svg";
import transitionIcon from "../../../image/transition-icon.svg";
import { useLocation } from "react-router-dom";
import {
  addIntegration,
  getIntegrationDetail,
  getintegrationList,
} from "../integrationAction";
import { clearMessage } from "../integrationSlice";
import { clearMessage as hubSpotClearMessage, setError } from "./hubSpotSlice";
import SettingsTab from "./hubSpotSetting";
import HubSpotOverViewTab from "./hubSpotOverViewTab";
import HubSpotLogs from "./hubSpotLogs";
import { getPredefinedCredit } from "../../roadmap/roadmapActions";
import RedeemService from "../../coachbarServices/RedeemService";
import CustomTooltip from "@coachbar/shared-components/src/components/Tooltip/CustomTooltip";
import { hubspotIntegrationTip } from "../../../constants/tooltips";
const pages = ["Overview", "Settings", "Logs"];
const TabStatus = [
  { slug: "OVERVIEW", title: "Overview" },
  { slug: "SETTING", title: "Settings" },
  { slug: "LOGS", title: "Logs" },
];
const serviceObj = {
  id: 5,
  title: "System integration",
  quantity: 1,
  credit: 2,
  type: "RedeemService",
  setError: setError,
};

const HubSpot = () => {
  const { integrationDetail, loading, error, success } = useSelector(
    (state) => state.integration
  );
  const { error: hubSpotError, success: hubSpotSuccess } = useSelector(
    (state) => state.hubSpot
  );
  const { prebookCredit } = useSelector((state) => state.roadmap);
  const { userInfo } = useSelector((state) => state.auth);
  const { onOpen, isOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();

  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const page = new URLSearchParams(search).get("page");
  const hubSpotCode = new URLSearchParams(search).get("code");
  let pageIndex = pages.indexOf(page);
  let initialIndex = 0;
  if (pageIndex !== -1) {
    initialIndex = pageIndex;
  }
  const [tabIndex, setTabIndex] = useState(initialIndex);
  const [listloading, setListLoading] = useState(true);
  const [integrationId, setIntegrationId] = useState("");

  let initializedHubspotCode = false;
  useEffect(() => {
    if (hubSpotCode && !initializedHubspotCode) {
      integrateHubspot();
      initializedHubspotCode = true;
    }
  }, []);

  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      dispatch(getPredefinedCredit());
      getintegrationList().then((response) => {
        if (
          response?.data?.success &&
          response?.data?.data?.integrationList?.length > 0
        ) {
          setIntegrationId(response.data.data.integrationList[0].id);
          dispatch(
            getIntegrationDetail(response.data.data.integrationList[0].id)
          );
        }
        setListLoading(false);
      });
    }
  }, []);

  const fetchIntegration = () => {
    if (integrationId) {
      dispatch(getIntegrationDetail(integrationId));
    }
  };

  const integrateHubspot = () => {
    let requestObj = {
      code: hubSpotCode,
      redirectUri: process.env.REACT_APP_HUBSPOT_REDIRECT_URI,
    };

    dispatch(addIntegration(requestObj));
    onTabClick(1);
  };
  const integrateHubSpotConnect = () => {
    window.open(
      `https://app.hubspot.com/oauth/authorize?client_id=${process.env.REACT_APP_HUBSPOT_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_HUBSPOT_REDIRECT_URI}&scope=crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.schemas.contacts.read%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.deals.write%20crm.schemas.companies.read%20crm.schemas.companies.write%20crm.schemas.contacts.write%20crm.schemas.deals.read%20crm.schemas.deals.write%20crm.objects.owners.read`,
      "_self"
    );
  };

  const onTabClick = (index = 0) => {
    window.scrollTo(0, 0);
    const newUrl = `${location.pathname}?page=${pages[index]}`;
    window.history.replaceState(null, "", newUrl);
    setTabIndex(index);
  };

  return (
    <>
      {error || success ? (
        <Notification
          title={error ? error : success}
          status={error ? "error" : "success"}
          onCloseToast={() => dispatch(clearMessage())}
        />
      ) : (
        ""
      )}
      {hubSpotError || hubSpotSuccess ? (
        <Notification
          title={hubSpotError ? hubSpotError : hubSpotSuccess}
          status={hubSpotError ? "error" : "success"}
          onCloseToast={() => dispatch(hubSpotClearMessage())}
        />
      ) : (
        ""
      )}
      <RedeemService
        isOpen={isOpen}
        onClose={onClose}
        selectedSevice={serviceObj}
        performExtraAction={integrateHubSpotConnect}
      />
      {loading || listloading ? (
        <Loader />
      ) : integrationDetail ? (
        <Stack spacing="5" m="5" align="stretch">
          <Card variant="form">
            <CardHeader borderColor="brandGray.20 !important" padding="0px">
              <SimpleGrid columns={2} py={4} templateColumns={"1.25fr 1.75fr"}>
                <Heading
                  size="md"
                  px={4}
                  borderBottom={"2px solid"}
                  borderColor={"brandGray.20"}
                >
                  HubSpot Integration
                </Heading>
                <CreateTabList
                  onTabClick={onTabClick}
                  isLoading={loading}
                  tabIndex={tabIndex}
                />
              </SimpleGrid>
            </CardHeader>
            <CardBody p={0}>
              {tabIndex === 0 ? (
                <HubSpotOverViewTab
                  tabIndex={tabIndex}
                  integration={integrationDetail}
                  // referralsList={referralsList}
                  // referralNextToken={referralNextToken}
                />
              ) : (
                ""
              )}
              {tabIndex === 1 ? (
                <SettingsTab
                  tabIndex={tabIndex}
                  onTabClick={onTabClick}
                  fetchIntegration={fetchIntegration}
                />
              ) : (
                ""
              )}
              {tabIndex === 2 ? <HubSpotLogs tabIndex={tabIndex} /> : ""}
            </CardBody>
          </Card>
        </Stack>
      ) : (
        <Center mt={"10rem"} p={[5, 5, 5, 0]}>
          <VStack spacing={8}>
            <HStack spacing={2}>
              <Image src={coachBarIntegrationImage} />
              <Image src={transitionIcon} />
              <Image src={hubSpotintegrationImage} />
            </HStack>
            <Text
              fontWeight={"700"}
              fontSize={"1.625rem"}
              lineHeight={"18px"}
              color={"brand.100"}
            >
              Coachbar Integration with HubSpot&nbsp;
              <CustomTooltip content={hubspotIntegrationTip} />
            </Text>
            <Text
              fontWeight={"400"}
              fontSize={"1rem"}
              lineHeight={"24px"}
              color={"brandGray.200"}
            >
              Integrate Your Coachbar Account with HubSpot to Share Data
            </Text>
            {/* {userInfo?.planId !== 1 && prebookCredit?.integrations > 0 ? (
              <Button
                title="Connect"
                w={"9rem"}
                h={"2.25rem"}
                variant={"serviceButton"}
                onClick={integrateHubSpotConnect}
              />
            ) : (
              <Button
                title="Buy Now"
                w={"9rem"}
                h={"2.25rem"}
                variant={"serviceButton"}
                onClick={onOpen}
              />
            )} */}
            {userInfo?.planId !== 1 ? (
              <Button
                title="Connect"
                w={"9rem"}
                h={"2.25rem"}
                variant={"serviceButton"}
                onClick={integrateHubSpotConnect}
                id="integration-hubspot-connect"
              />
            ) : (
              <Button
                title="Buy Now"
                w={"9rem"}
                h={"2.25rem"}
                variant={"serviceButton"}
                onClick={onOpen}
                id="integration-hubspot-buy-now"
              />
            )}
          </VStack>
        </Center>
      )}
    </>
  );
};

function CreateTabList(props) {
  return (
    <Tabs isLazy isFitted index={props.tabIndex}>
      <TabList
        color={"var(--chakra-colors-gray-500)"}
        borderColor={"brandGray.20"}
      >
        {TabStatus.map((status, index) => (
          <Tab
            fontWeight={"600"}
            key={`tabList${index}`}
            onClick={() => {
              props.onTabClick(index);
            }}
            sx={{
              _selected: {
                borderBottom: "3px solid",
                "--tabs-color": "brand.50",
                borderColor: "brand.50",
                color: "brand.50",
              },
            }}
          >
            {status["title"]}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
}

export default HubSpot;
