import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Spacer,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import { Button, Loader, Select } from "@coachbar/shared-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import hubSpotintegrationImage from "../../../image/hubspot-integratio.svg";

import CustomSingleDayPicker from "@coachbar/shared-components/src/components/DayPicker/CustomSingleDayPicker";
import { format, subDays } from "date-fns";
import {
  coachbarFields,
  directionTextMapping,
  sourceOption,
  sourceTextMapping,
  syncDirectionOption,
} from "../../../constants/hubspot";
import { referralStatusList } from "../../../constants/referralStatus";
import {
  updateIntegration,
  updateIntegrationStatus,
} from "../integrationAction";
import { getHubSpotProperty, manualSync } from "./hubSpotAction";
import FieldMapping from "./hubSpotMapping";
const coachbarStatusList = [...referralStatusList];
coachbarStatusList.shift();

const SettingsTab = (props) => {
  const {
    hubSpotFieldProperty,
    hubSpotStatusProperty,
    loading,
    manualSyncLoading,
  } = useSelector((state) => state.hubSpot);

  const {
    integrationDetail,
    loading: integrationLoading,
    updateLoading,
  } = useSelector((state) => state.integration);

  const dispatch = useDispatch();

  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      dispatch(getHubSpotProperty());
      prepareCoachbarField();
    }
  }, []);

  const [coachbarField, setCoachbarField] = useState([]);
  const [hubSpotField, sethubSpotField] = useState([]);
  const [coachbarStatus, setCoachbarStatus] = useState([]);
  const [HubSpotStatus, setHubSpotStatus] = useState([]);
  const [autoSync, setAutoSync] = useState(false);
  const [syncDirection, setSyncDirection] = useState(
    syncDirectionOption[1].value
  );
  const [source, setSource] = useState(sourceOption[1].value);
  const [statusMapping, setStatusMapping] = useState([]);
  const [fieldMapping, setFieldMapping] = useState([]);
  const [statusMappingError, setStatusMappingError] = useState(false);
  const [integrationStatus, setIntegrationStatus] = useState(true);
  const selectedPastDate = subDays(new Date(), 60);
  const [selectedDate, setSelectedDate] = useState(
    format(selectedPastDate, "yyyy-MM-dd")
  );

  useEffect(() => {
    if (integrationDetail?.id) {
      if (integrationDetail.masterSource) {
        setSource(integrationDetail.masterSource);
      }

      if (integrationDetail.syncDirection) {
        setSyncDirection(integrationDetail.syncDirection);
      }
      if (integrationDetail.storeConfig.Referral) {
        setAutoSync(
          integrationDetail.storeConfig.Referral === "Auto" ? true : false
        );
      }
      setIntegrationStatus(integrationDetail.active);
    }
  }, [integrationDetail]);

  useEffect(() => {
    if (
      integrationDetail?.referralFieldMapping?.length > 0 &&
      hubSpotFieldProperty.length > 0 &&
      coachbarFields.length > 0
    ) {
      let fieldMappingArray = [];
      integrationDetail.referralFieldMapping.map((field) => {
        const hsField = hubSpotFieldProperty.find(
          (property) => property.name === field.hubspotField
        );
        const cbField = coachbarFields.find((property) => {
          if (property.value === field.coachbarField) {
            return property;
          }
        });
        if (hsField && cbField) {
          fieldMappingArray.push({
            coachbarField: {
              label: cbField.label,
              value: cbField.value,
              disable: true,
            },
            hubspotField: {
              label: `${hsField.label} (${
                hsField.groupName === "contactinformation"
                  ? "Contact"
                  : "Company"
              })`,
              value: hsField.name,
              disable: true,
              category: hsField.category,
            },
          });
        }
      });
      setFieldMapping(fieldMappingArray);
    }
  }, [hubSpotFieldProperty]);

  useEffect(() => {
    if (
      integrationDetail?.referralStatusMapping?.length > 0 &&
      hubSpotStatusProperty.length > 0
    ) {
      let statusMappingArray = [];
      integrationDetail.referralStatusMapping.map((status) => {
        const hsStatus = hubSpotStatusProperty.find(
          (property) => property.value === status.hubspotStatus
        );
        const cbStatus = coachbarStatusList.find(
          (property) => property === status.coachbarStatus
        );
        if (hsStatus && cbStatus) {
          statusMappingArray.push({
            coachbarField: {
              label: cbStatus === "Inprocess" ? "In Process" : cbStatus,
              value: cbStatus,
              disable: true,
            },
            hubspotField: {
              label: hsStatus.label,
              value: hsStatus.value,
              disable: true,
            },
          });
        }
      });
      setStatusMapping(statusMappingArray);
    }
  }, [hubSpotStatusProperty]);

  const saveHubSpotSetting = () => {
    const emptyStatus = statusMapping.some((status) => {
      return !status.hubspotField.value || !status.coachbarField.value;
    });
    if (emptyStatus || statusMapping.length <= 0) {
      setStatusMappingError("Status mapping fields cannot be empty.");
      return;
    } else {
      setStatusMappingError("");
    }
    let masterSource;
    if (syncDirection === "Coachbar to Hubspot") {
      masterSource = "Coachbar";
    } else if (syncDirection === "Hubspot to Coachbar") {
      masterSource = "Hubspot";
    } else if (syncDirection === "Hubspot and Coachbar") {
      masterSource = source;
    }
    let integrationParam = {
      storeConfig: {
        Referral: autoSync ? "Auto" : "Manual",
      },
      syncDirection: syncDirection,
      masterSource,
      referralStatusMapping: statusMapping.map((status) => ({
        coachbarStatus: status.coachbarField.value,
        hubspotStatus: status.hubspotField.value,
      })),
      channelId: integrationDetail.channelId,
    };
    if (
      selectedDate &&
      !integrationDetail?.lastAccessedRecord?.hubspotReferralToDate &&
      (syncDirection === "Hubspot and Coachbar" ||
        syncDirection === "Hubspot to Coachbar")
    ) {
      integrationParam.lastAccessedRecord = {
        hubspotReferralToDate: format(
          new Date(selectedDate),
          "yyyy-MM-dd hh:mm:ss"
        ),
      };
    }
    if (fieldMapping.length > 0) {
      integrationParam.referralFieldMapping = fieldMapping.map((status) => ({
        coachbarField: status.coachbarField.value,
        hubspotField: status.hubspotField.value,
        category: status?.hubspotField?.category,
      }));
    }
    let requestObj = {
      integrationId: integrationDetail.id,
      requestData: integrationParam,
    };
    dispatch(updateIntegration(requestObj));
  };

  useEffect(() => {
    if (hubSpotFieldProperty.length > 0) {
      prepareHubSpotField();
    }
  }, [hubSpotFieldProperty]);

  useEffect(() => {
    if (hubSpotStatusProperty.length > 0) {
      prepareStatus();
    }
  }, [hubSpotStatusProperty]);

  /**
   * This function is use to prepare hubspot fields for the react select dropdown.
   */
  const prepareHubSpotField = () => {
    const hubSpotFiledOption = hubSpotFieldProperty.map((item) => {
      let disable = false;
      if (integrationDetail?.referralFieldMapping?.length > 0) {
        integrationDetail.referralFieldMapping.map((field) => {
          if (field.hubspotField === item.name) {
            disable = true;
          }
        });
      }
      return {
        label: `${item.label} (${
          item.groupName === "contactinformation" ? "Contact" : "Company"
        })`,
        value: item.name,
        category: item.category,
        disable,
      };
    });
    sethubSpotField(hubSpotFiledOption);
  };

  /**
   * This function is use to prepare coachbar fields for the react select dropdown.
   */
  const prepareCoachbarField = () => {
    const coachbarFieldOption = coachbarFields.map((item) => {
      let disable = false;
      if (integrationDetail?.referralFieldMapping?.length > 0) {
        integrationDetail.referralFieldMapping.map((field) => {
          if (field.coachbarField === item.value) {
            disable = true;
          }
        });
      }
      return {
        label: item.label,
        value: item.value,
        disable: disable,
      };
    });
    setCoachbarField(coachbarFieldOption);
  };

  /**
   * This function is use to prepare status for the react select dropdown.
   */
  const prepareStatus = () => {
    const hubSpotStatusOption = hubSpotStatusProperty.map((item) => {
      let disable = false;
      if (integrationDetail?.referralStatusMapping?.length > 0) {
        integrationDetail.referralStatusMapping.map((field) => {
          if (field.hubspotStatus === item.value) {
            disable = true;
          }
        });
      }
      return {
        label: item.label,
        value: item.value,
        disable: disable,
      };
    });
    setHubSpotStatus(hubSpotStatusOption);
    const coachbarStatus = coachbarStatusList.map((item) => {
      let disable = false;
      if (integrationDetail?.referralStatusMapping?.length > 0) {
        integrationDetail.referralStatusMapping.map((field) => {
          if (field.coachbarStatus === item) {
            disable = true;
          }
        });
      }
      return {
        label: item === "Inprocess" ? "In Process" : item,
        value: item,
        disable: disable,
      };
    });
    setCoachbarStatus(coachbarStatus);
  };

  const handleSyncClick = () => {
    setAutoSync(!autoSync);
  };

  const onSyncDirectionChange = (e) => {
    let value = e.target.value;
    setSyncDirection(value);
    if (value === "Coachbar to Hubspot") {
      setSelectedDate(null);
    }
  };

  const onSourceChange = (e) => {
    let value = e.target.value;
    setSource(value);
  };

  const fieldMappingFn = (mapping) => {
    setFieldMapping(mapping);
  };

  const statusMappingFn = (mapping) => {
    setStatusMapping(mapping);
  };

  const handleStatusBtnClick = async () => {
    let requestParam = {
      integrationId: integrationDetail.id,
      requestData: {
        active: !integrationStatus,
      },
    };
    dispatch(updateIntegrationStatus(requestParam));
  };

  const handleManualSync = async () => {
    dispatch(manualSync());
    props.fetchIntegration();
  };

  return (
    <>
      {loading || integrationLoading ? (
        <Loader />
      ) : (
        <Stack spacing={8} m="5" align="stretch">
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <HStack spacing={4}>
              <Image src={hubSpotintegrationImage} boxSize={"50px"} />
              <Text
                fontSize={"1.125rem"}
                lineHeight={"15px"}
                fontWeight={"400"}
                color={"brand.100"}
              >
                {integrationStatus
                  ? "HubSpot Connected"
                  : "HubSpot Disconnected"}
              </Text>
              {integrationDetail?.storeConfig?.Referral !== "Auto" &&
              integrationStatus ? (
                <Button
                  title="Manual Sync"
                  w={"9rem"}
                  h={"2.25rem"}
                  variant={"serviceButton"}
                  onClick={handleManualSync}
                  isLoading={manualSyncLoading}
                  id="integration-hubspot-manual-sync"
                />
              ) : (
                ""
              )}
            </HStack>

            {integrationStatus ? (
              <Button
                title={"Disconnect"}
                w={"10rem"}
                h={"2.25rem"}
                variant={"serviceButton"}
                bg={"#FA5655"}
                borderColor={"#FA5655"}
                onClick={handleStatusBtnClick}
                isLoading={updateLoading}
                id="integration-hubspot-disconnect"
              />
            ) : (
              <Button
                title="Connect"
                w={"9rem"}
                h={"2.25rem"}
                variant={"serviceButton"}
                onClick={handleStatusBtnClick}
                isLoading={updateLoading}
                id="integration-hubspot-connect"
              />
            )}
          </Flex>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="email-alerts" mb="0">
              {autoSync
                ? "Enable automatic synchronization of data with HubSpot"
                : "You'll need to manually update your data in HubSpot"}
            </FormLabel>
            <Switch
              onChange={handleSyncClick}
              id="email-alerts"
              isChecked={autoSync}
            />
          </FormControl>

          <Flex alignItems={"center"} gap={8}>
            <Stack w={"50%"}>
              <Text
                fontSize={"18px"}
                lineHeight={"1rem"}
                fontWeight={"600"}
                color={"brand.100"}
                pb={"0.5rem"}
              >
                Sync Direction:
              </Text>
              <Text
                fontSize={"14px"}
                lineHeight={"18px"}
                fontWeight={"400"}
                color={"brandGray.200"}
              >
                {directionTextMapping[syncDirection]}
              </Text>
            </Stack>
            <Spacer />
            <Select
              value={syncDirection}
              options={syncDirectionOption}
              onChange={onSyncDirectionChange}
              size="md"
            />
          </Flex>

          {syncDirection === "Hubspot and Coachbar" && (
            <Flex alignItems={"center"} gap={8}>
              <Stack w={"50%"}>
                <Text
                  fontSize={"18px"}
                  lineHeight={"1rem"}
                  fontWeight={"600"}
                  color={"brand.100"}
                  pb={"0.5rem"}
                >
                  Resolve Data Conflicts :
                </Text>
                <Text
                  fontSize={"14px"}
                  lineHeight={"18px"}
                  fontWeight={"400"}
                  color={"brandGray.200"}
                >
                  {sourceTextMapping[source]}
                </Text>
              </Stack>
              <Spacer />
              <Select
                value={source}
                options={sourceOption}
                onChange={onSourceChange}
                size="md"
              />
            </Flex>
          )}

          {!integrationDetail?.lastAccessedRecord?.hubspotReferralToDate &&
            (syncDirection === "Hubspot and Coachbar" ||
              syncDirection === "Hubspot to Coachbar") && (
              <Flex alignItems={"center"} gap={8}>
                <Stack w={"50%"}>
                  <Text
                    fontSize={"18px"}
                    lineHeight={"1rem"}
                    fontWeight={"600"}
                    color={"brand.100"}
                    pb={"0.5rem"}
                  >
                    Sync Historical Data :
                  </Text>
                  <Text
                    fontSize={"14px"}
                    lineHeight={"18px"}
                    fontWeight={"400"}
                    color={"brandGray.200"}
                  >
                    Syncing historical data, up to a maximum of 2 months from
                    the current date.
                  </Text>
                </Stack>
                <Spacer />
                <CustomSingleDayPicker
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  offsetRight="18px"
                />
              </Flex>
            )}

          {coachbarStatus.length && HubSpotStatus.length ? (
            <Box>
              <Text
                fontSize={"1.125rem"}
                lineHeight={"15px"}
                fontWeight={"600"}
                color={"brand.100"}
              >
                Map Coachbar Referral Status to HubSpot Lead Status
              </Text>
              <FieldMapping
                leftFieldProperty={coachbarStatus}
                rightFieldProperty={HubSpotStatus}
                mappingFn={statusMappingFn}
                mappingError={statusMappingError}
                fieldMapping={statusMapping}
                text="Status"
              />
            </Box>
          ) : (
            ""
          )}

          {coachbarField.length && hubSpotField.length ? (
            <Box>
              <Text
                fontSize={"1.125rem"}
                lineHeight={"15px"}
                fontWeight={"600"}
                color={"brand.100"}
              >
                Map Coachbar Property to HubSpot Property
              </Text>
              <FieldMapping
                leftFieldProperty={coachbarField}
                rightFieldProperty={hubSpotField}
                mappingFn={fieldMappingFn}
                // mappingError={fieldMappingError}
                fieldMapping={fieldMapping}
                text="Field"
              />
            </Box>
          ) : (
            ""
          )}
          <HStack spacing={6}>
            <Button
              onClick={saveHubSpotSetting}
              isLoading={integrationLoading}
              title="Save Changes"
              id="integration-hubspot-setting-save"
            />
            <Button
              variant={"discard"}
              onClick={() => props.onTabClick()}
              title="Cancel"
              width="98px"
              id="integration-hubspot-setting-discard"
            />
          </HStack>
        </Stack>
      )}
    </>
  );
};

export default SettingsTab;
