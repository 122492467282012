import { axiosInstance } from "../../services/axiosInstance";
import { getUserInfo, handleError } from "../../services/utility";

export const getTagListBySearch = async (searchText, abortControllerSignal) => {
  try {
    const { tenantId } = getUserInfo();
    let { data } = await axiosInstance.get(`/universalTags/all`, {
      params: { searchText, tenantId },
      signal: abortControllerSignal,
    });
    if (data.success) return data.data?.tagList;
  } catch (error) {
    if (error?.code === "ERR_CANCELED") return;
    handleError(error);
  }
};

export const createTag = async (name) => {
  try {
    let { data } = await axiosInstance.post("/universalTags/add", {
      name,
    });
    return data;
  } catch (error) {
    handleError(error);
  }
};
