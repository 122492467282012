import { Avatar, Box, Center, Flex, Text } from "@chakra-ui/react";
import { Loader, Table } from "@coachbar/shared-components";
import ChartContainer from "@coachbar/shared-components/src/screens/dashboard/ChartContainer";
import ChartHeader from "@coachbar/shared-components/src/screens/dashboard/ChartHeader";
import { useSelector } from "react-redux";
import NoDataChart from "@coachbar/shared-components/src/screens/dashboard/NoDataChart";
import { useChartsProvider } from "@coachbar/shared-components/src/screens/dashboard/chartContext";
import {
  compareInnerAndGeneralFilters,
  formatYAxisTicks,
  getAppliedFilters,
} from "@coachbar/shared-components/src/services/dashboardUtility/utility";
import {
  deepCopyObject,
  getCurrency,
} from "@coachbar/shared-components/src/services/utility";
import { useEffect, useMemo, useState } from "react";
import { getFinancialLeaderBoardData } from "../financialActions";
import { format } from "date-fns";
import {
  last30DaysEnd,
  last30DaysStart,
} from "@coachbar/shared-components/src/constants/constantValues";

const tableHead = [
  ["Provider", "Revenue", "Avg. Lead To Win Time", "Avg. Commission Per Month"],
];

const LeaderBoard = ({ slug, chartData }) => {
  const { selectedDate, appliedFilters, fixFilters } = useChartsProvider();
  let userInfo = useSelector((state) => state.auth?.userInfo);

  const widgetInfo = chartData;
  const [isLoading, setIsLoading] = useState(false);
  const [tableBody, setTableBody] = useState([]);
  const [innerDate, setInnerDate] = useState(
    deepCopyObject(
      userInfo?.role === "Manager"
        ? {
            from: format(last30DaysStart, "yyyy-MM-dd"),
            to: format(last30DaysEnd, "yyyy-MM-dd"),
          }
        : {}
    )
  );
  const [innerFilters, setInnerFilters] = useState(deepCopyObject(fixFilters));

  const getStyledTexts = (
    text,
    showPercentage = false,
    showAvatar = false,
    showCurrency = false
  ) => {
    const textStyle = {
      color: "#111A29",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "20px",
      width: "fit-content",
    };

    if (showAvatar) {
      return (
        <Flex alignItems="center" gap="8px">
          <Avatar
            border="1px solid #CEEAEE"
            size="xs"
            name={text?.name}
            src={text?.logo}
          />
          <Text {...textStyle}>{text?.name}</Text>
        </Flex>
      );
    } else {
      return (
        <Text {...textStyle}>
          {showCurrency ? getCurrency().symbol : ""}
          {isNaN(text) ? text : formatYAxisTicks(text || 0)}
          {showPercentage}
        </Text>
      );
    }
  };

  let innerAppliedFilters = useMemo(() => {
    return getAppliedFilters(innerFilters);
  }, [innerFilters]);

  const fetchLeadBoardData = async () => {
    let filtersPass = compareInnerAndGeneralFilters(
      userInfo?.role === "Manager" ? {} : appliedFilters,
      innerAppliedFilters,
      Object.keys(fixFilters),
      innerDate,
      selectedDate
    );
    if (filtersPass?.partners) delete filtersPass?.partners;
    let requestData = { ...filtersPass };

    setIsLoading(true);
    let { data } = await getFinancialLeaderBoardData(requestData);
    setIsLoading(false);

    if (data?.dashboard) {
      const tBody = data?.dashboard.map((item) => [
        getStyledTexts(item, false, true),
        getStyledTexts(item.totalDealAmount, true, false, true),
        getStyledTexts(item.AverageLeadToWinTime + " days"),
        getStyledTexts(item?.AverageCommission, false, false, true),
      ]);

      setTableBody(tBody);
    }
  };

  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      fetchLeadBoardData();
    }
  }, [innerDate, selectedDate, innerAppliedFilters, appliedFilters]);

  return (
    <>
      {widgetInfo?.isVisible && (
        <ChartContainer>
          <ChartHeader
            title={widgetInfo?.title}
            showDelete
            showFilters
            chartData={widgetInfo}
            filters={innerFilters}
            onSubmitFilters={(newFilters, date) => {
              setInnerFilters(newFilters);
              setInnerDate(date);
            }}
            date={innerDate}
            generalDate={selectedDate}
          />
          {isLoading ? (
            <Center h={"80%"}>
              <Loader />
            </Center>
          ) : tableBody.length === 0 ? (
            <Box h={"250px"}>
              <NoDataChart />
            </Box>
          ) : (
            <Box
              css={{
                td: {
                  width: "fit-content",
                },
              }}
            >
              <Table
                tHead={tableHead}
                tBody={tableBody}
                headingStyles={{
                  backgroundColor: "#EDF2F7",
                  color: "#1C2C44 !important",
                }}
                isHovered
                keepBordersRounded
                keepLastColumnLeftAligned
                showTableBorders
              />
            </Box>
          )}
        </ChartContainer>
      )}
    </>
  );
};

export default LeaderBoard;
