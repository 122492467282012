import React from "react";
import {
  Input,
  FormErrorMessage,
  FormControl,
  FormLabel,
  FormHelperText,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  InputRightElement,
  Image,
  HStack,
} from "@chakra-ui/react";
import warningIcon from "../../images/warningIcon.png";
import CustomTooltip from "../Tooltip/CustomTooltip";
const TextBox = ({
  validationErrors,
  innerref,
  hideLabel,
  widthFull,
  showPlaceHolder,
  leftAddOn,
  leftAddOnStyles,
  rightAddOn,
  reduceOpacity = false,
  errorSpace = true,
  ...props
}) => {
  let keys = innerref && innerref.name && innerref.name.split(".");
  const validation =
    validationErrors &&
    (keys.length > 1
      ? validationErrors?.[keys[0]]?.[keys[1]]
      : validationErrors?.[keys[0]]);

  return (
    <React.Fragment>
      <FormControl
        name={props.name || props.placeholder}
        isInvalid={props?.rightElement || validation}
        isRequired={props.required}
        // mt={"20px !important"}
      >
        {!hideLabel ? (
          <HStack mb={"4px"}>
            <FormLabel
              fontSize={"12px"}
              color={props?.labelColor ? props?.labelColor : "#5E6977"}
              fontWeight={props?.labelFontWeight ? props?.labelFontWeight : 600}
              textTransform={"capitalize"}
              as="legend"
              mr="2px"
              mb={0}
            >
              {props.name || props.placeholder}
            </FormLabel>
            {props.tooltip && (
              <CustomTooltip verticalAlign="middle" content={props.tooltip} />
            )}
          </HStack>
        ) : null}
        <InputGroup
          opacity={reduceOpacity ? 0.5 : 1}
          cursor={reduceOpacity ? "not-allowed" : "auto"}
        >
          {leftAddOn && (
            <InputLeftAddon
              style={{ ...leftAddOnStyles }}
              children={leftAddOn}
            />
          )}
          <Input
            borderColor={"#E6E7E9"}
            {...props}
            {...innerref}
            // variant="pill"
            focusBorderColor={"#0C94AC"}
            errorBorderColor={"#F8B4B4"}
            boxShadow={"none !important"}
            fontSize={"14px"}
            width={widthFull ? "full" : "full"}
            placeholder={showPlaceHolder ? props.placeholder : ""}
          />
          {rightAddOn && <InputRightAddon children={rightAddOn} />}
          {(props?.rightElement || validation) && (
            <InputRightElement>
              <Image src={warningIcon} />
            </InputRightElement>
          )}
        </InputGroup>
        {validation ? (
          <Errors errors={validation} />
        ) : props.hideHelperText ? (
          ""
        ) : (
          errorSpace && <FormHelperText>&nbsp;</FormHelperText>
        )}
      </FormControl>
    </React.Fragment>
  );
};

const Errors = ({ errors }) => {
  if (errors.types && Object.entries(errors.types).length > 0) {
    return Object.entries(errors.types).map(([type, message]) => (
      <FormErrorMessage key={type}>{message}</FormErrorMessage>
    ));
  } else {
    return <FormErrorMessage>{errors.message}</FormErrorMessage>;
  }
};

export default TextBox;
