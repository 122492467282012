import React from "react";
import Select from "react-select";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  HStack,
  Text,
} from "@chakra-ui/react";
import CountryFlag from "../CountryFlag/CountryFlag";
import CustomTooltip from "../Tooltip/CustomTooltip";

const CountryDropdown = ({
  validationErrors,
  innerRef = {},
  options,
  hideLabel,
  placeholder,
  onChange,
  parentWidth,
  customWidth,
  value,
  name,
  ...props
}) => {
  const validation = validationErrors && validationErrors["country"];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: `${
        state.isFocused ? "#0E97AE !important" : "#E6E7E9 !important"
      }`,
      boxShadow: "none",
      width: customWidth,
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "38px",
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: "8px",
      cursor: "pointer",
    }),
  };

  return (
    <FormControl
      width={parentWidth ? parentWidth : ""}
      isInvalid={!!validation}
      isRequired={props.required}
    >
      {!hideLabel ? (
        <FormLabel
          textTransform={"capitalize"}
          fontSize={"12px"}
          fontWeight={600}
          color={"#5E6977"}
          mb={"4px"}
          as="legend"
          display={"flex"}
        >
          {props.name || placeholder}
        </FormLabel>
      ) : null}
      <Select
        placeholder={placeholder}
        options={options}
        {...props}
        {...innerRef}
        value={value}
        onChange={onChange}
        styles={customStyles}
        isDisabled={props.disable ? true : false}
        components={{
          Option: ({ innerProps, label }) => (
            <div {...innerProps} style={{ paddingLeft: 2, paddingRight: 2 }}>
              <HStack
                bg={value?.label === label ? "brandGray.10" : "white"}
                _hover={{ bg: "brandGray.10" }}
                p={2}
              >
                <CountryFlag country={label} height={"1em"} />{" "}
                <Text>{label}</Text>
              </HStack>
            </div>
          ),
        }}
      />
      {validation ? (
        <Errors errors={validation} />
      ) : props.hideHelperText ? (
        ""
      ) : (
        <FormHelperText>&nbsp;</FormHelperText>
      )}
    </FormControl>
  );
};

const Errors = ({ errors }) => {
  if (errors) {
    return <FormErrorMessage>{errors.message}</FormErrorMessage>;
  }
  return null;
};

export default CountryDropdown;
