import { Box, Center, Image, Text } from "@chakra-ui/react";
import noRecordsFoundImg from "../images/match-make-empty.png";

const NoRecordFoundScreen = ({
  title,
  description,
  minHeight,
  imageUrl,
  children,
}) => {
  return (
    <Center minH={minHeight ? minHeight : "240px"}>
      <Box textAlign={"center"}>
        <Image
          src={imageUrl || noRecordsFoundImg}
          h={"100px"}
          mb={"24px"}
          mx={"auto"}
        />
        <Text
          color={"var(--title-black-text)"}
          fontSize={"16px"}
          lineHeight={"24px"}
          fontWeight={700}
          mb={"8px !important"}
        >
          {title || "No records found!"}
        </Text>
        <Text
          color={"#7E8792"}
          fontSize={"14px"}
          lineHeight={"20px"}
          fontWeight={500}
          mb={"20px !important"}
        >
          {description || ""}
        </Text>
        {children}
      </Box>
    </Center>
  );
};

export default NoRecordFoundScreen;
