import {
  format,
  parseISO,
  differenceInDays,
  subDays,
  formatISO,
  parse,
  isSameDay,
} from "date-fns";
import { deepCopyObject, getCurrency } from "../utility";
import {
  colors,
  filtersKeys,
  regionColors,
  regionKeys,
  regionLabels,
  regionWiseCountryCodes,
} from "../../constants/dashboard";
import { dateConstants } from "../../constants/constantValues";

export const calculatePercentageBasedOnData = (data) => {
  // Calculate total and previous total values
  let values = {};
  data.forEach((x) => {
    if (!values[x.legend]) {
      values[x.legend] = [x];
    } else {
      values[x.legend].push(x);
    }
  });
  let dataSet = Object.values(values).flat();
  let forTotal = {};
  data.forEach((x) => {
    if (!forTotal[x.legend]) {
      forTotal[x.legend] = x;
    }
  });
  const totals = Object.values(forTotal).reduce(
    (acc, curr) => {
      acc.total += curr.value;
      acc.prevTotal += curr.previousValue || 0;
      return acc;
    },
    { total: 0, prevTotal: 0 }
  );
  // Calculate percentage for each item
  return dataSet.map((item) => {
    const percentage = (item.value / totals.total) * 100 || 0;
    const prevPercentage = (item.previousValue / totals.prevTotal) * 100 || 0;

    return {
      ...item,
      id: item.id,
      value: item.value,
      legend: item.legend,
      percentage: percentage || "",
      ...(item.previousValue !== undefined && {
        previousValue: item.previousValue,
        prevPercentage: prevPercentage || "",
      }),
    };
  });
};

/**
 * Calculates the percentage distibution between the total records present in grid.
 * Do not make any changes in this function unless you need to modify percentage calculation in ChartGrid component.
 *
 * @param {string[]} data - Array of objects in which you want to add additional percentage property based on it's value.
 * @returns {string} Array of update objects with percentage property.
 */
export const gridPercentageCalculation = (data) => {
  // Calculate total value
  const total = data.reduce((acc, curr) => acc + curr.value, 0);
  const prevTotal = data.reduce((acc, curr) => acc + curr.previousValue, 0);

  // Calculate percentage for each country
  return data.map((dataItem) => ({
    id: dataItem.id,
    value: dataItem.value,
    percentage: (dataItem.value / total) * 100,
    previousValue: dataItem?.previousValue,
    prevPercentage: (dataItem.previousValue / prevTotal) * 100,
  }));
};

export const toggleWidgetProperty = (
  updatedChartData,
  chartData,
  setUpdatedChartData,
  propertyName
) => {
  const updatedChartList = {
    ...updatedChartData,
    [chartData?.slug]: {
      ...updatedChartData?.[chartData?.slug],
      [propertyName]: !updatedChartData?.[chartData?.slug]?.[propertyName],
    },
  };
  setUpdatedChartData(updatedChartList);
};

/**
 * Formats a date range into a string.
 * If the date range is not specified or incomplete, returns "All Time".
 * Optionally formats dates based on where to display.
 *
 * @param {Object} dateRange - The date range object with 'from' and 'to' properties.
 * @param {string} [formatOption="chart"] - Whether the format is for chart or grid or any other components.
 * @returns {string} The formatted date range string.
 */
export const formatDateRange = (dateRange, formatOption) => {
  if (!dateRange?.from || !dateRange?.to) {
    return "All Time";
  }

  let dateFormat, dateSeparator;

  switch (formatOption) {
    case "chart":
      dateFormat = "MMM dd";
      dateSeparator = "-";
      break;
    case "grid":
      dateFormat = "dd MMM, yyyy";
      dateSeparator = "to";
      break;
    default:
      dateFormat = "dd MMM, yyyy";
      dateSeparator = "to";
      break;
  }

  const formattedFrom = format(new Date(dateRange.from), dateFormat);
  const formattedTo = format(new Date(dateRange.to), dateFormat);

  return `${formattedFrom} ${dateSeparator} ${formattedTo}`;
};

export const formatTrenDate = (dateString) => {
  const date = new Date(dateString);
  return format(date, "MMM d, yyyy");
};

export const formatPercentage = (percentage) => {
  if (percentage % 1 === 0) {
    // If the percentage has no decimal part
    return Math.round(percentage) + "%";
  } else {
    // If the percentage has decimal part
    return percentage?.toFixed(2) + "%";
  }
};

export const prepareLeadAndDealTypeData = (fetchedData, colorMap) => {
  return Object.keys(fetchedData)
    .filter((key) => key !== "total") // Exclude 'total' property from API data
    .map((key) => {
      const color = colorMap[key];
      return {
        id: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
        label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize the first letter
        value: fetchedData[key],
        color: color,
      };
    });
};

/**
 * Prepare lead and deal data by status.
 * @param {Object} fetchedData - The fetched data object with status keys.
 * @param {Object} colorMap - The color map for the statuses.
 * @param {Object} [labelAndLegendMap] - Optional map for labels and legends.
 * @returns {Object} - An object containing the transformed data and the total value.
 */
export const prepareLeadAndDealDataByStatus = (
  fetchedData,
  colorMap = undefined,
  labelAndLegendMap = undefined
) => {
  const transformedData = Object.keys(fetchedData).map((key, i, arr) => ({
    id: labelAndLegendMap?.[key] || key,
    value: fetchedData[key],
    label: labelAndLegendMap?.[key] || key,
    legend: labelAndLegendMap?.[key] || key,
    color: colorMap ? colorMap[key] : colors[i % colors.length],
  }));

  const totalValue = transformedData.reduce((acc, item) => acc + item.value, 0);

  return { preparedStatusData: transformedData, totalValue };
};

// it will be used for customer type bar chart
export const prepareBarChartData = (
  fetchedData,
  colorMap = undefined,
  labelAndLegendMap = undefined
) => {
  const transformedData = Object.keys(fetchedData).map((key, i, arr) => ({
    id: fetchedData[key]?.label || key,
    actualId: labelAndLegendMap?.[key],
    value: fetchedData[key]?.value,
    label: fetchedData[key]?.label || key,
    legend: labelAndLegendMap?.[key] || key,
    color: colorMap ? colorMap[key] : colors[i % colors.length],
  }));

  const totalValue = transformedData.reduce((acc, item) => acc + item.value, 0);

  return { preparedStatusData: transformedData, totalValue };
};

/**
 * Calculate the number of days between two dates.
 * @param {Object} dateRange - The date range with 'from' and 'to' properties.
 * @param {string} dateRange.from - The starting date of the range.
 * @param {string} dateRange.to - The ending date of the range.
 * @returns {number} - The number of days between the two dates.
 */
export const calculateDaysBetweenDates = (dateRange) => {
  const { from, to } = dateRange;
  const currentFromDate = parseISO(from);
  const currentToDate = parseISO(to);
  return differenceInDays(currentToDate, currentFromDate);
};

/**
 * Get the previous date range based on the current date range.
 * @param {Object} selectedDate - The current date range.
 * @param {string} selectedDate.from - The starting date of the current range.
 * @param {string} selectedDate.to - The ending date of the current range.
 * @returns {Object} - The previous date range.
 */
export const getPreviousDateRange = (selectedDate) => {
  if (!selectedDate?.from || !selectedDate?.to) {
    return selectedDate;
  }

  const to = parse(selectedDate?.to, "yyyy-MM-dd", new Date());
  const from = parse(selectedDate?.from, "yyyy-MM-dd", new Date());

  let currentType = dateConstants.find(
    (x) =>
      isSameDay(x.dateObj.startDate, from) && isSameDay(x.dateObj.endDate, to)
  );
  if (currentType) {
    if (currentType?.label === "This week") {
      let thisType = dateConstants.find((x) => x.label === "Last week");
      return {
        from: formatISO(thisType.dateObj.startDate, { representation: "date" }),
        to: formatISO(thisType.dateObj.endDate, { representation: "date" }),
      };
    } else if (currentType?.label === "This month") {
      let thisType = dateConstants.find((x) => x.label === "Last month");
      return {
        from: formatISO(thisType.dateObj.startDate, { representation: "date" }),
        to: formatISO(thisType.dateObj.endDate, { representation: "date" }),
      };
    } else if (currentType?.label === "This quarter") {
      let thisType = dateConstants.find((x) => x.label === "Last quarter");
      return {
        from: formatISO(thisType.dateObj.startDate, { representation: "date" }),
        to: formatISO(thisType.dateObj.endDate, { representation: "date" }),
      };
    }
  }

  // Calculate the number of days between the current dates
  const numberOfDays = calculateDaysBetweenDates(selectedDate) + 1;

  // Parse the current dates
  const currentFromDate = parseISO(selectedDate.from);
  const currentToDate = parseISO(selectedDate.to);

  // Calculate the previous date range by subtracting the number of days
  const previousFromDate = subDays(currentFromDate, numberOfDays);
  const previousToDate = subDays(currentToDate, numberOfDays);

  // Format the dates back to ISO string
  return {
    from: formatISO(previousFromDate, { representation: "date" }),
    to: formatISO(previousToDate, { representation: "date" }),
  };
};

/**
 * Calculates the value difference between current and previous date range.
 * @param {Array} data - The current date data array.
 * @param {Array} prevData - The previous date data array.
 * @returns {Object} - An object with the value differences.
 */
export const calculateValueDifferences = (data, prevData) => {
  // Create a lookup object for previous data
  const prevDataLookup = prevData.reduce((acc, item) => {
    acc[item.id] = item.value;
    return acc;
  }, {});

  // Calculate the value difference for each item in the current data
  const valueDifferences = data.reduce((acc, item) => {
    const prevValue = prevDataLookup[item.id] || 0;
    const currentValue = item.value;

    const difference = currentValue - prevValue;

    acc[item.id] = difference;
    return acc;
  }, {});

  return valueDifferences;
};

export const getAppliedFilters = (filters) => {
  let newFilters = {};
  Object.keys(filters).forEach((x) => {
    let selectedFilters = filters[x].filter((x) => x.isChecked);
    if (selectedFilters.length > 0) {
      if (x === "type") {
        newFilters[x] =
          selectedFilters.length > 1 ? undefined : selectedFilters[0].value;
      } else {
        newFilters[x] =
          selectedFilters.length > 0
            ? selectedFilters.map((x) => x.value)
            : undefined;
      }
    }
  });
  return newFilters;
};

export const compareInnerAndGeneralFilters = (
  generalFilters,
  innerFilters,
  allFilterKeys,
  innerDate,
  generalDate
) => {
  let filters = {};
  allFilterKeys.forEach((key) => {
    if (innerFilters[key]) {
      filters[key] = innerFilters[key];
    } else if (generalFilters[key]) {
      filters[key] = generalFilters[key];
    }
  });
  if (
    innerDate?.hasOwnProperty("to") &&
    innerDate?.hasOwnProperty("from") &&
    JSON.stringify(innerDate) !== JSON.stringify(generalDate)
  ) {
    filters.fromDate = innerDate?.from;
    filters.toDate = innerDate?.to;
  } else {
    filters.fromDate = generalDate?.from;
    filters.toDate = generalDate?.to;
  }

  return filters;
};

export const formatDataForTrendDynamics = (
  statusColors = undefined,
  data,
  hasLabel = false
) => {
  return statusColors.map((status) => {
    return {
      id: hasLabel ? status.label : status.id,
      color: status.color,
      data: Object.keys(data).map((date) => {
        return {
          x: date,
          y: data[date][status.id] || 0,
        };
      }),
    };
  });
};

export const formatPrevDataForTrendDynamics = (
  currentData = {},
  prevData = {},
  statusColors = undefined
) => {
  return statusColors.map((status) => {
    return {
      id: `prev-${status.id}`,
      color: status.color,
      isPrev: true,
      data: Object.keys(currentData)
        .map((key, index) => {
          const prevDataEntry = Object.values(prevData)[index];
          // Check if prevDataEntry exists and has the status.id property
          if (!prevDataEntry) return undefined;

          return {
            x: key,
            y: prevDataEntry[status.id] || 0,
          };
        })
        .filter(Boolean),
    };
  });
};

export const removePrevData = (dataArray) => {
  return dataArray.filter((item) => !item.isPrev);
};

export const formatTrendData = (data) => {
  if (Object.keys(data).length === 0) {
    return [];
  }
  let labels = Object.keys(Object.values(data)[0]);
  return labels.map((label, i, arr) => {
    return {
      id: label,
      color: colors[i % colors.length],
      data: Object.keys(data).map((date) => {
        return {
          x: date,
          y: data[date][label] || 0,
        };
      }),
    };
  });
};

export const formatPrevTrendData = (currentData = {}, prevData = {}) => {
  if (Object.keys(currentData).length === 0) {
    return [];
  }
  let labels = Object.keys(Object.values(currentData)[0]);

  return labels.map((label, i) => {
    return {
      id: `prev-${label}`,
      color: colors[i % colors.length],
      isPrev: true,
      data: Object.keys(currentData)
        .map((key, index) => {
          const prevDataEntry = Object.values(prevData)[index];
          // Check if prevDataEntry exists and has the status.id property
          if (!prevDataEntry) return undefined;

          return {
            x: key,
            y: prevDataEntry[label.id] || prevDataEntry[label] || 0,
          };
        })
        .filter(Boolean),
    };
  });
};

export const calculatePercentageDifference = (oldValue, newValue) => {
  if (oldValue === 0 && newValue === 0) {
    return 0;
  }
  if (oldValue === 0) {
    return 100;
  }
  return parseFloat((((newValue - oldValue) / oldValue) * 100).toFixed(1));
};

export const getVisibleChartsFromDb = (widgetsList, charts) => {
  let res = {};
  Object.keys(charts).forEach((chartKey) => {
    res[chartKey] = deepCopyObject(charts[chartKey]);
    res[chartKey].isVisible = widgetsList.includes(chartKey);
  });
  return res;
};

export const getSpecificInnerFilters = (fixFilters, chartFilters) => {
  let filters = {};
  chartFilters.forEach((filterKey) => {
    if (filterKey !== filtersKeys.period)
      filters[filterKey] = deepCopyObject(fixFilters[filterKey]);
  });
  return deepCopyObject(filters);
};

export const generateRegionWiseDataForRegionMap = (data) => {
  const result = [];

  for (const region in data) {
    if (data.hasOwnProperty(region)) {
      const value = data[region];
      if (value > 0) {
        if (region !== regionKeys.Others) {
          const countries = regionWiseCountryCodes[region];
          countries.forEach((countryISO) => {
            result.push({
              id: countryISO,
              legend: regionLabels[region],
              value,
              color: regionColors[region],
              regionKey: region,
            });
          });
        } else {
          result.push({
            id: "GRL",
            legend: regionLabels[regionKeys.Others],
            value,
            color: regionColors[regionKeys.Others],
            regionKey: regionKeys.Others,
          });
        }
      }
    }
  }

  return result;
};

/**
 * Remove the previousValue property from each object in an array.
 * @param {Array} array - The array of objects to process.
 * @returns {Array} - A new array with the previousValue property removed from each object.
 */
export const removePreviousValue = (array) => {
  return array.map(({ previousValue, ...rest }) => rest);
};

/**
 * Add previous period data to current data array.
 * @param {Object} prevData - Previous period data as an object with id as key and value as value.
 * @param {Array} currentData - Current data array where each object contains an id.
 * @returns {Array} - A new array with the previousValue key added to matching objects.
 */
export const addPreviousValues = (prevData, currentData) => {
  return currentData.map((item) => {
    if (prevData.hasOwnProperty(item.id)) {
      return { ...item, previousValue: prevData[item.id] };
    } else if (prevData.hasOwnProperty(item.legend)) {
      if (
        typeof prevData[item.legend] === "number" ||
        typeof prevData[item.legend] === "string"
      ) {
        return { ...item, previousValue: prevData[item.legend] };
      } else {
        return { ...item, previousValue: prevData[item.legend].value };
      }
    }
    return item;
  });
};

export const checkTrendSumsIs0 = (data) => {
  if (data?.length === 0) {
    return true;
  }
  return data?.every((item) => {
    const sum = item?.data?.reduce((acc, curr) => acc + curr.y, 0);
    return sum === 0;
  });
};

// Preprocess dataArray to create a lookup map for quick access to data objects
const createLookupMap = (dataArray) => {
  const map = {};
  dataArray.forEach((item) => {
    map[item.id] = item;
  });
  return map;
};

// Create a map of dates to values for quick access to the data points
const createDataMap = (data) => {
  const map = {};
  data.forEach((point) => {
    map[point.x] = point.y;
  });
  return map;
};

export const findCorrespondingTrendValue = (id, date, dataArray = []) => {
  const lookupMap = createLookupMap(dataArray);

  // Get the main and counterpart IDs
  const counterpartId = id.startsWith("prev-") ? id.slice(5) : `prev-${id}`;

  // Retrieve the main object and counterpart object using the lookup map
  const mainObject = lookupMap[id];
  const counterpartObject = lookupMap[counterpartId];

  if (!mainObject || !counterpartObject) {
    return 0;
  }

  const mainDataMap = createDataMap(mainObject.data);
  const counterpartDataMap = createDataMap(counterpartObject.data);

  // Get the corresponding values for the given date
  const mainValue = mainDataMap[date];
  const counterpartValue = counterpartDataMap[date];

  if (mainValue === undefined || counterpartValue === undefined) {
    return 0;
  }

  // Return the counterpart data point value
  return counterpartValue;
};

// export const findTrendPercentageDifference = (
//   date,
//   status,
//   currentData,
//   previousData
// ) => {
//   // Extract the dates from currentData and previousData
//   const currentDates = Object.keys(currentData);
//   const previousDates = Object.keys(previousData);

//   // Find the index of the given date in the current data
//   const dateIndex = currentDates.indexOf(date);

//   if (dateIndex === -1) {
//     throw new Error("Date not found in current data");
//   }

//   // Find the corresponding date in the previous data
//   const previousDate = previousDates[dateIndex];

//   if (!previousDate) {
//     throw new Error("Corresponding date not found in previous data");
//   }

//   // Get the current and previous values for the given status
//   const currentValue = currentData[0][date][status];
//   const previousValue = previousData[0][previousDate][status];

//   // Calculate the percentage difference
//   const difference = ((currentValue - previousValue) / previousValue) * 100;

//   // Handle the case where the previous value is zero to avoid division by zero
//   if (previousValue === 0) {
//     return currentValue === 0 ? 0 : 100;
//   }

//   return { difference, previousDate };
// };

export const formatDataRevenueProduct = (data) => {
  return Object.entries(data)
    .filter(([name, value]) => value > 0)
    .map(([name, value]) => {
      const total = Object.values(data).reduce((sum, val) => sum + val, 0);
      const percentage = (value / total) * 100;
      return {
        name,
        value,
        percentage: parseFloat(percentage.toFixed(2)),
        valueWithCurrency: `${getCurrency().symbol}${value}`,
      };
    })
    .sort((a, b) => b.percentage - a.percentage);
};

export const formatIncentivePlanData = (data) => {
  return data
    .map(({ name, total }) => {
      const totalVal = data.reduce((sum, val) => sum + val.total, 0);
      const percentage = (total / totalVal) * 100;
      return {
        name,
        value: total,
        percentage: parseFloat(percentage.toFixed(2)),
        valueWithCurrency: `${getCurrency().symbol}${totalVal}`,
      };
    })
    .sort((a, b) => b.percentage - a.percentage);
};

export const addPreviousValuesRevenueProduct = (prevData, currentData) => {
  const mergedData = [];

  // Create a Set of all unique IDs from both arrays
  const allIds = new Set([
    ...prevData.map((item) => item.name),
    ...currentData.map((item) => item.name),
  ]);

  // Iterate over the unique IDs and find the corresponding items in each array
  for (const name of allIds) {
    const currentItem = prevData.find((item) => item.name === name);
    const prevItem = currentData.find((item) => item.name === name);

    let percentChange;

    // Calculate value and percentage change if both current and previous items exist
    percentChange = calculatePercentageDifference(
      prevItem?.value || 0,
      currentItem?.value || 0
    );

    mergedData.push({
      name,
      value: currentItem?.value || 0,
      color: currentItem?.color || prevItem?.color,
      percentChange,
      previousValue: prevItem?.value || 0,
      valueChange: (currentItem?.value || 0) - (prevItem?.value || 0),
    });
  }
  return mergedData;
};

/**
 * Convert a camelCase string to a proper label with spaces and capitalization.
 * @param {string} camelCaseString - The camelCase string to convert.
 * @returns {string} - The formatted label with spaces and proper capitalization.
 */
export const convertToLabel = (camelCaseString) => {
  return (
    camelCaseString
      // Insert a space before all uppercase letters
      .replace(/([A-Z])/g, " $1")
      // Capitalize the first letter of the entire string
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
};

export const checkRegionDataIsEmpty = (data) => {
  let total = data.reduce((sum, item) => sum + item.value, 0);
  return total === 0 ? true : false;
};

export const regionCompareData = (record, comparedRecords) => {
  const mergedData = [];

  // Create a Set of all unique IDs from both arrays
  const allIds = new Set([
    ...record.map((item) => item.id),
    ...comparedRecords.map((item) => item.id),
  ]);

  // Iterate over the unique IDs and find the corresponding items in each array
  for (const id of allIds) {
    const currentItem = record.find((item) => item.id === id);
    const prevItem = comparedRecords.find((item) => item.id === id);

    let percentChange;

    // Calculate value and percentage change if both current and previous items exist
    percentChange = calculatePercentageDifference(
      prevItem?.value || 0,
      currentItem?.value || 0
    );

    mergedData.push({
      id,
      legend: currentItem?.legend || prevItem?.legend,
      value: currentItem?.value || 0,
      color: currentItem?.color || prevItem?.color,
      regionKey: currentItem?.regionKey || prevItem?.regionKey,
      percentChange,
      previousValue: prevItem?.value || 0,
      valueChange: (currentItem?.value || 0) - (prevItem?.value || 0),
    });
  }
  return mergedData;
};
/**
 * Format Y-axis tick values to show 'k' for thousands, 'M' for millions, and 'B' for billions.
 *
 * @param {number} value - The numerical value of the tick.
 * @returns {string|number} - The formatted value with 'k' for thousands, 'M' for millions, 'B' for billions, or the original value if less than 1000.
 */
export const formatYAxisTicks = (value) => {
  if (value >= 1_000_000_000) {
    return `${parseFloat((value / 1_000_000_000).toFixed(1))}B`;
  } else if (value >= 1_000_000) {
    return `${parseFloat((value / 1_000_000).toFixed(1))}M`;
  } else if (value >= 1000) {
    return `${parseFloat((value / 1000).toFixed(1))}k`;
  }
  return value ? `${parseFloat(value.toFixed(1))}` : value;
};

/**
 * Adds a default chart data point to the existing data array.
 * @param {string} dateString - The date string for the new data point.
 * @param {Array<{ x: string, y: number }>} existingData - The existing array of data points.
 * @returns {Array<{ x: string, y: number }>} - The updated array of data points.
 */
export const normalizeSingleLineChartArray = (dateString, existingData) => {
  const newDataPoint = { x: dateString, y: 0 };
  return [newDataPoint, ...existingData];
};
