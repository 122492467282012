import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../services/axiosInstance";
import { getErrorMessage } from "../../services/utility";

export const addIntegration = createAsyncThunk(
  "add/addIntegration",
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        "/hubspot/getTokens",
        requestData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getintegrationList = async () => {
  try {
    const data = await axiosInstance.get(`/integration`);
    return data;
  } catch (error) {
    // return custom error message from API if any
    return getErrorMessage(error);
  }
};

export const getIntegrationDetail = createAsyncThunk(
  "get/integration",
  async (integrationId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/integration/${integrationId}`);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateIntegration = createAsyncThunk(
  "add/updateIntegration",
  async ({ integrationId, requestData }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(
        `/integration/${integrationId}`,
        requestData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getIntegrationLogs = async (requestData) => {
  try {
    // configure header's Content-Type as JSON
    const { data } = await axiosInstance.post("/integration/logs", requestData);
    return data;
  } catch (error) {
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return error.response.data;
    } else {
      return error.message;
    }
  }
};

export const updateIntegrationStatus = createAsyncThunk(
  "add/updateIntegrationStatus",
  async ({ integrationId, requestData }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(
        `/integration/status/${integrationId}`,
        requestData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
