import { useState, useEffect } from "react";
import { Input, InputGroup, InputLeftAddon } from "@chakra-ui/react";
import useDebounce from "../../hooks/useDebounce";
import { ReactComponent as SearchIcon } from "../../images/search-icon.svg";

const SearchBar = ({
  debounce = false,
  debounceDelay = 300,
  onChange,
  value: externalValue,
  onSearchChangeOperations,
  placeholder,
  maxWidth,
  bottomSpacing,
  containerProps = {},
  ...inputProps
}) => {
  const [value, setValue] = useState(externalValue || "");
  const debouncedValue = useDebounce(value, debounceDelay);

  useEffect(() => {
    if (!debounce) {
      onChange(value);
    } else {
      onChange(debouncedValue);
    }
  }, [debounce, onChange, value, debouncedValue]);

  return (
    <InputGroup
      maxW={maxWidth || "230px"}
      border="1px solid var(--chakra-colors-brandGray-101)"
      rounded={"6px"}
      _focusWithin={{ borderColor: "cyprus.500" }}
      h={"auto"}
      mb={bottomSpacing || ""}
      {...containerProps}
    >
      <InputLeftAddon bg={"white"} border={"none"} h={"auto"} p={"8px 12px"}>
        <SearchIcon height={"16px"} width={"16px"} />
      </InputLeftAddon>
      <Input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          onSearchChangeOperations && onSearchChangeOperations(e);
        }}
        w={"100%"}
        maxW={maxWidth || "230px"}
        border={"none !important"}
        h={"auto"}
        p={"8px 16px 8px 0"}
        fontSize={"14px"}
        fontWeight={400}
        color={"atlantic.700"}
        _placeholder={{ fontSize: "14px", color: "brandGray.300" }}
        rounded="6px"
        _focusVisible={{ borderColor: "cyprus.500" }}
        {...inputProps}
      />
    </InputGroup>
  );
};

export default SearchBar;
