import { Box, SimpleGrid } from "@chakra-ui/react";
import ValuesChangeCard from "@coachbar/shared-components/src/components/Charts/ValuesChangeCard";
import ChartContainer from "@coachbar/shared-components/src/screens/dashboard/ChartContainer";
import ChartHeader from "@coachbar/shared-components/src/screens/dashboard/ChartHeader";
import { useChartsProvider } from "@coachbar/shared-components/src/screens/dashboard/chartContext";
import {
  calculateDaysBetweenDates,
  calculatePercentageDifference,
  compareInnerAndGeneralFilters,
  formatYAxisTicks,
  getAppliedFilters,
  getPreviousDateRange,
  getSpecificInnerFilters,
} from "@coachbar/shared-components/src/services/dashboardUtility/utility";
import {
  deepCopyObject,
  getCurrency,
} from "@coachbar/shared-components/src/services/utility";
import React, { useEffect, useMemo, useState } from "react";
import { getRevenueData } from "../../../dashboardActions";
import OpenDealRevenueTrend from "./OpenDealRevenueTrend";
import WonDealRevenueTrend from "./WonDealRevenueTrend";

const Revenue = ({ chartData }) => {
  const { selectedDate, appliedFilters, fixFilters } = useChartsProvider();

  let currencySymbol = getCurrency().symbol;
  const [chartType, setChartType] = useState("");
  const [isCompared, setIsCompared] = useState(false);
  const [revenueData, setRevenueData] = useState({});
  const [revenueDataLoading, setRevenueDataLoading] = useState(false);
  const [innerFilters, setInnerFilters] = useState(
    getSpecificInnerFilters(fixFilters, chartData.filters)
  );

  const [innerDate, setInnerDate] = useState(deepCopyObject({}));

  let innerAppliedFilters = useMemo(() => {
    return getAppliedFilters(innerFilters);
  }, [innerFilters]);

  useEffect(() => {
    setIsCompared(false);
    fetchRevenueData();
  }, [innerDate, innerAppliedFilters, selectedDate, appliedFilters]);

  const fetchRevenueData = async () => {
    setRevenueDataLoading(true);
    let filtersPass = compareInnerAndGeneralFilters(
      appliedFilters,
      innerAppliedFilters,
      Object.keys(fixFilters),
      innerDate,
      selectedDate
    );
    let requestData = { ...filtersPass };
    let { data } = await getRevenueData(requestData);
    let resultData = data.dashboard;

    setRevenueDataLoading(false);
    setRevenueData(resultData);
  };

  let onCompareClick = async () => {
    if (isCompared) {
      let newData = deepCopyObject(revenueData);
      delete newData?.openDealRevenuePercentChange;
      delete newData?.openDealCountPercentChange;
      delete newData?.avgOpenDealRevenuePercentChange;
      delete newData?.wonDealRevenuePercentChange;
      delete newData?.wonDealCountPercentChange;
      delete newData?.avgWonDealRevenuePercentChange;
      setRevenueData(deepCopyObject(newData));
      setIsCompared((prev) => !prev);
    } else {
      setRevenueDataLoading(true);
      setIsCompared((prev) => !prev);
      let filtersPass = compareInnerAndGeneralFilters(
        appliedFilters,
        innerAppliedFilters,
        Object.keys(fixFilters),
        innerDate,
        selectedDate
      );
      let requestData = { ...filtersPass };
      let conDate = deepCopyObject(
        innerDate?.to && innerDate?.from ? innerDate : selectedDate
      );
      let prevDateRange = getPreviousDateRange(conDate);
      requestData.fromDate = prevDateRange.from;
      requestData.toDate = prevDateRange.to;
      let compareData = await getRevenueData(requestData);
      setRevenueDataLoading(false);
      let newData = deepCopyObject(revenueData);

      newData.openDealRevenuePercentChange = calculatePercentageDifference(
        compareData?.data.dashboard?.openDealRevenue,
        newData?.openDealRevenue
      );
      newData.openDealCountPercentChange = calculatePercentageDifference(
        compareData?.data.dashboard?.openDealCount,
        newData?.openDealCount
      );
      newData.avgOpenDealRevenuePercentChange = calculatePercentageDifference(
        compareData?.data.dashboard?.avgOpenDealRevenue,
        newData?.avgOpenDealRevenue
      );
      newData.wonDealRevenuePercentChange = calculatePercentageDifference(
        compareData?.data.dashboard?.wonDealRevenue,
        newData?.wonDealRevenue
      );
      newData.wonDealCountPercentChange = calculatePercentageDifference(
        compareData?.data.dashboard?.wonDealCount,
        newData?.wonDealCount
      );
      newData.avgWonDealRevenuePercentChange = calculatePercentageDifference(
        compareData?.data.dashboard?.avgWonDealRevenue,
        newData?.avgWonDealRevenue
      );

      setRevenueData(newData);
    }
  };

  let dayDiff = useMemo(() => {
    let conDate = deepCopyObject(
      innerDate?.to && innerDate?.from ? innerDate : selectedDate
    );
    return calculateDaysBetweenDates(conDate);
  }, [innerDate, selectedDate]);
  return (
    <Box>
      <ChartContainer>
        <ChartHeader
          title={chartData?.title}
          showAll
          chartData={chartData}
          chartType={chartType}
          onChartTypeChange={() => {
            if (chartType === "") {
              setChartType("table");
            } else {
              setChartType("");
            }
          }}
          filters={innerFilters}
          onSubmitFilters={(newFilters, date) => {
            setInnerFilters(newFilters);
            setInnerDate(date);
          }}
          date={innerDate}
          generalDate={selectedDate}
          onToogleCompare={onCompareClick}
          isCompared={isCompared}
        />
        <SimpleGrid
          className="top-tiles"
          columns={6}
          spacing={"20px"}
          mb="20px"
        >
          <ValuesChangeCard
            title={"Avg. Won Deal Revenue"}
            vsText={`prev ${dayDiff} days`}
            value={
              revenueData.hasOwnProperty("avgWonDealRevenue")
                ? `${currencySymbol}${formatYAxisTicks(
                    revenueData.avgWonDealRevenue
                  )}`
                : ""
            }
            changePercent={revenueData.avgWonDealRevenuePercentChange}
            showCompare={revenueData?.hasOwnProperty(
              "avgWonDealRevenuePercentChange"
            )}
            loading={revenueDataLoading}
          />
          <ValuesChangeCard
            title={"Open Deals Count"}
            vsText={`prev ${dayDiff} days`}
            value={
              revenueData.hasOwnProperty("openDealCount")
                ? formatYAxisTicks(revenueData.openDealCount)
                : ""
            }
            changePercent={revenueData.openDealCountPercentChange}
            showCompare={revenueData?.hasOwnProperty(
              "openDealCountPercentChange"
            )}
            loading={revenueDataLoading}
          />
          <ValuesChangeCard
            title={"Open Deals Revenue"}
            vsText={`prev ${dayDiff} days`}
            value={
              revenueData.hasOwnProperty("openDealRevenue")
                ? `${currencySymbol}${formatYAxisTicks(
                    revenueData.openDealRevenue
                  )}`
                : ""
            }
            changePercent={revenueData.openDealRevenuePercentChange}
            showCompare={revenueData?.hasOwnProperty(
              "openDealRevenuePercentChange"
            )}
            loading={revenueDataLoading}
          />

          <ValuesChangeCard
            title={"Won Deals Count"}
            vsText={`prev ${dayDiff} days`}
            value={
              revenueData.hasOwnProperty("wonDealCount")
                ? formatYAxisTicks(revenueData.wonDealCount)
                : ""
            }
            changePercent={revenueData.wonDealCountPercentChange}
            showCompare={revenueData?.hasOwnProperty(
              "wonDealCountPercentChange"
            )}
            loading={revenueDataLoading}
          />

          <ValuesChangeCard
            title={"Won Deals Revenue"}
            vsText={`prev ${dayDiff} days`}
            value={
              revenueData.hasOwnProperty("wonDealRevenue")
                ? `${currencySymbol}${formatYAxisTicks(
                    revenueData.wonDealRevenue
                  )}`
                : ""
            }
            changePercent={revenueData.wonDealRevenuePercentChange}
            showCompare={revenueData?.hasOwnProperty(
              "wonDealRevenuePercentChange"
            )}
            loading={revenueDataLoading}
          />
          <ValuesChangeCard
            title={"Avg. Open Deal Revenue"}
            vsText={`prev ${dayDiff} days`}
            value={
              revenueData.hasOwnProperty("avgOpenDealRevenue")
                ? `${currencySymbol}${formatYAxisTicks(
                    revenueData.avgOpenDealRevenue
                  )}`
                : ""
            }
            changePercent={revenueData.avgOpenDealRevenuePercentChange}
            showCompare={revenueData?.hasOwnProperty(
              "avgOpenDealRevenuePercentChange"
            )}
            loading={revenueDataLoading}
          />
        </SimpleGrid>
        <SimpleGrid columns={2} spacing={"20px"}>
          <OpenDealRevenueTrend
            appliedFilters={appliedFilters}
            selectedDate={selectedDate}
            innerAppliedFilters={innerAppliedFilters}
            innerDate={innerDate}
            fixFilters={fixFilters}
            chartType={chartType}
            isCompared={isCompared}
          />
          <WonDealRevenueTrend
            appliedFilters={appliedFilters}
            selectedDate={selectedDate}
            innerAppliedFilters={innerAppliedFilters}
            innerDate={innerDate}
            fixFilters={fixFilters}
            chartType={chartType}
            isCompared={isCompared}
          />
        </SimpleGrid>
      </ChartContainer>
    </Box>
  );
};

export default Revenue;
