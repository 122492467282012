import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Center, Flex, Text } from "@chakra-ui/react";
import Sidebar from "@coachbar/shared-components/src/screens/customForm/Sidebar";
import {
  bulkUpdateAddedProperty,
  updateActiveSubMenu,
  updateExistingData,
  updateFormProperties,
  updateFullInputList,
  updateInputList,
} from "./CustomFormSlice";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import { DndProvider } from "react-dnd-multi-backend";
import CustomizableForm from "@coachbar/shared-components/src/screens/customForm/CustomizableForm";
import { Button, Loader } from "@coachbar/shared-components";
import { initialLeadFormState } from "@coachbar/shared-components/src/constants/customForm";
import { getCustomForm, saveCustomForm } from "./CustomFormActions";
import { buttonVariants } from "@coachbar/shared-components/src/constants/constantValues";

const CustomForm = () => {
  const { formProperties, activeSubMenu, inputList, existingData } =
    useSelector((state) => state.customForm);
  const dispatch = useDispatch();

  // You can check the initialLeadFormState constant(located in shared constants) for the reference of required field Id's
  const mandatoryFieldIds = [1, 2, 3, 4, 5];
  const [isLoading, setIsLoading] = useState(true);
  const [saveLoader, setSaveLoader] = useState(false);
  const [formPropertyList, setFormPropertyList] = useState([]);
  const [customFormPropertyList, setCustomFormPropertyList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  let initialized = false;

  useEffect(() => {
    if (!initialized) {
      fetchCustomFormData();
      initialized = true;
    }

    return () => {
      dispatch(updateActiveSubMenu(undefined));
    };
  }, []);

  const fetchCustomFormData = async () => {
    try {
      const fetchResponse = await getCustomForm();
      if (fetchResponse?.data?.customForm?.id) {
        const { formMapping, ...rest } = fetchResponse?.data?.customForm;
        const updatedFormMapping = [...formMapping];

        initialLeadFormState.forEach((field) => {
          const existsInFormMapping = updatedFormMapping.some(
            (item) => item.id === field.id
          );
          if (!existsInFormMapping) {
            updatedFormMapping.unshift(field);
          }

          // Check if any field with added set to false exists in formMapping
          const notAddedInFormMapping = updatedFormMapping.some(
            (item) => !item.added
          );

          // If such a field exists, replace it with the corresponding field from initialLeadFormState
          if (notAddedInFormMapping) {
            updatedFormMapping.forEach((item, index) => {
              if (!item.added) {
                updatedFormMapping[index] =
                  initialLeadFormState.find((f) => f.id === item.id) || item;
              }
            });
          }
        });

        dispatch(updateExistingData({ ...rest }));
        dispatch(updateFullInputList(updatedFormMapping));

        const addedUpdates = updatedFormMapping.map((property) => ({
          id: property.id,
          added: property.added,
        }));
        dispatch(bulkUpdateAddedProperty(addedUpdates));
      } else {
        dispatch(updateFullInputList(initialLeadFormState));
        dispatch(updateFormProperties(initialLeadFormState));
      }
    } catch (error) {
      console.log("Error while Fetching Custom Form Data", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setFormPropertyList(formProperties);
  }, [formProperties]);

  useEffect(() => {
    setCustomFormPropertyList(inputList);
  }, [inputList]);

  useEffect(() => {
    let filteredFormPropertyList;
    if (searchTerm.trim() === "") {
      filteredFormPropertyList = formProperties;
    } else {
      filteredFormPropertyList = formProperties.filter((property) =>
        property.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    setFormPropertyList(filteredFormPropertyList);
  }, [searchTerm, formProperties]);

  const handleSaveCustomForm = async () => {
    setSaveLoader(true);
    try {
      const requestData = existingData
        ? { ...existingData, formMapping: inputList, formType: "Lead" }
        : { formMapping: inputList, formType: "Lead" };

      const saveResponse = await saveCustomForm(requestData);
      if (saveResponse?.data?.customForm?.id) {
        const { formMapping, ...rest } = saveResponse?.data?.customForm;
        dispatch(updateExistingData({ ...rest }));
      }
      dispatch(
        updateFullInputList(saveResponse?.data?.customForm?.formMapping)
      );
    } catch (error) {
      console.log("Error while saving Custom Form", error);
    } finally {
      setSaveLoader(false);
    }
  };

  const handleFormSearch = (event) => {
    const value = event?.target?.value || "";
    setSearchTerm(value);
  };

  const addFormProperty = (id) => {
    const filteredInputList = inputList?.filter((list) => list.added);
    const dropIndex = filteredInputList.length;
    const draggedIndex = inputList?.findIndex((property) => property.id === id);
    let newFormPropertyList = [...inputList];
    // Find the full details of the dragged item from formPropertyList
    const draggedItemDetails = inputList.find((property) => property.id === id);

    if (draggedIndex === dropIndex) {
      newFormPropertyList[draggedIndex] = {
        ...newFormPropertyList[draggedIndex],
        added: true,
      };
    } else {
      // Remove the original instance of the dragged item if it's different from the drop target
      if (dropIndex !== draggedIndex) {
        newFormPropertyList.splice(draggedIndex, 1);
      }
      // Insert the dragged item at the drop target's position
      newFormPropertyList.splice(dropIndex, 0, {
        ...draggedItemDetails,
        added: true,
      });
    }

    const addedUpdates = newFormPropertyList.map((property) => ({
      id: property.id,
      added: property.added,
    }));

    // dispatch(updateIsChanged(true));
    dispatch(updateFullInputList(newFormPropertyList));
    dispatch(bulkUpdateAddedProperty(addedUpdates));
    // dispatch(updateActiveSubMenu(draggedItemDetails.id));
  };

  const handleDrop = useCallback(
    (dropZoneData, draggedItemData) => {
      const dropIndex = dropZoneData.index;
      let draggedIndex = draggedItemData.index;
      if (draggedItemData.dragFrom === "sidebar") {
        draggedIndex = customFormPropertyList.findIndex(
          (property) => property.id === draggedItemData.id
        );
      }
      let newFormPropertyList = [...customFormPropertyList];
      const draggedItemDetails = customFormPropertyList.find(
        (property) => property.id === draggedItemData.id
      );

      // Remove the original instance of the dragged item if it's different from the drop target
      if (dropIndex !== draggedIndex) {
        newFormPropertyList.splice(draggedIndex, 1);
        // Insert the dragged item at the drop target's position
        // Set 'added' to true if the item is dragged from the sidebar
        newFormPropertyList.splice(dropIndex, 0, {
          ...draggedItemDetails,
          added: draggedItemData.dragFrom === "sidebar" || true,
        });
      } else {
        newFormPropertyList[draggedIndex] = {
          ...newFormPropertyList[draggedIndex],
          added: true,
        };
      }

      setCustomFormPropertyList(newFormPropertyList);

      const addedUpdates = newFormPropertyList.map((property) => ({
        id: property.id,
        added: property.added,
      }));

      //   dispatch(updateIsChanged(true));
      dispatch(updateFullInputList(newFormPropertyList));
      dispatch(bulkUpdateAddedProperty(addedUpdates));
    },
    [customFormPropertyList, inputList, dispatch]
  );

  const handleDeleteInput = (id) => {
    let newFormPropertyList = [...inputList];
    const draggedIndex = newFormPropertyList?.findIndex(
      (property) => property.id === id
    );
    const draggedItemDetails = formProperties.find(
      (property) => property.id === id
    );

    if (draggedIndex !== -1) {
      newFormPropertyList.splice(draggedIndex, 1);
    }

    newFormPropertyList.splice(newFormPropertyList.length, 0, {
      ...draggedItemDetails,
      added: false,
      isRequired: false,
    });

    const addedUpdates = newFormPropertyList.map((property) => ({
      id: property.id,
      added: property.added,
    }));

    // dispatch(updateIsChanged(true));
    dispatch(updateFullInputList(newFormPropertyList));
    dispatch(bulkUpdateAddedProperty(addedUpdates));
    if (activeSubMenu === id) {
      dispatch(updateActiveSubMenu(undefined));
    }
  };

  const handleEditInput = (id) => {
    dispatch(updateActiveSubMenu(id));
  };

  return (
    <Box w={"full"}>
      <Flex w={"full"}>
        {isLoading ? (
          <Center h={"calc(100vh - 180px)"} w={"inherit"}>
            <Loader />
          </Center>
        ) : (
          <>
            <DndProvider
              options={{
                backends: HTML5toTouch.backends.map((backend) => ({
                  ...backend,
                  preview: true,
                })),
              }}
            >
              <Sidebar
                formPropertyList={formPropertyList}
                addFormProperty={addFormProperty}
                formProperties={formProperties}
                handleFormSearch={handleFormSearch}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                mandatoryProperties={mandatoryFieldIds}
                dispatch={dispatch}
                updateActiveSubMenu={updateActiveSubMenu}
                updateInputList={updateInputList}
                activeSubMenu={activeSubMenu}
                inputList={inputList}
                type={"customForm"}
                dragFrom={"sidebar"}
              />
              <Flex
                px={"80px"}
                py={"60px"}
                w={"inherit"}
                alignItems={"center"}
                direction={"column"}
              >
                <CustomizableForm
                  customFormPropertyList={customFormPropertyList}
                  handleDrop={handleDrop}
                  handleDeleteInput={handleDeleteInput}
                  handleEditInput={handleEditInput}
                  mandatoryProperties={mandatoryFieldIds}
                  type={"customForm"}
                />
                <Flex justifyContent={"end"} gap={2} w={"675px"} pt={"24px"}>
                  <Text
                    onClick={() => {
                      dispatch(updateFullInputList(initialLeadFormState));
                      dispatch(updateFormProperties(initialLeadFormState));
                    }}
                    color={"#5E6977"}
                    p={"6px 16px"}
                    fontSize={"14px"}
                    fontWeight={500}
                    cursor={"pointer"}
                  >
                    Reset
                  </Text>
                  <Button
                    title="Save"
                    variant={buttonVariants.brandPrimary}
                    onClick={handleSaveCustomForm}
                    isLoading={saveLoader}
                    id="general-setting-custom-form-save"
                  />
                </Flex>
              </Flex>
            </DndProvider>
          </>
        )}
      </Flex>
    </Box>
  );
};

export default CustomForm;
