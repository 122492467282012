// UserProfile.js
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { updateUserProfile } from "./accountActions";
import { Button, TextBox } from "@coachbar/shared-components";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Heading,
  Stack,
  SimpleGrid,
  HStack,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { requiredField } from "../../constants/validationMessages";
import Notification from "../../components/Notification";
import { setUserData } from "../auth/authSlice";
import { setUserInfo, getUserInfo } from "../../services/utility";

const UserProfile = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const [apiState, setAPiState] = useState({
    error: null,
    success: null,
  });
  const { error, success } = apiState;

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm({ mode: "onChange" });
  const dispatch = useDispatch();

  const submitForm = (data) => {
    if (data) {
      let requestData = { ...data };
      setLoading(true);
      updateUserProfile(requestData).then((responseData) => {
        setLoading(false);
        if (responseData.code && responseData.code === 200) {
          let successMessage = {
            success: responseData.message,
            error: null,
          };
          setAPiState((prevState) => ({
            ...prevState,
            ...successMessage,
          }));
          let userDetails = getUserInfo();
          let updatedUserDetails = { ...userDetails, ...requestData };
          setUserInfo(updatedUserDetails);
          dispatch(setUserData());
        } else {
          let errorMessage = { success: null, error: responseData };
          setAPiState((prevState) => ({
            ...prevState,
            ...errorMessage,
          }));
        }
      });
    }
  };

  useEffect(() => {
    if (!isDirty) {
      setValue("firstName", userInfo["firstName"]);
      setValue("lastName", userInfo["lastName"]);
    }
  }, [userInfo, isDirty, setValue]);

  const clearMessage = () => {
    let message = { error: null, success: null };
    setAPiState((prevState) => ({
      ...prevState,
      ...message,
    }));
  };

  return (
    <Stack spacing="5" align="stretch">
      {error || success ? (
        <Notification
          title={error ? error : success}
          status={error ? "error" : "success"}
          onCloseToast={clearMessage}
        />
      ) : (
        ""
      )}
      <Card variant="form">
        <CardHeader>
          <Heading size="md">Personal Information</Heading>
        </CardHeader>
        <Divider />
        <form onSubmit={handleSubmit(submitForm)} noValidate>
          <CardBody paddingBottom="0px">
            <Stack spacing={4}>
              <HStack spacing={8}>
                <TextBox
                  type="text"
                  placeholder="Name"
                  name="First Name"
                  maxLength="100"
                  required
                  innerref={register("firstName", {
                    required: requiredField.replace("$Field$", "First Name"),
                  })}
                  validationErrors={errors}
                />
                <TextBox
                  type="text"
                  placeholder="https://yourcompany.com"
                  name="Last Name"
                  maxLength="100"
                  required
                  innerref={register("lastName", {
                    required: requiredField.replace("$Field$", "Last Name"),
                  })}
                  validationErrors={errors}
                />
              </HStack>
              <SimpleGrid columns={2} columnGap={8}>
                <FormControl>
                  <FormLabel textTransform={"capitalize"} as="legend">
                    Email
                  </FormLabel>
                  <span style={{ color: "var(--text-font-color)" }}>
                    {userInfo.email}
                  </span>
                </FormControl>
              </SimpleGrid>
            </Stack>
          </CardBody>
          <CardFooter>
            <Button
              type="submit"
              isLoading={loading}
              title="Save"
              id="my-account-profile-update-submit"
            />
          </CardFooter>
        </form>
      </Card>
    </Stack>
  );
};
export default UserProfile;
