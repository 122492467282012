import { axiosInstance } from "../../services/axiosInstance";
import { getErrorMessage } from "../../services/utility";

export const updateUserProfile = async (requestData) => {
  try {
    // configure header's Content-Type as JSON
    const { data } = await axiosInstance.post(`/user`, requestData);
    // store user's token in local storage
    // localStorage.setItem("userToken", data.userToken);
    return data;
  } catch (error) {
    // return custom error message from API if any
    return getErrorMessage(error);
  }
};

export const changePassword = async (requestData) => {
  try {
    // configure header's Content-Type as JSON
    const { data } = await axiosInstance.post(
      `/user/changePassword`,
      requestData
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    return getErrorMessage(error);
  }
};
