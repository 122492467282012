import React, { useEffect, useRef, useState } from "react";
import { useMultiDrag, useMultiDrop } from "react-dnd-multi-backend";
import {
  Box,
  Flex,
  HStack,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import DragIcon from "../../image/dragIcon.png";
import { getEmptyImage } from "../../services/utility";
import { SearchList, Select } from "@coachbar/shared-components";
import fallbackImage from "../../image/fallbackImage.png";
import closeIcon from "../../image/closeBundle.png";
import { noDataText } from "../../constants/icpRequirements";
import CustomTooltip from "@coachbar/shared-components/src/components/Tooltip/CustomTooltip";

const DraggableInput = ({
  id,
  type,
  label,
  placeholder,
  serialNumber,
  index,
  moveInput,
  inputStates = [],
  setInputStates,
  ...props
}) => {
  const optionsKey = `${id}_options`;
  const selectedStateKey = `${id}_selected`;
  const InputType = "FORM_INPUT";
  const ref = useRef(null);
  let inputComponent;

  const optionsList = inputStates[optionsKey] || [];
  const selectedOptions = inputStates[selectedStateKey] || [];

  const handleCheckboxChange = (newItem, updatedOptions) => {
    const isAlreadySelected = inputStates[selectedStateKey].some(
      (item) => item.name === newItem.name
    );
    let updatedSelectedOptions;
    if (isAlreadySelected) {
      updatedSelectedOptions = inputStates[selectedStateKey].filter(
        (item) => item.name !== newItem.name
      );
    } else {
      updatedSelectedOptions = [...inputStates[selectedStateKey], newItem];
    }
    setInputStates((prevState) => ({
      ...prevState,
      [selectedStateKey]: updatedSelectedOptions,
      [optionsKey]: updatedOptions,
    }));
  };

  const removeSelection = (key, value) => {
    setInputStates((prevState) => ({
      ...prevState,
      [selectedStateKey]: prevState[selectedStateKey].filter(
        (item) => item[key] !== value
      ),
    }));

    const updatedOptions = optionsList?.map((item) =>
      item[key] === value ? { ...item, isChecked: false } : item
    );
    setInputStates((prevState) => ({
      ...prevState,
      [optionsKey]: updatedOptions,
    }));
  };

  const onDropDownChange = (event) => {
    setInputStates((prevState) => ({
      ...prevState,
      [selectedStateKey]: event?.target?.value,
    }));
  };

  const popOverContainer = (
    label,
    placeHolder,
    dataList,
    functionToCall,
    disable,
    message = "",
    modalBtnClick,
    isModalBtnDisabled = false,
    required = false,
    showImage = true,
    allowSearch = true
  ) => {
    return (
      <Popover
        computePositionOnMount
        placement="top"
        id={`icp-${
          typeof placeHolder === "string"
            ? placeHolder?.split(" ").join("-").toLowerCase()
            : ""
        }`}
      >
        <PopoverTrigger>
          <button style={{ width: "100%" }}>
            <Box w="100%" textAlign={"left"}>
              <HStack spacing={1}>
                <Text
                  color={"var(--text-font-color)"}
                  fontSize="12px"
                  fontWeight={"500"}
                >
                  {label}
                </Text>
                {required ? (
                  <Text color={"#E53E3E"} fontWeight={"500"}>
                    *
                  </Text>
                ) : (
                  ""
                )}
              </HStack>
              <Box
                h={"40px"}
                w={"100%"}
                border={"1px solid"}
                borderColor={"brandGray.20"}
                borderRadius={"4px"}
                p={"5px 10px"}
                cursor={"pointer"}
                bg={"#FFFFFF"}
              >
                <Flex justifyContent={"space-between"}>
                  <Text color={"brandGray.40"}>{placeHolder}</Text>
                  <ChevronDownIcon h={"1.5em"} w={"1.2rem"} />
                </Flex>
              </Box>
            </Box>
          </button>
        </PopoverTrigger>
        <PopoverContent w={"inherit"}>
          <PopoverBody>
            <SearchList
              itemList={dataList}
              handleCheckBoxChange={functionToCall}
              disable={disable}
              message={message}
              showImage={showImage}
              allowSearch={allowSearch}
              fallbackImage={fallbackImage}
              modalBtnClick={modalBtnClick}
              isModalBtnDisabled={isModalBtnDisabled}
              moduleForId={`icp-setup-${placeHolder}`}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    );
  };

  // Conditionally render input components based on the 'type' prop
  switch (type) {
    case "dropdownWithCheckbox":
      inputComponent = (
        <Box position={"relative"} w={["30%", "50%"]}>
          {popOverContainer(
            "",
            placeholder,
            optionsList,
            handleCheckboxChange,
            false,
            " ",
            false,
            null,
            false,
            false,
            false
          )}
          <Flex display={"flex"} gap={"1rem"} my={2} flexWrap="wrap">
            {selectedOptions &&
              selectedOptions?.map((activeInputOption) => {
                return (
                  <Box
                    position="relative"
                    display="inline-block"
                    key={activeInputOption.name}
                  >
                    <Box
                      bg="#E2E8F0"
                      height="29px"
                      p="3px 10px 0px 10px"
                      borderRadius="6px"
                      flex="1 0 auto"
                    >
                      {activeInputOption.name}
                    </Box>
                    <Image
                      src={closeIcon}
                      w={"1rem"}
                      h={"1rem"}
                      size="sm"
                      variant="ghost"
                      colorScheme="gray"
                      position="absolute"
                      top={"-7px"}
                      right={"-7px"}
                      cursor={"pointer"}
                      onClick={() =>
                        removeSelection("name", activeInputOption.name)
                      }
                    />
                  </Box>
                );
              })}
          </Flex>
        </Box>
      );
      break;
    case "dropdownWithCheckboxAndSearch":
      const showImage = id === "currentApplicationsStack" ? true : false;
      inputComponent = (
        <Box mb={3} position={"relative"} w={["30%", "50%"]}>
          {popOverContainer(
            "",
            placeholder,
            optionsList,
            handleCheckboxChange,
            false,
            noDataText,
            null,
            false,
            false,
            showImage,
            true
          )}
          <Flex
            display={
              selectedOptions && selectedOptions?.length > 0 ? "flex" : "none"
            }
            gap={"1rem"}
            my={2}
            flexWrap="wrap"
          >
            {selectedOptions.map((currentOption) => {
              return (
                <Box
                  position="relative"
                  display="inline-block"
                  key={currentOption.name}
                >
                  <Box
                    bg="#E2E8F0"
                    height="29px"
                    p="3px 10px 0px 10px"
                    borderRadius="6px"
                    flex="1 0 auto"
                    maxWidth="max-content"
                  >
                    {currentOption.name}
                  </Box>
                  <Image
                    src={closeIcon}
                    w={"1rem"}
                    h={"1rem"}
                    size="sm"
                    variant="ghost"
                    colorScheme="gray"
                    position="absolute"
                    top={"-7px"}
                    right={"-7px"}
                    cursor={"pointer"}
                    onClick={() => removeSelection("name", currentOption.name)}
                  />
                </Box>
              );
            })}
          </Flex>
        </Box>
      );
      break;
    case "simpleDropdown":
      inputComponent = (
        <Box w={["30%", "50%"]} py={4}>
          <Select
            value={inputStates[selectedStateKey]}
            placeholder={placeholder}
            hideLabel={true}
            onChange={onDropDownChange}
            rounded={"md"}
            hideHelperText
            border="1px solid #D4DCE3"
            borderRadius="4px"
            mb={[2, 0]}
            options={optionsList}
            name="currency"
          />
        </Box>
      );
      break;
    default:
      inputComponent = null;
  }

  const [[{ isDragging }, dragRef, preview]] = useMultiDrag({
    type: InputType,
    item: { id, index, serialNumber, label },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [[{ isOver, canDrop }, drop]] = useMultiDrop({
    accept: InputType,
    hover: (draggedItem, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = draggedItem.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      const isAboveMiddle = hoverClientY < hoverMiddleY;
      if (
        (dragIndex < hoverIndex && isAboveMiddle) ||
        (dragIndex > hoverIndex && !isAboveMiddle)
      ) {
        return;
      }
      moveInput(dragIndex, hoverIndex);
      draggedItem.index = hoverIndex;
    },
  });

  useEffect(() => {
    preview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }, [preview]);

  const opacity = isDragging ? 0 : 1;
  const verticalPadding = isDragging ? 3 : 1;
  return (
    <Box
      display={"flex"}
      ref={drop}
      id="Draggable-Input"
      opacity={opacity}
      px={4}
      py={verticalPadding}
      borderBottom="1px solid #D4DCE3"
      rounded={"sm"}
      justifyContent={"space-between"}
      alignItems={"center"}
    >
      <Flex justifyContent={"space-between"} alignItems={"center"} w={"full"}>
        <Box display={"flex"} alignItems={"center"} gap={2}>
          <div
            style={{ cursor: "grab" }}
            ref={(node) => {
              ref.current = node;
              dragRef(node);
            }}
          >
            <Image src={DragIcon} h={4} mr={1} />
          </div>
          <Text>{serialNumber}.</Text>
          <Text
            color={"#111A29"}
            fontSize={"14px"}
            lineHeight={"20px"}
            fontWeight={700}
          >
            {label}
            {props.tooltip && (
              <>
                &nbsp; <CustomTooltip content={props.tooltip} />
              </>
            )}
          </Text>
        </Box>
        {inputComponent}
      </Flex>
    </Box>
  );
};

export default DraggableInput;
