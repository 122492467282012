import { CustomIcons, PageNotFound } from "@coachbar/shared-components";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { lazy, Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import ForgotPassword from "../features/auth/ForgotPassword";
import Login from "../features/auth/Login";
import Register from "../features/auth/Register";
import CoachbarServices from "../features/coachbarServices/CoachbarServices";
import HubSpot from "../features/integration/hubSpot/hubSpot";
import MyAccount from "../features/myAccount/MyAccount";
import ProfileSetup from "../features/profileSetup/ProfileSetup";
import ImportReferral from "../features/referrals/ImportReferral";
import NewReferral from "../features/referrals/NewReferral";
import ReferralDetails from "../features/referrals/ReferralDetails";
import Subscription from "../features/subscription/Subscription";
// import Roadmap from "../features/roadmap/Roadmap";
import {
  clientsModule,
  coachbarServicesModule,
  companyProfile,
  contractsModule,
  dealsModule,
  generalSetting,
  icpModule,
  integration,
  invitationsModule,
  leadsModule,
  lmsModule,
  providers,
  referral,
  rewardsModule,
  services,
  specializationModule,
  subscriptionModule,
  subUser,
  systemGrid,
  transactionHistory,
} from "../constants/moduleNames";
import AcceptInvitation from "../features/auth/AcceptInvitation";
import GeneralNotifications from "../features/generalNotifications/GeneralNotifications";
import CustomForm from "../features/generalSettings/customForms/CustomForm";
import GeneralSettings from "../features/generalSettings/GeneralSettings";
import IcpList from "../features/icpSetup/IcpList";
import NewIcp from "../features/icpSetup/NewIcp";
import Assets from "../features/lms/assets/Assets";
import LearningCenter from "../features/lms/learningCenter/LearningCenter";
import Invitations from "../features/myProviders/Invitations/Invitations";
import ProvidersList from "../features/myProviders/providersList/ProvidersList";
import PersonalNotifications from "../features/personalNotifications/PersonalNotifications";
import ClaimProfile from "../features/profileSetup/companyProfile/claimPfofile";
import ProfileMetrics from "../features/profileSetup/profileMetrics/ProfileMetrics";
import SoftwareConfiguration from "../features/softwareSpecialisation/SoftwareConfiguration";
import Plans from "../features/subscription/Plans";
import Performance from "../features/dashboard/performance/Performance";
import Financial from "../features/dashboard/financial/Financial";
import Insights from "../features/dashboard/insights/Insights";
import RewardDetails from "../features/rewards/rewardDetails";
import LeadDealStatus from "@coachbar/shared-components/src/screens/LeadDealStatus";
import ResetPassword from "../features/auth/ResetPassword";
const queryClient = new QueryClient();
const Dashboard = lazy(() => import("../features/dashboard/Dashboard"));
const TransactionHistory = lazy(() =>
  import("../features/transactionHistory/TransactionHistory")
);
const ClientsInformation = lazy(() =>
  import("../features/clientsInformation/ClientsInformation")
);
const ServiceOffering = lazy(() =>
  import("../features/serviceOffering/ServiceOfferingTabs")
);
const SoftwareSpecialisation = lazy(() =>
  import("../features/softwareSpecialisation/SpecializationTabs")
);
const UsersAndRoles = lazy(() =>
  import("../features/settings/usersAndRoles/UsersAndRoles")
);
const Leads = lazy(() => import("../features/leads/Leads"));
const Deals = lazy(() => import("../features/deals/Deals"));
const ReferralList = lazy(() => import("../features/referrals/ReferralsList"));
const PreviewProfile = lazy(() =>
  import("../features/profileSetup/PreviewProfile")
);
const Contracts = lazy(() => import("../features/contracts/Contracts"));
const Rewards = lazy(() => import("../features/rewards/rewards"));
const ProviderCard = lazy(() =>
  import("../features/providerCard/ProviderCard")
);

export const authRoutes = [
  {
    path: "/",
    name: "Login",
    component: <Login />,
  },
  {
    path: "/login",
    name: "Login",
    component: <Login />,
  },
  {
    path: "/register",
    name: "Register",
    component: <Register />,
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: <ResetPassword />,
  },
  {
    path: "/lead-status/:id",
    name: "Lead Status",
    component: <LeadDealStatus portal={"sc"} type={"lead"} />,
  },
  {
    path: "/deal-status/:id",
    name: "Deal Status",
    component: <LeadDealStatus portal={"sc"} type={"deal"} />,
  },
  {
    path: "/preview-profile/:slug",
    name: "Profile",
    component: (
      <Suspense fallback={<></>}>
        <PreviewProfile />
      </Suspense>
    ),
  },
  {
    path: "/accept-invitation",
    name: "Accept Invitation",
    component: <AcceptInvitation />,
  },
];

const subComponents = [
  {
    path: "/profile-setup/company-profile",
    name: "Company Profile",
    component: <ProfileSetup />,
    isProtected: false,
  },
  {
    path: "/profile-setup/specialisation",
    name: "Specialization",
    moduleName: specializationModule,
    component: (
      <Suspense fallback={<></>}>
        <SoftwareSpecialisation />
      </Suspense>
    ),
    isProtected: false,
  },
  {
    path: "/profile-setup/system-grid",
    name: "System Grid",
    moduleName: systemGrid,
    component: (
      <Suspense fallback={<></>}>
        <SoftwareConfiguration />
      </Suspense>
    ),
    isProtected: false,
  },
  {
    path: "/profile-setup/service-offering",
    name: "Services",
    moduleName: services,
    component: (
      <Suspense fallback={<></>}>
        <ServiceOffering />
      </Suspense>
    ),
    isProtected: false,
  },
  {
    path: "/profile-setup/client-info",
    name: "Clients",
    moduleName: clientsModule,
    component: (
      <Suspense fallback={<></>}>
        <ClientsInformation />
      </Suspense>
    ),
    isProtected: false,
  },
  {
    path: "/profile-setup/icp",
    name: "ICP",
    moduleName: icpModule,
    component: (
      <Suspense fallback={<></>}>
        <IcpList />
      </Suspense>
    ),
    isProtected: false,
  },
];

const subscriptionComponent = [
  {
    path: "/my-subscription/subscription",
    name: "Subscription",
    moduleName: subscriptionModule,
    component: <Subscription />,
    isProtected: false,
  },
  {
    path: "/my-subscription/transaction-history",
    name: "Transaction history",
    moduleName: transactionHistory,
    component: (
      <Suspense fallback={<></>}>
        <TransactionHistory />
      </Suspense>
    ),
    isProtected: false,
  },
];

const lmsSubRoutes = [
  {
    path: "/lms/assets",
    name: "Assets",
    component: (
      <Suspense fallback={<></>}>
        <QueryClientProvider client={queryClient}>
          <Assets />
        </QueryClientProvider>
      </Suspense>
    ),
    isProtected: false,
  },
  {
    path: "/lms/learning-center",
    name: "Learning Center",
    moduleName: lmsModule,
    component: <LearningCenter />,
  },
];

const settingComponent = [
  {
    path: "/settings/users-and-roles",
    name: "Users And Roles",
    moduleName: subUser,
    component: (
      <Suspense fallback={<></>}>
        <QueryClientProvider client={queryClient}>
          <UsersAndRoles />
        </QueryClientProvider>
      </Suspense>
    ),
  },
  {
    path: "/settings/custom-forms",
    name: "Custom Forms",
    moduleName: generalSetting,
    component: (
      <Suspense fallback={<></>}>
        <CustomForm />
      </Suspense>
    ),
    isProtected: false,
  },
  {
    path: "/settings/general",
    name: "General",
    moduleName: generalSetting,
    component: <GeneralSettings />,
    isProtected: false,
  },
  {
    path: "/settings/notifications",
    name: "Notifications",
    moduleName: generalSetting,
    component: (
      <Suspense fallback={<></>}>
        <GeneralNotifications />
      </Suspense>
    ),
    isProtected: false,
  },
  {
    path: "/settings/integration",
    name: "Integration",
    moduleName: integration,
    component: <HubSpot />,
    isProtected: false,
  },
];

const myProvidersComponents = [
  {
    path: "/my-providers/invitations",
    name: "Invitations",
    moduleName: invitationsModule,
    component: <Invitations />,
  },
];

const incentivesComponents = [
  {
    path: "/incentives/rewards",
    name: "Rewards",
    moduleName: rewardsModule,
    component: (
      <Suspense fallback={<></>}>
        <QueryClientProvider client={queryClient}>
          <Rewards />
        </QueryClientProvider>
      </Suspense>
    ),
  },
  {
    path: "/incentives/contracts",
    name: "Contracts",
    moduleName: contractsModule,
    component: (
      <Suspense fallback={<></>}>
        <QueryClientProvider client={queryClient}>
          <Contracts />
        </QueryClientProvider>
      </Suspense>
    ),
  },
];

const dashboardComponents = [
  {
    path: "/dashboard/performance",
    name: "Performance",
    isProtected: false,
    component: (
      <Suspense fallback={<></>}>
        <Performance />
      </Suspense>
    ),
  },
  // {
  //   path: "/dashboard/insights",
  //   name: "Insights",
  //   isProtected: false,
  //   component: (
  //     <Suspense fallback={<></>}>
  //       <Insights />
  //     </Suspense>
  //   ),
  // },
  {
    path: "/dashboard/financial",
    name: "Financial",
    isProtected: false,
    component: (
      <Suspense fallback={<></>}>
        <Financial />
      </Suspense>
    ),
  },
];

export const protectedRoutes = [
  {
    path: "/profile-setup/company-profile",
    name: "Company Profile",
    moduleName: companyProfile,
    component: <ProfileSetup />,
  },
  {
    path: "/my-subscription/subscription",
    name: "Subscription",
    moduleName: subscriptionModule,
    component: <Subscription />,
  },
  {
    path: "/my-subscription/transaction-history",
    name: "Transaction history",
    moduleName: transactionHistory,
    component: (
      <Suspense fallback={<></>}>
        <TransactionHistory />
      </Suspense>
    ),
  },
  {
    path: "/profile-setup/icp",
    name: "ICP",
    moduleName: icpModule,
    component: <IcpList />,
  },
  {
    path: "/settings/general",
    name: "General",
    moduleName: generalSetting,
    component: <GeneralSettings />,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: CustomIcons.DashboardIcon,
    menuItem: true,
    moduleName: companyProfile,
    subMenuItem: dashboardComponents,
    component: (
      <Suspense fallback={<></>}>
        <Dashboard />
      </Suspense>
    ),
  },
  {
    path: "/dashboard/performance",
    name: "Performance",
    // moduleName: "proposals",
    component: (
      <Suspense fallback={<></>}>
        <Performance />
      </Suspense>
    ),
  },
  {
    path: "/dashboard/insights",
    name: "Insights",
    // moduleName: "proposals",
    component: (
      <Suspense fallback={<></>}>
        <Insights />
      </Suspense>
    ),
  },
  {
    path: "/dashboard/financial",
    name: "Financial",
    // moduleName: "proposals",
    component: (
      <Suspense fallback={<></>}>
        <Financial />
      </Suspense>
    ),
  },
  {
    path: "/profile-setup",
    name: "Profile Setup",
    icon: CustomIcons.ProfileIcon,
    parentMenu: true,
    parentPath: "/profile-setup",
    // menuItem: true,
    // subMenuItem: subComponents,
    moduleName: companyProfile,
    component: <ProfileMetrics />,
  },
  {
    path: "/profile-setup",
    name: "Profile Setup",
    icon: CustomIcons.ProfileIcon,
    menuItem: true,
    subMenuItem: subComponents,
    moduleName: companyProfile,
    component: <ProfileMetrics />,
  },
  {
    path: "/profile-setup/system-grid",
    name: "System Grid",
    moduleName: systemGrid,
    component: <SoftwareConfiguration />,
  },
  {
    path: "/referrals",
    name: "Referrals",
    icon: CustomIcons.SubscriptionIcon,
    moduleName: referral,
    component: (
      <Suspense fallback={<></>}>
        <QueryClientProvider client={queryClient}>
          <ReferralList />
        </QueryClientProvider>
      </Suspense>
    ),
  },
  {
    path: "/referrals",
    name: "Referrals",
    icon: CustomIcons.ReferralIcon,
    menuItem: true,
    moduleName: referral,
    component: (
      <Suspense fallback={<></>}>
        <QueryClientProvider client={queryClient}>
          <ReferralList />
        </QueryClientProvider>
      </Suspense>
    ),
  },
  {
    path: "/referrals/details/:referralId",
    name: "Referral Details",
    moduleName: referral,
    component: <ReferralDetails />,
  },
  {
    path: "/referrals/add",
    name: "Add New",
    moduleName: referral,
    component: <NewReferral />,
  },
  {
    path: "/referrals/import",
    name: "Import",
    moduleName: referral,
    component: <ImportReferral />,
  },
  {
    path: "/profile-setup/icp/add",
    name: "Add Icp",
    moduleName: icpModule,
    component: <NewIcp />,
  },
  {
    path: "/profile-setup/icp/edit/:icpId",
    name: "Edit Icp",
    moduleName: icpModule,
    component: <NewIcp />,
  },
  {
    path: "/incentives/rewards/:rewardId",
    name: "Details",
    moduleName: rewardsModule,
    component: (
      <QueryClientProvider client={queryClient}>
        <RewardDetails />
      </QueryClientProvider>
    ),
  },
  {
    path: "/my-subscription",
    name: "My Subscription",
    icon: CustomIcons.SubscriptionIcon,
    parentMenu: true,
    parentPath: "/my-subscription/subscription",
    moduleName: subscriptionModule,
    component: <Subscription />,
  },
  {
    path: "/my-subscription/subscription",
    name: "My Subscription",
    icon: CustomIcons.SubscriptionIcon,
    menuItem: true,
    subMenuItem: subscriptionComponent,
    moduleName: subscriptionModule,
    component: <Subscription />,
  },
  {
    path: "/directory-listing",
    name: "Directory",
    icon: CustomIcons.DirectoryIcon,
    menuItem: true,
    directLink: true,
    moduleName: companyProfile,
    component: null,
  },
  {
    path: "/coachbar-services",
    name: "Coachbar Services",
    icon: CustomIcons.CBServicesIcon,
    menuItem: true,
    moduleName: coachbarServicesModule,
    component: <CoachbarServices />,
  },
  {
    path: "/my-providers",
    name: "My Providers",
    icon: CustomIcons.ProviderIcon,
    parentMenu: true,
    parentPath: "/my-providers",
    moduleName: providers,
    component: (
      <Suspense fallback={<></>}>
        <QueryClientProvider client={queryClient}>
          <ProvidersList />
        </QueryClientProvider>
      </Suspense>
    ),
  },
  {
    path: "/my-providers/:id",
    name: "Provider Card",
    moduleName: providers,
    component: (
      <Suspense fallback={<></>}>
        <QueryClientProvider client={queryClient}>
          <ProviderCard />
        </QueryClientProvider>
      </Suspense>
    ),
  },
  {
    path: "/lms",
    name: "LMS",
    icon: CustomIcons.LmsIcon,
    moduleName: lmsModule,
    parentMenu: true,
    parentPath: "/lms/assets",
    component: <div>LMS</div>,
  },
  {
    path: "/lms/assets",
    name: "Assets",
    moduleName: lmsModule,
    component: (
      <Suspense fallback={<></>}>
        <QueryClientProvider client={queryClient}>
          <Assets />
        </QueryClientProvider>
      </Suspense>
    ),
  },
  {
    path: "/lms/learning-center",
    name: "Learning Center",
    moduleName: lmsModule,
    component: <LearningCenter />,
  },
  {
    path: "/lms/assets",
    name: "LMS",
    icon: CustomIcons.LmsIcon,
    menuItem: true,
    moduleName: lmsModule,
    subMenuItem: lmsSubRoutes,
    component: <div>LMS</div>,
  },
  {
    path: "/leads",
    name: "Leads",
    icon: CustomIcons.LeadsSideBarIcon,
    menuItem: true,
    moduleName: leadsModule,
    component: (
      <Suspense>
        <QueryClientProvider client={queryClient}>
          <Leads />
        </QueryClientProvider>
      </Suspense>
    ),
  },
  {
    path: "/deals",
    name: "Deals",
    icon: CustomIcons.dealsSideBarIcon,
    menuItem: true,
    moduleName: dealsModule,
    component: (
      <Suspense>
        <QueryClientProvider client={queryClient}>
          <Deals />
        </QueryClientProvider>
      </Suspense>
    ),
  },
  {
    path: "/my-providers",
    name: "My Providers",
    icon: CustomIcons.ProviderIcon,
    menuItem: true,
    subMenuItem: myProvidersComponents,
    moduleName: providers,
    component: (
      <Suspense fallback={<></>}>
        <QueryClientProvider client={queryClient}>
          <ProvidersList />
        </QueryClientProvider>
      </Suspense>
    ),
  },
  {
    path: "/my-providers/invitations",
    name: "Invitations",
    icon: CustomIcons.ProviderIcon,
    moduleName: invitationsModule,
    component: (
      <Suspense>
        <QueryClientProvider client={queryClient}>
          <Invitations />
        </QueryClientProvider>
      </Suspense>
    ),
  },
  // incentives menu and it's submenu
  {
    path: "/incentives",
    name: "Incentives",
    icon: CustomIcons.IncentivesIcon,
    menuItem: true,
    subMenuItem: incentivesComponents,
    moduleName: rewardsModule,
    component: (
      <Suspense fallback={<></>}>
        <QueryClientProvider client={queryClient}>
          <Rewards />
        </QueryClientProvider>
      </Suspense>
    ),
  },
  {
    path: "/incentives/rewards",
    name: "Rewards",
    moduleName: rewardsModule,
    component: (
      <Suspense fallback={<></>}>
        <QueryClientProvider client={queryClient}>
          <Rewards />
        </QueryClientProvider>
      </Suspense>
    ),
  },
  {
    path: "/incentives/contracts",
    name: "Contracts",
    moduleName: contractsModule,
    component: (
      <Suspense fallback={<></>}>
        <QueryClientProvider client={queryClient}>
          <Contracts />
        </QueryClientProvider>
      </Suspense>
    ),
  },
  // incentives menu and it's submenu ends
  {
    path: "/my-subscription/subscription/plans",
    name: "Plans",
    moduleName: subscriptionModule,
    component: <Plans />,
  },
  // {
  //   path: "/roadmap",
  //   name: "Roadmap",
  //   moduleName: companyProfile,
  //   component: <Roadmap />,
  // },
  // below code is added for bread crumb
  {
    path: "/settings/custom-forms",
    name: "Custom Forms",
    moduleName: generalSetting,
    component: (
      <Suspense fallback={<></>}>
        <CustomForm />
      </Suspense>
    ),
  },
  {
    path: "/settings/users-and-roles",
    name: "Users And Roles",
    moduleName: subUser,
    component: (
      <Suspense fallback={<></>}>
        <QueryClientProvider client={queryClient}>
          <UsersAndRoles />
        </QueryClientProvider>
      </Suspense>
    ),
  },
  {
    path: "/settings",
    name: "Settings",
    icon: CustomIcons.SettingsIcon,
    parentMenu: true,
    parentPath: "/settings/users-and-roles",
    moduleName: subUser,
    component: (
      <Suspense fallback={<></>}>
        <QueryClientProvider client={queryClient}>
          <UsersAndRoles />
        </QueryClientProvider>
      </Suspense>
    ),
  },
  {
    path: "/settings/users-and-roles",
    name: "Settings",
    icon: CustomIcons.SettingsIcon,
    menuItem: true,
    subMenuItem: settingComponent,
    moduleName: subUser,
    component: (
      <Suspense fallback={<></>}>
        <QueryClientProvider client={queryClient}>
          <UsersAndRoles />
        </QueryClientProvider>
      </Suspense>
    ),
  },
  {
    path: "/settings/integration",
    name: "Integration",
    moduleName: integration,
    component: <HubSpot />,
  },
  {
    path: "/settings/notifications",
    name: "Notifications",
    moduleName: generalSetting,
    component: (
      <Suspense fallback={<></>}>
        <GeneralNotifications />
      </Suspense>
    ),
    isProtected: false,
  },
  {
    path: "/settings/integration/hubspot",
    name: "hubspot",
    moduleName: integration,
    component: <HubSpot />,
    isProtected: false,
  },
  {
    path: "/claim-profile",
    name: "Claim Profile",
    component: <ClaimProfile />,
  },
  {
    path: "/my-account",
    name: "My Account",
    component: <MyAccount />,
    hideBreadcrumb: true,
  },
  {
    path: "/personal-notifications",
    name: "Personal Notification Settings",
    component: <PersonalNotifications />,
  },
  {
    parentRoute: true,
    name: "Company Profile Protected Route",
    component: <Outlet />,
    subComponents: subComponents.slice(1),
  },
  {
    parentRoute: true,
    name: "Subscription Protected Route",
    component: <Outlet />,
    subComponents: subscriptionComponent.slice(1),
  },
  {
    parentRoute: true,
    name: "Setting Protected Route",
    component: <Outlet />,
    subComponents: settingComponent.slice(1),
  },
  {
    path: "*",
    name: "Page Not Found",
    component: <Navigate to="/404" replace={true} />,
  },
  {
    path: "/404",
    name: "",
    component: <PageNotFound />,
  },
];
