import React from "react";
import { useDrag } from "react-dnd";
import { Image, Text, HStack, Spacer } from "@chakra-ui/react";
import PlusCircleColored from "../../images/plus-circle-colored.svg";
import PlusCircle from "../../images/icon-circle-plus.svg";
import DragIcon from "../../images/dragIcon.png";

const DraggableCard = ({ id, name, added, onAdd, dragFrom, type }) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: type,
      item: { id, dragFrom: dragFrom },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
      canDrag: !added,
    }),
    [added, dragFrom]
  );
  const opacity = isDragging ? 0.4 : 1;

  return (
    <HStack
      ref={drag}
      key={id}
      opacity={opacity}
      px={3}
      py={2}
      mb={3}
      border={"1px solid #DEE8F8"}
      rounded={"4px"}
      _hover={{ border: "1px dashed #0C94AC", bg: "#E7FAFD" }}
      role="group"
    >
      <Image cursor="move" src={DragIcon} h={"14px"} />
      <Text
        color={"var(--title-black-text)"}
        fontSize={"12px"}
        fontWeight={500}
      >
        {name}
      </Text>
      <Spacer />
      {!added && onAdd && (
        <>
          <Image
            _groupHover={{ display: "none" }}
            src={PlusCircle}
            h={"15px"}
            cursor={"pointer"}
          />
          <Image
            display={"none"}
            _groupHover={{ display: "inline-block" }}
            src={PlusCircleColored}
            h={"15px"}
            cursor={"pointer"}
            onClick={() => onAdd(id)}
          />
        </>
      )}
    </HStack>
  );
};

export default DraggableCard;
