import { Box, Center, Flex } from "@chakra-ui/react";
import { Loader } from "@coachbar/shared-components";
import ChartLegends from "@coachbar/shared-components/src/components/Charts/ChartLegends";
import SpeedoMeter from "@coachbar/shared-components/src/components/Charts/SpeedoMeter";
import ChartContainer from "@coachbar/shared-components/src/screens/dashboard/ChartContainer";
import ChartHeader from "@coachbar/shared-components/src/screens/dashboard/ChartHeader";
import NoDataChart from "@coachbar/shared-components/src/screens/dashboard/NoDataChart";
import { useChartsProvider } from "@coachbar/shared-components/src/screens/dashboard/chartContext";
import {
  compareInnerAndGeneralFilters,
  getAppliedFilters,
  getPreviousDateRange,
  getSpecificInnerFilters,
} from "@coachbar/shared-components/src/services/dashboardUtility/utility";
import { deepCopyObject } from "@coachbar/shared-components/src/services/utility";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getPerformanceLeadConversionRate } from "../../dashboardActions";

const leadConversionData = [
  { id: "progress", value: 0, legend: "Lead", color: "#87CCAB" },
  { id: "remaining", value: 0, color: "#E1F2EA" },
];

const LeadConversionRate = ({ slug, chartData }) => {
  const { selectedDate, partnerId, appliedFilters, fixFilters } =
    useChartsProvider();

  let initialized = false;
  const widgetInfo = chartData;
  const [isLoading, setIsLoading] = useState(false);
  const [record, setRecord] = useState(deepCopyObject(leadConversionData));
  const [prevRecord, setPrevRecord] = useState([]);
  const [innerFilters, setInnerFilters] = useState(
    getSpecificInnerFilters(fixFilters, chartData.filters)
  );
  const [innerDate, setInnerDate] = useState(deepCopyObject({}));

  let innerAppliedFilters = useMemo(() => {
    return getAppliedFilters(innerFilters);
  }, [innerFilters]);

  const constructRequestData = ({ date }) => {
    let requestData = compareInnerAndGeneralFilters(
      appliedFilters,
      innerAppliedFilters,
      Object.keys(fixFilters),
      date,
      selectedDate
    );
    if (partnerId) {
      requestData.partners = [partnerId];
    }

    return requestData;
  };

  const onToogleCompare = () => {
    let conDate = deepCopyObject(
      innerDate?.to && innerDate?.from ? innerDate : selectedDate
    );
    const previousDateRange = getPreviousDateRange(conDate);
    const requestData = constructRequestData({ date: previousDateRange });
    fetchChartData(requestData, true);
  };

  const fetchChartData = useCallback(
    async (requestData, isCompared = false) => {
      setIsLoading(true);
      const { data } = await getPerformanceLeadConversionRate(requestData);
      setIsLoading(false);

      if (data) {
        let newData = deepCopyObject(leadConversionData);
        newData[0].value = data?.dashboard;
        newData[1].value = 100 - data?.dashboard;

        if (isCompared) {
          setPrevRecord(newData);
        } else {
          setRecord(deepCopyObject(newData));
        }
      }
    },
    []
  );

  useEffect(() => {
    if (!initialized) {
      initialized = true;
      setPrevRecord([]);
      const requestData = constructRequestData({ date: innerDate });
      fetchChartData(requestData);
    }
  }, [
    fetchChartData,
    innerDate,
    selectedDate,
    appliedFilters,
    innerAppliedFilters,
    partnerId,
  ]);

  return (
    <>
      {widgetInfo?.isVisible && (
        <ChartContainer>
          <ChartHeader
            title={widgetInfo?.title}
            showFilters
            showCompare
            showDelete
            chartData={chartData}
            filters={innerFilters}
            onSubmitFilters={(newFilters, date) => {
              setInnerFilters(newFilters);
              setInnerDate(date);
            }}
            onToogleCompare={() => {
              if (prevRecord?.length > 0) {
                setPrevRecord([]);
              } else {
                onToogleCompare();
              }
            }}
            isCompared={prevRecord?.length > 0 ? true : false}
            date={innerDate}
            generalDate={selectedDate}
          />
          {isLoading ? (
            <Center h={"80%"}>
              <Loader />
            </Center>
          ) : record[0]?.value <= 0 ? (
            <Box h={"175px"}>
              <NoDataChart />
            </Box>
          ) : (
            <Flex h={"280px"} direction={"column"} justifyContent={"center"}>
              <Box h={"150px"}>
                <SpeedoMeter
                  data={record}
                  prevData={prevRecord}
                  totalValue={record[0]?.value}
                />
              </Box>
              <ChartLegends data={record} />
            </Flex>
          )}
        </ChartContainer>
      )}
    </>
  );
};

export default LeadConversionRate;
