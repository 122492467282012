import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../services/axiosInstance";
import { getErrorMessage } from "../../services/utility";

export const getPublishedSystemGrid = createAsyncThunk(
  "get/publishedSystemGrid",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/softwareBundle?status=Published`
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const addIcp = createAsyncThunk(
  "post/icp",
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("/icp/add", requestData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getIcps = createAsyncThunk(
  "get/icps",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("/icp");
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const deleteIcp = createAsyncThunk(
  "delete/icp",
  async (icpId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(`/icp/${icpId}`);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateIcpStatus = createAsyncThunk(
  "update/icpStatus",
  async ({ icpId, requestParams }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(
        `/icp/status/${icpId}`,
        requestParams
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getIcp = createAsyncThunk(
  "get/icp",
  async (icpId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/icp/${icpId}`);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateIcp = createAsyncThunk(
  "update/icp",
  async ({ icpId, requestParams }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(`/icp/${icpId}`, requestParams);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
