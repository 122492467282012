// ReferralDetails.js
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Notification from "../../components/Notification";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  getReferralDetails,
  updateReferral,
  deleteReferral,
} from "./referralActions";
import {
  Card,
  CardHeader,
  CardBody,
  Heading,
  Stack,
  Divider,
  SimpleGrid,
  FormControl,
  FormLabel,
  Box,
  Select,
  useDisclosure,
  Text,
  Tag,
  Flex,
} from "@chakra-ui/react";
import { Button, Loader, AlertDialog } from "@coachbar/shared-components";
import { clearMessage, setError, setSuccess } from "./referralSlice";
import {
  referraltatus,
  defaultReferralSource,
} from "../../constants/referralStatus";
import RedeemService from "../coachbarServices/RedeemService";
import { getPredefinedCredit } from "../roadmap/roadmapActions";
import CustomTooltip from "@coachbar/shared-components/src/components/Tooltip/CustomTooltip";
import { getUserInfo } from "../../services/utility";
import {
  challengesAbTip,
  challengesTip,
  currentStackAbTip,
  currentStackTip,
  deploymentPreTip,
  evaluateSoftwareTip,
  requirementOverviewAbTip,
  requirementOverviewTip,
  serviceRequiredAbTip,
  serviceRequiredTip,
} from "../../constants/tooltips";
import { useRBAC } from "../../services/customHook";
import { coachbarServicesModule, referral } from "../../constants/moduleNames";
import {
  budgetOptionsObj,
  companyRevenueObj,
} from "@coachbar/shared-components/src/constants/constantValues";
import {
  budgetCalculation,
  getCurrency,
  getCurrencyRate,
  replaceCurrencyInLabel,
  revenueCalculate,
} from "@coachbar/shared-components/src/services/utility";

let referralUpdateStatus = referraltatus;

const ReferralDetails = () => {
  const location = useLocation();
  const status = new URLSearchParams(location.search).get("status");
  const { hasActionPermission } = useRBAC();
  let moduleName = referral;
  let permissions = {
    add: hasActionPermission(moduleName, "add"),
    edit: hasActionPermission(moduleName, "edit"),
    view: hasActionPermission(moduleName, "view"),
    delete: hasActionPermission(moduleName, "delete"),
  };

  let csModule = coachbarServicesModule;
  let coachbarServicesPermission = {
    add: hasActionPermission(csModule, "add"),
    edit: hasActionPermission(csModule, "edit"),
    view: hasActionPermission(csModule, "view"),
    delete: hasActionPermission(csModule, "delete"),
  };

  const navigate = useNavigate();
  const { referralDetails, loading, error, success } = useSelector(
    (state) => state.referral
  );
  const { prebookCredit } = useSelector((state) => state.roadmap);
  const [referralStatus, setReferralStatus] = useState("");
  const [formattedNumber, setFormattedNumber] = useState("");
  const dispatch = useDispatch();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    onOpen: onAlertOpen,
    isOpen: isAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();
  const [selectedSevice, setService] = useState({});
  const [rate, setRate] = useState(1);
  let { referralId } = useParams();

  let initialized = false;
  let userInfo = getUserInfo();
  let blurryText =
    referralStatus && referralStatus === "Unclaimed" ? (
      <span style={{ color: "transparent", textShadow: " 0 0 8px #000" }}>
        Not Available
      </span>
    ) : (
      "-"
    );

  const getRate = async () => {
    let data = await getCurrencyRate("", getCurrency().code, "USD");
    setRate(data);
  };
  useEffect(() => {
    if (!initialized && referralId) {
      //eslint-disable-next-line
      initialized = true;
      dispatch(getReferralDetails({ referralId, status }));
      dispatch(getPredefinedCredit());
      getRate();
    }
  }, []);

  useEffect(() => {
    if (referralDetails?.status) {
      setReferralStatus(referralDetails.status);
    }

    const fetchedPhoneNumber = referralDetails?.contactInformation?.phone;

    if (fetchedPhoneNumber && !fetchedPhoneNumber?.includes("+")) {
      setFormattedNumber(`${
        referralDetails?.contactInformation?.countryCode &&
        referralDetails?.contactInformation?.countryCode
      } - 
      ${referralDetails?.contactInformation?.phone}`);
    } else {
      setFormattedNumber(
        referralDetails?.contactInformation?.phone || blurryText
      );
    }
  }, [referralDetails]);

  const updateStatus = (event) => {
    setReferralStatus(event.target.value);
    let requestParams = { status: event.target.value };
    dispatch(
      updateReferral({ referralId: referralId, requestParams: requestParams })
    ).then((responseData) => {
      if (responseData.payload && responseData.payload.success) {
        dispatch(getReferralDetails({ referralId, status }));
      }
    });
  };

  const redeemReferral = (row) => {
    let serviceObj = {
      id: row.verified ? 7 : 8,
      title: row.verified ? "Verified referral" : "Unverified referral",
      quantity: 1,
      credit: row.verified ? 3 : 1,
      type: "RedeemReferral",
      referralId: row.id,
    };
    serviceObj.setError = setError;
    serviceObj.setSuccess = setSuccess;
    setService(serviceObj);
    onOpen();
  };

  const onCloseRedeem = () => {
    onClose();
  };

  const refetchDetail = () => {
    dispatch(getReferralDetails({ referralId, status }));
  };

  const onDeleteClick = () => {
    onAlertOpen();
  };

  const onAlertCloseAction = (referralDetails) => {
    let requestParams = { referralIdList: [referralDetails.id] };
    dispatch(deleteReferral(requestParams)).then((responseData) => {
      if (responseData.payload && responseData.payload.success) {
        navigate("/referrals");
      }
    });
    onAlertClose();
  };

  let budget = referralDetails?.requirements?.budget;
  if (
    referralDetails?.source === "Coachbar" &&
    referralDetails?.requirements?.budget
  ) {
    budget = budgetCalculation(referralDetails?.requirements?.budget, rate, []);
  }

  let revenue = referralDetails?.companyProfile?.annualRevenue;
  if (
    referralDetails?.source === "Coachbar" &&
    referralDetails?.companyProfile?.annualRevenue
  ) {
    revenue = revenueCalculate(
      referralDetails?.companyProfile?.annualRevenue,
      rate,
      []
    );
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Flex position={"relative"} justifyContent={"flex-end"} p={"24px"}>
            <AlertDialog
              title="Delete Referrals"
              actionButtonName="Delete"
              isAlertOpen={isAlertOpen}
              onAlertClose={onAlertClose}
              onAlertCloseAction={() => onAlertCloseAction(referralDetails)}
              body={
                <Text>
                  You are about to delete the manually added referral for the{" "}
                  <b>{referralDetails?.companyProfile?.companyName}</b>. Please
                  note that this action is irreversible. The referral(s) will be
                  permanently removed from the system. <br />
                  <br />
                  Are you sure you want to proceed with the deletion?
                </Text>
              }
            />
            {!loading &&
            referralDetails &&
            referralDetails?.status !== "Unclaimed" &&
            referralDetails?.status !== "Archived" ? (
              <Stack direction={"row"} height={"1.5rem"} mb="4px">
                {permissions.delete &&
                referralDetails?.source !== defaultReferralSource ? (
                  <Button
                    variant={"specializationButton"}
                    borderColor={"red.500"}
                    color={"red.500"}
                    title="Delete"
                    onClick={onDeleteClick}
                    id="referral-details-delete"
                  />
                ) : null}
                {permissions.edit && (
                  <Select
                    width={"8.5rem"}
                    value={referralStatus}
                    onChange={updateStatus}
                    mt="4px"
                    height={"30px"}
                  >
                    {referralUpdateStatus[referralDetails.status].map(
                      (status) => {
                        return (
                          <option key={status} value={status}>
                            {status === "Inprocess" ? "In Process" : status}
                          </option>
                        );
                      }
                    )}
                  </Select>
                )}
              </Stack>
            ) : !loading &&
              coachbarServicesPermission.add &&
              coachbarServicesPermission.edit &&
              referralDetails &&
              referralDetails?.status !== "Archived" ? (
              <>
                {prebookCredit?.unVerifiedReferral > 0 ? (
                  <Tag colorScheme={"linkedin"}>
                    {prebookCredit.unVerifiedReferral} Booked Unverified
                    Referrals
                  </Tag>
                ) : (
                  ""
                )}
                {prebookCredit?.verifiedReferral > 0 ? (
                  <Tag colorScheme={"linkedin"} ml={"1rem"}>
                    {prebookCredit.verifiedReferral} Booked Verified Referrals
                  </Tag>
                ) : (
                  ""
                )}

                <Button
                  maxH={"2rem"}
                  fontWeight={"400"}
                  w="7rem"
                  ml={"1rem"}
                  // isDisabled={
                  //   userInfo?.planId && userInfo?.planId !== 1 ? false : true
                  // }
                  title={referralDetails.verified ? "Redeem ¢3" : "Redeem ¢1"}
                  onClick={() => redeemReferral(referralDetails)}
                  id="referral-details-redeem"
                />
              </>
            ) : (
              <div>&nbsp;</div>
            )}
          </Flex>
          <Stack spacing="5" m="5" mt="0" align="stretch">
            {error || success ? (
              <Notification
                title={error ? error : success}
                status={error ? "error" : "success"}
                onCloseToast={() => dispatch(clearMessage())}
              />
            ) : (
              ""
            )}
            <RedeemService
              isOpen={isOpen}
              onClose={onCloseRedeem}
              selectedSevice={selectedSevice}
              performExtraAction={refetchDetail}
            />
            <Card variant="form">
              <CardHeader>
                <Heading size="md">Company Profile</Heading>
              </CardHeader>
              <Divider />
              <CardBody>
                <SimpleGrid columns={3} columnGap={8} rowGap={4}>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Name
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {referralDetails?.companyProfile?.companyName ||
                        blurryText}
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Company Website
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {referralDetails?.companyProfile?.website || blurryText}
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Industry
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {referralDetails?.companyProfile?.industry || blurryText}
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Employee Size
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {referralDetails?.companyProfile?.companySize ||
                        blurryText}
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Annual Revenue
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {replaceCurrencyInLabel(companyRevenueObj[revenue]) ||
                        blurryText}
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Business Type
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {referralDetails?.companyProfile?.businessType ||
                        blurryText}
                    </span>
                  </FormControl>
                </SimpleGrid>
              </CardBody>
            </Card>
            <Card variant="form">
              <CardHeader>
                <Heading size="md">Contact Information</Heading>
              </CardHeader>
              <Divider />
              <CardBody>
                <SimpleGrid columns={3} columnGap={8} rowGap={4}>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Name
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {referralDetails?.contactInformation?.contactName ||
                        blurryText}
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Email Address
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {referralDetails?.contactInformation?.email || blurryText}
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Phone No.
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {formattedNumber}
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Address
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {referralDetails?.contactInformation?.address ||
                        blurryText}
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Country
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {referralDetails?.contactInformation?.country ||
                        blurryText}
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Contact Role
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {referralDetails?.contactInformation?.contactRole ||
                        blurryText}
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Created
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {referralDetails?.created}
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Waiting Time
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {referralDetails?.contactInformation?.waitingTime ||
                        blurryText}
                    </span>
                  </FormControl>
                </SimpleGrid>
              </CardBody>
            </Card>
            <Card variant="form">
              <CardHeader>
                <Heading size="md">Requirement Overview</Heading>
              </CardHeader>
              <Divider />
              <CardBody>
                <SimpleGrid columns={1}>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Requirement &nbsp;
                      <CustomTooltip
                        content={
                          userInfo.userType === 3
                            ? requirementOverviewAbTip
                            : requirementOverviewTip
                        }
                      />
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {referralDetails?.requirements?.overview || blurryText}
                    </span>
                  </FormControl>
                </SimpleGrid>
                <SimpleGrid columns={2} columnGap={8} mt={4} rowGap={4}>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Service Required &nbsp;
                      <CustomTooltip
                        content={
                          userInfo?.userType === 3
                            ? serviceRequiredAbTip
                            : serviceRequiredTip
                        }
                      />
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {referralDetails?.requirements?.keyFeatures || blurryText}
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Challenges &nbsp;
                      <CustomTooltip
                        content={
                          userInfo.userType === 3
                            ? challengesAbTip
                            : challengesTip
                        }
                      />
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {referralDetails?.requirements?.reason || blurryText}
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Current App Stack &nbsp;
                      <CustomTooltip
                        content={
                          userInfo?.userType === 3
                            ? currentStackAbTip
                            : currentStackTip
                        }
                      />
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {referralDetails?.requirements?.appStack || blurryText}
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Evaluated Software &nbsp;
                      <CustomTooltip content={evaluateSoftwareTip} />
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {referralDetails?.requirements?.evaluated || blurryText}
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Project Duration
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {referralDetails?.requirements?.timeline || blurryText}
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Budget
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {replaceCurrencyInLabel(budgetOptionsObj[budget]) ||
                        blurryText}
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Implementation Strategy Preferences &nbsp;
                      <CustomTooltip content={deploymentPreTip} />
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {referralDetails?.requirements?.deployment || blurryText}
                    </span>
                  </FormControl>
                </SimpleGrid>
              </CardBody>
            </Card>
            <Card variant="form">
              <CardHeader>
                <Heading size="md">Next Step</Heading>
              </CardHeader>
              <Divider />
              <CardBody>
                <SimpleGrid columns={2} columnGap={8} mt={4} rowGap={4}>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Action Required
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {referralDetails?.nextStep?.actionRequired || blurryText}
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Follow up Instruction
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {referralDetails?.nextStep?.instructions || blurryText}
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Availability
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {referralDetails?.nextStep?.availability || blurryText}
                    </span>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      className="referralHeading"
                      textTransform={"capitalize"}
                      as="legend"
                    >
                      Best way to approach
                    </FormLabel>
                    <span style={{ color: "var(--text-font-color)" }}>
                      {referralDetails?.nextStep?.approach || blurryText}
                    </span>
                  </FormControl>
                </SimpleGrid>
              </CardBody>
            </Card>
          </Stack>
        </>
      )}
    </>
  );
};
export default ReferralDetails;
