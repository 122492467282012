import React from "react";
import {
  Box,
  Flex,
  FormLabel,
  Image,
  Input,
  Radio,
  Text,
} from "@chakra-ui/react";
import { useDrag } from "react-dnd";
import DragIcon from "../../images/dragIcon.png";
import binIcon from "../../images/brand-bin.svg";
import editIcon from "../../images/brand-edit.svg";

const DraggableFormInput = ({
  index,
  id,
  name,
  isRequired,
  placeholder,
  onDelete,
  onEdit,
  type,
  fieldType,
  mandatoryProperties = [],
}) => {
  const [opacity, drag] = useDrag(
    () => ({
      type: type,
      item: { id, index, dragFrom: "list" },
      collect: (monitor) => (monitor.isDragging() ? 0.4 : 1),
    }),
    [index, id]
  );

  return (
    <Box
      opacity={opacity}
      px={"38px"}
      py={"8px"}
      position={"relative"}
      role="group"
      ref={drag}
    >
      <Box
        position={"absolute"}
        inset={0}
        rounded={"8px"}
        display={"none"}
        _groupHover={{ display: "flex" }}
        border="1px solid #0C94AC"
        alignItems={"center"}
        className="brand-drag-icon"
      >
        <Image cursor="move" src={DragIcon} h={"14px"} ml={"12px"} />
        <Flex
          position={"absolute"}
          right={"12px"}
          top={"-12px"}
          border={"1px solid #0C94AC"}
          borderRadius={"12px"}
          bg={"#E7FAFD"}
          p={"4px 10px"}
          gap={2}
        >
          {onEdit && (
            <Image
              cursor="pointer"
              src={editIcon}
              h={"16px"}
              onClick={() => onEdit(id)}
            />
          )}
          {onDelete && !mandatoryProperties?.includes(id) && (
            <Image
              cursor="pointer"
              src={binIcon}
              h={"16px"}
              onClick={() => onDelete(id)}
            />
          )}
        </Flex>
      </Box>
      <Box>
        <FormLabel display={"flex"} justifyContent={"start"}>
          <Text
            color={"brandGray.400"}
            fontSize={"12px"}
            fontWeight={600}
            lineHeight={"18px"}
          >
            {name}
          </Text>
          {isRequired ? (
            <Text
              color={"cherryRed.700"}
              fontSize={"12px"}
              fontWeight={600}
              lineHeight={"18px"}
            >
              *
            </Text>
          ) : (
            ""
          )}
        </FormLabel>

        {fieldType === "radio" ? (
          <Flex gap={5}>
            <Radio isReadOnly>
              <Text fontSize={"14px"}>Yes</Text>
            </Radio>
            <Radio isReadOnly>
              <Text fontSize={"14px"}>No</Text>
            </Radio>
          </Flex>
        ) : (
          <Input placeholder={placeholder} readOnly fontSize={"14px"} />
        )}
      </Box>
    </Box>
  );
};

export default DraggableFormInput;
