export const initialLeadFormState = [
  {
    id: 1,
    name: "First Name",
    defaultKeyName: "firstName",
    added: true,
    isRequired: true,
    placeholder: "Enter your first name",
    fieldType: "input",
  },
  {
    id: 2,
    name: "Last Name",
    defaultKeyName: "lastName",
    added: true,
    isRequired: true,
    placeholder: "Enter your last name",
    fieldType: "input",
  },
  {
    id: 3,
    name: "Email",
    defaultKeyName: "email",
    added: true,
    isRequired: true,
    placeholder: "Enter your email address",
    fieldType: "input",
  },
  {
    id: 4,
    name: "Company",
    defaultKeyName: "companyName",
    added: true,
    isRequired: true,
    placeholder: "Enter your company name",
    fieldType: "input",
  },
  {
    id: 5,
    name: "Lead Status",
    defaultKeyName: "status",
    added: true,
    isRequired: true,
    placeholder: "Select",
    fieldType: "singleSelectDropDown",
  },
  {
    id: 22,
    name: "Do you have a product license?",
    defaultKeyName: "doYouHaveProductLicense",
    added: false,
    isRequired: false,
    fieldType: "radio",
  },
  {
    id: 6,
    name: "Company Website",
    defaultKeyName: "companyWebsite",
    added: false,
    isRequired: false,
    placeholder: "Enter your website",
    fieldType: "input",
  },
  {
    id: 7,
    name: "Services Required",
    defaultKeyName: "services",
    added: false,
    isRequired: false,
    placeholder: "Enter the services you provide",
    fieldType: "multiSelectDropDown",
  },
  {
    id: 8,
    name: "Phone",
    defaultKeyName: "phone",
    added: false,
    isRequired: false,
    placeholder: "Enter your phone number",
    fieldType: "phoneInput",
  },
  {
    id: 9,
    name: "Average Budget",
    defaultKeyName: "spBudget",
    added: false,
    isRequired: false,
    placeholder: "Enter your budget",
    fieldType: "multiSelectDropDown",
  },
  {
    id: 10,
    name: "Focus Industry",
    defaultKeyName: "industry",
    added: false,
    isRequired: false,
    placeholder: "Enter your industry",
    fieldType: "multiSelectDropDown",
  },
  {
    id: 11,
    name: "Location",
    defaultKeyName: "country",
    added: false,
    isRequired: false,
    placeholder: "Enter your location",
    fieldType: "singleSelectDropDown",
  },
  {
    id: 12,
    name: "Current Application Stack",
    defaultKeyName: "currentStack",
    added: false,
    isRequired: false,
    placeholder: "Enter your current technology stack",
    fieldType: "multiSelectDropDown",
  },
  {
    id: 13,
    name: "Company Size",
    defaultKeyName: "companySize",
    added: false,
    isRequired: false,
    placeholder: "Enter your company size",
    fieldType: "multiSelectDropDown",
  },
  {
    id: 14,
    name: "Company Annual Revenue",
    defaultKeyName: "spCompanyRevenue",
    added: false,
    isRequired: false,
    placeholder: "Enter your company revenue",
    fieldType: "multiSelectDropDown",
  },
  {
    id: 15,
    name: "Job Title",
    defaultKeyName: "jobTitle",
    added: false,
    isRequired: false,
    placeholder: "Enter your job title",
    fieldType: "input",
  },
  {
    id: 16,
    name: "Preferred Communication Type",
    defaultKeyName: "preferredContactType",
    added: false,
    isRequired: false,
    placeholder: "Enter your preferred contact type",
    fieldType: "multiSelectDropDown",
  },
  {
    id: 17,
    name: "City",
    defaultKeyName: "city",
    added: false,
    isRequired: false,
    placeholder: "Enter your city",
    fieldType: "input",
  },
  {
    id: 18,
    name: "When service should be started?",
    defaultKeyName: "serviceTiming",
    added: false,
    isRequired: false,
    placeholder: "Enter your service timings",
    fieldType: "multiSelectDropDown",
  },
  {
    id: 19,
    name: "Preferred Language",
    defaultKeyName: "preferredLanguage",
    added: false,
    isRequired: false,
    placeholder: "Enter your preferred language",
    fieldType: "multiSelectDropDown",
  },
  {
    id: 20,
    name: "Comments",
    defaultKeyName: "comments",
    added: false,
    isRequired: false,
    placeholder: "Enter your comments",
    fieldType: "input",
  },
  {
    id: 21,
    name: "Products",
    defaultKeyName: "products",
    added: false,
    isRequired: false,
    placeholder: "Enter your product",
    fieldType: "multiSelectDropDown",
  },
];
