import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const imgDeleteBtn = defineStyle({
  bg: "brandGray.20",
  position: "relative",
  top: "-10px",
  borderRadius: "20px",
  color: "white",
  height: "20px",
  width: "20px",
});

export const closeButtonTheme = defineStyleConfig({
  variants: {
    imgDeleteBtn,
  },
  // The default size and variant values
});
