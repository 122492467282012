import { axiosInstance } from "../../../services/axiosInstance";
import { handleError } from "../../../services/utility";

export const getListOfPartnerStatus = async () => {
  try {
    const { data } = await axiosInstance.get(`/partnerStatus`);
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};

export const addPartnerStatus = async (requestParams) => {
  try {
    const { data } = await axiosInstance.post(`/partnerStatus/add`, {
      ...requestParams,
    });
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};

export const editPartnerStatus = async (id, requestParams) => {
  try {
    const { data } = await axiosInstance.put(`/partnerStatus/${id}`, {
      ...requestParams,
    });
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};

export const deletePartnerStatus = async (id) => {
  try {
    const { data } = await axiosInstance.delete(`/partnerStatus/${id}`);
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};
