import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  getIndustriesList,
  getServiceIndustry,
} from "../serviceOffering/serviceOfferingActions";
import {
  getAllClientInfo,
  addClientInfo,
} from "../clientsInformation/clientsInformationActions";
import { addClientInfoToList } from "../clientsInformation/clientsInformationSlice";
import closeIcon from "../../image/closeBundle.png";
import {
  Box,
  Flex,
  HStack,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
  FormLabel,
  FormControl,
  FormHelperText,
  Input,
  Center,
  Divider,
} from "@chakra-ui/react";
import { ChevronDownIcon, AddIcon } from "@chakra-ui/icons";
import {
  Button,
  SearchList,
  Select as CustomSelect,
  TextArea,
  TextBox,
  Modal,
  Loader,
} from "@coachbar/shared-components";
import {
  addConfiguration,
  updateConfiguration,
  getAllSoftwareList,
  requestToAddSoftware,
  getSoftwareSpecialisation,
} from "./softwareSpecialisationActions";
import {
  addConfigurationToList,
  updateConfigurationFromList,
} from "./softwareSpecialisationSlice";
import {
  clientEmpty,
  softwareEmpty,
  growthOption,
  // businessOptionsReactSelect,
  companyTypeArrayReactSelect,
} from "../../constants/specialization";
import AddClientModal from "../../components/AddClientModal";
import fallbackImage from "../../image/fallbackImage.png";
import {
  maxFileSize,
  fileTypeAllowed,
} from "../../constants/validationMessages";
import {
  getPreSignedUrl,
  getUserInfo,
  updateSystemGridData,
  uploadImage,
} from "../../services/utility";
import { getProfileCompletionStatus } from "../profileSetup/profileMetrics/profileMetricsAction";
import SoftwareGridContainer from "./SoftwareGridContainer";
import { urlValidationPattern } from "../../constants/validationPatterns";
import ClientReactSelect from "../../components/ClientReactSelect";
import {
  buttonVariants,
  companyRevenueOptions,
} from "@coachbar/shared-components/src/constants/constantValues";
import {
  appendHttpsToUrl,
  replaceCurrencyInLabel,
} from "@coachbar/shared-components/src/services/utility";

const CreateConfiguration = (props) => {
  const { clientInfoList, loading: loaderClientList } = useSelector(
    (state) => state.clientInfo
  );
  const { softwareSpecializationList, loading } = useSelector(
    (state) => state.softwareSpecialisation
  );
  const businessOptionsReactSelect = companyRevenueOptions.map((x) => ({
    ...x,
    label: replaceCurrencyInLabel(x.label),
  }));
  //   const [showConfigurationForm, setShowConfigurationForm] = useState(false);
  const [softwareList, setSoftwareList] = useState([]);
  const [initialSoftwareList, setInitialSoftwareList] = useState([]);
  const [selectedSoftware, setSelectedSoftware] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]);
  const [allServiceList, setAllServiceList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [businessValue, setBusinessValue] = useState([]);
  const [growthValue, setGrowthValue] = useState([]);
  const [description, setDescription] = useState("");
  const [companyTypeList, setCompanyTypeList] = useState(
    companyTypeArrayReactSelect
  );
  const [selectedCompanyType, setSelectedCompanyType] = useState([]);
  const [businessOptionsList, setBusinessOptionsList] = useState(
    businessOptionsReactSelect
  );
  const [softwareError, setSoftwareError] = useState("");
  const [industryError, setIndustryError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [softwareLogoInput, setSoftwareLogoInput] = useState("");
  const [softwareLogoInputError, setsoftwareLogoInputError] = useState("");
  const [addCategoryShow, setAddCategoryShow] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategoryInput, setSelectedCategoryInput] = useState("");
  const [selectedCategoryInputError, setSelectedCategoryInputError] =
    useState("");
  const dispatch = useDispatch();
  const {
    register,
    resetField,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });
  const MAX_FILE_SIZE_LOGO = 200; // in KB;
  const fieldType = "client-logo";
  const [logoImagePath, setLogoImagePath] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingClient, setIsLoadingClient] = useState(false);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const addSoftwareModal = useDisclosure();
  const requestSoftwareModal = useDisclosure();
  const [addSoftwareInput, setAddSoftwareInput] = useState("");
  const [addSoftwareInputError, setAddSoftwareInputError] = useState("");
  const [addSoftwareCommentInput, setAddSoftwareCommentInput] = useState("");
  const [softwareSpecializationArray, setSoftwareSpecializationArray] =
    useState(null);
  const [softwareRefList, setSoftwareRefList] = useState(null);
  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      fetchIndustriesList();
      dispatch(getAllClientInfo());
      if (softwareSpecializationList) {
        setSoftwareRefList(softwareSpecializationList);
        prepareSoftwareList(softwareSpecializationList);
      } else {
        setIsLoading(true);
        dispatch(getAllSoftwareList())
          .then((responseData) => {
            const response = responseData.payload;
            if (
              response.success &&
              response.data &&
              response.data.softwareList
            ) {
              setSoftwareRefList(response.data.softwareList);
              prepareSoftwareList(response.data.softwareList);
              setIsLoading(false);
            } else {
              setIsLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      dispatch(getSoftwareSpecialisation()).then((response) => {
        if (
          response.payload.success &&
          response.payload.data &&
          response.payload.data.softwareSpecialization &&
          response.payload.data.softwareSpecialization
            .softwareSpecializationList
        ) {
          setSoftwareSpecializationArray(
            response.payload.data.softwareSpecialization
              .softwareSpecializationList
          );
        }
      });
    }
  }, []);

  useEffect(() => {
    var allSoftwareList = [];
    const seenNames = new Set();
    for (const key in softwareSpecializationArray) {
      softwareSpecializationArray[key].map((software) => {
        const softwareWithCategory = {
          ...software,
          category: key,
        };

        // Check if the software name is already seen
        if (!seenNames.has(software.name)) {
          allSoftwareList.push(softwareWithCategory);
          seenNames.add(software.name);
        }

        return softwareWithCategory;
      });
    }

    allSoftwareList.forEach((software) => {
      const index = softwareList.findIndex(
        (item) => item.name === software.name
      );
      if (index !== -1) {
        const matchedSoftware = softwareList.splice(index, 1)[0]; // Remove the item
        softwareList.unshift(matchedSoftware); // Add it to the top
      }
    });
  }, [softwareSpecializationArray]);

  useEffect(() => {
    if (allServiceList.length > 0) {
      dispatch(getServiceIndustry());
    }
  }, [allServiceList]);

  useEffect(() => {
    if (
      props.selectedConfiguration &&
      props.selectedConfiguration.id &&
      props.selectedConfiguration.clientList
    ) {
      let clientArray = [];
      let selectedClientArray = [];
      const bundle = props.selectedConfiguration;
      clientInfoList.map((client) => {
        const selectedItem = bundle.clientList.find(
          (item) => item.id === client.id
        );
        if (client.hasOwnProperty("name")) {
          if (selectedItem) {
            // clientArray.push({ ...client, isChecked: true });
            // selectedClientArray.push({ ...client, isChecked: true });
            clientArray.push({ label: client.name, value: client.id });
            selectedClientArray.push({
              label: client.name,
              value: client.id,
            });
          } else {
            clientArray.push({ label: client.name, value: client.id });
          }
        }
      });
      setClientList(clientArray);
      setSelectedClient(selectedClientArray);
    } else {
      const isCheckName = clientInfoList.filter((item) =>
        item.hasOwnProperty("name")
      );
      const listArray = isCheckName.map(({ name, id }) => {
        return { label: name, value: id };
      });
      setClientList(listArray);
    }
  }, [clientInfoList]);

  useEffect(() => {
    let serviceArray = [];
    let selectedArray = [];
    if (allServiceList.length > 0) {
      const bundle = props.selectedConfiguration;
      allServiceList?.map((industry) => {
        const selectedItem = bundle?.industry?.find(
          (item) => item === industry.name
        );
        if (selectedItem) {
          serviceArray.push({ label: industry.name, value: industry.name });
          selectedArray.push({ label: industry.name, value: industry.name });
        } else {
          serviceArray.push({ label: industry.name, value: industry.name });
        }
      });
      setSelectedIndustry(selectedArray);
      setIndustryList(serviceArray);
    }
  }, [allServiceList, props.selectedConfiguration]);

  useEffect(() => {
    if (initialSoftwareList.length === 0) {
      return;
    }
    const modifiedArray = Object.values(initialSoftwareList).flatMap(
      (categoryItems) => categoryItems
    );
    if (props.selectedConfiguration && props.selectedConfiguration.id) {
      const bundle = props.selectedConfiguration;
      const updatedSelected = Array.from({ length: 9 }).map((_, i) => {
        const matchingItem = bundle?.softwareList?.find(
          (item) => item.index === i
        );
        if (matchingItem) {
          return matchingItem;
        } else {
          return { index: i };
        }
      });
      setSelectedSoftware(updatedSelected);
      const updatedSoftware = modifiedArray.map((software) => {
        const selectedItem = bundle?.softwareList?.find(
          (selectedItem) => selectedItem?.name === software?.name
        );
        if (selectedItem) {
          return {
            ...selectedItem,
            isChecked: true,
          };
        }
        return software;
      });
      setSoftwareList(updatedSoftware);
    } else {
      setSoftwareList(modifiedArray);
    }
  }, [initialSoftwareList]);

  useEffect(() => {
    if (props.selectedConfiguration && props.selectedConfiguration.id) {
      const bundle = props.selectedConfiguration;
      let selectedArray = [];
      if (businessOptionsReactSelect.length > 0) {
        const bundle = props.selectedConfiguration;
        businessOptionsReactSelect?.map((business) => {
          const selectedItem = bundle?.businessSize?.find(
            (item) => item === business.value
          );
          if (selectedItem) {
            selectedArray.push({
              label: business.label,
              value: business.value,
            });
          }
        });
        setBusinessValue(selectedArray);
      }
      if (bundle.efficiencyGrowth) {
        setGrowthValue([
          {
            label: bundle.efficiencyGrowth,
            value: bundle.efficiencyGrowth,
          },
        ]);
      } else {
        setGrowthValue([]);
      }
      setDescription(bundle.description);
      setTitle(bundle.title);
      let selectedCompanyTypeArray = [];
      if (bundle.companyType && bundle.companyType.length > 0) {
        companyTypeArrayReactSelect.map((company) => {
          const selectedItem = bundle.companyType.find(
            (item) => item === company.label
          );
          if (selectedItem) {
            selectedCompanyTypeArray.push({
              label: company.label,
              value: company.label,
            });
          }
        });
        setSelectedCompanyType(selectedCompanyTypeArray);
      }
    }
  }, [props.selectedConfiguration]);

  const prepareSoftwareList = (softwareList) => {
    var allSoftwareList = [];
    var listOfCategory = [];
    const seenNames = new Set();
    for (const key in softwareList) {
      listOfCategory.push(key);
      softwareList[key].map((software) => {
        const softwareWithCategory = {
          ...software,
          category: key,
        };

        // Check if the software name is already seen
        if (!seenNames.has(software.name)) {
          allSoftwareList.push(softwareWithCategory);
          seenNames.add(software.name);
        }
        return softwareWithCategory;
      });
    }
    const optionList = listOfCategory
      .filter((i) => i !== "All")
      .map((item) => {
        return { value: item, label: item };
      });
    setCategoryList(optionList);
    setInitialSoftwareList(allSoftwareList);
  };

  const fetchIndustriesList = async () => {
    let list = await getIndustriesList();
    setAllServiceList(
      list.map((x) => {
        return { ...x, isChecked: false };
      })
    );
  };

  const prepareRequest = (status) => {
    let requestData = {};
    if (props.spTenantId) {
      requestData.spTenantId = props.spTenantId;
    } else {
      requestData.spTenantId = process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID;
    }
    let err = false;
    const updatedArray = selectedSoftware.map((item, i) => ({
      ...item,
      index: i,
    }));
    const selectedArray = updatedArray.filter((item) =>
      item.hasOwnProperty("name")
    );
    if (
      selectedArray &&
      selectedArray.length > 0 &&
      selectedArray.some((item) => item.index === 4)
    ) {
      setSoftwareError("");
      requestData.softwareList = selectedArray;
    } else {
      err = true;
      setSoftwareError("Please select core software");
    }
    if (selectedIndustry && selectedIndustry.length > 0) {
      setIndustryError("");
      requestData.industry = selectedIndustry.map((item) => item.label);
    } else {
      err = true;
      setIndustryError("Please select atleast 1 industry");
    }
    if (description) {
      setDescriptionError("");
      requestData.description = description;
    } else {
      err = true;
      setDescriptionError("Description is required ");
    }
    if (title) {
      setTitleError("");
      requestData.title = title;
    } else {
      err = true;
      setTitleError("Name is required ");
    }
    const businessData = businessValue.map((item) => item.value);
    requestData.businessSize = businessData;
    requestData.companyType =
      selectedCompanyType && selectedCompanyType.length > 0
        ? selectedCompanyType.map((item) => item.label)
        : [];
    requestData.efficiencyGrowth = growthValue[0]?.label || "";
    const filterData = selectedClient.map((cl) => {
      const data = clientInfoList.find((x) => x.id === cl.value);
      return data;
    });
    requestData.clientList = filterData;
    if (status) {
      requestData.bundleStatus = status;
    }
    return { requestData, isError: err };
  };

  const configurationSave = (status, publishDefault = false) => {
    const { requestData, isError } = prepareRequest(status);
    if (!isError) {
      if (props.selectedConfiguration && props.selectedConfiguration.id) {
        dispatch(
          updateConfiguration({
            configurationId: props.selectedConfiguration.id,
            requestData,
          })
        ).then((responseData) => {
          if (
            responseData.payload &&
            responseData.payload.success &&
            responseData.payload.data &&
            responseData.payload.data.softwareBundle
          ) {
            if (publishDefault) {
              let requestData = {
                bundleStatus: "Published",
              };
              dispatch(
                updateConfiguration({
                  configurationId:
                    responseData.payload.data?.softwareBundle?.id,
                  requestData,
                })
              ).then((onSavedResponse) => {
                dispatch(getProfileCompletionStatus());
                dispatch(
                  updateConfigurationFromList(
                    onSavedResponse.payload.data.softwareBundle
                  )
                );
                props.closeCreateForm();
                return;
              });
            } else {
              dispatch(getProfileCompletionStatus());
              dispatch(
                updateConfigurationFromList(
                  responseData.payload.data.softwareBundle
                )
              );
              props.closeCreateForm();
            }
          }
        });
      } else {
        dispatch(addConfiguration(requestData)).then((responseData) => {
          if (
            responseData.payload &&
            responseData.payload.success &&
            responseData.payload.data &&
            responseData.payload.data.softwareBundle
          ) {
            dispatch(getProfileCompletionStatus());
            updateSystemGridData(dispatch);
            dispatch(
              addConfigurationToList(responseData.payload.data.softwareBundle)
            );
            props.closeCreateForm();
          }
        });
      }
    }
  };

  const softwareChange = (newItem, updatedSoftwareList) => {
    setSelectedSoftware((prevItems) => {
      const nameExistsIndex = prevItems?.findIndex(
        (item) => item?.name === newItem?.name
      );

      if (nameExistsIndex !== -1) {
        const updatedItems = prevItems.filter(
          (item) => item.name !== newItem.name
        );
        return updatedItems;
      } else {
        const objectWithoutName = prevItems?.findIndex(
          (item) => !item?.hasOwnProperty("name")
        );
        if (objectWithoutName !== -1) {
          prevItems[objectWithoutName] = { ...newItem };
        } else {
          prevItems.push(newItem);
        }
        return [...prevItems];
      }
    });
    setSoftwareList(updatedSoftwareList);
  };

  const removeSelectedSoftware = (name) => {
    setSelectedSoftware((prevItem) => {
      const updatedIndex = prevItem.findIndex((item) => item.name === name);
      if (updatedIndex !== -1) {
        const { index } = prevItem[updatedIndex];
        prevItem[updatedIndex] = { index };
      }
      return prevItem;
    });
    setSoftwareList((prevItem) => {
      const updatedList = [...prevItem];
      for (let i = 0; i < updatedList.length; i++) {
        if (updatedList[i].name === name) {
          updatedList[i].isChecked = false;
          break; // Stop the loop after the match
        }
      }
      return updatedList;
    });
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const [title, setTitle] = useState("");

  // Add Client modal
  const onModalClose = () => {
    setLogoImagePath("");
    reset();
    onClose();
  };

  const onLogoDelete = () => {
    setLogoImagePath("");
    resetField("logo");
  };

  const handleError = (errors) => {
    if (errors && errors["logo"]) {
      resetField("logo");
      handleSubmit(submitForm)();
    }
  };

  const uploadImageFileToS3 = async (fileObj, preSignedUrl) => {
    uploadImage(fileObj, preSignedUrl).then((responseData) => {
      if (responseData.status === 200) {
        let imageUrl = preSignedUrl.split("?")[0];
        setLogoImagePath(imageUrl);
        setIsLoadingClient(false);
      } else {
        setIsLoadingClient(false);
      }
    });
  };

  const uploadFile = (fileObj) => {
    setIsLoadingClient(true);
    let fileExt = fileObj.name.split(".").pop();
    let filePath = fieldType + "-" + Date.now() + "." + fileExt;
    getPreSignedUrl(filePath).then((responseData) => {
      if (responseData.data && responseData.data.success) {
        const preSignedUrl = responseData.data?.data?.signedUrl;
        uploadImageFileToS3(fileObj, preSignedUrl);
      } else {
        setIsLoadingClient(false);
      }
    });
  };

  const validateFile = (files) => {
    const fileObj = files && files[0];

    if (!fileObj || !fileObj?.type) {
      return true;
    }

    const [type] = fileObj.type.split("/");
    if (!type || type !== "image") {
      return fileTypeAllowed;
    }

    const fsMb = fileObj.size / 1024;

    if (fsMb > MAX_FILE_SIZE_LOGO) {
      return maxFileSize.replace("$MB$", MAX_FILE_SIZE_LOGO + "KB");
    }
    if (!isSubmitting) {
      uploadFile(fileObj);
    }
    return true;
  };

  const submitForm = (data) => {
    data["logo"] = logoImagePath;
    dispatch(addClientInfo(data)).then((responseData) => {
      if (responseData.payload.success && responseData.payload.data.client) {
        dispatch(addClientInfoToList(responseData.payload.data.client));
        onModalClose();
      }
    });
  };

  const handleAddSoftwareInputChange = (e) => {
    setAddSoftwareInput(e.target.value);
    if (e.target.value) {
      setAddSoftwareInputError("");
    } else {
      setAddSoftwareInputError("Software name is required");
    }
  };

  const handleSoftwareLogoInputChange = (e) => {
    setSoftwareLogoInput(e.target.value);
    if (e.target.value) {
      if (e.target.value.match(urlValidationPattern)) {
        setsoftwareLogoInputError("");
      } else {
        setsoftwareLogoInputError("Please enter a valid URL");
      }
    } else {
      setsoftwareLogoInputError("Software logo is required");
    }
  };

  const handleAddCategoryInputChange = (e) => {
    setSelectedCategoryInput(e.target.value);
    if (e.target.value) {
      setSelectedCategoryInputError("");
    } else {
      setSelectedCategoryInputError("Software category is required");
    }
  };

  const handleRequestSoftwareSave = () => {
    if (!addSoftwareInput || addSoftwareInput.trim() == "") {
      setAddSoftwareInputError("Software name is required");
    } else {
      setAddSoftwareInputError("");
    }
    if (!softwareLogoInput || softwareLogoInput.trim() === "") {
      setsoftwareLogoInputError("Software logo is required");
    } else {
      setsoftwareLogoInputError("");
    }
    if (!selectedCategoryInput || selectedCategoryInput.trim() === "") {
      setSelectedCategoryInputError("Software category is required");
    } else {
      setSelectedCategoryInputError("");
    }
    if (
      addSoftwareInput.trim() !== "" &&
      softwareLogoInput.trim() !== "" &&
      selectedCategoryInput.trim() !== ""
    ) {
      let requestParams = {
        name: addSoftwareInput,
        description: addSoftwareCommentInput,
        logo: appendHttpsToUrl(softwareLogoInput),
        category: selectedCategoryInput,
      };
      dispatch(requestToAddSoftware(requestParams)).then((responseData) => {
        if (responseData.payload.success && responseData.payload.code === 200) {
          const key = responseData.payload.data.softwareSpecialization;
          const temp = JSON.parse(JSON.stringify(softwareRefList));
          if (temp[key.category]) {
            temp[key.category].push(key);
          } else {
            temp[key.category] = [key];
          }
          prepareSoftwareList(temp);
          setAddSoftwareInput("");
          setAddSoftwareInputError("");
          setAddSoftwareCommentInput("");
          setSoftwareLogoInput("");
          setsoftwareLogoInputError("");
          setSelectedCategoryInput("");
          setSelectedCategoryInputError("");
          requestSoftwareModal.onClose();
          addSoftwareModal.onClose();
        }
      });
    }
  };

  const customStyles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontSize: "14px",
        color: "#718096",
      };
    },
    control: (styles, state) => ({
      ...styles,
      boxShadow: "none",
      border: `1px solid ${state.isFocused ? "#0C94AC" : "#E6E7E9"}`,
      "&:hover": {
        borderColor: "#CBD5E0",
      },
    }),
    multiValue: (provided, state) => ({
      ...provided,
      color: "var(--title-black-text)",
      fontSize: "12px",
      fontWeight: 500,
      backgroundColor: "#F3F7FF",
      border: "1px solid #DEE8F8",
      borderRadius: "16px",
      overflow: "hidden",
      padding: "2px 4px",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: "var(--title-black-text)",
      fontSize: "12px",
      fontWeight: 500,
      backgroundColor: "#F3F7FF",
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: "#7E8792",
      backgroundColor: "#F3F7FF",
      ":hover": {
        backgroundColor: "transparent",
      },
    }),
  };

  const createConfiguration = () => {
    let userInfo = getUserInfo();
    return (
      <Flex className="full-width-popovers">
        <Box w={"50%"}>
          <Box mb={3}>
            <TextBox
              type="text"
              name={"Name"}
              maxLength="150"
              required
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              hideHelperText={true}
              tooltip={
                userInfo?.userType === 3
                  ? "Insert a prominent headline or name for System Grid."
                  : false
              }
            />
            {titleError && (
              <Box fontSize={"0.75rem"} color={"#E53E3E"}>
                {titleError}
              </Box>
            )}
          </Box>
          <Box mb={3} position={"relative"}>
            <FormLabel
              color={"#5E6977"}
              fontSize={"12px"}
              fontWeight={600}
              isRequired
            >
              Best for Industry
              <Box as="span" color={"red.500"}>
                *
              </Box>
            </FormLabel>
            <Select
              closeMenuOnSelect={false}
              options={industryList}
              value={selectedIndustry}
              onChange={(e) => {
                setSelectedIndustry(e);
                if (e.length > 0) {
                  setIndustryError("");
                } else {
                  setIndustryError("Please select atleast 1 industry");
                }
              }}
              styles={customStyles}
              placeholder={"Select Industry"}
              isSearchable={true}
              isMulti
            />
            {industryError && (
              <Box fontSize={"0.75rem"} color={"#E53E3E"}>
                {industryError}
              </Box>
            )}
          </Box>
          <Box mb={3} position={"relative"}>
            <FormLabel color={"#5E6977"} fontSize={"12px"} fontWeight={600}>
              Business Size
            </FormLabel>
            <Select
              closeMenuOnSelect={false}
              options={businessOptionsList}
              value={businessValue}
              onChange={(e) => {
                setBusinessValue(e);
              }}
              styles={customStyles}
              placeholder={"Select business size"}
              isSearchable={true}
              isMulti
            />
          </Box>
          <Box mb={3} position={"relative"}>
            <FormLabel color={"#5E6977"} fontSize={"12px"} fontWeight={600}>
              Best for Company Type
            </FormLabel>
            <Select
              closeMenuOnSelect={false}
              options={companyTypeList}
              value={selectedCompanyType}
              onChange={(e) => {
                setSelectedCompanyType(e);
              }}
              styles={customStyles}
              placeholder={"Select company type"}
              isSearchable={true}
              isMulti
            />
          </Box>
          <Box mb={3}>
            <FormLabel color={"#5E6977"} fontSize={"12px"} fontWeight={600}>
              Expected Efficiency Growth
            </FormLabel>
            <Select
              isClearable
              options={growthOption}
              value={growthValue[0]}
              onChange={(e) => {
                setGrowthValue([e]);
              }}
              styles={customStyles}
              placeholder={"Select efficiency growth"}
            />
          </Box>
          <Box mb={3}>
            <TextArea
              name="Description"
              // height="100px"
              required
              value={description}
              onChange={(e) => handleDescriptionChange(e)}
              maxLength={2000}
              helperText={`${description?.length || 0}/2000`}
              helperTextPosition={"right"}
            />
            {descriptionError && (
              <Box fontSize={"0.75rem"} color={"#E53E3E"}>
                {descriptionError}
              </Box>
            )}
          </Box>

          <Box mb={3} position={"relative"}>
            <FormLabel color={"#5E6977"} fontSize={"12px"} fontWeight={600}>
              Select clients using this Software Products Configuration
            </FormLabel>
            <ClientReactSelect
              clientInfoList={clientInfoList}
              clientList={clientList}
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
              customStyles={customStyles}
              addClientOpen={onOpen}
            />

            <AddClientModal
              isOpen={isOpen}
              onModalClose={onModalClose}
              submitForm={submitForm}
              handleError={handleError}
              loading={loaderClientList}
              validateFile={validateFile}
              isLoading={isLoadingClient}
              logoImagePath={logoImagePath}
              onLogoDelete={onLogoDelete}
              register={register}
              handleSubmit={handleSubmit}
              errors={errors}
            />
          </Box>
        </Box>
        <Center>
          <Divider
            mx={"24px"}
            border={"1px dashed #E6E7E9"}
            height="100%"
            orientation="vertical"
          />
        </Center>
        <Box w={"50%"}>
          <Modal
            isOpen={addSoftwareModal.isOpen}
            onClose={addSoftwareModal.onClose}
            title={"Add software(s) to Specialization Configuration"}
            allowCloseButton={true}
            displayFooter={false}
            // onSaveClick={addSoftwareModal.onClose}
            loading={loading}
            size={"xl"}
            padding={"0"}
          >
            <Box p={"24px"}>
              <SearchList
                itemList={softwareList}
                handleCheckBoxChange={softwareChange}
                disable={
                  selectedSoftware &&
                  selectedSoftware.length >= 9 &&
                  selectedSoftware.every((obj) => obj?.hasOwnProperty("name"))
                    ? true
                    : false
                }
                message={softwareEmpty}
                showImage={true}
                allowSearch={true}
                fallbackImage={fallbackImage}
                modalBtnClick={null}
                isModalBtnDisabled={false}
                showCategory={true}
                requestToAddSoftware={true}
                handleRequestSoftware={() => requestSoftwareModal.onOpen()}
                moduleForId="create-configuration-add-software-to-specialization"
              />
            </Box>
            <Center borderTop={"1px solid #E6E7E9"}>
              <Text
                cursor={"pointer"}
                p={"10px"}
                fontSize={"14px"}
                fontWeight={600}
                color={"#0c94ac"}
                onClick={() => requestSoftwareModal.onOpen()}
              >
                Add Software
              </Text>
            </Center>
          </Modal>

          <FormLabel>
            Software Included Into System Grid{" "}
            <span style={{ color: "#E53E3E", fontWeight: "500" }}>*</span>
          </FormLabel>
          <SoftwareGridContainer
            selectedSoftware={selectedSoftware}
            setSelectedSoftware={setSelectedSoftware}
            onClickOpenModal={addSoftwareModal.onOpen}
            removeSelectedSoftware={removeSelectedSoftware}
          />
          {softwareError && (
            <Box fontSize={"0.75rem"} color={"#E53E3E"}>
              {softwareError}
            </Box>
          )}
        </Box>
      </Flex>
    );
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Box p={"24px"}>
      <Box border="1px solid #E6E7E9" rounded={"8px"}>
        <Box p={"24px"}>
          {createConfiguration()}
          {/* Add Software Modal*/}
          <Modal
            isOpen={requestSoftwareModal.isOpen}
            onClose={() => {
              setAddSoftwareInput("");
              setAddSoftwareInputError("");
              setAddSoftwareCommentInput("");
              setSoftwareLogoInput("");
              setsoftwareLogoInputError("");
              setSelectedCategoryInput("");
              setSelectedCategoryInputError("");
              requestSoftwareModal.onClose();
            }}
            title={"Add Software"}
            allowCloseButton={true}
            displayFooter={true}
            onSaveClick={() => handleRequestSoftwareSave()}
            loading={loading}
          >
            <Box>
              <Text fontSize={"14px"} fontWeight={500} pb={"24px"}>
                Complete the form to add software and start using it right away.
                Please be aware that the software logo and description can be
                adjusted after moderation.
              </Text>
              <FormControl mb={4}>
                <FormLabel>
                  Software Name{" "}
                  <span style={{ color: "#E53E3E", fontWeight: "500" }}>*</span>
                </FormLabel>
                <Input
                  type="text"
                  value={addSoftwareInput}
                  onChange={(e) => handleAddSoftwareInputChange(e)}
                />
                {addSoftwareInputError && (
                  <FormHelperText color={"red.500"}>
                    {addSoftwareInputError}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>
                  Software Logo URL
                  <span style={{ color: "#E53E3E", fontWeight: "500" }}>*</span>
                </FormLabel>
                <Input
                  type="text"
                  value={softwareLogoInput}
                  onChange={(e) => handleSoftwareLogoInputChange(e)}
                />
                {softwareLogoInputError && (
                  <FormHelperText color={"red.500"}>
                    {softwareLogoInputError}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl mb={4}>
                <Flex justifyContent={"space-between"}>
                  <FormLabel>
                    Software Category
                    <span style={{ color: "#E53E3E", fontWeight: "500" }}>
                      *
                    </span>
                  </FormLabel>
                  <Text
                    fontSize={"12px"}
                    fontWeight={500}
                    color={"#1C2C44"}
                    cursor={"pointer"}
                    onClick={() => setAddCategoryShow(!addCategoryShow)}
                  >
                    {addCategoryShow ? "Select From List" : "Add Custom"}
                  </Text>
                </Flex>
                {!addCategoryShow ? (
                  <CustomSelect
                    value={selectedCategoryInput}
                    placeholder={"Select Software Category"}
                    hideLabel={true}
                    onChange={handleAddCategoryInputChange}
                    rounded={"md"}
                    hideHelperText
                    border="1px solid #D4DCE3"
                    borderRadius="4px"
                    options={categoryList}
                    name="softwareCategory"
                  />
                ) : (
                  <Input
                    type="text"
                    value={selectedCategoryInput}
                    onChange={(e) => handleAddCategoryInputChange(e)}
                  />
                )}
                {selectedCategoryInputError && (
                  <FormHelperText color={"red.500"}>
                    {selectedCategoryInputError}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl>
                <FormLabel>Description</FormLabel>
                <TextArea
                  minHeight="12em"
                  mt={"0rem"}
                  p={"5px"}
                  value={addSoftwareCommentInput}
                  fontSize={"14px"}
                  fontWeight={"400"}
                  lineHeight={"20px"}
                  color={"var(--text-font-color)"}
                  onChange={(e) => setAddSoftwareCommentInput(e.target.value)}
                  hideLabel={true}
                  hideHelperText={true}
                />
              </FormControl>
            </Box>
          </Modal>
        </Box>
        <Flex
          p={"16px 24px"}
          borderTop="1px solid #E6E7E9"
          justifyContent={"flex-end"}
          gap={"20px"}
        >
          {!loading && (
            <Button
              title="Cancel"
              onClick={props.closeCreateForm}
              variant={buttonVariants.greyLinkButton}
              id="software-specialisation-configuration-cancel"
            />
          )}
          <Button
            onClick={() =>
              configurationSave(
                !props.selectedConfiguration.bundleStatus ? "Saved" : false
              )
            }
            isLoading={loading}
            title="Save System Grid"
            variant={buttonVariants.brandPrimaryOutline}
            id="software-specialisation-configuration-save-system-grid"
          />
          {(!props.selectedConfiguration.bundleStatus ||
            props.selectedConfiguration.bundleStatus === "Saved") && (
            <Button
              isLoading={loading}
              variant={buttonVariants.brandPrimary}
              title="Save & Publish"
              onClick={() => {
                if (!props.selectedConfiguration.bundleStatus) {
                  configurationSave("Published");
                } else if (
                  props.selectedConfiguration.bundleStatus === "Saved"
                ) {
                  const publishDefault = true;
                  configurationSave(false, publishDefault);
                }
              }}
              id="software-specialisation-configuration-save-and-publish-system-grid"
            />
          )}
        </Flex>
      </Box>
    </Box>
  );
};

export default CreateConfiguration;
