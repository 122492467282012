import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Checkbox,
  Divider,
  Flex,
  Image,
  Spinner,
  Stack,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Button,
  Select as CustomSelect,
  MoreFilterButton,
  Loader,
  SearchList,
  TipsLinkButton,
  SystemTag,
  MoreFilterDrawer,
  Link,
  TableTextWithEllipsis,
} from "@coachbar/shared-components";
import CustomPopover from "@coachbar/shared-components/src/components/CustomPopover/CustomPopover";
import SearchBar from "@coachbar/shared-components/src/components/SearchBar/SearchBar";
import SelectFilterWithIcon from "@coachbar/shared-components/src/components/Select/SelectFilterWithIcon";
import {
  scrollbarCSS,
  statusColors,
  tableCss,
  userRoleEnums,
} from "@coachbar/shared-components/src/constants/constantValues";
import ColumnMoveSvg from "@coachbar/shared-components/src/images/column-move.svg";
import columnPosition from "@coachbar/shared-components/src/images/column-position.svg";
import exportIcon from "@coachbar/shared-components/src/images/export-icon.svg";
import StatusIcon from "@coachbar/shared-components/src/images/status-icon.svg";
import UserIcon from "@coachbar/shared-components/src/images/user-icon.svg";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ProfileNoRecordsFound from "../../../components/ProfileNoRecordsFound";
import { providers } from "../../../constants/moduleNames";
import sortingUpDown from "../../../image/sorting.svg";
import sortingDown from "../../../image/sortingDown.svg";
import sortingUp from "../../../image/sortingUp.svg";
import { useRBAC } from "../../../services/customHook";
import {
  downloadFromUrl,
  getUserInfo,
  notification,
} from "../../../services/utility";
import { getGeneralSettings } from "../../generalSettings/generalSettingsActions";
import { getAllUsers } from "../../settings/usersAndRoles/usersAndRolesActions";
import {
  createTag,
  getTagListBySearch,
} from "../../systemTag/systemTagActions";
import {
  exportProviderData,
  getProvidersList,
  updateProvider,
  updateProviderTagList,
} from "./providersListActions";
import { useNavigate } from "react-router-dom";

const TIPS_LINK_PAGE_MY_PROVIDER =
  "https://support.coachbar.io/support/solutions/articles/153000018988-getting-started-guide-software-consultant#My-Providers";

const ProvidersList = () => {
  const userInfo = getUserInfo();
  const navigate = useNavigate();
  const { hasActionPermission } = useRBAC();
  let moduleName = providers;
  let permissions = {
    add: hasActionPermission(moduleName, "add"),
    edit: hasActionPermission(moduleName, "edit"),
    view: hasActionPermission(moduleName, "view"),
    delete: hasActionPermission(moduleName, "delete"),
    export: hasActionPermission(moduleName, "export"),
    assigned: hasActionPermission(moduleName, "assigned"),
    fullAccess: hasActionPermission(moduleName, "Full_Access"),
  };
  const getVisibleColumns = () => {
    return localStorage.getItem("visibleColumns")
      ? JSON.parse(localStorage.getItem("visibleColumns"))
      : {};
  };

  useEffect(() => {
    if (userInfo.role === userRoleEnums.user) {
      navigate("/my-providers/invitations");
    }
  }, [navigate, userInfo.role]);

  const [sorting, setSorting] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const tableContainerRef = useRef(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [refetch, setRefetch] = useState(false);
  const [categories, setCategories] = useState([]);
  const [statues, setStatues] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedStatues, setSelectedStatues] = useState([]);
  const [isFeatured, setIsFeatured] = useState("all");
  const [cellLoading, setCellLoading] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [hasStatus, setHasStatus] = useState(false);
  const [hasUsers, setHasUsers] = useState(false);
  const [dataCount, setDataCount] = useState(0);
  const [columnVisibility, setColumnVisibility] = useState(getVisibleColumns());
  const [displayColumnVisibility, setDisplayColumnVisibility] = useState(
    getVisibleColumns()
  );
  const [appliedExtraFilterCount, setAppliedExtraFilterCount] = useState(0);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [extraFilters, setExtraFilters] = useState({
    systemTags: [],
  });
  const [systemTags, setSystemTags] = useState([]);

  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    onOpen: onStatusOpen,
    onClose: onStatusClose,
    isOpen: isStatusOpen,
  } = useDisclosure();
  const {
    onOpen: onColumnSelectionOpen,
    onClose: onColumnSelectionClose,
    isOpen: isColumnSelectionOpen,
  } = useDisclosure();

  const {
    onOpen: onExportOpen,
    onClose: onExportClose,
    isOpen: isExportOpen,
  } = useDisclosure();

  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      fetchCategories();
      fetchStatues();
      fetchUsers();
      fetchSystemTags();
    }
  }, []);

  useEffect(() => {
    setNextToken(null);
    let extraFilterCount = 0;
    if (extraFilters?.systemTags?.length > 0) extraFilterCount++;
    setAppliedExtraFilterCount(extraFilterCount);
    setRefetch((prev) => !prev);
  }, [extraFilters]);

  const cellLoadingComponent = (
    <span style={{ position: "relative" }}>
      <Spinner
        position={"absolute"}
        top="40%"
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="brand.100"
        size={"xs"}
        ml="4px"
      />
    </span>
  );

  const fetchCategories = async () => {
    await getGeneralSettings("scCategory").then((res) => {
      if (res?.code === 200) {
        setCategories(res?.data?.scPartnerCategoryList || []);
      }
    });
  };

  const fetchStatues = async () => {
    await getGeneralSettings("status").then((res) => {
      setHasStatus(true);
      if (res?.code === 200) {
        setStatues(res?.data?.partnerStatusList || []);
        setSelectedStatues([
          { id: "", name: "Unassigned", isChecked: false },
          ...(res?.data?.partnerStatusList
            ? res?.data?.partnerStatusList.map((x) => ({
                name: `${x.name}`,
                id: x.id,
                isChecked: false,
              }))
            : []),
        ]);
      }
    });
  };

  const fetchUsers = async () => {
    await getAllUsers().then((data) => {
      setUsers(data);
      setSelectedUsers([
        { id: "", name: "Unassigned", isChecked: false },
        ...(data.map((x) => ({
          name: `${x.firstName} ${x.lastName}`,
          id: x.id,
          isChecked: false,
        })) || []),
      ]);
      setHasUsers(true);
    });
  };
  const fetchSystemTags = async () =>
    getTagListBySearch().then((data) => {
      data?.length > 0 && setSystemTags(data);
      return data;
    });

  let categoryOptions = useMemo(() => {
    return categories.map((x) => ({ label: x.name, value: x.id }));
  }, [categories]);

  let statusOptions = useMemo(() => {
    let options = statues.map((status, i) => {
      return { ...status, style: statusColors[i % statusColors.length] };
    });

    return options.map((op) => ({
      label: op.name,
      value: op.id,
      style: op.style,
    }));
  }, [statues]);

  let managerOptions = useMemo(() => {
    return users.map((x) => ({
      label: `${x.firstName} ${x.lastName}`,
      value: x.id,
    }));
  }, [users]);

  let commonOptions = [{ label: "Unassigned", value: "Unassigned" }];

  const onSelectChange = async (row, type, value) => {
    let apiData = {};
    apiData[type] = value === "Unassigned" ? null : value;
    setCellLoading(`${row.original.id}-${type}`);
    try {
      await updateProvider(row.original.id, apiData).then((res) => {
        if (res?.data?.code === 200) {
          setNextToken("");
          notification(res.data.message);
          setRefetch((prevRefetch) => !prevRefetch);
          // setRefetch(!refetch);
        }
        setCellLoading("");
      });
    } catch (error) {
      setCellLoading("");
    }
  };

  const onFilterChange = (type, values) => {
    if (type === "users") {
      setSelectedUsers(values);
    } else if (type === "statuses") {
      setSelectedStatues(values);
    } else if (type === "isFeatured") {
      setIsFeatured(values);
    }
    setNextToken("");
    refetchList();
  };

  const refetchList = () => {
    setLoading(true);
    // setRefetch(!refetch);
    setRefetch((prevRefetch) => !prevRefetch);
  };

  const checkCanRowEdit = (rowData) => {
    return (
      permissions.fullAccess ||
      (permissions.hasOwnProperty("edit") &&
      permissions.hasOwnProperty("assigned") &&
      permissions.edit &&
      permissions.assigned
        ? userInfo.id === rowData.assignedUser
        : permissions.hasOwnProperty("edit") && permissions.edit
        ? true
        : false)
    );
  };

  const defaultColumns = useMemo(() => {
    let cols = [
      {
        id: "companyName",
        enableSorting: true,
        accessorKey: "spCompanyName",
        header: "Company Name",
        cell: ({ row }) => {
          return (
            <Link
              to={`/my-providers/${row.original.id}`}
              decoration="none"
              _hover={{ textDecoration: "none" }}
              maxW="350px"
            >
              <Flex
                alignItems={"center"}
                position={"relative"}
                justifyContent={"left"}
                width={"max-content"}
                maxW="350px"
              >
                <TableTextWithEllipsis
                  text={row.original.spCompanyName}
                  maxChars={25}
                  textContainerProps={{
                    mr: "52px",
                    color: "#0C94AC",
                    fontSize: "14px",
                    fontWeight: "500",
                    lineHeight: "20px",
                  }}
                />
                <Tag
                  className="tag provider-list-tag"
                  p="1px 4px 1px 4px"
                  border="1px solid #E6E7E9"
                  borderRadius={"4px"}
                  color="#9EA5AD"
                  fontWeight={"500"}
                  lineHeight={"16px"}
                  fontSize={"10px"}
                  bg="#fff"
                  w="45px"
                  ml={2}
                  position={"absolute"}
                  right={"0px"}
                  visibility={"hidden"}
                >
                  Preview
                </Tag>
              </Flex>
            </Link>
          );
        },
      },
      {
        id: "email",
        enableSorting: true,
        accessorKey: "spEmail",
        header: "Email",
        cell: ({ row }) => (
          <Text whiteSpace={"nowrap"}>{row.original.spEmail}</Text>
        ),
      },
      {
        id: "programType",
        enableSorting: true,
        accessorKey: "partnerProgramtype",
        header: "Partner Type",
        cell: ({ row }) => (
          <TableTextWithEllipsis
            text={row.original?.partnerProgramtype}
            maxChars={20}
          />
        ),
      },
      {
        id: "category",
        accessorKey: "category",
        enableSorting: true,
        header: "Provider Type",
        cell: ({ row }) => {
          let isLoading = cellLoading === `${row.original.id}-category`;
          return (
            <Flex>
              <CustomSelect
                hideHelperText
                hideLabel={true}
                options={[...commonOptions, ...categoryOptions]}
                value={
                  row.original.category
                    ? row.original.category
                    : commonOptions[0].value
                }
                disable={isLoading || !checkCanRowEdit(row.original)}
                onChange={(e) => {
                  onSelectChange(row, "category", e.target.value);
                }}
              />
              {isLoading && cellLoadingComponent}
            </Flex>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          let category1 = rowA.original.category
            ? categoryOptions.find((x) => x.value === rowA.original.category)
                ?.label || ""
            : "";
          let category2 = rowB.original.category
            ? categoryOptions.find((x) => x.value === rowB.original.category)
                ?.label || ""
            : "";
          return category1.localeCompare(category2);
        },
      },
      {
        id: "assignedUser",
        accessorKey: "assignedUser",
        header: "Provider Manager",
        enableSorting: true,
        cell: ({ row }) => {
          let isLoading = cellLoading === `${row.original.id}-assignedUser`;
          let user = users.find((x) => x.id === row.original.assignedUser);
          return (
            <Flex gap={"4px"} width={"fit-content"}>
              {row.original?.assignedUser && user ? (
                <Avatar
                  mr="2px"
                  fontSize={"10px"}
                  p="6px"
                  bg="#E7FAFD"
                  className="avatar"
                  color={"#0A768A"}
                  borderColor={"#CEEAEE"}
                  border={"1px solid"}
                  size="sm"
                  verticalAlign={"bottom"}
                  mt={"5px"}
                  name={`${user?.firstName[0]} ${user?.lastName[0]}`}
                />
              ) : (
                <Avatar bg="#BFC3C9" size="sm" mt={"5px"} className="avatar" />
              )}

              <CustomSelect
                hideHelperText
                hideLabel={true}
                options={[...commonOptions, ...managerOptions]}
                value={
                  row.original.assignedUser
                    ? row.original.assignedUser
                    : commonOptions[0].value
                }
                disable={isLoading || !checkCanRowEdit(row.original)}
                onChange={(e) => {
                  onSelectChange(row, "assignedUser", e.target.value);
                }}
                maxW="148px"
              />
              {isLoading && cellLoadingComponent}
            </Flex>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          let assignedUser1 = rowA.original.assignedUser
            ? managerOptions.find((x) => x.value === rowA.original.assignedUser)
                ?.label || ""
            : "";
          let assignedUser2 = rowB.original.assignedUser
            ? managerOptions.find((x) => x.value === rowB.original.assignedUser)
                ?.label || ""
            : "";
          return assignedUser1.localeCompare(assignedUser2);
        },
      },
      {
        id: "tags",
        accessorKey: "tags",
        header: "Tags",
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <SystemTag
              getTagListBySearch={getTagListBySearch}
              createTag={createTag}
              handleTagSubmit={handleTagSubmit(row.original.id)}
              selectedPartnerTags={
                row.original.tagIds?.map((tagId) => {
                  const currTag = systemTags.find((tag) => tag.id === tagId);
                  return {
                    label: currTag?.name || tagId,
                    value: currTag?.id || tagId,
                  };
                }) || []
              }
              permissions={{
                ...permissions,
                edit: checkCanRowEdit(row.original),
              }}
              displayLimit={2}
              containerProps={{ wrap: "nowrap" }}
              moduleForId="provider-list"
            />
          );
        },
      },
      {
        id: "status",
        accessorKey: "status",
        header: "Status",
        enableSorting: true,
        cell: ({ row }) => {
          let isLoading = cellLoading === `${row.original.id}-status`;
          let selectedValue =
            statusOptions.find((x) => x.value === row.original.status) || {};
          return (
            <Flex justifyContent={"flex-start"} width={"160px"}>
              {row.original.providerStatus !== "Archive" ? (
                <>
                  <Box
                    className="value-display"
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Text
                      width={"max-content"}
                      style={{
                        backgroundColor: selectedValue?.style?.background || "",
                        color: selectedValue?.style?.color || "#000",
                        padding: "3px 12px",
                        borderRadius: "12px",
                        fontWeight: "500",
                      }}
                    >
                      {selectedValue?.label || commonOptions[0].label}
                      <span style={{ paddingLeft: "4px" }}>
                        <ChevronDownIcon boxSize={5} color={"#7E8792"} />
                      </span>
                    </Text>
                  </Box>
                  <Box display={"none"} className="select">
                    <CustomSelect
                      hideHelperText
                      hideLabel={true}
                      options={[...commonOptions, ...statusOptions]}
                      value={
                        row.original.status
                          ? row.original.status
                          : commonOptions[0].value
                      }
                      disable={isLoading || !checkCanRowEdit(row.original)}
                      onChange={(e) => {
                        onSelectChange(row, "status", e.target.value);
                      }}
                    />
                  </Box>
                  {isLoading && cellLoadingComponent}
                </>
              ) : (
                <Text pl="8px">Archived</Text>
              )}
            </Flex>
          );
        },
        sortingFn: (rowA, rowB, columnId) => {
          let status1 = rowA.original.status
            ? statusOptions.find((x) => x.value === rowA.original.status)
                ?.label || ""
            : "";
          let status2 = rowB.original.status
            ? statusOptions.find((x) => x.value === rowB.original.status)
                ?.label || ""
            : "";
          return status1.localeCompare(status2);
        },
      },
      {
        id: "leadCount",
        accessorKey: "scTotalLeadCount",
        enableSorting: true,
        header: "Leads",
        cell: ({ row }) => {
          let color =
            row.original.hasOwnProperty("scTotalLeadCount") &&
            row.original.scTotalLeadCount > 0
              ? "#0C94AC"
              : "#000";
          return (
            <Text fontSize={"14px"} color={color} whiteSpace={"nowrap"}>
              {row.original.scTotalLeadCount
                ? row.original.scTotalLeadCount
                : 0}
            </Text>
          );
        },
      },
      {
        id: "dealCount",
        accessorKey: "scTotalDealCount",
        enableSorting: true,
        header: "Deals",
        cell: ({ row }) => {
          let color =
            row.original.hasOwnProperty("scTotalDealCount") &&
            row.original.scTotalDealCount > 0
              ? "#0C94AC"
              : "#000";
          return (
            <Text fontSize={"14px"} color={color} whiteSpace={"nowrap"}>
              {row.original.scTotalDealCount
                ? row.original.scTotalDealCount
                : 0}
            </Text>
          );
        },
      },
    ];
    return cols;
  }, [
    statusOptions,
    categoryOptions,
    managerOptions,
    cellLoading,
    permissions,
  ]);

  let columns = [...defaultColumns];

  //react-query has an useInfiniteQuery hook just for this situation!
  const { data, fetchNextPage, isFetching } = useInfiniteQuery(
    [
      "table-data",
      refetch,
      selectedStatues,
      hasStatus,
      selectedUsers,
      hasUsers,
      isFeatured,
      globalFilterValue,
    ], //adding status key causes table to fetch new data based on status and persist thos data.
    async ({ pageParam = 0 }) => {
      if (hasStatus && hasUsers) {
        const fetchedData = await getProvidersList({
          limit: 25,
          nextToken: nextToken,
          search: globalFilterValue,
          assignedUser:
            users.length + 1 ===
            selectedUsers.filter((x) => x.isChecked).map((x) => x.id).length
              ? undefined
              : selectedUsers.filter((x) => x.isChecked).map((x) => x.id),

          status:
            statues.length + 1 ===
            selectedStatues.filter((x) => x.isChecked).map((x) => x.id).length
              ? undefined
              : selectedStatues.filter((x) => x.isChecked).map((x) => x.id),
          featured: isFeatured,
          systemTags: extraFilters.systemTags,
        }); //pretend api call
        if (fetchedData) {
          setNextToken(fetchedData.nextToken || null);
          setLoading(false);
          setCellLoading("");
          setDataCount(fetchedData?.count || 0);
          return fetchedData.partnerList;
        }
        return [];
      }
    },
    {
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: 1000, // set cachtime so when user come back to particular tab it refetch the latest data.
    }
  );

  //we must flatten the array of arrays from the useInfiniteQuery hook
  const flatData = React.useMemo(() => {
    return data?.pages?.flatMap((page) => page) ?? [];
  }, [data]);

  const totalFetched = flatData.length;

  // called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        if (
          scrollHeight - scrollTop - clientHeight < 100 &&
          !isFetching &&
          nextToken
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched]
  );

  const getSavedColumns = () => {
    return localStorage.getItem("providerListColumnOrder")
      ? localStorage.getItem("providerListColumnOrder").split(";")
      : columns.map((column) => column.id); //must start out with populated columnOrderOptions so we can splice
  };

  const [displayColumnOrder, setDisplayColumnOrder] = useState(
    getSavedColumns()
  );

  const [columnOrderOptions, setColumnOrder] = useState(getSavedColumns());

  const reorderColumn = (draggedColumnId, targetColumnId, tempColumnOrder) => {
    tempColumnOrder.splice(
      tempColumnOrder.indexOf(targetColumnId),
      0,
      tempColumnOrder.splice(tempColumnOrder.indexOf(draggedColumnId), 1)[0]
    );
    return JSON.parse(JSON.stringify([...tempColumnOrder]));
  };

  const table = useReactTable({
    data: flatData,
    columns,
    state: {
      columnVisibility: displayColumnVisibility,
      sorting,
      columnOrder: displayColumnOrder,
    },
    onSortingChange: setSorting,
    onColumnVisibilityChange: setDisplayColumnVisibility,
    // this is to restrict search on specific fields
    onColumnOrderChange: setDisplayColumnOrder,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const onSave = () => {
    localStorage.setItem("visibleColumns", JSON.stringify(columnVisibility));
    localStorage.setItem(
      "providerListColumnOrder",
      columnOrderOptions.join(";")
    );
    setDisplayColumnOrder([...columnOrderOptions]);
    setDisplayColumnVisibility(columnVisibility);
    onColumnSelectionClose();
  };

  const onCancel = () => {
    setColumnOrder([...displayColumnOrder]);
    setColumnVisibility({ ...displayColumnVisibility });
    onColumnSelectionClose();
  };

  const onClickExport = async (withFilters = false) => {
    setExportLoading(true);
    let data = await exportProviderData(
      withFilters
        ? {
            search: globalFilterValue,
            assignedUser:
              users.length + 1 ===
              selectedUsers.filter((x) => x.isChecked).map((x) => x.id).length
                ? undefined
                : selectedUsers.filter((x) => x.isChecked).map((x) => x.id),

            status:
              statues.length + 1 ===
              selectedStatues.filter((x) => x.isChecked).map((x) => x.id).length
                ? undefined
                : selectedStatues.filter((x) => x.isChecked).map((x) => x.id),
            featured: isFeatured,
            systemTags: extraFilters.systemTags,
          }
        : {}
    );
    setExportLoading(false);
    if (data.code === 200) {
      downloadFromUrl(data.data, "Providers List");
      notification(data.message);
      onExportClose();
    }
  };

  const columnHandleChange = (checked, column) => {
    let newColumns = { ...columnVisibility };
    newColumns[column.id] = checked;
    setColumnVisibility(newColumns);
  };

  const DraggableColumn = ({ column, table }) => {
    const [, dropRef] = useDrop({
      accept: "column",
      drop: (draggedColumn) => {
        const newColumnOrder = reorderColumn(
          draggedColumn.id,
          column.id,
          columnOrderOptions
        );
        setColumnOrder([...newColumnOrder]);
      },
    });

    const [{ isDragging }, dragRef, previewRef] = useDrag({
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      item: () => column,
      type: "column",
    });

    return (
      <Box
        ref={dropRef}
        style={{ opacity: isDragging ? 0.5 : 1 }}
        draggable={column.id !== "companyName"}
      >
        <div ref={previewRef}>
          <div ref={dragRef}>
            <Box
              p="6px 8px"
              key={column.id}
              className="px-1"
              fontSize={"12px"}
              border={"1px solid"}
              borderColor={"#E6E7E9"}
              borderRadius={"4px"}
              mt="4px"
              mb="4px"
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Checkbox
                isDisabled={column.id === "companyName" ? true : false}
                cursor={column.id === "companyName" ? "text" : "pointer"}
                isChecked={
                  columnVisibility.hasOwnProperty(column.id)
                    ? columnVisibility[column.id]
                    : true
                }
                onChange={(e) => {
                  columnHandleChange(e.target.checked, column);
                }}
                _checked={{
                  "& .chakra-checkbox__control": {
                    background: "#0C94AC",
                    border: "#0C94AC",
                  },
                }}
                _disabled={{
                  "& .chakra-checkbox__control": {
                    background: "#0C94AC !important",
                    border: "#0C94AC !important",
                  },
                  "& svg": {
                    color: "#fff",
                  },
                }}
              >
                <Text
                  fontSize={"15px"}
                  fontWeight={500}
                  color={"var(--title-black-text)"}
                >
                  {column.columnDef.header}
                </Text>
              </Checkbox>
              <Image
                ml="1px"
                float={"right"}
                verticalAlign={"sub"}
                display={"inline-block"}
                src={ColumnMoveSvg}
                cursor={"pointer"}
              />
            </Box>
          </div>
        </div>
      </Box>
    );
  };

  const onSearchChangeOperations = () => {
    setLoading(true);
    setNextToken(null);
  };

  const handleMoreFilterModalClose = () => {
    setShowMoreFilters(false);
  };

  const handleTagSubmit = (providerId) => async (updatedTagList, cb) => {
    const payloadTags = updatedTagList.map((tag) => ({
      id: tag.value,
      name: tag.label,
    }));
    const [res] = await Promise.all([
      updateProviderTagList(payloadTags, providerId),
      fetchSystemTags(),
    ]);
    if (res?.success) {
      setNextToken(null);
      setRefetch((prev) => !prev);
    }
    cb(res);
  };

  return (
    <>
      <Box p="24px" pb="0px" className="provider-list-wrapper">
        <Flex
          justifyContent={"space-between"}
          w={"100%"}
          alignItems={"center"}
          mt={"0px !important"}
          mb={"16px"}
        >
          <Flex alignItems={"center"} gap={"20px"}>
            <SearchBar
              debounce
              value={globalFilterValue}
              onChange={(value) => {
                setGlobalFilterValue(value);
              }}
              onSearchChangeOperations={onSearchChangeOperations}
              placeholder="Search Providers..."
              height="36px"
            />
            <Divider
              borderRadius={"3px"}
              bgColor="#E6E7E9"
              width={"2.5px"}
              h="36px"
            />
            <Stack>
              <CustomPopover
                labelComponent={
                  <Flex
                    border={"1px solid"}
                    borderColor={"brandGray.101"}
                    borderRadius={"4px"}
                    p={"5px 10px"}
                    cursor={"pointer"}
                    bg={"#FFFFFF"}
                    height="36px"
                    alignItems={"center"}
                  >
                    <SelectFilterWithIcon
                      icon={UserIcon}
                      title={"Provider Manager"}
                      items={selectedUsers}
                      fontWeight={450}
                    />
                  </Flex>
                }
                onOpen={onOpen}
                onClose={onClose}
                showPopover={isOpen}
              >
                <SearchList
                  showAllSelect={true}
                  allowSearch
                  onSelectAll={() => {}}
                  itemList={selectedUsers.length === 1 ? [] : selectedUsers}
                  modalBtnClick={
                    selectedUsers.length === 1
                      ? false
                      : (newItems) => {
                          if (
                            JSON.stringify(newItems) !==
                            JSON.stringify(selectedUsers)
                          ) {
                            onFilterChange("users", newItems);
                          }
                          onClose();
                        }
                  }
                  isModalBtnDisabled={false}
                  isPopoverOpen={isOpen}
                  btnText="Submit"
                  moduleForId="provider-list-users"
                />
              </CustomPopover>
            </Stack>
            <Stack>
              <CustomPopover
                labelComponent={
                  <Flex
                    border={"1px solid"}
                    borderColor={"brandGray.101"}
                    borderRadius={"4px"}
                    p={"5px 10px"}
                    cursor={"pointer"}
                    bg={"#FFFFFF"}
                    height="36px"
                    alignItems={"center"}
                  >
                    <SelectFilterWithIcon
                      icon={StatusIcon}
                      title={"Status"}
                      items={selectedStatues}
                      fontWeight={450}
                    />
                  </Flex>
                }
                onOpen={onStatusOpen}
                onClose={onStatusClose}
                showPopover={isStatusOpen}
              >
                <SearchList
                  showAllSelect={true}
                  onSelectAll={() => {}}
                  itemList={selectedStatues.length === 1 ? [] : selectedStatues}
                  modalBtnClick={
                    selectedStatues.length === 1
                      ? false
                      : (newItems) => {
                          if (
                            JSON.stringify(newItems) !==
                            JSON.stringify(selectedStatues)
                          ) {
                            onFilterChange("statuses", newItems);
                          }
                          onStatusClose();
                        }
                  }
                  isModalBtnDisabled={false}
                  isPopoverOpen={isStatusOpen}
                  btnText="Submit"
                  moduleForId="provider-list-statuses"
                />
              </CustomPopover>
            </Stack>
            <MoreFilterButton
              appliedMoreFilterCount={appliedExtraFilterCount}
              setShowMoreFilters={setShowMoreFilters}
              showMoreFilters={showMoreFilters}
            />
          </Flex>
          <Flex gap={"20px"}>
            <TipsLinkButton
              to={TIPS_LINK_PAGE_MY_PROVIDER}
              linkProps={{ py: "6px" }}
            />
            {permissions.export && (
              <CustomPopover
                showPopover={isExportOpen}
                onClose={onExportClose}
                onOpen={() => {}}
                matchWidth={true}
                contentWidth={"180px"}
                offset={[-50, 0]}
                bodyStyle={{
                  padding: "0px",
                }}
                labelComponent={
                  <Button
                    variant="spThemeOutline"
                    leftIcon={<Image src={exportIcon} />}
                    color={"#6B7280"}
                    bg="#F9FAFB"
                    border="1px solid #E6E7E9"
                    borderRadius="4px"
                    title={"Export"}
                    isLoading={exportLoading}
                    pt="6px !important"
                    pb="6px !important"
                    pl="12px !important"
                    pr="12px !important"
                    onClick={() => {
                      if (
                        globalFilterValue?.length !== 0 ||
                        (!selectedStatues.every((x) => x.isChecked) &&
                          selectedStatues.some((x) => x.isChecked)) ||
                        (!selectedUsers.every((x) => x.isChecked) &&
                          selectedUsers.find((x) => x.isChecked)) ||
                        extraFilters.systemTags.length < systemTags.length ||
                        ["Yes", "No"].includes(isFeatured)
                      ) {
                        onExportOpen();
                      } else {
                        onClickExport();
                      }
                    }}
                    isDisabled={
                      !table.getRowModel().rows ||
                      table.getRowModel().rows.length === 0
                    }
                    id="provider-list-export"
                  />
                }
              >
                <Stack pt="4px" pb="4px" pr="6px" pl="6px">
                  {exportLoading ? (
                    <Stack height={"40px"} p="5px">
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="brand.100"
                        size={"xs"}
                        display={"block"}
                        margin={"auto"}
                      />
                    </Stack>
                  ) : (
                    <>
                      <Stack
                        variant={"none"}
                        color="#718096"
                        isLoading={exportLoading}
                        onClick={() => {
                          onClickExport(true);
                        }}
                      >
                        <Text
                          color={"var(--title-black-text)"}
                          p="8px"
                          textAlign={"center"}
                          cursor={"pointer"}
                        >
                          Export Filtered Data
                        </Text>
                      </Stack>
                      <Stack
                        variant={"none"}
                        color="#718096"
                        isLoading={exportLoading}
                        onClick={() => {
                          onClickExport();
                        }}
                      >
                        <Text
                          color={"var(--title-black-text)"}
                          p="8px"
                          textAlign={"center"}
                          cursor={"pointer"}
                        >
                          Export all data
                        </Text>
                      </Stack>
                    </>
                  )}
                </Stack>
              </CustomPopover>
            )}
          </Flex>
        </Flex>
        {loading ? (
          <Box
            style={{
              height: `calc(100vh - 196px)`,
              display: "flex",
              justifyContent: "center",
            }}
            borderRadius={"6px 6px 0px 0px"}
            border={"1px solid #E6E7E9"}
          >
            <Loader />
          </Box>
        ) : (
          <Box>
            <Flex mb="8px">
              <Text color="#5E6977" fontSize={"12px"}>
                Showing <b>{flatData.length}</b> out of <b>{dataCount} </b>
                entries
              </Text>
            </Flex>
            <Box
              onScroll={(e) => fetchMoreOnBottomReached(e.target)}
              ref={tableContainerRef}
              overflow={"auto"}
              style={{ maxHeight: `calc(100vh - 190px)` }}
              borderRadius={"6px 6px 0px 0px"}
              border={"1px solid #E6E7E9"}
              css={{
                ...scrollbarCSS,
                "& tr:hover": {
                  "& .provider-list-tag": {
                    visibility: "visible",
                  },
                },
              }}
            >
              {table.getRowModel().rows &&
              table.getRowModel().rows.length > 0 ? (
                <Table
                  variant={"simple"}
                  css={{
                    ...tableCss,
                    "& tr td:first-of-type, & tr th:first-of-type": {
                      paddingLeft: "24px",
                    },
                    "& tr td:last-of-type, & tr th:last-of-type": {
                      paddingRight: "12px",
                    },
                  }}
                >
                  <Thead bg="#F9FAFB">
                    {table.getHeaderGroups().map((headerGroup) => (
                      <Tr
                        h={"40px"}
                        bg={"#F9FAFB"}
                        key={headerGroup.id}
                        position={"sticky"}
                        top={0}
                        zIndex={10}
                      >
                        {headerGroup.headers.map((header) => {
                          return (
                            <Th
                              key={header.id}
                              colSpan={header.colSpan}
                              color={"#667180"}
                              fontSize={"14px"}
                              fontWeight={"700"}
                              lineHeight={"16px"}
                            >
                              {header.isPlaceholder ? null : (
                                <Box display={"flex"}>
                                  <Text display={"flex"}>
                                    {flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}
                                    {header.column.getCanSort() && (
                                      <Box
                                        width={"16px"}
                                        height={"16px"}
                                        minW={"16px"}
                                        cursor={"pointer"}
                                        ml="16px"
                                        {...{
                                          onClick:
                                            header.column.getToggleSortingHandler(),
                                        }}
                                      >
                                        {{
                                          asc: (
                                            <Image
                                              src={sortingUp}
                                              width={"16px"}
                                              height={"16px"}
                                            />
                                          ),
                                          desc: (
                                            <Image
                                              src={sortingDown}
                                              width={"16px"}
                                              height={"16px"}
                                            />
                                          ),
                                        }[header.column.getIsSorted()] ??
                                          (header.column.getCanSort() ? (
                                            <Image
                                              src={sortingUpDown}
                                              width={"16px"}
                                              height={"16px"}
                                            />
                                          ) : null)}
                                      </Box>
                                    )}
                                  </Text>
                                </Box>
                              )}
                            </Th>
                          );
                        })}
                        <Th
                          position={"sticky"}
                          right={"0"}
                          p={"12px"}
                          w={"40px"}
                          borderLeft={"1px solid #E6E7E9"}
                          bg="#F5F5F5"
                          zIndex={2}
                        >
                          <CustomPopover
                            showPopover={isColumnSelectionOpen}
                            onOpen={onColumnSelectionOpen}
                            onClose={onCancel}
                            contentWidth={"240px"}
                            labelComponent={
                              <Image
                                onClick={() => onColumnSelectionOpen()}
                                src={columnPosition}
                                cursor={"pointer"}
                                width={"16px"}
                                maxW={"16px"}
                                height={"16px"}
                                margin={"auto"}
                              />
                            }
                            bodyStyle={{
                              border: "1px solid rgb(230, 231, 233)",
                              borderRadius: "4px",
                              padding: "0",
                            }}
                            offset={[-100, 10]}
                          >
                            <Box width={"100%"}>
                              <Box width={"100%"} pt="0px" p="12px">
                                <Text
                                  fontWeight={"500"}
                                  fontSize={"12px"}
                                  color={"#5E6977"}
                                  mb={"20px"}
                                >
                                  Customize Columns
                                </Text>
                                <Box
                                  p="6px 8px"
                                  className="px-1"
                                  fontSize={"12px"}
                                  border={"1px solid"}
                                  borderColor={"#E6E7E9"}
                                  borderRadius={"4px"}
                                  mt="4px"
                                  mb="4px"
                                >
                                  <Checkbox
                                    isDisabled={true}
                                    cursor={"text"}
                                    isChecked={true}
                                    onChange={(e) => {}}
                                    _checked={{
                                      "& .chakra-checkbox__control": {
                                        background: "#0C94AC",
                                        border: "#0C94AC",
                                      },
                                    }}
                                    _disabled={{
                                      "& .chakra-checkbox__control": {
                                        background: "#0C94AC !important",
                                        border: "#0C94AC !important",
                                      },
                                      "& svg": {
                                        color: "#fff",
                                      },
                                    }}
                                    opacity="0.5"
                                  >
                                    <Text
                                      fontSize={"15px"}
                                      fontWeight={500}
                                      color={"var(--title-black-text)"}
                                    >
                                      Company Name
                                    </Text>
                                  </Checkbox>
                                </Box>
                                <DndProvider backend={HTML5Backend}>
                                  {columnOrderOptions.map((column) => {
                                    if (column !== "companyName") {
                                      return (
                                        <DraggableColumn
                                          table={table}
                                          column={table
                                            .getAllLeafColumns()
                                            .find((x) => x.id === column)}
                                        />
                                      );
                                    }
                                  })}
                                </DndProvider>
                              </Box>
                              <Box
                                width={"100%"}
                                textAlign={"end"}
                                borderTop={"1px solid"}
                                borderColor={"#E6E7E9"}
                                p="12px"
                              >
                                <Button
                                  title="Cancel"
                                  variant="discard"
                                  onClick={() => {
                                    onCancel();
                                  }}
                                  id="provider-list-cancel"
                                />
                                <Button
                                  ml="20px"
                                  title="Save"
                                  onClick={() => {
                                    onSave();
                                  }}
                                  id="provider-list-save"
                                />
                              </Box>
                            </Box>
                          </CustomPopover>
                        </Th>
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody>
                    {table.getRowModel().rows.map((row) => {
                      return (
                        <Tr key={row.id}>
                          {row.getVisibleCells().map((cell) => {
                            return (
                              <Td
                                key={cell.id}
                                color={"#2D3748"}
                                fontSize={"14px"}
                                lineHeight={"20px"}
                                fontWeight={"400"}
                                style={
                                  ["status", "category"].includes(
                                    cell.column.id
                                  )
                                    ? {
                                        paddingRight: "5px",
                                        paddingLeft: "24px",
                                      }
                                    : {}
                                }
                                opacity={
                                  row.original.providerStatus === "Archive"
                                    ? "0.5"
                                    : "1"
                                }
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              ) : (
                <Box
                  style={{
                    height: `calc(100vh - 230px)`,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <ProfileNoRecordsFound title={"No Providers Found"} />
                </Box>
              )}
            </Box>
          </Box>
        )}
        <MoreFilterDrawer
          isOpen={showMoreFilters}
          onModalClose={handleMoreFilterModalClose}
          setFilters={setExtraFilters}
          moreFilters={[
            {
              title: "Tags",
              key: "systemTags",
              options: systemTags.map((tag) => ({
                name: tag.name,
                value: tag.id,
                isChecked: extraFilters.systemTags.includes(tag.id),
              })),
              allowSearch: true,
            },
          ]}
        />
      </Box>
    </>
  );
};

export default ProvidersList;
