// ProfileMetrics.js
import {
  Box,
  Center,
  Flex,
  Progress,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { Button, Loader, TipsLinkButton } from "@coachbar/shared-components";
import { scrollbarCSS } from "@coachbar/shared-components/src/constants/constantValues";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TIPS_LINK_PAGE_COMPANY_PROFILE } from "../../../constants/profileSetup";
import { getUserInfo } from "../../../services/utility";
import { getPartnerProviderList } from "../../myProviders/providersList/providersListActions";
import PartnerProfileCard from "./PartnerProfileCard";
import {
  getPartnerProfileCompletionPercentage,
  getPartnerProfileRequirementPercentage,
  getProfileCompletionStatus,
} from "./profileMetricsAction";

const ProfileMetrics = () => {
  const dispatch = useDispatch();
  const { loading, profileCompletionData } = useSelector(
    (state) => state.profileMetrics
  );
  const userInfo = getUserInfo();
  const navigate = useNavigate();

  const [totalCompletionPercentage, setTotalCompletionPercentage] = useState(0);
  const [providerList, setProviderList] = useState([]);
  const [requirementPercentage, setRequirementPercentage] = useState(undefined);
  const [partnerProfilePercentage, setPartnerProfilePercentage] =
    useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  let initialized = false;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const requests = [
          fetchPartnerProviderList(),
          fetchRequirementPercentage(),
          fetchPartnerProfilePercentage(),
        ];

        await Promise.all(requests);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!initialized) {
      initialized = true;
      fetchData();
    }
  }, []);

  useEffect(() => {
    dispatch(getProfileCompletionStatus());
  }, [dispatch]);

  useEffect(() => {
    if (!profileCompletionData) {
      setTotalCompletionPercentage(0);
      return;
    }
    if (
      profileCompletionData &&
      Object.keys(profileCompletionData).length > 0
    ) {
      setTotalCompletionPercentage(profileCompletionData?.totalPercentage);
    }
  }, [profileCompletionData]);

  const fetchPartnerProviderList = async () => {
    try {
      const fetchedData = await getPartnerProviderList({});
      setProviderList(fetchedData?.partnerList || []);
    } catch (error) {
      console.log("Error in Partner Provider List", error);
    }
  };

  const fetchRequirementPercentage = async () => {
    try {
      const fetchedData = await getPartnerProfileRequirementPercentage();
      setRequirementPercentage(
        fetchedData?.data?.requirementPercentageList || []
      );
    } catch (error) {
      console.log("Error in Fetching Partner Requirement Percentage", error);
    }
  };

  const fetchPartnerProfilePercentage = async () => {
    try {
      const fetchedData = await getPartnerProfileCompletionPercentage({});
      setPartnerProfilePercentage(
        fetchedData?.data?.providerPercentageList || []
      );
    } catch (error) {
      console.log("Error in Fetching Partner Profile Percentage", error);
    }
  };

  return (
    <Box
      bg={"#F9FAFB"}
      maxH={"calc(100vh - 64px)"}
      p={"24px"}
      overflow={"auto"}
      css={scrollbarCSS}
    >
      {loading || isLoading ? (
        <Center minH={"calc(100vh - 180px)"}>
          <Loader />
        </Center>
      ) : (
        <>
          <Text
            py={"36px"}
            color={"var(--title-black-text)"}
            fontSize={"18px"}
            fontWeight={700}
            textAlign={"center"}
          >
            Hi {userInfo?.firstName} {userInfo?.lastName}!
          </Text>
          <Flex
            bg={"white"}
            boxShadow="0px 0px 4px 0px #E6E7E9"
            rounded={"8px"}
            p={"24px"}
            maxW={"fit-content"}
            mx={"auto"}
          >
            <Box p={"16px"} textAlign={"center"} w={"200px"} mr={"20px"}>
              <Text
                color={"var(--title-black-text)"}
                fontSize={"40px"}
                fontWeight={600}
              >
                {totalCompletionPercentage}%
              </Text>
              <Text color={"#556781"} fontSize={"16px"} fontWeight={600}>
                of your Profile is completed
              </Text>
            </Box>
            <Box>
              <Progress
                colorScheme="brand"
                size="md"
                value={totalCompletionPercentage}
                rounded={"13px"}
              />
              <Text
                color={"var(--title-black-text)"}
                fontSize={"14px"}
                fontWeight={600}
                lineHeight={"20px"}
                pt={"20px"}
              >
                Optimize Your Profile for Better Visibility.
              </Text>
              <Text
                color={"#5E6977"}
                fontSize={"12px"}
                fontWeight={450}
                lineHeight={"18px"}
                mt={"8px"}
              >
                Complete your profile with accurate and engaging information to
                attract a wider audience and maximize your visibility.
              </Text>
              <Flex
                justifyContent={"flex-start"}
                alignItems={"end"}
                pt={"20px"}
                gap={"20px"}
              >
                {totalCompletionPercentage < 100 && (
                  <Button
                    variant={"spThemeOutline"}
                    color={"#0C94AC"}
                    p={"8px 16px"}
                    h={"auto"}
                    border={"1px solid"}
                    borderColor={"#0C94AC"}
                    type="button"
                    title="Complete Profile"
                    onClick={() => navigate("/profile-setup/company-profile")}
                    id="profile-setup-complete-profile"
                  />
                )}
                <TipsLinkButton
                  to={TIPS_LINK_PAGE_COMPANY_PROFILE}
                  linkProps={{ py: "11px" }}
                />
                {/* <CustomRequirementPopover
                  popoverTriggerChildren={
                    <Text
                      color={"#0C94AC"}
                      fontSize={"14px"}
                      fontWeight={500}
                      lineHeight={"20px"}
                      cursor={"pointer"}
                    >
                      Why it is important to complete profile?
                    </Text>
                  }
                  popoverHeader={"Why Complete Profile"}
                  popoverBody={
                    <Box>
                      <Text>
                        The Partner Profiles define the conditions for the
                        interchange of data in an ALE environment with a trading
                        partner using the IDoc interface. To communicate with a
                        partner using the IDoc interface, you must have a
                        partner profile. You first need to generate the partner
                        profiles in the central system for all child systems.
                      </Text>
                      <Text mt={"8px"}>
                        Partner Profiles are the requirements for data exchange.
                        You define who can exchange messages with the SAP ERP
                        system using a specified port.
                      </Text>
                    </Box>
                  }
                /> */}
              </Flex>
            </Box>
          </Flex>
          {providerList && providerList?.length > 0 && (
            <Box py={"40px"}>
              <Text
                color={"var(--title-black-text)"}
                fontSize={"16px"}
                fontWeight={700}
                lineHeight={"24px"}
              >
                Partner(s) Profiles
              </Text>
              <SimpleGrid columns={2} spacing={"20px"} mt={"24px"}>
                {providerList?.map((provider) => {
                  return (
                    <PartnerProfileCard
                      provider={provider}
                      requirementPercentage={requirementPercentage}
                      partnerProfilePercentage={partnerProfilePercentage}
                    />
                  );
                })}
              </SimpleGrid>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ProfileMetrics;
