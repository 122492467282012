import { Box, Center, Flex } from "@chakra-ui/react";
import { Loader } from "@coachbar/shared-components";
import Bar from "@coachbar/shared-components/src/components/Charts/Bar";
import ChartLegends from "@coachbar/shared-components/src/components/Charts/ChartLegends";
import ChartContainer from "@coachbar/shared-components/src/screens/dashboard/ChartContainer";
import ChartGrid from "@coachbar/shared-components/src/screens/dashboard/ChartGrid";
import ChartHeader from "@coachbar/shared-components/src/screens/dashboard/ChartHeader";
import NoDataChart from "@coachbar/shared-components/src/screens/dashboard/NoDataChart";
import { useChartsProvider } from "@coachbar/shared-components/src/screens/dashboard/chartContext";
import {
  addPreviousValues,
  calculateDaysBetweenDates,
  compareInnerAndGeneralFilters,
  formatDateRange,
  getAppliedFilters,
  getPreviousDateRange,
  getSpecificInnerFilters,
  prepareBarChartData,
  removePreviousValue,
} from "@coachbar/shared-components/src/services/dashboardUtility/utility";
import { deepCopyObject } from "@coachbar/shared-components/src/services/utility";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getRevenueCustomerType } from "../financialActions";

const RevenueCustomerType = ({ chartData }) => {
  const { selectedDate, appliedFilters, fixFilters } = useChartsProvider();

  let initialized = false;
  const widgetInfo = chartData;
  const [isLoading, setIsLoading] = useState(false);
  const [chartType, setChartType] = useState("");
  const [customerTypes, setCustomerTypes] = useState([]);
  const [isCompared, setIsCompared] = useState(false);
  const [totalLeads, setTotalLeads] = useState([]);
  const [prevDateRange, setPrevDateRange] = useState(undefined);
  const [daysBetweenDates, setDaysBetweenDates] = useState(undefined);
  const [innerFilters, setInnerFilters] = useState(
    getSpecificInnerFilters(fixFilters, chartData.filters)
  );
  const [innerDate, setInnerDate] = useState(deepCopyObject({}));

  const [
    formattedDateForGrid,
    formattedPrevDateForGrid,
    formattedDateForChart,
    formattedPrevDateForChart,
  ] = useMemo(() => {
    let conDate = deepCopyObject(
      innerDate?.to && innerDate?.from ? innerDate : selectedDate
    );
    // Date format to show in Grid
    const date = formatDateRange(conDate || null, "grid");
    const prevDate = formatDateRange(prevDateRange || null, "grid");
    // Different date format to show in chart tooltip
    const formattedDate = formatDateRange(conDate || null, "chart");
    const formattedPrevDate = formatDateRange(prevDateRange || null, "chart");
    return [date, prevDate, formattedDate, formattedPrevDate];
  }, [innerDate, selectedDate, prevDateRange]);

  const formattedLeadStatusDataForGrid = useMemo(
    () =>
      customerTypes.map((data) => {
        return {
          id: data.id,
          value: data.value,
          previousValue: data.previousValue || 0,
        };
      }),
    [customerTypes]
  );

  const formattedTrendDataForLegends = useMemo(
    () =>
      customerTypes.map((x) => ({
        legend: x?.id,
        color: x?.color,
        isPrev: x?.isPrev,
      })),
    [customerTypes]
  );

  let innerAppliedFilters = useMemo(() => {
    return getAppliedFilters(innerFilters);
  }, [innerFilters]);

  const constructRequestData = ({ date }) => {
    let requestData = compareInnerAndGeneralFilters(
      appliedFilters,
      innerAppliedFilters,
      Object.keys(fixFilters),
      date,
      selectedDate
    );

    return requestData;
  };

  const clearPreviousData = () => {
    const copyOfLeadByStatus = [...customerTypes];
    setCustomerTypes(removePreviousValue(copyOfLeadByStatus));
    setPrevDateRange(null);
    setDaysBetweenDates(null);
  };

  const onToogleCompare = () => {
    if (isCompared) {
      setIsCompared(false);
      clearPreviousData();
    } else {
      setIsCompared(true);
      setIsCompared(true);
      let conDate = deepCopyObject(
        innerDate?.to && innerDate?.from ? innerDate : selectedDate
      );
      const previousDateRange = getPreviousDateRange(conDate);
      const numberOfDays = calculateDaysBetweenDates(conDate);
      setDaysBetweenDates(numberOfDays);
      setPrevDateRange(previousDateRange);
      const requestData = constructRequestData({ date: previousDateRange });
      fetchLeadStatusData(requestData, true, customerTypes);
    }
  };

  const fetchLeadStatusData = useCallback(
    async (requestData, isCompared = false, customerTypes) => {
      setIsLoading(true);
      const res = await getRevenueCustomerType(requestData);
      setIsLoading(false);
      const customersRes = res?.data?.dashboard;

      if (customersRes) {
        const { preparedStatusData, totalValue } =
          prepareBarChartData(customersRes);

        if (isCompared) {
          const updatedData = addPreviousValues(customersRes, customerTypes);
          setCustomerTypes(updatedData);
        } else {
          setTotalLeads(totalValue);
          setCustomerTypes(preparedStatusData);
        }
      }
    },
    []
  );

  useEffect(() => {
    if (!initialized) {
      initialized = true;
      clearPreviousData();
      const requestData = constructRequestData({ date: innerDate });
      fetchLeadStatusData(requestData);
    }
  }, [
    fetchLeadStatusData,
    innerDate,
    selectedDate,
    appliedFilters,
    innerAppliedFilters,
  ]);

  return (
    <>
      {widgetInfo?.isVisible && (
        <ChartContainer>
          <ChartHeader
            title={widgetInfo?.title}
            showAll
            chartData={chartData}
            chartType={chartType}
            onChartTypeChange={() => {
              if (chartType === "") {
                setChartType("table");
              } else {
                setChartType("");
              }
            }}
            onToogleCompare={onToogleCompare}
            isCompared={isCompared}
            filters={innerFilters}
            onSubmitFilters={(newFilters, date) => {
              setInnerFilters(newFilters);
              setInnerDate(date);
            }}
            date={innerDate}
            generalDate={selectedDate}
          />
          {isLoading ? (
            <Center h={"80%"}>
              <Loader />
            </Center>
          ) : !totalLeads || totalLeads <= 0 ? (
            <Box h={"304px"}>
              <NoDataChart />
            </Box>
          ) : chartType === "table" ? (
            <ChartGrid
              data={formattedLeadStatusDataForGrid}
              headings={
                isCompared
                  ? [
                      [
                        "Customer Type",
                        formattedDateForGrid,
                        formattedPrevDateForGrid,
                      ],
                    ]
                  : [["Customer Type", formattedDateForGrid]]
              }
            />
          ) : (
            <Flex h={"400px"} direction={"column"} justifyContent={"center"}>
              <Box h={"340px"} overflow={"visible"}>
                <Bar
                  chartFor="RevenueByCustomerType"
                  bottomLegend={"Revenue"}
                  keys={isCompared ? ["value", "previousValue"] : ["value"]}
                  isCompared={isCompared}
                  data={customerTypes}
                  prevDate={formattedPrevDateForChart}
                  selectedDate={formattedDateForChart}
                  daysBetweenDates={daysBetweenDates}
                  marginLeft={115}
                />
              </Box>
              <ChartLegends data={formattedTrendDataForLegends} />
            </Flex>
          )}
        </ChartContainer>
      )}
    </>
  );
};

export default RevenueCustomerType;
