import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../services/axiosInstance";
import { getErrorMessage, handleError } from "../../services/utility";
import { notification } from "@coachbar/shared-components/src/services/utility";

export const getPersonalNotificationList = createAsyncThunk(
  "get/personalNotificationList",
  async (userId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `/notificationSetting/byUser/${userId}`
      );
      return data?.data?.notificationSetting?.moduleWiseList || {};
    } catch (error) {
      // return custom error message from API if any
      notification(getErrorMessage(error), "error");
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updatePersonalNotificationStatus = async (
  notificationId,
  isActive,
  statusType
) => {
  try {
    const key =
      statusType === "emailStatus" ? "isEnableEmail" : "isEnableInternal";
    const { data } = await axiosInstance.put(
      `/notificationSetting/${statusType}/${notificationId}`,
      { [key]: isActive }
    );
    if (!data.success) throw new Error(data.message);
    if (data.success) notification(data.message);
    return data.success;
  } catch (error) {
    handleError(error);
  }
};

export const getNotificationCountForBell = async (userType) => {
  try {
    const { data } = await axiosInstance.get("notificationCount/count", {
      params: { userType },
    });
    if (!data.success) {
      throw new Error(data.message);
    }
    return data;
  } catch (error) {
    // handleError(error);
  }
};

export const getNotificationListForBell = async (
  limit = 25,
  nextToken,
  userType
) => {
  try {
    const { data } = await axiosInstance.post(`notification/list`, {
      limit,
      nextToken: nextToken,
      userType,
    });
    if (!data.success) {
      throw new Error(data.message);
    }
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const markAsReadBellNotification = async (notificationIds = []) => {
  try {
    const requestData = {
      isMarkAllRead: !notificationIds.length,
      notificationIds,
    };

    const { data } = await axiosInstance.put(
      `notification/markAsRead`,
      requestData
    );
    if (!data.success) {
      throw new Error(data.message);
    }
    return data;
  } catch (error) {
    handleError(error);
  }
};
