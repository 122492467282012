import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Flex, Image, Spacer, Text } from "@chakra-ui/react";
import { Button, Link } from "@coachbar/shared-components";
import { useSelector, useDispatch } from "react-redux";
import { updateTriggerSubmit } from "./companyProfile/companyProfileSlice";
import { getUserInfo } from "../../services/utility";
import { planRoute } from "../../constants/redirectionRoutes";
import {
  getCompanyProfile,
  profilePublishRequest,
} from "../profileSetup/companyProfile/companyProfileActions";
import IconCheckVerified from "../../image/check-verified.svg";
import IconClockRed from "../../image/clock-red.png";
import { systemGrid } from "../../constants/moduleNames";
import { buttonVariants } from "@coachbar/shared-components/src/constants/constantValues";
import CustomTooltip from "@coachbar/shared-components/src/components/Tooltip/CustomTooltip";

const ProfileFooter = ({
  activeStep,
  setActiveStep,
  permissions,
  totalStep,
  moduleName,
}) => {
  let userInfo = getUserInfo();
  const navigate = useNavigate();
  const {
    loading: companyProfileLoading,
    companyProfile,
    imageUploading: companyProfileImageUploading,
  } = useSelector((state) => state.companyProfile);
  const { loading: locationLoading, partnerLocationList } = useSelector(
    (state) => state.companyLocation
  );
  const { loading: videoLoading, videoList } = useSelector(
    (state) => state.video
  );
  const { loading: mediaLoading, mediaList } = useSelector(
    (state) => state.media
  );
  const { loading: urlDirectoryLoading, urlDirectoryList } = useSelector(
    (state) => state.urlDirectory
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const loaders = [
    locationLoading,
    videoLoading,
    mediaLoading,
    urlDirectoryLoading,
  ];
  const lists = [partnerLocationList, videoList, mediaList, urlDirectoryList];

  const loader = loaders[activeStep - 1];

  const handlePreviousStep = () => {
    setActiveStep(Math.max(0, activeStep - 1));
  };

  const handleSkipStep = () => {
    setActiveStep(Math.min(totalStep - 1, activeStep + 1));
  };

  const publishProfile = () => {
    if (userInfo?.planId && userInfo?.planId === 1 && !userInfo?.isGuestUser) {
      navigate(planRoute);
    } else {
      setLoading(true);
      profilePublishRequest({ profileStatus: "Awaiting" }).then((response) => {
        if (response.success) {
          dispatch(getCompanyProfile());
        }
        setLoading(false);
      });
    }
  };

  return (
    <Box
      w="full"
      bg={"#fff"}
      position={"sticky"}
      bottom={0}
      zIndex={2}
      mt={"0px !important"}
      borderTop={"2px solid #F9FAFB"}
      boxShadow={"1px 0px 4px #E5E7EB"}
      p="16px 24px"
    >
      <Flex gap={4} alignItems="center">
        {activeStep !== 0 && (
          <Button
            title="Back"
            onClick={handlePreviousStep}
            variant={buttonVariants.greyLinkButton}
            id="profile-setup-back"
          />
        )}

        <Spacer />
        {userInfo && userInfo.tenantId && (
          <Link
            to={`/preview-profile/${
              userInfo.slug ? userInfo.slug : userInfo.tenantId
            }`}
            target="_blank"
          >
            <Button
              title="Preview Profile"
              type="button"
              variant={buttonVariants.greyOutline}
              id="profile-setup-preview-profile"
              isDisabled={!companyProfile}
            />
          </Link>
        )}

        {moduleName !== systemGrid && (
          <>
            {activeStep !== 0 || !permissions.edit ? (
              activeStep !== totalStep - 1 && (
                <Button
                  title="Continue"
                  isLoading={loader}
                  onClick={handleSkipStep}
                  variant={buttonVariants.brandPrimary}
                  id="profile-setup-continue"
                />
              )
            ) : (
              <Button
                isLoading={
                  companyProfileLoading || companyProfileImageUploading
                }
                title={"Save & Continue"}
                onClick={() => dispatch(updateTriggerSubmit())}
                variant={buttonVariants.brandPrimary}
                id="profile-setup-save-and-continue"
              />
            )}
          </>
        )}
        <ProfileStatusElement
          loading={loading}
          permissions={permissions}
          companyProfile={companyProfile}
          publishProfile={publishProfile}
        />
      </Flex>
    </Box>
  );
};

const ProfileStatusElement = ({
  permissions,
  companyProfile,
  publishProfile,
  loading,
}) => {
  if (
    permissions.add &&
    companyProfile &&
    (companyProfile?.profileStatus === "Draft" ||
      companyProfile?.profileStatus === "Rejected")
  ) {
    return (
      <Button
        title="Publish Profile"
        onClick={publishProfile}
        isLoading={loading}
        variant={buttonVariants.greyFill}
        id="profile-setup-publish-profile"
      />
    );
  }

  if (companyProfile?.profileStatus === "Awaiting") {
    return (
      <CustomTooltip
        label="It usually takes 2 working days to process requests. You will be notified when it will be published"
        placement="left"
        width="380px"
        maxWidth="400px"
        hasArrow
      >
        <Flex alignItems={"center"}>
          <Text color={"#9F580A"} fontSize={"14px"} fontWeight={600} mr={"8px"}>
            Profile Request Sent
          </Text>
          <Image src={IconClockRed} h={"16px"} />
        </Flex>
      </CustomTooltip>
    );
  }

  if (companyProfile?.profileStatus === "Published") {
    return (
      <Flex alignItems={"center"}>
        <Text color={"#2C885C"} fontSize={"14px"} fontWeight={600} mr={"8px"}>
          Profile Published
        </Text>
        <Image src={IconCheckVerified} h={"16px"} />
      </Flex>
    );
  }

  return null;
};

export default ProfileFooter;
