// Media.js
import {
  Avatar,
  Box,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Image,
  Spacer,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ACTION_ICON_BUTTON_TYPE,
  ActionIconButton,
  AlertDialog,
  Button,
  FileUpload,
  Loader,
  Modal,
  Table,
  TableTextWithEllipsis,
  TextArea,
  TextBox,
  TipsLinkButton,
} from "@coachbar/shared-components";
import CustomRequirementPopover from "@coachbar/shared-components/src/components/CustomPopover/CustomRequirementPopover";
import {
  buttonVariants,
  scrollbarCSS,
} from "@coachbar/shared-components/src/constants/constantValues";
import NoRecordFoundScreen from "@coachbar/shared-components/src/screens/NoRecordFoundScreen";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Notification from "../../../components/Notification";
import { TIPS_LINK_PAGE_COMPANY_PROFILE } from "../../../constants/profileSetup";
import {
  permissionTypes,
  userRoleEnums,
} from "../../../constants/usersAndRoles";
import {
  maxFileSize,
  mediaTypeAllowed,
  requiredField,
} from "../../../constants/validationMessages";
import InfoOutline from "../../../image/Info-outline.svg";
import CoachBarLogoIcon from "../../../image/coachbar-logo-icon.png";
import emptyMedia from "../../../image/emptyMedia.png";
import fallbackImage from "../../../image/fallbackImage.png";
import greenCheckFill from "../../../image/green-check.svg";
import infoIcon from "../../../image/yellow-info-icon.svg";
import {
  getCurrentPartnerData,
  getPreSignedUrl,
  hasPermissionManagerSpecialCase,
  uploadImage,
} from "../../../services/utility";
import { getPartnerProviderList } from "../../myProviders/providersList/providersListActions";
import { getActiveRequirements } from "../../serviceOffering/serviceOfferingActions";
import { getProfileCompletionStatus } from "../profileMetrics/profileMetricsAction";
import { addMedia, getPartnerMediaList, updateMedia } from "./mediaActions";
import { clearMessage } from "./mediaSlice";

const MAX_FILE_SIZE_MEDIA = 1000; // in KB;
const fieldType = "media-type";
const TITLE_LABEL = "Title";
const MEDIA_LABEL = "Asset";
const DESC_LABEL = "Description";
const tableHead = [[MEDIA_LABEL, TITLE_LABEL, DESC_LABEL]];

const Media = ({ permissions }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const { loading, error, mediaList, success } = useSelector(
    (state) => state.media
  );

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    resetField,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });

  const [tableBody, setTableBody] = useState([]);
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [readMoreData, setReadMoreData] = useState({});

  useEffect(() => {
    let tBody = [];
    for (let index = 0; index < mediaList.length; index++) {
      let directoryItem = [];
      directoryItem.push(
        getImage(mediaList[index]["thumbnailUrl"], mediaList[index]["url"])
      );
      directoryItem.push(
        <TableTextWithEllipsis maxChars={30} text={mediaList[index]["title"]} />
      );
      directoryItem.push(
        getDescription(mediaList[index]["description"], mediaList[index])
      );
      if (permissions.edit || permissions.delete)
        directoryItem.push(getActions(mediaList[index], index));
      tBody.push(directoryItem);
    }
    setTableBody([...tBody]);
    //eslint-disable-next-line
  }, [mediaList, readMoreData, permissions]);

  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      //eslint-disable-next-line
      initialized = true;
      dispatch(
        getPartnerMediaList(process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID)
      );
      getPartnerList();
    }
  }, []);

  const onReadMoreClick = (data) => {
    let newReadMoreData = { ...readMoreData };
    if (newReadMoreData[data.id]) {
      delete newReadMoreData[data.id];
    } else {
      newReadMoreData[data.id] = true;
    }
    setReadMoreData(newReadMoreData);
  };

  const getDescription = (desc, data) => {
    if (desc) {
      let wrapedDesc = desc;
      if (desc.length > 88 && !readMoreData[data.id]) {
        wrapedDesc = desc.slice(0, 88) + "...";
      }
      return (
        <span style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
          {wrapedDesc}{" "}
          {desc.length > 0 && (
            <span
              style={{ cursor: "pointer", color: "#0C94AC" }}
              onClick={() => onReadMoreClick(data)}
            >
              {desc.length > 150 &&
                (readMoreData[data.id] ? `Show Less` : `Show More`)}
            </span>
          )}
        </span>
      );
    } else {
      return "";
    }
  };

  function getFileExtension(url) {
    const filename = url.substring(url.lastIndexOf("/") + 1);
    const extension = filename.substring(filename.lastIndexOf(".") + 1);
    return (
      <>
        {extension && (
          <Text
            bg={extension === "pdf" ? "#FCECEF" : "#EBF6F1"}
            color={extension === "pdf" ? "#86263B" : "#216645"}
            fontSize={"10px"}
            lineHeight={"16px"}
            fontWeight={600}
            px={2}
            py={1}
            rounded={"15px"}
          >
            {extension.toUpperCase()}
          </Text>
        )}
      </>
    );
  }

  const getImage = (thumbnailUrl, url) => {
    return (
      <HStack gap={2} whiteSpace={"nowrap"}>
        <Image
          src={thumbnailUrl}
          fallbackSrc={fallbackImage}
          objectFit="cover"
          boxSize="40px"
          rounded={"8px"}
        />
        {url && getFileExtension(url)}
      </HStack>
    );
  };

  const getActions = (mediaData = null, index) => {
    return (
      <HStack
        key={"HStack-" + index}
        float="right"
        cursor="pointer"
        gap={"20px"}
      >
        {permissions.edit && (
          <ActionIconButton
            type={ACTION_ICON_BUTTON_TYPE.EDIT}
            onClickAction={(e) => {
              editMedia(e, mediaData);
            }}
          />
        )}
        {permissions.delete && (
          <ActionIconButton
            type={ACTION_ICON_BUTTON_TYPE.DELETE}
            onClickAction={(e) => {
              onAlertOpenAction(e, mediaData);
            }}
            containerProps={{ marginLeft: "0px !important" }}
          />
        )}
      </HStack>
    );
  };

  const submitForm = (data) => {
    if (!selectedPartners.length) {
      setSelectedPartnersError(true);
      return;
    }
    let slectedId = selectedMedia || data.id;
    data["url"] = mediaImagePath;
    if (selectedPartners.length)
      data.partnerTenantIdList = [...selectedPartners];
    if (slectedId) {
      dispatch(
        updateMedia({
          mediaId: slectedId,
          requestParams: data,
        })
      ).then((responseData) => {
        if (responseData.payload.success && responseData.payload.data) {
          if (!mediaList || (mediaList && mediaList?.length < 1)) {
            dispatch(getProfileCompletionStatus());
          }
          dispatch(getPartnerMediaList(currentPartner));
          onModalClose();
        }
      });
    } else {
      dispatch(addMedia(data)).then((responseData) => {
        if (responseData.payload.success && responseData.payload.data) {
          if (!mediaList || (mediaList && mediaList?.length < 1)) {
            dispatch(getProfileCompletionStatus());
          }
          dispatch(getPartnerMediaList(currentPartner));
          onModalClose();
        }
      });
    }
  };

  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    onOpen: onAlertOpen,
    isOpen: isAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  const [selectedMedia, setSelectedMedia] = useState(null);

  const editMedia = (event, mediaData) => {
    let skipKeys = ["modified", "created", "id", "tenantId"];
    setSelectedMedia(mediaData?.id);
    setMediaImagePath(mediaData?.url);
    for (const key in mediaData) {
      if (mediaData.hasOwnProperty(key) && !skipKeys.includes(key)) {
        if (mediaData?.description) {
          setDescriptionLength(mediaData?.description.length);
        }
        setValue(key, mediaData[key]);
      }
    }
    setSelectedPartners([
      ...(mediaData?.partnerTenantIdList?.length
        ? mediaData?.partnerTenantIdList
        : []),
    ]);
    onOpen();
  };

  const onModalClose = () => {
    if (selectedMedia) {
      setSelectedMedia(null);
    }
    setMediaImagePath("");
    setSelectedPartners([]);
    setDescriptionLength(0);
    setSelectedPartnersError(false);
    reset();
    onClose();
  };

  const [mediaToDelete, setMediaToDelete] = useState(null);

  const onAlertCloseAction = () => {
    if (mediaToDelete?.id) {
      dispatch(
        updateMedia({
          mediaId: mediaToDelete?.id,
          requestParams: { ...mediaToDelete, partnerTenantIdList: [] },
        })
      ).then((responseData) => {
        if (responseData.payload.success && responseData.payload.data) {
          if (!mediaList || (mediaList && mediaList?.length < 1)) {
            dispatch(getProfileCompletionStatus());
          }
          dispatch(getPartnerMediaList(currentPartner));
          setMediaToDelete(null);
          onAlertClose();
        }
      });
    }
  };

  const onAlertOpenAction = (event, mediaDetails) => {
    setMediaToDelete(mediaDetails);
    onAlertOpen();
  };

  const [mediaImagePath, setMediaImagePath] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validateMedia = (value) => {
    if ((!value || value.length < 1) && !mediaImagePath) {
      return requiredField.replace("$Field$", "Media");
    }
    return validateFile(value);
  };

  const validateFile = (files) => {
    const fileObj = files && files[0];

    if (!fileObj || !fileObj?.type) {
      return true;
    }

    const [type, subType] = fileObj.type.split("/");
    if (!type || (type !== "image" && subType !== "pdf")) {
      return mediaTypeAllowed;
    }

    const fsMb = fileObj.size / 1024;

    if (fsMb > MAX_FILE_SIZE_MEDIA) {
      setValue("url", "");
      return maxFileSize.replace("$MB$", MAX_FILE_SIZE_MEDIA + "KB");
    }
    if (!isSubmitting) {
      uploadFile(fileObj);
    }
    return true;
  };

  const uploadFile = (fileObj) => {
    setIsLoading(true);
    let fileExt = fileObj.name.split(".").pop();
    let filePath = fieldType + "-" + Date.now() + "." + fileExt;
    getPreSignedUrl(filePath).then((responseData) => {
      if (responseData.data && responseData.data.success) {
        const preSignedUrl = responseData.data?.data?.signedUrl;
        uploadImageFileToS3(fileObj, preSignedUrl);
      } else {
        setIsLoading(false);
      }
    });
  };

  const uploadImageFileToS3 = async (fileObj, preSignedUrl) => {
    uploadImage(fileObj, preSignedUrl).then((responseData) => {
      if (responseData.status === 200) {
        let imageUrl = preSignedUrl.split("?")[0];
        setMediaImagePath(imageUrl);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const handleDescriptionChange = (event) => {
    setDescriptionLength(event.target.value.length);
  };
  let headers = tableHead[0];
  if (permissions.edit || permissions.delete) headers = [...headers, ...[""]];

  const [partnerList, setPartnerList] = useState([]);
  const [assignedPartnerList, setAssignedPartnerList] = useState([]);
  const [partnerListLoading, setPartnerListLoading] = useState(true);
  const [currentPartner, setCurrentPartner] = useState(
    process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID || ""
  );
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [currPartnerMediaRecommendation, setCurrPartnerMediaRecommendation] =
    useState(0);
  const [selectedPartnersError, setSelectedPartnersError] = useState(false);

  const getPartnerList = async () => {
    setPartnerListLoading(true);
    const partnerApiData = await getPartnerProviderList({});
    setPartnerList(partnerApiData?.partnerList || []);
    const assignedPartners =
      partnerApiData?.partnerList.filter(
        (partner) => partner.assignedUser === userInfo.id
      ) || [];
    setAssignedPartnerList(assignedPartners);
    setPartnerListLoading(false);
  };

  const handleUpdateModalIconClick = (selectedTenantId) => {
    setSelectedPartners((prevList) => {
      const isSelected = selectedPartners.includes(selectedTenantId);
      if (!isSelected) {
        if (selectedPartnersError) setSelectedPartnersError(false);
        return [...prevList, selectedTenantId];
      }
      return prevList.filter(
        (partnerTenantId) => partnerTenantId !== selectedTenantId
      );
    });
  };

  const getCurrPartnerMediaRecommendation = async (tenantId) => {
    const companyData = await getActiveRequirements(tenantId);
    const companyMediaRquirements =
      companyData?.data?.requirement?.minimumNumberOfMedia;
    if (companyMediaRquirements)
      setCurrPartnerMediaRecommendation(companyMediaRquirements);
    else setCurrPartnerMediaRecommendation(0);
  };

  const getMediaAddButton = (spTenantId, btnProps = {}) => {
    const ButtonComp = (
      <Button
        {...btnProps}
        title={"Add Media"}
        onClick={() => {
          setSelectedPartners([spTenantId]);
          onOpen();
        }}
        variant={buttonVariants.brandPrimary}
        id="media-add"
      />
    );
    if (
      hasPermissionManagerSpecialCase(
        permissionTypes.add,
        spTenantId,
        userInfo,
        permissions,
        partnerList
      )
    )
      return ButtonComp;
    return null;
  };

  const getAddModalPartnerList = () => {
    if (userInfo.role === userRoleEnums.manager) return assignedPartnerList;
    return partnerList;
  };

  const handleError = () => {
    if (!selectedPartners.length) setSelectedPartnersError(true);
    if (errors && errors["url"]) {
      resetField("url");
      handleSubmit(submitForm)();
    }
  };

  return (
    <Box
      bg={"white"}
      p={"16px 0 24px"}
      w={"100%"}
      h={"calc(100vh - 216px)"}
      rounded={"8px"}
      overflow={"auto"}
      boxShadow={"1px 0px 4px #E5E7EB"}
      css={scrollbarCSS}
    >
      {error || success ? (
        <Notification
          title={error ? error : success}
          status={error ? "error" : "success"}
          onCloseToast={() => dispatch(clearMessage())}
        />
      ) : (
        ""
      )}
      <Flex alignItems={"center"} p="0 24px">
        <Text
          color={"var(--title-black-text)"}
          fontSize={"14px"}
          lineHeight={"20px"}
          fontWeight={"700"}
          mr={"8px"}
        >
          Media Gallery
        </Text>
        <Text
          as={"span"}
          fontWeight={"500"}
          color={"var(--text-font-color)"}
          fontSize={"12px"}
          pt={"4px"}
        >
          (Maximum limit 20)
        </Text>
        <Spacer />
        <Flex alignItems={"center"} gap={"20px"}>
          <TipsLinkButton to={TIPS_LINK_PAGE_COMPANY_PROFILE} />
          {mediaList?.length > 0 && getMediaAddButton(currentPartner)}
        </Flex>
      </Flex>
      {partnerListLoading ? (
        <Loader />
      ) : (
        <Tabs position="relative" variant="unstyled">
          <TabList
            width={"100%"}
            overflow={"auto"}
            p="0 24px"
            gap="20px"
            borderBottom={"1px solid var(--chakra-colors-atlantic-100)"}
            css={scrollbarCSS}
          >
            <Tab
              whiteSpace={"nowrap"}
              fontSize={"14px"}
              fontWeight={600}
              color={"#7284A0"}
              marginBottom={0}
              p="8px 0"
              onClick={() => {
                setCurrentPartner(
                  process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
                );
                dispatch(
                  getPartnerMediaList(
                    process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
                  )
                );
                getCurrPartnerMediaRecommendation(
                  process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
                );
              }}
            >
              <Tooltip label="Coachbar" placement="bottom">
                <Avatar
                  size="xs"
                  name="Coachbar"
                  src={CoachBarLogoIcon}
                  mr={"4px"}
                />
              </Tooltip>
              Coachbar
            </Tab>
            {partnerList?.map((partner, i) => {
              return (
                <Tab
                  key={partner.referenceTenantId + i}
                  whiteSpace={"nowrap"}
                  fontSize={"14px"}
                  fontWeight={600}
                  color={"#7284A0"}
                  marginBottom={0}
                  p="8px 0"
                  onClick={() => {
                    setCurrentPartner(partner.referenceTenantId);
                    dispatch(getPartnerMediaList(partner.referenceTenantId));
                    getCurrPartnerMediaRecommendation(
                      partner.referenceTenantId
                    );
                  }}
                >
                  <Avatar
                    size="xs"
                    name={partner.spCompanyName}
                    src={partner?.logo}
                    mr={"4px"}
                  />
                  {partner.spCompanyName}
                </Tab>
              );
            })}
          </TabList>
          <TabIndicator
            height="3px"
            bg="#0C94AC"
            top={"38px"}
            borderRadius="2px 2px 0px 0px"
            zIndex={199}
          />
        </Tabs>
      )}
      {currPartnerMediaRecommendation > 0 && (
        <Flex
          p={"10px"}
          justifyContent={"center"}
          alignItems={"center"}
          bg={"#FDFDEA"}
          border={"1px solid #FDF6B2"}
          rounded={"6px"}
          m="20px 16px"
        >
          <Flex alignItems={"center"}>
            <Image h={"14px"} src={infoIcon} mr="8px" />
            <Text color={"#723B13"} fontSize={"12px"} fontWeight={450}>
              There are specific requirements for Partner Profiles to be
              published in the Partner Directory on the{" "}
              <span style={{ fontWeight: 600 }}>
                {getCurrentPartnerData(currentPartner, partnerList)
                  ?.scCompanyName || ""}
              </span>
              site. Please review the
            </Text>
            <Box position={"relative"}>
              <CustomRequirementPopover
                popoverTriggerChildren={
                  <Text
                    color={"#723B13"}
                    fontSize={"12px"}
                    fontWeight={600}
                    lineHeight={"18px"}
                    textDecor={"underline"}
                    cursor={"pointer"}
                    ml={"4px"}
                  >
                    recommendations
                  </Text>
                }
                popoverBody={
                  <Box>
                    Minimum {currPartnerMediaRecommendation} Media required.
                  </Box>
                }
              />
            </Box>
            .
          </Flex>
        </Flex>
      )}
      {loading ? (
        <Center minH={"calc(100vh - 364px)"}>
          <Loader />
        </Center>
      ) : mediaList && mediaList.length > 0 ? (
        <Box m={"24px"} mb="0">
          <Table
            tHead={[headers]}
            tBody={tableBody}
            headingStyles={{
              backgroundColor: "#F9FAFB",
              color: "#5E6977 !important",
            }}
            keepBordersRounded
            keepLastColumnLeftAligned={!permissions.edit || !permissions.delete}
          />
        </Box>
      ) : (
        <Flex
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          h={"calc(100% - 100px)"}
        >
          <NoRecordFoundScreen
            title="No Media Found"
            description={
              getMediaAddButton(currentPartner)
                ? "Try adding media of company using below Call to Action button."
                : ""
            }
            imageUrl={emptyMedia}
          >
            <Box mt={"20px"}>
              {getMediaAddButton(currentPartner, { rounded: "8px" })}
            </Box>
          </NoRecordFoundScreen>
        </Flex>
      )}
      <Modal
        isOpen={isOpen}
        onClose={onModalClose}
        title={selectedMedia ? "Update Media" : "Add New Media"}
        allowCloseButton={true}
        displayFooter={true}
        size={"md"}
        onSaveClick={handleSubmit(submitForm, handleError)}
        loading={loading}
        isDisabled={isLoading}
        saveButtonTitle="Save Media"
      >
        <Stack spacing={2}>
          <form noValidate onSubmit={handleSubmit(submitForm)}>
            <Flex>
              <TextBox
                type="text"
                name={TITLE_LABEL}
                placeholder={TITLE_LABEL}
                maxLength="100"
                required
                innerref={register("title", {
                  required: requiredField.replace("$Field$", TITLE_LABEL),
                })}
                validationErrors={errors}
                widthFull={true}
              />
            </Flex>
            <Flex>
              <TextArea
                placeholder={DESC_LABEL}
                innerref={register("description", {
                  onChange: (e) => handleDescriptionChange(e),
                })}
                maxLength={500}
                helperText={`${descriptionLength}/500`}
                helperTextPosition={"right"}
              />
            </Flex>
            <Flex>
              <FileUpload
                name="Asset"
                acceptedFileTypes="image/*,.pdf"
                required
                innerref={register("url", {
                  validate: validateMedia,
                })}
                validationErrors={errors}
                ratio={5 / 2}
                isLoading={isLoading}
                imgSrc={mediaImagePath}
              />
            </Flex>
            <Flex mb="4px">
              <Text
                fontSize={"12px"}
                color={"#5E6977"}
                fontWeight={600}
                as="legend"
                m="0 4px 4px 0"
              >
                Select the Directory
              </Text>
              <Image
                display={"inline-block"}
                src={InfoOutline}
                w={"16px"}
                mb="4px"
              />
            </Flex>
            <Flex
              spacing={12}
              flexWrap="wrap"
              justifyContent="start"
              gap="15px"
              position={"relative"}
            >
              {hasPermissionManagerSpecialCase(
                permissionTypes.add,
                process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID,
                userInfo,
                permissions,
                partnerList
              ) && (
                <Tooltip label="Coachbar" placement="bottom">
                  <Box
                    border="1px solid #BFC3C9"
                    borderRadius="36px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width={"36px"}
                    marginInlineStart={0}
                    height={"36px"}
                    cursor="pointer"
                    position={"relative"}
                    onClick={() => {
                      handleUpdateModalIconClick(
                        process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
                      );
                    }}
                  >
                    <Avatar
                      size="xs"
                      name="Coachbar"
                      src={CoachBarLogoIcon}
                      iconLabel="coachbar"
                    />
                    {selectedPartners.includes(
                      process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
                    ) ? (
                      <Image
                        position={"absolute"}
                        top="-2px"
                        right="-4px"
                        w={"12px"}
                        h={"12px"}
                        color={"#2C885C"}
                        src={greenCheckFill}
                      />
                    ) : null}
                  </Box>
                </Tooltip>
              )}
              {getAddModalPartnerList().map((partner, i) => (
                <Tooltip
                  key={partner.referenceTenantId + i}
                  label={partner.spCompanyName}
                  placement="bottom"
                >
                  <Box
                    border="1px solid #BFC3C9"
                    borderRadius="36px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width={"36px"}
                    height={"36px"}
                    m="0px !important"
                    cursor="pointer"
                    position={"relative"}
                    onClick={() => {
                      handleUpdateModalIconClick(partner.referenceTenantId);
                    }}
                  >
                    <Avatar
                      size="xs"
                      name="Coachbar"
                      src={partner.logo}
                      iconLabel="ABC"
                    />
                    {selectedPartners.includes(partner.referenceTenantId) ? (
                      <Image
                        position={"absolute"}
                        top="-2px"
                        right="-4px"
                        w={"12px"}
                        h={"12px"}
                        color={"#2C885C"}
                        src={greenCheckFill}
                      />
                    ) : null}
                  </Box>
                </Tooltip>
              ))}
              {selectedPartnersError && (
                <FormControl
                  isInvalid={true}
                  position={"absolute"}
                  bottom={"-16px"}
                  left={"0px"}
                  ml="0px !important"
                >
                  <FormErrorMessage>
                    Please select at least one directory.
                  </FormErrorMessage>
                </FormControl>
              )}
            </Flex>
          </form>
        </Stack>
      </Modal>
      <AlertDialog
        title="Delete Media"
        actionButtonName="Delete"
        isAlertOpen={isAlertOpen}
        onAlertClose={onAlertClose}
        onAlertCloseAction={onAlertCloseAction}
      />
    </Box>
  );
};
export default Media;
