import { InputRightElement, IconButton } from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { buttonVariants } from "../constants/constantValues";

const ShowHidePassword = (props) => {
  return (
    <InputRightElement top={"22px"}>
      <IconButton
        variant={buttonVariants.greyLinkButton}
        aria-label="Show hide password"
        onClick={props.handlePasswordVisibility}
        icon={
          props.showPassword ? (
            <ViewOffIcon color="#9EA5AD" />
          ) : (
            <ViewIcon color="#9EA5AD" />
          )
        }
        id={props.id}
      ></IconButton>
    </InputRightElement>
  );
};

export default ShowHidePassword;
