// ChangePassword.js
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { changePassword } from "./accountActions";
import Notification from "../../components/Notification";
import {
  Button,
  TextBox,
  PasswordStrengthIndicator,
  ShowHidePassword,
  Loader,
} from "@coachbar/shared-components";
import {
  requiredField,
  passwordMisMatch,
  lowerCaseLetter,
  upperCaseLetter,
  specialCharacter,
  passwordMinLength,
  passwordMaxLength,
} from "../../constants/validationMessages";
import {
  upperCaseValidationPattern,
  lowerCaseValidationPattern,
  specialCharacterValidationPattern,
} from "../../constants/validationPatterns";
import {
  Stack,
  InputGroup,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Divider,
  HStack,
  SimpleGrid,
  VStack,
  Box,
} from "@chakra-ui/react";
import { logout, setUserData } from "../auth/authSlice";
import { getUserInfo, setUserInfo } from "../../services/utility";
import {
  changePasswordMsg,
  setPasswordMsg,
} from "../../constants/infoMessages";

const ChangePassword = () => {
  const passwordValidationMessages = {
    lowerCaseLetter,
    upperCaseLetter,
    specialCharacter,
    passwordMinLength,
    passwordMaxLength,
  };

  const { userToken, userInfo } = useSelector((state) => state.auth);
  const [apiState, setAPiState] = useState({
    error: null,
    success: null,
  });
  const { error, success } = apiState;
  const [loading, setLoading] = useState(false);
  const [isChangePassword, setIsChangePassword] = useState(
    userInfo.isPasswordUpdated
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({ mode: "onChange", criteriaMode: "all" });
  const navigate = useNavigate();
  const password = useRef({});
  password.current = watch("newPassword", "");
  const dispatch = useDispatch();

  const submitForm = async (data) => {
    if (data) {
      let requestData = { ...data };
      requestData["accessToken"] = userToken["accessToken"];
      setLoading(true);
      changePassword(requestData).then((responseData) => {
        setLoading(false);
        if (responseData.code && responseData.code === 200) {
          let successMessage = {
            success: isChangePassword ? changePasswordMsg : setPasswordMsg,
            error: null,
          };
          reset();
          setAPiState((prevState) => ({
            ...prevState,
            ...successMessage,
          }));
          let userDetails = getUserInfo();
          let updatedUserDetails = {
            ...userDetails,
            isPasswordUpdated: responseData.data.user.isPasswordUpdated,
          };
          setUserInfo(updatedUserDetails);
          dispatch(setUserData());
        } else {
          let errorMessage = { success: null, error: responseData };
          setAPiState((prevState) => ({
            ...prevState,
            ...errorMessage,
          }));
        }
      });
    }
  };

  const clearMessage = () => {
    let userDetails = getUserInfo();
    if (success && isChangePassword) {
      dispatch(logout());
      navigate("/login");
    } else if (localStorage.getItem("isRedirect")) {
      localStorage.removeItem("isRedirect");
    }
    setIsChangePassword(userDetails?.isPasswordUpdated);
  };

  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const handleCurrentPasswordVisibility = () =>
    setShowCurrentPassword(!showCurrentPassword);
  return (
    <Stack spacing="5" align="stretch">
      {/* {!userInfo.isPasswordUpdated && localStorage.getItem("isRedirect") && (
        <Notification
          title={"Please set up a password for further email login."}
          status={"warning"}
          onCloseToast={clearMessage}
        />
      )} */}
      {error || success ? (
        <Notification
          title={error ? error : success}
          status={error ? "error" : "success"}
          onCloseToast={clearMessage}
        />
      ) : (
        ""
      )}
      <Card variant="form">
        <CardHeader>
          <Heading size="md">
            {userInfo.hasOwnProperty("isPasswordUpdated")
              ? userInfo.isPasswordUpdated
                ? "Change Password"
                : "Set Password"
              : "Change Password"}
          </Heading>
        </CardHeader>
        <Divider />
        {loading ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit(submitForm)} noValidate>
            <CardBody paddingBottom="0px">
              <Stack spacing={4}>
                <SimpleGrid columns={[1, 2]} columnGap={8}>
                  {userInfo.hasOwnProperty("isPasswordUpdated") ? (
                    userInfo.isPasswordUpdated ? (
                      <InputGroup>
                        <TextBox
                          type={showCurrentPassword ? "text" : "password"}
                          maxLength="100"
                          name="Current Password"
                          required
                          innerref={register("currentPassword", {
                            required: requiredField.replace(
                              "$Field$",
                              "Current Password"
                            ),
                          })}
                          validationErrors={errors}
                        />
                        <ShowHidePassword
                          handlePasswordVisibility={
                            handleCurrentPasswordVisibility
                          }
                          showPassword={showCurrentPassword}
                          id="my-acc-change-password-current-password-visiblity"
                        />
                      </InputGroup>
                    ) : (
                      ""
                    )
                  ) : (
                    <InputGroup>
                      <TextBox
                        type={showCurrentPassword ? "text" : "password"}
                        maxLength="100"
                        name="Current Password"
                        required
                        innerref={register("currentPassword", {
                          required: requiredField.replace(
                            "$Field$",
                            "Current Password"
                          ),
                        })}
                        validationErrors={errors}
                      />
                      <ShowHidePassword
                        handlePasswordVisibility={
                          handleCurrentPasswordVisibility
                        }
                        showPassword={showCurrentPassword}
                        id="my-acc-change-password-current-password-visiblity"
                      />
                    </InputGroup>
                  )}
                  <Box>
                    <InputGroup>
                      <TextBox
                        type={showPassword ? "text" : "password"}
                        name="new password"
                        maxLength="100"
                        placeholder="New Password"
                        mb={
                          password.current && errors.newPassword
                            ? "1rem"
                            : "none"
                        }
                        required
                        innerref={register("newPassword", {
                          required: requiredField.replace(
                            "$Field$",
                            "New Password"
                          ),
                          validate: {
                            hasSpecialCharacter: (value) =>
                              specialCharacterValidationPattern.test(value),
                            hasUpperCaseLetter: (value) =>
                              upperCaseValidationPattern.test(value),
                            hasLowerCaseLetter: (value) =>
                              lowerCaseValidationPattern.test(value),
                            hasMinLength: (value) => value.length >= 8,
                            hasMaxLength: (value) => value.length <= 16,
                          },
                        })}
                        validationErrors={errors}
                      />
                      <ShowHidePassword
                        handlePasswordVisibility={handlePasswordVisibility}
                        showPassword={showPassword}
                        id="my-acc-change-password-new-password-visiblity"
                      />
                    </InputGroup>
                    {password.current ? (
                      <PasswordStrengthIndicator
                        value={password.current}
                        validationErrors={errors.newPassword}
                        validationMessages={passwordValidationMessages}
                      />
                    ) : null}
                  </Box>
                </SimpleGrid>
                <SimpleGrid columns={[1, 2]} columnGap={8}>
                  <InputGroup>
                    <TextBox
                      type={showConfirmPassword ? "text" : "password"}
                      maxLength="100"
                      name="Confirm New Password"
                      required
                      innerref={register("confirmNewPassword", {
                        required: requiredField.replace(
                          "$Field$",
                          "Confirm Password"
                        ),
                        validate: (value) =>
                          value === password.current || passwordMisMatch,
                      })}
                      validationErrors={errors}
                    />
                    <ShowHidePassword
                      handlePasswordVisibility={handleConfirmPasswordVisibility}
                      showPassword={showConfirmPassword}
                      id="my-acc-change-password-confirm-password-visiblity"
                    />
                  </InputGroup>
                </SimpleGrid>
              </Stack>
            </CardBody>
            <CardFooter>
              <Button
                type="submit"
                isLoading={loading}
                title="Save"
                id="my-account-change-password-submit"
              />
            </CardFooter>
          </form>
        )}
      </Card>
    </Stack>
  );
};
export default ChangePassword;
