import {
  Flex,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
} from "@chakra-ui/react";
import { scrollbarCSS } from "../../constants/constantValues";
import { errorTriangle } from "@coachbar/shared-components/src/components/Icons/SvgsConstants";

const ProfileStepper = ({
  activeStep,
  setActiveStep,
  isCompleteStep,
  steps,
  containerPadding,
  stepError,
  setStepError,
  isTabEnabled,
  partnerId,
}) => {
  const handleTabChange = (index) => {
    if ((!isTabEnabled && !partnerId) || isTabEnabled) {
      setActiveStep(index);
    } else if (!isTabEnabled && partnerId) {
      setStepError(true);
    }
  };

  return (
    <Flex
      justifyContent={"center"}
      bg={"#F9FAFB"}
      position={"sticky"}
      top={0}
      zIndex={2}
      p={containerPadding || "16px 0 0 0"}
    >
      <Stepper
        index={activeStep}
        size={"md"}
        maxW={"100%"}
        overflow={"auto"}
        css={scrollbarCSS}
      >
        {steps.map((step, index) => (
          <Step
            key={index}
            onClick={() => handleTabChange(index)}
            cursor="pointer"
          >
            <StepIndicator
              bg={
                activeStep === index || isCompleteStep(index)
                  ? "#0C94AC !important"
                  : "white !important"
              }
              color={
                activeStep === index || isCompleteStep(index)
                  ? "white !important"
                  : "#9EA5AD !important"
              }
              border={
                activeStep === index || isCompleteStep(index)
                  ? "none"
                  : "1px solid #E6E7E9 !important"
              }
              height={"28px"}
              width={"28px"}
            >
              <StepStatus
                complete={
                  <StepIcon
                    bg={
                      isCompleteStep(index)
                        ? "#0C94AC !important"
                        : "#9EA5AD !important"
                    }
                  />
                }
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>
            <StepTitle
              color={
                activeStep === index ? "var(--title-black-text)" : "#9EA5AD"
              }
              style={{
                fontSize: "14px",
                fontWeight: 600,
                whiteSpace: "nowrap",
              }}
            >
              {step.title}
            </StepTitle>
            {partnerId &&
              !isTabEnabled &&
              stepError !== null &&
              activeStep === index &&
              errorTriangle}
            <StepSeparator
              rounded="8px"
              w={"32px !important"}
              flex={"0 0 32px !important"}
              bg={"#E6E7E9 !important"}
            />
          </Step>
        ))}
      </Stepper>
    </Flex>
  );
};

export default ProfileStepper;
