import {
  Box,
  Card,
  CardBody,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Button } from "@coachbar/shared-components";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Notification from "../../components/Notification";
import {
  profileRoute,
  subscriptionRoute,
} from "../../constants/redirectionRoutes";
import {
  getUserInfo,
  setSubscriptionStorage,
  setUserInfo,
} from "../../services/utility";
import { getPlanDetail } from "../auth/authActions";
import { setUserData } from "../auth/authSlice";
import { upgradePlan } from "./subscriptionActions";

const Plans = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const { companyProfile } = useSelector((state) => state.companyProfile);
  const { onOpen, isOpen, onClose } = useDisclosure();

  const [planDetails, setPlanDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let initialized = false;

  useEffect(() => {
    if (!initialized) {
      initialized = true;
      try {
        setIsLoading(true);
        dispatch(getPlanDetail()).then((responseData) => {
          setIsLoading(false);
          const response = responseData.payload;
          if (
            response &&
            response.success &&
            response.data &&
            response.data.subscriptionPlans
          ) {
            setPlanDetails(response.data.subscriptionPlans);
          }
        });
      } catch (error) {
        console.error("Error fetching plan details:", error);
      }
    }
  }, [dispatch, initialized]);

  const { price, leaderPlanBnplPrice } = useMemo(() => {
    const leaderPlan = planDetails.find((plan) => plan.planId === 10);
    const leaderPlanBnpl = planDetails.find((plan) => plan.planId === 11);

    return {
      price: leaderPlan?.price || 0,
      leaderPlanBnplPrice: leaderPlanBnpl?.price || 0,
    };
  }, [planDetails]);

  const changeplan = (selectedPlan) => {
    if (userInfo.planId && userInfo.planId !== 1) {
      setSelectedPlan(selectedPlan);
      onOpen();
    } else {
      if (selectedPlan.publicSignUpPage) {
        setSubscriptionStorage(selectedPlan.planId);
        window.open(selectedPlan.publicSignUpPage, "_self");
        navigate(subscriptionRoute);
      }
    }
  };

  const upgradeToNewPlan = () => {
    let requestData = {
      planId: selectedPlan.planId,
      productChangeDelayed: false,
    };
    setIsLoading(true);
    upgradePlan(requestData)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.success &&
          response.data.data
        ) {
          let userDetails = getUserInfo();
          userDetails["planId"] = selectedPlan.planId;
          userDetails["subscriptionStore"] =
            response.data.data.subscriptionStore;
          setUserInfo(userDetails);
          // localStorage.setItem("roadmapStatus", "false");
          dispatch(setUserData());
          setSuccess(response.data.message);
        } else {
          setError(response);
        }
        setIsLoading(false);
        setSelectedPlan({});
        onClose();
        navigate(subscriptionRoute);
      })
      .catch(() => {
        setIsLoading(false);
        setSelectedPlan({});
        onClose();
      });
  };

  const clearMessage = () => {
    setError(null);
    setSuccess(null);
  };

  return (
    <Box p={"24px"} bg={"#F9FAFB"} minH={"calc(100vh - 64px)"}>
      {error || success ? (
        <Notification
          title={error ? error : success}
          status={error ? "error" : "success"}
          onCloseToast={clearMessage}
        />
      ) : (
        ""
      )}
      <Flex justifyContent={"center"}>
        <Box>
          <Text
            color={"var(--chakra-colors-atlantic-700)"}
            fontSize={"16px"}
            fontWeight={700}
            textAlign={"center"}
            mb={"4px"}
          >
            Choose the best plan for you
          </Text>
          <Text
            color={"#5E6977"}
            fontSize={"12px"}
            fontWeight={500}
            textAlign={"center"}
          >
            Simple Pricing. No Hidden Fees.
          </Text>
          <Flex
            justifyContent={"center"}
            mt={"24px"}
            gap={"20px"}
            wrap={["wrap", "nowrap"]}
          >
            {isLoading
              ? getSkeletonLoader()
              : planDetails.length > 0 &&
                planDetails.map((plan) => {
                  return plan.isBNPL === false ? (
                    <Flex
                      border={
                        plan?.planId === 10
                          ? "1px solid #0C94AC"
                          : "1px solid #E6E7E9"
                      }
                      borderTop={plan?.planId === 10 && "4.5px solid #0C94AC"}
                      rounded={"8px"}
                      p={"24px"}
                      key={plan.planId}
                      direction={"column"}
                      maxW={"330px"}
                      w={"100%"}
                    >
                      <Text
                        fontSize={"16px"}
                        fontWeight={700}
                        color={"var(--title-black-text)"}
                      >
                        {plan.name}
                      </Text>
                      {/* {plan.description && (
                        <Text
                          fontSize={"12px"}
                          fontWeight={400}
                          color={"#5E6977"}
                        >
                          {plan.description}
                        </Text>
                      )} */}
                      <HStack alignItems={"baseline"} mt={"16px"}>
                        <Text
                          fontSize={"24px"}
                          fontWeight={700}
                          color={"var(--title-black-text)"}
                        >
                          {plan?.planId === 1
                            ? `$${plan?.price}`
                            : userInfo.planId === 1
                            ? `$${leaderPlanBnplPrice}`
                            : `$${plan?.price}`}
                        </Text>
                        <Text
                          fontSize={"12px"}
                          fontWeight={500}
                          color={"#5E6977"}
                        >
                          per year
                        </Text>
                      </HStack>
                      <Text
                        display={"inline-block"}
                        fontSize={"12px"}
                        fontWeight={600}
                        color={"var(--title-black-text)"}
                        mt={"16px"}
                        bg={"#F3F7FF"}
                        border={"1px solid #DEE8F8"}
                        p={"2px 10px"}
                        rounded={"20px"}
                        w={"fit-content"}
                      >
                        {plan.credit || 0} Credits Included
                      </Text>
                      <Box
                        mt={"16px"}
                        mb={"24px"}
                        pt={"16px"}
                        borderTop={"1px solid #E6E7E9"}
                      >
                        <Text
                          fontSize={"12px"}
                          fontWeight={600}
                          color={"#5E6977"}
                          mb={"8px !important"}
                        >
                          Features
                        </Text>

                        {plan.features.map((feature) => {
                          return (
                            <HStack key={feature}>
                              <Box>
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.3337 4L6.00033 11.3333L2.66699 8"
                                    stroke="#0C94AC"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </Box>
                              <Text
                                fontSize={"12px"}
                                fontWeight={500}
                                color={"#5E6977"}
                              >
                                {feature}
                              </Text>
                            </HStack>
                          );
                        })}
                      </Box>
                      {plan.planId !== userInfo.planId &&
                      (userInfo.planId > 5
                        ? plan.planId > userInfo.planId - 4
                        : plan.planId > userInfo.planId) ? (
                        <Flex flexGrow={1} alignItems={"flex-end"}>
                          <Box w={"full"}>
                            <Button
                              type="submit"
                              title={`Buy Now ${
                                userInfo.planId === 1
                                  ? `& Save $${leaderPlanBnplPrice - price}`
                                  : ""
                              }`}
                              variant={"brandPrimary"}
                              w="100%"
                              onClick={() => changeplan(plan)}
                              id="subscription-plan-buy-now"
                            />
                            {userInfo.planId === 1 && (
                              <Text
                                color={"var(--chakra-colors-cyprus-500)"}
                                fontSize={"14px"}
                                fontWeight={700}
                                textAlign={"center"}
                                cursor={"pointer"}
                                mt={"16px"}
                                onClick={() =>
                                  // for now we are only showing one plan so we are sending BNPL plan data by adding static condition
                                  changeplan(
                                    planDetails?.find(
                                      (plan) => plan?.planId === 11
                                    )
                                  )
                                }
                              >
                                Upgrade Now & Pay Later
                              </Text>
                            )}
                          </Box>
                        </Flex>
                      ) : (
                        ""
                      )}
                    </Flex>
                  ) : (
                    ""
                  );
                })}
          </Flex>
          {(!companyProfile ||
            (companyProfile && companyProfile.profileStatus === "Draft")) && (
            <HStack py={"40px"} justifyContent={"center"}>
              <Text
                fontSize={"14px"}
                fontWeight={400}
                color={"var(--title-black-text)"}
              >
                🤔 I want to look around first.
              </Text>
              <Text
                onClick={() => navigate(profileRoute)}
                cursor={"pointer"}
                color={"#0C94AC"}
                fontSize={"14px"}
                fontWeight={600}
                textDecoration={"underline"}
              >
                Explore Now
              </Text>
            </HStack>
          )}
        </Box>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p={"16px 24px"}>
            <Text
              color={"var(--title-black-text)"}
              fontSize={"16px"}
              fontWeight={600}
            >
              Upgrade Your Plan
            </Text>
          </ModalHeader>
          <ModalCloseButton color={"#7E8792"} top={"10px"} right={"20px"} />
          <ModalBody p={"20px 24px"}>
            <Text
              color={"var(--title-black-text)"}
              fontSize={"14px"}
              fontWeight={400}
            >
              Are you sure you want to upgrade your plan to{" "}
              <b>{selectedPlan.planName}</b> ? You will be charged{" "}
              <b>${selectedPlan.price}</b>{" "}
            </Text>
          </ModalBody>
          <ModalFooter borderTop={"1px solid #DEE8F8"} p={"16px 24px"}>
            <Text
              color="#5E6977"
              fontSize={"14px"}
              fontWeight={600}
              mr={"20px"}
              p={"8px 16px"}
              onClick={onClose}
              cursor={"pointer"}
            >
              Cancel
            </Text>
            <Button
              border={"none"}
              bg={"#0C94AC"}
              color={"white"}
              w={"fit-content"}
              variant="newThemePrimary"
              rounded={"4px"}
              isLoading={isLoading}
              fontSize={"14px"}
              fontWeight={600}
              title={"Upgrade Plan"}
              onClick={upgradeToNewPlan}
              id="subscription-plan-upgrade"
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

const getSkeletonLoader = () => {
  return (
    <>
      <Card variant={"plan"} maxW={"330px"} w={"330px"}>
        <CardBody>
          <Skeleton height={"400px"} />
        </CardBody>
      </Card>
      <Card variant={"plan"} maxW={"330px"} w={"330px"}>
        <CardBody>
          <Skeleton height={"400px"} />
        </CardBody>
      </Card>
    </>
  );
};

export default Plans;
