import { Flex, Spinner } from "@chakra-ui/react";
// import Loading from "../../images/loader.gif";

const Loader = () => {
  return (
    <Flex h={"full"} justifyContent={"center"} alignItems={"center"}>
      {/* <Image src={Loading} m={10} boxSize={"5rem"} /> */}
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="brand.100"
        size="xl"
        m={10}
      />
    </Flex>
  );
};

export default Loader;
