import axios from "axios";
// import awsV4 from "./awsSignature";
import { store } from "../app/store";
import { setUserToken, setUserInfo, getUserInfo } from "./utility";
import { logout, setToken, setUserData } from "../features/auth/authSlice";
import { getRoleDetails } from "../features/settings/usersAndRoles/usersAndRolesActions";
import { getUserDetails } from "../features/auth/authActions";

let baseUrl = process.env.REACT_APP_API_BASE_URL;

export const axiosInstance = axios.create({
  baseURL: baseUrl,
});

// const getSignedHeaders = async (request) => {
//   const signedRequest = awsV4
//     .newClient({
//       accessKey: "1234",
//       secretKey: "5678",
//       // sessionToken is required if you run this on AWS lambda
//       sessionToken: "123456789",
//       region: "us-east-1",
//       endpoint: request.baseURL,
//     })
//     .signRequest({
//       method: request.method,
//       path: request.url,
//       headers: request.headers,
//       queryParams: {},
//       body: request.data,
//     });

//   return signedRequest;
// };
const setSessionData = (data, roleData) => {
  if (data.data && data.data.userInfo) {
    setUserInfo({ ...data.data.userInfo, roleData: roleData });
  }
};

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = store.getState().auth.userToken;
    const userInfo = store.getState().auth.userInfo;

    let updatedConfig = { ...config };
    if (token) {
      updatedConfig.headers["Authorization"] = token.idToken;
      if (userInfo && updatedConfig?.data) {
        let data = { ...updatedConfig.data };
        data["userType"] = userInfo?.userType;
        data = JSON.stringify(data);
        updatedConfig["data"] = data;
      }
    }
    updatedConfig.headers["Content-Type"] = "application/json";
    return updatedConfig;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    // if (
    //   error.response.status === 401 &&
    //   originalRequest.url === "http://127.0.0.1:3000/v1/auth/token"
    // ) {
    //   router.push("/login");
    //   return Promise.reject(error);
    // }
    if (
      (error.response && error.response.status === 401) ||
      (error?.code === "ERR_NETWORK" &&
        error?.message === "Network Error" &&
        !originalRequest._retry)
    ) {
      console.log("retry called");
      originalRequest._retry = true;
      let authData = store.getState().auth;
      if (authData) {
        return axios
          .post(`${baseUrl}setup/refreshToken`, {
            email: authData.userInfo.email,
            idToken: authData.userToken.idToken,
            refreshToken: authData.userToken.refreshToken,
          })
          .then(async (res) => {
            if (res.status === 200 && res.data.data) {
              let tokens = {
                ...authData["userToken"],
                ...res.data.data.tokens,
              };
              setUserToken(tokens); // setting the localstorage
              store.dispatch(setToken()); // setting the userToken state
              originalRequest.headers["Authorization"] = tokens.idToken;
              if (res.data.data.userInfo) {
                let roleData = { roles: {} };
                if (res.data.data.userInfo.roleId) {
                  // get role based data
                  let tokenString = JSON.stringify(
                    res.data.data.tokens.idToken
                  );
                  roleData = await getRoleDetails(
                    {
                      id: res.data?.data?.userInfo?.roleId,
                    },
                    tokenString.substring(1, tokenString.length - 1)
                  );
                }
                setSessionData(res.data, roleData.roles);
                store.dispatch(setUserData()); // setting the userData state
              }
              return axios(originalRequest)
                .then((res) => {
                  return res;
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          })
          .catch((err) => {
            if (!err.response.data.status) {
              store.dispatch(logout());
              window.location = "/login";
              localStorage.setItem("session-expired-logout", "y");
            }
          });
      }
    } else if (
      error.response &&
      error.response.status === 403 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      let authData = store.getState().auth;
      let userInfo = getUserInfo();
      if (authData) {
        return axios
          .get(`${baseUrl}user`, {
            headers: {
              Authorization: authData.userToken.idToken,
            },
          })
          .then(async (res) => {
            if (res.data.code === 200) {
              await getRoleDetails(
                { id: res.data.data.user.roleId },
                authData.userToken.idToken
              ).then((response) => {
                let updatedUserInfo = {
                  ...res.data.data.user,
                  roleId: res.data.data.user.roleId,
                  roleData: response.roles,
                  role: res.data.data.user.role,
                };
                setUserInfo(updatedUserInfo);
                store.dispatch(setUserData());
                return axios(originalRequest)
                  .then((resp) => {
                    return resp;
                  })
                  .catch((errr) => {
                    return Promise.reject(errr);
                  });
              });
            }
          })
          .catch((e) => {
            console.log(e);
            return Promise.reject(error);
          });
      }
    }
    return Promise.reject(error);
  }
);
