import React from "react";
import CustomPopover from "../CustomPopover/CustomPopover";
import { Box, Checkbox, Flex, Image, Text } from "@chakra-ui/react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import ColumnMoveSvg from "@coachbar/shared-components/src/images/column-move.svg";
import Scrollbars from "react-custom-scrollbars-2";
import { Button } from "../..";
import columnPosition from "@coachbar/shared-components/src/images/column-position.svg";

const CustomizeColumns = (props) => {
  const {
    table,
    isColumnSelectionOpen,
    onColumnSelectionOpen,
    onCancelColumns,
    HTML5Backend,
    columnOrderOptions,
    onSaveColumns,
    reorderColumn,
    setColumnOrder,
    columnVisibility,
    columnHandleChange,
    hideDefaultCompanyField = false,
  } = props;

  const DraggableColumn = ({ column }) => {
    const [, dropRef] = useDrop({
      accept: "column",
      drop: (draggedColumn) => {
        const newColumnOrder = reorderColumn(
          draggedColumn.id,
          column.id,
          columnOrderOptions
        );
        setColumnOrder([...newColumnOrder]);
      },
    });

    const [{ isDragging }, dragRef, previewRef] = useDrag({
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      item: () => column,
      type: "column",
    });

    if (!column) return null;

    return (
      <Box
        ref={column?.id !== "companyName" ? dropRef : null}
        style={{ opacity: isDragging ? 0.5 : 1 }}
        draggable={column.id !== "companyName"}
      >
        <div ref={previewRef}>
          <div ref={dragRef}>
            <Flex
              key={column.id}
              border={"1px solid #E6E7E980"}
              borderRadius={"4px"}
              p={"10px 8px"}
              justifyContent={"space-between"}
              mt={"4px"}
            >
              <Checkbox
                isDisabled={column.id === "companyName"}
                cursor={column.id === "companyName" ? "text" : "pointer"}
                isChecked={
                  columnVisibility.hasOwnProperty(column.id)
                    ? columnVisibility[column.id]
                    : true
                }
                onChange={(e) => {
                  columnHandleChange(e.target.checked, column);
                }}
                _checked={{
                  "& .chakra-checkbox__control": {
                    background: "#0C94AC",
                    border: "#0C94AC",
                  },
                }}
                _disabled={{
                  "& .chakra-checkbox__control": {
                    background: "#0C94AC !important",
                    border: "#0C94AC !important",
                  },
                  "& svg": { color: "#fff" },
                }}
              >
                <Text
                  fontSize={"12px"}
                  fontWeight={500}
                  color={"var(--title-black-text)"}
                >
                  {column.columnDef.header}
                </Text>
              </Checkbox>
              <Image ml="1px" src={ColumnMoveSvg} cursor={"pointer"} />
            </Flex>
          </div>
        </div>
      </Box>
    );
  };

  return (
    <CustomPopover
      showPopover={isColumnSelectionOpen}
      onOpen={onColumnSelectionOpen}
      onClose={onCancelColumns}
      contentWidth={"260px"}
      labelComponent={
        <Image
          onClick={onColumnSelectionOpen}
          src={columnPosition}
          cursor={"pointer"}
          width={"16px"}
          height={"16px"}
          margin={"auto"}
        />
      }
      bodyStyle={{
        borderRadius: "4px",
        overflow: "hidden",
        boxShadow: "0px 8px 8px -4px #1018280A",
        padding: "0",
      }}
      offset={[-110, 10]}
      id="customize-column-popover"
    >
      <Box>
        <Text
          fontWeight={"500"}
          fontSize={"12px"}
          color={"#5E6977"}
          bg={"#F9FAFB"}
          p={"8px 12px"}
        >
          Customize Columns
        </Text>
        <Box p={"12px"}>
          {!hideDefaultCompanyField &&
            (columnOrderOptions.includes("readableId")
              ? ["Id", "Company"]
              : columnOrderOptions.includes("rewardPartner")
              ? ["Partner"]
              : columnOrderOptions.includes("rewardProvider")
              ? ["Provider"]
              : ["Company"]
            ).map((item) => (
              <Box
                border={"1px solid #E6E7E980"}
                borderRadius={"4px"}
                p={"10px 8px"}
              >
                <Checkbox
                  isDisabled={true}
                  cursor={"text"}
                  isChecked={true}
                  _checked={{
                    "& .chakra-checkbox__control": {
                      background: "#0C94AC",
                      border: "#0C94AC",
                    },
                  }}
                  _disabled={{
                    "& .chakra-checkbox__control": {
                      background: "#0C94AC !important",
                      border: "#0C94AC !important",
                    },
                    "& svg": { color: "#fff" },
                  }}
                  opacity="0.5"
                >
                  <Text
                    fontSize={"12px"}
                    fontWeight={500}
                    color={"var(--title-black-text)"}
                  >
                    {item}
                  </Text>
                </Checkbox>
              </Box>
            ))}
          <Scrollbars autoHeight autoHeightMax={"300px"} autoHide>
            <DndProvider backend={HTML5Backend}>
              {columnOrderOptions.map((column) => {
                if (
                  ![
                    "select",
                    "type",
                    "companyName",
                    "rewardPartner",
                    "rewardProvider",
                    "readableId",
                    "name",
                  ].includes(column)
                ) {
                  return (
                    <DraggableColumn
                      column={table
                        .getAllLeafColumns()
                        .find((x) => x.id === column)}
                    />
                  );
                }
              })}
            </DndProvider>
          </Scrollbars>
        </Box>
        <Flex
          p={"12px"}
          justifyContent={"flex-end"}
          alignItems={"center"}
          borderTop={"1px solid #E6E7E9"}
        >
          <Text
            onClick={onCancelColumns}
            color={"#5E6977"}
            p={"6px 16px"}
            fontSize={"14px"}
            fontWeight={500}
            cursor={"pointer"}
            id="customize-columns-component-cancel"
          >
            Cancel
          </Text>
          <Button
            ml="20px"
            w="fit-content"
            h={"auto"}
            p={"8px 16px"}
            bg="#0C94AC"
            border="none"
            rounded="4px"
            variant="newThemePrimary"
            title="Save"
            onClick={onSaveColumns}
            id="customize-columns-component-save"
          />
        </Flex>
      </Box>
    </CustomPopover>
  );
};

export default CustomizeColumns;
