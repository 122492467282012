import {
  Box,
  Flex,
  Text,
  Image,
  Stack,
  Divider,
  SimpleGrid,
  useDisclosure,
  HStack,
  VStack,
  FormLabel,
} from "@chakra-ui/react";
import logo from "../../../image/white-logo.svg";
import smileImage from "@coachbar/shared-components/src/images/icon-smile.svg";
import { useNavigate } from "react-router-dom";
import fallbackImage from "../../../image/fallbackImage.png";
import { profileRoute } from "../../../constants/redirectionRoutes";
import {
  requiredField,
  whiteSpace,
} from "../../../constants/validationMessages";
import coachbarLoading from "@coachbar/shared-components/src/images/coachbar-loading.gif";
import {
  claimUserProfile,
  searchCompanyProfile,
} from "./companyProfileActions";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Modal,
  RadioGroup,
  TextBox,
} from "@coachbar/shared-components";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo, setUserInfo } from "../../../services/utility";
import { setUserData } from "../../auth/authSlice";
import { whiteSpacePattern } from "../../../constants/validationPatterns";
import { userTypeList } from "../../../constants/auth";
import CurrencyDropDown from "@coachbar/shared-components/src/components/CurrencyDropDown/CurrencyDropDown";
import { updateCurrency } from "../../auth/authActions";
import {
  getSelectedCurrencyObj,
  setCurrency,
} from "@coachbar/shared-components/src/services/utility";

const ClaimProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [companyList, setCompanyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [claimProfileLoading, setClaimProfileLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      userType: 1,
    },
  });

  useEffect(() => {
    if (userInfo) {
      if (userInfo.hasOwnProperty("isClaimed") && userInfo.isClaimed === true) {
        navigate(profileRoute);
      } else if (!userInfo.companyName) {
        onOpen();
      } else {
        searchCompany({
          companyName: userInfo.companyName,
          updateCompany: false,
        });
      }

      if (!userInfo.currencyCode) {
        onOpen();
      }
    }
  }, [navigate, userInfo]);

  const getPopupTitle = () => {
    // let logo = claimProfileLoading ? coachbarLoading : smileImage;
    let Content = claimProfileLoading
      ? "We are preparing your profile, it will take some time"
      : `It seems like you're new here. To help us get to know you better,please provide your  company name, currency below.`;
    return (
      <Stack spacing={0}>
        <Text
          mb={"14px !important"}
          color={"var(--title-black-text)"}
          fontSize={"20px"}
          fontWeight={600}
          textAlign={"center"}
        >
          👋 Hey {userInfo?.firstName}, All Done!
        </Text>
        <Text
          textAlign={"center"}
          fontSize={"14px"}
          fontWeight={400}
          color={"#5E6977"}
          mb={"24px !important"}
        >
          {Content}
        </Text>
      </Stack>
    );
  };

  const submitForm = async (data) => {
    let requestData = {
      companyName: data.companyName,
      userType: data.userType,
      updateCompany: true,
      currencyCode: data.currency?.value?.split("---")[0],
    };
    searchCompany(requestData);
  };

  const searchCompany = (requestData) => {
    setLoading(true);
    searchCompanyProfile(requestData).then((response) => {
      if (response.success && response.data && response.data.profiles) {
        setCompanyList(response.data.profiles);
      } else {
        let userInfo = getUserInfo();
        userInfo.companyName = requestData.companyName;
        userInfo.currencyCode = requestData.currencyCode;
        setCurrency(getSelectedCurrencyObj(requestData.currencyCode));
        setUserInfo(userInfo);
        claimProfileClick();
      }
      onClose();
      setLoading(false);
    });
  };

  const claimProfileClick = (companyName = null) => {
    let requestData = {
      isClaimed: true,
    };
    if (companyName !== null) {
      requestData.scProfile = {};
      requestData.scProfile.companyNameIndex = companyName;
      setClaimProfileLoading(true);
      onOpen();
    }
    setLoading(true);
    claimUserProfile(requestData).then((response) => {
      if (companyName) {
        onClose();
        setClaimProfileLoading(false);
      }
      if (response.success) {
        let userDetails = getUserInfo();
        userDetails["isClaimed"] = true;
        if (companyName) {
          userDetails["profileCompleted"] = true;
        }
        setUserInfo(userDetails);
        dispatch(setUserData());
        navigate(profileRoute);
      }
      setLoading(false);
    });
  };

  const createNewProfile = () => {
    return (
      <Box
        border={"1px solid #D4DCE3"}
        borderRadius={"12px"}
        mb={"1rem"}
        bg="linear-gradient(90deg, #0C94AC 0%, #07D0BA 100%)"
        boxShadow="rgba(0,0,0,0.20) 0 12px 38px"
      >
        <VStack spacing={3} p={"1rem"}>
          <Text
            fontWeight={"700"}
            fontSize={"1rem"}
            lineHeight={"24px"}
            color={"#FFFFFF"}
          >
            Can’t find your profile ?
          </Text>
          <Text
            fontWeight={"600"}
            fontSize={"13px"}
            lineHeight={"20px"}
            color={"#FFFFFF"}
          >
            You can create new profile from scratch
          </Text>
          <Button
            type="button"
            title="Create New Profile"
            variant={"specializationButton"}
            w={"150px"}
            bg={"#FFFFFF"}
            isLoading={loading}
            onClick={() => claimProfileClick()}
            id="company-profile-create-new-profile"
          />
        </VStack>
      </Box>
    );
  };

  return (
    <Stack spacing={4} textAlign={"center"}>
      <Flex px="8" height="80px" alignItems="center" justifyContent={"center"}>
        <Image src={logo} alt="Coachbar Logo"></Image>
      </Flex>
      <Divider />
      <Stack pl={"10rem"} pr={"10rem"}>
        <Text fontSize={"1.5rem"} lineHeight={"28px"} color={"brand.100"}>
          Ah, we believe, we have your details
        </Text>
        <SimpleGrid
          spacingX={"20px"}
          columns={{ base: 1, md: 2, lg: 3, xl: 3 }}
        >
          {companyList.map((company) => {
            return (
              <Box
                key={company.companyNameIndex}
                border={"1px solid #D4DCE3"}
                borderRadius={"12px"}
                mb={"1rem"}
              >
                <HStack
                  spacing={4}
                  mb={"1rem"}
                  mt={"1rem"}
                  alignItems={"center"}
                >
                  <Box
                    ml={"20px"}
                    border={"1px solid #D4DCE3"}
                    backgroundColor={"#FFFFFF"}
                    borderRadius={"0.75rem"}
                  >
                    <Image
                      src={company?.logo}
                      alt="Logo"
                      height={["60px", "60px", "70px", "80px"]}
                      width={["60px", "60px", "70px", "80px"]}
                      maxW={["60px", "60px", "70px", "80px"]}
                      maxH={["60px", "60px", "70px", "80px"]}
                      fallbackSrc={fallbackImage}
                      borderRadius={"0.75rem"}
                      objectFit={"contain"}
                    />
                  </Box>
                  <Text
                    fontSize={"1rem"}
                    lineHeight={"24px"}
                    color={"brand.100"}
                    fontWeight={"700"}
                  >
                    {company.companyName}
                  </Text>
                </HStack>
                <Divider borderColor={"#D4DCE3"} />
                <Button
                  mb={"0.5rem"}
                  mt={"0.5rem"}
                  type="button"
                  isLoading={loading}
                  title="Claim Profile"
                  variant={"specializationButton"}
                  w={"130px"}
                  onClick={() => claimProfileClick(company.companyNameIndex)}
                  id="company-profile-claim-profile"
                />
              </Box>
            );
          })}

          {companyList.length > 0 ? createNewProfile() : ""}
        </SimpleGrid>
      </Stack>
      <Modal
        isOpen={isOpen}
        // onClose={onModalClose}
        // title={getPopupTitle()}
        // allowCloseButton={true}
        displayFooter={false}
        size={"lg"}
        // onSaveClick={handleSubmit(submitForm)}
        loading={loading}
        saveButtonTitle="Continue"
        hideDiscard={true}
      >
        {!claimProfileLoading ? (
          <Box p={"8px"}>
            {getPopupTitle()}
            <form noValidate>
              <>
                {
                  <FormLabel
                    htmlFor="userType"
                    color={"#5E6977"}
                    fontWeight={500}
                    fontSize={"14px"}
                    mb={"12px"}
                  >
                    Register As
                  </FormLabel>
                }
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      onChange={onChange}
                      value={value}
                      childList={userTypeList}
                    />
                  )}
                  name="userType"
                  control={control}
                />
                <Box mt={"20px"}>
                  <TextBox
                    type="text"
                    maxLength="100"
                    placeholder="Company Name"
                    required
                    innerref={register("companyName", {
                      required: requiredField.replace(
                        "$Field$",
                        "Company Name"
                      ),
                      pattern: {
                        value: whiteSpacePattern,
                        message: whiteSpace,
                      },
                    })}
                    validationErrors={errors}
                  />
                </Box>
              </>
              <Stack mb="20px">
                <Controller
                  control={control}
                  name="currency"
                  defaultValue={null}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <CurrencyDropDown
                      width={"100%"}
                      options={[]}
                      placeholder="Select Currency"
                      onCurrencyChange={(selectedCurrency) => {
                        onChange(selectedCurrency);
                      }}
                      name={name}
                      size="md"
                      customWidth={"100%"}
                      currency={value}
                      defaultCurrency={"USD"}
                      innerRef={ref}
                      validationErrors={errors}
                    />
                  )}
                />
              </Stack>
              <Stack>
                <Text fontWeight={"500"} fontSize={"12px"} color={"#7E8792"}>
                  <Box
                    display={"inline-block"}
                    color={"#C81E1E"}
                    fontWeight={"600"}
                    mr="2px"
                  >
                    Note:{" "}
                  </Box>
                  Base currency cannot be changed once saved. Please ensure the
                  correct base currency is selected before saving.
                </Text>
              </Stack>
            </form>
            <HStack justifyContent={"flex-end"} mt={"24px"}>
              <Button
                title={"Continue"}
                onClick={handleSubmit(submitForm)}
                isLoading={loading}
                variant="newColorThemePrimary"
                id="company-profile-continue"
              />
            </HStack>
          </Box>
        ) : (
          ""
        )}
      </Modal>
    </Stack>
  );
};

export default ClaimProfile;
