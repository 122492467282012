import React from "react-dom";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import TableTextWithEllipsis from "../Table/TableTextWithEllipsis";

export const ProfileMenu = ({ userInfo, profileMenuItems = [] }) => {
  const userFullName = `${userInfo?.firstName} ${userInfo?.lastName}`;
  return (
    <HStack spacing={{ base: "0", md: "6" }}>
      <Flex alignItems={"center"}>
        <Menu offset={[0, 0]}>
          <MenuButton
            py={2}
            transition="all 0.3s"
            _focus={{ boxShadow: "none" }}
          >
            <HStack>
              <Avatar
                size={"sm"}
                name={userFullName}
                bg="var(--chakra-colors-white)"
                borderColor={"cyprus.50"}
                color={"cyprus.500"}
                showBorder
              />
              <VStack
                display={{ base: "none", md: "flex" }}
                alignItems="flex-start"
                spacing="1px"
              >
                <TableTextWithEllipsis
                  textContainerProps={{ fontSize: "14px", fontWeight: 500 }}
                  maxChars={25}
                  text={userFullName}
                />
              </VStack>
              <Box display={{ base: "none", md: "flex" }}>
                <ChevronDownIcon />
              </Box>
            </HStack>
          </MenuButton>
          <Portal>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
              p="6px 4px"
              zIndex={99}
            >
              <MenuItem
                gap="8px"
                p="8px"
                cursor={"default"}
                onClick={(e) => e.preventDefault()}
                _hover={{ bg: "var(--chakra-colors-white)" }}
                _focus={{ bg: "var(--chakra-colors-white)" }}
              >
                <Avatar
                  size={"sm"}
                  name={userFullName}
                  bg="var(--chakra-colors-white)"
                  borderColor={"cyprus.50"}
                  color={"cyprus.500"}
                  showBorder
                />
                <VStack spacing={0} flex={"1"} alignItems={"start"}>
                  <TableTextWithEllipsis
                    textContainerProps={{ fontSize: "14px", fontWeight: 500 }}
                    maxChars={25}
                    text={userFullName}
                    tooltipProps={{ placement: "left" }}
                  />
                  <TableTextWithEllipsis
                    textContainerProps={{
                      fontSize: "10px",
                      fontWeight: 500,
                      lineHeight: "16px",
                      color: "var(--chakra-colors-brandGray-500)",
                      mt: "0px !important",
                      noOfLines: 1,
                    }}
                    maxChars={25}
                    text={userInfo.email}
                    tooltipProps={{ placement: "left" }}
                  />
                </VStack>
              </MenuItem>
              <MenuDivider my="1px !important" />
              {profileMenuItems.map((menuItem, i) => {
                if (menuItem.type === "tab")
                  return (
                    <MenuItem
                      key={i}
                      onClick={menuItem.onClick}
                      borderRadius={"6px"}
                      gap={"8px"}
                      p="8px"
                      stroke={"brandGray.500"}
                      color={"brandGray.500"}
                      _hover={{
                        bg: "cyprus.50",
                        stroke: "cyprus.500",
                        color: "cyprus.500",
                      }}
                    >
                      <Flex
                        border={"none"}
                        bg={"transparent"}
                        fill="transparent"
                        alignItems={"center"}
                        width={"16px"}
                      >
                        <menuItem.Icon />
                      </Flex>
                      <Text
                        fontSize={"14px"}
                        lineHeight={"20px"}
                        fontWeight={500}
                        color={"inherit"}
                      >
                        {menuItem.title}
                      </Text>
                    </MenuItem>
                  );

                if (menuItem.type === "divider")
                  return <MenuDivider my="1px !important" key={i} />;
                return null;
              })}
            </MenuList>
          </Portal>
        </Menu>
      </Flex>
    </HStack>
  );
};
