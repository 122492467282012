import {
  AspectRatio,
  Box,
  Container,
  Input,
  Stack,
  Text,
  FormLabel,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Image,
  HStack,
  Center,
} from "@chakra-ui/react";
import fileUploadIcon from "../../images/fileUploadIcon.png";
import spinnerIcon from "../../images/spinnerIcon.png";
import circledCloseIcon from "../../images/circledCloseIcon.png";

import { useEffect, useState } from "react";

export default function FileUpload({
  innerref,
  validationErrors,
  note,
  hideLabel,
  ...props
}) {
  const validation = validationErrors && validationErrors[innerref.name];
  let imageType = null;
  if (props.imgSrc) {
    imageType = props.imgSrc.substring(props.imgSrc.lastIndexOf(".") + 1);
  }

  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    let intervalId;
    if (props?.isLoading) {
      intervalId = setInterval(() => {
        setRotation((prevRotation) => (prevRotation + 15) % 360);
      }, 100);
    } else {
      setRotation(0);
    }
    return () => clearInterval(intervalId); // Clear the interval on component unmount or when isLoading is false
  }, [props?.isLoading]);

  return (
    <Container
      pl={"0px"}
      pr={"0px"}
      m={"0px"}
      maxWidth={props.boxWidth ? props.boxWidth : "100%"}
      cursor={props.isDisabled ? "not-allowed" : "pointer"}
      opacity={props.isDisabled ? 0.5 : 1}
    >
      <FormControl
        name={props.name}
        isInvalid={validation}
        isRequired={props.required}
        pointerEvents={props.isDisabled ? "none" : "all"}
      >
        {!hideLabel && (
          <FormLabel
            as="legend"
            color={"#5E6977"}
            fontWeight={600}
            mb={"4px"}
            fontSize={"12px"}
          >
            {props.name}
          </FormLabel>
        )}
        <AspectRatio
          width={props.boxWidth ? props.boxWidth : "100%"}
          ratio={props.ratio ? props.ratio : 21 / 9}
        >
          <Box
            borderColor="gray.300"
            borderStyle="dashed"
            borderWidth="1px"
            rounded="4px"
            shadow="sm"
            role="group"
            transition="all 150ms ease-in-out"
            _hover={{
              shadow: "md",
              borderColor: "#0C94AC",
            }}
            // as={motion.div}
            initial="rest"
            animate="rest"
            bg="white"
          >
            <Box
              position="relative"
              top="0"
              left="0"
              height="65%"
              width="100%"
              display="flex"
              flexDirection="column"
              bgImage={props.isLoading ? "none" : `url("${props.imgSrc}")`}
              bgRepeat={"no-repeat"}
              bgPosition={"center"}
              bgSize={"contain"}
              style={{ backgroundOrigin: "content-box" }}
            >
              <Stack
                height="100%"
                width="100%"
                display="flex"
                alignItems="center"
                justify="center"
                spacing="4"
              >
                {!props.isLoading &&
                imageType &&
                imageType.toLowerCase() === "pdf" ? (
                  <embed
                    src={props.imgSrc}
                    type="application/pdf"
                    style={{ zIndex: 2 }}
                    width={"100%"}
                    height={"100%"}
                  />
                ) : null}
                {props.isLoading ? (
                  <Center height={"inherit"} width={"inherit"}>
                    <Image
                      src={spinnerIcon}
                      boxSize={"18px"}
                      transform={`rotate(${rotation}deg)`}
                      transition="transform 0.1s ease-in-out"
                    />
                  </Center>
                ) : !props.imgSrc ? (
                  <Stack alignItems={"center"} px={2}>
                    <Image src={fileUploadIcon} boxSize={"18px"} />
                    <HStack>
                      <Text
                        color={"#0C94AC"}
                        fontWeight={500}
                        fontSize={"12px"}
                        lineHeight={"18px"}
                        mt={"0 !important"}
                        mr={1}
                      >
                        Click to Upload
                      </Text>
                      <Text
                        color={"#BFC3C9"}
                        fontWeight={500}
                        fontSize={"12px"}
                        lineHeight={"18px"}
                        mt={"0 !important"}
                        ml={"0 !important"}
                      >
                        or Drag & Drop
                      </Text>
                    </HStack>
                    <Text
                      color={"#9EA5AD"}
                      fontSize={"10px"}
                      lineHeight={"16px"}
                      fontWeight={400}
                      textAlign={"center"}
                    >
                      {note}
                    </Text>
                  </Stack>
                ) : (
                  <>
                    {props.allowDelete && (
                      <Image
                        position={"absolute"}
                        zIndex={1}
                        top={{ lg: "-1", xl: "-3" }}
                        right={{ lg: "1", xl: "3" }}
                        src={circledCloseIcon}
                        boxSize={"18px"}
                        onClick={props.onDeleteClick}
                        cursor={"pointer"}
                      />
                    )}
                  </>
                )}
              </Stack>
            </Box>
            <Input
              disabled={props.isLoading}
              // ref="upload"
              type="file"
              height="100%"
              width="100%"
              position="absolute"
              top="0"
              left="0"
              opacity="0 !important"
              aria-hidden="true"
              onChange={props.onChange}
              cursor={"pointer"}
              accept={
                props.acceptedFileTypes ? props.acceptedFileTypes : "image/*"
              }
              multiple={false}
              isDisabled={props.isDisabled ? props.isDisabled : false}
              {...innerref}
            />
          </Box>
        </AspectRatio>
        {validation?.message ? (
          <FormErrorMessage>{validation?.message}</FormErrorMessage>
        ) : (
          <FormHelperText>&nbsp;</FormHelperText>
        )}
      </FormControl>
    </Container>
  );
}
