import React from "react";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import { DndProvider } from "react-dnd-multi-backend";
import IcpRequirementsForm from "./IcpRequirementsForm";
import DragPreviewLayer from "./DragPreviewLayer";

const NewIcp = () => {
  return (
    <DndProvider
      options={{
        backends: HTML5toTouch.backends.map((backend) => ({
          ...backend,
          preview: true,
        })),
      }}
    >
      <DragPreviewLayer />
      <IcpRequirementsForm />
    </DndProvider>
  );
};

export default NewIcp;
