import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../services/axiosInstance";
import { getErrorMessage } from "../../../services/utility";

export const addUrlDirectory = createAsyncThunk(
  "add/urlDirectory",
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(
        "/urlDirectory/add",
        requestData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getAllUrlDirectory = createAsyncThunk(
  "get/allUrlDirectory",
  async (companyProfileId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/urlDirectory?limit=10`);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateUrlDirectory = createAsyncThunk(
  "update/urlDirectory",
  async ({ urlDirectoryId, requestParams }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(
        `/urlDirectory/${urlDirectoryId}`,
        requestParams
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const deleteUrlDirectory = createAsyncThunk(
  "delete/urlDirectory",
  async (urlDirectoryId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(
        `/urlDirectory/${urlDirectoryId}`
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
