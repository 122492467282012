import React, { useEffect, useCallback } from "react";
import { useToast } from "@chakra-ui/react";

const Notification = ({ title, description, status, onCloseToast, time }) => {
  const toast = useToast();
  const showToast = useCallback(() => {
    if (title) {
      toast({
        title: "",
        description: title || "",
        status: status || "success",
        position: "top",
        duration: time ? time : 3000,
        isClosable: true,
        onCloseComplete: onCloseToast,
      });
    }
  }, [title]);

  useEffect(() => {
    showToast();
  }, [showToast]);

  return null;
};

export default Notification;
