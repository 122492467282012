// CoachbarService.js
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Notification from "../../components/Notification";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getCoachbarServiceList,
  getCoachbarServicePurchasedList,
} from "./coachbarServicesActions";
import { Loader, TipsLinkButton } from "@coachbar/shared-components";
import {
  Tabs,
  TabList,
  Tab,
  HStack,
  Text,
  Box,
  Flex,
  TabIndicator,
} from "@chakra-ui/react";
import { clearMessage } from "./coachbarServicesSlice";
import CoachbarServiceList from "./CoachbarServiceList";
import { getDateString } from "../../services/utility";
import { useRBAC } from "../../services/customHook";
import { coachbarServicesModule } from "../../constants/moduleNames";
import NoRequestCompleted from "../../image/no-request-completed.png";
import NoRequestRaised from "../../image/no-request-raised.png";
import ProfileNoRecordsFound from "../../components/ProfileNoRecordsFound";
import DateFilter from "@coachbar/shared-components/src/components/DateFilter/DateFilter";
const pages = ["services", "request-raised", "request-completed"];

const serviceStatus = [
  { slug: "SERVICE_LIST", title: "Services" },
  { slug: "REQUEST_RAISED", title: "Request Raised" },
  { slug: "REQUEST_COMPLETED", title: "Request Completed" },
];

const defaultDateRange = {
  to: "",
  from: "",
};

const TIPS_LINK_PAGE_COACHBAR_SERVICES =
  "https://support.coachbar.io/support/solutions/articles/153000018988-getting-started-guide-software-consultant#Coachbar-Services";

const CoachbarService = () => {
  const {
    coachbarServiceList,
    coachbarServicePurchasedList,
    loading,
    error,
    success,
  } = useSelector((state) => state.coachbarServices);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.auth);
  const [filterDateRange, setFilterDateRange] = useState(defaultDateRange);
  const subscriptionDetails = userInfo.subscriptionStore;
  const remainingCredits =
    subscriptionDetails?.allocatedCredits -
    (subscriptionDetails?.usedCredits +
      (subscriptionDetails?.expiredCredits ?? 0));

  const { hasActionPermission } = useRBAC();
  let moduleName = coachbarServicesModule;
  let permissions = {
    add: hasActionPermission(moduleName, "add"),
    edit: hasActionPermission(moduleName, "edit"),
    view: hasActionPermission(moduleName, "view"),
    delete: hasActionPermission(moduleName, "delete"),
  };

  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const page = new URLSearchParams(search).get("page");
  let pageIndex = pages.indexOf(page);
  let initialIndex = 0;
  if (pageIndex !== -1) {
    initialIndex = pageIndex;
  }
  const [tabIndex, setTabIndex] = useState(initialIndex);

  let initialized = false;

  useEffect(() => {
    if (!initialized) {
      initialized = true;
      if (tabIndex === 0) {
        dispatch(getCoachbarServiceList());
      }
    }
  }, [tabIndex]);

  let initializedRequest = false;

  useEffect(() => {
    if (!initializedRequest) {
      initializedRequest = true;
      if (tabIndex !== 0) {
        let requestData = {
          status: tabIndex === 1 ? "Raised" : "Completed",
          startDate: filterDateRange.from,
          endDate: filterDateRange.to,
        };
        dispatch(getCoachbarServicePurchasedList(requestData));
      }
    }
  }, [tabIndex, filterDateRange]);

  const onTabClick = (index = 0) => {
    // const newUrl = `${location.pathname}?page=${pages[index]}`;
    // window.history.replaceState(null, "", newUrl);
    setTabIndex(index);
  };

  const handleDateChange = (range) => {
    setFilterDateRange(range);
  };

  return (
    <Box>
      {error || success ? (
        <Notification
          title={error ? error : success}
          status={error ? "error" : "success"}
          onCloseToast={() => dispatch(clearMessage())}
        />
      ) : (
        ""
      )}
      <Flex
        boxShadow="0px 2px 4px 0px #E5E7EB"
        alignItems={"center"}
        justifyContent={"space-between"}
        bgColor={"var(--chakra-colors-white)"}
        pos={"relative"}
        zIndex={99}
      >
        <CreateTabList
          onTabClick={onTabClick}
          referralData={""}
          isLoading={loading}
          tabIndex={tabIndex}
        />
        {permissions.add && permissions.edit && (
          <Flex alignItems={"center"} pr={"24px"} gap={"20px"}>
            <Text fontSize={"14px"} fontWeight={500} color={"#556781"}>
              Remaining Credits:{" "}
              <span
                style={{
                  color: remainingCredits < 2 ? "#E02424" : "#0C94AC",
                  fontWeight: 600,
                }}
              >
                {remainingCredits || 0}
              </span>
            </Text>
            <TipsLinkButton to={TIPS_LINK_PAGE_COACHBAR_SERVICES} />
            {permissions.add && permissions.edit && remainingCredits < 2 && (
              <Box
                fontSize={"14px"}
                fontWeight={500}
                color={"#0C94AC"}
                cursor={"pointer"}
                p={"6px 16px"}
                bg={"#E7FAFD"}
                border={"1px solid #CEEAEE"}
                rounded={"4px"}
                onClick={() => navigate("/my-subscription/subscription")}
              >
                Buy Credits
              </Box>
            )}
          </Flex>
        )}
      </Flex>
      <Box
        p={"24px"}
        m={"24px"}
        rounded={"8px"}
        boxShadow="0px 0px 4px 0px #E6E7E9"
        bgColor={"var(--chakra-colors-white)"}
      >
        {tabIndex === 0 ? (
          <>
            {loading ? (
              <Loader />
            ) : (
              <CoachbarServiceList
                permissions={permissions}
                cbServiceList={coachbarServiceList}
                onTabClick={onTabClick}
              />
            )}
          </>
        ) : (
          <CoachbarRequests
            servicePurchasedList={coachbarServicePurchasedList}
            tabIndex={tabIndex}
            handleDateChange={handleDateChange}
            filterDateRange={filterDateRange}
            loading={loading}
          />
        )}
      </Box>
    </Box>
  );
};

function CreateTabList(props) {
  return (
    <Tabs isLazy index={props.tabIndex} position="relative" variant="unstyled">
      <TabList
        borderBottom={"1px solid #E7FAFD"}
        gap={"24px"}
        px={"24px"}
        bgColor={"var(--chakra-colors-white)"}
        pos={"relative"}
        zIndex={99}
      >
        {serviceStatus.map((service, index) => (
          <Tab
            p={"18px 0"}
            fontWeight={"600"}
            fontSize={"14px"}
            color={"brandGray.400"}
            borderBottom={"1px solid transparent"}
            key={`tabList${index}`}
            onClick={() => {
              props.onTabClick(index);
            }}
            _selected={{
              color: "var(--chakra-colors-cyprus-500)",
            }}
          >
            {service["title"]}
          </Tab>
        ))}
      </TabList>
      <TabIndicator
        height="3px"
        bg="var(--chakra-colors-cyprus-500)"
        top={"55px"}
        borderRadius="2px 2px 0px 0px"
        zIndex={199}
      />
    </Tabs>
  );
}

function CoachbarRequests(props) {
  return (
    <Box minHeight={"calc(100vh - 216px)"}>
      <HStack justifyContent={"flex-end"} mb={"16px"}>
        <DateFilter
          handleDateChange={props.handleDateChange}
          selectedDateRange={defaultDateRange}
          moduleForId="coachbar-services"
          showByDefaultAllTimeOption
        />
      </HStack>
      {props.loading ? (
        <Loader />
      ) : props.servicePurchasedList.length > 0 ? (
        props.servicePurchasedList.map((servicePurchasedData, index) => {
          return (
            <>
              <Box
                key={servicePurchasedData.id + "-service-id"}
                bg={"#fff"}
                border={"1px solid #E6E7E9"}
                rounded={"8px"}
                overflow={"hidden"}
                p={"16px 20px"}
                mb={"16px"}
              >
                <HStack justifyContent={"space-between"}>
                  <HStack>
                    <Text
                      fontSize={"14px"}
                      fontWeight={600}
                      color={"var(--title-black-text)"}
                    >
                      {servicePurchasedData.id}
                    </Text>
                    <Text
                      fontSize={"12px"}
                      fontWeight={500}
                      p={"4px 10px"}
                      rounded={"4px"}
                      color={
                        servicePurchasedData.tag === "SUBMITTED"
                          ? "#2C885C"
                          : "brand.50"
                      }
                      bg={
                        servicePurchasedData.tag === "SUBMITTED"
                          ? "#EBF6F1"
                          : "brand.50"
                      }
                    >
                      {servicePurchasedData.tag}
                    </Text>
                  </HStack>
                  <Box fontSize={"12px"} color={"#5E6977"} fontWeight={500}>
                    {getDateString(servicePurchasedData.modified)}
                  </Box>
                </HStack>

                <Text
                  color={"#5E6977"}
                  fontSize={"12px"}
                  fontWeight={400}
                  mt={"8px"}
                >
                  {servicePurchasedData.message}
                </Text>
              </Box>
            </>
          );
        })
      ) : (
        <ProfileNoRecordsFound
          imageUrl={props.tabIndex === 1 ? NoRequestRaised : NoRequestCompleted}
          title={
            props.tabIndex === 1
              ? "No request raised!"
              : "No request completed!"
          }
        />
      )}
    </Box>
  );
}

export default CoachbarService;
