import React from "react";
import { useSelector } from "react-redux";
import LMSLogin from "@coachbar/shared-components/src/screens/LMSLogin";
import { litmosLogin } from "./LearningCenterActions";

const LearningCenter = () => {
  const { userInfo } = useSelector((state) => state.auth);
  return (
    <LMSLogin
      litmosLogin={litmosLogin}
      isPartner={true}
      isLitmosUser={userInfo?.litmosUserId ?? false}
      moduleForId="lerning-center"
    />
  );
};

export default LearningCenter;
