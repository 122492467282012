import { Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useRBAC } from "../../../services/customHook";
import { lmsModule } from "../../../constants/moduleNames";
import AssetListing from "@coachbar/shared-components/src/screens/assets/AssetListing";
import {
  downloadFile,
  downloadFolder,
  getAssetList,
  updateAssetTagList,
} from "./assetsActions";
import { getPartnerProviderList } from "../../myProviders/providersList/providersListActions";
import {
  createTag,
  getTagListBySearch,
} from "../../systemTag/systemTagActions";

const Assets = () => {
  const { hasActionPermission } = useRBAC();
  let moduleName = lmsModule;
  let permissions = {
    add: hasActionPermission(moduleName, "add"),
    edit: hasActionPermission(moduleName, "edit"),
    assigned: hasActionPermission(moduleName, "assigned"),
    view: hasActionPermission(moduleName, "view"),
    delete: hasActionPermission(moduleName, "delete"),
    fullAccess: hasActionPermission(moduleName, "Full_Access"),
  };

  const { userInfo } = useSelector((state) => state.auth);
  return (
    <Box name="Assets" p={"16px 24px"}>
      <AssetListing
        portal={"sc"}
        permissions={permissions}
        userInfo={userInfo}
        fetchPartnerOrProviderList={getPartnerProviderList}
        fetchAssetList={getAssetList}
        downloadFolder={downloadFolder}
        downloadFile={downloadFile}
        updateAssetTagList={updateAssetTagList}
        getTagListBySearch={getTagListBySearch}
        createTag={createTag}
      />
    </Box>
  );
};

export default Assets;
