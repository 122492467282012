export const roleOptions = [
  { label: "Owner", value: "Owner" },
  { label: "Admin", value: "Admin" },
  { label: "Manager", value: "Manager" },
  { label: "User", value: "User" },
];

export const schedulePeriods = [
  { label: "Hourly", value: "Hourly" },
  { label: "Daily", value: "Daily" },
  { label: "Weekly", value: "Weekly" },
  { label: "Monthly", value: "Monthly" },
];
export const commonLeadParams = [
  "Lead Name",
  "Lead Email",
  "Lead Company",
  "Software Provider",
  "Lead Source",
];

export const commonDealParams = [
  "Deal Amount",
  "Deal Created Date",
  "Deal Stage",
  "Deal Source",
];

export const commonApplicationParams = [
  "Provider Company",
  "Provider Name",
  "Provider Email",
];

export const commonInvitationsToAcceptRejectParams = [
  "Provider Company",
  "Provider Name",
  "Provider Email",
  "Date Sent",
];

export const commonProfileManagementParams = [
  "Provider Company",
  "Provider Name",
  "Provider Email",
];
export const commonProvider = [
  "Provider Company",
  "Provider Name",
  "Provider Email",
  "Provider Type",
  "Provider Status",
];
export const commonLMSParams = [...commonProvider];
export const commoContractParams = [
  "Provider Company Name",
  "Provider Name",
  "Provider Email",
  "Date Uploaded",
  "Provider Manager",
];
export const commonIncentiveProgramParams = [
  "Provider Type",
  "Partner Company",
  "Incentive Program Name",
  "User Who Created The Program",
  "Provider Company",
];
export const commonRewardsCalculationParams = [
  "Provider Type",
  "Partner Company",
  "Incentive Program Name",
  "Provider Company",
];

export const insertParameter = {
  "lead received": [...commonLeadParams, "Lead Created Date", "Lead Status"],
  "lead accepted/rejected": [
    ...commonLeadParams,
    "Lead Created Date",
    "Lead Status",
    "User Name",
  ],
  "lead assigned": [
    ...commonLeadParams,
    "User Name",
    "Assignee",
    "Lead Created Date",
    "Lead Status",
  ],
  "lead submitted to software provider(s)": [
    ...commonLeadParams,
    "Assignee",
    "Lead Created Date",
    "Lead Status",
  ],

  "lead accepted/rejected by software provider": [
    ...commonLeadParams,
    "Lead Owner",
    "Lead Created Date",
    "Lead Status",
  ],
  "leads synced to hubspot": [],
  "deal received": [...commonLeadParams, ...commonDealParams],
  "deal accepted/rejected": [
    ...commonLeadParams,
    ...commonDealParams,
    "User Name",
  ],
  "deal accepted/rejected by software provider": [
    ...commonLeadParams,
    "Lead Created Date",
    "Lead Status",
    "Lead Owner",
    ...commonDealParams,
    "Deal Owner",
  ],
  "deal assigned": [
    ...commonLeadParams,
    ...commonDealParams,
    "Assignee",
    "User Name",
  ],
  "deal submitted to software provider(s)": [
    ...commonLeadParams,
    ...commonDealParams,
    "Assignee",
  ],
  "deals synced to hubspot": [],
  "leads to accept": [...commonLeadParams, "Lead Created Date", "Lead Status"],
  "leads to assign": [...commonLeadParams, "Lead Created Date", "Lead Status"],
  "leads to submit": [
    ...commonLeadParams,
    "Lead Created Date",
    "Lead Status",
    "Lead Owner",
  ],
  "leads submitted not accepted/rejected": [
    ...commonLeadParams,
    "Lead Created Date",
    "Lead Status",
    "Lead Submitted Date",
  ],
  "deals to accept": [...commonLeadParams, ...commonDealParams],
  "deals to assign": [...commonLeadParams, ...commonDealParams],
  "deals to submit": [...commonLeadParams, ...commonDealParams, "Deal Owner"],
  "deals submitted not accepted/rejected": [
    ...commonLeadParams,
    ...commonDealParams,
    "Deal Owner",
    "Deal Submitted Date",
  ],
  // deal and lead complete
  "invitation received": [
    ...commonApplicationParams,
    "Software Provider",
    "Application Status",
    "Date Received",
  ],
  "application accepted/rejected": [
    ...commonApplicationParams,
    "Software Provider",
    "Application Status",
    "Reject Reason",
    "Date Accepted/Rejected",
  ],
  "invitation accepted/rejected": [
    ...commonApplicationParams,
    "Software Provider",
    "Invitation Status",
    "Date Accepted/Rejected",
    "Date Received",
  ],
  "invitations to accept/reject": commonInvitationsToAcceptRejectParams,
  // partner application complete
  "profile has been published on partner directory": [
    ...commonProfileManagementParams,
    "Date Published",
  ],
  "profile has been removed from partner directory": [
    ...commonProfileManagementParams,
    "Date Published",
    "Date Unpublished",
  ],
  "partner profiles requirements not fulfilled": [
    ...commonProfileManagementParams,
  ],
  // profile management complete
  "partner archived": [...commonProvider],
  "provider to assign": [...commonProvider],
  // provider notificaton complete

  "your company added to lms": [...commonLMSParams],
  "partner user added to lms": [
    ...commonLMSParams,
    "Created Date",
    "Partner User First/Last Name",
    "Partner User Email",
  ],
  "partner company passed the course": [
    ...commonLMSParams,
    "Created Date",
    "Partner User First/Last Name",
    "Partner User Email",
    "Course Completion Date",
    "Course Title",
  ],
  "partner company started the course": [
    ...commonLMSParams,
    "Created Date",
    "Partner User First/Last Name",
    "Partner User Email",
    "Course Completion Date",
    "Course Title",
    "Course Start Date",
  ],
  "courses not completed": [...commonLMSParams, "Course Title"],
  "contract received": [...commoContractParams],
  "signed contract uploaded": [...commoContractParams],
  "providers who did not sign the contracts": [...commoContractParams],
  "incentive program assigned by the provider": [
    ...commonIncentiveProgramParams,
  ],
  "reward is calculated": [...commonRewardsCalculationParams],
  "inquiry received": [
    ...commonRewardsCalculationParams,
    "Deal Id",
    "Provider Manager",
  ],
  "clawback applied": [
    ...commonRewardsCalculationParams,
    "Deal Id",
    "Provider Manager",
  ],
  clawback: [...commonRewardsCalculationParams, "Deal Id", "Provider Manager"],
};

export const notificationsModules = [
  {
    key: "Lead_And_Deal",
    moduleTitle: "Lead and Deals Notifications",
  },
  {
    key: "Application_And_Recruitment",
    moduleTitle: "Partner Applications and Recruitment Notifications",
  },
  {
    key: "Profile_Management",
    moduleTitle: "Partner Profile Management Notifications",
  },
  {
    key: "Provider",
    moduleTitle: "Provider Notifications",
  },
  {
    key: "LMS",
    moduleTitle: "LMS Notifications",
  },
  {
    key: "Contract",
    moduleTitle: "Contract Notifications",
  },
  {
    key: "Incentive_Program",
    moduleTitle: "Incentive Program Notifications",
  },
  {
    key: "Rewards_Calculation",
    moduleTitle: "Rewards Calculation Notifications",
  },
];
