import { Link } from "../..";
import { Text } from "@chakra-ui/react";
import { ReactComponent as LightBulb02 } from "@coachbar/shared-components/src/images/lightbulb-02.svg";

const TipsLinkButton = ({
  Icon,
  title,
  to,
  hideIcon,
  hideText,
  linkProps = {},
}) => {
  return (
    <Link
      decoration="none"
      target="_blank"
      to={to || "#"}
      textDecoration="none"
      color="brandGray.500"
      bg="transparent"
      stroke="brandGray.500"
      fill="none"
      _hover={{
        stroke: "cyprus.500",
        fill: "cyprus.50",
        bg: "cyprus.50",
        color: "cyprus.500",
      }}
      display="flex"
      alignItems="center"
      gap="8px"
      p="9px 12px"
      borderRadius={"4px"}
      {...linkProps}
    >
      {!hideIcon && (Icon || <LightBulb02 height="22px" width="22px" />)}
      {!hideText && (
        <Text
          fontSize={"14px"}
          lineHeight={"18px"}
          fontWeight={500}
          textAlign={"center"}
          mt="4px"
        >
          {title || "Tips"}
        </Text>
      )}
    </Link>
  );
};

export default TipsLinkButton;
