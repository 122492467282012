import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../services/axiosInstance";
import {
  setUserInfo,
  setUserToken,
  getErrorMessage,
  handleError,
} from "../../services/utility";
import { getRoleDetails } from "../settings/usersAndRoles/usersAndRolesActions";
import {
  getSelectedCurrencyObj,
  setCurrency,
} from "@coachbar/shared-components/src/services/utility";

const setSessionData = (data, roleData) => {
  if (data.data && data.data.tokens) {
    setUserToken(data.data.tokens);
  }
  if (data.data && data.data.userInfo) {
    setUserInfo({ ...data.data.userInfo, roleData: roleData });
  }
};

/**
 * The second argument — thunkAPI — is an object containing parameters usually passed to a Redux thunk function.
 * Parameters include getState, dispatch, rejectWithValue, and more
 */
export const registerUser = createAsyncThunk(
  "auth/register",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/setup/signUp`, {
        firstName: data.firstName,
        lastName: data.lastName,
        type: data.iam,
        email: data.email,
        password: data.password,
        companyName: data.companyName,
        userType: data.userType,
      });
      return response.data;
    } catch (error) {
      // return custom error message from backend if present
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const registerUserViaGoogle = createAsyncThunk(
  "auth/registerViaGoogle",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/setup/signUp`, {
        googleIdToken: data.googleIdToken,
      });
      if (response.data.data.tokens?.isRedirect) {
        localStorage.setItem(
          "isRedirect",
          response.data.data.tokens?.isRedirect
        );
      }
      // get role based data
      let roleData = { roles: {} };
      if (response.data.data.userInfo.roleId) {
        let tokenString = JSON.stringify(response.data.data.tokens.idToken);
        roleData = await getRoleDetails(
          {
            id: response.data?.data?.userInfo?.roleId,
          },
          tokenString.substring(1, tokenString.length - 1)
        );
      }
      setSessionData(response.data, roleData.roles);
      return response.data;
    } catch (error) {
      // return custom error message from backend if present
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const userLogin = createAsyncThunk(
  "auth/login",
  async (requestData, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const { data } = await axiosInstance.post(`/setup/login`, requestData);
      if (data.data.tokens?.isRedirect) {
        localStorage.setItem("isRedirect", data.data.tokens?.isRedirect);
      }
      // set currency
      if (data?.user?.currencyCode)
        setCurrency(getSelectedCurrencyObj(data?.user?.currencyCode));
      // get role based data
      let roleData = { roles: {} };
      if (data.data?.userInfo?.roleId) {
        let tokenString = JSON.stringify(data.data.tokens.idToken);
        roleData = await getRoleDetails(
          {
            id: data?.data?.userInfo?.roleId,
          },
          tokenString.substring(1, tokenString.length - 1)
        );
      }
      // store user's token in local storage
      setSessionData(data, roleData.roles);
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data?.code === 412
      ) {
        return error.response.data;
      }
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async ({ email }, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const { data } = await axiosInstance.post(`/setup/forgotPassword`, {
        email,
      });
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const newPassword = createAsyncThunk(
  "auth/newPassword",
  async (requestData, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const { data } = await axiosInstance.post(
        `/setup/confirmPassword`,
        requestData
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const verifyUser = createAsyncThunk(
  "auth/verifyUser",
  async (requestData, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const { data } = await axiosInstance.post(
        `/setup/confirmUser`,
        requestData
      );
      // store user's token in local storage
      setSessionData(data);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const resendConfirmationCode = createAsyncThunk(
  "auth/resendCode",
  async (email, { rejectWithValue }) => {
    try {
      let encodeEmail = encodeURIComponent(email);
      // configure header's Content-Type as JSON
      const { data } = await axiosInstance.get(
        `/setup/resendConfirmationCode?email=${encodeEmail}`
      );
      // store user's token in local storage
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const checkEmailExists = createAsyncThunk(
  "auth/emailVerify",
  async (email, { rejectWithValue }) => {
    try {
      let encodeEmail = encodeURIComponent(email);
      // configure header's Content-Type as JSON
      const { data } = await axiosInstance.get(
        `/setup/verify?email=${encodeEmail}`
      );
      // store user's token in local storage
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getPlanDetail = createAsyncThunk(
  "auth/getPlan",
  async (userId, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const { data } = await axiosInstance.get(`/subscription/plans`);
      // store user's token in local storage
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getInvitationDetails = async (requestParams) => {
  try {
    const { data } = await axiosInstance.post(
      `/sp/setup/verifyScInvite`,
      {
        ...requestParams,
      },
      {
        baseURL: process.env.REACT_APP_API_BASE_URL.slice(
          0,
          process.env.REACT_APP_API_BASE_URL.length - 3
        ),
      }
    );
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const verifyInvite = async (requestData) => {
  try {
    // configure header's Content-Type as JSON
    const { data } = await axiosInstance.post(
      `/sp/setup/verifyInvite`,
      { ...requestData },
      {
        baseURL: process.env.REACT_APP_API_BASE_URL.slice(
          0,
          process.env.REACT_APP_API_BASE_URL.length - 3
        ),
      }
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    if (error.response.data.code === 400) {
      const data = { data: error.response.data };
      return data;
    } else {
      return getErrorMessage(error);
    }
  }
};

export const acceptInvite = createAsyncThunk(
  "auth/acceptInvite",
  async (requestData, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const { data } = await axiosInstance.post(
        `/sp/setup/acceptInvite`,
        { ...requestData },
        {
          baseURL: process.env.REACT_APP_API_BASE_URL.slice(
            0,
            process.env.REACT_APP_API_BASE_URL.length - 3
          ),
        }
      );

      let roleData = { roles: {} };
      if (data.data.userInfo.roleId) {
        // get role based data
        let tokenString = JSON.stringify(data.data.tokens.idToken);
        roleData = await getRoleDetails(
          {
            id: data?.data?.userInfo?.roleId,
          },
          tokenString.substring(1, tokenString.length - 1)
        );
      }
      // store user's token in local storage
      setSessionData(data, roleData.roles);
      return data;
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data?.code === 412
      ) {
        return error.response.data;
      }
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getUserDetails = async () => {
  try {
    const { data } = await axiosInstance.get(`user`);
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const updateCurrency = async (currencyCode) => {
  try {
    const { data } = await axiosInstance.post("user/setCurrency", {
      currencyCode: currencyCode,
    });
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const verifyResetPasswordOtp = createAsyncThunk(
  "auth/verify-otp",
  async (payload, { rejectWithValue }) => {
    try {
      // configure header's Content-Type as JSON
      const { data } = await axiosInstance.post(
        "/setup/verifyConfirmationCode",
        payload
      );
      // store user's token in local storage
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
