import {
  Card,
  CardBody,
  CardHeader,
  Stack,
  Heading,
  SimpleGrid,
  Divider,
  Box,
  Flex,
  Spacer,
  ListItem,
  UnorderedList,
  Text,
  Radio,
  RadioGroup,
  HStack,
  Image,
  Input,
  FormErrorMessage,
  FormControl,
  Skeleton,
} from "@chakra-ui/react";
import { Button, Link } from "@coachbar/shared-components";
import Notification from "../../components/Notification";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import ExcelImage from "../../image/excel.svg";
import { getPreSignedUrl, uploadImage } from "../../services/utility";
import {
  maxFileSize,
  excelTypeAllowed,
} from "../../constants/validationMessages";
import { useNavigate } from "react-router-dom";
import { DeleteIcon } from "@chakra-ui/icons";
import { importReferral } from "./referralActions";

const MAX_FILE_SIZE = 200; // in KB;

const ImportReferral = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [value, setValue] = useState("Import");
  const [errorMessage, setErrorMsg] = useState("");
  const [success, setApiSuccess] = useState("");
  const [error, setApiError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [filePath, setFilePath] = useState("");

  const validateAndUploadFile = (event) => {
    let files = event.target.files;
    if (errorMessage) setErrorMsg("");
    if (filePath) setFilePath("");

    const fileObj = files && files[0];
    if (!fileObj || !fileObj.type) {
      return true;
    }

    const type = fileObj.type;
    if (
      !type ||
      (type !== "text/csv" &&
        type !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
    ) {
      setErrorMsg(excelTypeAllowed);
      return excelTypeAllowed;
    }

    const fsMb = fileObj.size / 1024;
    let maxAllowedFileSize = MAX_FILE_SIZE;

    if (fsMb > maxAllowedFileSize) {
      setErrorMsg(maxFileSize.replace("$MB$", maxAllowedFileSize + "KB"));
      return true;
    }

    uploadFile(fileObj);
    return true;
  };

  const uploadFile = (fileObj) => {
    let fileExt = fileObj.name.split(".").pop();
    let filePathUrl = "import-referral-" + Date.now() + "." + fileExt;
    setIsLoading(true);
    getPreSignedUrl(filePathUrl).then((responseData) => {
      if (responseData.data && responseData.data.success) {
        const preSignedUrl = responseData.data?.data?.signedUrl;
        uploadImageFileToS3(fileObj, preSignedUrl);
      }
    });
  };

  const uploadImageFileToS3 = async (fileObj, preSignedUrl) => {
    uploadImage(fileObj, preSignedUrl).then((responseData) => {
      if (responseData.status === 200) {
        let imageUrl = preSignedUrl.split("?")[0];
        setFilePath(imageUrl);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const onSubmit = () => {
    if (filePath) {
      if (value === "Import") {
        setIsLoading(true);
        dispatch(importReferral({ requestParams: { url: filePath } })).then(
          (response) => {
            console.log(response);
            if (response && response.payload && response.payload.success) {
              setApiSuccess(response.payload.message);
            } else {
              setApiError(response);
            }
            setFilePath("");
            setIsLoading(false);
          }
        );
      }
    } else {
      setErrorMsg("Excel file is required");
    }
  };

  const onCancel = () => {
    navigate("/referrals");
  };

  const onRemoveClick = () => {
    setFilePath("");
  };

  const clearMessage = () => {
    setApiError("");
    setApiSuccess("");
  };

  return (
    <Stack spacing="5" m="5" align="stretch">
      {error || success ? (
        <Notification
          title={error ? error : success}
          status={error ? "error" : "success"}
          onCloseToast={() => clearMessage()}
        />
      ) : (
        ""
      )}
      <Card variant="form">
        <CardHeader>
          <Heading size="md">Import from file</Heading>
        </CardHeader>
        <Divider />
        <CardBody>
          <Flex>
            <Stack width={"100%"}>
              <Text as="b">I want to</Text>
              <RadioGroup onChange={setValue} value={value}>
                <Stack spacing={5} direction="row">
                  <Radio value="Import" colorScheme={"brand"}>
                    Import New Referrals
                  </Radio>
                  {/* <Radio value="Update" colorScheme={"brand"}>
                    Update Existing Referrals
                  </Radio> */}
                </Stack>
              </RadioGroup>
              <Box mt="1.75rem !important">
                <Text as="b">Upload document file</Text>
              </Box>
              {isLoading ? (
                <Skeleton height={"5rem"} width={"100%"} />
              ) : !filePath ? (
                <>
                  <Box
                    minH={"5rem"}
                    borderColor="gray.300"
                    borderStyle="dashed"
                    borderWidth="2px"
                    rounded="md"
                    shadow="sm"
                    transition="all 150ms ease-in-out"
                    _hover={{
                      shadow: "md",
                    }}
                    bg="white"
                    display={"flex"}
                  >
                    <SimpleGrid columns={2} templateColumns={"2fr 1fr"}>
                      <HStack spacing={3}>
                        <Image
                          src={ExcelImage}
                          alt={`Excel`}
                          boxSize="40px"
                          objectFit="cover"
                          ml="1rem"
                        />
                        <Text fontSize={"sm"}>
                          Drag and drop your file or upload from computer
                        </Text>
                        <Button
                          minW={"4rem"}
                          variant={"specializationButton"}
                          title="Browse"
                          id="referrals-browse-referral"
                        />
                      </HStack>
                      <Input
                        left="-200%"
                        width="300% !important"
                        zIndex={2}
                        type="file"
                        height="100%"
                        opacity="0 !important"
                        aria-hidden="true"
                        onChange={validateAndUploadFile}
                        cursor={"pointer"}
                        accept={".xlsx, .xls, .csv"}
                        multiple={false}
                      />
                    </SimpleGrid>
                  </Box>
                  <FormControl
                    name={"import"}
                    isInvalid={errorMessage ? true : false}
                    isRequired={true}
                  >
                    <FormErrorMessage>{errorMessage}</FormErrorMessage>
                  </FormControl>
                </>
              ) : (
                <HStack spacing={3}>
                  <Image
                    src={ExcelImage}
                    alt={`Excel`}
                    boxSize="40px"
                    objectFit="cover"
                  />
                  <Text fontSize={"sm"}>{filePath.split("/").slice(-1)}</Text>
                  <DeleteIcon
                    cursor={"pointer"}
                    boxSize={4}
                    color={"red.500"}
                    onClick={onRemoveClick}
                  />
                </HStack>
              )}
            </Stack>
            <Spacer />
            <Stack
              maxW={"20rem"}
              p={4}
              ml={8}
              borderRadius={"8px"}
              bg={"brandGray.10"}
            >
              <Text as="b">Instructions</Text>
              <UnorderedList ml={"1.25rem !important"}>
                <ListItem pb="0.75rem">
                  Make sure you have entered all the data in right order
                </ListItem>
                <ListItem pb="0.75rem">
                  Your file should be in .xls, .xlsx or .csv format only
                </ListItem>
                <ListItem pb="0.75rem">
                  If you don’t have sample file for referrals then you can
                  download it from below
                </ListItem>
              </UnorderedList>
              <HStack spacing={3} ml={"1rem !important"}>
                <Image
                  src={ExcelImage}
                  alt={`Excel`}
                  boxSize="20px"
                  objectFit="cover"
                />
                <Link
                  fontWeight={500}
                  fontSize={"1rem"}
                  to={
                    "https://coachbar.s3.amazonaws.com/sample-files/Template-Import-Referral.xlsx"
                  }
                  title="Download Sample"
                />
              </HStack>
            </Stack>
          </Flex>
          <HStack spacing={4}>
            <Button
              type="button"
              isLoading={isLoading}
              title="Import Referral"
              onClick={onSubmit}
              id="referrals-import-referral"
            />
            <Button
              onClick={onCancel}
              variant={"discard"}
              isLoading={isLoading}
              title="Cancel"
              id="referrals-cancel-import"
            />
          </HStack>
        </CardBody>
      </Card>
    </Stack>
  );
};

export default ImportReferral;
