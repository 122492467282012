import { Box, Flex, HStack, IconButton, Image, Text } from "@chakra-ui/react";
import React from "react";
import Select, { components } from "react-select";
import fallbackImage from "../image/fallbackImage.png";

const ClientReactSelect = ({
  clientInfoList,
  clientList,
  selectedClient,
  setSelectedClient,
  customStyles,
  addClientOpen,
}) => {
  const Menu = (props) => {
    return (
      <components.Menu {...props}>
        <>
          <div>{props.children}</div>
          {clientInfoList?.length < 10 && (
            <div className={"add-client-react"}>
              <button
                onClick={() => addClientOpen()}
                id="add-client-modal-open"
              >
                Add Client
              </button>
            </div>
          )}
        </>
      </components.Menu>
    );
  };

  const Option = ({ innerProps, label, value }) => {
    const logo = clientInfoList?.filter((item) => value === item.id)[0]?.logo;

    return (
      <div {...innerProps}>
        <HStack
          alignItems={"center"}
          cursor={"pointer"}
          p={"8px"}
          spacing={"8px"}
          bg={"#fff"}
          _hover={{ bg: "#DEEBFF" }}
        >
          <Image
            src={logo || fallbackImage}
            boxSize={"25px"}
            objectFit={"cover"}
          />
          <Text fontSize={"16px"} fontWeight={400}>
            {label}
          </Text>
        </HStack>
      </div>
    );
  };

  //   const ValueContainer = ({ children, ...props }) => {
  //     const valueLength = props.getValue();
  //     const handleRemoveValue = (valueToRemove) => {
  //       props.setValue(valueLength.filter((value) => value !== valueToRemove));
  //     };
  //     return (
  //       <Flex flexWrap={"wrap"} flex={1} p={"2px 8px"}>
  //         {valueLength?.map((val) => {
  //           const logo = clientInfoList?.filter(
  //             (item) => val.value === item.id
  //           )[0]?.logo;
  //           return (
  //             <HStack
  //               alignItems={"center"}
  //               border="1px solid #DEE8F8"
  //               overflow="hidden"
  //               p="4px 8px"
  //               spacing={"2px"}
  //               bg={"#F3F7FF"}
  //               rounded={"16px"}
  //               margin={"2px"}
  //               whiteSpace={"nowrap"}
  //             >
  //               <Image
  //                 rounded={"50%"}
  //                 overflow={"hidden"}
  //                 src={logo || fallbackImage}
  //                 boxSize={"15px"}
  //                 objectFit={"cover"}
  //               />
  //               <Text
  //                 textTransform={"capitalize"}
  //                 color={"#111A29"}
  //                 fontSize="12px"
  //                 fontWeight={500}
  //                 pr={"6px"}
  //               >
  //                 {val.label}
  //               </Text>
  //               <Box cursor={"pointer"} onClick={() => handleRemoveValue(val)}>
  //                 <svg
  //                   fill={"#7E8792"}
  //                   height="14"
  //                   width="14"
  //                   viewBox="0 0 20 20"
  //                   aria-hidden="true"
  //                 >
  //                   <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
  //                 </svg>
  //               </Box>
  //             </HStack>
  //           );
  //         })}
  //       </Flex>
  //     );
  //   };

  return (
    <Select
      menuPlacement={"top"}
      closeMenuOnSelect={false}
      options={clientList}
      value={selectedClient}
      onChange={(e) => {
        setSelectedClient(e);
      }}
      styles={customStyles}
      placeholder={"Select Clients"}
      isSearchable={true}
      components={{ Menu, Option }}
      isMulti
    />
  );
};

export default ClientReactSelect;
