// Videos.js
import {
  Avatar,
  Box,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Image,
  SimpleGrid,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {
  AlertDialog,
  Button,
  Loader,
  Modal,
  TextBox,
  TipsLinkButton,
} from "@coachbar/shared-components";
import CustomRequirementPopover from "@coachbar/shared-components/src/components/CustomPopover/CustomRequirementPopover";
import {
  buttonVariants,
  scrollbarCSS,
} from "@coachbar/shared-components/src/constants/constantValues";
import NoRecordFoundScreen from "@coachbar/shared-components/src/screens/NoRecordFoundScreen";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Notification from "../../../components/Notification";
import { TIPS_LINK_PAGE_COMPANY_PROFILE } from "../../../constants/profileSetup";
import {
  permissionTypes,
  userRoleEnums,
} from "../../../constants/usersAndRoles";
import {
  invalidUrl,
  requiredField,
} from "../../../constants/validationMessages";
import { urlValidationPattern } from "../../../constants/validationPatterns";
import InfoOutline from "../../../image/Info-outline.svg";
import CoachBarLogoIcon from "../../../image/coachbar-logo-icon.png";
import emptyVideo from "../../../image/emptyVideo.png";
import greenCheckFill from "../../../image/green-check.svg";
import infoIcon from "../../../image/yellow-info-icon.svg";
import {
  appendHttpsToUrl,
  getCurrentPartnerData,
  hasPermissionManagerSpecialCase,
  removeHttpsFromUrl,
} from "../../../services/utility";
import { getPartnerProviderList } from "../../myProviders/providersList/providersListActions";
import { getActiveRequirements } from "../../serviceOffering/serviceOfferingActions";
import { getProfileCompletionStatus } from "../profileMetrics/profileMetricsAction";
import VideoCard from "./VideoCard";
import {
  addVideos,
  getPartnerVideoList,
  updateToStarVideo,
  updateVideos,
} from "./videoActions";
import { clearMessage } from "./videoSlice";

const PLATFORM_LABEL = "Description";
const LISTING_LABEL = "Link to Video";

const Videos = ({ permissions }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const { loading, error, videoList, success } = useSelector(
    (state) => state.video
  );

  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      //eslint-disable-next-line
      initialized = true;
      dispatch(
        getPartnerVideoList(process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID)
      );
      getPartnerList();
      getCurrPartnerVideoRecommendation(
        process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
      );
    }
  }, []);

  const submitForm = (data) => {
    if (!selectedPartners.length) {
      setSelectedPartnersError(true);
      return;
    }
    const selectedId = selectedVideos;
    data.url = appendHttpsToUrl(data.url);
    data.partnerTenantIdList = [...selectedPartners];
    if (selectedId) {
      dispatch(
        updateVideos({
          videoId: selectedId,
          requestParams: data,
        })
      ).then((responseData) => {
        if (responseData.payload.success && responseData.payload.data) {
          if (!videoList || (videoList && videoList?.length < 1)) {
            dispatch(getProfileCompletionStatus());
          }
          dispatch(getPartnerVideoList(currentPartner));
          onModalClose();
        }
      });
    } else {
      dispatch(addVideos(data)).then((responseData) => {
        if (responseData.payload.success && responseData.payload.data) {
          if (!videoList || (videoList && videoList?.length < 1)) {
            dispatch(getProfileCompletionStatus());
          }
          dispatch(getPartnerVideoList(currentPartner));
          onModalClose();
        }
      });
    }
  };

  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    onOpen: onAlertOpen,
    isOpen: isAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  const [selectedVideos, setSelectedVideos] = useState(null);

  const onModalClose = () => {
    if (selectedVideos) {
      setSelectedVideos(null);
    }
    setSelectedPartners([]);
    setSelectedPartnersError(false);
    reset();
    onClose();
  };

  const [videoIdToDelete, setVideosToDelete] = useState(null);

  const onAlertCloseAction = () => {
    if (videoIdToDelete?.id) {
      const requestParams = { ...videoIdToDelete, partnerTenantIdList: [] };
      onAlertClose();
      dispatch(
        updateVideos({
          videoId: videoIdToDelete?.id,
          requestParams,
        })
      ).then((responseData) => {
        if (responseData.payload.success && responseData.payload.data) {
          if (!videoList || (videoList && videoList?.length < 1)) {
            dispatch(getProfileCompletionStatus());
          }
          dispatch(getPartnerVideoList(currentPartner));
          setVideosToDelete(null);
        }
      });
    }
  };

  const onAlertOpenAction = (videoDetails) => {
    setVideosToDelete(videoDetails);
    onAlertOpen();
  };

  const [partnerList, setPartnerList] = useState([]);
  const [assignedPartnerList, setAssignedPartnerList] = useState([]);
  const [partnerListLoading, setPartnerListLoading] = useState(true);
  const [currentPartner, setCurrentPartner] = useState(
    process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID || ""
  );
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [currPartnerVideoRecommendation, setCurrPartnerVideoRecommendation] =
    useState(0);
  const [selectedPartnersError, setSelectedPartnersError] = useState(false);

  const getPartnerList = async () => {
    setPartnerListLoading(true);
    const partnerApiData = await getPartnerProviderList({});
    setPartnerList(partnerApiData?.partnerList || []);
    const assignedPartners =
      partnerApiData?.partnerList.filter(
        (partner) => partner.assignedUser === userInfo.id
      ) || [];
    setAssignedPartnerList(assignedPartners);
    setPartnerListLoading(false);
  };

  const getVideoCardList = (videoList) => {
    return (
      <SimpleGrid spacing={"20px"} m={"24px"} columns={[1, 2, 2, 3, 4]}>
        {videoList.map((video) => {
          return (
            <VideoCard
              key={video.id}
              video={video}
              onAlertOpenAction={onAlertOpenAction}
              permissions={permissions}
              editVideo={editVideo}
              handleUpdateToStarVideo={handleUpdateToStarVideo}
            />
          );
        })}
      </SimpleGrid>
    );
  };

  const handleUpdateModalIconClick = (selectedTenantId) => {
    setSelectedPartners((prevList) => {
      const isSelected = selectedPartners.includes(selectedTenantId);
      if (!isSelected) {
        if (selectedPartnersError) setSelectedPartnersError(false);
        return [...prevList, selectedTenantId];
      }
      return prevList.filter(
        (partnerTenantId) => partnerTenantId !== selectedTenantId
      );
    });
  };

  const editVideo = (video) => {
    let skipKeys = ["modified", "created", "id", "tenantId"];
    setSelectedVideos(video?.id);
    for (const key in video) {
      if (video.hasOwnProperty(key) && !skipKeys.includes(key)) {
        setValue(
          key,
          key === "url" ? removeHttpsFromUrl(video[key]) : video[key]
        );
      }
    }
    setSelectedPartners([
      ...(video?.partnerTenantIdList?.length ? video?.partnerTenantIdList : []),
    ]);
    onOpen();
  };

  const getCurrPartnerVideoRecommendation = async (tenantId) => {
    const companyData = await getActiveRequirements(tenantId);
    const companyVideoRquirements =
      companyData?.data?.requirement?.minimumNumberOfVideo;
    if (companyVideoRquirements)
      setCurrPartnerVideoRecommendation(companyVideoRquirements);
    else setCurrPartnerVideoRecommendation(0);
  };

  const getVideoAddButton = (spTenantId, btnProps = {}) => {
    const ButtonComp = (
      <Button
        {...btnProps}
        title={"Add Video"}
        onClick={() => {
          setSelectedPartners([spTenantId]);
          onOpen();
        }}
        variant={buttonVariants.brandPrimary}
        id="profile-setup-video-add"
      />
    );
    if (
      hasPermissionManagerSpecialCase(
        permissionTypes.add,
        spTenantId,
        userInfo,
        permissions,
        partnerList
      )
    )
      return ButtonComp;

    return null;
  };

  const handleUpdateToStarVideo = (videoId) => {
    dispatch(
      updateToStarVideo({
        videoId,
        spTenantId: currentPartner,
      })
    ).then((responseData) => {
      if (responseData.payload.success && responseData.payload.data) {
        dispatch(getPartnerVideoList(currentPartner));
      }
    });
  };

  const getAddModalPartnerList = () => {
    if (userInfo.role === userRoleEnums.manager) return assignedPartnerList;
    return partnerList;
  };

  const handleError = () => {
    if (!selectedPartners.length) setSelectedPartnersError(true);
  };

  return (
    <Box
      bg={"white"}
      p={"16px 0 0"}
      w={"100%"}
      h={"calc(100vh - 216px)"}
      rounded={"8px"}
      overflow={"auto"}
      boxShadow={"1px 0px 4px #E5E7EB"}
      css={scrollbarCSS}
    >
      {error || success ? (
        <Notification
          title={error ? error : success}
          status={error ? "error" : "success"}
          onCloseToast={() => dispatch(clearMessage())}
        />
      ) : (
        ""
      )}
      <Flex
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={"20px"}
        p="0 24px"
      >
        <Flex alignItems={"center"}>
          <Text
            color={"var(--title-black-text)"}
            fontSize={"14px"}
            lineHeight={"20px"}
            fontWeight={"700"}
            mr={"8px"}
          >
            Video Gallery
          </Text>
          {/* <Text
            as={"span"}
            fontWeight={"500"}
            color={"var(--text-font-color)"}
            fontSize={"12px"}
            pt={"4px"}
          >
            (Maximum limit 20)
          </Text> */}
        </Flex>
        <Flex alignItems={"center"} gap={"20px"}>
          <TipsLinkButton to={TIPS_LINK_PAGE_COMPANY_PROFILE} />
          {videoList.length > 0 && getVideoAddButton(currentPartner)}
        </Flex>
      </Flex>
      {partnerListLoading ? (
        <Loader />
      ) : (
        <Tabs position="relative" variant="unstyled">
          <TabList
            width={"100%"}
            overflow={"auto"}
            p="0 24px"
            gap="20px"
            borderBottom={"1px solid var(--chakra-colors-atlantic-100)"}
            css={scrollbarCSS}
          >
            <Tab
              whiteSpace={"nowrap"}
              fontSize={"14px"}
              fontWeight={600}
              color={"#7284A0"}
              marginBottom={0}
              p="8px 0"
              onClick={() => {
                setCurrentPartner(
                  process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
                );
                dispatch(
                  getPartnerVideoList(
                    process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
                  )
                );
                getCurrPartnerVideoRecommendation(
                  process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
                );
              }}
            >
              <Tooltip label="Coachbar" placement="bottom">
                <Avatar
                  size="xs"
                  name="Coachbar"
                  src={CoachBarLogoIcon}
                  mr={"4px"}
                />
              </Tooltip>
              Coachbar
            </Tab>
            {partnerList?.map((partner, i) => {
              return (
                <Tab
                  key={partner.referenceTenantId + i}
                  whiteSpace={"nowrap"}
                  fontSize={"14px"}
                  fontWeight={600}
                  color={"#7284A0"}
                  marginBottom={0}
                  p="8px 0"
                  onClick={() => {
                    setCurrentPartner(partner.referenceTenantId);
                    dispatch(getPartnerVideoList(partner.referenceTenantId));
                    getCurrPartnerVideoRecommendation(
                      partner.referenceTenantId
                    );
                  }}
                >
                  <Avatar
                    size="xs"
                    name={partner.spCompanyName}
                    src={partner?.logo}
                    mr={"4px"}
                  />
                  {partner.spCompanyName}
                </Tab>
              );
            })}
          </TabList>
          <TabIndicator
            height="3px"
            bg="#0C94AC"
            top={"38px"}
            borderRadius="2px 2px 0px 0px"
            zIndex={199}
          />
        </Tabs>
      )}
      {currPartnerVideoRecommendation > 0 && (
        <Flex
          p={"10px"}
          justifyContent={"center"}
          alignItems={"center"}
          bg={"#FDFDEA"}
          border={"1px solid #FDF6B2"}
          rounded={"6px"}
          m="20px 16px"
        >
          <Flex alignItems={"center"}>
            <Image h={"14px"} src={infoIcon} mr="8px" />
            <Text color={"#723B13"} fontSize={"12px"} fontWeight={450}>
              There are specific requirements for Partner Profiles to be
              published in the Partner Directory on the{" "}
              <span style={{ fontWeight: 600 }}>
                {getCurrentPartnerData(currentPartner, partnerList)
                  ?.scCompanyName || ""}
              </span>
              site. Please review the
            </Text>
            <Box position={"relative"}>
              <CustomRequirementPopover
                popoverTriggerChildren={
                  <Text
                    color={"#723B13"}
                    fontSize={"12px"}
                    fontWeight={600}
                    lineHeight={"18px"}
                    textDecor={"underline"}
                    cursor={"pointer"}
                    ml={"4px"}
                  >
                    recommendations
                  </Text>
                }
                popoverBody={
                  <Box>
                    Minimum {currPartnerVideoRecommendation} videos required.
                  </Box>
                }
              />
            </Box>
            .
          </Flex>
        </Flex>
      )}
      {!partnerListLoading ? (
        loading ? (
          <Center minH={"calc(100vh - 364px)"}>
            <Loader />
          </Center>
        ) : videoList && videoList.length > 0 ? (
          <>{getVideoCardList(videoList)}</>
        ) : (
          <Flex
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            h={"calc(100% - 100px)"}
          >
            <NoRecordFoundScreen
              title="No Video Found"
              description={
                getVideoAddButton(currentPartner)
                  ? "Try adding videos of company using below Call to Action button."
                  : ""
              }
              imageUrl={emptyVideo}
            >
              <Box mt="20px">
                {getVideoAddButton(currentPartner, { rounded: "8px" })}
              </Box>
            </NoRecordFoundScreen>
          </Flex>
        )
      ) : null}
      <Modal
        isOpen={isOpen}
        onClose={onModalClose}
        title={selectedVideos ? "Update Video" : "Add New Video"}
        allowCloseButton={true}
        displayFooter={true}
        size={"md"}
        onSaveClick={handleSubmit(submitForm, handleError)}
        loading={loading}
        saveButtonTitle="Save Video"
      >
        <Stack spacing={2}>
          <form noValidate>
            <Flex>
              <TextBox
                type="text"
                name={PLATFORM_LABEL}
                placeholder={PLATFORM_LABEL}
                maxLength="100"
                required
                innerref={register("title", {
                  required: requiredField.replace("$Field$", PLATFORM_LABEL),
                })}
                validationErrors={errors}
                widthFull={true}
              />
            </Flex>
            <Flex>
              <TextBox
                type="text"
                name={LISTING_LABEL}
                placeholder={"www.youtube.com/embed/example"}
                showPlaceHolder={true}
                maxLength="256"
                required
                innerref={register("url", {
                  required: requiredField.replace("$Field$", LISTING_LABEL),
                  pattern: {
                    value: urlValidationPattern,
                    message: invalidUrl,
                  },
                })}
                leftAddOn="https://"
                validationErrors={errors}
                widthFull={true}
              />
            </Flex>
            <Flex mb="4px">
              <Text
                fontSize={"12px"}
                color={"#5E6977"}
                fontWeight={600}
                as="legend"
                m="0 4px 4px 0"
              >
                Select the Directory
              </Text>
              <Image
                display={"inline-block"}
                src={InfoOutline}
                w={"16px"}
                mb="4px"
              />
            </Flex>
            <Flex
              spacing={12}
              mb="16px"
              flexWrap="wrap"
              justifyContent="start"
              gap="15px"
              position={"relative"}
            >
              {hasPermissionManagerSpecialCase(
                permissionTypes.add,
                process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID,
                userInfo,
                permissions,
                partnerList
              ) && (
                <Tooltip label="Coachbar" placement="bottom">
                  <Box
                    border="1px solid #BFC3C9"
                    borderRadius="36px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width={"36px"}
                    marginInlineStart={0}
                    height={"36px"}
                    cursor="pointer"
                    position={"relative"}
                    onClick={() => {
                      handleUpdateModalIconClick(
                        process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
                      );
                    }}
                  >
                    <Avatar
                      size="xs"
                      name="Coachbar"
                      src={CoachBarLogoIcon}
                      iconLabel="ABC"
                    />
                    {selectedPartners.includes(
                      process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
                    ) ? (
                      <Image
                        position={"absolute"}
                        top="-2px"
                        right="-4px"
                        w={"12px"}
                        h={"12px"}
                        color={"#2C885C"}
                        src={greenCheckFill}
                      />
                    ) : null}
                  </Box>
                </Tooltip>
              )}
              {getAddModalPartnerList().map((partner, i) => (
                <Tooltip
                  key={partner.referenceTenantId + i}
                  label={partner.spCompanyName}
                  placement="bottom"
                >
                  <Box
                    border="1px solid #BFC3C9"
                    borderRadius="36px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width={"36px"}
                    height={"36px"}
                    m="0px !important"
                    cursor="pointer"
                    position={"relative"}
                    onClick={() => {
                      handleUpdateModalIconClick(partner.referenceTenantId);
                    }}
                  >
                    <Avatar
                      size="xs"
                      name="Coachbar"
                      src={partner.logo}
                      iconLabel="ABC"
                    />
                    {selectedPartners.includes(partner.referenceTenantId) ? (
                      <Image
                        position={"absolute"}
                        top="-2px"
                        right="-4px"
                        w={"12px"}
                        h={"12px"}
                        color={"#2C885C"}
                        src={greenCheckFill}
                      />
                    ) : null}
                  </Box>
                </Tooltip>
              ))}
              {selectedPartnersError && (
                <FormControl
                  isInvalid={true}
                  position={"absolute"}
                  bottom={"-16px"}
                  left={"0px"}
                  ml="0px !important"
                >
                  <FormErrorMessage>
                    Please select at least one directory.
                  </FormErrorMessage>
                </FormControl>
              )}
            </Flex>
            {/* <Flex>
              <Checkbox
                defaultChecked={videoList && videoList.length === 0}
                {...register("isStarVideo")}
                _checked={{
                  "& .chakra-checkbox__control": {
                    background: "#0C94AC",
                    border: "#0C94AC",
                  },
                }}
              >
                <Text
                  ml="5px"
                  fontSize={"14px"}
                  fontWeight={400}
                  color={"#111A29"}
                >
                  Mark as Primary Video
                </Text>
              </Checkbox>
            </Flex> */}
          </form>
        </Stack>
      </Modal>
      <AlertDialog
        title="Delete Video"
        actionButtonName="Delete"
        isAlertOpen={isAlertOpen}
        onAlertClose={onAlertClose}
        onAlertCloseAction={onAlertCloseAction}
      />
    </Box>
  );
};
export default Videos;
