import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../services/axiosInstance";
import { handleError, notification } from "../../services/utility";

export const getRawardList = async (requestData = {}) => {
  try {
    let { data } = await axiosInstance.post(
      `/commissionList/list`,
      requestData
    );
    return data.data;
  } catch (error) {
    handleError(error);
  }
};

export const getEstimatedRawardList = async (requestData = {}) => {
  try {
    let { data } = await axiosInstance.post(
      `/estimatedCommissionList/list`,
      requestData
    );
    return data.data;
  } catch (error) {
    handleError(error);
  }
};

export const getEstimationCalculation = async () => {
  try {
    let { data } = await axiosInstance.get(
      `/estimatedCommissionList/estimationCalculation?userType=1`
    );
    if (data && data?.code === 200) notification(data.message);
    return data.data;
  } catch (error) {
    handleError(error);
  }
};

export const getCommissionDetailList = async (requestData = {}) => {
  try {
    let { data } = await axiosInstance.post(
      `/commissionDetail/list/v1`,
      requestData
    );
    return data.data;
  } catch (error) {
    handleError(error);
  }
};

export const getCommissionPerformanceDetailList = async (requestData = {}) => {
  try {
    let { data } = await axiosInstance.post(
      `/commissionDetail/performanceBase/list`,
      requestData
    );
    return data.data;
  } catch (error) {
    handleError(error);
  }
};

export const getAllPlans = async () => {
  try {
    let { data } = await axiosInstance.get(`/plans/allProvider/planNameList`);
    return data.data;
  } catch (error) {
    if (
      error?.response?.data?.message !== "Incentive program plan not found." &&
      error?.response?.data?.message !== "Provider does not exist."
    ) {
      handleError(error);
    }
  }
};

export const getCommissionDetails = async (id, spTenantId) => {
  try {
    let { data } = await axiosInstance.get(
      `/commissionList/${id}?tenantId=${spTenantId}&userType=1`
    );
    return data.data;
  } catch (error) {
    handleError(error);
  }
};

export const getPlanDetails = async (id, spTenantId) => {
  try {
    const { data } = await axiosInstance.get(
      `/historical/plans/${id}?tenantId=${spTenantId}`
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};

export const getPlanNamesByCommissionId = async (
  spTenantId,
  requestData = {}
) => {
  try {
    let { data } = await axiosInstance.post(
      `/commission/getPlanNamesByCommissionIds?referenceTenantId=${spTenantId}`,
      requestData
    );
    return data.data;
  } catch (error) {
    handleError(error);
  }
};

export const getInquiryList = async (requestData = {}) => {
  try {
    let { data } = await axiosInstance.post(`/inquiry/list`, requestData);
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const getNoteList = async (requestData = {}) => {
  try {
    let { data } = await axiosInstance.post(`/note/list`, requestData);
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const sendInquiry = async (requestData = {}) => {
  try {
    let { data } = await axiosInstance.post(`/inquiry/add`, requestData);
    if (data && data?.code === 200) notification(data.message);
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const addNotes = async (requestData = {}) => {
  try {
    let { data } = await axiosInstance.post(`/note/add`, requestData);
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const deleteNote = async (id) => {
  try {
    const { data } = await axiosInstance.delete(`/note/${id}`);
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};

export const editNote = async (id, requestData) => {
  try {
    const { data } = await axiosInstance.put(`/note/${id}`, requestData);
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const editCommission = async (commissionId, requestData) => {
  try {
    const { data } = await axiosInstance.put(
      `/commissionDetail/recalculate/${commissionId}`,
      requestData
    );
    return data;
  } catch (error) {
    handleError(error);
  }
};
