import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../services/axiosInstance";
import {
  getErrorMessage,
  getUserInfo,
  handleError,
} from "../../../services/utility";

export const addBank = createAsyncThunk(
  "add/bank",
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("bank/add", requestData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getBanks = createAsyncThunk(
  "get/bank",
  async (bankId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`bank`);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateBank = createAsyncThunk(
  "update/bank",
  async ({ bankId, requestData }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(`/bank/${bankId}`, requestData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getAllBanksList = async () => {
  try {
    const { data } = await axiosInstance.get(`bank/list`);
    return data.data.bankList;
  } catch (error) {
    console.log(error);
  }
};

export const requestToAddNewBank = async (requestData) => {
  try {
    let userInfo = getUserInfo();
    let updatedFinalRequestData = { ...requestData };
    updatedFinalRequestData.userType = userInfo.userType;
    const { data } = await axiosInstance.post(
      `bank/addBankRequest`,
      updatedFinalRequestData
    );
    return data;
  } catch (error) {
    handleError(error);
  }
};
