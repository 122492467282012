import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { scrollbarCSS } from "../../constants/constantValues";
import { formatYAxisTicks } from "../../services/dashboardUtility/utility";
import { getCurrency } from "../../services/utility";
import CustomTooltip from "../Tooltip/CustomTooltip";
import ChartLegends from "./ChartLegends";

const CustomBarChart = ({
  currentDate,
  data,
  dataType = "Revenue",
  isCompared,
}) => {
  let bigTotal = data?.[0]?.value * 1.1;
  return (
    <Box p="24px 0px">
      <Box overflowY="auto" minH={"280px"} maxH="280px" css={scrollbarCSS}>
        {data.map((item, index) => (
          <Flex
            key={`${index}-${item.name}`}
            alignItems="center"
            mb={"16px"}
            flexDirection="column"
          >
            <Flex alignItems="center" mb="4px" width="100%">
              <Box>
                <CustomTooltip
                  content={item.name.length > 7 ? item.name : ""}
                  width="fit-content"
                >
                  <Text
                    fontWeight={"500"}
                    fontSize={"10px"}
                    lineHeight={"16px"}
                    mr="10px"
                    textOverflow={"ellipsis"}
                    align={"right"}
                    color={"#7E8792"}
                    maxW={"50px"}
                    minW={"50px"}
                    whiteSpace={"nowrap"}
                    overflow={"hidden"}
                  >
                    {item.name}
                  </Text>
                </CustomTooltip>
              </Box>
              <Box width={"100%"}>
                <CustomTooltip
                  placement="top-start"
                  width="fit-content"
                  content={
                    <Box
                      css={{
                        ".date": {
                          fontWeight: "700",
                          fontSize: "10px",
                          lineHeight: "16px",
                          color: "#B7C8E1",
                        },
                        ".prod-name": {
                          color: "#FFFFFF",
                          fontWeight: "500",
                          fontSize: "12px",
                          lineHeight: "16px",
                        },
                        ".prod-value": {
                          color: "#FFFFFF",
                          fontWeight: "600",
                          fontSize: "14px",
                          lineHeight: "16px",
                        },
                      }}
                    >
                      <Flex gap={"8px"} alignItems={"center"}>
                        <Flex gap={"4px"} alignItems={"center"}>
                          <Box
                            bg={"#76A9FA"}
                            boxSize={"8px"}
                            rounded={"2px"}
                          ></Box>
                          <Text className="prod-name">
                            {dataType} for {item.name}{" "}
                          </Text>
                        </Flex>
                        <Text className="prod-value">
                          {`${getCurrency().symbol}${
                            formatYAxisTicks(item.value) || 0
                          }`}
                        </Text>
                      </Flex>
                      {item?.hasOwnProperty("previousValue") && (
                        <Flex gap={"8px"} alignItems={"center"} mt={"8px"}>
                          <Flex gap={"4px"} alignItems={"center"}>
                            <Box
                              bg={"#DEE8F8"}
                              boxSize={"8px"}
                              rounded={"2px"}
                            ></Box>
                            <Text className="prod-name">Previous Period</Text>
                          </Flex>
                          <Text className="prod-value">
                            {` ${getCurrency().symbol}${
                              formatYAxisTicks(item.previousValue) || 0
                            }`}
                          </Text>
                        </Flex>
                      )}
                    </Box>
                  }
                >
                  <Flex alignItems={"center"} width={"100%"} mb="2px">
                    <Box
                      bg="#76A9FA"
                      height={item?.previousValue ? "15px" : "30px"}
                      width={`${
                        (item.value / bigTotal) * 100 === 0
                          ? 0.01
                          : (item.value / bigTotal) * 100
                      }%`}
                      borderRightRadius={"8px"}
                    />
                    <Text
                      ml="12px"
                      minW="24px"
                      fontWeight={"500"}
                      fontSize={"10px"}
                      lineHeight={"16px"}
                      color="#111A29"
                    >
                      {formatYAxisTicks(item.value)}
                    </Text>
                  </Flex>
                  {item?.hasOwnProperty("previousValue") && (
                    <Flex alignItems={"center"} width={"100%"}>
                      <Box
                        bg="#DEE8F8"
                        height="15px"
                        width={`${
                          (item.previousValue / bigTotal) * 100 === 0
                            ? 0.01
                            : (item.previousValue / bigTotal) * 100
                        }%`}
                        borderRightRadius={"8px"}
                      ></Box>
                      <Text
                        ml="12px"
                        minW="24px"
                        fontWeight={"500"}
                        fontSize={"10px"}
                        lineHeight={"16px"}
                        color="#111A29"
                      >
                        {formatYAxisTicks(item.previousValue)}
                      </Text>
                    </Flex>
                  )}
                </CustomTooltip>
              </Box>
            </Flex>
          </Flex>
        ))}
      </Box>
      <ChartLegends
        data={[{ legend: dataType, color: "#76A9FA" }]}
        showPrevLegend={isCompared}
      />
    </Box>
  );
};

export default CustomBarChart;
