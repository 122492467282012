import React, { useEffect, useState } from "react";
import { Box, Divider, Flex, Image, Spinner, Text } from "@chakra-ui/react";
import { Modal } from "@coachbar/shared-components";
import userIcon from "../../../images/user.png";
import CustomReactSelect from "../../../components/Select/CustomReactSelect";
import { getSharedWithUserList } from "../utility";

const ShareWithModal = ({
  isOpen,
  onClose,
  portal,
  selectedData,
  setSelectedData,
  entitysList,
  getPartnerTypes,
  getPartnerGroups,
  getPartnersByTypes,
  shareAsset,
  removeSharedPartners,
  userInfo,
  setRefetch,
  setNextToken,
  setLoading: setGridLoading,
  fetchPartnerOrProviderList,
  moduleForId = "",
}) => {
  const managerRole = "Manager";
  let intialized = false;
  const [loading, setLoading] = useState(false);
  const [sharedWithPartnerList, setSharedWithPartnerList] = useState([]);
  const [selectedPartnerTypes, setSelectedPartnerTypes] = useState([]);
  const [selectedPartnerTypeError, setSelectedPartnerTypeError] = useState([]);
  const [selectedPartnerGroups, setSelectedPartnerGroups] = useState([]);
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [partnerTypeOptions, setPartnerTypeOptions] = useState([]);
  const [partnerGroupOptions, setPartnerGroupOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [disabledPartnerIds, setDisabledPartnerIds] = useState([]);
  const [partnerList, setPartnerList] = useState([]);

  const discardClick = () => {
    setLoading(false);
    onClose();
    setSelectedData(null);
    setSelectedPartnerTypeError("");
    setSelectedPartnerTypes([]);
    setSelectedPartnerGroups([]);
    setSelectedPartners([]);
    setPartnerGroupOptions([]);
    if (userInfo?.role !== managerRole) {
      setPartnerOptions([]);
      setSharedWithPartnerList([]);
    }
  };

  const fetchPartnerTypes = async () => {
    const typeRes = await getPartnerTypes("spCategory");
    const types = typeRes?.data?.spPartnerCategoryList;
    if (types && types?.length > 0) {
      setPartnerTypeOptions(
        types?.map((t) => ({ label: t.name, value: t.id }))
      );
    }
  };

  const updatePartnerOptions = (partnerList, sharedWithPartnerList) => {
    if (partnerList && sharedWithPartnerList) {
      const matchedReferenceTenantIds = new Set(
        sharedWithPartnerList.map((item) => item.referenceTenantId)
      );

      const filteredPartnerList = partnerList.filter(
        (partner) => !matchedReferenceTenantIds.has(partner.referenceTenantId)
      );

      setPartnerOptions(
        filteredPartnerList?.map((partner) => ({
          label: partner.scCompanyName,
          value: partner.referenceTenantId,
        }))
      );
    }
  };

  const fetchPartnerOptions = async (requestData = null) => {
    try {
      let partnerRes;
      if (userInfo?.role === managerRole) {
        partnerRes = await getPartnersByTypes({});
        partnerRes = partnerRes?.data;
      } else {
        partnerRes = await fetchPartnerOrProviderList();
      }

      // const fetchedPartnerList = partnerRes?.partnerList;
      const fetchedPartnerList = partnerRes?.partnerList?.filter(
        (partner) => partner.partnerStatus !== "Draft"
      );
      setPartnerList(fetchedPartnerList);

      updatePartnerOptions(fetchedPartnerList, sharedWithPartnerList);
    } catch (error) {
      console.error("Error in fetchPartnerOptions:", error);
    }
  };

  const fetchPartnerAndGroupByTypes = async () => {
    try {
      const requestData = {
        partnerTypeList: selectedPartnerTypes?.map((pt) => pt?.value),
      };

      const [groupRes] = await Promise.allSettled([
        getPartnerGroups(requestData),
      ]);

      const groupList = groupRes?.value?.data?.groupList;

      if (groupList) {
        setPartnerGroupOptions(
          groupList?.map((t) => ({ label: t.name, value: t.id }))
        );
      }
    } catch (error) {
      console.error("Error in fetchPartnerAndGroupByTypes:", error);
    }
  };

  const submitForm = async () => {
    if (
      userInfo?.role !== managerRole
        ? selectedPartnerTypes?.length < 1 && selectedPartners?.length < 1
        : selectedPartnerTypes?.length < 1
    ) {
      setSelectedPartnerTypeError(
        userInfo?.role !== managerRole
          ? "Partner Type or Partners is required"
          : "Partner is required"
      );
      return;
    } else {
      setSelectedPartnerTypeError("");
      setGridLoading(true);
      setLoading(true);
      try {
        const requestData = {
          sharedById: userInfo?.id,
          sharedByName: `${userInfo?.firstName} ${userInfo?.lastName}`,
          partnerTypeList: selectedPartnerTypes?.map((sp) => sp.value),
          partneGroupList: selectedPartnerGroups?.map((sp) => sp.value),
          sharedPartners: selectedPartners?.map((sp) => sp.value),
        };
        const shareRes = await shareAsset(requestData, selectedData?.id);
        if (shareRes?.code === 200) {
          discardClick();
          setNextToken(null);
          setRefetch((prev) => !prev);
        }
      } catch (error) {
        console.log("Error while sharing Asset", error);
      } finally {
        setGridLoading(false);
        setLoading(false);
      }
    }
  };

  const handleRemoveSharedPartners = async (partnerData) => {
    // Manager can only remove(unshare) the partners which are assigned to them.
    if (
      userInfo?.role === managerRole &&
      !partnerList.some(
        (assignedPartner) =>
          assignedPartner.referenceTenantId === partnerData?.referenceTenantId
      )
    ) {
      return;
    }
    setLoading(true);
    setDisabledPartnerIds((prev) => [...prev, partnerData?.referenceTenantId]);
    try {
      const removeRes = await removeSharedPartners(
        selectedData?.id,
        partnerData?.referenceTenantId
      );
      if (removeRes?.code === 200) {
        setSharedWithPartnerList((prev) =>
          prev.filter(
            (partner) =>
              partner?.referenceTenantId !== partnerData?.referenceTenantId
          )
        );
        setPartnerOptions((prev) => [
          ...prev,
          {
            label: partnerData.scCompanyName,
            value: partnerData.referenceTenantId,
          },
        ]);
        setNextToken(null);
        setRefetch((prev) => !prev);
      }
    } catch (error) {
      console.log("Error while removing partners from shared asset", error);
    } finally {
      setLoading(false);
      setDisabledPartnerIds((prev) =>
        prev?.filter((p) => p !== partnerData?.referenceTenantId)
      );
    }
  };

  useEffect(() => {
    const partnerIds = selectedData?.sharedPartners;
    const getSharedWithPartnerList = getSharedWithUserList(
      partnerIds,
      entitysList
    );
    setSharedWithPartnerList(getSharedWithPartnerList);
  }, [selectedData, entitysList]);

  useEffect(() => {
    if (!intialized) {
      intialized = true;
      if (portal === "sp" && userInfo?.role !== "Manager") {
        fetchPartnerTypes();
        fetchPartnerOptions();
      }

      if (userInfo?.role === managerRole) {
        fetchPartnerOptions();
      }
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      updatePartnerOptions(partnerList, sharedWithPartnerList);
    }
  }, [isOpen, partnerList, sharedWithPartnerList]);

  return (
    <>
      <Modal
        title={"Share with"}
        isOpen={isOpen}
        onClose={discardClick}
        displayFooter={true}
        allowCloseButton={true}
        loading={loading}
        onSaveClick={submitForm}
        saveButtonTitle="Share"
        discardTitle="Cancel"
        size="xl"
        spDesign={portal === "sp" ? true : false}
        moduleForId={moduleForId}
        isCentered
      >
        <Box>
          {sharedWithPartnerList && sharedWithPartnerList?.length > 0 && (
            <>
              <Box bg={"#F9FAFB"} rounded={"8px"} p={"16px"}>
                {sharedWithPartnerList?.map((partner, index) => {
                  // Manager can only remove(unshare) the partners which are assigned to them.
                  const isDeleteActionAllowed =
                    userInfo?.role !== managerRole ||
                    partnerList.some(
                      (assignedPartner) =>
                        assignedPartner.referenceTenantId ===
                        partner?.referenceTenantId
                    );

                  return (
                    <Flex
                      key={partner?.referenceTenantId}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      mb={
                        index !== sharedWithPartnerList?.length - 1
                          ? "12px"
                          : ""
                      }
                    >
                      <Flex gap={"8px"} alignItems={"center"}>
                        <Image
                          boxSize={"32px"}
                          rounded={"50%"}
                          src={partner?.logo || userIcon}
                        />
                        <Text
                          color={"#111A29"}
                          fontSize={"14px"}
                          fontWeight={450}
                          lineHeight={"20px"}
                        >
                          {partner?.scCompanyName}
                        </Text>
                      </Flex>
                      <Flex alignItems={"center"} gap={"6px"}>
                        {disabledPartnerIds?.includes(
                          partner?.referenceTenantId
                        ) && (
                          <Spinner
                            thickness="4px"
                            speed="0.65s"
                            emptyColor="gray.200"
                            color="brand.100"
                            size={"xs"}
                          />
                        )}
                        <Text
                          color={"#7E8792"}
                          fontSize={"12px"}
                          fontWeight={500}
                          lineHeight={"20px"}
                          cursor={
                            isDeleteActionAllowed ? "pointer" : "not-allowed"
                          }
                          opacity={isDeleteActionAllowed ? 1 : 0.5}
                          pointerEvents={
                            disabledPartnerIds?.includes(
                              partner?.referenceTenantId
                            )
                              ? "none"
                              : "all"
                          }
                          _hover={{
                            color: `${isDeleteActionAllowed ? "#111A29" : ""}`,
                            textDecor: `${
                              isDeleteActionAllowed ? "underline" : "none"
                            }`,
                          }}
                          onClick={() => handleRemoveSharedPartners(partner)}
                        >
                          Delete
                        </Text>
                      </Flex>
                    </Flex>
                  );
                })}
              </Box>
              <Divider my={"16px"} borderColor={"#DEE8F8"} />
            </>
          )}
          {userInfo?.role !== managerRole && (
            <>
              <Box mb={"16px !important"}>
                <CustomReactSelect
                  label={"Select Partner Type"}
                  options={partnerTypeOptions}
                  value={selectedPartnerTypes}
                  onChange={(e) => {
                    if (e?.length < 1) {
                      setPartnerGroupOptions([]);
                      setSelectedPartnerGroups([]);
                    } else {
                      setSelectedPartnerTypeError("");
                    }
                    setSelectedPartnerTypes(e);
                  }}
                  onMenuClose={fetchPartnerAndGroupByTypes}
                  isMulti
                  isRequired={selectedPartners.length < 1}
                />
              </Box>
              <CustomReactSelect
                label={"Select Partner Group"}
                options={partnerGroupOptions}
                value={selectedPartnerGroups}
                onChange={setSelectedPartnerGroups}
                isMulti
              />
              <Flex gap={"20px"} alignItems={"center"} my={"16px"}>
                <Divider />
                <Text color={"#7E8792"} fontSize={"14px"} fontWeight={500}>
                  OR
                </Text>
                <Divider />
              </Flex>
            </>
          )}
          <CustomReactSelect
            label={"Select Partner"}
            options={partnerOptions}
            value={selectedPartners}
            onChange={(list) => {
              setSelectedPartnerTypeError("");
              setSelectedPartners(list);
            }}
            errorMessage={selectedPartnerTypeError}
            isMulti
            isRequired={selectedPartnerTypes.length < 1}
          />
        </Box>
      </Modal>
    </>
  );
};

export default ShareWithModal;
