import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../services/axiosInstance";
import { getErrorMessage } from "../../services/utility";

export const getServicesForRoadmap = createAsyncThunk(
  "get/roadmap",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("/roadmap/service/list");
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const saveRoadmap = createAsyncThunk(
  "post/roadmap",
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("/roadmap/add", requestData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getPredefinedCredit = createAsyncThunk(
  "get/getPredefinedCredit",
  async (referralId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/roadmap/getPrebookCredit`);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
