import { notification } from "@coachbar/shared-components/src/services/utility";
import { axiosInstance } from "../../../services/axiosInstance";
import { handleError } from "../../../services/utility";

export const getCustomForm = async () => {
  try {
    const { data } = await axiosInstance.get(`/setting/customForm`);
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};

export const getCustomFormByTenantId = async (tenantId) => {
  try {
    const { data } = await axiosInstance.get(
      `/setting/customForm/byReference?tenantId=${tenantId}`
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};

export const saveCustomForm = async (requestParams) => {
  try {
    const { data } = await axiosInstance.post(`/setting/customForm/add`, {
      ...requestParams,
    });
    if (data?.code === 200 || 201) notification(data.message);
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};
