// transactionHistorySlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  getPredefinedCredit,
  getServicesForRoadmap,
  saveRoadmap,
} from "./roadmapActions";

const initialState = {
  loading: false,
  error: null,
  success: null,
  servicesList: [],
  roadMapSuccess: null,
  prebookCredit: null,
};

const roadmapSlice = createSlice({
  name: "roadmap",
  initialState,
  reducers: {
    clearMessage: (state) => {
      state.success = null;
      state.error = null;
    },
    setError: (state, action) => {
      let errorMsg = action.payload;
      state.error = errorMsg;
    },
    setSuccess: (state, action) => {
      let successMsg = action.payload;
      state.success = successMsg;
    },
    setRoadMapSuccess: (state, action) => {
      let successMsg = action.payload;
      state.roadMapSuccess = successMsg;
    },
    clearRoadMapMessage: (state) => {
      state.roadMapSuccess = null;
    },
  },
  extraReducers: {
    // Get Roadmap Service List
    [getServicesForRoadmap.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getServicesForRoadmap.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
      if (payload.data && payload.success) {
        state.servicesList = payload.data?.coachbarServiceList || [];
      }
    },
    [getServicesForRoadmap.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload?.code && payload?.code >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },

    // Save Roadmap
    [saveRoadmap.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [saveRoadmap.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
    },
    [saveRoadmap.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload?.code && payload?.code >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    [getPredefinedCredit.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPredefinedCredit.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
      if (payload.data && payload.success) {
        state.prebookCredit = payload.data?.preBookCredit || null;
      }
    },
    [getPredefinedCredit.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
  },
});

export const {
  setError,
  setSuccess,
  clearMessage,
  clearRoadMapMessage,
  setRoadMapSuccess,
} = roadmapSlice.actions;

export default roadmapSlice.reducer;
