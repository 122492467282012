import {
  AspectRatio,
  Box,
  Container,
  Input,
  Stack,
  Text,
  FormLabel,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Skeleton,
} from "@chakra-ui/react";
import { Button } from "@coachbar/shared-components";

export default function FileUpload({
  innerref,
  validationErrors,
  moduleForId,
  ...props
}) {
  const validation = validationErrors && validationErrors[innerref.name];
  let imageType = null;
  if (props.imgSrc) {
    imageType = props.imgSrc.substring(props.imgSrc.lastIndexOf(".") + 1);
  }
  return (
    <Container
      pl={"0px"}
      pr={"0px"}
      m={"0px"}
      maxWidth={props.boxWidth ? props.boxWidth : "100%"}
    >
      <FormControl
        name={props.name}
        isInvalid={validation}
        isRequired={props.required}
        pointerEvents={props.isDisabled ? "none" : "inherit"}
      >
        <FormLabel as="legend" fontSize={"12px"}>
          {props.name}
        </FormLabel>
        <AspectRatio
          width={props.boxWidth ? props.boxWidth : "100%"}
          ratio={props.ratio ? props.ratio : 21 / 9}
        >
          <Box
            borderColor="gray.300"
            borderStyle="dashed"
            borderWidth="2px"
            rounded="md"
            shadow="sm"
            role="group"
            transition="all 150ms ease-in-out"
            _hover={{
              shadow: "md",
            }}
            // as={motion.div}
            initial="rest"
            animate="rest"
            bg="white"
          >
            <Box
              position="absolute"
              top="0"
              left="0"
              height="100%"
              width="100%"
              display="flex"
              flexDirection="column"
              bgImage={props.isLoading ? "none" : `url("${props.imgSrc}")`}
              bgRepeat={"no-repeat"}
              bgPosition={"top"}
              bgSize={"contain"}
              style={{ backgroundOrigin: "content-box" }}
              pb={props.imgSrc && !props.isLoading ? "54px" : "0"}
            >
              <Stack
                height="100%"
                width="100%"
                display="flex"
                alignItems="center"
                justify="center"
                spacing="4"
              >
                {!props.isLoading &&
                imageType &&
                imageType.toLowerCase() === "pdf" ? (
                  <embed
                    src={props.imgSrc}
                    type="application/pdf"
                    style={{ zIndex: 2 }}
                    width={"100%"}
                    height={"100%"}
                  />
                ) : null}
                {props.isLoading ? (
                  <Skeleton height={"inherit"} width={"inherit"} />
                ) : !props.imgSrc ? (
                  <Stack>
                    <Button
                      title="Add File"
                      variant={"discard"}
                      size="sm"
                      height={"2rem"}
                      id={`${moduleForId}-add-file`}
                    />
                    <Text fontWeight="light" mt={"0 !important"}>
                      or drop file to upload
                    </Text>
                  </Stack>
                ) : (
                  !props.isDisabled && (
                    <Stack
                      position={"absolute"}
                      bottom={"1.5"}
                      direction={"row"}
                    >
                      <Button
                        title="Replace File"
                        variant={"discard"}
                        size="sm"
                        height={"2rem"}
                        id={`${moduleForId}-replace-file`}
                      />
                      {props.allowDelete ? (
                        <Button
                          title="Delete File"
                          variant={"danger"}
                          zIndex={"2"}
                          size="sm"
                          height={"2rem"}
                          onClick={props.onDeleteClick}
                          id={`${moduleForId}-delete-file`}
                        />
                      ) : null}
                    </Stack>
                  )
                )}
              </Stack>
            </Box>
            <Input
              disabled={props.isLoading}
              ref="upload"
              type="file"
              height="100%"
              width="100%"
              position="absolute"
              top="0"
              left="0"
              opacity="0 !important"
              aria-hidden="true"
              onChange={props.onChange}
              cursor={"pointer"}
              accept={
                props.acceptedFileTypes ? props.acceptedFileTypes : "image/*"
              }
              multiple={false}
              {...innerref}
            />
          </Box>
        </AspectRatio>
        {validation?.message ? (
          <FormErrorMessage>{validation?.message}</FormErrorMessage>
        ) : (
          <FormHelperText>&nbsp;</FormHelperText>
        )}
      </FormControl>
    </Container>
  );
}
