import { Box } from "@chakra-ui/react";
import DropZone from "../../components/DragAndDrop/DropZone";
import DraggableFormInput from "../../components/DragAndDrop/DraggableFormInput";
import { scrollbarCSS } from "../../constants/constantValues";

const CustomizableForm = ({
  customFormPropertyList,
  handleDrop,
  handleDeleteInput,
  handleEditInput,
  mandatoryProperties,
  type,
}) => {
  return (
    <Box
      h={"fit-content"}
      maxH={"calc(100vh - 248px)"}
      border={"1px dashed #E6E7E9"}
      rounded={"8px"}
      bg={"#F9FAFC"}
      px={"34px"}
      py={"12px"}
      w={"675px"}
      overflow={"auto"}
      css={scrollbarCSS}
    >
      {customFormPropertyList &&
        customFormPropertyList.length &&
        customFormPropertyList
          ?.filter((property) => property.added)
          ?.map((property, index) => {
            return (
              <Box key={property.id}>
                <DropZone
                  id={property.id}
                  index={index}
                  onDrop={handleDrop}
                  type={type}
                  key={`dropzone-${property.id}`}
                />
                <DraggableFormInput
                  key={`input-${property.id}`}
                  index={index}
                  id={property.id}
                  name={property.name}
                  isRequired={property.isRequired}
                  placeholder={property.placeholder}
                  onDelete={handleDeleteInput}
                  type={type}
                  mandatoryProperties={mandatoryProperties}
                  onEdit={handleEditInput}
                  fieldType={property.fieldType}
                />
              </Box>
            );
          })}
      <DropZone
        id={customFormPropertyList.length + 1}
        index={
          customFormPropertyList?.filter((property) => property.added)?.length
        }
        onDrop={handleDrop}
        type={type}
      />
    </Box>
  );
};

export default CustomizableForm;
