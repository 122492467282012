import React, { useState } from "react";
import { Modal } from "../..";
import { Stack, Box, Text } from "@chakra-ui/react";
import CurrencyDropDown from "./CurrencyDropDown";
import { darkSmallLogo } from "../Icons/SvgsConstants";
import { notification } from "../../services/utility";

const AskCurrencyModel = ({ onSuccess, updateCurrency, moduleForId }) => {
  const [loading, setLoading] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("USD");

  const submitForm = async () => {
    if (selectedCurrency) {
      setLoading(true);
      let res = await updateCurrency(
        selectedCurrency?.value?.split("---")[0] || "USD"
      );
      setLoading(false);
      if (res?.success) {
        onSuccess(res);
      }
    } else {
      notification("Please Select Currency", "error");
    }
  };
  return (
    <Modal
      isOpen={true}
      // onClose={onModalClose}
      title={""}
      // allowCloseButton={true}
      displayFooter={true}
      size={"md"}
      onSaveClick={submitForm}
      loading={loading}
      saveButtonTitle="Continue"
      hideDiscard={true}
      moduleForId={moduleForId}
      spFooter
    >
      {
        <Box>
          <Stack mb="40px">
            <Stack align={"center"} mb="16px">
              <Box
                display={"inline-block"}
                boxSize={"40px"}
                css={{
                  svg: {
                    height: "40px",
                    width: "40px",
                  },
                }}
              >
                {" "}
                {darkSmallLogo}{" "}
              </Box>
            </Stack>
            <Text
              textAlign={"center"}
              color={"#111A29"}
              fontSize={"16px"}
              fontWeight={"600"}
              lineHeight={"24px"}
            >
              You’re almost there!
            </Text>
            <Text
              textAlign={"center"}
              color={"#5E6977"}
              fontSize={"12px"}
              fontWeight={"500"}
              lineHeight={"18px"}
            >
              Complete the following basic settings to start.
            </Text>
          </Stack>
          <Stack spacing={2} mb="16px">
            <CurrencyDropDown
              width={"100%"}
              required={true}
              options={[]}
              placeholder="Base Currency"
              onCurrencyChange={(selectedCurrency) => {
                setSelectedCurrency(selectedCurrency);
              }}
              size="md"
              customWidth={"100%"}
              currency={selectedCurrency}
              defaultCurrency={"USD"}
              hideHelperText
            />
          </Stack>
          <Stack>
            <Text fontWeight={"500"} fontSize={"12px"} color={"#7E8792"}>
              <Box
                display={"inline-block"}
                color={"#C81E1E"}
                fontWeight={"600"}
                mr="2px"
              >
                Note:{" "}
              </Box>
              Base currency cannot be changed once saved. Please ensure the
              correct base currency is selected before saving.
            </Text>
          </Stack>
        </Box>
      }
    </Modal>
  );
};

export default AskCurrencyModel;
