import { Center, Box, Image, Text } from "@chakra-ui/react";
import noRecordsFoundImg from "@coachbar/shared-components/src/images/match-make-empty.png";

const ProfileNoRecordsFound = ({
  title,
  description,
  minHeight,
  imageUrl,
  children,
}) => {
  return (
    <Center
      mb={"20px"}
      mt={minHeight ? "" : "134px"}
      minH={minHeight ? minHeight : "auto"}
    >
      <Box textAlign={"center"}>
        <Image
          src={imageUrl || noRecordsFoundImg}
          h={"100px"}
          mb={"24px"}
          mx={"auto"}
        />
        <Text
          color={"var(--title-black-text)"}
          fontSize={"16px"}
          fontWeight={700}
          lineHeight={"20px"}
          mb={"8px"}
        >
          {title || "No records found!"}
        </Text>
        <Text
          color={"#7E8792"}
          fontSize={"14px"}
          lineHeight={"20px"}
          fontWeight={400}
        >
          {description || ""}
        </Text>
        {children}
      </Box>
    </Center>
  );
};

export default ProfileNoRecordsFound;
