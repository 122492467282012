import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../services/axiosInstance";
import { handleError, notification } from "../../services/utility";
import { addToIdMap, setData, setRequiredIndustries } from "./IndustrySlice";

export const transformIndustryData = (dispatch, industriesForProviders) => {
  const industryProviderMap = {};
  industriesForProviders.forEach((pi) => {
    pi.services.filter(Boolean).forEach((service) => {
      if (!industryProviderMap[service]) {
        industryProviderMap[service] = [];
      }

      industryProviderMap[service].push(pi.spTenantId);
    });
    dispatch(addToIdMap({ id: pi.id, tenantId: pi.spTenantId }));
  });

  dispatch(setData({ data: industryProviderMap }));
};

export const transformRequiredIndustryData = (
  dispatch,
  requiredIndustriesForProviders
) => {
  const requiredIndustryProviderMap = {};
  requiredIndustriesForProviders.forEach((pi) => {
    pi.industry.filter(Boolean).forEach((industry) => {
      if (!requiredIndustryProviderMap[industry]) {
        requiredIndustryProviderMap[industry] = [];
      }

      requiredIndustryProviderMap[industry].push(pi.tenantId);
    });
  });

  dispatch(setRequiredIndustries({ data: requiredIndustryProviderMap }));
};

export const saveIndustries = createAsyncThunk(
  "save/industries",
  async (requestData, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.put(
        "/service/v1/update",
        requestData
      );
      if (data?.code === 200 || 201) notification(data.message);

      transformIndustryData(
        dispatch,
        data?.data?.service?.partnerServicesList ?? []
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      handleError(error);
    }
  }
);

export const getActiveRequirementsList = async () => {
  try {
    const { data } = await axiosInstance.get(`/requirement/all`);
    return data;
  } catch (error) {
    console.log(error);
  }
};
