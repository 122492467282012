import { Box, Flex, Text } from "@chakra-ui/react";

const ChartLegends = ({ data, showPrevLegend = false }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      wrap={"wrap"}
      mt="24px"
      gap="12px"
      p="10px 0"
    >
      {data?.map((dataItem) =>
        dataItem?.legend ? (
          <Flex
            key={dataItem.legend}
            alignItems="center"
            gap="8px"
            justifyContent={"center"}
          >
            <Box
              boxSize="8px"
              bg={dataItem.color}
              rounded="2px"
              boxShadow="0px 1px 2px 0px #1018281A"
            />
            <Text
              color="#5E6977"
              fontSize="12px"
              fontWeight={500}
              cursor="default"
              _hover={{ color: "#000" }}
            >
              {dataItem.legend}
            </Text>
          </Flex>
        ) : null
      )}
      {showPrevLegend && (
        <Flex alignItems="center" gap="8px">
          <Box
            boxSize="8px"
            bg="#DEE8F8"
            rounded="2px"
            boxShadow="0px 1px 2px 0px #1018281A"
          />
          <Text
            color="#5E6977"
            fontSize="12px"
            fontWeight={500}
            cursor="default"
            _hover={{ color: "#000" }}
          >
            Previous Data
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default ChartLegends;
