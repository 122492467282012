import { Box, Flex, Text } from "@chakra-ui/react";
import {
  minusChange,
  plusChange,
} from "@coachbar/shared-components/src/components/Icons/SvgsConstants";
import CellLoadingComponent from "@coachbar/shared-components/src/components/Loader/CellLoadingComponent";
import React from "react";

const ValuesChangeCard = ({
  title,
  value,
  changePercent,
  vsText,
  loading,
  showCompare = false,
  valueSuffix = "",
}) => {
  const getBackgroundColor = (changePercent) => {
    if (Number.isNaN(changePercent) || !changePercent) {
      return "linear-gradient(260.06deg, #F3F7FF -2.76%, #FFFFFF 18.95%)";
    }
    return changePercent >= 0
      ? "linear-gradient(260.06deg, #EBF6F1 -2.76%, #FFFFFF 18.95%)"
      : "linear-gradient(260.06deg, #FDE8E8 -2.76%, #FFFFFF 18.95%)";
  };

  const getBorderColor = (changePercent) => {
    if (Number.isNaN(changePercent) || !changePercent) {
      return "1px solid #F3F7FF";
    }
    return changePercent >= 0 ? "1px solid #EBF6F1" : "1px solid #FDF2F2";
  };

  return (
    <Box
      bg={getBackgroundColor(changePercent)}
      p="12px"
      border={getBorderColor(changePercent)}
      boxShadow={"0px 0px 4px 0px rgba(230, 231, 233, 1)"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
      rounded={"8px"}
    >
      {loading ? (
        <Flex justifyContent={"center"} alignItems={"center"} minH="80px">
          <CellLoadingComponent />
        </Flex>
      ) : (
        <>
          <Text
            color={"#5E6977"}
            fontWeight={"600"}
            fontSize={"14px"}
            lineHeight={"20px"}
            mb="12px !important"
          >
            {title}
          </Text>
          {
            <Flex alignItems={"baseline"} gap={"4px"}>
              <Text
                color="#111A29"
                fontWeight={"700"}
                fontSize={"16px"}
                lineHeight={"24px"}
              >
                {value}
              </Text>
              {valueSuffix && (
                <Text
                  color="#7E8792"
                  fontWeight={"500"}
                  fontSize={"12px"}
                  lineHeight={"24px"}
                >
                  {valueSuffix}
                </Text>
              )}
            </Flex>
          }
          {showCompare && (
            <>
              {!Number.isNaN(changePercent) && changePercent !== 0 ? (
                <Flex mt={"4px !important"}>
                  {" "}
                  <Text fontSize={"12px"} fontWeight={"700"}>
                    {" "}
                    {changePercent > 0 ? (
                      <Box
                        display="flex"
                        alignItems={"center"}
                        color="#216645"
                        css={{
                          path: {
                            stroke: "#216645",
                          },
                        }}
                      >
                        {plusChange} {Math.abs(changePercent)}%
                      </Box>
                    ) : (
                      <Box display="flex" alignItems={"center"} color="#C81E1E">
                        {minusChange} {Math.abs(changePercent)}%
                      </Box>
                    )}
                  </Text>
                  <Text
                    ml="2px"
                    color={"#7E8792"}
                    fontWeight={"500"}
                    fontSize={"12px"}
                    lineHeight={"18px"}
                  >
                    vs {vsText}
                  </Text>
                </Flex>
              ) : (
                <Flex> - </Flex>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default ValuesChangeCard;
