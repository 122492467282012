import { axiosInstance } from "../../../services/axiosInstance";
import { getUserInfo, handleError } from "../../../services/utility";

export const getProvidersList = async (requestData) => {
  try {
    let { data } = await axiosInstance.post(`/sp/provider/list`, requestData);
    return data.data;
  } catch (error) {
    handleError(error);
  }
};

export const getPartnerProviderList = async (requestData) => {
  try {
    let { data } = await axiosInstance.post(
      `/sp/partnerProvider/list`,
      requestData
    );
    return data.data;
  } catch (error) {
    handleError(error);
  }
};

export const updateProvider = async (id, requestData) => {
  try {
    let res = await axiosInstance.put(`/sp/provider/edit/${id}`, requestData);
    return res;
  } catch (error) {
    handleError(error);
  }
};

export const exportProviderData = async (requestData) => {
  try {
    let { data } = await axiosInstance.post(`/sp/provider/export`, requestData);
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const updateProviderTagList = async (tagList, providerId) => {
  const { tenantId } = getUserInfo();
  try {
    const { data } = await axiosInstance.put(
      "/partnerCard/addTags/" + providerId,
      { tags: tagList },
      { params: { tenantId } }
    );
    return data;
  } catch (error) {
    handleError(error);
  }
};
