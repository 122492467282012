// PersonaliseAccount.js
import { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Notification from "../../components/Notification";
import {
  registerUser,
  resendConfirmationCode,
  verifyUser,
} from "./authActions";
import {
  Button,
  TextBox,
  RadioGroup,
  Modal,
  OtpVerification,
  Link,
  PasswordStrengthIndicator,
  ShowHidePassword,
} from "@coachbar/shared-components";
import {
  requiredField,
  invalidEmail,
  passwordMisMatch,
  lowerCaseLetter,
  upperCaseLetter,
  specialCharacter,
  passwordMinLength,
  passwordMaxLength,
} from "../../constants/validationMessages";
import {
  emailValidationPattern,
  upperCaseValidationPattern,
  lowerCaseValidationPattern,
  specialCharacterValidationPattern,
} from "../../constants/validationPatterns";
import {
  useDisclosure,
  Center,
  HStack,
  Box,
  Stack,
  Text,
  InputGroup,
  FormLabel,
} from "@chakra-ui/react";
import { useClearState } from "../../services/customHook.js";
import { clearMessage } from "./authSlice";
import {
  claimProfileRoute,
  profileRoute,
} from "../../constants/redirectionRoutes";
import { getSubscriptionPlanDetails } from "../../services/utility";
import { userTypeList } from "../../constants/auth";

const PersonaliseAccount = (props) => {
  const passwordValidationMessages = {
    lowerCaseLetter,
    upperCaseLetter,
    specialCharacter,
    passwordMinLength,
    passwordMaxLength,
  };
  const { loading, userInfo, error, success } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const [isOtpScreen, setIsOtpScreen] = useState(false);
  const radioList = [
    { title: "An individual", value: 1 },
    { title: "A Business", value: 2 },
  ];
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
    getValues,
    setValue,
  } = useForm({
    criteriaMode: "all",
    defaultValues: {
      iam: 2,
      email: props.email ? props.email : "",
      userType: 1,
    },
    mode: "onChange",
  });
  const navigate = useNavigate();
  const password = useRef({});
  password.current = watch("password", "");
  useClearState();

  useEffect(() => {
    if (props.userData) {
      setValue("firstName", props.userData.scFirstName);
      setValue("lastName", props.userData.scLastName);
      setValue("companyName", props.userData.scCompanyName);
    }
  }, [props.userData]);

  useEffect(() => {
    if (
      userInfo &&
      userInfo.hasOwnProperty("isClaimed") &&
      userInfo.isClaimed === false
    ) {
      navigate(claimProfileRoute);
    } else if (userInfo) {
      navigate(profileRoute);
    }
  }, [userInfo]);

  const getPlanDetail = () => {
    if (localStorage.getItem("planId")) {
      getSubscriptionPlanDetails(dispatch);
    }
    navigate(profileRoute);
  };

  const submitForm = (data) => {
    // transform email string to lowercase to avoid case sensitivity issues in login
    data.email = data.email.toLowerCase();
    dispatch(registerUser(data)).then((responseData) => {
      if (responseData.payload && responseData.payload.success)
        setIsOtpScreen(true);
    });
  };
  const [otpValue, setOtpValue] = useState("");

  const handleChange = (otpValue) => {
    setOtpValue(otpValue);
  };

  const submitOTP = () => {
    if (otpValue) {
      let email = getValues("email") || "";
      let requestData = { email: email, confirmationCode: otpValue };
      dispatch(verifyUser(requestData)).then((responseData) => {
        if (responseData.payload && responseData.payload.success) {
          setIsOtpScreen(false);
        }
      });
    }
  };

  const resendOtp = () => {
    let email = getValues("email") || "";
    dispatch(resendConfirmationCode(email));
  };

  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordVisibility = () => setShowPassword(!showPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  return (
    <>
      {error || success ? (
        <Notification
          time={error ? 7000 : 3000}
          title={error ? error : success}
          status={error ? "error" : "success"}
          onCloseToast={() => dispatch(clearMessage())}
        />
      ) : (
        ""
      )}
      <form onSubmit={handleSubmit(submitForm)} noValidate>
        {!isOtpScreen ? (
          <>
            <Text
              fontSize="20px"
              fontWeight="700"
              mb="16px !important"
              color="var(--title-black-text)"
              textAlign="center"
            >
              Almost done, personalize your account
            </Text>
            <HStack align={"center"} justify={"center"} mb={"32px"}>
              <label
                htmlFor="iam"
                style={{
                  color: "#5E6977",
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                I am
              </label>
              <Controller
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    onChange={onChange}
                    value={value}
                    // name="iam"
                    childList={radioList}
                  />
                )}
                name="iam"
                control={control}
              />
            </HStack>
            {!props?.userData && (
              <>
                <FormLabel
                  htmlFor="userType"
                  fontSize={"14px"}
                  fontWeight={500}
                  color={"#5E6977"}
                >
                  Register As
                </FormLabel>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      onChange={onChange}
                      value={value}
                      childList={userTypeList}
                    />
                  )}
                  name="userType"
                  control={control}
                />
              </>
            )}
            <HStack spacing={"24px"} mt={"20px"}>
              <TextBox
                type="text"
                maxLength="100"
                placeholder="First Name"
                required
                innerref={register("firstName", {
                  required: requiredField.replace("$Field$", "First Name"),
                })}
                validationErrors={errors}
              />
              <TextBox
                type="text"
                maxLength="100"
                placeholder="Last Name"
                required
                innerref={register("lastName", {
                  required: requiredField.replace("$Field$", "Last Name"),
                })}
                validationErrors={errors}
              />
            </HStack>
            <HStack spacing={"24px"}>
              <TextBox
                type="text"
                maxLength="100"
                placeholder="Email"
                required
                innerref={register("email", {
                  required: requiredField.replace("$Field$", "Email"),
                  pattern: {
                    value: emailValidationPattern,
                    message: invalidEmail,
                  },
                })}
                validationErrors={errors}
              />
              <TextBox
                type="text"
                maxLength="100"
                placeholder="Company Name"
                required
                innerref={register("companyName", {
                  required: requiredField.replace("$Field$", "Company Name"),
                })}
                validationErrors={errors}
              />
            </HStack>

            <HStack spacing={"24px"}>
              <Box w="100%">
                <InputGroup>
                  <TextBox
                    type={showPassword ? "text" : "password"}
                    name="password"
                    maxLength="100"
                    placeholder="Create a Password"
                    mb={password.current && errors.password ? "1rem" : "none"}
                    required
                    innerref={register("password", {
                      required: requiredField.replace("$Field$", "Password"),
                      validate: {
                        hasSpecialCharacter: (value) =>
                          specialCharacterValidationPattern.test(value),
                        hasUpperCaseLetter: (value) =>
                          upperCaseValidationPattern.test(value),
                        hasLowerCaseLetter: (value) =>
                          lowerCaseValidationPattern.test(value),
                        hasMinLength: (value) => value.length >= 8,
                        hasMaxLength: (value) => value.length <= 16,
                      },
                    })}
                    validationErrors={errors}
                  />
                  <ShowHidePassword
                    handlePasswordVisibility={handlePasswordVisibility}
                    showPassword={showPassword}
                    id="auth-personalise-acc-create-password-visiblity"
                  />
                </InputGroup>
              </Box>
              <InputGroup>
                <TextBox
                  type={showConfirmPassword ? "text" : "password"}
                  maxLength="100"
                  placeholder="Confirm Password"
                  required
                  innerref={register("confirmPassword", {
                    required: requiredField.replace(
                      "$Field$",
                      "Confirm Password"
                    ),
                    validate: (value) =>
                      value === password.current || passwordMisMatch,
                  })}
                  validationErrors={errors}
                />
                <ShowHidePassword
                  handlePasswordVisibility={handleConfirmPasswordVisibility}
                  showPassword={showConfirmPassword}
                  id="auth-personalise-acc-confirm-password-visiblity"
                />
              </InputGroup>
            </HStack>
            {password.current ? (
              <PasswordStrengthIndicator
                value={password.current}
                validationErrors={errors.password}
                validationMessages={passwordValidationMessages}
              />
            ) : null}

            <Button
              type="submit"
              w={"100%"}
              mt={"20px"}
              isLoading={loading}
              title="Continue"
              variant={"newColorThemePrimary"}
              id="personalise-account-continue"
            />
            <Text
              color="var(--title-black-text)"
              fontWeight={400}
              fontSize="16px"
              textAlign="center"
              mt={"40px"}
            >
              Already have an account?&nbsp;
              <Link
                color="#0C94AC"
                fontWeight={600}
                to="/login"
                title="Login"
              />
            </Text>
          </>
        ) : (
          <>
            <Text
              fontSize="20px"
              lineHeight={"20px"}
              fontWeight="700"
              mb="30px !important"
              color="var(--title-black-text)"
              textAlign="center"
            >
              Great, You’re Done!
            </Text>
            <Stack spacing={4}>
              <Text
                color="#5E6977"
                fontSize="16px"
                fontWeight={400}
                textAlign="center"
              >
                You’re almost there! We sent an email to{" "}
                <b>{getValues("email")}</b>
              </Text>
              <Text
                color="#5E6977"
                fontSize="16px"
                fontWeight={400}
                textAlign="center"
              >
                Please enter the code you received below
              </Text>
            </Stack>
            <Center pt={5} pb={4}>
              <OtpVerification onChange={handleChange} value={otpValue} />
            </Center>
            <Stack spacing={4}>
              <Text
                color="#5E6977"
                fontSize="16px"
                fontWeight={400}
                textAlign="center"
              >
                Please check spam or junk folder if not received in inbox
              </Text>

              <Text
                color="#5E6977"
                fontSize="16px"
                fontWeight={600}
                textAlign="center"
              >
                Didn’t get verification code?{" "}
                <Link
                  to="#"
                  color="#0C94AC"
                  fontWeight={400}
                  onClick={resendOtp}
                  title="Resend OTP"
                />
              </Text>
              <Center>
                <Button
                  type="submit"
                  onClick={submitOTP}
                  title="Verify OTP"
                  variant={"newColorThemePrimary"}
                  isLoading={loading}
                  w="100%"
                  id="personalise-account-verify-otp"
                />
              </Center>
            </Stack>
          </>
        )}
      </form>
    </>
  );
};
export default PersonaliseAccount;
