import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Image,
} from "@chakra-ui/react";
import { Button } from "@coachbar/shared-components";
import { buttonVariants } from "../../constants/constantValues";

const CustomModal = (props) => {
  const { isCentered = false, moduleForId = "" } = props;
  return (
    <Modal
      onClose={props.onClose}
      isOpen={props.isOpen}
      isCentered={isCentered}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size={props.size ? props.size : "md"}
      blockScrollOnMount={false}
      showHeaderBorder={false}
    >
      <ModalOverlay />

      <ModalContent maxWidth={props.applyMaxContent && "max-content"}>
        {props.headerWithIcon ? (
          <ModalHeader
            p={"16px 24px"}
            alignItems={"flex-start"}
            display={"flex"}
            borderBottom={"1px solid var(--chakra-colors-atlantic-100)"}
          >
            {props.headerIcon && (
              <Image src={props.headerIcon} h={"20px"} mr={"8px"} mt={"4px"} />
            )}
            <Text
              color={"var(--title-black-text)"}
              fontSize={"16px"}
              fontWeight={600}
            >
              {props.title}
            </Text>
          </ModalHeader>
        ) : (
          <>
            {props.title && (
              <ModalHeader
                fontSize="16px"
                fontWeight={"600"}
                color={"var(--title-black-text)"}
                p="16px 24px"
                textAlign={props.alignHeader ? props.alignHeader : "left"}
                borderBottom={"1px solid var(--chakra-colors-atlantic-100)"}
                maxW={"98%"}
              >
                <Text noOfLines={1}>{props.title}</Text>
              </ModalHeader>
            )}
          </>
        )}
        {props.allowCloseButton ? <ModalCloseButton /> : null}
        <ModalBody p={props.padding || "24px"}>{props.children}</ModalBody>
        {props.displayFooter ? (
          <ModalFooter
            borderTop={"1px solid #F3F7FF"}
            justifyContent={props.alignFooter ? props.alignFooter : "end"}
          >
            {props.spDesign ? (
              <>
                {!props.hideDiscard ? (
                  <Button
                    title={props?.discardTitle || "Discard"}
                    mr={3}
                    onClick={props.onClose}
                    style={{
                      borderRadius: "4px",
                      background: "#FFF",
                      color: "#5E6977",
                      borderColor: "#fff",
                    }}
                    id={`${moduleForId}-spdesign-footer-cancel`}
                  />
                ) : (
                  ""
                )}
                {props.extraFooterButton && (
                  <Button
                    title={
                      props.extraFooterButtonTitle
                        ? props.extraFooterButtonTitle
                        : "Save"
                    }
                    onClick={props.extraFooterButtonClick}
                    isLoading={props.loading}
                    variant="newColorThemePrimary"
                    mr="12px"
                    bg="#fff"
                    color="#0C94AC"
                    isDisabled={props.isDisabled ? props.isDisabled : false}
                    id={`${moduleForId}-spdesign-extra-footer-button`}
                  />
                )}
                <Button
                  title={props.saveButtonTitle ? props.saveButtonTitle : "Save"}
                  onClick={props.onSaveClick}
                  isLoading={props.loading}
                  variant="newColorThemePrimary"
                  isDisabled={props.isDisabled ? props.isDisabled : false}
                  id={`${moduleForId}-spdesign-footer-save`}
                />
              </>
            ) : (
              <>
                {!props.hideDiscard ? (
                  <Button
                    title={props?.discardTitle || "Cancel"}
                    variant={buttonVariants.greyLinkButton}
                    mr={"20px"}
                    onClick={props.onClose}
                    id={`${moduleForId}-footer-cancel`}
                  />
                ) : (
                  ""
                )}
                {props.extraFooterButton && (
                  <Button
                    title={
                      props.extraFooterButtonTitle
                        ? props.extraFooterButtonTitle
                        : "Save"
                    }
                    onClick={props.extraFooterButtonClick}
                    isLoading={props.loading}
                    variant={buttonVariants.brandPrimaryOutline}
                    mr="12px"
                    isDisabled={props.isDisabled ? props.isDisabled : false}
                    id={`${moduleForId}-extra-footer-button`}
                  />
                )}
                <Button
                  title={props.saveButtonTitle ? props.saveButtonTitle : "Save"}
                  onClick={props.onSaveClick}
                  isLoading={props.loading}
                  isDisabled={props.isDisabled ? props.isDisabled : false}
                  variant={buttonVariants.brandPrimary}
                  id={`${moduleForId}-footer-save`}
                />
              </>
            )}
          </ModalFooter>
        ) : null}
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
