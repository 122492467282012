import React, { useEffect, useMemo, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  Spinner,
  Stack,
  Text,
  Link,
  VStack,
  Image,
  Box,
  Flex,
} from "@chakra-ui/react";
import { fetchLeadDealStatus } from "../services/utility";
import SuccessCheck from "../images/success-check.svg";
import ErrorCheck from "../images/error-check.svg";

const LeadDealStatus = ({ portal, type }) => {
  const { id } = useParams();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const flag = new URLSearchParams(search).get("flag");
  const [apiRes, setApiRes] = useState(null);
  const performedAction = useMemo(
    () => apiRes?.message?.includes("already"),
    [apiRes?.message]
  );

  let initialize = false;
  useEffect(() => {
    if (!initialize) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      initialize = true;
      const fetchLeadStatus = async () => {
        const res = await fetchLeadDealStatus(
          portal,
          type,
          `${id}${location.search}`
        );
        setApiRes(res);
      };
      fetchLeadStatus();
    }
  }, []);

  return (
    <Flex
      minH={"100vh"}
      justifyContent={"center"}
      alignItems={"center"}
      direction={{ base: "column", md: "row" }}
      pos={"relative"}
    >
      {!apiRes ? (
        <Spinner color="#0C94AC" size={"lg"} />
      ) : (
        <VStack gap={2}>
          <Box mb={3}>
            <Image
              src={
                (flag === "Accepted" || performedAction) && flag !== "Rejected"
                  ? SuccessCheck
                  : ErrorCheck
              }
              h={"70px"}
            />
          </Box>
          <Stack textAlign={"center"} textTransform={"capitalize"}>
            {apiRes?.code === 200 && (
              <Text fontSize={"16px"} fontWeight={700} color={"#111A29"}>
                {type} {flag}
              </Text>
            )}
            <Text fontSize={"14px"} fontWeight={500} color={"#7E8792"}>
              {apiRes?.message}
            </Text>
          </Stack>
          <Link
            href={`/${type}s`}
            p="8px 16px"
            bg={"#0C94AC"}
            fontSize={"14px"}
            fontWeight={500}
            color={"#FFFFFF"}
            rounded={"8px"}
            textTransform={"capitalize"}
            _hover={{ bg: "#0C94AC" }}
          >
            Back to {type}
          </Link>
        </VStack>
      )}
    </Flex>
  );
};

export default LeadDealStatus;
