import React from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerFooter,
  DrawerBody,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { Button } from "@coachbar/shared-components";
import { scrollbarCSS } from "../../constants/constantValues";

const CustomDrawer = (props) => {
  const { moduleForId } = props;
  return (
    <Drawer
      onClose={props.onClose}
      isOpen={props.isOpen}
      isCentered
      closeOnEsc={props?.closeOnOverlayClick || false}
      // size={props.size ? props.size : "md"}
      closeOnOverlayClick={props?.closeOnOverlayClick || false}
      blockScrollOnMount={false}
      {...props}
    >
      {props?.overlayShow !== false && <DrawerOverlay />}

      <DrawerContent
        minW={props.minW ? props.minW : "480px"}
        top={props?.spacingTop || 0}
      >
        <DrawerHeader
          borderBottom={"1px solid"}
          borderBottomColor={props.borderBottomColor || "#DEE8F8"}
          fontSize="lg"
          lineHeight={"20px"}
          letterSpacing={"0.14px"}
          fontWeight={"700"}
          color={"var(--title-black-text)"}
          bg={props?.headerBg ? props?.headerBg : "#FFF"}
          p={
            props?.headerPadding ? props?.headerPadding : "16px 24px !important"
          }
          textAlign={props.alignHeader ? props.alignHeader : "unset"}
        >
          {props.title}
        </DrawerHeader>
        {props.allowCloseButton ? (
          <DrawerCloseButton
            p={props?.headerPadding ? props?.headerPadding : "16px 24px"}
          />
        ) : null}
        <DrawerBody p={props?.bodyPadding ?? "24px"} css={scrollbarCSS}>
          {props.children}
        </DrawerBody>
        {props.displayFooter ? (
          <DrawerFooter
            borderTop={"1px"}
            borderTopColor={"#DEE8F8"}
            justifyContent={props.alignFooter ? props.alignFooter : "end"}
          >
            {props.spDesign ? (
              <>
                {!props.hideDiscard ? (
                  <Button
                    title={props?.discardTitle || "Discard"}
                    mr={3}
                    onClick={props.onClose}
                    style={{
                      borderRadius: "4px",
                      background: "#FFF",
                      color: "#5E6977",
                      borderColor: "#fff",
                    }}
                    id={`${moduleForId}-drawer-footer-discard`}
                  />
                ) : (
                  ""
                )}
                {props.extraFooterButton && (
                  <Button
                    title={
                      props.extraFooterButtonTitle
                        ? props.extraFooterButtonTitle
                        : "Save"
                    }
                    onClick={props.extraFooterButtonClick}
                    isLoading={props.loading}
                    variant="newColorThemePrimary"
                    mr="12px"
                    bg="#fff"
                    color="#0C94AC"
                    isDisabled={props.isDisabled ? props.isDisabled : false}
                    id={`${moduleForId}-drawer-footer-extra`}
                  />
                )}
                <Button
                  title={props.saveButtonTitle ? props.saveButtonTitle : "Save"}
                  onClick={props.onSaveClick}
                  isLoading={props.loading}
                  variant="newColorThemePrimary"
                  isDisabled={props.isDisabled ? props.isDisabled : false}
                  id={`${moduleForId}-drawer-footer-save`}
                />
              </>
            ) : (
              <>
                {!props.hideDiscard ? (
                  <Button
                    title={props?.discardTitle || "Discard"}
                    variant="discard"
                    mr={3}
                    onClick={props.onClose}
                    id={`${moduleForId}-drawer-footer-discard`}
                  />
                ) : (
                  ""
                )}
                {props.extraFooterButton && (
                  <Button
                    title={
                      props.extraFooterButtonTitle
                        ? props.extraFooterButtonTitle
                        : "Save"
                    }
                    onClick={props.extraFooterButtonClick}
                    isLoading={props.loading}
                    variant="discard"
                    bg="#fff"
                    mr="12px"
                    border="2px solid #0C94AC"
                    color="#0C94AC"
                    isDisabled={props.isDisabled ? props.isDisabled : false}
                    id={`${moduleForId}-drawer-footer-extra`}
                  />
                )}
                <Button
                  title={props.saveButtonTitle ? props.saveButtonTitle : "Save"}
                  onClick={props.onSaveClick}
                  isLoading={props.loading}
                  isDisabled={props.isDisabled ? props.isDisabled : false}
                  id={`${moduleForId}-drawer-footer-save`}
                />
              </>
            )}
          </DrawerFooter>
        ) : null}
      </DrawerContent>
    </Drawer>
  );
};

export default CustomDrawer;
