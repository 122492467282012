import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Text,
} from "@chakra-ui/react";
import { ResponsiveChoropleth } from "@nivo/geo";
import React, { useMemo, useState } from "react";
import { geoChartData } from "../../constants/charts";
import { regionColors, regionKeys } from "../../constants/dashboard";
import { formatYAxisTicks } from "../../services/dashboardUtility/utility";
import { getCurrency } from "../../services/utility";
import { minusChange, plusChange } from "../Icons/SvgsConstants";

const GeoGraph = ({ data, showCurrency = false }) => {
  const [rotation, setRotation] = useState([0, 0, 0]);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);

  const handleMouseDown = (event) => {
    event.preventDefault();

    setDragging(true);
    setStartX(event.clientX);
  };

  const handleMouseMove = (event) => {
    event.preventDefault();

    if (dragging) {
      const newOffsetX = event.clientX - startX;
      const newRotation = [...rotation];
      newRotation[0] = newOffsetX;
      setRotation(newRotation);
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  let fillArray = useMemo(() => {
    return data.map((x) => {
      return {
        match: {
          id: x.id,
        },
        id: x.regionKey,
      };
    });
  }, [data]);

  let defs = useMemo(() => {
    return Object.keys(regionKeys).map((key) => {
      return {
        id: key,
        type: "linearGradient",
        colors: [
          {
            offset: 0,
            color: regionColors[key],
          },
        ],
      };
    });
  }, []);

  const formatValue = (value) => {
    const formattedValue = formatYAxisTicks(value) || 0;
    return showCurrency
      ? `${getCurrency().symbol}${formattedValue}`
      : formattedValue;
  };

  return (
    <Box>
      <div
        style={{
          width: "100%",
          height: "400px",
          // overflow: "hidden",
          cursor: dragging ? "move" : "pointer",
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <ResponsiveChoropleth
          data={data}
          // width={"100%"}
          height={400}
          features={geoChartData.features}
          colors={["#3F83F8"]}
          domain={[0, 1000000]}
          unknownColor="#FFF"
          label="properties.name"
          valueFormat=".2s"
          projectionScale={82}
          projectionTranslation={[0.5, 0.6]}
          projectionRotation={rotation}
          enableGraticule={false}
          graticuleLineColor=""
          borderWidth={1}
          borderColor="#A4CAFE99"
          legends={[]}
          tooltip={(e) => {
            const geoData = e.feature;
            return (
              geoData.data && (
                <Flex
                  padding="6px 10px"
                  alignItems="center"
                  background="#1C2C44"
                  rounded="8px"
                  boxShadow="rgba(183, 200, 225, 1)"
                  gap={"24px"}
                  h={"auto"}
                >
                  <Box>
                    <Text
                      color="#B7C8E1"
                      fontSize="14px"
                      fontWeight={600}
                      lineHeight="16px"
                      mb={"2px"}
                    >
                      {geoData?.data?.legend}
                    </Text>
                    <Flex alignItems={"center"} gap={"8px"}>
                      <Text color="white" fontSize="12px" fontWeight={600}>
                        {formatValue(geoData?.data?.value || 0)}
                      </Text>
                      {geoData?.data?.hasOwnProperty("valueChange") &&
                        geoData?.data?.valueChange !== 0 && (
                          <Flex alignItems={"center"}>
                            <Box
                              boxSize={"14px"}
                              css={{
                                path: {
                                  stroke:
                                    geoData?.data?.valueChange >= 0
                                      ? "#37AA73"
                                      : "#F98080",
                                },
                              }}
                            >
                              {geoData?.data?.valueChange > 0
                                ? plusChange
                                : minusChange}
                            </Box>
                            <Text
                              fontSize="14px"
                              fontWeight={600}
                              color={
                                geoData?.data?.valueChange > 0
                                  ? "#5FBB8F"
                                  : "#C81E1E"
                              }
                            >
                              {formatValue(geoData?.data?.valueChange)}
                            </Text>
                          </Flex>
                        )}
                    </Flex>
                  </Box>
                  <CircularProgress
                    size={"40px"}
                    value={Math.round(geoData?.data?.percentage || 0)}
                    color="#FF8A4C"
                    trackColor="#FEECDC"
                    capIsRound
                  >
                    <CircularProgressLabel
                      color={"white"}
                      fontSize={"12px"}
                      fontWeight={600}
                    >
                      {Math.round(geoData?.data?.percentage || 0)}%
                    </CircularProgressLabel>
                  </CircularProgress>
                </Flex>
              )
            );
          }}
          defs={defs}
          fill={fillArray}
        />
      </div>
    </Box>
  );
};

export default GeoGraph;
