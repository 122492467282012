import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { useDrop } from "react-dnd";

const DropZone = ({ id, index, onDrop, children, type }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: type,
    drop: (item, monitor) => {
      let newIndex = index;
      if (item.index < index) newIndex = index - 1;
      onDrop({ id, index: item?.dragFrom === "list" ? newIndex : index }, item);
    },
    canDrop: (item) => {
      if (item.index === undefined) return true;
      return !(index === item.index || index === item.index + 1);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <Box
      ref={drop}
      opacity={canDrop ? 1 : 0}
      h={"15px"}
      w={"100%"}
      px={"36px"}
      border={canDrop ? "1px dashed #0C94AC" : "none"}
      bg={isOver ? "#0C94AC" : "none"}
    >
      {children || (
        <Text
          color={"white"}
          fontSize={"10px"}
          textAlign={"center"}
          fontWeight={500}
        >
          Release here to Drop
        </Text>
      )}
    </Box>
  );
};

export default DropZone;
