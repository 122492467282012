import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { format, subDays } from "date-fns";
import {
  leadDealSource,
  leadDealType,
  regionKeys,
  regionLabels,
} from "../../constants/dashboard";
import { getAppliedFilters } from "../../services/dashboardUtility/utility";
import { deepCopyObject } from "../../services/utility";

const defaultDateRange = {
  from: format(subDays(new Date(), 30), "yyyy-MM-dd"),
  to: format(new Date(), "yyyy-MM-dd"),
};

export const ChartsContext = createContext({
  dashboardData: {},
  selectedDate: defaultDateRange,
  setSelectedDate: () => {},
  chartList: {},
  onChartHide: () => {},
  module: "dashboard",
  filters: {},
  appliedFilters: {},
  partnerManagers: [],
  statuses: [],
  tierList: [],
  partnerTypes: [],
  partnerGroups: [],
  userInfo: {},
  platformJson: {},
});

const ChartsProvider = ({
  children,
  charts,
  module,
  partnerId,
  partners,
  partnerManagers,
  partnerTypes,
  partnerGroups,
  partnerTiers,
  userInfo,
  dashboardData,
  platform,
  updateDashboardApi,
  dashboardType,
  isMyPartner,
  updateDashboardListAction,
}) => {
  const dispatch = useDispatch();

  const platformJson = {
    platform: platform,
    partnerTitle: platform === "sc" ? "Provider(s)" : "Partner(s)",
    otherPlatform: platform === "sc" ? "sp" : "sc",
  };
  const fixFilters = {
    partners:
      partners?.map((x) => {
        return {
          ...x,
          name: x?.[`${platformJson?.otherPlatform}CompanyName`],
          label: x?.[`${platformJson?.otherPlatform}CompanyName`],
          value: x.referenceTenantId,
          isChecked: false,
        };
      }) || [],
    partnerManagers:
      partnerManagers?.map((x) => {
        return {
          ...x,
          name: `${x.firstName} ${x.lastName}`,
          label: `${x.firstName} ${x.lastName}`,
          value: x.id,
          isChecked: false,
        };
      }) || [],
    partnerTypes:
      partnerTypes?.map((x) => {
        return {
          ...x,
          name: `${x.name}`,
          label: `${x.name}`,
          value: x.id,
          isChecked: false,
        };
      }) || [],
    partnerGroups:
      partnerGroups?.map((x) => {
        return {
          ...x,
          name: `${x.name}`,
          label: `${x.name}`,
          value: x.id,
          isChecked: false,
        };
      }) || [],
    partnerTiers:
      partnerTiers?.map((x) => {
        return {
          ...x,
          name: `${x.title}`,
          label: `${x.title}`,
          value: x.id,
          isChecked: false,
        };
      }) || [],
    regions: Object.keys(regionKeys).map((x) => {
      return {
        label: regionLabels[x],
        name: regionLabels[x],
        value: x,
        isChecked: false,
      };
    }),
    type: leadDealType.map((x) => ({
      label: x,
      name: x,
      value: x,
      isChecked: false,
    })),
    source: [
      ...(platform === "sc"
        ? [
            {
              label: "Service Request",
              name: "Service Request",
              value: "Service Request",
              isChecked: false,
            },
          ]
        : []),
      ...leadDealSource.map((x) => ({
        label: x,
        name: x,
        value: x,
        isChecked: false,
      })),
      ...(partners
        ?.filter((x) => {
          let addSource = true;
          if (partnerId && x.referenceTenantId !== partnerId) {
            addSource = false;
          }
          return addSource;
        })
        .map((x) => {
          return {
            ...x,
            name: x?.[`${platformJson?.otherPlatform}CompanyName`],
            label: x?.[`${platformJson?.otherPlatform}CompanyName`],
            value: x.referenceTenantId,
            isChecked: false,
          };
        }) || []),
    ],
  };
  const [allFilterOptions, setAllFilterOptions] = useState(
    deepCopyObject(fixFilters)
  );
  const [selectedDate, setSelectedDate] = useState(defaultDateRange);
  const [filters, setFilters] = useState(deepCopyObject(fixFilters));
  const [chartList, setChartList] = useState(charts);
  const [dashData, setDashData] = useState(deepCopyObject(dashboardData));

  useEffect(() => {
    setDashData(deepCopyObject(dashboardData));
  }, [dashboardData]);

  const onChartHide = async (chartSlug, callback) => {
    let newChartList = { ...chartList };
    newChartList[chartSlug].isVisible = false;

    if (dashData?.id) {
      let objPass = deepCopyObject(dashData);
      objPass.widgetList = deepCopyObject(
        objPass.widgetList.filter((x) => x !== chartSlug)
      );
      let { data } = await updateDashboardApi(objPass);
      callback();
      if (data?.code === 200) {
        setDashData(data?.data?.dashboard);
        setChartList(newChartList);
        if (updateDashboardListAction) dispatch(updateDashboardListAction());
      }
    } else {
      let objPass = {
        dashboardType: dashboardType,
        widgetList: deepCopyObject(
          Object.keys(chartList).filter((x) => x !== chartSlug)
        ),
      };
      let { data } = await updateDashboardApi(objPass);
      callback();
      if (data?.code === 200) {
        setDashData(data?.data?.dashboard);
        setChartList(newChartList);
        if (updateDashboardListAction) dispatch(updateDashboardListAction());
      }
    }
  };
  useEffect(() => {
    let newFilters = deepCopyObject(filters);
    newFilters.source = [
      ...leadDealSource.map((x) => ({
        label: x,
        name: x,
        value: x,
        isChecked: false,
      })),
      ...(partners
        ?.filter((x) => {
          let addSource = true;
          if (partnerId && x.referenceTenantId !== partnerId) {
            addSource = false;
          }
          return addSource;
        })
        .map((x) => {
          return {
            ...x,
            name: x?.[`${platformJson?.otherPlatform}CompanyName`],
            label: x?.[`${platformJson?.otherPlatform}CompanyName`],
            value: x.referenceTenantId,
            isChecked: false,
          };
        }) || []),
    ];
    setFilters(newFilters);
    setAllFilterOptions(newFilters);
  }, [partnerId]);

  const onFiltersSubmit = (newFilters) => {
    setFilters(deepCopyObject(newFilters));
  };

  let appliedFilters = useMemo(() => {
    return getAppliedFilters(filters);
  }, [filters]);

  const data = {
    selectedDate: selectedDate,
    module: module,
    chartList,
    setSelectedDate: (dt) => {
      setSelectedDate({ ...dt });
    },
    setChartList,
    onChartHide,
    onFiltersSubmit: onFiltersSubmit,
    setDashboardData: setDashData,
    partnerId: partnerId,
    fixFilters: allFilterOptions,
    isMyPartner,
    filters: filters,
    appliedFilters: appliedFilters,
    userInfo: userInfo,
    dashboardData: dashData,
    platformJson: platformJson,
  };

  return (
    <ChartsContext.Provider value={data}>{children}</ChartsContext.Provider>
  );
};

const useChartsProvider = () => {
  const context = useContext(ChartsContext);
  if (context === undefined) {
    throw new Error("useCharts can only be used inside Partner Card Component");
  }
  return context;
};

export { ChartsProvider, useChartsProvider };
