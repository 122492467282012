import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  SimpleGrid,
  Skeleton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Button } from "@coachbar/shared-components";
import {
  attachmentNote,
  dollarNote,
  dueNote,
  greenWallet,
  minusCircle,
  plusCircle,
} from "@coachbar/shared-components/src/components/Icons/SvgsConstants";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import OverviewDetailsListing from "./overviewDetailsListing";
import InquiriesDrawer from "./InquiriesDrawer";
import { getInquiryList, getNoteList, getPlanDetails } from "./rewardsActions";
import { getUserInfo } from "../../services/utility";
import { scrollbarCSS } from "@coachbar/shared-components/src/constants/constantValues";
import { getCurrency } from "@coachbar/shared-components/src/services/utility";
import OverviewDetailsPerformanceListing from "./overviewDetailsPerformanceListing";

const OverviewDetails = ({
  commissionDetailLoader,
  planNameListLoader,
  commissionDetail,
  planNameList,
  fetchCommissionDetails,
}) => {
  const params = useParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const [inquiryList, setInquiryList] = useState([]);
  const [noteList, setNoteList] = useState([]);
  const [selectedPlanId, setSelectedPlanId] = useState("");
  const [programOverviewShow, setProgramOverviewShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [programOverviewDetails, setProgramOverviewDetails] = useState({});
  const [programOverviewDetailsLoader, setProgramOverviewDetailsLoader] =
    useState(false);
  const userInfo = getUserInfo();
  const [isInquiryFetching, setIsInquiryFetching] = useState(false);
  const [isNotesFetching, setIsNotesFetching] = useState(false);
  const [inquiryNextToken, setInquiryNextToken] = useState(null);
  const [notesNextToken, setNotesNextToken] = useState(null);
  const [performanceDetail, setPerformanceDetail] = useState(false);
  const [performanceLoading, setPerformanceLoading] = useState(false);
  const {
    onOpen: onInquiryModalOpen,
    isOpen: isInquiryModalOpen,
    onClose: onInquiryModalClose,
  } = useDisclosure();

  const fetchPlanDetails = async (id, spTenantId) => {
    setProgramOverviewDetailsLoader(true);

    try {
      const res = await getPlanDetails(id, spTenantId);

      if (res) {
        setProgramOverviewDetails(res?.data?.plans ?? {});
      } else {
        setProgramOverviewDetails({});
      }
    } catch (error) {
      console.error("Error fetching plan details:", error);
    } finally {
      setProgramOverviewDetailsLoader(false);
    }
  };

  useEffect(() => {
    setSelectedPlanId(planNameList?.planId ?? "");
    if (planNameList?.historicalPlanId) {
      const spTenantIdFind = planNameList?.planList?.find(
        (x) => x.planId === planNameList?.planId
      );
      fetchPlanDetails(
        planNameList?.historicalPlanId,
        spTenantIdFind?.spTenantId
      );
    }
  }, [planNameList]);

  const fetchInquiryList = async (prevRecords = false) => {
    setIsInquiryFetching(true);
    const res = await getInquiryList({
      limit: 25,
      tenantId: userInfo?.tenantId,
      sourceId: params?.rewardId,
      inquiryType: null,
      nextToken: inquiryNextToken,
    });
    setIsInquiryFetching(false);
    if (res && res.data) {
      setInquiryNextToken(res.data?.inquiryList?.nextToken);
      if (prevRecords) {
        setInquiryList([...inquiryList, ...res.data?.inquiryList?.inquiryList]);
      } else {
        setInquiryList(res.data?.inquiryList?.inquiryList);
      }
    }
  };

  const fetchNoteList = async (prevRecords) => {
    setIsNotesFetching(true);
    const res = await getNoteList({
      limit: 20,
      tenantID: userInfo?.tenantId,
      referenceTenantId: params?.rewardId,
      noteType: "User Inquiries",
      nextToken: notesNextToken,
    });
    setIsNotesFetching(false);
    if (res && res.data) {
      setNotesNextToken(res.data?.nextToken);
      if (prevRecords) {
        setNoteList([...noteList, ...res.data?.noteResultList]);
      } else {
        setNoteList(res.data?.noteResultList);
      }
    }
  };

  let initialized = true;
  useEffect(() => {
    initialized = false;
    fetchNoteList();
    fetchInquiryList();
  }, []);

  const handleCommisionDetails = async (
    historicalPlanId = "",
    planId = "",
    spTenantId = "",
    index
  ) => {
    setSelectedTab(index);
    setSelectedPlanId(planId);
    fetchPlanDetails(historicalPlanId, spTenantId);
  };

  const selectedPlan = useMemo(() => {
    return planNameList?.planList?.find(
      (item) => item.planId === selectedPlanId
    );
  }, [planNameList?.planList, selectedPlanId]);

  return (
    <Box p={"24px"}>
      <Flex justifyContent={"flex-end"} mb={"24px"} gap={6}>
        <Button
          variant={"newColorThemePrimary"}
          borderColor={"#CEEAEE"}
          bg={"#E7FAFD"}
          color={"#0C94AC"}
          title="View Inquiries"
          borderRadius="6px"
          onClick={onInquiryModalOpen}
          id="rewards-details-view"
        />
      </Flex>
      <SimpleGrid columns={4} gap={"20px"} mb={"20px"}>
        <Box
          border="1px solid #D7EEE3"
          bg={"#fff"}
          rounded={"8px"}
          p={"12px 20px"}
        >
          <HStack justifyContent={"space-between"}>
            <Text fontSize={"14px"} fontWeight={500} color={"#5E6977"}>
              Total Reward(s)
            </Text>
            {greenWallet}
          </HStack>
          <Text fontSize={"18px"} fontWeight={700} color={"#2C885C"}>
            {commissionDetailLoader ? (
              <Skeleton h={"22px"} w={"100px"} borderRadius={"6px"} />
            ) : (
              <>
                {getCurrency().symbol}
                {commissionDetail?.scTotal?.toFixed(2) || 0}
              </>
            )}
          </Text>
        </Box>
        <Box
          border="1px solid #D7EEE3"
          bg={"#fff"}
          rounded={"8px"}
          p={"12px 20px"}
        >
          <HStack justifyContent={"space-between"}>
            <Text fontSize={"14px"} fontWeight={500} color={"#5E6977"}>
              Clawback
            </Text>
            {attachmentNote}
          </HStack>
          <Text fontSize={"18px"} fontWeight={700} color={"#B3324E"}>
            {commissionDetailLoader ? (
              <Skeleton h={"22px"} w={"100px"} borderRadius={"6px"} />
            ) : (
              <>
                {getCurrency().symbol}
                {commissionDetail?.scClawbackAmount?.toFixed(2) || 0}
              </>
            )}
          </Text>
        </Box>
        <Box
          border="1px solid #D7EEE3"
          bg={"#fff"}
          rounded={"8px"}
          p={"12px 20px"}
        >
          <HStack justifyContent={"space-between"}>
            <Text fontSize={"14px"} fontWeight={500} color={"#5E6977"}>
              Reward(s) Paid
            </Text>
            {dollarNote}
          </HStack>
          <Text fontSize={"18px"} fontWeight={700} color={"#1C64F2"}>
            {commissionDetailLoader ? (
              <Skeleton h={"22px"} w={"100px"} borderRadius={"6px"} />
            ) : (
              <>
                {getCurrency().symbol}
                {commissionDetail?.scPayoutAmount?.toFixed(2) || 0}
              </>
            )}
          </Text>
        </Box>
        <Box
          border="1px solid #D7EEE3"
          bg={"#fff"}
          rounded={"8px"}
          p={"12px 20px"}
        >
          <HStack justifyContent={"space-between"}>
            <Text fontSize={"14px"} fontWeight={500} color={"#5E6977"}>
              Due
            </Text>
            {dueNote}
          </HStack>
          <Text fontSize={"18px"} fontWeight={700} color={"#C27803"}>
            {commissionDetailLoader ? (
              <Skeleton h={"22px"} w={"100px"} borderRadius={"6px"} />
            ) : (
              <>
                {getCurrency().symbol}
                {commissionDetail?.scDueAmount?.toFixed(2) || 0}
              </>
            )}
          </Text>
        </Box>
      </SimpleGrid>
      <Box bg={"#fff"} border="1px solid #E6E7E999" rounded={"4px"} p={"20px"}>
        <Text fontSize={"14px"} fontWeight={600} color={"#111A29"} mb={"8px"}>
          Partner Programs Reward Calculation
        </Text>
        {planNameListLoader ? (
          <HStack spacing={"24px"}>
            <Skeleton h={"35px"} w={"200px"} borderRadius={"6px"} />
            <Skeleton h={"35px"} w={"200px"} borderRadius={"6px"} />
            <Skeleton h={"35px"} w={"200px"} borderRadius={"6px"} />
            <Skeleton h={"35px"} w={"200px"} borderRadius={"6px"} />
            <Skeleton h={"35px"} w={"200px"} borderRadius={"6px"} />
          </HStack>
        ) : (
          <>
            {planNameList?.planList?.length ? (
              <>
                <HStack
                  spacing={"24px"}
                  css={scrollbarCSS}
                  w={"100%"}
                  overflow={"auto"}
                >
                  {planNameList?.planList?.map((plan, i) => {
                    return (
                      <Text
                        key={i}
                        cursor={
                          programOverviewDetailsLoader
                            ? "not-allowed"
                            : "pointer"
                        }
                        py={"6px"}
                        fontSize={"14px"}
                        fontWeight={500}
                        whiteSpace={"nowrap"}
                        color={selectedTab === i ? "#0C94AC" : "#7E8792"}
                        borderBottom={`2px solid ${
                          selectedTab === i ? "#0C94AC" : "transparent"
                        }`}
                        onClick={() => {
                          if (!programOverviewDetailsLoader) {
                            handleCommisionDetails(
                              plan?.historicalPlanId,
                              plan?.planId,
                              plan?.spTenantId,
                              i
                            );
                          }
                        }}
                      >
                        {plan?.planName}
                      </Text>
                    );
                  })}
                </HStack>
                <Box>
                  {Object.entries(programOverviewDetails).length > 0 ? (
                    <>
                      <HStack my={"24px"} spacing={"60px"}>
                        <Box>
                          <Text
                            fontSize={"12px"}
                            fontWeight={600}
                            color={"#5E6977"}
                          >
                            Effective Date
                          </Text>
                          <Text
                            fontSize={"14px"}
                            fontWeight={600}
                            color={"#111A29"}
                          >
                            {programOverviewDetailsLoader ? (
                              <Skeleton
                                h={"20px"}
                                w={"180px"}
                                borderRadius={"6px"}
                              />
                            ) : (
                              <>
                                {programOverviewDetails?.effectiveStartDate &&
                                  format(
                                    new Date(
                                      programOverviewDetails?.effectiveStartDate
                                    ),
                                    "MMM dd, yyyy"
                                  )}{" "}
                                -{" "}
                                {programOverviewDetails?.effectiveEndDate
                                  ? format(
                                      new Date(
                                        programOverviewDetails?.effectiveEndDate
                                      ),
                                      "MMM dd, yyyy"
                                    )
                                  : "On Going"}
                              </>
                            )}
                          </Text>
                        </Box>
                        <Box flexGrow={1}>
                          <Text
                            fontSize={"12px"}
                            fontWeight={600}
                            color={"#5E6977"}
                          >
                            Total Reward
                          </Text>
                          <Text
                            fontSize={"14px"}
                            fontWeight={600}
                            color={"#2C885C"}
                          >
                            {programOverviewDetailsLoader ? (
                              <Skeleton
                                h={"20px"}
                                w={"180px"}
                                borderRadius={"6px"}
                              />
                            ) : (
                              <>
                                {getCurrency().symbol}
                                {selectedPlan?.scTotalAmount?.toFixed(2) || 0}
                              </>
                            )}
                          </Text>
                        </Box>
                        <HStack
                          cursor={"pointer"}
                          onClick={() =>
                            setProgramOverviewShow(!programOverviewShow)
                          }
                        >
                          <Text
                            fontSize={"14px"}
                            fontWeight={700}
                            color={"#0C94AC"}
                            userSelect={"none"}
                          >
                            Program Overview
                          </Text>
                          {programOverviewShow ? minusCircle : plusCircle}
                        </HStack>
                      </HStack>
                      {programOverviewShow && (
                        <Box
                          border={"1px solid #E6E7E9"}
                          rounded={"6px"}
                          p={"24px"}
                          mb={"24px"}
                        >
                          {programOverviewDetails?.outcomeBaseObject?.rates?.map(
                            (item) => {
                              return (
                                <SimpleGrid
                                  columns={3}
                                  bg={"#F9FAFB"}
                                  p={"8px 20px"}
                                  rounded={"8px"}
                                  mb={"8px"}
                                >
                                  <Box>
                                    <Text
                                      fontSize={"12px"}
                                      fontWeight={500}
                                      color={"#5E6977"}
                                    >
                                      Base
                                    </Text>
                                    <Text
                                      fontSize={"14px"}
                                      fontWeight={600}
                                      color={"#111A29"}
                                    >
                                      {programOverviewDetailsLoader ? (
                                        <Skeleton
                                          h={"20px"}
                                          w={"180px"}
                                          borderRadius={"6px"}
                                        />
                                      ) : (
                                        <>
                                          {programOverviewDetails
                                            ?.outcomeBaseObject?.type ===
                                          "Performance Base"
                                            ? item?.title
                                            : programOverviewDetails
                                                ?.outcomeBaseObject?.titles[0]}
                                        </>
                                      )}
                                    </Text>
                                  </Box>
                                  <Box>
                                    <Text
                                      fontSize={"12px"}
                                      fontWeight={500}
                                      color={"#5E6977"}
                                    >
                                      Rate Type
                                    </Text>
                                    <Text
                                      fontSize={"14px"}
                                      fontWeight={600}
                                      color={"#111A29"}
                                    >
                                      {programOverviewDetailsLoader ? (
                                        <Skeleton
                                          h={"20px"}
                                          w={"180px"}
                                          borderRadius={"6px"}
                                        />
                                      ) : (
                                        <>{item?.rateType}</>
                                      )}
                                    </Text>
                                  </Box>
                                  <Box>
                                    <Text
                                      fontSize={"12px"}
                                      fontWeight={500}
                                      color={"#5E6977"}
                                    >
                                      {programOverviewDetails?.outcomeBaseObject
                                        ?.type === "Performance Base"
                                        ? "Revenue Bands"
                                        : "Rate Per Product"}
                                    </Text>
                                    <Text
                                      fontSize={"14px"}
                                      fontWeight={600}
                                      color={"#111A29"}
                                    >
                                      {programOverviewDetailsLoader ? (
                                        <Skeleton
                                          h={"20px"}
                                          w={"180px"}
                                          borderRadius={"6px"}
                                        />
                                      ) : (
                                        <>
                                          {programOverviewDetails
                                            ?.outcomeBaseObject?.type ===
                                          "Performance Base" ? (
                                            <>
                                              {item?.isRevenueBandApplied
                                                ? "Yes"
                                                : "No"}
                                            </>
                                          ) : (
                                            <>
                                              {item?.isRatePerProduct
                                                ? "Yes"
                                                : "No"}
                                            </>
                                          )}
                                        </>
                                      )}
                                    </Text>
                                  </Box>
                                </SimpleGrid>
                              );
                            }
                          )}
                          <SimpleGrid mt={"8px"} columns={2} gap={"20px"}>
                            {programOverviewDetails?.outcomeBaseObject?.calculationRules?.map(
                              (item, index) => {
                                const formattedRules = [];
                                const ruleLabels = {
                                  dealType: "Deal Type",
                                  leadType: "Lead Type",
                                  dealStatus: "Deal Status",
                                  leadStatus: "Lead Status",
                                };
                                for (const [key, value] of Object.entries(
                                  item?.rule
                                )) {
                                  formattedRules.push(
                                    `${ruleLabels[key]} is ${value}`
                                  );
                                }
                                return (
                                  <Box
                                    bg={"#F9FAFB"}
                                    p={"8px 20px"}
                                    rounded={"8px"}
                                  >
                                    <Text
                                      fontSize={"12px"}
                                      fontWeight={500}
                                      color={"#5E6977"}
                                    >
                                      Calculations Rule(s): {item?.name}
                                    </Text>
                                    <SimpleGrid columns={2}>
                                      {formattedRules?.map((rule) => {
                                        return (
                                          <Text
                                            fontSize={"14px"}
                                            fontWeight={600}
                                            color={"#111A29"}
                                          >
                                            {programOverviewDetailsLoader ? (
                                              <Skeleton
                                                h={"20px"}
                                                w={"180px"}
                                                borderRadius={"6px"}
                                              />
                                            ) : (
                                              <>{rule}</>
                                            )}
                                          </Text>
                                        );
                                      })}
                                    </SimpleGrid>
                                  </Box>
                                );
                              }
                            )}
                          </SimpleGrid>
                        </Box>
                      )}
                    </>
                  ) : (
                    <Center py={"70px"}>
                      <Text
                        fontSize={"14px"}
                        fontWeight={600}
                        color={"#5E6977"}
                      >
                        No data to display
                      </Text>
                    </Center>
                  )}
                  {selectedPlanId &&
                    programOverviewDetails?.outcomeBaseObject?.type ===
                      "Outcome Base" && (
                      <OverviewDetailsListing
                        fetchInquiryList={fetchInquiryList}
                        commissionDetail={commissionDetail}
                        programOverviewDetails={programOverviewDetails}
                        programOverviewDetailsLoader={
                          programOverviewDetailsLoader
                        }
                        planId={selectedPlanId}
                        fetchCommissionDetails={fetchCommissionDetails}
                      />
                    )}
                  {selectedPlanId &&
                    !programOverviewDetailsLoader &&
                    programOverviewDetails?.outcomeBaseObject?.type ===
                      "Performance Base" && (
                      <OverviewDetailsPerformanceListing
                        fetchInquiryList={fetchInquiryList}
                        commissionDetail={commissionDetail}
                        programOverviewDetails={programOverviewDetails}
                        programOverviewDetailsLoader={
                          programOverviewDetailsLoader
                        }
                        planId={selectedPlanId}
                        performanceDetail={performanceDetail}
                        setPerformanceLoading={setPerformanceLoading}
                      />
                    )}
                </Box>
              </>
            ) : (
              <>No plan found</>
            )}
          </>
        )}
      </Box>
      <InquiriesDrawer
        planId={selectedPlanId}
        isInquiryFetching={isInquiryFetching}
        isNotesFetching={isNotesFetching}
        inquiryListNextToken={inquiryNextToken}
        noteListNextToken={notesNextToken}
        requestData={commissionDetail}
        commissionId={params?.rewardId}
        inquiryList={inquiryList}
        noteList={noteList}
        fetchNoteList={fetchNoteList}
        fetchInquiryList={fetchInquiryList}
        isOpen={isInquiryModalOpen}
        onClose={onInquiryModalClose}
      />
    </Box>
  );
};

export default OverviewDetails;
