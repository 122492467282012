import {
  Select,
  FormLabel,
  FormControl,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";
import { React } from "react";
import CustomTooltip from "../Tooltip/CustomTooltip";

const CustomSelect = ({
  validationErrors,
  innerref,
  options,
  hideLabel,
  placeholder,
  onChange,
  parentWidth,
  ...props
}) => {
  const validation = validationErrors && validationErrors[innerref.name];
  return (
    <FormControl
      width={parentWidth ? parentWidth : ""}
      isInvalid={validation}
      isRequired={props.required}
    >
      {!hideLabel ? (
        <FormLabel
          textTransform={"capitalize"}
          fontSize={"12px"}
          fontWeight={600}
          color={"#5E6977"}
          mb={"4px"}
          as="legend"
          display={"flex"}
        >
          {props.name || placeholder}
          {props.tooltip && <CustomTooltip content={props.tooltip} />}
        </FormLabel>
      ) : null}
      <Select
        placeholder={placeholder}
        {...props}
        {...innerref}
        onChange={onChange}
        h="40px"
        fontSize={"14px"}
        isDisabled={props.disable ? true : false}
        _focusVisible={{
          borderColor: !validation ? "#0C94AC" : "none",
        }}
        css={{
          paddingInlineStart: props.leftPadding || "1rem",
        }}
      >
        {options?.map((option) => (
          <option
            value={option.value}
            style={option?.style || {}}
            key={option.value || option.label}
          >
            {option.label}
          </option>
        ))}
      </Select>
      {validation ? (
        <Errors errors={validationErrors[innerref.name]} />
      ) : props.hideHelperText ? (
        ""
      ) : (
        <FormHelperText>&nbsp;</FormHelperText>
      )}
    </FormControl>
  );
};

const Errors = ({ errors }) => {
  if (errors.types && Object.entries(errors.types).length > 0) {
    return Object.entries(errors.types).map(([type, message]) => (
      <FormErrorMessage key={type}>{message}</FormErrorMessage>
    ));
  } else {
    return <FormErrorMessage>{errors.message}</FormErrorMessage>;
  }
};

export default CustomSelect;
