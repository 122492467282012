import { Box, Flex, Text } from "@chakra-ui/react";
import {
  barChartIcon,
  circledCheckMark,
  funnelChartIcon,
  geoGraphIcon,
  lineChartIcon,
  periodSummaryIcon,
  pieChartIcon,
  speedoMeterIcon,
  trainingAndCoursesIcon,
} from "../../components/Icons/SvgsConstants";
import { chartIconsTypes } from "../../constants/dashboard";
import { toggleWidgetProperty } from "../../services/dashboardUtility/utility";

const ChartWidget = ({
  chartData,
  updatedChartData,
  setUpdatedChartData,
  key,
}) => {
  const iconMap = {
    [chartIconsTypes.funnelChartIcon]: funnelChartIcon,
    [chartIconsTypes.pieChartIcon]: pieChartIcon,
    [chartIconsTypes.barChartIcon]: barChartIcon,
    [chartIconsTypes.speedoMeterIcon]: speedoMeterIcon,
    [chartIconsTypes.lineChartIcon]: lineChartIcon,
    [chartIconsTypes.geoGraphIcon]: geoGraphIcon,
    [chartIconsTypes.periodSummaryIcon]: periodSummaryIcon,
    [chartIconsTypes.trainingAndCoursesIcon]: trainingAndCoursesIcon,
  };

  return (
    <Box
      key={key}
      bg={chartData?.isVisible ? "#E7FAFD66" : "#FFF"}
      rounded={"8px"}
      border={
        chartData?.isVisible ? "1px solid #0C94AC" : "1px solid #E6E7E9CC"
      }
      p={"8px"}
      cursor={"pointer"}
      onClick={() =>
        toggleWidgetProperty(
          updatedChartData,
          chartData,
          setUpdatedChartData,
          "isVisible"
        )
      }
    >
      <Flex justifyContent={"end"} w={"full"}>
        {chartData?.isVisible ? (
          <Box boxSize={"16px"}>{circledCheckMark}</Box>
        ) : (
          <Box
            border={"1px solid #BFC3C9CC"}
            boxSize={"16px"}
            rounded={"50%"}
          />
        )}
      </Flex>
      <Box boxSize={"28px"} mb={"12px"} mx={"auto"}>
        {iconMap[chartData?.iconType]}
      </Box>
      <Text
        color={"#111A29"}
        fontSize={"14px"}
        fontWeight={600}
        lineHeight={"20px"}
        textAlign={"center"}
        mb={"4px"}
      >
        {chartData?.title}
      </Text>
      <Text
        color={"#7E8792"}
        fontSize={"12px"}
        fontWeight={500}
        lineHeight={"18px"}
        textAlign={"center"}
      >
        {chartData?.description}
      </Text>
    </Box>
  );
};

export default ChartWidget;
