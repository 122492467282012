import React, { useState } from "react";
import {
  Box,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  errorTriangle,
  uploadIcon,
} from "@coachbar/shared-components/src/components/Icons/SvgsConstants";
import { Button, Link, TextArea } from "@coachbar/shared-components";
import CellLoadingComponent from "@coachbar/shared-components/src/components/Loader/CellLoadingComponent";
import {
  getPreSignedUrl,
  getUserInfo,
  uploadImage,
} from "../../services/utility";
import FileIcon from "../../image/file-icon.png";
import TrashIcon from "@coachbar/shared-components/src/images/trash-grey.svg";
import { sendInquiry } from "./rewardsActions";
import { useParams } from "react-router-dom";

const MAX_PDF_SIZE = 10 * 1024 * 1024; /* 10  MB */

const SendInquiryModal = ({
  planId,
  repliesData,
  setRepliesData,
  fetchInquiryList,
  requestData,
  inquiryType,
  isOpen,
  onClose,
}) => {
  const [fileError, setFileError] = useState("");
  const [inquiryMsg, setInquiryMsg] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const userInfo = getUserInfo();
  const params = useParams();

  const onFileUpload = async (e) => {
    setFileError("");
    let files = [...e.target.files];
    const allowedFileTypes = ["doc", "pdf", "rtf", "docx"];
    const fileExtensions = files.map((file) =>
      file?.name?.split(".")?.pop()?.toLowerCase()
    );

    const invalidFileTypes = fileExtensions.filter(
      (ext) => !allowedFileTypes?.includes(ext)
    );

    if (files?.length + fileList?.length > 5) {
      setFileError("Maximum of 5 files are allowed.");
      return;
    }

    if (invalidFileTypes?.length > 0) {
      setFileError("Allowed file types: .doc, .docx, .rtf, .pdf");
      return;
    }
    if (files.find((file) => file.size > MAX_PDF_SIZE)) {
      setFileError("File Size is more then 10 MB");
      return;
    }
    setUploadLoading(true);

    const uploadedFileUrls = await uploadFiles(files);
    setFileList([...fileList, ...uploadedFileUrls]);
    setUploadLoading(false);
  };

  const uploadImageFileToS3 = async (fileObj, preSignedUrl, onSuccess) => {
    await uploadImage(fileObj, preSignedUrl).then((responseData) => {
      if (responseData.status === 200) {
        let imageUrl = preSignedUrl.split("?")[0];
        onSuccess(imageUrl);
        return imageUrl;
      }
    });
  };

  const uploadFiles = async (files, setIsLoading) => {
    const promises = [];
    let fileData = [];

    for (const fileObj of files) {
      let [fileName, fileExt] = fileObj.name.split(".");
      let filePath = fileName + "-" + Date.now() + "." + fileExt;

      promises.push(
        getPreSignedUrl(filePath)
          .then((responseData) => {
            if (responseData.data && responseData.data.success) {
              const preSignedUrl = responseData.data?.data?.signedUrl;
              return uploadImageFileToS3(fileObj, preSignedUrl, (url) => {
                fileData.push(url);
              });
            }
          })
          .catch((error) => {
            console.log("Error in Presigned Url API", error);
          })
      );
    }

    try {
      await Promise.allSettled(promises);
      // return promises.filter((promise) => promise !== undefined);
      return fileData;
    } catch (error) {
      console.log("Error in uploadFiles", error);
    } finally {
      // setUploadLoading(false);
    }
  };

  const onDeleteClickFile = async (index) => {
    const updatedList = fileList?.filter((x, i) => i !== index);
    setFileList(updatedList);
  };

  const handleSendInquiry = async () => {
    setLoading(true);
    if (repliesData) {
      let replayData = {
        tenantId: userInfo?.tenantId,
        inquiryType: repliesData?.inquiryType,
        message: inquiryMsg,
        spTenantId: repliesData?.spTenantId,
        scTenantId: userInfo?.tenantId,
        scUserId: userInfo?.id,
        spUserId: params?.rewardId,
        sourceId: params?.rewardId,
        parentId: repliesData?.id,
        previousReplyId: repliesData?.id,
        attachments: fileList,
        planId,
      };
      if (repliesData?.replies?.length > 0) {
        const prevId =
          repliesData?.replies[repliesData?.replies?.length - 1].id;
        replayData.previousReplyId = prevId;
      }
      const fetchedData = await sendInquiry(replayData);
      if (fetchedData && fetchedData?.data) {
        setInquiryMsg("");
        setFileError("");
        setFileList([]);
        setRepliesData(null);
        fetchInquiryList();
        setLoading(false);
        onClose();
      } else {
        setLoading(false);
      }
    } else {
      const requestObj = {
        tenantId: requestData?.spTenantId,
        message: inquiryMsg,
        inquiryType: "Deal",
        spTenantId: requestData?.spTenantId,
        scTenantId: requestData?.scTenantId,
        scUserId: userInfo?.id,
        spUserId: params?.rewardId,
        sourceId: params?.rewardId,
        parentId: null,
        previousReplyId: null,
        attachments: fileList,
        referenceId: requestData?.id,
        module: "Deal",
        dealReadableId: requestData?.dealReadableId,
        leadReadableId: requestData?.leadReadableId,
        planId,
      };

      const generalRequestObj = {
        tenantId: userInfo?.tenantId,
        message: inquiryMsg,
        inquiryType: inquiryType,
        spTenantId: requestData?.spTenantId,
        scTenantId: userInfo?.tenantId,
        scUserId: userInfo?.id,
        spUserId: params?.rewardId,
        sourceId: params?.rewardId,
        parentId: null,
        previousReplyId: null,
        attachments: fileList,
        planId,
      };

      const fetchedData = await sendInquiry(
        inquiryType ? generalRequestObj : requestObj
      );
      if (fetchedData && fetchedData?.data) {
        setInquiryMsg("");
        setFileError("");
        setFileList([]);
        fetchInquiryList();
        setLoading(false);
        onClose();
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      closeOnOverlayClick={false}
      onClose={() => {
        setInquiryMsg("");
        setFileError("");
        setFileList([]);
        onClose();
      }}
      size={"lg"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          fontSize={"14px"}
          fontWeight={700}
          color={"#111A29"}
          p={"16px 24px"}
          borderBottom={"1px solid #F3F7FF"}
        >
          {repliesData ? "Reply to Inquiry" : "Send Inquiry"}
        </ModalHeader>
        <ModalCloseButton top={"12px"} right={"16px"} />
        <ModalBody p={"24px"}>
          <TextArea
            required
            rows={4}
            name={"Inquiry"}
            placeholder={"write a message..."}
            maxLength={2000}
            value={inquiryMsg}
            onChange={(e) => setInquiryMsg(e.target.value)}
          />
          <Text fontSize={"12px"} fontWeight={600} color={"#5E6977"} my={"4px"}>
            Add Attachment
          </Text>
          <Box
            pos={"relative"}
            p="8px 16px"
            bg={"#F9FAFB"}
            border="1px solid #E6E7E9"
            borderRadius={"4px"}
            cursor={"pointer"}
            w={"max-content"}
          >
            <Flex
              color={"#5E6977"}
              css={{
                path: { stroke: "#7E8792" },
              }}
              justifyContent={"center"}
            >
              {uploadIcon}
              <Text ml="8px" fontSize={"14px"} lineHeight={"20px"}>
                Browse File
              </Text>
            </Flex>
            {!uploadLoading && (
              <Input
                type="file"
                accept=".doc,.docx,.rtf,.pdf"
                height="100%"
                width="100%"
                position="absolute"
                top="0"
                left="0"
                onChange={(e) => onFileUpload(e)}
                cursor={"pointer"}
                opacity="0 !important"
                multiple
              />
            )}
          </Box>
          <Text
            fontWeight={"500"}
            fontSize={"12px"}
            color={"#9EA5AD"}
            mt={"4px"}
          >
            Supported file types: .doc, .docx, .rtf, .pdf under 10MB
          </Text>
          {fileError && (
            <Flex alignItems={"center"} mt="8px">
              <Box boxSize={"16px"} mr="8px">
                {errorTriangle}
              </Box>
              <Text fontSize={"12px"} color={"#C81E1E"} fontWeight={"500"}>
                {fileError}
              </Text>
            </Flex>
          )}
          <Box className="pdf-file-list" mt="20px">
            {uploadLoading ? (
              <Flex gap={"8px"} alignItems={"center"}>
                <CellLoadingComponent color={"#0C94AC"} size={"sm"} />
                <Text color={"#556781"} fontSize={"12px"} fontWeight={500}>
                  Uploading file
                </Text>
              </Flex>
            ) : (
              <SimpleGrid columns={2} spacing={"16px"}>
                {fileList?.map((fileObj, i, allFiles) => {
                  let fileElement = (file) => {
                    const fileUrl = file?.split("?")[0];
                    const fileName = fileUrl?.split("/")?.pop();
                    const nameWithoutTimestamp = fileName.replace(/\-\d+/, "");

                    return (
                      <Flex
                        key={`${fileObj} - ${i}`}
                        alignItems={"center"}
                        padding={"8px 12px"}
                        border={"1px solid #E6E7E9"}
                        borderRadius={"8px"}
                        justifyContent={"space-between"}
                      >
                        <Flex alignItems={"center"}>
                          <Image src={FileIcon} boxSize={"40px"} />
                          <Box ml="12px">
                            <Text
                              fontWeight={"500"}
                              fontSize={"14px"}
                              // mb="4px"
                              color="#111A29"
                              css={{
                                "a,a:hover": {
                                  textDecoration: "none",
                                },
                              }}
                            >
                              <Link to={fileUrl} target="_blank">
                                <Tooltip label={nameWithoutTimestamp}>
                                  {nameWithoutTimestamp.length > 14
                                    ? nameWithoutTimestamp.substring(0, 14) +
                                      "..."
                                    : nameWithoutTimestamp}
                                </Tooltip>
                              </Link>
                            </Text>
                          </Box>
                        </Flex>
                        <Box>
                          <Image
                            onClick={() => onDeleteClickFile(i)}
                            cursor={"pointer"}
                            src={TrashIcon}
                            boxSize={"16px"}
                          />
                        </Box>
                      </Flex>
                    );
                  };
                  return <Box width={"100%"}>{fileElement(fileObj)} </Box>;
                })}
              </SimpleGrid>
            )}
          </Box>
        </ModalBody>

        <ModalFooter borderTop={"1px solid #F3F7FF"} p={"16px 24px"}>
          <Text
            color="#5E6977"
            fontSize={"14px"}
            fontWeight={600}
            mr={"20px"}
            p={"8px 16px"}
            opacity={uploadLoading ? "0.5" : "1"}
            onClick={() => {
              if (!uploadLoading) {
                setInquiryMsg("");
                setFileError("");
                setFileList([]);
                onClose();
              }
            }}
            cursor={uploadLoading ? "not-allowed" : "pointer"}
          >
            Cancel
          </Text>
          <Button
            border={"none"}
            bg={"#0C94AC"}
            color={"white"}
            w={"fit-content"}
            variant="newThemePrimary"
            rounded={"4px"}
            isLoading={uploadLoading || loading}
            isDisabled={uploadLoading || loading || !inquiryMsg}
            fontSize={"14px"}
            fontWeight={600}
            title={"Send"}
            onClick={handleSendInquiry}
            id="rewards-inquiry-send"
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SendInquiryModal;
