export const userRoleEnums = {
  manager: "Manager",
  owner: "Owner",
  admin: "Admin",
  user: "User",
};

export const usersRolesList = [
  userRoleEnums.owner,
  userRoleEnums.admin,
  userRoleEnums.manager,
  userRoleEnums.user,
];

export const permissionTypes = {
  add: "add",
  edit: "edit",
  view: "view",
  delete: "delete",
  assigned: "assigned",
  export: "export",
};

export const invitationStatus = [
  { value: "all", label: "All Status" },
  { value: "Sent", label: "Pending" },
  { value: "Active", label: "Active" },
  { value: "Expired", label: "Expired" },
];
