import React from "react";
import {
  PinInput,
  PinInputField,
  HStack,
  FormErrorMessage,
  FormControl,
  FormHelperText,
  Stack,
} from "@chakra-ui/react";

const OtpVerification = ({ validationErrors, innerref, ...props }) => {
  const validation = validationErrors && validationErrors["otp"];
  return (
    <Stack spacing={0}>
      <HStack>
        <PinInput placeholder="-" isInvalid={validation} otp {...props}>
          <PinInputField {...innerref} />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
          <PinInputField />
        </PinInput>
      </HStack>
      <FormControl
        isInvalid={validation}
        isRequired
        name={props.name || props.placeholder}
      >
        {!validation ? (
          <FormHelperText>&nbsp;</FormHelperText>
        ) : (
          <FormErrorMessage>
            {validationErrors["otp"]["message"]}
          </FormErrorMessage>
        )}
      </FormControl>
    </Stack>
  );
};

export default OtpVerification;
