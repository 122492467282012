import React, { useEffect, useState } from "react";
import {
  Box,
  Spinner,
  Stack,
  Switch,
  TableContainer,
  Text,
} from "@chakra-ui/react";
import { scrollbarCSS } from "@coachbar/shared-components/src/constants/constantValues";
import { Table } from "@coachbar/shared-components";
import { updatePersonalNotificationStatus } from "./personalNotificationActions";

const tableHead = [["", "In-App", "Email"]];

const NotificationTable = ({ cardTitle, moduleNotifications }) => {
  const [tableBody, setTableBody] = useState([]);

  useEffect(() => {
    if (moduleNotifications && moduleNotifications.length > 0) {
      const notificationList = [];
      for (let index = 0; index < moduleNotifications.length; index++) {
        const notificationItem = [];
        notificationItem.push(
          <NotificationInfo
            title={moduleNotifications[index]["eventName"]}
            subtitle={moduleNotifications[index]["description"]}
          />
        );
        notificationItem.push(
          <NotificationSwitch
            isActive={moduleNotifications[index].isEnableInternal}
            notificationId={moduleNotifications[index].id}
            isDisabled={!moduleNotifications[index].adminEnableInternal}
            statusType={"internalStatus"}
          />
        );
        notificationItem.push(
          <NotificationSwitch
            isActive={moduleNotifications[index].isEnableEmail}
            notificationId={moduleNotifications[index].id}
            isDisabled={!moduleNotifications[index].adminEnableEmail}
            statusType={"emailStatus"}
          />
        );
        notificationList.push(notificationItem);
      }
      setTableBody([...notificationList]);
    }
  }, [moduleNotifications]);

  return (
    tableBody &&
    tableBody.length > 0 && (
      <CardBlock title={cardTitle}>
        <Table
          tHead={tableHead}
          tBody={tableBody}
          keepBordersRounded
          headerTextStyle={{
            color: "#5E6977",
            backgroundColor: "#F9FAFB",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 600,
          }}
        />
      </CardBlock>
    )
  );
};

const CardBlock = ({ title, children }) => (
  <Stack
    p={"1rem"}
    gap={1}
    bg={"#fff"}
    border={"1px solid #E6E7E9"}
    borderRadius={"4px"}
  >
    <Text color={"#111A29"} fontSize={"14px"} fontWeight={600}>
      {title}
    </Text>
    <TableContainer overflow={"auto"} css={scrollbarCSS}>
      {children}
    </TableContainer>
  </Stack>
);

const NotificationInfo = ({ title, subtitle }) => (
  <Box fontSize={"14px"} style={{ width: 650 }}>
    <Text color={"#111A29"} fontWeight={600}>
      {title}
    </Text>
    <Text color={"#7E8792"} fontWeight={450}>
      {subtitle}
    </Text>
  </Box>
);

const NotificationSwitch = ({
  isActive,
  isDisabled,
  notificationId,
  statusType,
}) => {
  const [isSwitchActive, setIsSwitchActive] = useState(isActive);
  const [isLoading, setIsLoading] = useState(false);
  const handleStateChange = async () => {
    setIsLoading(true);
    const isSuccess = await updatePersonalNotificationStatus(
      notificationId,
      !isSwitchActive,
      statusType
    );
    isSuccess && setIsSwitchActive((bool) => !bool);
    setIsLoading(false);
  };
  return (
    <Box pos={"relative"} display={"inline-block"}>
      <Switch
        size="md"
        colorScheme="brand"
        isChecked={isSwitchActive}
        onChange={handleStateChange}
        isFocusable={false}
        isDisabled={isLoading || isDisabled}
      />
      {isLoading && (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="brand.100"
          size={"xs"}
          position={"absolute"}
          right={"-16px"}
          top={"4px"}
        />
      )}
    </Box>
  );
};

export default NotificationTable;
