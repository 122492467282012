import imageIcon from "../images/image-file-icon.svg";
import presentationIcon from "../images/ppt-file-icon.svg";
import presentationKeyIcon from "../images/key-file-icon.svg";
import videoIcon from "../images/video-file-icon.svg";
import documentWordIcon from "../images/doc-file-icon.svg";
import documentPdfIcon from "../images/pdf-file-icon.svg";
import documentExcelIcon from "../images/excel-file-icon.svg";
import documentTextIcon from "../images/txt-file-icon.svg";
import documentCsvIcon from "../images/csv-file-icon.svg";
import documentRtfIcon from "../images/rtf-file-icon.svg";
import documentHtmlIcon from "../images/html-file-icon.svg";
import designPsdIcon from "../images/psd-file-icon.svg";
import designAiIcon from "../images/ai-file-icon.svg";
import zipIcon from "../images/zip-file-icon.svg";

export const assetErrorMsg = "Add external link or Upload file";

export const invalidaFileTypes = [
  "ACTION",
  "APK",
  "APP",
  "BAT",
  "BIN",
  "CAB",
  "CMD",
  "COM",
  "COMMAND",
  "CPL",
  "CSH",
  "EX_",
  "EXE",
  "GADGET",
  "INF¹",
  "INS",
  "INX",
  "IPA",
  "ISU",
  "JOB",
  "JSE",
  "KSH",
  "LNK",
  "MSC",
  "MSI",
  "MSP",
  "MST",
  "OSX",
  "OUT",
  "PAF",
  "PIF",
  "PRG",
  "PS1",
  "REG",
  "RGS",
  "RUN",
  "SCR",
  "SCT",
  "SHB",
  "SHS",
  "U3P",
  "VB",
  "VBE",
  "VBS",
  "VBSCRIPT",
  "WORKFLOW",
  "WS",
  "WSF",
  "WSH",
];

export const extensionsToIcons = {
  IMAGE: {
    JPG: imageIcon,
    JPEG: imageIcon,
    PNG: imageIcon,
    GIF: imageIcon,
    TIFF: imageIcon,
    BMP: imageIcon,
    SVG: imageIcon,
  },
  VIDEO: {
    MP4: videoIcon,
    AVI: videoIcon,
    MOV: videoIcon,
    WMV: videoIcon,
    FLV: videoIcon,
    MKV: videoIcon,
    MPEG: videoIcon,
    SWF: videoIcon,
    WAV: videoIcon,
    MP3: videoIcon,
  },
  DOCUMENT: {
    DOCX: documentWordIcon,
    PDF: documentPdfIcon,
    XLSX: documentExcelIcon,
    TXT: documentTextIcon,
    CSV: documentCsvIcon,
    RTF: documentRtfIcon,
    HTML: documentHtmlIcon,
    ZIP: zipIcon,
  },
  DESIGN: {
    PSD: designPsdIcon,
    AI: designAiIcon,
  },
  PRESENTATION: {
    PPTX: presentationIcon,
    KEY: presentationKeyIcon,
  },
};

export const assetsColumnOrder = "assetsColumnOrder";
export const assetsVisibleColumns = "assetsVisibleColumns";
