import { useState, useEffect } from "react";
import { Stack, HStack, Text, Box } from "@chakra-ui/react";
import Select from "react-select";
import NoData from "../../../components/NoData";
import { Link, Loader, Table } from "@coachbar/shared-components";
import { getIntegrationLogs } from "../integrationAction";
import { referralDetailsRoute } from "../../../constants/redirectionRoutes";
import SearchBar from "@coachbar/shared-components/src/components/SearchBar/SearchBar";
import DateFilter from "@coachbar/shared-components/src/components/DateFilter/DateFilter";
const tableHead = [
  [
    "Date",
    "Sync Status",
    "Referral Link",
    "Referral Email",
    "Referral Name",
    "Logs Message",
  ],
];

const limit = 5;
const statusOption = [
  { value: "AllStatus", label: "All statuses" },
  { value: "success", label: "Success" },
  { value: "error", label: "Error" },
];
const HubSpotLogs = () => {
  const defaultDateRange = {
    to: "",
    from: "",
  };

  const [filterDateRange, setFilterDateRange] = useState(defaultDateRange);
  const [activityLog, setActivityLog] = useState([]);
  const [filterResetBackup, setFilterResetBackup] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedOption, setSelectedOption] = useState(statusOption[0]);
  const [searchInput, setSearchInput] = useState("");
  const [tableBody, setTableBody] = useState([]);

  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      let requestData = {
        limit,
        startDate: filterDateRange.from,
        endDate: filterDateRange.to,
        channelId: 1,
      };
      if (selectedOption && selectedOption.value !== "AllStatus") {
        requestData.status = selectedOption.value;
      }
      setLoading(true);
      setActivityLog([]);
      setFilterResetBackup([]);
      getActivityLog(requestData);
    }
  }, [selectedOption, filterDateRange]);

  const getActivityLog = async (requestParam) => {
    const response = await getIntegrationLogs(requestParam);
    if (response && response.success) {
      if (response?.data?.logs?.hubspotContactList) {
        setActivityLog((prevData) => {
          let updatedLogs = [
            ...prevData,
            ...response.data.logs.hubspotContactList,
          ];
          if (searchInput) {
            return updatedLogs?.filter((item) =>
              Object.values(item).some((val) =>
                val
                  ?.toString()
                  ?.toLowerCase()
                  ?.includes(searchInput?.toLowerCase())
              )
            );
          } else {
            return updatedLogs;
          }
        });
        setFilterResetBackup((prevData) => [
          ...prevData,
          ...response.data.logs.hubspotContactList,
        ]);
        setNextToken(response.data.logs.nextToken);
        setLoading(false);
      }
    } else {
      setError(
        response.message ? response.message : "Oops, an Issue Occurred!"
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    let tBody = [];
    for (let index = 0; index < activityLog.length; index++) {
      let logs = [];
      logs.push(activityLog[index]["createdDate"]);
      logs.push(activityLog[index]["status"]);
      logs.push(
        activityLog[index]["referralId"] ? (
          <Link
            color={"brand.50"}
            to={`${referralDetailsRoute}/${activityLog[index]["referralId"]}`}
          >
            View Referral
          </Link>
        ) : (
          ""
        )
      );
      logs.push(activityLog[index]["email"] ? activityLog[index]["email"] : "");
      logs.push(
        activityLog[index]["companyName"]
          ? activityLog[index]["companyName"]
          : ""
      );
      logs.push(activityLog[index]["message"]);
      tBody.push(logs);
    }
    setTableBody([...tBody]);
  }, [activityLog]);

  const handleSearchInput = (event) => {
    const inputValue = event?.target?.value;
    if (inputValue) {
      const filteredData = filterResetBackup.filter((item) =>
        Object.values(item).some((val) =>
          val?.toString()?.toLowerCase()?.includes(inputValue?.toLowerCase())
        )
      );
      setActivityLog(filteredData);
    } else {
      setActivityLog(filterResetBackup);
    }
    setSearchInput(inputValue);
  };

  const handleDateChange = (range) => {
    setFilterDateRange(range);
  };

  const handleLoadMore = () => {
    let requestData = {
      limit,
      startDate: filterDateRange.from,
      endDate: filterDateRange.to,
      channelId: 1,
      nextToken,
    };
    if (selectedOption && selectedOption.value !== "AllStatus") {
      requestData.status = selectedOption.value;
    }
    getActivityLog(requestData);
  };

  return (
    <Stack spacing={2} m="5" align="stretch">
      <HStack justifyContent={"space-between"} mb={"15px !important"}>
        <Text fontSize={18} color={"brand.100"} fontWeight={600}>
          Activity Logs
        </Text>
        <HStack>
          <Box minW={"160px"}>
            <DateFilter
              handleDateChange={handleDateChange}
              selectedDateRange={defaultDateRange}
              moduleForId="hubspot-logs"
              height="36px"
              showByDefaultAllTimeOption
            />
          </Box>
          <Box minW={"160px"}>
            <Select
              options={statusOption}
              value={selectedOption}
              onChange={setSelectedOption}
              defaultValue={"AllStatus"}
              placeholder="All Statuses"
              isSearchable={true}
            />
          </Box>

          <SearchBar
            value={searchInput}
            onChange={(value) => {
              setSearchInput(value);
            }}
            onSearchChangeOperations={handleSearchInput}
            placeholder="Search"
          />
        </HStack>
      </HStack>
      <Stack border={"1px solid #E2E8F0"} borderRadius={"6px"}>
        {loading ? (
          <Loader />
        ) : activityLog?.length > 0 ? (
          <>
            <Table tHead={tableHead} tBody={tableBody} />
            {nextToken && (
              <Text
                m={"10px auto !important"}
                cursor={"pointer"}
                color={"brand.50"}
                onClick={() => handleLoadMore()}
              >
                Load More Logs
              </Text>
            )}{" "}
          </>
        ) : (
          <NoData description={"No Activity Logs found."} />
        )}
      </Stack>
    </Stack>
  );
};

export default HubSpotLogs;
