import React, { useEffect, useMemo, useState } from "react";
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  HStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Divider,
  Image,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  Stack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Portal,
  Spacer,
  StackDivider,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { ExternalLinkIcon, HamburgerIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import logo from "../image/logo.svg";
import smallLogo from "../image/small-logo.svg";
import { getCompanyProfile } from "../features/profileSetup/companyProfile/companyProfileActions";
import { Button } from "@coachbar/shared-components";
import { planRoute } from "../constants/redirectionRoutes";
import { getProfileCompletionStatus } from "../features/profileSetup/profileMetrics/profileMetricsAction";
import { getAllSoftwareList } from "../features/softwareSpecialisation/softwareSpecialisationActions";
import { Scrollbars } from "react-custom-scrollbars-2";
import { getUserInfo } from "../services/utility";
import { useRBAC } from "../services/customHook";
import {
  coachbarServicesModule,
  subscriptionModule,
} from "../constants/moduleNames";
import CloseSidebarIcon from "../image/close-sidebar.svg";
import OpenSidebarIcon from "../image/open-sidebar.svg";
import CloseNavSublist from "../image/close-nav-sublist.svg";
import BreadCrumb from "./BreadCrumb";
import NotificationPopover from "@coachbar/shared-components/src/components/Notifications/NotificationPopover";
import { ProfileMenu } from "@coachbar/shared-components/src/components/ProfileMenu/ProfileMenu";
import { ReactComponent as Bell03 } from "../image/bell-03.svg";
import { ReactComponent as HelpCircle } from "../image/help-circle.svg";
import { ReactComponent as LogOut01 } from "../image/log-out-01.svg";
import { ReactComponent as PhoneCall01 } from "../image/phone-call-01.svg";
import { ReactComponent as User01 } from "../image/user-01.svg";
import { ReactComponent as LightBulb03 } from "@coachbar/shared-components/src/images/lightbulb-03.svg";
import {
  getNotificationCountForBell,
  getNotificationListForBell,
  markAsReadBellNotification,
} from "../features/personalNotifications/personalNotificationActions";
// import { socket } from "../socket";
import useWebSocket from "../useWebSocket";

let LinkItems = [];
const activeStyle = {
  backgroundColor: "#075967",
  borderColor: "#0A768A",
  borderRadius: "7px",
  borderWidth: "1px",
};

const NOTIFICATION_LIST_COUNT = 50;

const EXCLUDED_PATH_FOR_AB = [
  "/my-providers",
  "/leads",
  "/deals",
  "/lms/assets",
  "/incentives",
  "/settings/general",
  "/settings/integration/hubspot",
  "/profile-setup/system-grid",
  "/settings/custom-forms",
  "/settings/notifications",
];

const setLinkItems = (routes, hasViewPermission, userInfo) => {
  LinkItems = [];
  for (let index = 0; index < routes.length; index++) {
    let route = routes[index];
    if ((route.path && route.menuItem) || route.directLink) {
      let addItem = true;
      addItem = hasViewPermission(route.moduleName);
      if (
        EXCLUDED_PATH_FOR_AB.includes(route.path) &&
        [3].includes(userInfo?.userType)
      ) {
        addItem = false;
      }
      if (addItem) {
        LinkItems.push({
          name: route.name,
          icon: route.icon,
          path: route.path,
          directLink: route.directLink || false,
          subMenu: route.subMenuItem ? route.subMenuItem : null,
          moduleName: route.moduleName,
        });
      }
    }
  }
};

const getSubMenuCount = (subMenuList, userInfo, hasViewPermission) => {
  let count = 0;
  subMenuList.forEach((link) => {
    let showLink = true;
    showLink = link.moduleName ? hasViewPermission(link.moduleName) : true;
    if (userInfo?.userType === 3 && EXCLUDED_PATH_FOR_AB.includes(link.path)) {
      showLink = false;
    }
    if (showLink) count++;
  });
  return count;
};

export default function SidebarWithHeader({ routes, children }) {
  const dispatch = useDispatch();
  let location = useLocation();
  let initialize = false;
  const [sidebarSize, setSidebarSize] = useState("large");

  let userInfo = getUserInfo();
  useEffect(() => {
    if (!initialize) {
      initialize = true;
      if (!["profile-setup/company-profile"].includes(location?.pathname)) {
        dispatch(getCompanyProfile());
      }
      dispatch(getProfileCompletionStatus());
      if (
        ![
          "/profile-setup/specialisation",
          "/profile-setup/system-grid",
          "/profile-setup/icp/add",
        ].includes(location?.pathname)
      ) {
        dispatch(getAllSoftwareList());
      }
    }
  }, []);

  const { hasViewPermission } = useRBAC();
  setLinkItems(routes, hasViewPermission, userInfo);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      minH="100vh"
      bg={useColorModeValue("var(--bg-theme-primary)", "gray.900")}
    >
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
        sidebarSize={sidebarSize}
        setSidebarSize={setSidebarSize}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <MobileNav
        sidebarSize={sidebarSize}
        setSidebarSize={setSidebarSize}
        onOpen={onOpen}
        routes={routes}
      />
      <Box
        transition="0.5s ease"
        ml={sidebarSize === "small" ? "69px" : { base: "0", md: "229px" }}
      >
        {children}
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, ...rest }) => {
  let location = useLocation();
  const { companyProfile } = useSelector((state) => state.companyProfile);
  const { profileCompletionData } = useSelector(
    (state) => state.profileMetrics
  );
  let userInfo = getUserInfo();
  const checkSubmenu = (subMenuList) => {
    let isSubMenuActive = false;
    if (subMenuList) {
      for (let index = 0; index < subMenuList.length; index++) {
        let path = subMenuList[index]["path"];
        if (location.pathname.indexOf(path) !== -1) {
          isSubMenuActive = true;
          break;
        }
      }
    }
    return isSubMenuActive;
  };

  let accordionIndex = 0;
  for (let itemIndex = 0; itemIndex < LinkItems.length; itemIndex++) {
    if (LinkItems[itemIndex].path) {
      let spliPath = LinkItems[itemIndex].path.split("/");
      let intitialPath = LinkItems[itemIndex].path;
      if (spliPath.length > 1) {
        intitialPath = "/" + spliPath[1];
      }
      let isActivePath =
        location.pathname.indexOf(intitialPath) !== -1 ? true : false;
      if (isActivePath) {
        accordionIndex = itemIndex;
        break;
      }
    }
  }

  const { hasViewPermission } = useRBAC();
  return (
    <Box
      transition="0.5s ease"
      bg={"#162336"}
      w={rest.sidebarSize === "small" ? "68px" : "228px"}
      pos="fixed"
      zIndex={999}
      h="full"
      {...rest}
    >
      <span
        onClick={() => {
          rest.setSidebarSize(rest.sidebarSize === "small" ? "large" : "small");
        }}
        style={{
          minWidth: "24px",
          position: "absolute",
          right: "-11px",
          top: "22px",
          cursor: "pointer",
          zIndex: 10,
        }}
        id="sidebar-drawer-open-close"
      >
        <Image
          src={
            rest.sidebarSize === "small" ? OpenSidebarIcon : CloseSidebarIcon
          }
        />
      </span>

      <Scrollbars
        autoHide
        renderThumbVertical={({ style, ...props }) => (
          <div
            {...props}
            style={{
              ...style,
              background: "rgba(256, 256, 256, .2)",
            }}
          />
        )}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          position={"relative"}
        >
          <Image
            style={
              rest.sidebarSize === "small"
                ? {
                    paddingTop: "16px",
                    paddingLeft: "16px",
                    paddingBottom: "16px",
                  }
                : {
                    paddingLeft: "32px",
                    height: "80px",
                    paddingRight: "2.3rem",
                  }
            }
            src={rest.sidebarSize === "small" ? smallLogo : logo}
            alt="Coachbar Logo"
          ></Image>
          <CloseButton
            display={{ base: "flex", md: "none" }}
            color="#fff"
            onClick={onClose}
          />
        </Flex>

        <Accordion
          // index={accordionIndex}
          allowMultiple={true}
          defaultIndex={rest.sidebarSize === "small" ? [] : [accordionIndex]}
        >
          {LinkItems.map((link, index) => {
            let isActive =
              location.pathname.indexOf(link.path) !== -1 ||
              checkSubmenu(link?.subMenu)
                ? true
                : false;
            let showMenu = link.moduleName
              ? hasViewPermission(link.moduleName)
              : true;

            if (showMenu)
              return (
                <AccordionItem
                  key={link.name}
                  borderTopWidth="0px"
                  borderBottomWidth="0px !important"
                  isDisabled={
                    link.name === "Directory" &&
                    (!companyProfile ||
                      (companyProfile &&
                        companyProfile.profileStatus !== "Published"))
                      ? true
                      : false
                  }
                  mb={LinkItems.length === index + 1 ? "2rem" : 0}
                >
                  <NavItem
                    icon={link.icon}
                    path={
                      link.name === "Directory"
                        ? process.env.REACT_APP_PARTNER_PAGE_URL
                        : link.path === "/profile-setup" &&
                          userInfo?.userType === 3
                        ? "/profile-setup/company-profile"
                        : link.path
                    }
                    subMenuList={link.subMenu}
                    isActive={isActive}
                    isDirectLink={link.directLink}
                    name={link.name}
                    sidebarSize={rest.sidebarSize}
                  >
                    {link.name === "Directory" ? (
                      <Box alignItems={"flex-start"}>
                        <Text>
                          {link.name}&nbsp;
                          <ExternalLinkIcon />
                        </Text>
                        {!companyProfile ||
                        (companyProfile &&
                          companyProfile.profileStatus !== "Published") ? (
                          <Text fontSize={"12px"}>Under Approval</Text>
                        ) : (
                          ""
                        )}{" "}
                      </Box>
                    ) : link.name === "Profile Setup" &&
                      profileCompletionData?.totalPercentage !== 100 ? (
                      <Flex
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        w={"full"}
                      >
                        {link.name}
                        {/* <Text
                          rounded={"50px"}
                          p={"4px"}
                          ml={1}
                          bg={isActive ? "#04FCC4" : "#029776"}
                          color={isActive ? "#02654E" : "#E6FFF9"}
                          fontSize={"12px"}
                          fontWeight={"500"}
                          lineHeight={1}
                        >
                          {isNaN(profileCompletionData?.totalPercentage)
                            ? "0%"
                            : parseInt(profileCompletionData?.totalPercentage) +
                              "%"}
                        </Text> */}
                      </Flex>
                    ) : (
                      link.name
                    )}
                  </NavItem>
                </AccordionItem>
              );
          })}
        </Accordion>
      </Scrollbars>
    </Box>
  );
};

const NavItem = ({
  icon,
  path,
  children,
  subMenuList,
  isSubmenu,
  isActive,
  isDirectLink,
  name,
  ...rest
}) => {
  const { userInfo } = useSelector((state) => state.auth);
  let activeColorCode = useColorModeValue("brand.50", "brand.50");
  const { companyProfile } = useSelector((state) => state.companyProfile);
  let location = useLocation();
  const { hasViewPermission } = useRBAC();

  let subMenuComponent = subMenuList ? (
    <HStack
      py={rest.sidebarSize === "small" ? 2 : 0}
      px={rest.sidebarSize === "small" ? 2 : 5}
    >
      {rest.sidebarSize !== "small" && (
        <Divider
          orientation="vertical"
          height={`${
            42 * getSubMenuCount(subMenuList, userInfo, hasViewPermission)
          }px`}
          borderWidth={"2px"}
          borderStyle={"solid"}
          position={"relative"}
          left={"8px"}
          marginRight={"10px"}
          borderRadius={"5px"}
          borderColor={"#075967"}
        />
      )}
      <Stack width={"100%"}>
        <SubNavList
          name={name}
          subMenuList={subMenuList}
          userInfo={userInfo}
          sidebarSize={rest.sidebarSize}
        />
      </Stack>
    </HStack>
  ) : (
    rest.sidebarSize === "small" && (
      <>
        <Box
          align={"center"}
          p={"8px"}
          role="group"
          cursor="pointer"
          width={"inherit"}
          color={"white"}
          _hover={activeStyle}
          style={
            isActive
              ? activeStyle
              : {
                  borderWidth: "1px",
                  borderColor: "transparent",
                  color: "white",
                }
          }
        >
          <Link
            as={RouterLink}
            to={path}
            style={{ textDecoration: "none" }}
            _focus={{ boxShadow: "none" }}
            id={`sidebar-tab-${
              typeof name === "string"
                ? name?.split(" ").join("-").toLowerCase()
                : ""
            }`}
          >
            {children}
          </Link>
        </Box>
      </>
    )
  );

  return (
    <div>
      <Popover offset={[0, 20]} trigger="hover" placement="right">
        <Stack p={"5px"} pr="12px" pl="10px" pos={"relative"}>
          <Link
            as={RouterLink}
            to={path}
            style={{ textDecoration: "none" }}
            _focus={{ boxShadow: "none" }}
            target={isDirectLink ? "_blank" : "_self"}
            pointerEvents={
              name === "Directory" &&
              (!companyProfile ||
                (companyProfile &&
                  companyProfile.profileStatus !== "Published"))
                ? "none"
                : "unset"
            }
            id={`sidebar-tab-${
              typeof name === "string"
                ? name?.split(" ").join("-").toLowerCase()
                : ""
            }`}
          >
            <AccordionButton p="0" aria-expanded={true}>
              <PopoverTrigger>
                <Flex
                  align={"center"}
                  justifyContent={rest.sidebarSize === "small" && "center"}
                  p={"8px"}
                  role="group"
                  cursor="pointer"
                  width={"inherit"}
                  color={"white"}
                  fontSize={"14px"}
                  fontWeight={600}
                  lineHeight={"20px"}
                  _hover={activeStyle}
                  style={
                    isActive &&
                    ((subMenuList &&
                      location.pathname === path &&
                      !subMenuList.find((x) => x.path === path)) ||
                      !subMenuList ||
                      rest.sidebarSize === "small")
                      ? activeStyle
                      : {
                          borderWidth: "1px",
                          borderColor: "transparent",
                          color: "white",
                        }
                  }
                  {...rest}
                >
                  <>
                    {icon && (
                      <Icon
                        fontSize="24px"
                        _groupHover={{
                          color: "brandGray.100",
                        }}
                        as={icon}
                      />
                    )}

                    {rest.sidebarSize === "large" && (
                      <span style={{ marginLeft: "5px" }}>{children}</span>
                    )}
                  </>
                  {subMenuList &&
                    (rest.sidebarSize === "large" ? (
                      <AccordionIcon
                        pos={"absolute"}
                        right={"25px"}
                        top={"18px"}
                      />
                    ) : (
                      <Image
                        pos={"absolute"}
                        right={"-1px"}
                        src={CloseNavSublist}
                      />
                    ))}
                </Flex>
              </PopoverTrigger>
            </AccordionButton>
          </Link>
        </Stack>
        {rest.sidebarSize === "small" ? (
          <Portal>
            <PopoverContent
              bg="#162336"
              borderColor="#0A768A"
              width={"max-content"}
            >
              {subMenuComponent}
            </PopoverContent>
          </Portal>
        ) : (
          <AccordionPanel p="0">{subMenuComponent}</AccordionPanel>
        )}
      </Popover>
    </div>
  );
};

const SubNavList = ({ name, subMenuList, userInfo, ...rest }) => {
  let location = useLocation();
  // const { profileCompletionData } = useSelector(
  //   (state) => state.profileMetrics
  // );

  // const getProfileMetricsData = (title) => {
  //   if (title === "Company Profile") {
  //     return parseInt(
  //       (profileCompletionData?.companyProfilePercentage * 100) /
  //         (userInfo?.userType === 1 ? 20 : 25)
  //     );
  //   } else if (title === "System Grid") {
  //     return parseInt((profileCompletionData?.systemGrid * 100) / 30);
  //   } else if (title === "Clients") {
  //     return parseInt((profileCompletionData?.clients * 100) / 10);
  //   } else if (title === "Services") {
  //     return parseInt((profileCompletionData?.servicePercentage * 100) / 10);
  //   } else if (title === "Specialization") {
  //     return parseInt((profileCompletionData?.specialisation * 100) / 10);
  //   } else if (title === "ICP") {
  //     return parseInt((profileCompletionData?.icp * 100) / 20);
  //   }
  // };
  const { hasViewPermission } = useRBAC();
  return subMenuList.map((link) => {
    let isSubMenuActive =
      location.pathname.indexOf(link.path) !== -1 ? true : false;
    let isLinkProtected = link.isProtected;
    let showLink = true;
    showLink = link.moduleName ? hasViewPermission(link.moduleName) : true;
    if (userInfo?.userType === 3 && EXCLUDED_PATH_FOR_AB.includes(link.path)) {
      showLink = false;
    }
    if (showLink)
      return !isLinkProtected ? (
        <Link
          as={RouterLink}
          to={link.path}
          style={{ textDecoration: "none" }}
          _focus={{ boxShadow: "none" }}
          key={link.name}
          id={`sidebar-tab-${
            typeof link?.name === "string"
              ? link?.name?.split(" ").join("-").toLowerCase()
              : ""
          }`}
        >
          <Flex
            ml={rest.sidebarSize === "small" ? "0" : "5px"}
            align="center"
            p={"8px"}
            fontSize={"14px"}
            fontWeight={600}
            role="group"
            cursor="pointer"
            bg={isSubMenuActive ? "#075967" : "none"}
            borderRadius={"7px"}
            color={"#fff"}
            border={`1px solid ${isSubMenuActive ? "#0A768A" : "transparent"}`}
            _hover={activeStyle}
            justifyContent={"space-between"}
          >
            {link.name}
            {/* {name === "Profile Setup" &&
              getProfileMetricsData(link?.name) !== 100 && (
                <Text
                  rounded={"50px"}
                  p={"4px"}
                  ml={1}
                  bg={isSubMenuActive ? "#04FCC4" : "#029776"}
                  color={isSubMenuActive ? "#02654E" : "#E6FFF9"}
                  fontSize={"12px"}
                  fontWeight={"500"}
                  lineHeight={1}
                >
                  {isNaN(getProfileMetricsData(link.name))
                    ? "0%"
                    : getProfileMetricsData(link.name) + "%"}
                </Text>
              )} */}
          </Flex>
        </Link>
      ) : null;
  });
};

const MobileNav = ({ onOpen, ...rest }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.auth);
  const { connectSocket, disConnectSocket, socket } = useWebSocket();
  const [notifications, setNotifications] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [count, setCount] = useState(0);

  const handleHelpClick = () => {
    window.open("https://support.coachbar.io/support/home", "_blank");
  };

  const onLogOut = () => {
    disConnectSocket();
    dispatch(logout());
    navigate("/login");
  };

  const handleSupportClick = () => {};

  const redirectToPlan = () => {
    navigate(planRoute);
  };

  const { hasViewPermission } = useRBAC();

  const profileMenuItems = useMemo(() => {
    const profileItemList = [
      {
        type: "tab",
        title: "My Account",
        Icon: User01,
        onClick: () => navigate("/my-account"),
      },
      {
        type: "tab",
        title: "Notification",
        Icon: Bell03,
        onClick: () => navigate("/personal-notifications"),
      },
      {
        type: "tab",
        title: "Help Center",
        Icon: HelpCircle,
        onClick: handleHelpClick,
      },
      {
        type: "tab",
        title: <a href="mailto:support@coachbar.io">Contact Support</a>,
        Icon: PhoneCall01,
        onClick: () => {},
      },
      { type: "divider" },
      {
        type: "tab",
        title: "Sign Out",
        Icon: LogOut01,
        onClick: onLogOut,
      },
    ];
    if (userInfo?.userType === 3) profileItemList.splice(1, 1);
    return profileItemList;
  }, []);

  const getNotificationCount = async () => {
    const result = await getNotificationCountForBell(userInfo?.userType);
    if (result?.code === 200) {
      setCount(result.data?.notificationCount?.count ?? 0);
    }
  };

  const getNotifications = async () => {
    const result = await getNotificationListForBell(
      NOTIFICATION_LIST_COUNT,
      nextToken,
      userInfo?.userType
    );
    if (result.code === 200 && result.data?.notification?.notificationList) {
      setNotifications([
        ...notifications,
        ...result.data?.notification?.notificationList,
      ]);
      setNextToken(result.data?.notification?.nextToken ?? null);
    }
  };

  const getNotificationList = () => {
    getNotificationCount();
    getNotifications();
  };

  let initialize = false;
  useEffect(() => {
    if (!initialize) {
      initialize = true;
      getNotificationList();
    }
  }, []);

  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      connectSocket();
    }
    return () => disConnectSocket();
  }, []);

  useEffect(() => {
    socket?.addEventListener("message", (event) => {
      const socketResponse = JSON.parse(event.data);
      if (socketResponse && socketResponse.userId === userInfo.id) {
        setNotifications((prevNotifications) => [
          socketResponse,
          ...prevNotifications,
        ]);
        setCount(socketResponse.count);
      }
    });
  }, [socket, userInfo.id]);

  const handleMarkAsRead = (id = null) => {
    markAsReadBellNotification(id ? [id] : []);
    const updatedNotifications = notifications.map((n) =>
      !id || n.id === id ? { ...n, isNotificationRead: true } : n
    );
    setNotifications(updatedNotifications);
    setCount(id ? (prevCount) => prevCount - 1 : 0);
  };

  return (
    <Flex
      ml={rest.sidebarSize === "small" ? "69px" : { base: "0", md: "229px" }}
      px={{ base: 4, md: 4 }}
      height="16"
      alignItems="center"
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("cyprus.50", "gray.700")}
      bg="var(--chakra-colors-white)"
      justifyContent={{ base: "space-between", md: "flex-end" }}
      boxShadow="0px 1px 4px 0px #E5E7EB"
      {...rest}
      gap="24px"
      zIndex={99}
      pos={"relative"}
    >
      {/* <Spacer /> */}
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<HamburgerIcon />}
        id="sidebar-hamburger-open-menu"
      />
      <Image
        display={{ base: "flex", md: "none" }}
        bg={"brand.100"}
        src={logo}
        alt="Coachbar Logo"
      ></Image>
      <BreadCrumb routes={rest.routes} />
      <Spacer />
      <HStack
        gap={"24px"}
        divider={
          <StackDivider
            border="2px solid var(--chakra-colors-cyprus-100)"
            rounded={"3px"}
            mx="0 !important"
            my="8px !important"
          />
        }
      >
        {userInfo &&
        userInfo?.planId &&
        hasViewPermission(coachbarServicesModule) ? (
          <Box mr={"auto"} display={{ base: "none", md: "block" }}>
            <Flex alignItems={"center"}>
              Current plan : {userInfo?.subscriptionStore?.planName}
              {userInfo?.planId !== 5 &&
              hasViewPermission(subscriptionModule) &&
              userInfo?.planId < 10 ? (
                <Button
                  title="Upgrade Now"
                  border={"none"}
                  bg={"#0C94AC"}
                  color={"white"}
                  w={"fit-content"}
                  variant="newThemePrimary"
                  rounded={"8px"}
                  onClick={redirectToPlan}
                  ml="1rem"
                  id="upgrade-plan-link-redirect"
                />
              ) : (
                ""
              )}
            </Flex>
          </Box>
        ) : (
          ""
        )}
        <Box
          as="button"
          pos="relative"
          _focus={{ boxShadow: "none" }}
          outline="none"
          transition="0.6s ease all"
          padding="4px"
          border="1px solid"
          borderRadius="16px"
          bg="transparent"
          borderColor="transparent"
          fill="transparent"
          stroke="brandGray.500"
          _hover={{
            bg: "cyprus.50",
            borderColor: "cyprus.100",
            fill: "cyprus.50",
            stroke: "cyprus.500",
          }}
          id="resource-center"
        >
          <LightBulb03 width="24px" height="24px" />
        </Box>
        {userInfo?.userType !== 3 && (
          <NotificationPopover
            count={count}
            notifications={notifications}
            markAsRead={handleMarkAsRead}
            refetch={getNotificationList}
            nextToken={nextToken}
          />
        )}
        <ProfileMenu userInfo={userInfo} profileMenuItems={profileMenuItems} />
      </HStack>
    </Flex>
  );
};
