// clientInformationSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  addClientInfo,
  updateClientInfo,
  getAllClientInfo,
  deleteClientInfo,
} from "./clientsInformationActions";

const initialState = {
  loading: false,
  error: null,
  success: null,
  companLocation: null,
  clientInfoList: [],
};

const clientInformationSlice = createSlice({
  name: "clientInformation",
  initialState,
  reducers: {
    addClientInfoToList: (state, action) => {
      state.clientInfoList = state.clientInfoList.concat(action.payload);
    },
    removeClientInformationFromList: (state, action) => {
      let locationId = action.payload;
      state.clientInfoList = state.clientInfoList.filter(
        (location) => location.id !== locationId
      );
    },
    updateClientInfoFromList: (state, action) => {
      let locationId = action.payload.id;
      state.clientInfoList = state.clientInfoList.map((location) => {
        return location.id === locationId ? action.payload : location;
      });
    },
    clearMessage: (state) => {
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: {
    // add client information
    [addClientInfo.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addClientInfo.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
      if (payload.data && payload.success) {
        // state.companyLocation = payload.data.profile;
      }
    },
    [addClientInfo.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // get all client information
    [getAllClientInfo.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllClientInfo.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
      if (payload.data && payload.success) {
        state.clientInfoList = payload.data?.clientList || [];
      }
    },
    [getAllClientInfo.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // update client information
    [updateClientInfo.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateClientInfo.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [updateClientInfo.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // delete client information
    [deleteClientInfo.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteClientInfo.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [deleteClientInfo.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
  },
});

export const {
  addClientInfoToList,
  removeClientInformationFromList,
  updateClientInfoFromList,
  clearMessage,
} = clientInformationSlice.actions;

export default clientInformationSlice.reducer;
