// transactionHistorySlice.js
import { createSlice } from "@reduxjs/toolkit";
import { getTransactionHistory } from "./transactionHistoryActions";

const initialState = {
  loading: false,
  error: null,
  success: null,
  transactionHistoryList: [],
};

const transactionHistorySlice = createSlice({
  name: "transactionHistory",
  initialState,
  reducers: {
    clearMessage: (state) => {
      state.success = null;
      state.error = null;
    },
    setError: (state, action) => {
      let errorMsg = action.payload;
      state.error = errorMsg;
    },
    setSuccess: (state, action) => {
      let successMsg = action.payload;
      state.success = successMsg;
    },
  },
  extraReducers: {
    // get coachbar service list
    [getTransactionHistory.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getTransactionHistory.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
      if (payload.data && payload.success) {
        state.transactionHistoryList = payload.data?.subscriptionActivity || [];
      }
    },
    [getTransactionHistory.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
  },
});

export const { setError, setSuccess, clearMessage } =
  transactionHistorySlice.actions;

export default transactionHistorySlice.reducer;
