import { Box, Flex, HStack, Image, Progress, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

const PartnerProfileCard = ({
  provider,
  requirementPercentage,
  partnerProfilePercentage,
}) => {
  const [profileCompletionPercentage, setProfileCompletionPercentage] =
    useState(0);
  const [profileRequirementPercentage, setProfileRequirementPercentage] =
    useState(0);

  useEffect(() => {
    if (requirementPercentage && requirementPercentage?.length > 0) {
      const foundRequirement = requirementPercentage.find(
        (requirement) => requirement?.spTenantId === provider?.referenceTenantId
      );
      const profileRequirement = foundRequirement?.isNa
        ? "NA"
        : foundRequirement?.totalPercentage;
      setProfileRequirementPercentage(profileRequirement);
    }

    if (partnerProfilePercentage && partnerProfilePercentage?.length > 0) {
      const foundProfile = partnerProfilePercentage.find(
        (requirement) => requirement?.spTenantId === provider?.referenceTenantId
      );
      const profileCompletion = foundProfile
        ? foundProfile.totalPercentage
        : "NA";
      setProfileCompletionPercentage(profileCompletion);
    }
  }, [
    requirementPercentage,
    partnerProfilePercentage,
    provider?.referenceTenantId,
  ]);

  return (
    <Box
      p={"20px"}
      bg={"white"}
      rounded={"8px"}
      boxShadow="0px 0px 4px 0px #E6E7E9"
    >
      <Flex alignItems={"start"} justifyContent={"space-between"} mb={"24px"}>
        <HStack>
          <Image
            src={provider?.logo}
            boxSize={"32px"}
            mr={"8px"}
            objectFit={"contain"}
          />
          <Text
            color={"var(--title-black-text)"}
            fontSize={"14px"}
            fontWeight={600}
            lineHeight={"20px"}
          >
            {provider?.spCompanyName}
          </Text>
        </HStack>
        <Text
          p={"2px 12px"}
          rounded={"full"}
          fontSize={"12px"}
          fontWeight={500}
          lineHeight={"18px"}
          bg={provider?.profileStatus === "Published" ? "#EBF6F1" : "#FEF9E5"}
          color={
            provider?.profileStatus === "Published" ? "#2C885C" : "#9F580A"
          }
          border={`1px solid ${
            provider?.profileStatus === "Published" ? "#D7EEE3" : "#FDF3CC"
          }`}
        >
          {provider?.profileStatus === "Published"
            ? "Published"
            : "Not Published"}
        </Text>
      </Flex>
      <Box bg={"#E6E7E933"} p={"12px 16px"} rounded={"8px"}>
        <Flex justifyContent={"space-between"}>
          <Text
            color={"var(--title-black-text)"}
            fontSize={"12px"}
            fontWeight={600}
            lineHeight={"18px"}
          >
            Profile Completion
          </Text>
          <Text
            color={"#2C885C"}
            fontSize={"12px"}
            fontWeight={500}
            lineHeight={"18px"}
          >
            {profileCompletionPercentage !== "NA"
              ? `${profileCompletionPercentage}% Completed`
              : profileCompletionPercentage}
          </Text>
        </Flex>
        <Progress
          colorScheme="brand"
          size="md"
          value={
            profileCompletionPercentage !== "NA"
              ? profileCompletionPercentage
              : 0
          }
          rounded={"13px"}
          mt={"8px"}
        />

        <Flex justifyContent={"space-between"} mt={"20px"}>
          <Text
            color={"var(--title-black-text)"}
            fontSize={"12px"}
            fontWeight={600}
            lineHeight={"18px"}
          >
            Requirement Fullfillment
          </Text>
          <Text
            color={"#2C885C"}
            fontSize={"12px"}
            fontWeight={500}
            lineHeight={"18px"}
          >
            {profileRequirementPercentage !== "NA"
              ? `${profileRequirementPercentage}% Completed`
              : profileRequirementPercentage}
          </Text>
        </Flex>
        <Progress
          colorScheme="brand"
          size="md"
          value={
            profileRequirementPercentage !== "NA"
              ? profileRequirementPercentage
              : 0
          }
          rounded={"13px"}
          mt={"8px"}
        />
      </Box>
    </Box>
  );
};

export default PartnerProfileCard;
