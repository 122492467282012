import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import Select from "react-select";
import { currenciesList } from "../../constants/constantValues";
import CountryFlag from "../CountryFlag/CountryFlag";

const CurrencyDropDown = ({
  validationErrors,
  innerRef = {},
  options,
  hideLabel,
  placeholder,
  parentWidth,
  customWidth,
  name,
  currency,
  onCurrencyChange,
  ...props
}) => {
  const validation = validationErrors && validationErrors["country"];
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: `${
        state.isFocused ? "#0E97AE !important" : "#E6E7E9 !important"
      }`,
      boxShadow: "none",
      width: customWidth,
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "38px",
    }),
    menuList: (provided, state) => ({
      ...provided,
      padding: "8px",
      cursor: "pointer",
    }),
  };
  const currencyOptions = currenciesList.map((x) => {
    return {
      label: (
        <Flex
          display={"flex"}
          fontSize={"14px"}
          color="#383F47"
          fontWeight={"400"}
        >
          {" "}
          <Box display={"inline-block"} mr="8px">
            <CountryFlag country={x.country} height={"1em"} />
          </Box>
          <Text>
            {x.code} - {x?.name}{" "}
          </Text>
        </Flex>
      ),
      value: `${x.code}---${x.name}`,
    };
  });
  let selectedCurrency = useMemo(() => {
    return currencyOptions.find((x) => x?.value?.split("---")[0] === currency);
  }, [currency]);
  let defaultCurrency = useMemo(() => {
    return currencyOptions.find(
      (x) => x?.value?.split("---")[0] === props.defaultCurrency
    );
  }, [props.defaultCurrency]);
  return (
    <Stack>
      <FormControl
        width={parentWidth ? parentWidth : ""}
        isInvalid={!!validation}
        isRequired={props.required}
      >
        {!hideLabel ? (
          <FormLabel
            textTransform={"capitalize"}
            fontSize={"12px"}
            fontWeight={600}
            color={"#5E6977"}
            mb={"4px"}
            as="legend"
            display={"flex"}
          >
            {props.name || placeholder}
          </FormLabel>
        ) : null}
        <Select
          width={"100%"}
          options={currencyOptions}
          placeholder="Select Currency"
          styles={customStyles}
          onChange={(selectedCountry) => {
            onCurrencyChange(selectedCountry);
          }}
          defaultValue={defaultCurrency}
          isSearchable
          isClearable
          size="md"
          customWidth={"100%"}
          value={selectedCurrency}
          {...props}
          {...innerRef}
        />
        {validation ? (
          <Errors errors={validation} />
        ) : props.hideHelperText ? (
          ""
        ) : (
          <FormHelperText>&nbsp;</FormHelperText>
        )}
      </FormControl>
    </Stack>
  );
};

const Errors = ({ errors }) => {
  if (errors) {
    return <FormErrorMessage>{errors.message}</FormErrorMessage>;
  }
  return null;
};

export default CurrencyDropDown;
