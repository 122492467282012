import { axiosInstance } from "../../services/axiosInstance";
import { handleError } from "../../services/utility";

export const GENERAL_SETTING_API_ROUTES = {
  LEAD_DEAL: "lead-deal",
  SC_CATEGORY: "scCategory",
  STATUS: "status",
};

export const getGeneralSettings = async (type) => {
  try {
    const { data } = await axiosInstance.get(`/setting/${type}`);
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};

export const addGeneralSettings = async (type, requestParams) => {
  try {
    const { data } = await axiosInstance.post(`/setting/${type}/add`, {
      ...requestParams,
    });
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};

export const editGeneralSettings = async (type, id, requestParams) => {
  try {
    const { data } = await axiosInstance.put(`/setting/${type}/${id}`, {
      ...requestParams,
    });
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};

export const deleteGeneralSettings = async (type, id) => {
  try {
    const { data } = await axiosInstance.delete(`/setting/${type}/${id}`);
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};
export const getPartnerAvailability = async () => {
  try {
    const { data } = await axiosInstance.get("/partnerAvailability");
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};
export const addPartnerAvailability = async (requestParams) => {
  try {
    const { data } = await axiosInstance.post("/partnerAvailability/add", {
      ...requestParams,
    });
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};
export const updatePartnerAvailability = async (dataId, requestParams) => {
  try {
    const { data } = await axiosInstance.put("/partnerAvailability/" + dataId, {
      ...requestParams,
    });
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};
