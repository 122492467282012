import { Box, Center, Flex } from "@chakra-ui/react";
import { Loader } from "@coachbar/shared-components";
import CustomBarChart from "@coachbar/shared-components/src/components/Charts/CustomBarChart";
import ChartContainer from "@coachbar/shared-components/src/screens/dashboard/ChartContainer";
import ChartGrid from "@coachbar/shared-components/src/screens/dashboard/ChartGrid";
import ChartHeader from "@coachbar/shared-components/src/screens/dashboard/ChartHeader";
import NoDataChart from "@coachbar/shared-components/src/screens/dashboard/NoDataChart";
import { useChartsProvider } from "@coachbar/shared-components/src/screens/dashboard/chartContext";
import {
  addPreviousValuesRevenueProduct,
  calculateDaysBetweenDates,
  compareInnerAndGeneralFilters,
  formatDataRevenueProduct,
  formatDateRange,
  getAppliedFilters,
  getPreviousDateRange,
  getSpecificInnerFilters,
} from "@coachbar/shared-components/src/services/dashboardUtility/utility";
import { deepCopyObject } from "@coachbar/shared-components/src/services/utility";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getRevenueProduct } from "../financialActions";

const RevenueProduct = ({ chartData }) => {
  const { selectedDate, appliedFilters, fixFilters } = useChartsProvider();

  let initialized = false;
  const widgetInfo = chartData;
  const [isLoading, setIsLoading] = useState(false);
  const [chartType, setChartType] = useState("");
  const [products, setProducts] = useState([]);
  const [compareProducts, setCompareProducts] = useState([]);
  const [isCompared, setIsCompared] = useState(false);
  const [prevDateRange, setPrevDateRange] = useState(undefined);
  const [daysBetweenDates, setDaysBetweenDates] = useState(undefined);
  const [innerFilters, setInnerFilters] = useState(
    getSpecificInnerFilters(fixFilters, chartData.filters)
  );
  const [innerDate, setInnerDate] = useState(deepCopyObject({}));

  const clearPreviousData = () => {
    setCompareProducts([]);
    setPrevDateRange(null);
    setDaysBetweenDates(null);
  };

  let conDate = useMemo(() => {
    clearPreviousData();
    return deepCopyObject(
      innerDate?.to && innerDate?.from ? innerDate : selectedDate
    );
  }, [selectedDate, innerDate]);
  const [
    formattedDateForGrid,
    formattedPrevDateForGrid,
    formattedDateForChart,
    formattedPrevDateForChart,
  ] = useMemo(() => {
    // Date format to show in Grid
    const date = formatDateRange(conDate || null, "grid");
    const prevDate = formatDateRange(prevDateRange || null, "grid");
    // Different date format to show in chart tooltip
    const formattedDate = formatDateRange(conDate || null, "chart");
    const formattedPrevDate = formatDateRange(prevDateRange || null, "chart");
    return [date, prevDate, formattedDate, formattedPrevDate];
  }, [conDate, prevDateRange]);

  let innerAppliedFilters = useMemo(() => {
    return getAppliedFilters(innerFilters);
  }, [innerFilters]);

  const constructRequestData = ({ date }) => {
    let requestData = compareInnerAndGeneralFilters(
      appliedFilters,
      innerAppliedFilters,
      Object.keys(fixFilters),
      date,
      selectedDate
    );

    return requestData;
  };

  const onToogleCompare = () => {
    if (isCompared) {
      setIsCompared(false);
      clearPreviousData();
    } else {
      setIsCompared(true);
      const previousDateRange = getPreviousDateRange(conDate);
      const numberOfDays = calculateDaysBetweenDates(conDate);
      setDaysBetweenDates(numberOfDays);
      setPrevDateRange(previousDateRange);
      const requestData = constructRequestData({ date: previousDateRange });
      fetchData(requestData, true, products);
    }
  };

  const fetchData = useCallback(
    async (requestData, isCompared = false, products) => {
      setIsLoading(true);
      const res = await getRevenueProduct(requestData);
      setIsLoading(false);
      const productsRes = res?.data?.dashboard;

      if (productsRes) {
        if (isCompared) {
          let resProds = formatDataRevenueProduct(productsRes);
          setCompareProducts(resProds);
        } else {
          setProducts(formatDataRevenueProduct(productsRes));
        }
      }
    },
    []
  );

  useEffect(() => {
    if (!initialized) {
      initialized = true;
      clearPreviousData();
      const requestData = constructRequestData({ date: innerDate });
      fetchData(requestData);
    }
  }, [fetchData, innerDate, selectedDate, appliedFilters, innerAppliedFilters]);

  let finalRecords = useMemo(() => {
    if (isCompared) {
      const updatedData = addPreviousValuesRevenueProduct(
        products,
        compareProducts
      );
      return updatedData;
    } else {
      return products;
    }
  }, [products, compareProducts]);

  const formattedLeadStatusDataForGrid = useMemo(
    () =>
      finalRecords.map((data) => {
        return {
          id: data.name,
          value: data.value,
          previousValue: data.previousValue || 0,
        };
      }),
    [finalRecords]
  );

  return (
    <>
      {widgetInfo?.isVisible && (
        <ChartContainer>
          <ChartHeader
            title={widgetInfo?.title}
            showConvertToGrid
            showFilters
            showDelete
            showCompare
            chartData={chartData}
            chartType={chartType}
            onChartTypeChange={() => {
              if (chartType === "") {
                setChartType("table");
              } else {
                setChartType("");
              }
            }}
            onToogleCompare={onToogleCompare}
            isCompared={isCompared}
            filters={innerFilters}
            onSubmitFilters={(newFilters, date) => {
              setInnerFilters(newFilters);
              setInnerDate(date);
            }}
            date={innerDate}
            generalDate={selectedDate}
          />
          {isLoading ? (
            <Center h={"80%"}>
              <Loader />
            </Center>
          ) : finalRecords.length <= 0 ? (
            <Box h={"304px"}>
              <NoDataChart />
            </Box>
          ) : chartType === "table" ? (
            <ChartGrid
              data={formattedLeadStatusDataForGrid}
              headings={
                isCompared
                  ? [
                      [
                        "Products",
                        formattedDateForGrid,
                        formattedPrevDateForGrid,
                      ],
                    ]
                  : [["Products", formattedDateForGrid]]
              }
            />
          ) : (
            <Flex h={"400px"} direction={"column"} justifyContent={"center"}>
              <Box h={"360px"} overflow={"visible"}>
                <CustomBarChart
                  currentDate={conDate}
                  data={finalRecords}
                  isCompared={isCompared}
                />
              </Box>
            </Flex>
          )}
        </ChartContainer>
      )}
    </>
  );
};

export default RevenueProduct;
