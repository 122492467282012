import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import {
  invalidUrl,
  requiredField,
  whiteSpace,
} from "../../../constants/validationMessages";
import { Modal, TextArea, TextBox } from "@coachbar/shared-components";
import {
  urlValidationPattern,
  whiteSpacePattern,
} from "../../../constants/validationPatterns";
import { assetErrorMsg, invalidaFileTypes } from "../../../constants/assets";
import FolderUpload from "../../../components/FolderUpload/FolderUpload";
import {
  getAssetIconByExtension,
  getParentFolderId,
  onUploadProgress,
} from "../utility";
import { notification } from "../../../services/utility";

const UploadFileModal = ({
  isOpen,
  onClose,
  portal,
  data,
  setRefetch,
  breadCrumbs,
  setNextToken,
  setLoading: setGridLoading,
  getPreSignedUrl,
  uploadImage,
  saveAssetData,
}) => {
  const MAX_ASSET_FILE_SIZE = 20000; // 20000 in KB (20 MB);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    resetField,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });

  const [loading, setLoading] = useState(false);
  const [externalUrl, setExternalUrl] = useState(undefined);
  const [externalUrlError, setExternalUrlError] = useState(undefined);
  const [assetFile, setAssetFile] = useState(undefined);
  const [assetErrorMessage, setAssetErrorMessage] = useState(undefined);
  const [assetFileIcon, setAssetFileIcon] = useState(undefined);
  const [isLinkDisabled, setIsLinkDisabled] = useState(false);
  const [isAssetDisabled, setIsAssetDisabled] = useState(false);
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [assetFileErrorMsg, setAssetFileErrorMsg] = useState(undefined);
  const [usingDrop, setUsingDrop] = useState(false);
  const [uploadedCount, setUploadedCount] = useState(0);

  const validateAssetFileOrExternalUrl = () => {
    if (!assetFile && !externalUrl) {
      setAssetErrorMessage(assetErrorMsg);
      return false;
    }

    if (!assetFile && externalUrlError) {
      setExternalUrlError(invalidUrl);
      return false;
    }

    if (!assetFile && !externalUrl?.match(urlValidationPattern)) {
      setExternalUrlError(invalidUrl);
      return false;
    }

    setExternalUrlError("");
    return true;
  };

  const submitForm = async (data) => {
    const isValidParams = validateAssetFileOrExternalUrl();
    if (!isValidParams) return;

    setGridLoading(true);
    setLoading(true);
    if (externalUrl) {
      uploadFile(externalUrl, data, true);
    } else {
      uploadFile(assetFile, data);
    }
    notification("Upload has been started successfully.", "success", "", 5000);
  };

  const discardClick = () => {
    setLoading(false);
    reset();
    onClose();
    clearErrors();
    setAssetErrorMessage(undefined);
    setAssetFile(undefined);
    setExternalUrl(undefined);
    setIsLinkDisabled(false);
    setIsAssetDisabled(false);
    setExternalUrlError(undefined);
    setDescriptionLength(0);
    setAssetFileErrorMsg(null);
    setUsingDrop(false);
  };

  useEffect(() => {
    const isDisabled = assetFile ? true : false;
    setIsLinkDisabled(isDisabled);
  }, [assetFile]);

  const handleAssetDeletion = () => {
    setAssetFile(undefined);
    resetField("asset");
    if (!externalUrl) {
      setAssetErrorMessage(assetErrorMsg);
    }
  };

  const validateFile = (fileList, usingDrop = false) => {
    if (isLinkDisabled && (!fileList || fileList.length < 1)) {
      setAssetFileErrorMsg("File is required");
      return;
    }
    setAssetFileErrorMsg(null);
    setAssetErrorMessage(null);
    setExternalUrlError(null);
    setUsingDrop(usingDrop);

    validateFileType(fileList);
  };

  const validateFileType = (files) => {
    const fileObj = files && files[0];
    if (!fileObj) {
      return true;
    }

    const fileExtension = (fileObj?.name?.split(".").pop() || "").toUpperCase();

    if (invalidaFileTypes.includes(fileExtension)) {
      setAssetFileErrorMsg(`${fileExtension} file extension is not valid!`);
      return;
    }

    const fsMb = fileObj.size / 1024;
    if (fsMb > MAX_ASSET_FILE_SIZE) {
      setValue("url", "");
      setAssetFileErrorMsg("Max file size allowed is 20MB");
      return;
    }
    if (!isSubmitting) {
      setAssetFile(fileObj);
      const icon = getAssetIconByExtension(fileExtension);
      setAssetFileIcon(icon);
    }
  };

  const uploadFile = (file, formData, isExternalUrl = false) => {
    let requestData = {};
    const parentFolderId = getParentFolderId(breadCrumbs);
    if (isExternalUrl) {
      requestData = {
        assets: [
          {
            name: formData?.name,
            assetUrl: externalUrl,
            description: formData?.description,
            parentFolderId,
          },
        ],
      };

      saveAssetData(requestData).then((res) => {
        discardClick();
        setNextToken(null);
        setRefetch((prev) => !prev);
      });
    } else {
      requestData = {
        parentFolderId,
        objectName: usingDrop ? file.path.slice(1) : file.path,
      };

      getPreSignedUrl(requestData)
        .then(({ data }) => {
          if (data && data.serviceOfferingList) {
            const generatedPresignedUrl = data.serviceOfferingList;
            const updatedRequestData = [...generatedPresignedUrl];
            updatedRequestData[0].description = formData?.description;
            updatedRequestData[0].name = formData?.name;
            const matchingUrl = generatedPresignedUrl.find(
              (item) => item.path === requestData.objectName
            );

            if (matchingUrl) {
              const { preSignUrl } = matchingUrl;
              uploadImage(file, preSignUrl, onUploadProgress).then(
                ({ status }) => {
                  if (status === 200) {
                    setUploadedCount(uploadedCount);
                    // make API call to store respective file data to DB
                    saveAssetData({ assets: generatedPresignedUrl }).then(
                      (res) => {
                        discardClick();
                        setNextToken(null);
                        setRefetch((prev) => !prev);
                      }
                    );
                  }
                }
              );
            }
          }
        })
        .catch((error) => {
          console.error("Error occurred while fetching presigned URL:", error);
        });
    }
  };

  const handleUrlChange = (e) => {
    const url = e?.target?.value;
    if (url) {
      if (!url.match(urlValidationPattern)) {
        setExternalUrlError(invalidUrl);
      } else {
        setExternalUrlError("");
      }
      setExternalUrl(url);
      setIsAssetDisabled(true);
      setAssetErrorMessage("");
    } else {
      setExternalUrl("");
      setIsAssetDisabled(false);
      if (!assetFile) {
        setAssetErrorMessage(assetErrorMsg);
      }
    }
  };

  const handleDescriptionChange = (event) => {
    setDescriptionLength(event.target.value.length);
  };

  return (
    <>
      <Modal
        title={data ? "Edit File" : "Upload File"}
        isOpen={isOpen}
        onClose={discardClick}
        displayFooter={true}
        allowCloseButton={true}
        loading={loading}
        onSaveClick={() => handleSubmit(submitForm)()}
        saveButtonTitle="Upload File"
        discardTitle="Cancel"
        size="xl"
        spDesign={portal === "sp" ? true : false}
        isCentered
      >
        <form noValidate>
          <Box>
            <TextBox
              name="Name"
              maxLength="256"
              innerref={register("name", {
                required: requiredField.replace("$Field$", "Name"),
                pattern: {
                  value: whiteSpacePattern,
                  message: whiteSpace,
                },
              })}
              required
              validation={true}
              validationErrors={errors}
              showPlaceHolder={true}
              placeholder={"Enter file name"}
            />
            <Box>
              <TextArea
                placeholder={"Description"}
                innerref={register("description", {
                  onChange: (e) => handleDescriptionChange(e),
                })}
                maxLength={500}
                helperText={`${descriptionLength}/500`}
                helperTextPosition={"right"}
              />
            </Box>
            <Box mt={"16px"}>
              <TextBox
                type="text"
                name={"External Link"}
                placeholder={"Enter external link"}
                maxLength="256"
                onChange={handleUrlChange}
                leftAddOn="https://"
                widthFull={true}
                isDisabled={isLinkDisabled}
                reduceOpacity={isLinkDisabled}
                hideHelperText
              />
            </Box>
          </Box>
          {externalUrlError && (
            <Text color={"#E53E3E"} fontSize={"12px"}>
              {externalUrlError}
            </Text>
          )}

          <Flex gap={"20px"} alignItems={"center"} py={"20px"}>
            <Divider />
            <Text color={"#7E8792"} fontSize={"14px"} fontWeight={500}>
              OR
            </Text>
            <Divider />
          </Flex>

          <Box>
            <FolderUpload
              name="Attach Files"
              onChange={validateFile}
              onDrop={validateFile}
              acceptedFileTypes="*"
              assetErrorMsg={assetFileErrorMsg}
              ratio={5 / 2}
              isLoading={loading}
              validFile={assetFile}
              fileIcon={assetFileIcon}
              rootFolderNames={[assetFile?.name]?.filter(Boolean)}
              onDeleteClick={handleAssetDeletion}
              note={
                "Supported file types include images, videos, documents, designs, presentations, and multimedia files, with a maximum allowable size of 20MB."
              }
              isDisabled={isAssetDisabled}
            />
          </Box>

          {assetErrorMessage && (
            <Text color={"#E53E3E"} fontSize={"12px"}>
              - {assetErrorMessage}
            </Text>
          )}
        </form>
      </Modal>
    </>
  );
};

export default UploadFileModal;
