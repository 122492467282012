import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Spinner,
  Stack,
  Switch,
  TableContainer,
  Text,
} from "@chakra-ui/react";
import { scrollbarCSS } from "@coachbar/shared-components/src/constants/constantValues";
import {
  ACTION_ICON_BUTTON_TYPE,
  ActionIconButton,
  Table,
} from "@coachbar/shared-components";
import { updateGeneralNotificationActiveState } from "./generalNotificationActions";
import { useDispatch } from "react-redux";
import { notification } from "@coachbar/shared-components/src/services/utility";
import { updateNotificationRecord } from "./generalNotificationSlice";

const tableHead = [["", "Is Active?", ""]];

const NotificationTable = ({
  cardTitle,
  moduleNotifications,
  handleEditClick,
  notificationsModulesKey,
}) => {
  const [tableBody, setTableBody] = useState([]);

  useEffect(() => {
    if (moduleNotifications && moduleNotifications.length > 0) {
      const notificationList = [];
      for (let index = 0; index < moduleNotifications.length; index++) {
        const notificationItem = [];
        notificationItem.push(
          <NotificationInfo
            title={moduleNotifications[index]["eventName"]}
            subtitle={moduleNotifications[index]["description"]}
          />
        );
        notificationItem.push(
          <NotificationSwitch
            isActive={moduleNotifications[index]["active"]}
            notificationId={moduleNotifications[index].id}
            notificationsModulesKey={notificationsModulesKey}
          />
        );
        notificationItem.push(
          <Flex justifyContent={"end"} alignItems={"center"}>
            <ActionIconButton
              type={ACTION_ICON_BUTTON_TYPE.EDIT}
              onClickAction={() =>
                handleEditClick(
                  moduleNotifications[index],
                  notificationsModulesKey
                )
              }
            />
          </Flex>
        );
        notificationList.push(notificationItem);
      }
      setTableBody([...notificationList]);
    }
  }, [moduleNotifications]);

  return (
    tableBody &&
    tableBody.length > 0 && (
      <CardBlock title={cardTitle}>
        <Table
          tHead={tableHead}
          tBody={tableBody}
          keepBordersRounded
          headerTextStyle={{
            color: "#5E6977",
            backgroundColor: "#F9FAFB",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 600,
          }}
        />
      </CardBlock>
    )
  );
};

const CardBlock = ({ title, children }) => (
  <Stack
    p={"1rem"}
    gap={1}
    bg={"#fff"}
    border={"1px solid #E6E7E9"}
    borderRadius={"4px"}
  >
    <Text color={"#111A29"} fontSize={"14px"} fontWeight={600}>
      {title}
    </Text>
    <TableContainer overflow={"auto"} css={scrollbarCSS}>
      {children}
    </TableContainer>
  </Stack>
);

const NotificationInfo = ({ title, subtitle }) => (
  <Box fontSize={"14px"} style={{ width: 650 }}>
    <Text color={"#111A29"} fontWeight={600}>
      {title}
    </Text>
    <Text color={"#7E8792"} fontWeight={450}>
      {subtitle}
    </Text>
  </Box>
);

const NotificationSwitch = ({
  isActive,
  notificationId,
  notificationsModulesKey,
}) => {
  const [isSwitchActive, setIsSwitchActive] = useState(isActive);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    setIsSwitchActive(isActive);
  }, [isActive]);

  const handleStateChange = async () => {
    setIsLoading(true);
    const data = await updateGeneralNotificationActiveState(
      notificationId,
      !isSwitchActive
    );
    if (data.success) {
      notification(data.message);
      dispatch(
        updateNotificationRecord({
          notificationsModulesKey,
          notificationId,
          data: data.data.notificationSetting,
        })
      );
      // setIsSwitchActive((bool) => !bool);
    }
    setIsLoading(false);
  };
  return (
    <>
      <Switch
        size="md"
        colorScheme="brand"
        isChecked={isSwitchActive}
        onChange={handleStateChange}
        isFocusable={false}
        isDisabled={isLoading}
      />
      {isLoading && (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="brand.100"
          size={"xs"}
          ml="4px"
        />
      )}
    </>
  );
};

export default NotificationTable;
