import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as Plus } from "../../../images/plus.svg";
import {
  Box,
  Divider,
  Flex,
  Spinner,
  Text,
  useDisclosure,
  Tooltip,
} from "@chakra-ui/react";
import CustomModal from "../../Modal/Modal";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { ReactComponent as PlusCircle } from "../../../images/plus-circle.svg";
import { ReactComponent as X } from "../../../images/x.svg";
import { scrollbarCSS } from "../../../constants/constantValues";
import { notification } from "../../../services/utility";
import CustomTooltip from "../../Tooltip/CustomTooltip";
import TableTextWithEllipsis from "../../Table/TableTextWithEllipsis";

const INPUT_SEARCH_LIMIT = 56;
/**
 *
 * @param {Object} Props | React props passed by parent to child
 * @param {Function} Props.getTagListBySearch | function to fetch tag list by search string
 * @param {Function} Props.createTag | function/service to create tag by name given by user
 * @param {Function} Props.handleTagSubmit | action to call after user submit updated tags
 * @param {Array} Props.selectedPartnerTags | previously selected tag list
 * @param {Number} [Props.displayLimit] | max tags to show into the UI after it will show count of remaining tags
 * @param {Object} Props.permissions | permission obj user will have
 * @param {String} Props.permissions.edit | edit permission of user
 * @param {Boolean} [Props.hideAddTagButton] | Pass true if you want to hide add tag button
 * @param {Object} [Props.containerProps] | container props you want to pass
 * @returns
 */
const SystemTag = ({
  getTagListBySearch,
  createTag,
  handleTagSubmit,
  selectedPartnerTags,
  permissions,
  displayLimit = null,
  hideAddTagButton = false,
  containerProps = {},
  moduleForId = "",
}) => {
  const {
    isOpen: isAddTagModalOpen,
    onOpen: onAddTagModalOpen,
    onClose: onAddTagModalClose,
  } = useDisclosure();

  const [selectedTagList, setSelectedTagList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [inputValue, setInputValue] = useState("testing");
  const [isTagSubmitting, setIsTagSubmitting] = useState(false);
  const apiControllerRef = useRef(null);
  const timeoutId = useRef(null);

  useEffect(() => {
    setSelectedTagList([...selectedPartnerTags]);
    return () => {
      setTagList([]);
      setSelectedTagList([]);
      setInputValue("");
      setIsTagSubmitting(false);
    };
  }, [isAddTagModalOpen, selectedPartnerTags]);

  const loadOptions = (inputValue, callback) => {
    if (selectedTagList?.length > 9) {
      callback(tagList);
      return;
    }
    if (timeoutId.current) clearTimeout(timeoutId.current);
    timeoutId.current = setTimeout(async () => {
      if (apiControllerRef.current) apiControllerRef.current.abort();
      const abortController = new AbortController();
      apiControllerRef.current = abortController;
      const data = await getTagListBySearch(inputValue, abortController.signal);
      apiControllerRef.current = null;
      let tagList = [];
      if (data?.length)
        tagList = data.map((tag) => ({ label: tag.name, value: tag.id }));
      setTagList(tagList);
      callback(tagList);
    }, 600);
  };

  const handleInputChange = (searchInput, actionData) => {
    if (selectedTagList.length > 9) return;
    switch (actionData.action) {
      case "input-change":
      case "value-insert":
      case "set-value":
        if (searchInput.length < INPUT_SEARCH_LIMIT) setInputValue(searchInput);
        else notification("Maximum 56 characters allowed.", "error");
        break;

      default:
        break;
    }
  };

  const handleSubmit = () => {
    setIsTagSubmitting(true);
    handleTagSubmit(selectedTagList, (res) => {
      if (res?.success) {
        onAddTagModalClose();
      }
      setIsTagSubmitting(false);
    });
  };

  const handleTagCrossClick = (removedTagId, cb = () => {}) => {
    const updatedTagList = [...selectedPartnerTags].filter(
      (tag) => tag.value !== removedTagId
    );
    handleTagSubmit(updatedTagList, (res) => {
      if (res?.success) {
        notification("Tag removed successfully.");
      }
      cb(res);
    });
  };

  return (
    <Flex
      alignItems={"center"}
      justifyContent="flex-start"
      gap={"8px"}
      wrap={"wrap"}
      {...containerProps}
    >
      {selectedPartnerTags?.map((tag, index) =>
        !displayLimit || index < displayLimit ? (
          <Flex
            border={"1px solid var(--chakra-colors-atlantic-100)"}
            bg="atlantic.50"
            color={"atlantic.700"}
            fontSize={"12px"}
            fontWeight={500}
            lineHeight={"20px"}
            p="3px 12px"
            borderRadius={"20px"}
            gap={"4px"}
            alignItems={"center"}
            key={tag.value}
          >
            <TableTextWithEllipsis text={tag.label} maxChars={20} />
            {permissions.edit && (
              <RemoveTagComp
                tagId={tag.value}
                handleTagCrossClick={handleTagCrossClick}
              />
            )}
          </Flex>
        ) : null
      )}
      {displayLimit && selectedPartnerTags.length > displayLimit && (
        <CustomTooltip
          placement="bottom"
          content={selectedPartnerTags.slice(displayLimit).map((tag) => (
            <Text key={tag.value}>{tag.label}</Text>
          ))}
          hasArrow
        >
          <Text
            color={"cyprus.500"}
            border={"1px solid"}
            borderColor={"cyprus.600"}
            bg={"white"}
            borderRadius={"12px"}
            w={"24px"}
            h="24px"
            display={"inline-flex"}
            justifyContent={"center"}
            alignItems={"center"}
            fontSize={"12px"}
            fontWeight={700}
            pt="1px"
          >
            +{selectedPartnerTags.length - displayLimit}
          </Text>
        </CustomTooltip>
      )}
      {selectedPartnerTags.length < 10 && !hideAddTagButton && (
        <>
          <Tooltip
            label="Add Tag"
            placement="right"
            hasArrow
            isDisabled={!permissions.edit}
            borderRadius={"4px"}
            padding={"8px"}
          >
            <Flex
              width={"24px"}
              height={"24px"}
              alignItems={"center"}
              justifyContent={"center"}
              border={"1.5px solid"}
              borderRadius={"40px"}
              borderStyle={"dashed"}
              ms="0px !important"
              cursor={permissions.edit ? "pointer" : "not-allowed"}
              onClick={permissions.edit ? onAddTagModalOpen : () => {}}
              stroke={permissions.edit ? "cyprus.500" : "brandGray.400"}
              borderColor={permissions.edit ? "cyprus.500" : "brandGray.400"}
              bg={permissions.edit ? "white" : "brandGray.101"}
            >
              <Plus
                stroke="inherite"
                fill="inherite"
                width="16px"
                height="16px"
              />
            </Flex>
          </Tooltip>
          {permissions.edit && (
            <CustomModal
              isOpen={isAddTagModalOpen}
              onClose={onAddTagModalClose}
              title={"Manage Tags"}
              allowCloseButton={true}
              displayFooter={true}
              size={"md"}
              onSaveClick={handleSubmit}
              loading={isTagSubmitting}
              saveButtonTitle="Done"
              padding="8px 24px 24px"
              moduleForId={moduleForId}
            >
              <Box pos={"relative"}>
                <Text
                  fontSize={"12px"}
                  fontWeight={500}
                  lineHeight={"18px"}
                  color={"brandGray.500"}
                  mb="4px"
                >
                  Tags
                </Text>
                <AsyncSelect
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={(e) => {
                    setSelectedTagList(e);
                  }}
                  value={selectedTagList}
                  placeholder="Search & Select"
                  styles={{
                    control: (baseStyles, state) => {
                      return {
                        ...baseStyles,
                        borderColor: state.isFocused
                          ? "var(--chakra-colors-cyprus-500) !important"
                          : "var(--chakra-colors-brandGray-101)",
                        boxShadow: "none",
                        "&:hover": {
                          borderColor: "var(--chakra-colors-cyprus-100)",
                        },
                        transition: "0.6s ease border-color",
                      };
                    },
                    input: (baseStyles, state) => ({
                      ...baseStyles,
                      color: "var(--chakra-colors-atlantic-700)",
                      fontSize: "14px",
                      lineHeight: "20px",
                      fontWeight: 500,
                    }),
                    multiValueLabel: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: "transparent",
                      color: "--chakra-colors-atlantic-700",
                      fontSize: "12px",
                      lineHeight: "20px",
                      fontWeight: 500,
                      paddingTop: "0px",
                      paddingBottom: "0px",
                    }),
                    multiValueRemove: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: "transparent",
                      color: "var(--chakra-colors-brandGray-400)",
                      transition: "0.6s ease color",
                      height: "20px",
                      // padding: "6px",
                      "&:hover": {
                        backgroundColor: "transparent",
                        color: "var(--chakra-colors-atlantic-700)",
                      },
                    }),
                    multiValue: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: "var(--chakra-colors-atlantic-50)",
                      border: "1px solid var(--chakra-colors-atlantic-100)",
                      borderRadius: "16px",
                      padding: "2px 10px",
                    }),
                    clearIndicator: (baseStyles, state) => ({
                      ...baseStyles,
                      cursor: "pointer",
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                      ...baseStyles,
                      cursor: "pointer",
                    }),
                    indicatorSeparator: (baseStyles, state) => ({
                      ...baseStyles,
                      width: "2px",
                      borderRadius: "8px",
                    }),
                    menu: (baseStyles, state) => {
                      const inputValue = state.selectProps.inputValue;
                      const optionLength = state.options.length;
                      const isLoading = state.isLoading;
                      return {
                        ...baseStyles,
                        boxShadow:
                          optionLength < 1 && inputValue && !isLoading
                            ? "none"
                            : "0 0 0 1px hsla(0, 0%, 0%, 0.1),0 4px 11px hsla(0, 0%, 0%, 0.1)",
                        backgroundColor: "var(--chakra-colors-white)",
                        borderRadius: "8px",
                        padding:
                          optionLength < 1 && inputValue && !isLoading
                            ? "0px"
                            : "4px",
                      };
                    },
                    menuList: (baseStyles, state) => ({
                      ...baseStyles,
                      ...scrollbarCSS,
                      position: "relative",
                      padding: "0px",
                    }),
                    option: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: "transparent",
                      cursor: "pointer",
                      color: "var(--chakra-colors-atlantic-700)",
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      transition: "0.2s ease background-color",
                      "&:hover": {
                        backgroundColor: "var(--chakra-colors-cyprus-50)",
                      },
                      padding: "8px",
                      borderRadius: "8px",
                    }),
                  }}
                  loadOptions={loadOptions}
                  components={{ NoOptionsMessage, LoadingMessage, MenuList }}
                  createTag={createTag}
                  autoFocus
                  // defaultMenuIsOpen
                  escapeClearsValue={false}
                  defaultOptions={tagList}
                  setSelectedTagList={setSelectedTagList}
                  inputValue={inputValue}
                  onInputChange={handleInputChange}
                  isDisabled={isTagSubmitting}
                />
              </Box>
            </CustomModal>
          )}
        </>
      )}
    </Flex>
  );
};

const LoadingMessage = React.memo(
  (props) => {
    return (
      <DropDownInfoContainer
        innerRef={props.innerRef}
        innerProps={props.innerProps}
        displayText="Loading..."
      />
    );
  },
  () => true
);

const handleAddTagClick = async (props, setIsAddTagLoading, searchValue) => {
  setIsAddTagLoading(true);
  const trimedSearchValue = searchValue?.trim();
  const res = await props.selectProps.createTag(trimedSearchValue);
  if (res?.success) {
    const tagToInsert = {
      label: res.data.tag.name,
      value: res.data.tag.id,
    };
    props.selectProps.setSelectedTagList((prev) => [...prev, tagToInsert]);
    props.selectProps.onInputChange("", {
      action: "value-insert",
      prevInputValue: searchValue,
    });
  }
  setIsAddTagLoading(false);
};

const NoOptionsMessage = React.memo(
  (props) => {
    const [isAddTagLoading, setIsAddTagLoading] = useState(false);
    const searchValue = useMemo(
      () => props.selectProps.inputValue,
      [props.selectProps.inputValue]
    );

    if (isAddTagLoading)
      return (
        <DropDownInfoContainer
          innerRef={props.innerRef}
          innerProps={props.innerProps}
          displayText="Loading..."
          border={"1px solid hsla(0, 0%, 0%, 0.1)"}
          borderRadius={"8px"}
          py="12px"
        />
      );

    if (!searchValue?.length)
      return (
        <DropDownInfoContainer
          innerRef={props.innerRef}
          innerProps={props.innerProps}
          displayText="Search to see results"
        />
      );

    return (
      <CreateNewTagComp
        onAddTagClick={() =>
          handleAddTagClick(props, setIsAddTagLoading, searchValue)
        }
        innerRef={props.innerRef}
        searchValue={searchValue}
        innerProps={props.innerProps}
      />
    );
  },
  (prev, next) => {
    return prev?.selectProps?.inputValue === next?.selectProps?.inputValue;
  }
);

const CreateNewTagComp = React.memo(
  ({
    innerRef,
    onAddTagClick,
    searchValue,
    innerProps = {},
    containerStyle = {},
  }) => {
    return (
      <Flex
        ref={innerRef}
        {...innerProps}
        color="var(--chakra-colors-brandGray-300)"
        alignItems={"center"}
        justifyContent={"center"}
        py="12px"
        bg={"var(--chakra-colors-cyprus-50)"}
        border={"1px solid var(--chakra-colors-cyprus-100)"}
        borderRadius={"8px"}
        cursor={"pointer"}
        onClick={() => onAddTagClick()}
        style={{ ...containerStyle }}
      >
        <PlusCircle
          fill="var(--chakra-colors-cyprus-50)"
          stroke="var(--chakra-colors-cyprus-500)"
          style={{ marginBottom: "2px" }}
        />
        <Text
          fontSize={"14px"}
          color={"cyprus.500"}
          fontWeight={500}
          lineHeight={"20px"}
          ml="6px"
        >
          Create new tag "
        </Text>
        <Text
          maxW="180px"
          noOfLines={1}
          fontSize={"14px"}
          color={"cyprus.500"}
          fontWeight={500}
          lineHeight={"20px"}
          mr="0px"
          wordBreak={"break-all"}
        >
          {searchValue}
        </Text>
        <Text
          fontSize={"14px"}
          color={"cyprus.500"}
          fontWeight={500}
          lineHeight={"20px"}
        >
          "
        </Text>
      </Flex>
    );
  },
  (prev, curr) => prev.searchValue === curr.searchValue
);

const DropDownInfoContainer = React.memo(
  ({ innerRef, innerProps, displayText, ...extraProps }) => (
    <Flex
      ref={innerRef}
      {...innerProps}
      color="var(--chakra-colors-brandGray-300)"
      justifyContent={"center"}
      py="8px"
      fontSize={"14px"}
      fontWeight={500}
      {...extraProps}
    >
      {displayText}
    </Flex>
  ),
  (prev, next) => prev.displayText === next.displayText
);

const MenuList = (props) => {
  const buttonRef = createRef();
  const [isAddTagLoading, setIsAddTagLoading] = useState(false);
  const searchValue = useMemo(
    () => props.selectProps.inputValue,
    [props.selectProps.inputValue]
  );

  if (props.selectProps.value.length > 9)
    return (
      <DropDownInfoContainer
        innerRef={props.innerRef}
        innerProps={props.innerProps}
        displayText="You can select maximum 10 tag only."
      />
    );

  if (props.isLoading || isAddTagLoading)
    return (
      <DropDownInfoContainer
        innerRef={props.innerRef}
        innerProps={props.innerProps}
        displayText="Loading..."
      />
    );

  return (
    <components.MenuList {...props}>
      {props.children}
      {!isAddTagLoading &&
        !props.isLoading &&
        searchValue &&
        Array.isArray(props.children) && (
          <Box
            pos={"sticky"}
            left={"0px"}
            bottom={"0px"}
            zIndex={10}
            bg={"white"}
          >
            <Divider pt="4px" mb="4px" />
            <CreateNewTagComp
              innerRef={buttonRef}
              onAddTagClick={() =>
                handleAddTagClick(props, setIsAddTagLoading, searchValue)
              }
              searchValue={searchValue}
              containerStyle={{
                borderTopRightRadius: "0px",
                borderTopLeftRadius: "0px",
                border: "0px",
              }}
            />
          </Box>
        )}
    </components.MenuList>
  );
};

const RemoveTagComp = React.memo(({ handleTagCrossClick, tagId }) => {
  const [isTagRemoving, setIsTagRemoving] = useState(false);
  if (isTagRemoving)
    return <Spinner ml="4px" color="brandGray.400" size={"xs"} />;
  return (
    <X
      width="16px"
      height="16px"
      stroke="var(--chakra-colors-brandGray-400)"
      fill="none"
      cursor="pointer"
      onClick={() => {
        setIsTagRemoving(true);
        handleTagCrossClick(tagId, () => setIsTagRemoving(false));
      }}
    />
  );
});

export default SystemTag;
