import {
  Box,
  Circle,
  Flex,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { format, isSameDay, parseISO } from "date-fns";
import { useMemo, useState } from "react";
import {
  deleteIcon,
  filterIcon,
  gridIcon,
  twoWayHorizontalArrows,
} from "../../components/Icons/SvgsConstants";
import { dateConstants } from "../../constants/constantValues";
import { iconMap, slugConstants } from "../../constants/dashboard";
import {
  getAppliedFilters,
  getPreviousDateRange,
} from "../../services/dashboardUtility/utility";
import { deepCopyObject } from "../../services/utility";
import ChartFilter from "./ChartFilter";
import CellLoadingComponent from "@coachbar/shared-components/src/components/Loader/CellLoadingComponent";
import { useChartsProvider } from "./chartContext";

const ChartHeader = ({
  title,
  showAll,
  showFilters,
  showCompare,
  showConvertToGrid,
  showDelete,
  chartData,
  onChartTypeChange,
  chartType,
  onToogleCompare,
  isCompared,
  filters,
  onSubmitFilters,
  generalDate,
  date,
}) => {
  const { onChartHide, userInfo, partnerId, platformJson } =
    useChartsProvider();

  const {
    onOpen: onFilterModalOpen,
    onClose: onFilterModalClose,
    isOpen: isFilterModalOpen,
  } = useDisclosure();
  const tooltipStyles = {
    p: "6px 12px",
    bg: "#162336",
    rounded: "4px",
    color: "white",
    fontSize: "12px",
    fontWeight: 450,
  };

  const [deleteLoading, setDeleteLoading] = useState(false);

  let numberOfInnerFiltersApplied = useMemo(() => {
    let count = 0;
    if (
      date?.hasOwnProperty("to") &&
      date?.hasOwnProperty("from") &&
      JSON.stringify(generalDate) !== JSON.stringify(date)
    ) {
      count++;
    }
    if (filters) {
      let appliedFilters = getAppliedFilters(filters);
      count = count + Object.keys(appliedFilters).length;
    }

    return count;
  }, [generalDate, date, filters]);

  let isAllTimeAppliedData = useMemo(() => {
    let filters = {};
    if (
      date?.hasOwnProperty("to") &&
      date?.hasOwnProperty("from") &&
      JSON.stringify(date) !== JSON.stringify(generalDate)
    ) {
      filters.fromDate = date?.from;
      filters.toDate = date?.to;
    } else {
      filters.fromDate = generalDate?.from;
      filters.toDate = generalDate?.to;
    }

    return filters.fromDate === "" && filters.toDate === "";
  }, [generalDate, date]);

  let dateTag = useMemo(() => {
    let conDate =
      date &&
      deepCopyObject(
        date?.hasOwnProperty("to") && date?.hasOwnProperty("from")
          ? date
          : generalDate
      );
    if (conDate?.to && conDate?.from) {
      const to = parseISO(conDate?.to);
      const from = parseISO(conDate?.from);
      let currentType = dateConstants.find(
        (x) =>
          isSameDay(x.dateObj.startDate, from) &&
          isSameDay(x.dateObj.endDate, to)
      );
      let customDate = `${format(from, "MMM dd, yyyy")} -
          ${format(to, "MMM dd, yyyy")}`;

      return currentType ? currentType.label : customDate;
    }
    return "All Time";
  }, [date, generalDate]);

  let compareDateTag = useMemo(() => {
    let conDate =
      date && deepCopyObject(date?.to && date?.from ? date : generalDate);
    conDate = getPreviousDateRange(conDate);
    if (conDate?.to && conDate?.from) {
      const to = parseISO(conDate?.to);
      const from = parseISO(conDate?.from);
      let currentType = dateConstants.find(
        (x) =>
          isSameDay(x.dateObj.startDate, from) &&
          isSameDay(x.dateObj.endDate, to)
      );
      let customDate = `${format(from, "MMM dd, yyyy")} -
          ${format(to, "MMM dd, yyyy")}`;

      return currentType ? currentType.label : customDate;
    }
  }, [date, generalDate]);

  const onDeleteClick = async () => {
    setDeleteLoading(true);
    await onChartHide(chartData?.slug, () => {
      setDeleteLoading(false);
    });
  };

  return (
    <>
      <Box mb={"24px"}>
        <Flex mb="12px" alignItems={"center"} justifyContent={"space-between"}>
          <Text
            color={"var(--chakra-colors-atlantic-700)"}
            fontSize={"14px"}
            fontWeight={700}
            lineHeight={"20px"}
          >
            {title}
          </Text>
          {userInfo?.role !== "User" && (
            <Flex alignItems={"center"} gap={"12px"}>
              {chartData?.filters &&
                (showFilters || showAll) &&
                filters &&
                Object.keys(filters).length > 0 && (
                  <Tooltip label="Filters" {...tooltipStyles}>
                    <Box
                      position={"relative"}
                      boxSize={"16px"}
                      cursor={"pointer"}
                      _hover={{
                        path: {
                          stroke: "var(--chakra-colors-cyprus-500)",
                        },
                      }}
                      onClick={onFilterModalOpen}
                    >
                      {filters && numberOfInnerFiltersApplied > 0 && (
                        <Circle
                          color="white"
                          bg="cherryRed.500"
                          fontSize="9px"
                          lineHeight="10px"
                          fontWeight={700}
                          position="absolute"
                          top={"-6px"}
                          right={"-7px"}
                          size={"14px"}
                          cursor="pointer"
                        >
                          {numberOfInnerFiltersApplied}
                        </Circle>
                      )}
                      {filterIcon}
                    </Box>
                  </Tooltip>
                )}
              {(showCompare || showAll) && !isAllTimeAppliedData && (
                <Tooltip label="Compare" {...tooltipStyles}>
                  <Box
                    boxSize={"16px"}
                    cursor={"pointer"}
                    _hover={{
                      path: {
                        stroke: "var(--chakra-colors-cyprus-500)",
                      },
                    }}
                    css={{
                      path: {
                        stroke: isCompared ? "#0C94AC" : "#7E8792",
                      },
                    }}
                    onClick={() => {
                      onToogleCompare();
                    }}
                  >
                    {twoWayHorizontalArrows}
                  </Box>
                </Tooltip>
              )}
              {(showConvertToGrid || showAll) && (
                <Tooltip
                  label={
                    chartType === "table"
                      ? iconMap(chartData?.chartType)?.label
                      : "Grid"
                  }
                  {...tooltipStyles}
                >
                  <Box
                    boxSize={"16px"}
                    cursor={"pointer"}
                    _hover={{
                      path: {
                        stroke: "var(--chakra-colors-cyprus-500)",
                      },
                    }}
                    css={{
                      path: {
                        stroke: chartType === "table" ? "#0C94AC" : "#7E8792",
                      },
                      svg: {
                        height: "16px",
                        width: "16px",
                      },
                    }}
                    onClick={() => {
                      onChartTypeChange();
                    }}
                  >
                    {chartType === "table"
                      ? iconMap(chartData?.chartType)?.icon
                      : gridIcon}
                  </Box>
                </Tooltip>
              )}

              {(showDelete || showAll) && (
                <Tooltip label="Delete" {...tooltipStyles}>
                  {deleteLoading ? (
                    <CellLoadingComponent />
                  ) : (
                    <Box
                      boxSize={"16px"}
                      cursor={"pointer"}
                      _hover={{
                        path: {
                          stroke: "var(--chakra-colors-cyprus-500)",
                        },
                      }}
                      onClick={() => onDeleteClick()}
                    >
                      {deleteIcon}
                    </Box>
                  )}
                </Tooltip>
              )}
            </Flex>
          )}
        </Flex>
        <Flex alignItems={"center"}>
          <Tag
            paddingInline={"0"}
            bg="#DEE8F899"
            padding="4px 10px !important"
            borderRadius={"16px"}
            fontWeight={"500"}
            fontSize={"12px"}
            lineHeight={"16px"}
            color="#7284A0"
          >
            {dateTag}
          </Tag>
          <Box display={"inline-block"}>
            {isCompared && (
              <>
                <Box
                  display={"inline-block"}
                  m="0px 4px"
                  fontWeight={"500"}
                  size="10px"
                  lineHeight={"16px"}
                  color={"#7284A0"}
                >
                  vs
                </Box>
                <Tag
                  paddingInline={"0"}
                  bg="#DEE8F899"
                  padding="4px 10px !important"
                  borderRadius={"16px"}
                  fontWeight={"500"}
                  fontSize="12px"
                  lineHeight={"16px"}
                  color="#7284A0"
                >
                  {compareDateTag}
                </Tag>
              </>
            )}
          </Box>
        </Flex>
      </Box>
      {chartData?.filters && filters && (
        <ChartFilter
          isOpen={isFilterModalOpen}
          onClose={onFilterModalClose}
          filters={filters}
          setFilterValues={onSubmitFilters}
          chartName={title}
          hideDate={chartData.slug === slugConstants.engCourseCompletionRate}
          date={date}
          partnerId={partnerId}
          platformJson={platformJson}
          chartData={chartData}
          userInfo={userInfo}
        />
      )}
    </>
  );
};

export default ChartHeader;
