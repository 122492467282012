import { Box, Text } from "@chakra-ui/react";
import React from "react";

const InnerChartContainer = ({ children, chartHeader }) => {
  return (
    <Box
      p={"20px 24px 24px 24px"}
      rounded={"8px"}
      bg={"white"}
      border={"1px solid #E6E7E9"}
    >
      <Text
        color="#5E6977"
        fontSize={"14px"}
        lineHeight={"20px"}
        fontWeight={"600"}
        mb="15px"
      >
        {chartHeader}
      </Text>
      {children}
    </Box>
  );
};

export default InnerChartContainer;
