import React from "react";
import { TextBox, FileUpload } from "@coachbar/shared-components";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ModalCloseButton,
} from "@chakra-ui/react";
import { requiredField, invalidUrl } from "../constants/validationMessages";
import { urlValidationPattern } from "../constants/validationPatterns";
import { buttonVariants } from "@coachbar/shared-components/src/constants/constantValues";

function AddClientModal({
  isOpen,
  onModalClose,
  submitForm,
  handleError,
  loading,
  validateFile,
  isLoading,
  logoImagePath,
  onLogoDelete,
  register,
  handleSubmit,
  selectedClientInfo,
  errors,
}) {
  return (
    <Modal isOpen={isOpen} onClose={onModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          p={"16px 24px"}
          fontSize={"16px"}
          fontWeight={600}
          color={"var(--title-black-text)"}
          borderBottom={"1px solid #DEE8F8"}
        >
          {selectedClientInfo ? "Update Client" : "Add Client"}
        </ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit(submitForm, handleError)} noValidate>
          <ModalBody p={"24px"}>
            <TextBox
              type="text"
              name={"Name"}
              placeholder={"Name"}
              maxLength="100"
              required
              innerref={register("name", {
                required: requiredField.replace("$Field$", "Name"),
              })}
              validationErrors={errors}
            />
            <TextBox
              type="text"
              name={"Website URL"}
              placeholder="www.yourcompany.com"
              maxLength="256"
              required
              innerref={register("websiteURL", {
                required: requiredField.replace("$Field$", "Website URL"),
                pattern: {
                  value: urlValidationPattern,
                  message: invalidUrl,
                },
              })}
              leftAddOn="https://"
              validationErrors={errors}
            />
            <FileUpload
              name="Logo"
              acceptedFileTypes="image/*"
              innerref={register("logo", {
                validate: validateFile,
              })}
              validationErrors={errors}
              ratio={5 / 2}
              boxWidth="100%"
              isLoading={isLoading}
              imgSrc={logoImagePath}
              allowDelete={true}
              onDeleteClick={onLogoDelete}
            />
          </ModalBody>

          <ModalFooter borderTop={"1px solid #DEE8F8"} p={"16px 24px"}>
            <Button
              mr={"20px"}
              onClick={onModalClose}
              variant={buttonVariants.greyLinkButton}
              id="add-client-cancel"
            >
              Cancel
            </Button>
            <Button
              isDisabled={loading}
              variant={buttonVariants.brandPrimary}
              type="submit"
              id="add-client-submit"
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}

export default AddClientModal;
