// install yarn add @nivo/pie (please try to align the version of installed @nivo packages)
import { ResponsivePie } from "@nivo/pie";
import { useMemo } from "react";
import {
  calculateValueDifferences,
  formatPercentage,
} from "../../services/dashboardUtility/utility";

// make sure parent container have a defined height when using responsive component, otherwise height will be 0 and no chart will be rendered
const SpeedoMeter = ({ data, prevData, totalValue }) => {
  const valueDifference = useMemo(() => {
    if (prevData?.length > 0) {
      return calculateValueDifferences(data, prevData);
    }
    return {}; // Return an empty object if prevData is not available
  }, [data, prevData]);

  return (
    <ResponsivePie
      data={data}
      startAngle={-90}
      endAngle={90}
      innerRadius={0.8}
      padAngle={0}
      cornerRadius={0}
      colors={{
        datum: "data.color",
      }}
      borderWidth={0}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      enableArcLinkLabels={false}
      enableRadialLabels={false}
      enableSliceLabels={false}
      enableArcLabels={false}
      tooltip={() => <></>}
      layers={[
        "arcs",
        "arcLabels",
        "arcLinkLabels",
        "legends",
        ({ centerX, centerY, ...props }) => {
          return (
            <>
              {totalValue && (
                <text
                  x={centerX}
                  y={valueDifference["progress"] ? centerY - 40 : centerY - 10}
                  textAnchor="middle"
                  dominantBaseline="central"
                  style={{
                    fontSize: "24px",
                    fontWeight: 700,
                  }}
                >
                  {`${formatPercentage(totalValue)}` || "NA"}
                </text>
              )}
              {valueDifference["progress"] && (
                <text
                  x={centerX}
                  y={centerY - 10}
                  textAnchor="middle"
                  dominantBaseline="central"
                  style={{
                    fontSize: "14px",
                    fontWeight: 700,
                    fill:
                      valueDifference["progress"] >= 0 ? "#216645" : "#C81E1E",
                  }}
                >
                  {valueDifference["progress"] >= 0 ? `\u{1F869}` : `\u{1F86B}`}{" "}
                  {`${formatPercentage(valueDifference["progress"])}` || "NA"}
                </text>
              )}
            </>
          );
        },
      ]}
    />
  );
};

export default SpeedoMeter;
