import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../../services/axiosInstance";
import { getErrorMessage, handleError } from "../../../services/utility";

export const addCompanyProfile = createAsyncThunk(
  "add/companyProfile",
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("/profile/add", requestData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getCompanyProfile = createAsyncThunk(
  "get/companyProfile",
  async (cb, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/profile`);
      cb && cb(data);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateCompanyProfile = createAsyncThunk(
  "update/companyProfile",
  async ({ companyId, requestParams }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(
        `/profile/${companyId}`,
        requestParams
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const searchCompanyProfile = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      `/profile/searchProfile`,
      requestData
    );
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return error.response.data;
    } else {
      return error.message;
    }
  }
};

export const claimUserProfile = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(
      `/profile/profileSetup`,
      requestData
    );
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return error.response.data;
    } else {
      return error.message;
    }
  }
};

export const profilePublishRequest = async (requestData) => {
  try {
    const { data } = await axiosInstance.post(`/profile/publish`, requestData);
    return data;
  } catch (error) {
    if (error.response && error.response.data.message) {
      return error.response.data;
    } else {
      return error.message;
    }
  }
};

export const updateWorldWide = async (requestParams) => {
  try {
    const { data } = await axiosInstance.post(
      `profile/operatingWorldwide`,
      requestParams
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};

export const getCompanyProfileWithoutState = async (userId) => {
  try {
    const { data } = await axiosInstance.get(`/profile`);
    return data;
  } catch (error) {
    // return custom error message from API if any
    return handleError(error);
  }
};
