import React, { useEffect, useState } from "react";
import { useDragLayer } from "react-dnd";
import DraggableInput from "./DraggableInput";

function getItemStyles(currentOffset) {
  if (!currentOffset) {
    return {
      display: "none",
    };
  }

  const { x, y } = currentOffset;
  const transform = `translate(${42}px, ${y - 70}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
}

const DragPreviewLayer = () => {
  const [inputWidth, setInputWidth] = useState("");
  const { itemType, isDragging, item, currentOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      isDragging: monitor.isDragging(),
      currentOffset: monitor.getSourceClientOffset(),
    })
  );

  useEffect(() => {
    const width = document.getElementById("Draggable-Input");
    if (width) {
      setInputWidth(width.offsetWidth);
    }
  }, []);

  if (!isDragging || itemType !== "FORM_INPUT" || !item) {
    return null;
  }

  return (
    <div
      style={{
        ...getItemStyles(currentOffset),
        zIndex: 99999,
        position: "fixed",
        pointerEvents: "none",
        width: inputWidth,
        backgroundColor: "white",
        border: "1px solid #E2E8F0",
        borderRadius: "0.5rem",
        cursor: isDragging ? "grabbing" : "grab",
      }}
    >
      <DraggableInput
        index={item.index}
        id={item.id}
        serialNumber={item.serialNumber}
        label={item.label}
      />
    </div>
  );
};

export default DragPreviewLayer;
