// serviceOfferingSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  invitationDelete,
  invitationUpdate,
  getInvitationsList,
} from "./invitationActions";

const initialState = {
  industriesLoading: false,
  error: null,
  success: null,
};

const invitationSlice = createSlice({
  name: "invitations",
  initialState,
  reducers: {
    clearMessage: (state) => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: {
    // add service offering
    [invitationDelete.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [invitationDelete.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [invitationDelete.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // get service offering
    [invitationUpdate.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [invitationUpdate.fulfilled]: (state, { payload }) => {
      state.success = payload.message;
      state.loading = false;
    },
    [invitationUpdate.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
  },
});

export const {
  addServiceToList,
  removeServiceFromList,
  updateServiceList,
  clearMessage,
} = invitationSlice.actions;

export default invitationSlice.reducer;
