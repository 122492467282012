import React from "react";
import Select from "react-select";
import { FormLabel, Text } from "@chakra-ui/react";

const CustomReactSelect = ({
  label,
  options,
  value,
  onChange,
  errorMessage,
  isMulti = false,
  isRequired = false,
  onMenuClose,
  isLoading,
  hideLabel = false,
  isDisabled = false,
  controlWidth,
  isClearable,
  customPlaceholder = "",
}) => {
  const customStyles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontSize: "14px",
        color: "#718096",
      };
    },
    control: (styles, state) => ({
      ...styles,
      boxShadow: "none",
      border: `1px solid ${state.isFocused ? "#0C94AC" : "#E6E7E9"}`,
      "&:hover": {
        borderColor: "#CBD5E0",
      },
      width: controlWidth ? controlWidth : "100%",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      color: "var(--title-black-text)",
      fontSize: "12px",
      fontWeight: 500,
      backgroundColor: "#F3F7FF",
      border: "1px solid #DEE8F8",
      borderRadius: "16px",
      overflow: "hidden",
      padding: "2px 4px",
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      color: "var(--title-black-text)",
      fontSize: "12px",
      fontWeight: 500,
      backgroundColor: "#F3F7FF",
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      color: "#7E8792",
      backgroundColor: "#F3F7FF",
      ":hover": {
        backgroundColor: "transparent",
      },
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      height: "calc(100% - 15px) !important",
    }),
  };

  return (
    <>
      {!hideLabel && (
        <FormLabel
          color={"#5E6977"}
          fontSize={"12px"}
          fontWeight={600}
          mb={"4px"}
        >
          {label} {isRequired && <span style={{ color: "red" }}>*</span>}
        </FormLabel>
      )}
      <Select
        closeMenuOnSelect={isMulti ? false : true}
        options={options}
        value={value}
        onChange={onChange}
        placeholder={customPlaceholder || "Select"}
        isSearchable={true}
        isMulti={isMulti}
        styles={customStyles}
        onMenuClose={onMenuClose}
        isLoading={isLoading}
        isDisabled={isDisabled}
        isClearable={isClearable}
      />
      {errorMessage && (
        <Text color={"red"} fontSize={"12px"}>
          {errorMessage}
        </Text>
      )}
    </>
  );
};

export default CustomReactSelect;
