import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Button,
} from "@chakra-ui/react";
import { useRef } from "react";
import { buttonVariants } from "../../constants/constantValues";

const CustomAlertDialog = (props) => {
  const cancelRef = useRef();
  return (
    <AlertDialog
      isOpen={props.isAlertOpen}
      leastDestructiveRef={cancelRef}
      isCentered
      onClose={props.onAlertClose}
      {...props}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {props.title}
          </AlertDialogHeader>

          <AlertDialogCloseButton onClick={props.onAlertClose} />

          <AlertDialogBody>
            {props.resellerDescription ? (
              <span>{props.resellerDescription}</span>
            ) : (
              <>
                {props.isCoreSoftwareText ? (
                  props.isCoreSoftwareText
                ) : (
                  <>
                    {props.body ? (
                      props.body
                    ) : props.description ? (
                      <span>
                        Are you sure you want to delete{" "}
                        <b>{props.description}</b>? You cannot undo this action
                        afterward.
                      </span>
                    ) : (
                      <span>
                        Are you sure you want to delete this item? You cannot
                        undo this action afterward.
                      </span>
                    )}
                  </>
                )}
              </>
            )}
          </AlertDialogBody>

          {!props.hideFooter && (
            <AlertDialogFooter>
              {props.spDesign ? (
                <>
                  <Button
                    ref={cancelRef}
                    onClick={props.onAlertClose}
                    isLoading={props.loading}
                    style={{
                      borderRadius: "4px",
                      background: "#FFF",
                      color: "#5E6977",
                      borderColor: "#fff",
                    }}
                    id={`${
                      typeof props.title === "string"
                        ? props.title?.split(" ").join("-").toLowerCase()
                        : ""
                    }-alert-dialog-cancel`}
                  >
                    {props.cancelButtonName ? props.cancelButtonName : "Cancel"}
                  </Button>
                  <Button
                    variant="newColorThemePrimary"
                    bgColor={"#C81E1E"}
                    borderColor={"#C81E1E"}
                    onClick={props.onAlertCloseAction}
                    ml={3}
                    isLoading={props.loading}
                    id={`${
                      typeof props.title === "string"
                        ? props.title?.split(" ").join("-").toLowerCase()
                        : ""
                    }-alert-dialog-confirm`}
                  >
                    {props.actionButtonName}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    ref={cancelRef}
                    onClick={props.onAlertClose}
                    variant="discard"
                    isLoading={props.loading}
                    id={`${
                      typeof props.title === "string"
                        ? props.title?.split(" ").join("-").toLowerCase()
                        : ""
                    }-alert-dialog-cancel`}
                  >
                    {props.cancelButtonName ? props.cancelButtonName : "Cancel"}
                  </Button>
                  <Button
                    variant={
                      props.actionButtonVarient || buttonVariants.dangerFill
                    }
                    onClick={props.onAlertCloseAction}
                    ml={3}
                    isLoading={props.loading}
                    id={`${
                      typeof props.title === "string"
                        ? props.title?.split(" ").join("-").toLowerCase()
                        : ""
                    }-alert-dialog-confirm`}
                  >
                    {props.actionButtonName}
                  </Button>
                </>
              )}
            </AlertDialogFooter>
          )}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default CustomAlertDialog;
