import { axiosInstance } from "../../../services/axiosInstance";
import { handleError, notification } from "../../../services/utility";

export const getUserRoles = async (requestData) => {
  try {
    let { data } = await axiosInstance.post(`/sp/subUser/role`, {
      ...requestData,
    });
    return data.data;
  } catch (error) {
    handleError(error);
  }
};

export const getUsersList = async (requestData) => {
  try {
    let { data } = await axiosInstance.post(`/sp/subUser/list`, requestData);
    return data.data;
  } catch (error) {
    handleError(error);
  }
};

export const editUser = async (requestData) => {
  try {
    let { data } = await axiosInstance.post(`/sp/subUser/update`, {
      ...requestData,
    });
    return data.data;
  } catch (error) {
    handleError(error);
  }
};

export const resendMail = async (requestData) => {
  try {
    let { data } = await axiosInstance.post(`/sp/subUser/resend`, {
      ...requestData,
    });
    if (data?.code === 200) notification(data.message);

    return data;
  } catch (error) {
    handleError(error);
  }
};

export const inviteUser = async (requestData) => {
  try {
    let { data } = await axiosInstance.post(`/sp/subUser/invite`, {
      ...requestData,
    });
    return data.data;
  } catch (error) {
    handleError(error);
  }
};

export const deleteUser = async (requestData) => {
  try {
    let { data } = await axiosInstance.post(`/sp/subUser/delete`, {
      ...requestData,
    });
    if (data?.code === 200) notification(data.message);

    return data;
  } catch (error) {
    handleError(error);
  }
};

export const getRoleDetails = async (requestData, token = null) => {
  try {
    let { data } = await axiosInstance.post(
      `/sp/subUser/role/detail`,
      requestData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return data.data;
  } catch (error) {
    handleError(error);
  }
};

export const getAllUsers = async () => {
  try {
    let { data } = await axiosInstance.get(`/sp/assignUser/list`);
    return data.data;
  } catch (error) {
    handleError(error);
  }
};
