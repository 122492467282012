import { Box, HStack, Text } from "@chakra-ui/react";
import React from "react";
import Select, { components } from "react-select";
import { customStylesForReactSelect } from "../../constants/constantValues";

const FiltersReactSelect = ({
  index,
  filterKey,
  options,
  selectedValues,
  name,
  onSelect,
  onSelectAll,
}) => {
  const onFilterSelection = (value) => {
    onSelect(filterKey, value);
  };

  const onSelectAllClick = () => {
    let newFilters = options.map((x) => ({ label: x.label, value: x.value }));
    onSelectAll(filterKey, newFilters);
  };

  const Menu = (props) => {
    return (
      <components.Menu {...props}>
        <>
          <Box
            display={"flex"}
            padding={"8px"}
            cursor={"pointer"}
            alignItems={"center"}
            flexDir={"row"}
            borderBottom={"1px solid #E6E7E9"}
            onClick={() => onSelectAllClick()}
            fontWeight={"500"}
          >
            <Text>All</Text>
          </Box>
          <div>{props.children}</div>
        </>
      </components.Menu>
    );
  };

  const Option = ({ innerProps, label, value }) => {
    return (
      <div {...innerProps}>
        <HStack
          alignItems={"center"}
          cursor={"pointer"}
          p={"8px"}
          spacing={"8px"}
        >
          <Text fontSize={"16px"} fontWeight={400}>
            {label}
          </Text>
        </HStack>
      </div>
    );
  };

  return (
    <Select
      closeMenuOnSelect={false}
      options={options}
      value={selectedValues}
      onChange={(e) => {
        onFilterSelection(e);
      }}
      placeholder={`Select ${name}`}
      isSearchable={true}
      components={{ Menu, Option }}
      isMulti
      styles={customStylesForReactSelect}
      menuPlacement={index > 4 ? "top" : "auto"}
      menuPosition="fixed"
    />
  );
};

export default FiltersReactSelect;
