export const icpTitleTip =
  "Refers to Ideal Customer Profile. Here, you can outline your perfect customer characteristics";
export const hubspotIntegrationTip =
  "Integrate with other system & sync your data in Coachbar";
export const affiliationTip =
  "Refers to the Professional Organization you are part of.";
export const companyLocationTip =
  "This refers to the country in which your business conducts its operations and carries out daily activities. ";
export const partnerStatusTip =
  "Refer to the Status you’ve earned by serving no. of Clients of particular Software (For ex. Gold, Silver, Platinum)";
export const urlDirectoryTip =
  "Refers to websites where your products/services are listed";
export const serviceRequiredTip =
  "Indicates the need of business contact for certain services.";
export const challengesTip =
  "Refers to the Referral’s Pain points that needs to be addressed";
export const currentStackTip =
  "Refers to all the Software currently Business is using";
export const evaluateSoftwareTip =
  "Refers to the Software which was used previously or assessed by Business";
export const requirementOverviewTip =
  "Refers to the specific criteria or condition required by Business Contact.";
export const referralTooltip =
  "Recommend references who looking for other type of Consultant";
export const serviceOfferingTip =
  "Select the category that best aligns with the service you're offering.";
export const servicesTip = "Refers to the Range of Services you offer";
export const supportedIndustriesTip =
  "Select the Industries your Services focused on";
export const bestForIndusTip =
  "Select the industries that best aligns with the software ecosystem for your Client’s needs.";
export const selectCompanyTip =
  "Select the Business Model (Client is serving which type of customers) that best aligns with Software ecosystem for their needs.";
export const systemGridTip =
  "Enables you to create connected software ecosystem for your client’s businesses.";
export const buyAddonTip =
  "You can buy additional credits to buy more services from Coachbar";
export const waitingTimeIcpTip =
  "Refers to the duration from the service request to the commencement of service provision.";
export const waitingTimeReferralTip =
  "Refers to the duration from the service request to the commencement of service provision.";
export const deploymentPreTip = "Approach to implement the Software.";
export const efficiencyGrowthTip =
  "Refers to Estimated rise in operational efficiency.";
export const actionRequiredTip =
  "Suggest the next follow up action that needs to be taken";
export const bestWayApproachTip =
  "Suggest the most effective strategy to approach a Business";
export const systemGridAbTip =
  "Refers to layout suggesting Software connection to another.";
export const createConfigAbTip =
  "Insert a prominent headline or name for System Grid.";
export const bestForIndusAbTip =
  "These are the sectors where our system grid have greatest impact.";
export const waitingTimeIcpAbTip =
  "Refers to the time between Implementation decision & initiation";
export const waitingTimeReferralAbTip =
  "Refers to Time to wait to contact the referral";
export const requirementOverviewAbTip =
  "Refers to the specific criteria or condition required by referral";
export const serviceRequiredAbTip = "Refers to the  Services referral required";
export const challengesAbTip =
  "Refers to the problems of referrals that needs to be addressed";
export const currentStackAbTip =
  "Refers to all the apps currently referral is using";
