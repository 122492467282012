import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreateAccount from "./CreateAccount";
import PersonaliseAccount from "./PersonaliseAccount";
import { AuthLeftPanel } from "@coachbar/shared-components";
import { Stack } from "@chakra-ui/react";
import { AuthRightPanel } from "@coachbar/shared-components";
import {
  claimProfileRoute,
  planRoute,
  profileRoute,
} from "../../constants/redirectionRoutes";
import { useLocation } from "react-router-dom";
import {
  getSubscriptionPlanDetails,
  setSubscriptionStorage,
} from "../../services/utility";
import { getInvitationDetails } from "./authActions";

const RegisterUser = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [userEmail, setUserEmail] = useState("");
  const [invitedUserData, setInvitedUserData] = useState(null);
  const dispatch = useDispatch();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const planId = searchParams.get("planId");

  if (planId) {
    setSubscriptionStorage(planId);
  }
  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      if (new URLSearchParams(location.search).get("invitation")) {
        fetchInvitationData(
          new URLSearchParams(location.search).get("invitation")
        );
      }
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      // if (planId) {
      //   if (userInfo.planId === 1) {
      //     // User has subscribed with free plan
      //     getPlanDetail();
      //   } else if (userInfo.planId !== planId) {
      //     // user has selected different plan other then what he/she has subscribed
      //     navigate(planRoute);
      //   }
      // } else if (localStorage.getItem("isRedirect")) {
      //   navigate("/settings");
      if (
        userInfo.hasOwnProperty("isClaimed") &&
        userInfo.isClaimed === false
      ) {
        navigate(claimProfileRoute);
      } else {
        navigate(profileRoute);
      }
    }
  }, [navigate, userInfo]);

  const fetchInvitationData = async (token) => {
    let { data } = await getInvitationDetails({ id: token });
    setInvitedUserData(data);
    setUserEmail(data.scEmail);
  };

  const getPlanDetail = () => {
    getSubscriptionPlanDetails(dispatch);
    // navigate(profileRoute);
  };

  const setUserData = (data) => {
    setUserEmail(data.email);
  };

  return (
    <Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
      <AuthLeftPanel />
      <AuthRightPanel
        width={"1 1 calc(100% - 430px)"}
        widthXl={userEmail ? "500px" : "400px"}
      >
        {!userEmail ? (
          <CreateAccount setUserData={setUserData} />
        ) : (
          <PersonaliseAccount email={userEmail} userData={invitedUserData} />
        )}
      </AuthRightPanel>
    </Stack>
  );
};
export default RegisterUser;
