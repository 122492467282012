import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import EmptyDashboardImage from "../../../images/empty-dashboard.png";

const EmptyDashboard = ({
  onWidgetModalOpen,
  height = "calc(100vh - 88px)",
  moduleForId = "",
}) => {
  return (
    <Flex h={height} alignItems={"center"}>
      <Box width={"100%"}>
        <Flex justifyContent={"center"} width={"100%"} mb="24px">
          <Image src={EmptyDashboardImage} height={"300px"} width={"350px"} />
        </Flex>
        <Box width={"100%"}>
          <Text
            align={"center"}
            color={"#111A29"}
            fontWeight={"700"}
            fontSize={"16px"}
            lineHeight={"24px"}
            mb="4px !important"
          >
            This dashboard doesn’t have widgets just yet!
          </Text>
          <Text
            color={"#7E8792"}
            fontWeight={"500"}
            fontSize={"14px"}
            lineHeight={"20px"}
            align={"center"}
          >
            Please add one or more widgets to gain insights into system data.
          </Text>
          <Flex justifyContent={"center"} mt="20px">
            <Button
              textAlign={"center"}
              variant="newColorThemePrimary"
              onClick={onWidgetModalOpen}
              id={`${moduleForId}-widget-add"`}
            >
              Add Widgets
            </Button>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default EmptyDashboard;
