// companyLocationSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  addCompanyLocation,
  updateCompanyLocation,
  getPartnerLocationList,
} from "./companyLocationActions";

const initialState = {
  loading: false,
  error: null,
  success: null,
  companLocation: null,
  partnerLocationList: [],
};

const companLocationSlice = createSlice({
  name: "companLocation",
  initialState,
  reducers: {
    addLocationToList: (state, action) => {
      if (action.payload.primary) {
        state.partnerLocationList = state.partnerLocationList.map(
          (location) => {
            return location.primary
              ? { ...location, primary: false }
              : location;
          }
        );
      }
      state.partnerLocationList = state.partnerLocationList.concat(
        action.payload
      );
    },
    removeLocationFromList: (state, action) => {
      let locationId = action.payload;
      state.partnerLocationList = state.partnerLocationList.filter(
        (location) => location.id !== locationId
      );
    },
    updateLocationFromList: (state, action) => {
      let locationId = action.payload.id;
      state.partnerLocationList = state.partnerLocationList.map((location) => {
        return location.id === locationId
          ? { ...location, ...action.payload }
          : action.payload.primary && location.primary
          ? { ...location, primary: false }
          : location;
      });
    },
    clearMessage: (state) => {
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: {
    // add company location
    [addCompanyLocation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addCompanyLocation.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
      if (payload.data && payload.success) {
        // state.companyLocation = payload.data.profile;
      }
    },
    [addCompanyLocation.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // update company location
    [updateCompanyLocation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateCompanyLocation.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [updateCompanyLocation.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // get partner company location
    [getPartnerLocationList.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.partnerLocationList = [];
    },
    [getPartnerLocationList.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
      if (payload.data && payload.success) {
        state.partnerLocationList = payload.data.addressList;
      }
    },
    [getPartnerLocationList.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
  },
});

export const {
  addLocationToList,
  removeLocationFromList,
  updateLocationFromList,
  clearMessage,
} = companLocationSlice.actions;

export default companLocationSlice.reducer;
