import { Box, Radio, RadioGroup, Stack, Text } from "@chakra-ui/react";

const CustomRadioGroup = ({ title, childList, ...props }) => {
  const { onChange, value } = props;
  return (
    <RadioGroup {...props}>
      <Stack direction={props.direction ? props.direction : "row"}>
        {childList.map((child) => (
          <>
            {props.boxDesign ? (
              <Box
                borderLeft={child.value === 1 ? "1px solid #E6E7E9" : "none"}
                roundedLeft={child.value === 1 ? "4px" : "0"}
                roundedRight={child.value === 10 ? "4px" : "0"}
                borderRight={"1px solid #E6E7E9"}
                borderTop={"1px solid #E6E7E9"}
                borderBottom={"1px solid #E6E7E9"}
                marginInlineStart={"0 !important"}
                pos={"relative"}
                boxSize={"32px"}
                bg={value >= child.value ? "#E7FAFD" : "none"}
                fontSize={"14px"}
                fontWeight={500}
                color={"var(--title-black-text)"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                {child.title}
                <Radio
                  pos={"absolute"}
                  inset={0}
                  opacity={0}
                  zIndex={1}
                  key={child.value}
                  value={child.value}
                  isChecked={value === child.value}
                  onChange={() => onChange(child.value)}
                  sx={{
                    "input:checked ~ &": {
                      bgColor: "#0C94AC",
                      border: "#0C94AC",
                    },
                  }}
                ></Radio>
              </Box>
            ) : (
              <Radio
                key={child.value}
                value={child.value}
                isChecked={value === child.value}
                onChange={() => onChange(child.value)}
                size={props?.size ?? "md"}
                sx={{
                  "input:checked ~ &": {
                    bgColor: "#0C94AC",
                    border: "#0C94AC",
                  },
                }}
              >
                <Text fontSize={"14px"} fontWeight={500} color={"#5E6977"}>
                  {child.title}
                </Text>
              </Radio>
            )}
          </>
        ))}
      </Stack>
    </RadioGroup>
  );
};

export default CustomRadioGroup;
