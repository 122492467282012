import { Box, SimpleGrid } from "@chakra-ui/react";
import ValuesChangeCard from "@coachbar/shared-components/src/components/Charts/ValuesChangeCard";
import ChartContainer from "@coachbar/shared-components/src/screens/dashboard/ChartContainer";
import ChartHeader from "@coachbar/shared-components/src/screens/dashboard/ChartHeader";
import { useChartsProvider } from "@coachbar/shared-components/src/screens/dashboard/chartContext";
import {
  calculateDaysBetweenDates,
  calculatePercentageDifference,
  compareInnerAndGeneralFilters,
  formatYAxisTicks,
  getAppliedFilters,
  getPreviousDateRange,
} from "@coachbar/shared-components/src/services/dashboardUtility/utility";
import {
  deepCopyObject,
  getCurrency,
} from "@coachbar/shared-components/src/services/utility";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getLeadToWinData, getRevenueData } from "../../dashboardActions";
import {
  getAvgLeadDealCommission,
  getClawBackData,
  getEstimatedCommission,
} from "../../financial/financialActions";

const PeriodSummary = () => {
  const { selectedDate, appliedFilters, fixFilters, partnerId } =
    useChartsProvider();
  const { userInfo } = useSelector((state) => state.auth);

  let currencySymbol = getCurrency().symbol;
  const [revenueData, setRevenueData] = useState({});
  const [leadToWinData, setLeadToWinData] = useState({});
  const [revenueDataLoading, setRevenueDataLoading] = useState(false);
  const [winDataLoading, setWinDataLoading] = useState(false);
  const [totComm, setTotComm] = useState({});
  const [totCommLoading, setTotCommDataLoading] = useState(false);
  const [avgComm, setAvgComm] = useState({});
  const [avgCommLoading, setAvgCommDataLoading] = useState(false);
  const [clawBack, setClawBack] = useState({});
  const [clawBackLoading, setClawBackDataLoading] = useState(false);
  const [innerFilters, setInnerFilters] = useState(deepCopyObject(fixFilters));
  const [innerDate, setInnerDate] = useState(deepCopyObject({}));

  let innerAppliedFilters = useMemo(() => {
    return getAppliedFilters(innerFilters);
  }, [innerFilters]);

  let initialize = false;

  useEffect(() => {
    if (!initialize) {
      initialize = true;
      fetchRevenueData();
      fetchWinData();
      fetchTotComm();
      fetchAvgCommission();
      fetchClawBackData();
    }
  }, [innerDate, innerAppliedFilters, selectedDate, appliedFilters]);

  const fetchRevenueData = async () => {
    setRevenueDataLoading(true);
    let filtersPass = compareInnerAndGeneralFilters(
      appliedFilters,
      innerAppliedFilters,
      Object.keys(fixFilters),
      innerDate,
      selectedDate
    );
    let requestData = { ...filtersPass };
    requestData.partners = [partnerId];
    let { data } = await getRevenueData(requestData);
    let conDate = deepCopyObject(
      innerDate?.to && innerDate?.from ? innerDate : selectedDate
    );

    let prevDateRange = deepCopyObject(getPreviousDateRange(conDate));
    let prevRequestedData = deepCopyObject(requestData);
    prevRequestedData.fromDate = prevDateRange.from;
    prevRequestedData.toDate = prevDateRange.to;
    let compareData = await getRevenueData(prevRequestedData);
    let resultData = data.dashboard;

    resultData.openDealRevenuePercentChange = calculatePercentageDifference(
      compareData.data.dashboard?.openDealRevenue,
      resultData?.openDealRevenue
    );
    resultData.openDealCountPercentChange = calculatePercentageDifference(
      compareData.data.dashboard?.openDealCount,
      resultData?.openDealCount
    );
    resultData.avgOpenDealRevenuePercentChange = calculatePercentageDifference(
      compareData.data.dashboard?.avgOpenDealRevenue,
      resultData?.avgOpenDealRevenue
    );
    resultData.wonDealRevenuePercentChange = calculatePercentageDifference(
      compareData.data.dashboard?.wonDealRevenue,
      resultData?.wonDealRevenue
    );
    resultData.wonDealCountPercentChange = calculatePercentageDifference(
      compareData.data.dashboard?.wonDealCount,
      resultData?.wonDealCount
    );
    resultData.avgWonDealRevenuePercentChange = calculatePercentageDifference(
      compareData.data.dashboard?.avgWonDealRevenue,
      resultData?.avgWonDealRevenue
    );
    setRevenueDataLoading(false);
    setRevenueData(resultData);
  };
  const fetchWinData = async () => {
    setWinDataLoading(true);
    let filtersPass = compareInnerAndGeneralFilters(
      appliedFilters,
      innerAppliedFilters,
      Object.keys(fixFilters),
      innerDate,
      selectedDate
    );
    let requestData = { ...filtersPass };
    requestData.partners = [partnerId];
    let { data } = await getLeadToWinData(requestData);
    let conDate = deepCopyObject(
      innerDate?.to && innerDate?.from ? innerDate : selectedDate
    );
    let prevDateRange = getPreviousDateRange(conDate);
    requestData.fromDate = prevDateRange.from;
    requestData.toDate = prevDateRange.to;
    let compareData = await getLeadToWinData(requestData);
    let resultData = data.dashboard;
    resultData.leadInNewAverageTimePercentChange =
      calculatePercentageDifference(
        compareData.data.dashboard?.leadInNewAverageTime,
        resultData?.leadInNewAverageTime
      );
    resultData.leadToWinAverageTimePercentChange =
      calculatePercentageDifference(
        compareData.data.dashboard?.leadToWinAverageTime,
        resultData?.leadToWinAverageTime
      );
    setWinDataLoading(false);
    setLeadToWinData(resultData);
  };

  const fetchTotComm = async () => {
    setTotCommDataLoading(true);
    let filtersPass = compareInnerAndGeneralFilters(
      appliedFilters,
      innerAppliedFilters,
      Object.keys(fixFilters),
      innerDate,
      selectedDate
    );
    let requestData = { ...filtersPass };
    requestData.partners = [partnerId];
    let { data } = await getEstimatedCommission(requestData);
    let resultData = data.dashboard;
    let conDate = deepCopyObject(
      innerDate?.to && innerDate?.from ? innerDate : selectedDate
    );
    let prevDateRange = deepCopyObject(getPreviousDateRange(conDate));
    let prevRequestedData = deepCopyObject(requestData);
    prevRequestedData.fromDate = prevDateRange.from;
    prevRequestedData.toDate = prevDateRange.to;
    let compareData = await getEstimatedCommission(prevRequestedData);

    resultData.totalPercentageChange = calculatePercentageDifference(
      compareData.data.dashboard?.total,
      resultData?.total
    );

    setTotCommDataLoading(false);
    setTotComm(resultData);
  };

  const fetchAvgCommission = async () => {
    setAvgCommDataLoading(true);
    let filtersPass = compareInnerAndGeneralFilters(
      appliedFilters,
      innerAppliedFilters,
      Object.keys(fixFilters),
      innerDate,
      selectedDate
    );
    let requestData = { ...filtersPass };
    requestData.partners = [partnerId];
    let { data } = await getAvgLeadDealCommission(requestData);
    let resultData = data.dashboard;
    let conDate = deepCopyObject(
      innerDate?.to && innerDate?.from ? innerDate : selectedDate
    );
    let prevDateRange = deepCopyObject(getPreviousDateRange(conDate));
    let prevRequestedData = deepCopyObject(requestData);
    prevRequestedData.fromDate = prevDateRange.from;
    prevRequestedData.toDate = prevDateRange.to;
    let compareData = await getAvgLeadDealCommission(prevRequestedData);

    resultData.totalPercentageChange = calculatePercentageDifference(
      compareData.data.dashboard?.total,
      resultData?.total
    );

    setAvgCommDataLoading(false);
    setAvgComm(resultData);
  };

  const fetchClawBackData = async () => {
    setClawBackDataLoading(true);
    let filtersPass = compareInnerAndGeneralFilters(
      appliedFilters,
      innerAppliedFilters,
      Object.keys(fixFilters),
      innerDate,
      selectedDate
    );
    let requestData = { ...filtersPass };
    requestData.partners = [partnerId];
    let { data } = await getClawBackData(requestData);
    let resultData = data.dashboard;

    let conDate = deepCopyObject(
      innerDate?.to && innerDate?.from ? innerDate : selectedDate
    );

    let prevDateRange = deepCopyObject(getPreviousDateRange(conDate));
    let prevRequestedData = deepCopyObject(requestData);
    prevRequestedData.fromDate = prevDateRange.from;
    prevRequestedData.toDate = prevDateRange.to;
    let compareData = await getClawBackData(prevRequestedData);

    resultData.totalPercentageChange = calculatePercentageDifference(
      compareData.data.dashboard?.total,
      resultData?.total
    );

    setClawBackDataLoading(false);
    setClawBack(resultData);
  };

  let dayDiff = useMemo(() => {
    let conDate = deepCopyObject(
      innerDate?.to && innerDate?.from ? innerDate : selectedDate
    );
    return calculateDaysBetweenDates(conDate);
  }, [innerDate, selectedDate]);

  return (
    <Box mb="20px">
      <ChartContainer>
        <ChartHeader
          title={"Period Summary"}
          showFilters
          showDelete
          filters={innerFilters}
          onSubmitFilters={(newFilters, date) => {
            setInnerFilters(newFilters);
            setInnerDate(date);
          }}
          date={innerDate}
          generalDate={selectedDate}
        />
        <SimpleGrid columns={4} spacing={"20px"}>
          {userInfo.role !== "User" && (
            <ValuesChangeCard
              title={"Open Deals Revenue"}
              vsText={`prev ${dayDiff} days`}
              value={
                revenueData.hasOwnProperty("openDealRevenue")
                  ? `${currencySymbol}${formatYAxisTicks(
                      revenueData.openDealRevenue
                    )}`
                  : ""
              }
              changePercent={revenueData.openDealRevenuePercentChange}
              loading={revenueDataLoading}
              showCompare={true}
            />
          )}
          <ValuesChangeCard
            title={"Open Deals Count"}
            vsText={`prev ${dayDiff} days`}
            value={
              revenueData.hasOwnProperty("openDealCount")
                ? revenueData.openDealCount
                : ""
            }
            changePercent={revenueData.openDealCountPercentChange}
            loading={revenueDataLoading}
            showCompare={true}
          />
          {userInfo.role !== "User" && (
            <ValuesChangeCard
              title={"Avg. Open Deal Revenue"}
              vsText={`prev ${dayDiff} days`}
              value={
                revenueData.hasOwnProperty("avgOpenDealRevenue")
                  ? `${currencySymbol}${formatYAxisTicks(
                      revenueData.avgOpenDealRevenue
                    )}`
                  : ""
              }
              changePercent={revenueData.avgOpenDealRevenuePercentChange}
              loading={revenueDataLoading}
              showCompare={true}
            />
          )}
          <ValuesChangeCard
            title={"Avg. Time in Lead: New"}
            vsText={`prev ${dayDiff} days`}
            value={
              leadToWinData.hasOwnProperty("leadInNewAverageTime")
                ? leadToWinData.leadInNewAverageTime
                : ""
            }
            changePercent={leadToWinData.leadInNewAverageTimePercentChange}
            loading={winDataLoading}
          />
          {userInfo.role !== "User" && (
            <ValuesChangeCard
              title={"Won Deals Revenue"}
              vsText={`prev ${dayDiff} days`}
              value={
                revenueData.hasOwnProperty("wonDealRevenue")
                  ? `${currencySymbol}${formatYAxisTicks(
                      revenueData.wonDealRevenue
                    )}`
                  : ""
              }
              changePercent={revenueData.wonDealRevenuePercentChange}
              loading={revenueDataLoading}
              showCompare={true}
            />
          )}
          <ValuesChangeCard
            title={"Won Deals Count"}
            vsText={`prev ${dayDiff} days`}
            value={
              revenueData.hasOwnProperty("wonDealCount")
                ? revenueData.wonDealCount
                : ""
            }
            changePercent={revenueData.wonDealCountPercentChange}
            loading={revenueDataLoading}
            showCompare={true}
          />
          {userInfo.role !== "User" && (
            <ValuesChangeCard
              title={"Avg. Won Deal Revenue"}
              vsText={`prev ${dayDiff} days`}
              value={
                revenueData.hasOwnProperty("avgWonDealRevenue")
                  ? `${currencySymbol}${formatYAxisTicks(
                      revenueData.avgWonDealRevenue
                    )}`
                  : ""
              }
              changePercent={revenueData.avgWonDealRevenuePercentChange}
              loading={revenueDataLoading}
              showCompare={true}
            />
          )}
          <ValuesChangeCard
            title={"Avg. Lead-to-Win Time"}
            vsText={`prev ${dayDiff} days`}
            value={
              leadToWinData.hasOwnProperty("leadToWinAverageTime")
                ? leadToWinData.leadToWinAverageTime
                : ""
            }
            changePercent={leadToWinData.leadToWinAverageTimePercentChange}
            loading={winDataLoading}
          />
          <ValuesChangeCard
            title={"Estimated Commission"}
            vsText={`prev ${dayDiff} days`}
            value={totComm.hasOwnProperty("total") ? totComm.total : ""}
            changePercent={totComm.TotalPercentChange}
            loading={totCommLoading}
          />
          <ValuesChangeCard
            title={"Clawback"}
            vsText={`prev ${dayDiff} days`}
            value={clawBack.hasOwnProperty("total") ? clawBack.total : ""}
            changePercent={clawBack.TotalPercentChange}
            loading={clawBackLoading}
          />
          <ValuesChangeCard
            title={"Avg. Estimated Commission"}
            vsText={`prev ${dayDiff} days`}
            value={avgComm.hasOwnProperty("total") ? avgComm.total : ""}
            changePercent={avgComm.TotalPercentChange}
            loading={avgCommLoading}
          />
          {/* <ValuesChangeCard
            title={"Avg. Estimated Payout"}
            vsText={`prev ${dayDiff} days`}
          /> */}
        </SimpleGrid>
      </ChartContainer>
    </Box>
  );
};

export default PeriodSummary;
