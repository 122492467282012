import React, { useState, useEffect } from "react";
import { Input, Tr, Td, Flex, Box } from "@chakra-ui/react";
import CustomDropdown from "@coachbar/shared-components/src/components/CustomDropDown/CustomDropDown";
import { getCurrency } from "@coachbar/shared-components/src/services/utility";

const ProductCommission = ({ row, programOverviewDetails }) => {
  const [transactions, setTransactions] = useState({});
  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    const initialTransactions = {};
    row?.original?.productCommission?.forEach((deal) => {
      deal?.priceOptions?.forEach((prod) => {
        initialTransactions[prod?.id] = prod?.scTransaction || 0;
      });
    });
    setInitialData(initialTransactions);
    setTransactions(initialTransactions);
  }, [row]);

  return row?.original?.productCommission?.map((deal) =>
    deal?.priceOptions?.map((prod) => {
      return (
        <Tr key={prod?.id}>
          <Td color={"#111A29"} fontSize={"14px"}>
            {deal?.name}
          </Td>
          <Td color={"#111A29"} fontSize={"14px"}>
            {prod?.name}
          </Td>
          <Td color={"#111A29"} fontSize={"14px"}>
            {prod?.scTotal !== undefined && prod?.scTotal !== null
              ? `${getCurrency().symbol}${prod?.scTotal}`
              : "-"}
          </Td>
          <Td color={"#111A29"} fontSize={"14px"}>
            {prod?.scRate !== undefined && prod?.scRate !== null
              ? programOverviewDetails?.outcomeBaseObject?.rates[0]
                  ?.rateType === "Fixed Amount"
                ? `${getCurrency().symbol}${prod?.scRate}`
                : `${prod?.scRate}%`
              : "-"}
          </Td>
          <Td color={"#2C885C"} fontSize={"14px"}>
            {prod?.scReward !== undefined && prod?.scReward !== null
              ? `${getCurrency().symbol}${prod?.scReward}`
              : "-"}
          </Td>
          <Td color={"#111A29"} fontSize={"14px"}>
            {prod?.scTransaction !== undefined && prod?.scTransaction !== null
              ? `${getCurrency().symbol}${prod?.scTransaction}`
              : "-"}
          </Td>
          <Td color={"#C27803"} fontSize={"14px"}>
            {prod?.scDue !== undefined && prod?.scDue !== null
              ? `${getCurrency().symbol}${prod?.scDue}`
              : "-"}
          </Td>
          <Td color={"#111A29"} fontSize={"14px"}>
            {prod?.status}
          </Td>
        </Tr>
      );
    })
  );
};

export default ProductCommission;
