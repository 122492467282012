import { Box, Flex, AbsoluteCenter, Image } from "@chakra-ui/react";
import logo from "../images/logo.svg";

const AuthLeftPanel = () => {
  return (
    <Flex flex="1 1 430px">
      <Box bgGradient={`var(--primary-gradient)`} pos="relative" w="full">
        <AbsoluteCenter>
          <Image src={logo} h={"72px"} alt="Coachbar Logo"></Image>
        </AbsoluteCenter>
      </Box>
    </Flex>
  );
};

export default AuthLeftPanel;
