// CompanyLocation.js
import { StarIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Card,
  CardBody,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  IconButton,
  Image,
  SimpleGrid,
  Spinner,
  Stack,
  StackDivider,
  Switch,
  Tab,
  TabIndicator,
  TabList,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {
  AlertDialog,
  Button,
  CountryFlag,
  Loader,
  Modal,
  TextBox,
  TipsLinkButton,
} from "@coachbar/shared-components";
import CountryDropdown from "@coachbar/shared-components/src/components/CountryDropdown/CountryDropdown";
import CustomRequirementPopover from "@coachbar/shared-components/src/components/CustomPopover/CustomRequirementPopover";
import CustomTooltip from "@coachbar/shared-components/src/components/Tooltip/CustomTooltip";
import { scrollbarCSS } from "@coachbar/shared-components/src/constants/constantValues";
import NoRecordFoundScreen from "@coachbar/shared-components/src/screens/NoRecordFoundScreen";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Notification from "../../../components/Notification";
import { TIPS_LINK_PAGE_COMPANY_PROFILE } from "../../../constants/profileSetup";
import { companyLocationTip } from "../../../constants/tooltips";
import {
  permissionTypes,
  userRoleEnums,
} from "../../../constants/usersAndRoles";
import { requiredField } from "../../../constants/validationMessages";
import InfoOutline from "../../../image/Info-outline.svg";
import CoachBarLogoIcon from "../../../image/coachbar-logo-icon.png";
import greenCheckFill from "../../../image/green-check.svg";
import emptyLocation from "../../../image/location-empty.png";
import infoIcon from "../../../image/yellow-info-icon.svg";
import {
  getCountryList,
  getCurrentPartnerData,
  hasPermissionManagerSpecialCase,
  notification,
} from "../../../services/utility";
import { getPartnerProviderList } from "../../myProviders/providersList/providersListActions";
import { getActiveRequirements } from "../../serviceOffering/serviceOfferingActions";
import { updateWorldWide } from "../companyProfile/companyProfileActions";
import { updateWorldWideValue } from "../companyProfile/companyProfileSlice";
import { getProfileCompletionStatus } from "../profileMetrics/profileMetricsAction";
import {
  addCompanyLocation,
  getPartnerLocationList,
  updateCompanyLocation,
} from "./companyLocationActions";
import {
  addLocationToList,
  clearMessage,
  updateLocationFromList,
} from "./companyLocationSlice";
import {
  Edit02,
  Star01,
  Trash03,
} from "@coachbar/shared-components/src/images";

const countryOptions = getCountryList();

const CompanyLocation = ({ permissions }) => {
  const { userInfo } = useSelector((state) => state.auth);
  const { loading, error, partnerLocationList, success } = useSelector(
    (state) => state.companyLocation
  );
  const { companyProfile } = useSelector((state) => state.companyProfile);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const [isWorldWide, setIsWorldWide] = useState(false);
  const [worldwideLoading, setWorldwideLoading] = useState(false);

  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      //eslint-disable-next-line
      initialized = true;
      dispatch(
        getPartnerLocationList(process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID)
      );
      getPartnerList();
      getCurrPartnerLocationRecommendation(
        process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
      );
    }
  }, []);

  useEffect(() => {
    if (companyProfile) setIsWorldWide(companyProfile.worldwide);
  }, [companyProfile]);

  const submitForm = (data) => {
    if (!selectedPartners.length) {
      setSelectedPartnersError(true);
      return;
    }
    const selectedLocationId = selectedLocation || data.id;
    const selectedCountryValue = data.country?.value || data?.country || "";
    const requestParams = {
      ...data,
      country: selectedCountryValue,
    };
    requestParams.partnerTenantIdList = [...selectedPartners];

    if (selectedLocationId) {
      handleLocationUpdate(selectedLocationId, requestParams);
    } else {
      dispatch(addCompanyLocation(requestParams)).then((responseData) => {
        if (responseData.payload.success && responseData.payload.data) {
          if (
            !partnerLocationList ||
            (partnerLocationList && partnerLocationList?.length < 1)
          ) {
            dispatch(getProfileCompletionStatus());
          }
          dispatch(addLocationToList(responseData.payload.data.address));
          onModalClose();
        }
      });
    }
  };

  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    onOpen: onAlertOpen,
    isOpen: isAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  const [selectedLocation, setSelectedLocation] = useState(null);

  const editLocation = (event, addressDetails) => {
    setValue("country", {
      label: addressDetails?.country,
      value: addressDetails?.country,
    });
    let skipKeys = ["modified", "created", "id", "tenantId", "country"];
    setSelectedLocation(addressDetails?.id);
    for (const key in addressDetails) {
      if (addressDetails.hasOwnProperty(key) && !skipKeys.includes(key)) {
        setValue(key, addressDetails[key]);
      }
    }
    setSelectedPartners([
      ...(addressDetails?.partnerTenantIdList?.length
        ? addressDetails?.partnerTenantIdList
        : []),
    ]);
    onOpen();
  };

  const setAsprimaryLocation = (event, addressDetails) => {
    const newAddressDetails = { ...addressDetails };
    newAddressDetails["primary"] = true;
    handleLocationUpdate(newAddressDetails.id, newAddressDetails);
  };

  const onModalClose = () => {
    if (selectedLocation) {
      setSelectedLocation(null);
    }
    setSelectedPartners([]);
    setSelectedPartnersError(false);
    reset();
    onClose();
  };

  const [locationToDelete, setLocationToDelete] = useState(null);

  const onAlertCloseAction = () => {
    if (locationToDelete?.id) {
      const requestParams = { ...locationToDelete, partnerTenantIdList: [] };
      dispatch(
        updateCompanyLocation({
          addressId: locationToDelete?.id,
          requestParams,
        })
      ).then((responseData) => {
        if (responseData.payload.success && responseData.payload.data) {
          dispatch(getPartnerLocationList(currentPartner));
          onAlertClose();
        }
      });
    }
  };

  const onAlertOpenAction = (event, addressDetails) => {
    setLocationToDelete(addressDetails);
    onAlertOpen();
  };

  const getAddressCardList = (partnerLocationList) => {
    return (
      <SimpleGrid spacing={"20px"} m={"24px"} columns={[1, 2, 2, 3, 4]}>
        {partnerLocationList.map((address) => {
          return (
            <Card
              key={address.id}
              variant={"outline"}
              size="md"
              m={"0px"}
              border={address.primary ? "2px solid" : "0px"}
              borderColor={address.primary ? "brand.50" : ""}
              boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1)"
              borderRadius="8px"
              overflow={"hidden"}
              _hover={{
                ".hover-buttons": {
                  display: "flex",
                },
              }}
            >
              {address.primary ? (
                <Box position="absolute" right="0px">
                  <Stack
                    direction="row"
                    alignItems="center"
                    justify="center"
                    spacing={1}
                    background="brand.50"
                    fontSize="10px"
                    fontWeight="600"
                    lineHeight="12px"
                    // fontFamily="Inter"
                    color="brandGray.100"
                    padding="4px"
                    borderRadius="6px"
                    borderBottomRightRadius="0"
                    borderTopLeftRadius="0"
                  >
                    <StarIcon />
                    <Text>PRIMARY</Text>
                  </Stack>
                </Box>
              ) : (
                ""
              )}

              <CardBody>
                {address.country ? (
                  <HStack>
                    <Text
                      color={"var(--title-black-text)"}
                      fontWeight={600}
                      fontSize={"14px"}
                      lineHeight={"20px"}
                    >
                      {address.country}
                    </Text>
                    <CountryFlag country={address.country} height={"1em"} />
                  </HStack>
                ) : (
                  ""
                )}
                <Stack
                  spacing="1"
                  fontWeight="500"
                  fontStyle="normal"
                  lineHeight="24px"
                  fontSize="16px"
                  color="brand.100"
                >
                  <Text>{address?.address1}</Text>
                  <Text>{address?.address2}</Text>
                  <Text>{address?.city}</Text>
                  <Text>{address?.state}</Text>
                  <Text>{address?.zipCode}</Text>
                </Stack>
                <Box
                  className="hover-buttons"
                  position="absolute"
                  inset={0}
                  bg={"rgba(17, 26, 41, 0.8)"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  display={"none"}
                  gap={"12px"}
                >
                  {permissions.edit && (
                    <>
                      <IconButton
                        variant="ghost"
                        _hover={{
                          backgroundColor: address.primary ? "" : "#0C94AC",
                        }}
                        _active={{
                          backgroundColor: address.primary ? "" : "#0C94AC",
                        }}
                        onClick={(e) => setAsprimaryLocation(e, address)}
                        border={"none"}
                        isDisabled={address.primary}
                        _disabled={{
                          opacity: 1,
                          bg: "none",
                          cursor: "not-allowed",
                        }}
                        id={`company-location-list-made-primary-${address.id}`}
                      >
                        <Star01
                          stroke={
                            address.primary
                              ? "var(--chakra-colors-cyprus-400)"
                              : "var(--chakra-colors-white)"
                          }
                          fill={
                            address.primary
                              ? "var(--chakra-colors-cyprus-400)"
                              : "none"
                          }
                          width="24px"
                          height="24px"
                        />
                      </IconButton>
                      <IconButton
                        variant="ghost"
                        _hover={{ backgroundColor: "#0C94AC" }}
                        _active={{ backgroundColor: "#0C94AC" }}
                        onClick={(e) => editLocation(e, address)}
                        border={"none"}
                        id={`company-location-list-edit-${address.id}`}
                      >
                        <Edit02
                          stroke="var(--chakra-colors-white)"
                          fill="none"
                          width="24px"
                          height="24px"
                        />
                      </IconButton>
                    </>
                  )}
                  {permissions.delete && (
                    <IconButton
                      variant="ghost"
                      _hover={{ backgroundColor: "#0C94AC" }}
                      _active={{ backgroundColor: "#0C94AC" }}
                      onClick={(e) => onAlertOpenAction(e, address)}
                      border={"none"}
                      id={`company-location-list-delete-${address.id}`}
                    >
                      <Trash03
                        stroke="var(--chakra-colors-white)"
                        fill="none"
                        width="24px"
                        height="24px"
                      />
                    </IconButton>
                  )}
                </Box>
              </CardBody>
            </Card>
          );
        })}
      </SimpleGrid>
    );
  };

  const onWorldWideChange = async (e) => {
    setWorldwideLoading(true);
    await updateWorldWide({ worldwide: e.target.checked })
      .then((res) => {
        if (res.success) {
          dispatch(updateWorldWideValue(res.data.profile.worldwide));
          if (res.data.profile.worldwide)
            notification("Successfully added Operating Worldwide.");
          else notification("Operating Worldwide deleted successfully.");
        }
      })
      .finally(() => {
        setWorldwideLoading(false);
      });
  };

  const [partnerList, setPartnerList] = useState([]);
  const [assignedPartnerList, setAssignedPartnerList] = useState([]);
  const [partnerListLoading, setPartnerListLoading] = useState(true);
  const [currentPartner, setCurrentPartner] = useState(
    process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID || ""
  );
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [
    currPartnerLocationRecommendation,
    setCurrPartnerLocationRecommendation,
  ] = useState([]);
  const [selectedPartnersError, setSelectedPartnersError] = useState(false);

  const getPartnerList = async () => {
    setPartnerListLoading(true);
    const partnerApiData = await getPartnerProviderList({});
    setPartnerList(partnerApiData?.partnerList || []);
    const assignedPartners =
      partnerApiData?.partnerList.filter(
        (partner) => partner.assignedUser === userInfo.id
      ) || [];
    setAssignedPartnerList(assignedPartners);
    setPartnerListLoading(false);
  };

  const handleUpdateModalIconClick = (selectedTenantId) => {
    setSelectedPartners((prevList) => {
      const isSelected = selectedPartners.includes(selectedTenantId);
      if (!isSelected) {
        if (selectedPartnersError) setSelectedPartnersError(false);
        return [...prevList, selectedTenantId];
      }
      return prevList.filter(
        (partnerTenantId) => partnerTenantId !== selectedTenantId
      );
    });
  };

  const getCurrPartnerLocationRecommendation = async (tenantId) => {
    const companyData = await getActiveRequirements(tenantId);
    const companyLocationRquirements = companyData?.data?.requirement?.country;
    if (companyLocationRquirements?.length)
      setCurrPartnerLocationRecommendation(companyLocationRquirements);
    else setCurrPartnerLocationRecommendation([]);
  };

  const getLocationAddButton = (spTenantId, btnProps = {}) => {
    const ButtonComp = (
      <Button
        {...btnProps}
        title={"Add Location"}
        onClick={() => {
          setValue("country", null);
          setSelectedPartners([spTenantId]);
          onOpen();
        }}
        id="profile-setup-location-add"
      />
    );
    if (
      hasPermissionManagerSpecialCase(
        permissionTypes.add,
        spTenantId,
        userInfo,
        permissions,
        partnerList
      )
    )
      return ButtonComp;

    return null;
  };

  const getAddModalPartnerList = () => {
    if (userInfo.role === userRoleEnums.manager) return assignedPartnerList;
    return partnerList;
  };

  const handleError = () => {
    if (!selectedPartners.length) setSelectedPartnersError(true);
  };

  const handleLocationUpdate = (addressId, requestParams) => {
    dispatch(
      updateCompanyLocation({
        addressId,
        requestParams,
      })
    ).then((responseData) => {
      if (responseData.payload.success && responseData.payload.data) {
        if (
          !partnerLocationList ||
          (partnerLocationList && partnerLocationList?.length < 1)
        ) {
          dispatch(getProfileCompletionStatus());
        }
        dispatch(updateLocationFromList(responseData.payload.data.address));
        onModalClose();
      }
    });
  };

  return (
    <Box
      bg={"white"}
      p={"16px 0 0"}
      w={"100%"}
      h={"calc(100vh - 216px)"}
      rounded={"8px"}
      overflow={"auto"}
      boxShadow={"1px 0px 4px #E5E7EB"}
      css={scrollbarCSS}
    >
      {error || success ? (
        <Notification
          title={error ? error : success}
          status={error ? "error" : "success"}
          onCloseToast={() => dispatch(clearMessage())}
        />
      ) : (
        ""
      )}
      <Flex
        alignItems={"center"}
        justifyContent={"space-between"}
        p="0 24px 8px"
      >
        <Text
          color={"var(--title-black-text)"}
          fontSize={"14px"}
          lineHeight={"20px"}
          fontWeight={700}
          display="flex"
          alignItems={"center"}
          gap={"6px"}
        >
          Company Locations
          <CustomTooltip content={companyLocationTip}>
            <Image
              display={"inline-block"}
              cursor={"pointer"}
              src={InfoOutline}
              w={"20px"}
              verticalAlign={"center"}
              mt={"6px"}
            />
          </CustomTooltip>
        </Text>
        <HStack
          gap={"20px"}
          divider={
            <StackDivider
              border="2px solid var(--chakra-colors-brandGray-101)"
              rounded={"8px"}
              m={"0px !important"}
            />
          }
        >
          {permissions.edit && companyProfile && (
            <HStack p="6px 0">
              {worldwideLoading && (
                <span>
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="brand.100"
                    size={"xs"}
                    ml="3px"
                  />
                </span>
              )}
              <Switch
                isChecked={isWorldWide}
                onChange={onWorldWideChange}
                size="md"
                id="is-worldwide"
                isDisabled={worldwideLoading}
                colorScheme="brand"
              />
              <Text
                color={"var(--title-black-text)"}
                fontSize={"14px"}
                lineHeight={"20px"}
                fontWeight={500}
              >
                Operating Worldwide
              </Text>
            </HStack>
          )}

          <>
            <TipsLinkButton to={TIPS_LINK_PAGE_COMPANY_PROFILE} />
            {partnerLocationList?.length >= 1
              ? getLocationAddButton(currentPartner)
              : null}
          </>
        </HStack>
      </Flex>
      {partnerListLoading ? (
        <Loader />
      ) : (
        <Tabs position="relative" variant="unstyled">
          <TabList
            width={"100%"}
            overflow={"auto"}
            p="0 24px"
            gap="20px"
            borderBottom={"1px solid var(--chakra-colors-atlantic-100)"}
            css={scrollbarCSS}
          >
            <Tab
              whiteSpace={"nowrap"}
              fontSize={"14px"}
              fontWeight={600}
              color={"#7284A0"}
              marginBottom={0}
              p="8px 0"
              onClick={() => {
                setCurrentPartner(
                  process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
                );
                dispatch(
                  getPartnerLocationList(
                    process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
                  )
                );
                getCurrPartnerLocationRecommendation(
                  process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
                );
              }}
            >
              <Tooltip label="Coachbar" placement="bottom">
                <Avatar
                  size="xs"
                  name="Coachbar"
                  src={CoachBarLogoIcon}
                  mr={"4px"}
                />
              </Tooltip>
              Coachbar
            </Tab>
            {partnerList?.map((partner, i) => {
              return (
                <Tab
                  key={partner.referenceTenantId + i}
                  whiteSpace={"nowrap"}
                  fontSize={"14px"}
                  fontWeight={600}
                  color={"#7284A0"}
                  marginBottom={0}
                  p="8px 0"
                  onClick={() => {
                    setCurrentPartner(partner.referenceTenantId);
                    dispatch(getPartnerLocationList(partner.referenceTenantId));
                    getCurrPartnerLocationRecommendation(
                      partner.referenceTenantId
                    );
                  }}
                >
                  <Avatar
                    size="xs"
                    name={partner.spCompanyName}
                    src={partner?.logo}
                    mr={"4px"}
                  />
                  {partner.spCompanyName}
                </Tab>
              );
            })}
          </TabList>
          <TabIndicator
            height="3px"
            bg="#0C94AC"
            top={"38px"}
            borderRadius="2px 2px 0px 0px"
            zIndex={199}
          />
        </Tabs>
      )}
      {currPartnerLocationRecommendation.length > 0 && (
        <Flex
          p={"10px"}
          justifyContent={"center"}
          alignItems={"center"}
          bg={"#FDFDEA"}
          border={"1px solid #FDF6B2"}
          rounded={"6px"}
          m="20px 16px"
        >
          <Flex alignItems={"center"}>
            <Image h={"14px"} src={infoIcon} mr="8px" />
            <Text color={"#723B13"} fontSize={"12px"} fontWeight={450}>
              There are specific requirements for Partner Profiles to be
              published in the Partner Directory on the{" "}
              <span style={{ fontWeight: 600 }}>
                {getCurrentPartnerData(currentPartner, partnerList)
                  ?.scCompanyName || ""}
              </span>
              site. Please review the
            </Text>
            <Box position={"relative"}>
              <CustomRequirementPopover
                popoverTriggerChildren={
                  <Text
                    color={"#723B13"}
                    fontSize={"12px"}
                    fontWeight={600}
                    lineHeight={"18px"}
                    textDecor={"underline"}
                    cursor={"pointer"}
                    ml={"4px"}
                  >
                    recommendations
                  </Text>
                }
                popoverBody={
                  <Box>
                    {currPartnerLocationRecommendation.map((software, i) => {
                      return <Text key={i}>{software}</Text>;
                    })}
                  </Box>
                }
              />
            </Box>
            .
          </Flex>
        </Flex>
      )}
      {!partnerListLoading ? (
        loading ? (
          <Center minH={"calc(100vh - 364px)"}>
            <Loader />
          </Center>
        ) : partnerLocationList && partnerLocationList.length > 0 ? (
          <>{getAddressCardList(partnerLocationList)}</>
        ) : (
          <Flex
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            h={"calc(100% - 100px)"}
          >
            <NoRecordFoundScreen
              title="No Locations Found"
              description={
                getLocationAddButton(currentPartner)
                  ? "Try adding company locations to see the data using below Call to Action button."
                  : ""
              }
              imageUrl={emptyLocation}
            >
              <Box mt="20px">
                {getLocationAddButton(currentPartner, { rounded: "8px" })}
              </Box>
            </NoRecordFoundScreen>
          </Flex>
        )
      ) : null}
      <Modal
        isOpen={isOpen}
        onClose={onModalClose}
        title={selectedLocation ? "Update Location" : "Add New Location"}
        allowCloseButton={true}
        displayFooter={true}
        size={"2xl"}
        onSaveClick={handleSubmit(submitForm, handleError)}
        loading={loading}
        saveButtonTitle="Save Location"
      >
        <Stack spacing={2}>
          <form noValidate>
            <HStack spacing={6}>
              <TextBox
                type="text"
                name="Address Line 1"
                placeholder="Address Line 1"
                maxLength="255"
                required
                innerref={register("address1", {
                  required: requiredField.replace("$Field$", "Address Line 1"),
                })}
                validationErrors={errors}
              />
              <TextBox
                type="text"
                name="Address Line 2"
                maxLength="255"
                placeholder="Address Line 2"
                innerref={register("address2")}
              />
            </HStack>
            <HStack spacing={6}>
              <TextBox
                type="text"
                name="city"
                placeholder="City"
                maxLength="100"
                required
                innerref={register("city", {
                  required: requiredField.replace("$Field$", "City"),
                })}
                validationErrors={errors}
              />
              <TextBox
                type="text"
                placeholder="State/Province"
                name="State/Province"
                maxLength="100"
                required
                innerref={register("state", {
                  required: requiredField.replace("$Field$", "State/Province"),
                })}
                validationErrors={errors}
              />
            </HStack>
            <HStack spacing={6}>
              <Controller
                control={control}
                name="country"
                defaultValue={null}
                rules={{ required: "Country is required." }}
                render={({ field: { onChange, value, name, ref } }) => (
                  <CountryDropdown
                    options={countryOptions}
                    placeholder="Select Country"
                    onChange={(selectedCountry) => {
                      onChange(selectedCountry);
                    }}
                    name={name}
                    size="md"
                    customWidth={"300px"}
                    value={value}
                    innerRef={ref}
                    required
                    validationErrors={errors}
                    maxMenuHeight={200}
                  />
                )}
              />
              <TextBox
                type="text"
                name="Zip/Postal Code"
                placeholder="Zip/Postal Code"
                required
                innerref={register("zipCode", {
                  required: requiredField.replace("$Field$", "Zip/Postal Code"),
                })}
                validationErrors={errors}
              />
            </HStack>
            <HStack spacing={12} mb="4px">
              <Text
                fontSize={"12px"}
                color={"#5E6977"}
                fontWeight={600}
                as="legend"
                mb="4px"
              >
                Select the Directory
              </Text>
              <Image
                display={"inline-block"}
                src={InfoOutline}
                w={"16px"}
                ml="4px !important"
                mb="4px"
              />
            </HStack>
            <HStack
              spacing={12}
              mb="16px"
              flexWrap="wrap"
              justifyContent="start"
              gap="15px"
              position={"relative"}
            >
              {hasPermissionManagerSpecialCase(
                permissionTypes.add,
                process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID,
                userInfo,
                permissions,
                partnerList
              ) && (
                <Tooltip label="Coachbar" placement="bottom">
                  <Box
                    border="1px solid #BFC3C9"
                    borderRadius="36px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width={"36px"}
                    marginInlineStart={0}
                    height={"36px"}
                    cursor="pointer"
                    position={"relative"}
                    onClick={() => {
                      handleUpdateModalIconClick(
                        process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
                      );
                    }}
                  >
                    <Avatar
                      size="xs"
                      name="Coachbar"
                      src={CoachBarLogoIcon}
                      iconLabel="ABC"
                    />
                    {selectedPartners.includes(
                      process.env.REACT_APP_COACHBAR_ADMIN_TENANT_ID
                    ) ? (
                      <Image
                        position={"absolute"}
                        top="-2px"
                        right="-4px"
                        w={"12px"}
                        h={"12px"}
                        color={"#2C885C"}
                        src={greenCheckFill}
                      />
                    ) : null}
                  </Box>
                </Tooltip>
              )}
              {getAddModalPartnerList().map((partner, i) => (
                <Tooltip
                  key={partner.referenceTenantId + i}
                  label={partner.spCompanyName}
                  placement="bottom"
                >
                  <Box
                    border="1px solid #BFC3C9"
                    borderRadius="36px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    width={"36px"}
                    height={"36px"}
                    m="0px !important"
                    cursor="pointer"
                    position={"relative"}
                    onClick={() => {
                      handleUpdateModalIconClick(partner.referenceTenantId);
                    }}
                  >
                    <Avatar
                      size="xs"
                      name="Coachbar"
                      src={partner.logo}
                      iconLabel="ABC"
                    />
                    {selectedPartners.includes(partner.referenceTenantId) ? (
                      <Image
                        position={"absolute"}
                        top="-2px"
                        right="-4px"
                        w={"12px"}
                        h={"12px"}
                        color={"#2C885C"}
                        src={greenCheckFill}
                      />
                    ) : null}
                  </Box>
                </Tooltip>
              ))}
              {selectedPartnersError && (
                <FormControl
                  isInvalid={true}
                  position={"absolute"}
                  bottom={"-16px"}
                  left={"0px"}
                  ml="0px !important"
                >
                  <FormErrorMessage>
                    Please select at least one directory.
                  </FormErrorMessage>
                </FormControl>
              )}
            </HStack>
            <HStack spacing={6}>
              <Checkbox
                defaultChecked={
                  partnerLocationList && partnerLocationList.length === 0
                }
                {...register("primary")}
                _checked={{
                  "& .chakra-checkbox__control": {
                    background: "#0C94AC",
                    border: "#0C94AC",
                  },
                }}
              >
                Mark as primary
              </Checkbox>
            </HStack>
          </form>
        </Stack>
      </Modal>
      <AlertDialog
        title="Delete Location"
        actionButtonName="Delete"
        isAlertOpen={isAlertOpen}
        onAlertClose={onAlertClose}
        onAlertCloseAction={onAlertCloseAction}
      />
    </Box>
  );
};
export default CompanyLocation;
