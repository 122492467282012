import { useEffect, useState, useRef } from "react";
import { Button, Link } from "@coachbar/shared-components";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Stack,
  HStack,
  Text,
  Image,
  Spacer,
  useDisclosure,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Wrap,
  WrapItem,
  Flex,
} from "@chakra-ui/react";
import fallbackImage from "../../image/fallbackImage.png";
import RedeemService from "./RedeemService";
import { setError, setSuccess } from "./coachbarServicesSlice";
import { buttonVariants } from "@coachbar/shared-components/src/constants/constantValues";
import QuantityModifier from "./QuantityModifier";

const CoachbarServiceList = ({ permissions, ...props }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const accordionRefs = useRef([]);
  const viewDetails = new URLSearchParams(location.search);
  const detailsIndex = new URLSearchParams(viewDetails).get("details");
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [selectedSevice, setService] = useState({});
  const [serviceQuantity, setServiceQuantity] = useState([]);
  const [detailsViewIndex, setDetailsViewIndex] = useState(
    detailsIndex ? parseInt(detailsIndex) : 0
  );

  useEffect(() => {
    // if (detailsIndex && accordionRefs.current[detailsIndex]) {
    //   setTimeout(() => {
    //     accordionRefs.current[detailsIndex]?.scrollIntoView({
    //       behavior: "smooth",
    //     });
    //   }, 100);
    // }
  }, []);

  useEffect(() => {
    if (props.cbServiceList?.length > 0) {
      const updatedServiceWithQuantities = props?.cbServiceList?.map(
        (service) => {
          return {
            id: service?.id,
            quantity: 1,
          };
        }
      );
      setServiceQuantity(updatedServiceWithQuantities);
    }
  }, [props.cbServiceList]);

  const onBuyNow = (service) => {
    let serviceObj = { ...service };
    serviceObj.type = "RedeemService";
    serviceObj.setError = setError;
    serviceObj.setSuccess = setSuccess;
    serviceObj.quantity =
      serviceQuantity?.find((sq) => sq.id === service?.id)?.quantity || 1;
    setService(serviceObj);
    onOpen();
  };

  const onViewDetailClick = (serviceId) => {
    const detailsParams = new URLSearchParams(location.search);
    detailsParams.set("details", serviceId);
    navigate({ search: detailsParams.toString() });
  };

  const onCloseReddemService = () => {
    onClose();
  };

  const onReddemCredit = () => {
    props.onTabClick(1);
  };

  const increaseServiceQuantity = (id) => {
    setServiceQuantity((prevQuantities) =>
      prevQuantities.map((qty) => {
        if (qty.id === id) {
          return { ...qty, quantity: qty.quantity + 1 };
        }
        return qty;
      })
    );
  };

  const decreaseServiceQuantity = (id) => {
    setServiceQuantity((prevQuantities) =>
      prevQuantities.map((qty) => {
        if (qty.id === id) {
          // Check if the quantity is already 1 before decrementing
          const newQuantity = qty.quantity > 1 ? qty.quantity - 1 : 1;
          return { ...qty, quantity: newQuantity };
        }
        return qty;
      })
    );
  };

  const WrapItemList = ({ servicelist }) => {
    return (
      <Wrap>
        {servicelist.map((service) => {
          return (
            <WrapItem key={service.id}>
              <Text
                border={"1px solid #DEE8F8"}
                bg={"#F3F7FF"}
                fontSize={"12px"}
                fontWeight={500}
                color={"var(--title-black-text)"}
                rounded={"16px"}
                p={"4px 10px"}
              >
                {service}
              </Text>
            </WrapItem>
          );
        })}
      </Wrap>
    );
  };

  return (
    <>
      <RedeemService
        isOpen={isOpen}
        onClose={onCloseReddemService}
        selectedSevice={selectedSevice}
        performExtraAction={onReddemCredit}
      />

      <Accordion allowToggle defaultIndex={detailsViewIndex - 1}>
        {props.cbServiceList.map((service) => {
          return props.serviceDetailId &&
            props.serviceDetailId === service.id ? null : (
            <AccordionItem
              ref={(ref) => (accordionRefs.current[service.id] = ref)}
              key={service.id}
              border={"none"}
              mb={"24px"}
            >
              {({ isExpanded }) => (
                <Box border="1px solid #E6E7E9" rounded={"8px"}>
                  {!isExpanded && (
                    <Box p={"20px"}>
                      <HStack spacing={"12px"} alignItems={"flex-start"}>
                        <Image
                          src={service.icon}
                          alt={service.title}
                          fallbackSrc={fallbackImage}
                          boxSize={"40px"}
                        />
                        <Stack spacing={"12px"}>
                          <Text
                            fontSize={"14px"}
                            fontWeight={600}
                            color={"var(--title-black-text)"}
                          >
                            {service.title}
                          </Text>
                          <HStack spacing={0}>
                            <Text
                              fontSize={"12px"}
                              fontWeight={400}
                              color={"#5E6977"}
                            >
                              {service.shortDescription}
                            </Text>
                            <AccordionButton
                              w={"auto"}
                              p={0}
                              _hover={{ bg: "none" }}
                            >
                              <Text
                                cursor={"pointer"}
                                fontSize={"12px"}
                                fontWeight={500}
                                color={"#0C94AC"}
                                onClick={() => onViewDetailClick(service.id)}
                              >
                                ... View more
                              </Text>
                            </AccordionButton>
                          </HStack>
                        </Stack>
                        <Spacer />
                        <Box
                          minW={"170px"}
                          border={"1px solid #E6E7E9"}
                          textAlign={"center"}
                          borderRadius={"8px"}
                          overflow={"hidden"}
                        >
                          <Text
                            bg={"#F9FAFB"}
                            p={"12px"}
                            fontSize={"16px"}
                            fontWeight={600}
                            color={"#0C94AC"}
                          >
                            {service.credit} Credits
                          </Text>
                          <HStack
                            borderTop={"1px solid #E6E7E9"}
                            p={"14px"}
                            spacing={"4px"}
                            justifyContent={"center"}
                          >
                            <Text
                              fontSize={"12px"}
                              fontWeight={500}
                              color={"#7E8792"}
                            >
                              Duration:
                            </Text>
                            <Text
                              fontSize={"12px"}
                              fontWeight={500}
                              color={"var(--title-black-text)"}
                              textTransform={
                                service.timeFrame ? "lowercase" : "capitalize"
                              }
                            >
                              {service.timeFrame || "NA"}
                            </Text>
                          </HStack>
                        </Box>
                      </HStack>
                    </Box>
                  )}
                  <AccordionPanel p={0}>
                    <HStack
                      p={"20px"}
                      alignItems={"flex-start"}
                      justifyContent={"space-between"}
                      spacing={0}
                    >
                      <Box pr={"32px"}>
                        <HStack spacing={"12px"} mb={"20px"}>
                          <Image
                            src={service.icon}
                            alt={service.title}
                            fallbackSrc={fallbackImage}
                            boxSize={"40px"}
                          />
                          <Text
                            fontSize={"14px"}
                            fontWeight={600}
                            color={"var(--title-black-text)"}
                          >
                            {service.title}
                          </Text>
                        </HStack>
                        <Box>
                          <Text
                            fontSize={"12px"}
                            fontWeight={600}
                            color={"var(--title-black-text)"}
                          >
                            About this service
                          </Text>
                          <Text
                            color={"#5E6977"}
                            fontWeight={"400"}
                            fontSize="12px"
                            mt={"8px"}
                          >
                            {service?.aboutService}
                          </Text>
                          <Text
                            fontSize={"12px"}
                            fontWeight={600}
                            color={"var(--title-black-text)"}
                            mt={"24px"}
                          >
                            How it works
                          </Text>
                          <Text
                            color={"#5E6977"}
                            fontWeight={"400"}
                            fontSize="12px"
                            mt={"8px"}
                          >
                            {service?.howItWorks}
                          </Text>
                        </Box>
                      </Box>
                      <Box
                        border={"1px solid #E6E7E9"}
                        rounded={"8px"}
                        minW={"240px"}
                        overflow={"hidden"}
                      >
                        <Box
                          bg={"#F9FAFB"}
                          p={"12px"}
                          textAlign={"center"}
                          borderBottom={"1px solid #E6E7E9"}
                        >
                          <Text
                            fontSize={"16px"}
                            fontWeight={600}
                            color={"#0C94AC"}
                          >
                            {service?.credit} Credits
                          </Text>
                          <HStack spacing={"4px"} justifyContent={"center"}>
                            <Text
                              fontSize={"12px"}
                              fontWeight={500}
                              color={"#7E8792"}
                            >
                              Duration:
                            </Text>
                            <Text
                              fontSize={"12px"}
                              fontWeight={500}
                              color={"var(--title-black-text)"}
                              textTransform={
                                service.timeFrame ? "lowercase" : "capitalize"
                              }
                            >
                              {service.timeFrame || "NA"}
                            </Text>
                          </HStack>
                        </Box>
                        <Box p={"16px 20px"}>
                          {service && service.deliverables ? (
                            <>
                              <Text
                                color={"#7E8792"}
                                fontWeight={"400"}
                                fontSize="12px"
                                mb={"8px !important"}
                              >
                                Deliverables
                              </Text>

                              <WrapItemList
                                key={service.id}
                                servicelist={service.deliverables}
                              />
                            </>
                          ) : (
                            ""
                          )}
                          {service && service.exampleProjects ? (
                            <>
                              <Text
                                color={"#7E8792"}
                                fontWeight={"400"}
                                fontSize="12px"
                                mt={"24px"}
                                mb={"8px !important"}
                              >
                                Sample Projects
                              </Text>
                              <HStack spacing={"6px"}>
                                {service.exampleProjects.map(
                                  (project, index) => {
                                    return (
                                      <>
                                        {index !== 0 ? (
                                          <Text as={"span"} color={"#CEEAEE"}>
                                            &nbsp;|&nbsp;
                                          </Text>
                                        ) : (
                                          ""
                                        )}
                                        <Link
                                          to={project.link}
                                          target="_blank"
                                          fontSize={"12px"}
                                          fontWeight={500}
                                          color={"#0C94AC"}
                                        >
                                          {project.name}
                                        </Link>
                                      </>
                                    );
                                  }
                                )}
                              </HStack>
                            </>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Box>
                    </HStack>
                  </AccordionPanel>
                  <Box borderTop="1px solid #E6E7E9" p={"16px 20px"}>
                    <HStack spacing={"1rem"}>
                      {!isExpanded && (
                        <>
                          {/* {service.exampleProjects ? (
                            <>
                              <Text
                                color={"brandGray.200"}
                                fontWeight={"400"}
                                fontSize="md"
                              >
                                Sample Projects
                              </Text>
                              <Box ml={"1rem"}>
                                {service.exampleProjects.map(
                                  (project, index) => {
                                    return (
                                      <>
                                        {index !== 0 ? (
                                          <Text as={"span"}>&nbsp;|&nbsp;</Text>
                                        ) : (
                                          ""
                                        )}
                                        <Link to={project.link} target="_blank">
                                          {project.name}
                                        </Link>
                                      </>
                                    );
                                  }
                                )}
                              </Box>
                            </>
                          ) : null} */}

                          {service.links ? (
                            <>
                              <Text
                                color={"brandGray.200"}
                                fontWeight={"400"}
                                fontSize="md"
                              >
                                Sample Links:
                              </Text>
                              {service.links.map((serviceLink, index) => {
                                return (
                                  <HStack spacing={2} key={index + "-image"}>
                                    <Image
                                      src={serviceLink.logo}
                                      fallbackSrc={fallbackImage}
                                      boxSize={"24px"}
                                    />
                                    <Text>Video {index + 1}</Text>
                                  </HStack>
                                );
                              })}
                            </>
                          ) : null}
                        </>
                      )}
                      <Flex alignItems={"baseline"} gap={"8px"}>
                        <Text
                          fontSize={"12px"}
                          fontWeight={600}
                          color={"var(--chakra-colors-atlantic-700)"}
                        >
                          Select Quantity
                        </Text>
                        <Text
                          fontSize={"12px"}
                          fontWeight={450}
                          color={"var(--chakra-colors-atlantic-700)"}
                        >
                          (1 Qty = {service?.credit} Credits)
                        </Text>
                        <QuantityModifier
                          service={service}
                          quantity={serviceQuantity}
                          onIncrement={increaseServiceQuantity}
                          onDecrement={decreaseServiceQuantity}
                        />
                        <Text
                          fontSize={"12px"}
                          fontWeight={600}
                          color={"var(--chakra-colors-atlantic-700)"}
                          ml={"8px"}
                        >
                          Total Credits:
                        </Text>
                        <Text
                          fontSize={"12px"}
                          fontWeight={600}
                          color={"var(--chakra-colors-cyprus-500)"}
                        >
                          {serviceQuantity?.find(
                            (qty) => qty.id === service?.id
                          )?.quantity * service?.credit}
                        </Text>
                      </Flex>
                      <Spacer />
                      <HStack spacing={4}>
                        {isExpanded && (
                          <AccordionButton
                            w={"auto"}
                            p={0}
                            _hover={{ bg: "none" }}
                          >
                            <HStack
                              spacing={"4px"}
                              cursor={"pointer"}
                              onClick={() => onViewDetailClick(service.id)}
                            >
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M6.26672 2.97052C6.50379 2.93547 6.74835 2.91667 7.00024 2.91667C9.97816 2.91667 11.9323 5.54449 12.5887 6.58398C12.6682 6.70979 12.7079 6.7727 12.7302 6.86973C12.7469 6.94259 12.7468 7.05755 12.7301 7.13042C12.7079 7.22744 12.6679 7.29077 12.5879 7.41741C12.413 7.69425 12.1463 8.08331 11.793 8.50527M3.92252 3.9171C2.66131 4.77266 1.8051 5.9613 1.41231 6.58308C1.3325 6.70942 1.29259 6.7726 1.27035 6.86961C1.25364 6.94248 1.25363 7.05742 1.27033 7.13029C1.29257 7.22731 1.33229 7.29022 1.41174 7.41602C2.06823 8.45551 4.02232 11.0833 7.00024 11.0833C8.20098 11.0833 9.23526 10.6561 10.0851 10.078M1.75024 1.75L12.2502 12.25M5.76281 5.76256C5.44612 6.07925 5.25024 6.51675 5.25024 7C5.25024 7.9665 6.03374 8.75 7.00024 8.75C7.48349 8.75 7.92099 8.55412 8.23768 8.23744"
                                  stroke="#5E6977"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <Text
                                fontSize={"14px"}
                                fontWeight={500}
                                color={"#5E6977"}
                              >
                                Hide Details
                              </Text>
                            </HStack>
                          </AccordionButton>
                        )}
                        <Button
                          title="Buy Now"
                          isDisabled={!(permissions.add && permissions.edit)}
                          onClick={() => {
                            onBuyNow(service);
                          }}
                          variant={buttonVariants.brandPrimary}
                          id={`coachbar-service-buy-now-${service.id}`}
                        />
                      </HStack>
                    </HStack>
                  </Box>
                </Box>
              )}
            </AccordionItem>
          );
        })}
      </Accordion>
    </>
  );
};

export default CoachbarServiceList;
