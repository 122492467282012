export const requiredField = "$Field$ is required";
export const invalidEmail = "Please enter a valid business email address.";
export const passwordMisMatch = "Passwords do not match.";
export const lowerCaseLetter = "A lowercase letter";
export const upperCaseLetter = "A uppercase letter";
export const specialCharacter = "A special character (#?!@$%^&*-)";
export const passwordMinLength = "Minimum 8 characters";
export const passwordMaxLength = "Maximum 16 characters";
export const maxFileSize = "Max file size allowed is $MB$";
export const fileTypeAllowed =
  "Allowed file types: .png, .jpeg (.jpg), .svg, .pdf, .gif, .tiff";
export const invalidUrl = "Please enter a valid URL";
export const minYearAllowed = "Minimum value: $Year$";
export const maxYearAllowed = "Maximum value: $Year$";
export const numberAllowed = "Please enter a numeric value.";
export const mediaTypeAllowed =
  "Allowed file types: .png, .jpeg (.jpg), .svg, .pdf, .gif, .tiff";
export const excelTypeAllowed = "Allowed file types: .xls, .xlsx, .csv";
export const whiteSpace = "White space is not allowed";
