import { axiosInstance } from "../../../services/axiosInstance";
import { handleError } from "../../../services/utility";

export const getLitmusData = async (partnerId) => {
  try {
    const { data } = await axiosInstance.get(
      `/sp/litmos/user/training/${partnerId}/?userType=1`
    );
    return data;
  } catch (error) {
    // return custom error message from API if any
    handleError(error);
  }
};
