import { createSlice } from "@reduxjs/toolkit";
import { initialLeadFormState } from "@coachbar/shared-components/src/constants/customForm";

const initialState = {
  activeSubMenu: undefined,
  formProperties: initialLeadFormState,
  inputList: initialLeadFormState,
  existingData: undefined,
};

const customFormSlice = createSlice({
  name: "customForm",
  initialState,
  reducers: {
    updateFormProperties: (state, { payload }) => {
      state.formProperties = payload;
    },
    updateFullInputList: (state, { payload }) => {
      state.inputList = payload;
    },
    updateExistingData: (state, { payload }) => {
      state.existingData = payload;
    },
    updateFormProperty: (state, { payload }) => {
      const { id, changes } = payload;
      const index = state.formProperties.findIndex(
        (property) => property.id === id
      );
      if (index !== -1) {
        state.formProperties[index] = {
          ...state.formProperties[index],
          ...changes,
        };
      }
    },
    updateInputList: (state, { payload }) => {
      const { id, changes } = payload;
      const index = state.inputList.findIndex((property) => property.id === id);
      if (index !== -1) {
        state.inputList[index] = {
          ...state.inputList[index],
          ...changes,
        };
      }
    },
    bulkUpdateAddedProperty: (state, { payload }) => {
      payload.forEach((update) => {
        const { id, added } = update;
        const index = state.formProperties.findIndex(
          (property) => property.id === id
        );
        if (index !== -1) {
          state.formProperties[index].added = added;
        }
      });
    },
    updateActiveSubMenu: (state, { payload }) => {
      state.activeSubMenu = payload;
    },
  },
  extraReducers: {},
});

export const {
  updateFormProperties,
  updateFormProperty,
  bulkUpdateAddedProperty,
  updateInputList,
  updateFullInputList,
  updateActiveSubMenu,
  updateExistingData,
} = customFormSlice.actions;

export default customFormSlice.reducer;
