import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Progress,
  Spacer,
  Text,
  useDisclosure,
  useNumberInput,
} from "@chakra-ui/react";
import { Button } from "@coachbar/shared-components";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Notification from "../../components/Notification";
import puzzlePiece from "../../image/puzzle-piece.png";
import { convertDate, getUserInfo, setUserInfo } from "../../services/utility";
import { setUserData } from "../auth/authSlice";
import {
  getSubscriptionDetails,
  purchaseAddOnCredits,
} from "./subscriptionActions";

const DEFAULT_CREDIT = 5;

const AddonCredits = (props) => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const [addOnAmount, setAddOnAmount] = useState(props.addOnAmount);
  const [addOnCredits, setAddOnCredits] = useState(DEFAULT_CREDIT);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [rotation, setRotation] = useState(0);
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: 1,
      min: 1,
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  const onStepperBtnClick = () => {
    let purchaseQuantity = getInputProps().value;
    let creditCount = purchaseQuantity * DEFAULT_CREDIT;
    let creditPurchaseAmount = purchaseQuantity * props.addOnAmount;
    setAddOnCredits(creditCount);
    setAddOnAmount(creditPurchaseAmount);
  };

  const buyCredit = () => {
    let requestParams = {
      addOnCredits: addOnCredits,
    };
    setIsLoading(true);
    purchaseAddOnCredits(requestParams).then((response) => {
      if (
        response &&
        response.data &&
        response.data.success &&
        response.data.data
      ) {
        setSuccess(response.data.message);
        let userDetails = getUserInfo();
        userDetails["subscriptionStore"] = response.data.data.subscriptionStore;
        setUserInfo(userDetails);
        dispatch(setUserData());
      } else {
        setError(response);
      }
      setIsLoading(false);
      onClose();
    });
  };

  const clearMessage = () => {
    setError(null);
    setSuccess(null);
  };

  const { allocatedCredits, usedCredits, expiredCredits } =
    props?.subscriptionDetails || {};
  const { remainingCredits, creditPercentage, progressColor } = useMemo(() => {
    const totalCredits = allocatedCredits || 0;
    const creditsUsed = usedCredits || 0;
    const creditsExpired = expiredCredits || 0;
    const remaining = totalCredits - (creditsUsed + creditsExpired);
    const percentage = (remaining / totalCredits) * 100;

    let color;
    if (percentage >= 70) {
      color = "green"; // Green - #37AA73
    } else if (percentage >= 50) {
      color = "yellow"; // Yellow - #F4C500
    } else {
      color = "red"; // Red - #F05252
    }

    return {
      remainingCredits: remaining,
      creditPercentage: percentage,
      progressColor: color,
    };
  }, [allocatedCredits, usedCredits]);

  const handleRefresh = () => {
    const intervalId = setInterval(() => {
      setRotation((prevRotation) => prevRotation + 1);
    }, 5);

    getSubscriptionDetails().then((response) => {
      if (response) {
        clearInterval(intervalId);
        setRotation(0);
      } else {
        clearInterval(intervalId);
        setRotation(0);
      }
    });
  };

  return (
    <>
      {error || success ? (
        <Notification
          title={error ? error : success}
          status={error ? "error" : "success"}
          onCloseToast={clearMessage}
        />
      ) : (
        ""
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader p={"16px 24px"} alignItems={"center"} display={"flex"}>
            <Image src={puzzlePiece} h={"16px"} mr={"8px"} />
            <Text
              color={"var(--title-black-text)"}
              fontSize={"16px"}
              fontWeight={600}
            >
              Buy Add-On Credits
            </Text>
          </ModalHeader>
          <ModalCloseButton color={"#7E8792"} top={"10px"} right={"20px"} />
          <ModalBody p={"20px 24px"}>
            <Text
              color={"var(--title-black-text)"}
              fontSize={"14px"}
              fontWeight={400}
            >
              Are you sure you want to buy {addOnCredits} credits ? You will be
              charged <b>${addOnAmount}</b> amount.
            </Text>
          </ModalBody>
          <ModalFooter borderTop={"1px solid #DEE8F8"} p={"16px 24px"}>
            <Text
              color="#5E6977"
              fontSize={"14px"}
              fontWeight={600}
              mr={"20px"}
              p={"8px 16px"}
              onClick={onClose}
              cursor={"pointer"}
            >
              Cancel
            </Text>
            <Button
              border={"none"}
              bg={"#0C94AC"}
              color={"white"}
              w={"fit-content"}
              variant="newThemePrimary"
              rounded={"4px"}
              isLoading={isLoading}
              fontSize={"14px"}
              fontWeight={600}
              title={"Buy Credits"}
              onClick={buyCredit}
              id="subscription-buy-credits"
            />
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box
        border={"1px solid #E6E7E9"}
        mt={"24px"}
        rounded={"4px"}
        overflow={"hidden"}
      >
        <Box p={"24px"}>
          <HStack
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            mb={"40px"}
          >
            <Box>
              <Text
                fontSize={"14px"}
                fontWeight={600}
                color={"var(--title-black-text)"}
              >
                Account Plan Usage
              </Text>
              <Text
                fontSize={"12px"}
                fontWeight={400}
                color={"#5E6977"}
                mt={"8px"}
              >
                Valid Until:{" "}
                {convertDate(props?.subscriptionDetails?.validUntil)}
              </Text>
            </Box>
            <HStack cursor={"pointer"}>
              <Box transform={`rotate(${rotation}deg)`}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.6667 6.66667C14.6667 6.66667 13.33 4.84548 12.2441 3.75883C11.1582 2.67218 9.6576 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C10.7354 14 13.0433 12.1695 13.7655 9.66667M14.6667 6.66667V2.66667M14.6667 6.66667H10.6667"
                    stroke="#5E6977"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Box>
              <Text
                fontSize={"14px"}
                fontWeight={600}
                color={"#5E6977"}
                onClick={handleRefresh}
              >
                Refresh
              </Text>
            </HStack>
          </HStack>
          <Box>
            <HStack justifyContent={"space-between"} mb={"8px"}>
              <Text
                fontSize={"12px"}
                fontWeight={600}
                color={"var(--title-black-text)"}
              >
                Remaining Credits - {remainingCredits} /{" "}
                {props?.subscriptionDetails?.allocatedCredits}
              </Text>
              <Text fontSize={"12px"} fontWeight={500} color={"#5E6977"}>
                Total Credits: {props?.subscriptionDetails?.allocatedCredits}
              </Text>
            </HStack>
            <Progress
              size="sm"
              rounded={"30px"}
              bg={"#E6E7E9"}
              colorScheme={progressColor}
              value={creditPercentage}
            />
          </Box>
        </Box>
        <Box borderTop={"1px solid #E6E7E9"} p={"20px 24px"} bg={"#F9FAFB"}>
          <Flex>
            <Box>
              <HStack mb={"8px"}>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 3.33331C14 4.43788 11.3137 5.33331 8 5.33331C4.68629 5.33331 2 4.43788 2 3.33331M14 3.33331C14 2.22874 11.3137 1.33331 8 1.33331C4.68629 1.33331 2 2.22874 2 3.33331M14 3.33331V12.6666C14 13.7733 11.3333 14.6666 8 14.6666C4.66667 14.6666 2 13.7733 2 12.6666V3.33331M14 6.48012C14 7.58679 11.3333 8.48012 8 8.48012C4.66667 8.48012 2 7.58679 2 6.48012M14 9.62665C14 10.7333 11.3333 11.6266 8 11.6266C4.66667 11.6266 2 10.7333 2 9.62665"
                    stroke="#5E6977"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <Text
                  fontSize={"14px"}
                  fontWeight={600}
                  color={"var(--title-black-text)"}
                >
                  Buy More Credits
                </Text>
              </HStack>
              <Text fontSize={"12px"} fontWeight={400} color={"#5E6977"}>
                This allows you to add/buy more credits in the same Coachbar
                account.
              </Text>
            </Box>
            <Spacer />
            <HStack gap={"16px"}>
              <Text
                fontSize={"14px"}
                fontWeight={500}
                color={"var(--title-black-text)"}
              >
                Total Credits:
              </Text>
              <HStack>
                <IconButton
                  variant={"outline"}
                  border={"1px solid #E6E7E9"}
                  borderRight={"none"}
                  id="decrementCreditQuantity"
                  {...dec}
                  icon={<MinusIcon color={"#7E8792"} />}
                  onClick={onStepperBtnClick}
                />
                <Input
                  textAlign={"center"}
                  id="creditPurchaseCount"
                  p={0}
                  m={0}
                  marginInline={"0px !important"}
                  isReadOnly
                  width={"40px"}
                  height={"35px"}
                  borderRadius={"0px"}
                  {...input}
                  maxLength={1}
                />
                <IconButton
                  variant={"outline"}
                  id="incrementCreditQuantity"
                  marginInline={"0px !important"}
                  {...inc}
                  icon={<AddIcon color={"#7E8792"} />}
                  onClick={onStepperBtnClick}
                />
              </HStack>
              <Box h={"100%"} w={"1px"} bg={"#E6E7E9"}></Box>
              <HStack>
                <Text fontSize={"12px"} fontWeight={500} color={"#5E6977"}>
                  Subtotal:
                </Text>
                <Text
                  fontSize={"14px"}
                  fontWeight={500}
                  color={"var(--title-black-text)"}
                >
                  ${addOnAmount}
                </Text>
              </HStack>
              <Button
                title="Buy Now"
                variant="newColorThemePrimary"
                onClick={onOpen}
                isLoading={props.isLoading}
                id="subscription-buy-now"
              />
            </HStack>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default AddonCredits;
