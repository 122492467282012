import { Box, FormLabel, Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Modal } from "../..";
import DashboardDateFilter from "../../components/Charts/components/DashboardDateFilter";
import { scrollbarCSS } from "../../constants/constantValues";
import {
  filterLabels,
  filtersKeys,
  finManagerFilterForRevenueChart,
  partnerFilters,
} from "../../constants/dashboard";
import FiltersReactSelect from "./FiltersReactSelect";

const ChartFilter = ({
  isOpen,
  onClose,
  setFilterValues,
  filters,
  chartName,
  date,
  chartData,
  platformJson,
  userInfo,
  hideDate,
  ...props
}) => {
  const [selectedDate, setSelectedDate] = useState(date);
  const [selectedFilters, setSelectedFilters] = useState({});

  useEffect(() => {
    if (isOpen) {
      let newSelectedFilters = JSON.parse(JSON.stringify({ ...filters }));
      Object.keys(newSelectedFilters).forEach((key) => {
        newSelectedFilters[key] = filters[key]
          .filter((x) => x.isChecked)
          .map((x) => ({
            ...x,
            label: x.label,
            value: x.value,
          }));
      });
      setSelectedFilters(newSelectedFilters);
    }
  }, [isOpen, filters]);

  const onDateChange = (e) => {
    setSelectedDate(e);
  };

  const onFilterChange = (filterKey, newFilters) => {
    let newSelectedFilters = JSON.parse(JSON.stringify(selectedFilters));
    newSelectedFilters[filterKey] = newFilters;
    setSelectedFilters(newSelectedFilters);
  };

  const onSelectAll = (filterKey, newFilters) => {
    let newSelectedFilters = JSON.parse(JSON.stringify(selectedFilters));
    newSelectedFilters[filterKey] = newFilters;
    setSelectedFilters(newSelectedFilters);
  };

  const onApplyFilters = () => {
    let newFilters = JSON.parse(JSON.stringify(filters));
    Object.keys(selectedFilters).forEach((key) => {
      let values = selectedFilters[key].map((x) => x.value);
      newFilters[key] = filters[key].map((x) => ({
        ...x,
        isChecked: values.includes(x.value),
      }));
    });
    setFilterValues(newFilters, selectedDate);
    onClose();
  };

  return (
    <Modal
      title={`Filters: "${chartName}"`}
      isOpen={isOpen}
      onClose={onClose}
      displayFooter={true}
      allowCloseButton={true}
      onSaveClick={() => {
        onApplyFilters();
      }}
      saveButtonTitle="Submit"
      discardTitle="Cancel"
      size="3xl"
      moduleForId={`${
        typeof chartName === "string"
          ? chartName?.split(" ").join("-").toLowerCase()
          : ""
      }-chart-filter`}
      spDesign
    >
      <Box
        overflow={"auto"}
        maxH={"calc(100vh - 310px)"}
        px="8px"
        css={{
          ...scrollbarCSS,
          ".chakra-form__label": {
            fontWeight: "500",
            fontSize: "12px",
            lineHeight: "18px",
            color: "#5E6977",
            marginBottom: "4px !important",
            marginTop: "12px",
          },
          ".css-b62m3t-container": {
            margin: "0px !important",
          },
          ".date-filter": {
            margin: "0px !important",
          },
        }}
      >
        {!hideDate && (
          <Stack pos={"relative"}>
            <FormLabel>Period</FormLabel>
            <DashboardDateFilter
              bg="#FFF"
              color={"#111A29"}
              handleDateChange={onDateChange}
              selectedDateRange={selectedDate}
              showLastMonthData
              customPopoverTop="50px"
              removeDateOption={true}
              moduleForId="dashboard-chart-period"
            />
          </Stack>
        )}
        {Object.keys(filters).map((x, i) => {
          let showFilter = true;
          if (props.partnerId && partnerFilters.includes(x)) {
            showFilter = false;
          }
          if (
            finManagerFilterForRevenueChart.includes(chartData?.slug) &&
            userInfo?.role === "Manager" &&
            x === filtersKeys.partnerManagers
          ) {
            showFilter = false;
          }
          if (showFilter && filters[x].length > 0) {
            let label =
              x === "partners" ? platformJson.partnerTitle : filterLabels[x];
            return (
              <Stack>
                <FormLabel>{label}</FormLabel>
                <FiltersReactSelect
                  index={i}
                  filterKey={x}
                  name={label}
                  options={filters[x]}
                  selectedValues={selectedFilters?.[x] || []}
                  onSelect={onFilterChange}
                  onSelectAll={onSelectAll}
                />
              </Stack>
            );
          }
        })}
      </Box>
    </Modal>
  );
};

export default ChartFilter;
