import ReactCountryFlag from "react-country-flag";
import { getCountryIso2 } from "../../services/utility";

const CountryFlag = (props) => {
  let countryCode = "";
  if (props.country === "São Tomé and Príncipe") {
    countryCode = "ST";
  } else if (props.country === "Serbia") {
    countryCode = "RS";
  } else if (props.country === "Réunion") {
    countryCode = "RE";
  } else if (props.country === "Montenegro") {
    countryCode = "ME";
  } else if (props.country === "United States of America") {
    countryCode = "US";
  } else {
    countryCode = getCountryIso2(props.country);
  }

  return (
    <ReactCountryFlag
      countryCode={countryCode}
      style={{
        width: props?.width || "1.5em",
        height: props?.height || "1em",
      }}
      svg
    />
  );
};

export default CountryFlag;
