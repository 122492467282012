import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import {
  Box,
  Divider,
  Flex,
  Image,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  TableContainer,
  HStack,
  Menu,
  MenuButton,
  Button as ChakraButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  ACTION_ICON_BUTTON_TYPE,
  ActionIconButton,
  Button,
  Loader,
} from "@coachbar/shared-components";
import { useDispatch, useSelector } from "react-redux";
import sortingDown from "../../../image/sortingDown.svg";
import sortingUp from "../../../image/sortingUp.svg";
import sortingUpDown from "../../../image/sorting.svg";
import {
  getInvitationsList,
  getPartnerProgramDetails,
  getPartnerPrograms,
  getPartnerTier,
  invitationDelete,
  invitationUpdate,
} from "./invitationActions";
import CustomAlertDialog from "@coachbar/shared-components/src/components/AlertDialog/CustomAlertDialog";
import DateFilter from "@coachbar/shared-components/src/components/DateFilter/DateFilter";
import { setUserInfo } from "../../../services/utility";
import { setUserData } from "../../auth/authSlice";
import Notification from "../../../components/Notification";
import { clearMessage } from "./invitationSlice";
import { useRBAC } from "../../../services/customHook";
import { invitationsModule } from "../../../constants/moduleNames";
import ProfileNoRecordsFound from "../../../components/ProfileNoRecordsFound";
import {
  buttonVariants,
  menuItemStyles,
  scrollbarCSS,
  tableCss,
} from "@coachbar/shared-components/src/constants/constantValues";
import SearchBar from "@coachbar/shared-components/src/components/SearchBar/SearchBar";
import InvitationDetails from "./invitationDetail";
import checkVerified02Green from "@coachbar/shared-components/src/images/check-verified-03-green.svg";
import fileX02LightRed from "@coachbar/shared-components/src/images/file-x-02-light-red.svg";
import slashOctagonDarkRed from "@coachbar/shared-components/src/images/slash-octagon-dark-red.svg";
import alertHexagonBlue from "@coachbar/shared-components/src/images/alert-hexagon-blue.svg";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { StatusIcon } from "@coachbar/shared-components/src/images";

const INVITATION_STATUSES = {
  SENT: "Sent",
  ACCEPTED: "Accepted",
  REJECTED: "Rejected",
  EXPIRED: "Expired",
};

const EVENT_TYPES = {
  ACCEPT: "Accept",
  REJECT: "Reject",
  DELETE: "Delete",
};

const StatusTag = React.memo(({ status }) => {
  return (
    <HStack gap="8px" bg={"transparent"} p="4px 12px" borderRadius={"16px"}>
      <Image src={getIcon(status)} w="16px" h="16px" />
      <Text fontSize={"14px"} fontWeight="450" m="0px !important">
        {status}
      </Text>
    </HStack>
  );
});

export const getIcon = (status) => {
  switch (status) {
    case INVITATION_STATUSES.SENT:
      return alertHexagonBlue;
    case INVITATION_STATUSES.ACCEPTED:
      return checkVerified02Green;
    case INVITATION_STATUSES.EXPIRED:
      return fileX02LightRed;
    case INVITATION_STATUSES.REJECTED:
      return slashOctagonDarkRed;

    default:
      return alertHexagonBlue;
  }
};

const invitationStatusOptions = [
  { value: "all", label: "All Status" },
  { value: "Sent", label: "New" },
  { value: "Expired", label: "Expired" },
  { value: "Accepted", label: "Accepted" },
  { value: "Rejected", label: "Rejected" },
];

const Invitations = () => {
  const { onClose } = useDisclosure();
  const {
    // loading,
    error,
    success,
  } = useSelector((state) => state.invitations);
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const defaultDateRange = {
    to: "",
    from: "",
  };

  const [sorting, setSorting] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const tableContainerRef = useRef(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [refetch, setRefetch] = useState(false);
  const [bulkAction, setBulkAction] = useState("");
  const [filters, setFilters] = useState({
    selectedStatus: "all",
    date: defaultDateRange,
  });
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [eventType, setEventType] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  const [partnerProgramContent, setPartnerProgramContent] = useState(null);
  const [partnerProgramList, setPartnerProgramList] = useState([]);
  const [partnerTier, setPartnerTier] = useState("");
  const {
    isOpen: isDetailModal,
    onOpen: openDetailModal,
    onClose: closeDetailModal,
  } = useDisclosure();

  const {
    isOpen: isStatusMenuOpen,
    onOpen: onStatusMenuOpen,
    onClose: onStatusMenuClose,
  } = useDisclosure();

  const { hasActionPermission } = useRBAC();
  let moduleName = invitationsModule;
  let permissions = {
    add: hasActionPermission(moduleName, "add"),
    edit: hasActionPermission(moduleName, "edit"),
    view: hasActionPermission(moduleName, "view"),
    delete: hasActionPermission(moduleName, "delete"),
  };

  function IndeterminateCheckbox({ indeterminate, className = "", ...rest }) {
    const ref = React.useRef(null);

    useEffect(() => {
      if (typeof indeterminate === "boolean") {
        ref.current.indeterminate = !rest.checked && indeterminate;
      }
    }, [ref, indeterminate]);

    return (
      <input
        type="checkbox"
        ref={ref}
        className={className + " cursor-pointer"}
        {...rest}
      />
    );
  }

  const defaultColumns = useMemo(() => {
    let cols = [
      {
        id: "select",
        enableSorting: false,
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        id: "name",
        accessorKey: "spName",
        header: "Name",
      },
      {
        id: "email",
        accessorKey: "spEmail",
        header: "Email",
      },
      {
        id: "company",
        accessorKey: "spCompanyName",
        header: "Company",
      },
      {
        id: "date",
        accessorKey: "inviteDate",
        header: "Date Received",
        cell: ({ row }) => {
          return (
            <>
              {new Date(row.original.inviteDate)
                .toUTCString()
                .split(" ")
                .slice(0, 5)
                .join(" ")}
            </>
          );
        },
      },
      {
        id: "status",
        header: "Status",
        cell: ({ row }) =>
          Object.values(INVITATION_STATUSES).includes(
            row.original.inviteStatus
          ) && (
            <StatusTag
              status={
                row.original.inviteStatus === INVITATION_STATUSES.SENT
                  ? "New"
                  : row.original.inviteStatus
              }
            />
          ),
      },
    ];
    if (permissions.view || permissions.edit || permissions.delete) {
      cols.push({
        id: "actions",
        enableSorting: false,
        header: "",
        cell: ({ row }) => {
          const currInvitationStatus = row.original.inviteStatus;
          return (
            <Flex alignItems={"center"} gap="20px" justifyContent={"end"}>
              {permissions.view && (
                <ActionIconButton
                  type={ACTION_ICON_BUTTON_TYPE.PREVIEW}
                  onClickAction={() => {
                    setSelectedRecords([row]);
                    fetchPartnerProgramDetails([row]);
                    openDetailModal();
                  }}
                  tooltipLabel={"View"}
                />
              )}
              {permissions.edit &&
                currInvitationStatus === INVITATION_STATUSES.SENT && (
                  <React.Fragment>
                    <ActionIconButton
                      type={ACTION_ICON_BUTTON_TYPE.ACCEPT}
                      onClickAction={() => {
                        handleUpdateInviteRequest(
                          [row],
                          INVITATION_STATUSES.ACCEPTED
                        );
                      }}
                      containerProps={{ marginLeft: "0px !important" }}
                    />
                    <ActionIconButton
                      type={ACTION_ICON_BUTTON_TYPE.REJECT}
                      onClickAction={() => {
                        setSelectedRecords([row]);
                        setEventType(EVENT_TYPES.REJECT);
                      }}
                      containerProps={{ marginLeft: "0px !important" }}
                    />
                  </React.Fragment>
                )}
              {permissions.delete &&
                (currInvitationStatus === INVITATION_STATUSES.REJECTED ||
                  currInvitationStatus === INVITATION_STATUSES.EXPIRED) && (
                  <ActionIconButton
                    type={ACTION_ICON_BUTTON_TYPE.DELETE}
                    onClickAction={() => {
                      setSelectedRecords([row]);
                      setEventType(EVENT_TYPES.DELETE);
                    }}
                    containerProps={{ marginLeft: "0px !important" }}
                  />
                )}
            </Flex>
          );
        },
      });
    }

    return cols;
  }, [permissions]);

  let columns = [...defaultColumns];

  //react-query has an useInfiniteQuery hook just for this situation!
  const { data, fetchNextPage, isFetching } = useInfiniteQuery(
    ["table-data", refetch, filters], //adding status key causes table to fetch new data based on status and persist thos data.
    async ({ pageParam = 0 }) => {
      const fetchedData = await getInvitationsList({
        limit: 25,
        nextToken: nextToken,
        status: filters.selectedStatus,
        startDate: filters.date.from,
        endDate: filters.date.to,
      }); //pretend api call
      setLoading(false);
      if (fetchedData) {
        setNextToken(fetchedData.nextToken || null);
        return fetchedData.scInviteList;
      }
      return [];
    },
    {
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: 1000, // set cachtime so when user come back to particular tab it refetch the latest data.
    }
  );

  //we must flatten the array of arrays from the useInfiniteQuery hook
  const flatData = React.useMemo(() => {
    return data?.pages?.flatMap((page) => page) ?? [];
  }, [data]);

  const totalFetched = flatData.length;

  // called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        if (
          scrollHeight - scrollTop - clientHeight < 100 &&
          !isFetching &&
          nextToken
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched]
  );

  const table = useReactTable({
    data: flatData,
    columns,
    state: {
      sorting,
      globalFilter: globalFilterValue,
    },
    onSortingChange: setSorting,
    // this is to restrict search on specific fields
    getColumnCanGlobalFilter: (x) => {
      return x.id === "name" || x.id === "company" || x.id === "email";
    },
    onGlobalFilterChange: setGlobalFilterValue,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  let selectedRows = useMemo(() => {
    return table.getSelectedRowModel();
  }, [table.getSelectedRowModel()]);

  const refetchList = () => {
    setLoading(true);
    // setRefetch(!refetch);
    setRefetch((prevRefetch) => !prevRefetch);
  };

  const handleFilterChange = (filterType, event) => {
    let updatedFilters = { ...filters };
    if (filterType === "date") {
      updatedFilters.date = event.target.value;
    } else if (filterType === "selectedStatus") {
      updatedFilters.selectedStatus = event.target.value;
    }
    setLoading(true);
    setNextToken("");
    setFilters(updatedFilters);
  };

  const fetchPartnerProgramDetails = async (records) => {
    setContentLoading(true);
    const currInvitationDetail = records[0].original;
    const promiseArray = [
      getPartnerProgramDetails({
        id: currInvitationDetail.partnerProgram,
        tenantId: currInvitationDetail.spTenantId,
      }),
      getPartnerPrograms(currInvitationDetail.spTenantId),
    ];
    if (currInvitationDetail?.tier)
      promiseArray.push(
        getPartnerTier(
          currInvitationDetail.spTenantId,
          currInvitationDetail?.tier
        )
      );
    const [
      { data: partnerProgramDetailData },
      { data: partnerProgramListData },
      partnerTierRes,
    ] = await Promise.all(promiseArray);
    if (partnerProgramDetailData)
      setPartnerProgramContent(partnerProgramDetailData);

    if (partnerProgramListData)
      setPartnerProgramList(partnerProgramListData?.partnerProgram || []);
    if (partnerTierRes?.success) setPartnerTier(partnerTierRes.data);
    setContentLoading(false);
  };

  const handleUpdateInviteRequest = async (listOfUsers, status) => {
    setBtnLoading(true);
    let userList = [];
    let response = null;
    if (
      status === INVITATION_STATUSES.ACCEPTED ||
      status === INVITATION_STATUSES.REJECTED
    ) {
      userList = listOfUsers
        .filter((x) => x.original.inviteStatus === INVITATION_STATUSES.SENT)
        .map((x) => {
          return {
            status: status,
            id: x.original.id,
          };
        });
      response = await dispatch(
        invitationUpdate({
          email: userInfo.email,
          resendList: userList,
        })
      );
      if (status === INVITATION_STATUSES.ACCEPTED) setBulkAction("");
    } else if (status === EVENT_TYPES.DELETE) {
      response = await dispatch(
        invitationDelete({
          deleteList: listOfUsers
            .filter(
              (x) =>
                x.original.inviteStatus === INVITATION_STATUSES.REJECTED ||
                x.original.inviteStatus === INVITATION_STATUSES.EXPIRED
            )
            .map((x) => {
              return x.original.id;
            }),
        })
      );
    }

    setBtnLoading(false);
    setContentLoading(false);
    if (response && response.payload.code !== 200) return;
    if (status === INVITATION_STATUSES.ACCEPTED && !userInfo?.isGuestUser) {
      let updatedUserInfo = { ...userInfo };
      updatedUserInfo.isGuestUser = true;
      setUserInfo(updatedUserInfo);
      dispatch(setUserData());
    }
    onAlertClose(status);
    refetchList();
    table.resetRowSelection();
  };

  const onDateChange = (date) => {
    let updatedFilters = { ...filters };
    if (JSON.stringify(date) !== JSON.stringify(updatedFilters.date)) {
      updatedFilters.date = date;
      setLoading(true);
      setFilters(updatedFilters);
      setNextToken("");
    }
  };

  const handleSelectChange = async (e) => {
    setBulkAction(e.target.value);
    if (e.target.value === EVENT_TYPES.ACCEPT) {
      handleUpdateInviteRequest(
        selectedRows.rows,
        INVITATION_STATUSES.ACCEPTED
      );
    } else if (e.target.value === EVENT_TYPES.REJECT) {
      setEventType(EVENT_TYPES.REJECT);
      setSelectedRecords(selectedRows.rows);
    }
  };

  const onAlertClose = (status) => {
    onClose();
    if (isDetailModal) {
      if (
        status === INVITATION_STATUSES.REJECTED ||
        status === INVITATION_STATUSES.ACCEPTED
      )
        setSelectedRecords((prev) => {
          const updatedPrev = [...prev];
          updatedPrev[0].original.inviteStatus = status;
          return updatedPrev;
        });

      if (status === EVENT_TYPES.DELETE) {
        closeDetailModal();
        setSelectedRecords([]);
        setPartnerProgramContent(null);
        setPartnerProgramList([]);
        setPartnerTier("");
      }
    } else setSelectedRecords([]);
    setContentLoading(false);
    setEventType("");
    setBulkAction("");
  };

  const requestUpdateBody = () => {
    if (eventType === EVENT_TYPES.REJECT || eventType === EVENT_TYPES.DELETE) {
      return (
        <Text>
          Are you Sure You Want to {eventType}{" "}
          {bulkAction
            ? bulkAction === EVENT_TYPES.DELETE
              ? "selected rejected/expired Invitations"
              : "selected new Invitations"
            : "Invitation"}
          ?
        </Text>
      );
    }

    return "";
  };

  const getPartnerProgramNames = (text) => {
    const matches = text.match(/\[PP:(.*?)\]/g);
    return matches
      ? matches.map((match) => match.replace(/\[PP:|\]/g, ""))
      : [text];
  };

  const getPartnerProgramFilledData = (text, partnerPrograms) => {
    const regex = /\[PP:(.*?)\]/g;

    const matches = text.match(regex);
    const count = partnerPrograms?.length ?? 0;
    const items = partnerPrograms ?? [];

    if (matches && count > 0) {
      const formattedResult = matches.map((match) => {
        const programName = match.replace(/\[PP:|\]/g, "");
        const detail = items?.find((p) => p.programName === programName);
        if (detail) {
          return `<br><b>${detail.programName}</p><p>- ${detail.benefits}</p><br>`;
        } else {
          return "";
        }
      });
      return text
        .replace(regex, () => formattedResult.shift())
        .replaceAll(", ", "");
    } else {
      return text;
    }
  };

  // Function to replace placeholders with actual values in Email Body
  const replacePlaceholders = (body, partnerData) => {
    const emailBodyReplacements = {
      ApplicantName: `${partnerData?.scFirstName || ""} ${
        partnerData?.scLastName || ""
      }`,
      MyName: `${partnerData?.spName || ""}`,
      inviteUrl: `${
        partnerData?.scTenantId
          ? `${process.env.REACT_APP_API_BASE_URL}login?invitation=${partnerData?.id}`
          : `${process.env.REACT_APP_API_BASE_URL}register?invitation=${partnerData?.id}`
      }`,
      ApplicantCompanyName: `${partnerData?.scCompanyName || " "}`,
      MyCompanyName: `${partnerData?.spCompanyName || " "}`,
    };

    let replacedBody = body;

    if (replacedBody && replacedBody.includes("[PP:")) {
      const names = getPartnerProgramNames(replacedBody);
      if (names && names.length > 0) {
        replacedBody = getPartnerProgramFilledData(
          replacedBody,
          partnerProgramList
        );
      }
    }

    for (const [key, value] of Object.entries(emailBodyReplacements)) {
      const regex = new RegExp(`\\[${key}\\]`, "g");
      if (key === "inviteUrl") {
        // Wrap the invite URL in an anchor tag with blue color
        replacedBody = replacedBody.replace(regex, `<span>Click here</span>`);
      } else {
        replacedBody = replacedBody.replace(regex, value);
      }
    }
    return replacedBody;
  };

  return (
    <>
      {error || success ? (
        <Notification
          title={error ? error : success}
          status={error ? "error" : "success"}
          onCloseToast={() => dispatch(clearMessage())}
        />
      ) : (
        ""
      )}
      <Stack position={"relative"} p={"24px"} gap={"24px"}>
        <Flex
          justifyContent={"space-between"}
          w={"100%"}
          alignItems={"center"}
          mt={"0px !important"}
        >
          <Flex alignItems={"center"} gap={"20px"}>
            <SearchBar
              value={globalFilterValue}
              onChange={(value) => {
                setGlobalFilterValue(value);
              }}
              placeholder="Search Items..."
              height="36px"
            />
            <Divider
              borderRadius={"3px"}
              bgColor="#E6E7E9"
              width={"2.5px"}
              h="36px"
            />
            <DateFilter
              handleDateChange={onDateChange}
              selectedDateRange={defaultDateRange}
              moduleForId="provider-invitation"
              height="36px"
              showByDefaultAllTimeOption
            />
            <Box
              position={"relative"}
              bgColor={"var(--chakra-colors-white)"}
              zIndex={99}
            >
              <Menu
                isOpen={isStatusMenuOpen}
                onClose={onStatusMenuClose}
                onOpen={onStatusMenuOpen}
              >
                <MenuButton
                  border={"1px solid #E6E7E9"}
                  borderRadius={"4px"}
                  bg={"inherit"}
                  color={"#7E8792"}
                  fontWeight={500}
                  fontSize={"14px"}
                  p={"9px 12px"}
                  lineHeight={1}
                  h={"auto"}
                  as={ChakraButton}
                  rightIcon={
                    isStatusMenuOpen ? (
                      <ChevronUpIcon
                        color="var(--chakra-colors-brandGray-300)"
                        height={"16px"}
                        width="16px"
                      />
                    ) : (
                      <ChevronDownIcon
                        color="var(--chakra-colors-brandGray-300)"
                        height={"16px"}
                        width="16px"
                      />
                    )
                  }
                  leftIcon={
                    <StatusIcon
                      height={"16px"}
                      width={"16px"}
                      stroke={"var(--chakra-colors-brandGray-400)"}
                      fill={"var(--chakra-colors-white)"}
                    />
                  }
                  sx={{
                    _hover: {
                      bg: "white",
                    },
                  }}
                >
                  {invitationStatusOptions.find(
                    (option) => option.value === filters.selectedStatus
                  )?.label || invitationStatusOptions[0].label}
                </MenuButton>
                <MenuList
                  zIndex={"popover"}
                  border={"1px solid #E6E7E9"}
                  p={"4px"}
                  minW={"155px"}
                  w={"180px"}
                >
                  {invitationStatusOptions.map((status) => (
                    <MenuItem
                      {...menuItemStyles}
                      fontWeight={400}
                      bgColor={
                        status.value === filters.selectedStatus
                          ? "var(--chakra-colors-cyprus-50)"
                          : "var(--chakra-colors-white)"
                      }
                      onClick={() => {
                        handleFilterChange("selectedStatus", {
                          target: { value: status.value },
                        });
                      }}
                    >
                      {status.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Box>
          </Flex>
          <Flex justifyContent={"space-around"} height={"100%"}>
            {permissions.edit && (
              <Select
                width={"8.5rem"}
                size="md"
                value={bulkAction}
                onChange={handleSelectChange}
                ml="5"
                disabled={
                  !selectedRows.rows.some(
                    (x) => x.original.inviteStatus === INVITATION_STATUSES.SENT
                  )
                }
              >
                <option key="" value="">
                  Bulk Action
                </option>
                {[EVENT_TYPES.ACCEPT, EVENT_TYPES.REJECT].map((status) => {
                  return (
                    <option
                      key={status}
                      style={{ textTransform: "capitalize" }}
                      value={status}
                    >
                      {status}
                    </option>
                  );
                })}
              </Select>
            )}

            {permissions.delete && (
              <Button
                ml="3"
                variant={buttonVariants.dangerOutline}
                title="Delete in Bulk"
                onClick={() => {
                  setEventType(EVENT_TYPES.DELETE);
                  setBulkAction(EVENT_TYPES.DELETE);
                  setSelectedRecords(selectedRows.rows);
                }}
                isDisabled={
                  !selectedRows.rows.some(
                    (x) =>
                      x.original.inviteStatus ===
                        INVITATION_STATUSES.REJECTED ||
                      x.original.inviteStatus === INVITATION_STATUSES.EXPIRED
                  )
                }
                id="my-provider-invitation-details-accept"
              />
            )}
          </Flex>
        </Flex>
        {loading ? (
          <Box
            height={`calc(100vh - 255px)`}
            display={"flex"}
            justifyContent={"center"}
            borderRadius={"6px 6px 0px 0px"}
            border={"1px solid #E6E7E9"}
          >
            <Loader />
          </Box>
        ) : (
          <Box
            onScroll={(e) => fetchMoreOnBottomReached(e.target)}
            ref={tableContainerRef}
            overflow={"auto"}
            border={"1px solid #E2E8F0"}
            borderTopRadius={"6px"}
            style={{ maxHeight: `calc(100vh - 235px)` }}
            css={scrollbarCSS}
            mt={"0px !important"}
          >
            {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
              <TableContainer css={scrollbarCSS}>
                <Table variant="simple" css={tableCss}>
                  <Thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <Tr
                        h={"40px"}
                        bg={"#F5F5F5"}
                        key={headerGroup.id}
                        position={"sticky"}
                        top={0}
                        zIndex={10}
                      >
                        {headerGroup.headers.map((header) => (
                          <Th
                            key={header.id}
                            colSpan={header.colSpan}
                            width={header.width || ""}
                          >
                            {header.isPlaceholder ? null : (
                              <Box
                                display={"flex"}
                                justifyContent={"flex-start"}
                              >
                                <Text display={"flex"} alignItems={"center"}>
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                  {header.column.getCanSort() && (
                                    <Box
                                      width={"16px"}
                                      height={"16px"}
                                      minW={"16px"}
                                      cursor={"pointer"}
                                      ml="16px"
                                      onClick={header.column.getToggleSortingHandler()}
                                    >
                                      {{
                                        asc: (
                                          <Image
                                            src={sortingUp}
                                            width={"16px"}
                                            height={"16px"}
                                          />
                                        ),
                                        desc: (
                                          <Image
                                            src={sortingDown}
                                            width={"16px"}
                                            height={"16px"}
                                          />
                                        ),
                                      }[header.column.getIsSorted()] ??
                                        (header.column.getCanSort() ? (
                                          <Image
                                            src={sortingUpDown}
                                            width={"16px"}
                                            height={"16px"}
                                          />
                                        ) : null)}
                                    </Box>
                                  )}
                                </Text>
                              </Box>
                            )}
                          </Th>
                        ))}
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody>
                    {table.getRowModel().rows.map((row) => {
                      return (
                        <Tr key={row.id}>
                          {row.getVisibleCells().map((cell) => {
                            return (
                              <Td
                                key={cell.id}
                                color={"#111a29"}
                                fontSize={"14px"}
                                lineHeight={"20px"}
                                fontWeight={"500"}
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            ) : (
              <Box
                style={{
                  height: `calc(100vh - 255px)`,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ProfileNoRecordsFound title="No Users Found" />
              </Box>
            )}
          </Box>
        )}
      </Stack>

      <CustomAlertDialog
        isAlertOpen={selectedRecords.length > 0 && eventType}
        onAlertClose={onAlertClose}
        onAlertCloseAction={() => {
          switch (eventType) {
            case EVENT_TYPES.REJECT:
              handleUpdateInviteRequest(
                selectedRecords,
                INVITATION_STATUSES.REJECTED
              );
              break;
            case EVENT_TYPES.DELETE:
              handleUpdateInviteRequest(selectedRecords, EVENT_TYPES.DELETE);
              break;

            default:
              break;
          }
        }}
        body={requestUpdateBody()}
        actionButtonName={eventType || ""}
        actionButtonVarient={buttonVariants.dangerFill}
        title={`${eventType} Invitation`}
        loading={btnLoading}
      />
      <InvitationDetails
        isOpen={isDetailModal}
        isDetailModalLoading={contentLoading}
        data={selectedRecords?.[0]?.original}
        handleModalClose={() => {
          closeDetailModal();
          setPartnerProgramContent(null);
          setPartnerProgramList([]);
          setPartnerTier("");
          table.resetRowSelection();
        }}
        partnerProgramContent={partnerProgramContent}
        permissions={permissions}
        replacePlaceholders={replacePlaceholders}
        setEventType={setEventType}
        setSelectedRecords={setSelectedRecords}
        handleUpdateInviteRequest={handleUpdateInviteRequest}
        partnerTier={partnerTier}
      />
    </>
  );
};

export default Invitations;
