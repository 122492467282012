// Subscription.js
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Loader,
  RadioGroup,
  TextArea,
  Select,
  TipsLinkButton,
} from "@coachbar/shared-components";
import { useForm, Controller } from "react-hook-form";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Stack,
  HStack,
  Image,
  Text,
  Divider,
  Flex,
  Spacer,
  Box,
  useDisclosure,
  Center,
  Checkbox,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  planRoute,
  subscriptionRoute,
} from "../../constants/redirectionRoutes";
import {
  convertDate,
  deleteSubscriptionStorage,
  getDateString,
} from "../../services/utility";
import {
  cancelSubscription,
  confirmSubscription,
  getPaymentUpdateUrl,
  getSubscriptionDetails,
  reactivateSubscription,
} from "./subscriptionActions";
import { setUserData } from "../auth/authSlice";
import { setUserInfo, getUserInfo } from "../../services/utility";
import AddonCredits from "./AddonCredits";
import Notification from "../../components/Notification";
import NoData from "../../components/NoData";
import alertIcon from "../../image/alert-octagon.png";
import { buttonVariants } from "@coachbar/shared-components/src/constants/constantValues";
const ADDON_PRICE = [
  {
    planId: 2,
    amount: 2500,
  },
  {
    planId: 3,
    amount: 1750,
  },
  {
    planId: 4,
    amount: 1500,
  },
  {
    planId: 5,
    amount: 1250,
  },
  {
    planId: 6,
    amount: 2500,
  },
  {
    planId: 7,
    amount: 1750,
  },
  {
    planId: 8,
    amount: 1500,
  },
  {
    planId: 9,
    amount: 1250,
  },
  {
    planId: 10,
    amount: 2500,
  },
  {
    planId: 11,
    amount: 2500,
  },
];

const cancelReasonsOptions = [
  { label: "Pricing", value: "Pricing" },
  { label: "Business is closing", value: "Business is closing" },
  {
    label: "Didn't meet our expectations",
    value: "Didn't meet our expectations",
  },
  {
    label: "Going with a different provider",
    value: "Going with a different provider",
  },
  {
    label: "We need things Coachbar doesn't have",
    value: "We need things Coachbar doesn't have",
  },
];
const returnChancesOptions = [
  { title: "1", value: 1 },
  { title: "2", value: 2 },
  { title: "3", value: 3 },
  { title: "4", value: 4 },
  { title: "5", value: 5 },
  { title: "6", value: 6 },
  { title: "7", value: 7 },
  { title: "8", value: 8 },
  { title: "9", value: 9 },
  { title: "10", value: 10 },
];
const TIPS_LINK_PAGE_SUBSCRIPTION =
  "https://support.coachbar.io/support/solutions/articles/153000018988-getting-started-guide-software-consultant#My-Subscription";

const Subscription = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const subscriptionDetails = userInfo.subscriptionStore;
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const navigate = useNavigate();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isOpenReactivate, setIsOpenReactivate] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const customerId = searchParams.get("customerId");
  const productId = searchParams.get("productId");
  const signupPaymentId = searchParams.get("signupPaymentId");
  const subscriptionId = searchParams.get("subscriptionId");
  const getRef = searchParams.get("ref");

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onChange",
  });
  let initialize = false;

  useEffect(() => {
    if (!initialize) {
      initialize = true;
      getSubscriptionpageDetail();
    }
  }, []);

  useEffect(() => {
    if (customerId && productId && signupPaymentId && subscriptionId) {
      confirmSubscriptionPlan();
    }
    if (getRef && getRef === "update-card") {
      getSubscriptionpageDetail();
    }
  }, []);

  const getSubscriptionpageDetail = () => {
    setSubscriptionLoading(true);
    getSubscriptionDetails().then((response) => {
      if (
        response &&
        response.data &&
        response.data.success &&
        response.data.data
      ) {
        let userDetails = getUserInfo();
        userDetails["subscriptionStore"] = response.data.data.subscriptionStore;
        setUserInfo(userDetails);
        dispatch(setUserData());
      }
      if (getRef && getRef === "update-card") {
        navigate(subscriptionRoute);
      }
      setSubscriptionLoading(false);
    });
  };

  const confirmSubscriptionPlan = async () => {
    let requestData = {
      customerId: customerId,
      productId: productId,
      signupPaymentId: signupPaymentId,
      subscriptionId: subscriptionId,
      planId: JSON.parse(localStorage.getItem("planId")),
    };
    const response = await confirmSubscription(requestData);
    if (
      response &&
      response.success &&
      response.data &&
      response.data.subscriptionStore
    ) {
      let userDetails = getUserInfo();
      userDetails["planId"] = requestData.planId;
      userDetails["subscriptionStore"] = response.data.subscriptionStore;
      setUserInfo(userDetails);
      localStorage.setItem("roadmapStatus", "false");
      dispatch(setUserData());
      deleteSubscriptionStorage();
      navigate(subscriptionRoute);
    }
  };

  const rediretToPlan = () => {
    navigate(planRoute);
  };

  const updateCard = () => {
    setIsLoading(true);
    getPaymentUpdateUrl().then((response) => {
      if (
        response &&
        response.data &&
        response.data.success &&
        response.data.data
      ) {
        let updateUrl = response.data.data.paymentUpdateURL;
        window.open(updateUrl, "_blank");
      }
      setIsLoading(false);
    });
  };

  const handleDescriptionChange = (event) => {
    setDescriptionLength(event.target.value.length);
  };

  const submitForm = (data) => {
    if (data.confirmCancel) {
      let requestData = {
        reasons: [data.reasons],
        returnChances: data.returnChances,
        feedback: data.description,
      };
      setIsLoading(true);
      cancelSubscription(requestData)
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.data
          ) {
            let userDetails = getUserInfo();
            userDetails["subscriptionStore"] =
              response.data.data.subscriptionStore;
            setUserInfo(userDetails);
            dispatch(setUserData());
            setSuccess(response.data.message);
          } else {
            setError(response);
          }
          setIsLoading(false);
          onClose();
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const clearMessage = () => {
    setError(null);
    setSuccess(null);
  };

  const subscriptionReactivate = () => {
    setIsLoading(true);
    reactivateSubscription()
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.success &&
          response.data.data
        ) {
          let userDetails = getUserInfo();
          userDetails["subscriptionStore"] =
            response.data.data.subscriptionStore;
          setUserInfo(userDetails);
          dispatch(setUserData());
          setSuccess(response.data.message);
        } else {
          setError(response);
        }
        setIsLoading(false);
        setIsOpenReactivate(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  let addOnPricePoint = ADDON_PRICE.filter((addOnData) => {
    return userInfo.planId === addOnData.planId;
  });

  return (
    <>
      {error || success ? (
        <Notification
          title={error ? error : success}
          status={error ? "error" : "success"}
          onCloseToast={clearMessage}
        />
      ) : (
        ""
      )}
      <Modal
        isOpen={isOpenReactivate}
        onClose={() => setIsOpenReactivate(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            p={"16px 24px"}
            color={"var(--title-black-text)"}
            fontSize={"16px"}
            fontWeight={600}
          >
            Reactivate Subscription
          </ModalHeader>
          <ModalCloseButton color={"#7E8792"} top={"10px"} right={"20px"} />
          <ModalBody p={"20px 24px"}>
            <Text
              color={"var(--title-black-text)"}
              fontSize={"14px"}
              fontWeight={400}
            >
              Are you sure you want to reactivate the subscription.
            </Text>
          </ModalBody>
          <ModalFooter borderTop={"1px solid #DEE8F8"} p={"16px 24px"}>
            <Button
              mr={"20px"}
              onClick={() => setIsOpenReactivate(false)}
              title="Cancel"
              variant={buttonVariants.greyLinkButton}
              id="subscription-reactivate-cancel"
            />
            <Button
              isLoading={isLoading}
              title={"Reactivate Subscription"}
              onClick={subscriptionReactivate}
              variant={buttonVariants.brandPrimary}
              id="subscription-reactivate"
            />
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            alignItems={"center"}
            display={"flex"}
            p={"16px 24px"}
            borderBottom={"1px solid #DEE8F8"}
          >
            <Image src={alertIcon} h={"16px"} mr={"8px"} />
            <Text
              color={"var(--title-black-text)"}
              fontSize={"14px"}
              fontWeight={600}
            >
              Cancel Subscription
            </Text>
          </ModalHeader>
          <ModalCloseButton color={"#7E8792"} top={"10px"} right={"20px"} />
          <ModalBody p={"20px 24px"}>
            <form onSubmit={handleSubmit(submitForm)} noValidate>
              <FormControl
                isRequired
                isInvalid={errors?.reasons}
                pos={"relative"}
              >
                <Controller
                  name="reasons"
                  control={control}
                  isRequired
                  render={({ field: { onChange, value } }) => (
                    <Select
                      name="Please share your reason for leaving"
                      value={value}
                      placeholder="Please select a reason"
                      options={cancelReasonsOptions}
                      onChange={onChange}
                      style={{ paddingInlineStart: "0.5rem" }}
                      innerref={register("reasons", {
                        required: "Please select a reason.",
                      })}
                    />
                  )}
                />
                <FormErrorMessage pos={"absolute"} bottom={0}>
                  {errors?.reasons?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isRequired
                isInvalid={errors?.returnChances}
                pos={"relative"}
              >
                <FormLabel
                  as="legend"
                  fontSize={"12px"}
                  fontWeight={600}
                  color={"#5E6977"}
                  mb={"4px"}
                >
                  How likely are you to return?
                </FormLabel>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <RadioGroup
                      boxDesign={true}
                      onChange={onChange}
                      value={value}
                      childList={returnChancesOptions}
                      innerref={register("returnChances", {
                        required: "Please select at least one option.",
                      })}
                    />
                  )}
                  isRequired={true}
                  name="returnChances"
                  control={control}
                />
                <FormErrorMessage pos={"absolute"} top={"100%"}>
                  {errors?.returnChances?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl mt={"20px"}>
                <FormLabel
                  textTransform={"capitalize"}
                  as="legend"
                  fontSize={"12px"}
                  fontWeight={600}
                  color={"#5E6977"}
                  mb={"4px"}
                >
                  Any other feedback you had like to share?
                </FormLabel>
                <TextArea
                  hideLabel={true}
                  innerref={register("description", {
                    onChange: (e) => handleDescriptionChange(e),
                  })}
                  maxLength={1000}
                  helperText={`${descriptionLength}/1000`}
                  helperTextPosition={"right"}
                  validationErrors={errors}
                />
              </FormControl>

              <FormControl
                isRequired
                isInvalid={errors?.confirmCancel}
                mt={"20px"}
              >
                <HStack spacing={0} alignItems={"flex-start"}>
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        pt={"3px"}
                        size="md"
                        value={value}
                        // name={item.name}
                        // isChecked={item.isChecked}
                        onChange={onChange}
                        innerref={register("confirmCancel", {
                          required:
                            "Please confirm the subscription cancellation.",
                        })}
                      ></Checkbox>
                    )}
                    name="confirmCancel"
                    control={control}
                  />
                  <Text
                    pl={"8px"}
                    color={"#5E6977"}
                    fontWeight={"400"}
                    fontSize={"12px"}
                  >
                    I understand that by proceeding, I will lose access to all
                    my storage and all premium features associated with my
                    account(These actions will only take effect on the date my
                    subscription expires)
                  </Text>
                </HStack>
                <FormErrorMessage>
                  {errors?.confirmCancel?.message}
                </FormErrorMessage>
              </FormControl>
            </form>
          </ModalBody>
          <ModalFooter borderTop={"1px solid #DEE8F8"} p={"16px 24px"}>
            <Button
              onClick={onClose}
              variant={buttonVariants.greyLinkButton}
              title="Cancel"
              mr={"20px"}
              id="subscription-cancellation-cancel"
            />
            <Button
              isLoading={isLoading}
              title={"Confirm Cancellation"}
              onClick={handleSubmit(submitForm)}
              variant={buttonVariants.brandPrimary}
              id="subscription-cancellation-confirm"
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
      {subscriptionLoading ? (
        <Loader />
      ) : (
        <>
          <Box p={"24px"}>
            <Box
              bg={"#fff"}
              boxShadow="0px 0px 4px 0px #E6E7E9"
              rounded={"8px"}
              p={"24px"}
            >
              <Flex alignItems={"center"}>
                <Text
                  fontSize={"16px"}
                  fontWeight={700}
                  color={"var(--title-black-text)"}
                >
                  Subscription Details
                </Text>
                <Spacer />
                <TipsLinkButton to={TIPS_LINK_PAGE_SUBSCRIPTION} />
              </Flex>
              <Box
                p={"24px"}
                mt={"24px"}
                border={"1px solid #E6E7E9"}
                rounded={"4px"}
              >
                {subscriptionDetails ? (
                  <>
                    <Flex justifyContent={"space-between"} mb={"40px"}>
                      <HStack>
                        <Text
                          color={"var(--title-black-text)"}
                          fontSize={"20px"}
                          fontWeight={600}
                        >
                          {userInfo.planId === 1
                            ? "Free"
                            : subscriptionDetails.planName}
                        </Text>
                        <Text
                          border={`1px solid ${
                            subscriptionDetails?.status === "Cancelled"
                              ? "#FDE8E8"
                              : "#D7EEE3"
                          }`}
                          bg={
                            subscriptionDetails?.status === "Cancelled"
                              ? "#FDF2F2"
                              : "#EBF6F1"
                          }
                          color={
                            subscriptionDetails?.status === "Cancelled"
                              ? "#C81E1E"
                              : "#216645"
                          }
                          p={"3px 12px"}
                          fontSize={"12px"}
                          fontWeight={600}
                          rounded={"16px"}
                          textTransform={"capitalize"}
                        >
                          {userInfo.planId === 1
                            ? "Active"
                            : subscriptionDetails.status}
                        </Text>
                      </HStack>
                      <Flex gap={"16px"}>
                        {subscriptionDetails.status &&
                        subscriptionDetails.status.toLowerCase() ===
                          "cancelled" &&
                        userInfo.planId !== 1 ? (
                          <Button
                            onClick={() => setIsOpenReactivate(true)}
                            title="Resume Subscription"
                            variant={buttonVariants.brandPrimary}
                            id="subscription-resume"
                          />
                        ) : (
                          ""
                        )}

                        {subscriptionDetails.status &&
                        subscriptionDetails.status.toLowerCase() === "active" &&
                        userInfo.planId !== 1 ? (
                          <Button
                            onClick={onOpen}
                            title="Cancel Subscription"
                            variant={buttonVariants.greyOutline}
                            id="subscription-cancel"
                          />
                        ) : (
                          ""
                        )}

                        {subscriptionDetails?.status?.toLowerCase() !==
                          "cancelled" &&
                          userInfo.planId < 10 && (
                            <Button
                              type="submit"
                              title="Upgrade Plan"
                              variant={buttonVariants.brandPrimary}
                              onClick={rediretToPlan}
                              id="subscription-upgrade-plan"
                            />
                          )}
                      </Flex>
                    </Flex>
                    <HStack gap={"100px"}>
                      {/* {subscriptionDetails.status.toLowerCase() ===
                        "cancelled" && (
                        <Box>
                          <Text
                            fontSize={"12px"}
                            color={"#7E8792"}
                            fontWeight={600}
                          >
                            Cancelled Date
                          </Text>
                          <Text
                            fontSize={"16px"}
                            fontWeight={600}
                            color={"#111A29"}
                            mt={"8px"}
                          >
                            ${subscriptionDetails.totalAmount}
                          </Text>
                        </Box>
                      )} */}
                      <Box>
                        <Text
                          fontSize={"12px"}
                          color={"#7E8792"}
                          fontWeight={600}
                        >
                          Start Date
                        </Text>
                        <Text
                          fontSize={"16px"}
                          fontWeight={600}
                          color={"var(--title-black-text)"}
                          mt={"8px"}
                        >
                          {userInfo.planId === 1
                            ? convertDate(userInfo.created)
                            : convertDate(subscriptionDetails.planStartDate)}
                        </Text>
                      </Box>
                      {subscriptionDetails.validUntil && (
                        <Box>
                          <Text
                            fontSize={"12px"}
                            color={"#7E8792"}
                            fontWeight={600}
                          >
                            Valid Until
                          </Text>
                          <Text
                            fontSize={"16px"}
                            fontWeight={600}
                            color={"var(--title-black-text)"}
                            mt={"8px"}
                          >
                            {subscriptionDetails.validUntil
                              ? convertDate(subscriptionDetails.validUntil)
                              : "-"}
                          </Text>
                        </Box>
                      )}
                      {subscriptionDetails.planAmount &&
                        subscriptionDetails.status.toLowerCase() !==
                          "cancelled" && (
                          <Box>
                            <Text
                              fontSize={"12px"}
                              color={"#7E8792"}
                              fontWeight={600}
                            >
                              Next Billing Amount
                            </Text>
                            <Flex
                              alignItems={"baseline"}
                              mt={"8px"}
                              gap={"4px"}
                            >
                              <Text
                                fontSize={"16px"}
                                fontWeight={600}
                                color={"var(--title-black-text)"}
                              >
                                ${subscriptionDetails?.planAmount}
                              </Text>
                              <Text
                                fontSize={"12px"}
                                fontWeight={600}
                                color={"#7E8792"}
                              >
                                / Year
                              </Text>
                            </Flex>
                          </Box>
                        )}
                      {subscriptionDetails.validUntil &&
                        subscriptionDetails.status !== "Cancelled" && (
                          <Box>
                            <Text
                              fontSize={"12px"}
                              color={"#7E8792"}
                              fontWeight={600}
                            >
                              Renew On
                            </Text>
                            <Text
                              fontSize={"16px"}
                              fontWeight={600}
                              color={"var(--title-black-text)"}
                              mt={"8px"}
                            >
                              {subscriptionDetails.status &&
                              subscriptionDetails.validUntil &&
                              subscriptionDetails.status !== "Cancelled"
                                ? convertDate(subscriptionDetails.validUntil)
                                : "-"}
                            </Text>
                          </Box>
                        )}
                      {subscriptionDetails.status === "Cancelled" && (
                        <Box>
                          <Text
                            fontSize={"12px"}
                            color={"#7E8792"}
                            fontWeight={600}
                          >
                            Cancelled Date
                          </Text>
                          <Text
                            fontSize={"16px"}
                            fontWeight={600}
                            color={"var(--title-black-text)"}
                            mt={"8px"}
                          >
                            {subscriptionDetails.status &&
                            subscriptionDetails.planCancelDate &&
                            subscriptionDetails.status === "Cancelled"
                              ? convertDate(subscriptionDetails.planCancelDate)
                              : "-"}
                          </Text>
                        </Box>
                      )}
                    </HStack>

                    {subscriptionDetails.status.toLowerCase() !==
                      "cancelled" && (
                      <>
                        {subscriptionDetails?.billingInfo ? (
                          <AddonCredits
                            subscriptionDetails={subscriptionDetails}
                            addOnAmount={
                              addOnPricePoint.length > 0
                                ? addOnPricePoint[0]?.amount
                                : "NA"
                            }
                          />
                        ) : null}

                        {subscriptionDetails?.billingInfo ? (
                          <BillingInfo
                            billingDetail={subscriptionDetails?.billingInfo}
                            onUpdateCard={updateCard}
                            isLoading={isLoading}
                          />
                        ) : null}
                      </>
                    )}
                  </>
                ) : (
                  <NoData description="No Subscription detail found" />
                )}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

function BillingInfo(props) {
  return (
    <Flex
      border={"1px solid #E6E7E9"}
      p={"20px 24px"}
      alignItems={"center"}
      rounded={"4px"}
      mt={"24px"}
    >
      <Box>
        <Text
          fontSize={"14px"}
          fontWeight={600}
          color={"var(--title-black-text)"}
        >
          Payment Method
        </Text>
        <Text fontSize={"12px"} fontWeight={400} color={"#5E6977"}>
          Manage your default payment method
        </Text>
      </Box>
      <Spacer />
      <HStack mr={"80px"}>
        <Box id="billing-card-block" mr={"10px"}>
          {props.billingDetail?.cardType}
        </Box>
        <Box>
          <Text
            fontSize={"14px"}
            fontWeight={600}
            color={"var(--title-black-text)"}
          >
            {props.billingDetail?.maskedCardNumber}
          </Text>
          <Text fontSize={"12px"} fontWeight={400} color={"#5E6977"}>
            Expires: {props.billingDetail?.cardExpirationDate}
          </Text>
        </Box>
      </HStack>
      <Button
        title="Update Card"
        onClick={props.onUpdateCard}
        isLoading={props.isLoading}
        variant={buttonVariants.brandPrimaryOutline}
        id="subscription-update-card"
      />
    </Flex>
  );
}

export default Subscription;
