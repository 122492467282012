import { useState, useEffect } from "react";
import { Stack, HStack, Text, Box, Image, Flex } from "@chakra-ui/react";
import coachBarIntegrationImage from "../../../image/coachbar-integration.svg";
import hubSpotintegrationImage from "../../../image/hubspot-integratio.svg";
import Select from "react-select";
import { getSyncedReferralsList } from "./hubSpotAction";
import NoData from "../../../components/NoData";
import { Loader, Table } from "@coachbar/shared-components";
import { referralStatusList } from "../../../constants/referralStatus";
import fallbackImage from "../../../image/fallbackImage.png";
import SearchBar from "@coachbar/shared-components/src/components/SearchBar/SearchBar";
import DateFilter from "@coachbar/shared-components/src/components/DateFilter/DateFilter";
const coachbarStatusList = [...referralStatusList];
coachbarStatusList.shift();
const tableHead = [
  [
    "Sync Source",
    "Contact name",
    "Email",
    "Company",
    "last updated date",
    "Status",
  ],
];

const limit = 5;
const HubSpotOverViewTab = (props) => {
  const statusOption = coachbarStatusList.map((status) => {
    return {
      value: status,
      label: status === "Inprocess" ? "In Process" : status,
    };
  });
  statusOption.unshift({ value: "AllStatus", label: "All statuses" });

  const defaultDateRange = {
    to: "",
    from: "",
  };

  const [filterDateRange, setFilterDateRange] = useState(defaultDateRange);
  const [syncedReferralsList, setSyncedReferralsList] = useState([]);
  const [filterResetBackup, setFilterResetBackup] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedOption, setSelectedOption] = useState(statusOption[0]);
  const [searchInput, setSearchInput] = useState("");
  const [tableBody, setTableBody] = useState([]);

  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      let requestData = {
        limit,
        startDate: filterDateRange.from,
        endDate: filterDateRange.to,
      };
      if (selectedOption && selectedOption.value !== "AllStatus") {
        requestData.status = selectedOption.value;
      }
      setLoading(true);
      setSyncedReferralsList([]);
      setFilterResetBackup([]);
      getReferralList(requestData);
    }
  }, [selectedOption, filterDateRange]);

  const getReferralList = async (queryParam) => {
    const response = await getSyncedReferralsList(queryParam);
    if (response && response.success) {
      if (response.data && response.data.contactList) {
        const updatedContactList = response.data.contactList.map((item) => {
          if (item.status && item.status.toLowerCase() === "inprocess") {
            return { ...item, status: "In Process" };
          }
          return item;
        });
        setSyncedReferralsList((prevData) => {
          let updatedReferralData = [...prevData, ...updatedContactList];
          if (searchInput) {
            return updatedReferralData.filter((item) =>
              Object.values(item).some((val) =>
                val.toString().toLowerCase().includes(searchInput.toLowerCase())
              )
            );
          } else {
            return updatedReferralData;
          }
        });
        setFilterResetBackup((prevData) => [
          ...prevData,
          ...response.data.contactList,
        ]);
        setNextToken(response.data.nextToken);
        setLoading(false);
      }
    } else {
      setError(response.message ? response.message : "Something Went Wrong!");
      setLoading(false);
    }
  };

  useEffect(() => {
    let tBody = [];
    for (let index = 0; index < syncedReferralsList.length; index++) {
      let referral = [];
      referral.push(getSource(syncedReferralsList[index]["source"]));
      referral.push(syncedReferralsList[index]["contactName"]);
      referral.push(syncedReferralsList[index]["email"]);
      referral.push(syncedReferralsList[index]["companyName"]);
      referral.push(syncedReferralsList[index]["modified"]);
      referral.push(syncedReferralsList[index]["status"]);
      tBody.push(referral);
    }
    setTableBody([...tBody]);
  }, [syncedReferralsList]);

  const getSource = (source) => {
    let sourceUrl =
      source === "Hubspot" ? hubSpotintegrationImage : coachBarIntegrationImage;
    return (
      <HStack spacing={1}>
        <Image
          src={sourceUrl}
          alt={"Client Logo"}
          // objectFit="contain"
          fallbackSrc={fallbackImage}
          boxSize="20px"
        />
        <Text minW={"70px"}>
          {(source === "Hubspot" ? "HubSpot" : "Coachbar") || "Coachbar"}
        </Text>
      </HStack>
    );
  };

  const handleSearchInput = (event) => {
    const inputValue = event?.target?.value;
    if (inputValue) {
      const filteredData = filterResetBackup.filter((item) =>
        Object.values(item).some((val) =>
          val.toString().toLowerCase().includes(inputValue?.toLowerCase())
        )
      );
      setSyncedReferralsList(filteredData);
    } else {
      setSyncedReferralsList(filterResetBackup);
    }
    setSearchInput(inputValue);
  };

  const handleDateChange = (range) => {
    setFilterDateRange(range);
  };

  const handleLoadMore = () => {
    let requestData = {
      limit,
      startDate: filterDateRange.from,
      endDate: filterDateRange.to,
      nextToken,
    };
    if (selectedOption && selectedOption.value !== "AllStatus") {
      requestData.status = selectedOption.value;
    }
    getReferralList(requestData);
  };

  return (
    <Stack spacing={2} m="5" align="stretch">
      <Stack w={"50%"}>
        {/* <HStack spacing={8} justifyContent="space-between">
          <Text
            fontSize={"1.125rem"}
            lineHeight={"50px"}
            fontWeight={"400"}
            color={"brandGray.200"}
          >
            Connected Account :
          </Text>
          <Text
            fontSize={"1.125rem"}
            lineHeight={"50px"}
            fontWeight={"400"}
            color={"brand.100"}
          >
            SC HubSpot Account
          </Text>
        </HStack> */}
        <HStack spacing={8} justifyContent="space-between">
          <Text
            fontSize={"1.125rem"}
            lineHeight={"50px"}
            fontWeight={"400"}
            color={"brandGray.200"}
          >
            Integration Status:
          </Text>
          <Text
            fontSize={"1.125rem"}
            lineHeight={"50px"}
            fontWeight={"400"}
            color={"brand.100"}
          >
            {props.integration.active ? "Active" : "InActive"}
          </Text>
        </HStack>
        <HStack spacing={8} justifyContent="space-between">
          <Text
            fontSize={"1.125rem"}
            lineHeight={"50px"}
            fontWeight={"400"}
            color={"brandGray.200"}
          >
            Connected:
          </Text>
          <Text
            fontSize={"1.125rem"}
            lineHeight={"50px"}
            fontWeight={"400"}
            color={"brand.100"}
          >
            {props.integration.created}
          </Text>
        </HStack>

        <HStack spacing={8} justifyContent="space-between">
          <Text
            fontSize={"1.125rem"}
            lineHeight={"50px"}
            fontWeight={"400"}
            color={"brandGray.200"}
          >
            Last Synced:
          </Text>
          <Text
            fontSize={"1.125rem"}
            lineHeight={"50px"}
            fontWeight={"400"}
            color={"brand.100"}
          >
            {props.integration?.lastAccessedRecord?.lastReferralToDate || "-"}
          </Text>
        </HStack>
      </Stack>
      <Flex
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={"15px !important"}
      >
        <Text fontSize={18} color={"brand.100"} fontWeight={600}>
          Data Synced
        </Text>
        <Flex alignItems={"center"} gap={"8px"}>
          <Box minW={"160px"}>
            <DateFilter
              handleDateChange={handleDateChange}
              selectedDateRange={defaultDateRange}
              moduleForId="hubspot-overview-tab"
              height="36px"
              showByDefaultAllTimeOption
            />
          </Box>
          <Box minW={"160px"}>
            <Select
              options={statusOption}
              value={selectedOption}
              onChange={setSelectedOption}
              defaultValue={"AllStatus"}
              placeholder="All Statuses"
              isSearchable={true}
            />
          </Box>

          <SearchBar
            value={searchInput}
            onChange={(value) => {
              setSearchInput(value);
            }}
            onSearchChangeOperations={handleSearchInput}
            placeholder="Search"
          />
        </Flex>
      </Flex>
      <Stack border={"1px solid #E2E8F0"} borderRadius={"6px"}>
        {loading ? (
          <Loader />
        ) : syncedReferralsList?.length > 0 ? (
          <>
            <Table tHead={tableHead} tBody={tableBody} />
            {nextToken && (
              <Text
                m={"10px auto !important"}
                cursor={"pointer"}
                color={"brand.50"}
                onClick={() => handleLoadMore()}
              >
                Load More Data
              </Text>
            )}{" "}
          </>
        ) : (
          <NoData description={"No synced data found."} />
        )}
      </Stack>
    </Stack>
  );
};

export default HubSpotOverViewTab;
