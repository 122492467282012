// coachbarServicesSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  addCoachbarService,
  updateCoachbarService,
  getCoachbarServiceList,
  getCoachbarServicePurchasedList,
  deleteCoachbarService,
} from "./coachbarServicesActions";

const initialState = {
  loading: false,
  error: null,
  success: null,
  coachbarServiceList: [],
  coachbarServicePurchasedList: [],
};

const coachbarServicesSlice = createSlice({
  name: "coachbarService",
  initialState,
  reducers: {
    clearMessage: (state) => {
      state.success = null;
      state.error = null;
    },
    setError: (state, action) => {
      let errorMsg = action.payload;
      state.error = errorMsg;
    },
    setSuccess: (state, action) => {
      let successMsg = action.payload;
      state.success = successMsg;
    },
  },
  extraReducers: {
    // add coachbar services
    [addCoachbarService.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addCoachbarService.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [addCoachbarService.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // get coachbar service list
    [getCoachbarServiceList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCoachbarServiceList.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
      if (payload.data && payload.success) {
        state.coachbarServiceList = payload.data?.coachbarServiceList || [];
      }
    },
    [getCoachbarServiceList.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // get coachbar service purchased list
    [getCoachbarServicePurchasedList.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCoachbarServicePurchasedList.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
      if (payload.data && payload.success) {
        state.coachbarServicePurchasedList =
          payload.data?.purchasedServiceList || [];
      }
    },
    [getCoachbarServicePurchasedList.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // update coachbar services
    [updateCoachbarService.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateCoachbarService.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [updateCoachbarService.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    [deleteCoachbarService.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteCoachbarService.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [deleteCoachbarService.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
  },
});

export const { setError, setSuccess, clearMessage } =
  coachbarServicesSlice.actions;

export default coachbarServicesSlice.reducer;
