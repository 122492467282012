import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";

const useWebSocket = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const socketURL = `${process.env.REACT_APP_SOCKET_URL}?tenantId=${userInfo?.tenantId}&userId=${userInfo?.id}&authToken=${process.env.REACT_APP_SOCKET_AUTHTOKEN}-${userInfo?.id}`;
  const [socket, setSocket] = useState(null);
  const [socketResponse, setSocketResponse] = useState(null);

  const onOpen = () => {
    console.log("==> WebSocket connected");
  };

  const onClose = () => {
    console.log("==> WebSocket disconnected");
    setSocketResponse(null);
  };

  const onMessage = (event) => {
    setSocketResponse(JSON.parse(event.data));
  };

  const connectSocket = useCallback(() => {
    if (!socket || socket.readyState !== WebSocket.CLOSED) {
      const newSocket = new WebSocket(socketURL);
      newSocket.addEventListener("message", onMessage);
      newSocket.addEventListener("open", onOpen);
      newSocket.addEventListener("close", onClose);
      setSocket(newSocket);
    }
  }, [socket, socketURL]);

  const disConnectSocket = () => {
    if (socket) {
      socket.close();
      setSocket(null);
      onClose();
    }
  };

  useEffect(() => {
    const socketInterval = setInterval(() => {
      socket && socket?.close();
      setSocket(null);
      connectSocket();
    }, 10 * 60 * 1000); // Reconnect every 10 minutes

    // Clean up the interval on component unmount
    return () => clearInterval(socketInterval);
  }, [connectSocket, socket]);

  return { connectSocket, disConnectSocket, socketResponse, socket };
};

export default useWebSocket;
