import { Box, Center } from "@chakra-ui/react";
import Pie from "@coachbar/shared-components/src/components/Charts/Pie";
import ChartContainer from "@coachbar/shared-components/src/screens/dashboard/ChartContainer";
import ChartGrid from "@coachbar/shared-components/src/screens/dashboard/ChartGrid";
import ChartHeader from "@coachbar/shared-components/src/screens/dashboard/ChartHeader";
import { useChartsProvider } from "@coachbar/shared-components/src/screens/dashboard/chartContext";
import {
  calculateDaysBetweenDates,
  compareInnerAndGeneralFilters,
  formatDateRange,
  getAppliedFilters,
  getPreviousDateRange,
  getSpecificInnerFilters,
} from "@coachbar/shared-components/src/services/dashboardUtility/utility";

import { Loader } from "@coachbar/shared-components";
import NoDataChart from "@coachbar/shared-components/src/screens/dashboard/NoDataChart";
import { deepCopyObject } from "@coachbar/shared-components/src/services/utility";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getLeadsData } from "../../dashboardActions";

const Leads = ({ slug, chartData }) => {
  const { selectedDate, partnerId, appliedFilters, fixFilters } =
    useChartsProvider();

  let initialized = false;
  const widgetInfo = chartData;
  const [isLoading, setIsLoading] = useState(false);
  const [chartType, setChartType] = useState("");
  const [leadData, setLeadData] = useState([]);
  const [prevLeadData, setPrevLeadData] = useState([]);
  const [leadsTotal, setLeadsTotal] = useState(null);
  const [prevLeadsTotal, setPrevLeadsTotal] = useState(null);
  const [prevDateRange, setPrevDateRange] = useState(undefined);
  const [daysBetweenDates, setDaysBetweenDates] = useState(undefined);
  const [innerFilters, setInnerFilters] = useState(
    getSpecificInnerFilters(fixFilters, chartData.filters)
  );
  const [innerDate, setInnerDate] = useState({});

  const [
    formattedDateForGrid,
    formattedPrevDateForGrid,
    formattedDateForChart,
  ] = useMemo(() => {
    let conDate = deepCopyObject(
      innerDate?.to && innerDate?.from ? innerDate : selectedDate
    );
    // Date format to show in Grid
    const date = formatDateRange(conDate || null, "grid");
    const prevDate = formatDateRange(prevDateRange || null, "grid");
    // Different date format to show in chart tooltip
    const formattedDate = formatDateRange(conDate || null, "chart");
    return [date, prevDate, formattedDate];
  }, [innerDate, selectedDate, prevDateRange]);

  const formattedLeadDataForGrid = useMemo(
    () =>
      leadData.map((data) => {
        const prevData = prevLeadData?.find((prev) => prev.id === data.id);
        return {
          id: data.id,
          value: data.value,
          previousValue: !prevData ? undefined : prevData.value,
        };
      }),
    [leadData, prevLeadData]
  );

  let innerAppliedFilters = useMemo(() => {
    return getAppliedFilters(innerFilters);
  }, [innerFilters]);

  const constructRequestData = ({ date }) => {
    let requestData = compareInnerAndGeneralFilters(
      appliedFilters,
      innerAppliedFilters,
      chartData.filters,
      date,
      selectedDate
    );
    if (partnerId) {
      requestData.partners = [partnerId];
    }
    delete requestData?.type;
    delete requestData?.partnerManagers;
    return requestData;
  };

  const onToogleCompare = () => {
    let conDate = deepCopyObject(
      innerDate?.to && innerDate?.from ? innerDate : selectedDate
    );
    const previousDateRange = getPreviousDateRange(conDate);
    const numberOfDays = calculateDaysBetweenDates(conDate);
    setDaysBetweenDates(numberOfDays);
    setPrevDateRange(previousDateRange);
    const requestData = constructRequestData({ date: previousDateRange });
    fetchLeadData(requestData, true);
  };

  const fetchLeadData = useCallback(async (requestData, isCompared = false) => {
    setIsLoading(true);
    const leadRes = await getLeadsData(requestData);
    const fetchedLeadData = leadRes?.data?.dashboard;
    setIsLoading(false);

    if (fetchedLeadData) {
      const accepted = {
        id: "Accepted",
        label: "Accepted",
        value: fetchedLeadData["accepted"],
        color: "#5FBB8F",
      };

      const rejected = {
        id: "Rejected",
        label: "Rejected",
        value: fetchedLeadData["rejected"],
        color: "#F98080",
      };

      let pending;
      if (
        fetchedLeadData["accepted"] + fetchedLeadData["rejected"] !==
        fetchedLeadData["total"]
      ) {
        pending = {
          id: "Pending",
          label: "Pending",
          value:
            fetchedLeadData["total"] -
            (fetchedLeadData["accepted"] + fetchedLeadData["rejected"]),
          color: "#FBE899",
        };
      }

      const preparedLeadData = pending
        ? [accepted, rejected, pending]
        : [accepted, rejected];

      if (isCompared) {
        setPrevLeadData(preparedLeadData);
        setPrevLeadsTotal(
          fetchedLeadData?.total === 0 ? null : fetchedLeadData?.total
        );
      } else {
        setLeadData(preparedLeadData);
        setLeadsTotal(
          fetchedLeadData?.total === 0 ? null : fetchedLeadData?.total
        );
      }
    }
  }, []);

  // useEffect(() => {
  //   setInnerDate(deepCopyObject(selectedDate));
  // }, [selectedDate]);

  useEffect(() => {
    if (!initialized) {
      initialized = true;
      setPrevLeadData([]);
      setPrevLeadsTotal(null);
      setDaysBetweenDates(null);
      const requestData = constructRequestData({ date: innerDate });
      fetchLeadData(requestData);
    }
  }, [
    fetchLeadData,
    innerDate,
    selectedDate,
    appliedFilters,
    innerAppliedFilters,
    partnerId,
  ]);

  return (
    <>
      {widgetInfo?.isVisible && (
        <ChartContainer>
          <ChartHeader
            title={widgetInfo?.title}
            showAll
            chartData={chartData}
            chartType={chartType}
            onChartTypeChange={() => {
              if (chartType === "") {
                setChartType("table");
              } else {
                setChartType("");
              }
            }}
            onToogleCompare={() => {
              if (prevLeadData?.length > 0) {
                setPrevLeadData([]);
                setPrevLeadsTotal(null);
                setPrevDateRange(null);
                setDaysBetweenDates(null);
              } else {
                onToogleCompare();
              }
            }}
            isCompared={prevLeadData?.length > 0 ? true : false}
            filters={innerFilters}
            onSubmitFilters={(newFilters, date) => {
              setInnerFilters(newFilters);
              setInnerDate(date);
            }}
            date={innerDate}
            generalDate={selectedDate}
          />
          {isLoading ? (
            <Center h={"80%"}>
              <Loader />
            </Center>
          ) : !prevLeadsTotal && !leadsTotal ? (
            <Box h={"150px"}>
              <NoDataChart />
            </Box>
          ) : chartType === "table" ? (
            <ChartGrid
              data={formattedLeadDataForGrid}
              headings={
                prevLeadData?.length > 0
                  ? [["Status", formattedDateForGrid, formattedPrevDateForGrid]]
                  : [["Status", formattedDateForGrid]]
              }
            />
          ) : (
            <Box h={"280px"}>
              <Pie
                data={leadData}
                prevData={prevLeadData}
                total={leadsTotal}
                selectedDate={formattedDateForChart}
                daysBetweenDates={daysBetweenDates}
              />
            </Box>
          )}
        </ChartContainer>
      )}
    </>
  );
};

export default Leads;
