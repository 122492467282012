// CompanyLocation.js
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import {
  Box,
  Flex,
  HStack,
  Image,
  Spacer,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ACTION_ICON_BUTTON_TYPE,
  ActionIconButton,
  AlertDialog,
  Button,
  FileUpload,
  Loader,
  Modal,
  Table,
  TableTextWithEllipsis,
  TextArea,
  TextBox,
} from "@coachbar/shared-components";
import CustomTooltip from "@coachbar/shared-components/src/components/Tooltip/CustomTooltip";
import {
  buttonVariants,
  scrollbarCSS,
} from "@coachbar/shared-components/src/constants/constantValues";
import { useDispatch } from "react-redux";
import ProfileNoRecordsFound from "../../../components/ProfileNoRecordsFound";
import { partnerStatusTip } from "../../../constants/tooltips";
import {
  maxFileSize,
  mediaTypeAllowed,
  requiredField,
} from "../../../constants/validationMessages";
import InfoOutline from "../../../image/Info-outline.svg";
import pencilIcon from "../../../image/edit-icon-pencil-grey.svg";
import emptyMedia from "../../../image/emptyMedia.png";
import fallbackImage from "../../../image/fallbackImage.png";
import trashIcon from "../../../image/trash-outline-grey.svg";
import {
  getPreSignedUrl,
  notification,
  uploadImage,
} from "../../../services/utility";
import { getProfileCompletionStatus } from "../profileMetrics/profileMetricsAction";
import {
  addPartnerStatus,
  deletePartnerStatus,
  editPartnerStatus,
  getListOfPartnerStatus,
} from "./partnerStatusActions";

const PLATFORM_LABEL = "Description";
const LISTING_LABEL = "Title";
const MEDIA_LABEL = "Asset";
const tableHead = [[MEDIA_LABEL, LISTING_LABEL, PLATFORM_LABEL]];
const MAX_FILE_SIZE_MEDIA = 1000; // in KB;
const fieldType = "media-type";

const PartnerStatus = ({ permissions }) => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const {
    onOpen: onAlertOpen,
    isOpen: isAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [partnerStatusList, setPartnerStatusList] = useState([]);
  const [tableBody, setTableBody] = useState([]);
  const [mediaImagePath, setMediaImagePath] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [descriptionLength, setDescriptionLength] = useState(0);
  const [selectedData, setSelectedData] = useState(null);
  const [readMoreData, setReadMoreData] = useState({});

  const {
    register,
    handleSubmit,
    resetField,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({ mode: "onChange" });

  let initialized = false;
  useEffect(() => {
    if (!initialized) {
      initialized = true;
      fetchPartnerStatus();
    }
  }, []);

  useEffect(() => {
    let tBody = [];
    for (let index = 0; index < partnerStatusList.length; index++) {
      let directoryItem = [];
      directoryItem.push(
        getImage(
          partnerStatusList[index]["thumbnailUrl"]
            ? partnerStatusList[index]["thumbnailUrl"]
            : partnerStatusList[index]["url"]
        )
      );
      directoryItem.push(
        <TableTextWithEllipsis
          maxChars={25}
          text={partnerStatusList[index]["title"]}
        />
      );
      directoryItem.push(
        getDescription(
          partnerStatusList[index]["description"],
          partnerStatusList[index]
        )
      );
      if (permissions.edit || permissions.delete)
        directoryItem.push(getActions(partnerStatusList[index], index));
      tBody.push(directoryItem);
    }
    setTableBody([...tBody]);
    //eslint-disable-next-line
  }, [partnerStatusList, readMoreData, permissions]);

  const fetchPartnerStatus = async () => {
    setLoading(true);
    await getListOfPartnerStatus().then((res) => {
      setLoading(false);
      if (res?.code === 200) {
        setPartnerStatusList(res?.data?.partnerStatusList || []);
      }
    });
  };

  const editMedia = (event, mediaData) => {
    let skipKeys = ["modified", "created", "id", "tenantId"];
    setSelectedData(mediaData);
    setMediaImagePath(mediaData?.url);
    for (const key in mediaData) {
      if (mediaData.hasOwnProperty(key) && !skipKeys.includes(key)) {
        if (mediaData?.description) {
          setDescriptionLength(mediaData?.description.length);
        }
        setValue(key, mediaData[key]);
      }
    }
    onOpen();
  };

  const validateMedia = (value) => {
    if ((!value || value.length < 1) && !mediaImagePath) {
      return requiredField.replace("$Field$", "Media");
    }
    return validateFile(value);
  };

  const validateFile = (files) => {
    const fileObj = files && files[0];

    if (!fileObj || !fileObj?.type) {
      return true;
    }

    const [type, subType] = fileObj.type.split("/");
    if (!type || (type !== "image" && subType !== "pdf")) {
      return mediaTypeAllowed;
    }

    const fsMb = fileObj.size / 1024;

    if (fsMb > MAX_FILE_SIZE_MEDIA) {
      setValue("url", "");
      return maxFileSize.replace("$MB$", MAX_FILE_SIZE_MEDIA + "KB");
    }
    if (!isSubmitting) {
      uploadFile(fileObj);
    }
    return true;
  };

  const uploadFile = (fileObj) => {
    setIsLoading(true);
    let fileExt = fileObj.name.split(".").pop();
    let filePath = fieldType + "-" + Date.now() + "." + fileExt;
    getPreSignedUrl(filePath).then((responseData) => {
      if (responseData.data && responseData.data.success) {
        const preSignedUrl = responseData.data?.data?.signedUrl;
        uploadImageFileToS3(fileObj, preSignedUrl);
      } else {
        setIsLoading(false);
      }
    });
  };

  const uploadImageFileToS3 = async (fileObj, preSignedUrl) => {
    uploadImage(fileObj, preSignedUrl).then((responseData) => {
      if (responseData.status === 200) {
        let imageUrl = preSignedUrl.split("?")[0];
        setMediaImagePath(imageUrl);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const onAlertOpenAction = (event, mediaDetails) => {
    setSelectedData(mediaDetails);
    onAlertOpen();
  };

  const onSuccess = (res) => {
    notification(res.message);
    setSelectedData(null);
    onModalClose();
    onAlertClose();
    fetchPartnerStatus();
  };

  const handleError = (errors) => {
    if (errors && errors["url"]) {
      resetField("url");
      handleSubmit(submitForm)();
    }
  };

  const submitForm = async (data) => {
    data["url"] = mediaImagePath;
    setLoading(true);

    try {
      if (selectedData) {
        editPartnerStatus(selectedData.id, data).then((res) => {
          setLoading(false);
          if (res?.code === 200) {
            onSuccess(res);
          }
        });
      } else {
        await addPartnerStatus(data).then((res) => {
          setLoading(false);
          if (res?.code === 200) {
            onSuccess(res);
            dispatch(getProfileCompletionStatus());
          }
        });
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      setLoading(true);
      onAlertClose();
      await deletePartnerStatus(selectedData.id).then((res) => {
        if (res?.code === 200) {
          onSuccess(res);
          dispatch(getProfileCompletionStatus());
        }
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const onModalClose = () => {
    setSelectedData(null);
    setMediaImagePath("");
    setDescriptionLength(0);
    reset();
    onClose();
  };

  const onAlertCloseAction = () => {
    onDelete();
  };

  const handleDescriptionChange = (event) => {
    setDescriptionLength(event.target.value.length);
  };

  const onReadMoreClick = (data) => {
    let newReadMoreData = { ...readMoreData };
    if (newReadMoreData[data.id]) {
      delete newReadMoreData[data.id];
    } else {
      newReadMoreData[data.id] = true;
    }
    setReadMoreData(newReadMoreData);
  };

  const getDescription = (desc, data) => {
    if (desc) {
      let wrapedDesc = desc;
      if (desc.length > 92 && !readMoreData[data.id]) {
        wrapedDesc = desc.slice(0, 92) + "...";
      }
      return (
        <span style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
          {wrapedDesc}{" "}
          {desc.length > 0 && (
            <span
              style={{ cursor: "pointer", color: "#0C94AC" }}
              onClick={() => onReadMoreClick(data)}
            >
              {desc.length > 92 &&
                (readMoreData[data.id] ? `Read Less` : `Read More`)}
            </span>
          )}
        </span>
      );
    } else {
      return "";
    }
  };

  const getWebsiteName = (siteName) => (
    <Text
      fontSize={"14px"}
      lineHeight={"20px"}
      fontWeight={500}
      color={"var(--title-black-text)"}
    >
      {siteName}
    </Text>
  );

  const getActions = (mediaData = null, index) => {
    return (
      <HStack key={"HStack-" + index} float="right" gap={"16px"}>
        {permissions.edit && (
          <ActionIconButton
            type={ACTION_ICON_BUTTON_TYPE.EDIT}
            onClickAction={(e) => {
              editMedia(e, mediaData);
            }}
          />
        )}
        {permissions.delete && (
          <ActionIconButton
            type={ACTION_ICON_BUTTON_TYPE.DELETE}
            onClickAction={(e) => {
              onAlertOpenAction(e, mediaData);
            }}
            containerProps={{ marginLeft: "0px !important" }}
          />
        )}
      </HStack>
    );
  };

  const getImage = (url) => {
    return (
      <Image
        src={url}
        fallbackSrc={fallbackImage}
        objectFit="cover"
        boxSize="50px"
      />
    );
  };

  let headers = tableHead[0];
  if (permissions.edit || permissions.delete)
    headers = [...headers, ...["Actions"]];

  return (
    <Box
      bg={"white"}
      p={"24px 0"}
      w={"100%"}
      h={"calc(100vh - 223px)"}
      rounded={"8px"}
      overflow={"auto"}
      boxShadow={"1px 0px 4px #E5E7EB"}
      css={scrollbarCSS}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"} p="0 24px">
        <Text
          color={"var(--title-black-text)"}
          fontSize={"14px"}
          lineHeight={"20px"}
          fontWeight={"700"}
          mr={"8px"}
        >
          Partner Status
        </Text>
        <CustomTooltip content={partnerStatusTip}>
          <Image
            display={"inline-block"}
            cursor={"pointer"}
            src={InfoOutline}
            w={"20px"}
            verticalAlign={"center"}
            mt={"10px"}
          />
        </CustomTooltip>
        <Text
          as={"span"}
          fontWeight={"500"}
          color={"var(--text-font-color)"}
          fontSize={"12px"}
          pt={"4px"}
          ml={"8px"}
        >
          (Maximum limit 10)
        </Text>
        <Spacer />
        {permissions.add && partnerStatusList?.length > 0 && (
          <Button
            title={"Add Partner Status"}
            onClick={onOpen}
            variant={buttonVariants.brandPrimary}
            isDisabled={partnerStatusList.length >= 10 ? true : false}
            id="profile-setup-partner-status-add"
          />
        )}
      </Flex>
      {loading ? (
        <Loader />
      ) : partnerStatusList && partnerStatusList.length > 0 ? (
        <Box m={"24px"} mb="0">
          <Table
            tHead={[headers]}
            tBody={tableBody}
            keepLastColumnLeftAligned={!permissions.edit || !permissions.delete}
            headingStyles={{
              backgroundColor: "#F9FAFB",
              color: "#5E6977 !important",
            }}
            keepBordersRounded
          />
        </Box>
      ) : (
        <Flex
          direction={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          h={"calc(100% - 90px)"}
        >
          <ProfileNoRecordsFound
            title="No Partner Status Found"
            description="Try adding Partner Status of company using below Call to Action button."
            imageUrl={emptyMedia}
          >
            {permissions.add && (
              <Button
                title="Add Partner Status"
                onClick={onOpen}
                variant={buttonVariants.brandPrimary}
                rounded="8px"
                id="profile-setup-partner-status-add"
                mt="18px"
              />
            )}
          </ProfileNoRecordsFound>
        </Flex>
      )}

      <Modal
        isOpen={isOpen}
        onClose={onModalClose}
        title={selectedData ? "Update Partner Status" : "Add Partner Status"}
        allowCloseButton={true}
        displayFooter={true}
        size={"md"}
        onSaveClick={handleSubmit(submitForm, handleError)}
        loading={loading}
        isDisabled={isLoading}
        saveButtonTitle="Save Partner Status"
        spDesign
      >
        <Stack spacing={2}>
          <form noValidate>
            <Flex>
              <TextBox
                type="text"
                name={"Title"}
                placeholder={"Title"}
                maxLength="100"
                required
                innerref={register("title", {
                  required: requiredField.replace("$Field$", "Title"),
                })}
                validationErrors={errors}
                widthFull={true}
              />
            </Flex>
            <Flex>
              <TextArea
                placeholder={"Description"}
                innerref={register("description", {
                  required: requiredField.replace("$Field$", "Description"),
                  onChange: (e) => handleDescriptionChange(e),
                })}
                maxLength={500}
                required
                helperText={`${descriptionLength}/500`}
                validationErrors={errors}
                helperTextPosition={"right"}
              />
            </Flex>
            <Flex>
              <FileUpload
                name="Asset"
                acceptedFileTypes="image/*,.pdf"
                required
                innerref={register("url", {
                  validate: validateMedia,
                })}
                validationErrors={errors}
                ratio={5 / 2}
                isLoading={isLoading}
                imgSrc={mediaImagePath}
              />
            </Flex>
          </form>
        </Stack>
      </Modal>
      <AlertDialog
        title="Delete Partner Status"
        actionButtonName="Delete"
        isAlertOpen={isAlertOpen}
        onAlertClose={onAlertClose}
        onAlertCloseAction={onAlertCloseAction}
      />
    </Box>
  );
};
export default PartnerStatus;
