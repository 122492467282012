// mediaSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  addMedia,
  updateMedia,
  getAllMedia,
  deleteMedia,
  getPartnerMediaList,
} from "./mediaActions";

const initialState = {
  loading: false,
  error: null,
  success: null,
  mediaList: [],
};

const mediaSlice = createSlice({
  name: "media",
  initialState,
  reducers: {
    addMediaToList: (state, action) => {
      state.mediaList = state.mediaList.concat(action.payload);
    },
    removeMediaFromList: (state, action) => {
      let locationId = action.payload;
      state.mediaList = state.mediaList.filter(
        (location) => location.id !== locationId
      );
    },
    updateMediaFromList: (state, action) => {
      let locationId = action.payload.id;
      state.mediaList = state.mediaList.map((location) => {
        return location.id === locationId
          ? { ...location, ...action.payload }
          : location;
      });
    },
    clearMessage: (state) => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: {
    // add media
    [addMedia.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addMedia.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
      if (payload.success && payload.data.media) {
        state.mediaList = [payload.data.media, ...state.mediaList];
      }
    },
    [addMedia.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // get all media
    [getAllMedia.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAllMedia.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
      if (payload.data && payload.success) {
        state.mediaList = payload.data?.mediaList || [];
      }
    },
    [getAllMedia.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // update media
    [updateMedia.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateMedia.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [updateMedia.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // delete media
    [deleteMedia.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteMedia.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [deleteMedia.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // get partner media
    [getPartnerMediaList.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.mediaList = [];
    },
    [getPartnerMediaList.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
      if (payload.data && payload.success) {
        state.mediaList = payload.data?.mediaList || [];
      }
    },
    [getPartnerMediaList.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
  },
});

export const {
  addMediaToList,
  removeMediaFromList,
  updateMediaFromList,
  clearMessage,
} = mediaSlice.actions;

export default mediaSlice.reducer;
