import { Text, Heading, Box, Flex, Center } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  companySizeOptions,
  deploymentOptions,
  icpRequirements,
  icpTitle,
  projectDurationOptions,
  waitingTimeOptions,
} from "../../constants/icpRequirements";
import { useDispatch, useSelector } from "react-redux";
import update from "immutability-helper";
import DraggableInput from "./DraggableInput";
import { Button, Loader, TextBox } from "@coachbar/shared-components";
import {
  addIcp,
  getIcp,
  getPublishedSystemGrid,
  updateIcp,
} from "./icpActions";
import { addIcpToList, updateListOptions } from "./icpSlice";
import { getAllSoftwareList } from "../softwareSpecialisation/softwareSpecialisationActions";
import { getIndustriesList } from "../serviceOffering/serviceOfferingActions";
import { getProfileCompletionStatus } from "../profileSetup/profileMetrics/profileMetricsAction";
import { getCountryList } from "../../services/utility";
import { serviceCategoryOptions } from "@coachbar/shared-components/src/constants/icpRequirements";
import CustomTooltip from "@coachbar/shared-components/src/components/Tooltip/CustomTooltip";
import { icpTitleTip } from "../../constants/tooltips";
import {
  budgetOptionsObj,
  buttonVariants,
  companyRevenueObj,
  companyRevenueOptions,
  dropdownBudgetOptions,
  scrollbarCSS,
} from "@coachbar/shared-components/src/constants/constantValues";
import { replaceCurrencyInLabel } from "@coachbar/shared-components/src/services/utility";

const IcpRequirementsForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { icpId } = useParams();
  const { softwareSpecializationList, loading } = useSelector(
    (state) => state.softwareSpecialisation
  );
  const { systemGridOptions, appStackOptions, industryListOptions } =
    useSelector((state) => state.icp);

  const countriesOptions = getCountryList(true);
  let budgetOptions = dropdownBudgetOptions.map((x) => ({
    ...x,
    isChecked: false,
    name: replaceCurrencyInLabel(x.label),
    label: replaceCurrencyInLabel(x.label),
  }));
  let revenueOptions = companyRevenueOptions.map((x) => ({
    ...x,
    isChecked: false,
    label: replaceCurrencyInLabel(x.label),
    name: replaceCurrencyInLabel(x.label),
  }));
  const icpInitialState = {
    projectBudget_options: budgetOptions,
    projectBudget_selected: [],
    serviceCategory_options: serviceCategoryOptions,
    serviceCategory_selected: [],
    waitingTime_options: waitingTimeOptions,
    waitingTime_selected: [],
    companyRevenue_options: revenueOptions,
    companyRevenue_selected: [],
    projectDuration_options: projectDurationOptions,
    projectDuration_selected: [],
    systemGrid_options: [],
    systemGrid_selected: undefined,
    currentApplicationsStack_options: [],
    currentApplicationsStack_selected: [],
    industry_options: [],
    industry_selected: [],
    country_options: countriesOptions,
    country_selected: [],
    companySize_options: companySizeOptions,
    companySize_selected: [],
    deployment_options: deploymentOptions,
    deployment_selected: [],
  };

  const [inputList, setInputList] = useState(icpRequirements);
  const [inputStates, setInputStates] = useState(icpInitialState);
  const [isLoading, setIsLoading] = useState(false);
  const [sgOptions, setSgOptions] = useState([]);
  const [asOptions, setAsOptions] = useState([]);
  const [serviceIndustriesOptions, setServiceIndustriesOptions] = useState([]);
  const [title, setTitle] = useState("");
  const [titleErrorMessage, setTitleErrorMessage] = useState("");
  const [icpStatus, setIcpStatus] = useState(true);

  useEffect(() => {
    setInputList(icpRequirements);
    setInputStates(icpInitialState);

    setInputList((prevInputList) =>
      prevInputList.map((item, index) => ({
        ...item,
        serialNumber: index + 1,
      }))
    );
  }, []);

  useEffect(() => {
    if (softwareSpecializationList) {
      prepareSoftwareList(softwareSpecializationList);
    } else {
      // setIsLoading(true);
      dispatch(getAllSoftwareList())
        .then((responseData) => {
          const response = responseData.payload;
          if (response.success && response.data && response.data.softwareList) {
            prepareSoftwareList(response.data.softwareList);
            // setIsLoading(false);
          } else {
            // setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (
      !systemGridOptions?.length ||
      // !appStackOptions?.length ||
      !industryListOptions?.length
    ) {
      setIsLoading(true);
      dispatch(getPublishedSystemGrid())
        .then((systemGridResponse) => {
          let fetchedSystemGridOptions;
          if (systemGridResponse?.payload?.data?.softwareBundleList) {
            fetchedSystemGridOptions =
              systemGridResponse.payload.data.softwareBundleList.map((grid) => {
                return {
                  label: grid.title,
                  value: grid.id,
                };
              });
          }
          // getSoftwareList().then((appStackResponse) => {
          //   const softwareList = appStackResponse?.data?.softwareList;
          //   const combinedSoftwareList = Object.values(softwareList).flat();
          //   const fetchedAppStackOptions = combinedSoftwareList?.map((csl) => {
          //     return {
          //       ...csl,
          //       isChecked: false,
          //     };
          //   });

          getIndustriesList().then((industryResponse) => {
            const fetchedIndustriesOptions = industryResponse?.map(
              (industry) => {
                return {
                  name: industry.name,
                  isChecked: false,
                };
              }
            );
            setInputStates((prevState) => ({
              ...prevState,
              systemGrid_options: fetchedSystemGridOptions,
              // currentApplicationsStack_options: fetchedAppStackOptions,
              industry_options: fetchedIndustriesOptions,
            }));
            setSgOptions(fetchedSystemGridOptions);
            // setAsOptions(fetchedAppStackOptions);
            setServiceIndustriesOptions(fetchedIndustriesOptions);
            setIsLoading(false);
            dispatch(
              updateListOptions({
                systemGridOptions: fetchedSystemGridOptions,
                // appStackOptions: fetchedAppStackOptions,
                industriesOptions: fetchedIndustriesOptions,
              })
            );
          });
          // });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      if (icpId) setIsLoading(true);
      setInputStates((prevState) => ({
        ...prevState,
        systemGrid_options: systemGridOptions,
        // currentApplicationsStack_options: appStackOptions,
        industry_options: industryListOptions,
      }));
      setSgOptions(systemGridOptions);
      setAsOptions(appStackOptions);
      setServiceIndustriesOptions(industryListOptions);
    }
  }, [dispatch]);

  const prepareSoftwareList = (softwareList) => {
    var allSoftwareList = [];
    const seenNames = new Set();
    for (const key in softwareList) {
      softwareList[key].map((software) => {
        const softwareWithCategory = {
          ...software,
          category: key,
          isChecked: false,
        };

        // Check if the software name is already seen
        if (!seenNames.has(software.name)) {
          allSoftwareList.push(softwareWithCategory);
          seenNames.add(software.name);
        }

        return softwareWithCategory;
      });
    }
    setInputStates((prevState) => ({
      ...prevState,
      currentApplicationsStack_options: allSoftwareList,
    }));
  };

  const processApiData = (apiData, localData) => {
    const updatedInputStates = { ...inputStates };
    const updatedInputList = [];

    apiData?.itemList?.forEach((item) => {
      const { name } = item;
      const keyOptions = `${name}_options`;
      const keySelected = `${name}_selected`;

      if (name === "systemGrid" && item?.selectedItem) {
        updatedInputStates[keySelected] = item.selectedItem;
      } else if (updatedInputStates[keyOptions]) {
        const selectedItemsList = item?.selectedItemList;
        const options = updatedInputStates[keyOptions];

        if (Array.isArray(selectedItemsList) && selectedItemsList.length > 0) {
          const selectedOptions = selectedItemsList.map((itemName) => ({
            name:
              name === "companyRevenue"
                ? replaceCurrencyInLabel(companyRevenueObj[itemName])
                : name === "projectBudget"
                ? replaceCurrencyInLabel(budgetOptionsObj[itemName])
                : itemName,
            label:
              name === "companyRevenue"
                ? replaceCurrencyInLabel(companyRevenueObj[itemName])
                : name === "projectBudget"
                ? replaceCurrencyInLabel(budgetOptionsObj[itemName])
                : itemName,
            value:
              name === "companyRevenue" || name === "projectBudget"
                ? itemName
                : undefined,
            isChecked: true,
          }));
          updatedInputStates[keySelected] = selectedOptions;

          // Create a new array with updated options
          const updatedOptions = options.map((option) => ({
            ...option,
            isChecked: selectedItemsList.includes(
              name === "companyRevenue" || name === "projectBudget"
                ? option.value
                : option.name
            ),
          }));
          updatedInputStates[keyOptions] = updatedOptions;
        } else {
          // Create a new array with options having isChecked set to false
          const updatedOptions = options.map((option) => ({
            ...option,
            isChecked: false,
          }));
          updatedInputStates[keyOptions] = updatedOptions;
          updatedInputStates[keySelected] = [];
        }
      }

      const localItem = localData.find((local) => local.id === name);
      if (localItem) {
        updatedInputList.push({
          id: localItem.id,
          type: localItem.type,
          label: localItem.label,
          placeholder: localItem.placeholder,
          serialNumber: item.priority,
        });
      }
    });

    return { updatedInputStates, updatedInputList };
  };

  useEffect(() => {
    if (
      icpId &&
      (sgOptions?.length > 0 ||
        asOptions?.length > 0 ||
        serviceIndustriesOptions?.length > 0)
    ) {
      setIsLoading(true);
      let fetchedDataFromApi;
      dispatch(getIcp(icpId)).then((response) => {
        if (response?.payload?.success && response?.payload?.data?.icp) {
          fetchedDataFromApi = response?.payload?.data?.icp;
          setTitle(fetchedDataFromApi?.title);
          setIcpStatus(fetchedDataFromApi?.active);
          const { updatedInputStates, updatedInputList } = processApiData(
            fetchedDataFromApi,
            icpRequirements
          );

          setInputStates(updatedInputStates);
          setInputList(
            updatedInputList.sort((a, b) => a.serialNumber - b.serialNumber)
          );
          setIsLoading(false);
        }
      });
    }
  }, [icpId, sgOptions, asOptions, serviceIndustriesOptions]);

  const handleSaveIcp = () => {
    if (!title) {
      setTitleErrorMessage("Title is required");
      return;
    } else if (title.trim() === "") {
      setTitleErrorMessage("Title cannot be empty or whitespace");
      return;
    }

    let requestData = {
      title: title?.replace(/\s+/g, " ").trim(),
      itemList: [],
      active: icpStatus,
    };

    inputList?.map((item) => {
      if (item?.id === "systemGrid") {
        const isSelected = inputStates[`${item?.id}_selected`];
        if (!isSelected || isSelected?.length < 1) {
          requestData?.itemList?.push({
            name: item.id,
            priority: item?.serialNumber,
          });
        } else {
          requestData?.itemList?.push({
            name: item.id,
            priority: item?.serialNumber,
            selectedItem: inputStates[`${item?.id}_selected`],
          });
          requestData.gridId = inputStates[`${item?.id}_selected`];
        }
      } else if (
        item?.id === "companyRevenue" ||
        item?.id === "projectBudget"
      ) {
        const isSelected = inputStates[`${item?.id}_selected`].length > 0;
        if (!isSelected) {
          requestData?.itemList?.push({
            name: item.id,
            priority: item?.serialNumber,
          });
        } else {
          const selectedItems = inputStates[`${item?.id}_selected`]?.map(
            (item) => item?.value
          );
          requestData?.itemList?.push({
            name: item.id,
            priority: item?.serialNumber,
            selectedItemList: selectedItems,
          });
        }
      } else {
        const isSelected = inputStates[`${item?.id}_selected`].length > 0;
        if (!isSelected) {
          requestData?.itemList?.push({
            name: item.id,
            priority: item?.serialNumber,
          });
        } else {
          const selectedItems = inputStates[`${item?.id}_selected`]?.map(
            (item) => item?.name
          );
          requestData?.itemList?.push({
            name: item.id,
            priority: item?.serialNumber,
            selectedItemList: selectedItems,
          });
        }
      }
    });

    if (icpId) {
      dispatch(updateIcp({ icpId, requestParams: requestData })).then(
        (responseData) => {
          if (responseData.payload.success && responseData.payload.data.icp) {
            navigate("/profile-setup/icp");
            dispatch(getProfileCompletionStatus());
          }
        }
      );
    } else {
      dispatch(addIcp(requestData)).then((responseData) => {
        if (responseData.payload.success && responseData.payload.data.icp) {
          dispatch(addIcpToList(responseData.payload.data.icp));
          navigate("/profile-setup/icp");
          dispatch(getProfileCompletionStatus());
        }
      });
    }
  };

  const onDiscard = () => {
    navigate("/profile-setup/icp");
    setTitleErrorMessage("");
  };

  const handleTitleChange = (e) => {
    const titleValue = e.target.value;
    setTitle(titleValue);
  };

  const moveInput = useCallback(
    (dragIndex, hoverIndex) => {
      setInputList((prevInputList) => {
        const newList = update(prevInputList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevInputList[dragIndex]],
          ],
        });
        newList.forEach((item, index) => {
          item.serialNumber = index + 1;
        });
        return newList;
      });
    },
    [setInputList]
  );

  const renderInput = useCallback(
    (input, index) => {
      return (
        <DraggableInput
          key={input.id}
          index={index}
          id={input.id}
          moveInput={moveInput}
          inputStates={inputStates}
          setInputStates={setInputStates}
          {...input}
        />
      );
    },
    [moveInput, inputStates, inputList]
  );

  return (
    <Box p="24px" className="full-width-popovers">
      {/* <Card variant="form"> */}
      <Heading size="md" mb={"16px"}>
        <Text
          color={"#111A29"}
          fontSize={"16px"}
          lineHeight={"20px"}
          fontWeight={700}
        >
          {icpId ? `Edit ${icpTitle}` : `Add ${icpTitle}`} &nbsp;
          <CustomTooltip content={icpTitleTip} />
        </Text>
      </Heading>
      {isLoading ? (
        <Center minH={"calc(100vh - 160px)"}>
          <Loader />
        </Center>
      ) : (
        <>
          <TextBox
            type="text"
            name={"name"}
            placeholder={"Enter Name"}
            maxLength="256"
            showPlaceHolder={true}
            required
            value={title}
            onChange={(e) => handleTitleChange(e)}
            hideHelperText={true}
          />
          {titleErrorMessage && (
            <Text color={"red.500"} mt={1} fontSize={"sm"}>
              {titleErrorMessage}
            </Text>
          )}
          <Text
            color={"#111A29"}
            fontSize={"14px"}
            lineHeight={"20px"}
            fontWeight={700}
            pt={"16px"}
            pb={"4px"}
          >
            Drag & Drop to Rank ICP Attributes
          </Text>
          <Box
            border={"1px solid #D4DCE3"}
            maxH={"calc(100vh - 312px)"}
            overflow={"auto"}
            bgColor={"var(--chakra-colors-white)"}
            css={scrollbarCSS}
          >
            {inputList &&
              inputList?.length > 0 &&
              inputList.map((input, index) => renderInput(input, index))}
          </Box>
        </>
      )}
      <Flex w={"full"} justifyContent={"flex-end"} gap={"20px"} mt={"20px"}>
        <Button
          variant={buttonVariants.greyOutline}
          title="Discard"
          onClick={onDiscard}
          id="icp-requirement-form-cancel"
        />
        <Button
          type="submit"
          px={6}
          title="Save"
          onClick={handleSaveIcp}
          id="icp-requirement-form-submit"
        />
      </Flex>
    </Box>
  );
};

export default IcpRequirementsForm;
