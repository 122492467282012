// integrationSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  addIntegration,
  getIntegrationDetail,
  updateIntegration,
  updateIntegrationStatus,
} from "./integrationAction.js";

const initialState = {
  loading: false,
  updateLoading: false,
  error: null,
  success: null,
  integrationDetail: null,
  integrationList: [],
};

const integrationSlice = createSlice({
  name: "integration",
  initialState,
  reducers: {
    clearMessage: (state) => {
      state.success = null;
      state.error = null;
    },
    setError: (state, action) => {
      let errorMsg = action.payload;
      state.error = errorMsg;
    },
    setSuccess: (state, action) => {
      let successMsg = action.payload;
      state.success = successMsg;
    },
  },
  extraReducers: {
    // add coachbar services
    [addIntegration.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addIntegration.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
      if (payload.data && payload.success) {
        state.integrationDetail = payload.data?.integration || null;
      }
    },
    [addIntegration.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // get integration detail list
    [getIntegrationDetail.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getIntegrationDetail.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
      if (payload.data && payload.success) {
        state.integrationDetail = payload.data?.hubspot || null;
      }
    },
    [getIntegrationDetail.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // update integration detail
    [updateIntegration.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateIntegration.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
      if (payload.data && payload.success) {
        state.integrationDetail = payload.data?.hubspot || null;
      }
    },
    [updateIntegration.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // update integration detail
    [updateIntegrationStatus.pending]: (state) => {
      state.updateLoading = true;
      state.error = null;
    },
    [updateIntegrationStatus.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.updateLoading = false;
      if (payload.data && payload.success && payload.data?.integration) {
        state.integrationDetail = {
          ...state.integrationDetail,
          active: payload.data.integration.active,
        };
      }
    },
    [updateIntegrationStatus.rejected]: (state, { payload }) => {
      state.updateLoading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
  },
});

export const { setError, setSuccess, clearMessage } = integrationSlice.actions;

export default integrationSlice.reducer;
