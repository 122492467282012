import { createSlice } from "@reduxjs/toolkit";
import { getPersonalNotificationList } from "./personalNotificationActions";

const initialState = {
  loading: false,
  error: null,
  success: null,
  notificationSettingList: {},
};

const hubSpot = {
  syncLead: "Leads Synced to HubSpot",
  syncDeal: "Deals Synced to HubSpot",
};

const personalNotificationSlice = createSlice({
  name: "personalNotificationSetting",
  initialState,
  reducers: {
    resetPersonalNotificationState: (state) => {
      state.error = null;
      state.success = null;
      state.loading = false;
      state.notificationSettingList = {};
    },
  },
  extraReducers: {
    [getPersonalNotificationList.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [getPersonalNotificationList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      const leads = payload?.Lead ?? [];
      const deals = payload?.Deal ?? [];
      state.notificationSettingList = {
        ...payload,
        Lead_And_Deal: [
          ...leads?.filter((deal) => deal.eventName !== hubSpot.syncLead),
          ...deals?.filter((lead) => lead.eventName !== hubSpot.syncDeal),
        ],
      };
    },
    [getPersonalNotificationList.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
  },
});

export const { resetPersonalNotificationState } =
  personalNotificationSlice.actions;

export default personalNotificationSlice.reducer;
