/**
 * @fileoverview Drawer Filter Component for more filters.
 * This file contains [Drawer component with config to display filter dynamicaly with given props].
 *
 * @author Prashant Chauhan
 * @version 1.0.0
 */

import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Image,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import CustomDrawer from "@coachbar/shared-components/src/components/Drawer/Drawer";
import CustomButton from "@coachbar/shared-components/src/components/Button/Button";
import MultiCheckboxSelector from "@coachbar/shared-components/src/components/MultiCheckboxSelector/MultiCheckboxSelector";
import { CloseIcon } from "@chakra-ui/icons";
import { Button } from "@coachbar/shared-components";
import {
  buttonVariants,
  scrollbarCSS,
} from "@coachbar/shared-components/src/constants/constantValues";
import { Scrollbars } from "react-custom-scrollbars-2";
import CustomRadioGroup from "../RadioGroup/RadioGroup";
import FilterIcon from "@coachbar/shared-components/src/images/filter-icon.svg";

/**
 *
 * @param {Object} props | React Props passed by parent component
 * @param {String} props.name | Name to give into filter list
 * @param {String} props.title | Title to show into the filter list
 * @param {Object} props.options | List of the filter item
 * @param {String} props.moreFilterObj.options.name | Item name to show into the filter list
 * @param {String} props.moreFilterObj.options.value | Item value to send on submit
 * @param {Boolean} props.moreFilterObj.options.isChecked | Item is selected by user or not
 * @param {Function} props.onFilterChange | action to call on submit button
 * @param {Boolean} props.isRadio | render checkbox or radio
 * @param {Boolean} props.allowSearch | Show search box or not
 * @param {Boolean} props.customHeight | Give max height to the accordion component
 *
 * @returns {React.ReactComponentElement}
 */
const MoreFilterAccrodian = React.memo(
  ({
    name,
    title,
    Options,
    onFilterChange,
    isRadio = false,
    allowSearch,
    customHeight = "250px",
  }) => {
    const { onOpen: isFilterSelectionOpen } = useDisclosure();
    return (
      <AccordionItem className="filter" border={"none"}>
        <AccordionButton>
          <Box
            as="span"
            flex="1"
            textAlign="left"
            fontSize={"14px"}
            fontWeight={"600"}
          >
            {title}
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel py="0px">
          {!isRadio ? (
            <MultiCheckboxSelector
              name={name}
              handleCheckBoxChange={(_, opt) => onFilterChange(opt)}
              onSelectAll={(opt) => onFilterChange(opt)}
              customHeight={customHeight}
              itemList={Options}
              isPopoverOpen={isFilterSelectionOpen}
              showAllSelect
              allowSearch={allowSearch}
            />
          ) : (
            <Box m={"8px 0"}>
              <Scrollbars autoHeight autoHeightMax={"85px"} autoHide>
                <CustomRadioGroup
                  title={name}
                  childList={Options}
                  direction="column"
                  size="sm"
                  onChange={(value) => {
                    let updatedValues = Options.map((x) => ({
                      ...x,
                      isChecked: x.value === value,
                    }));
                    onFilterChange(updatedValues);
                  }}
                  value={Options?.find((x) => x.isChecked)?.value || ""}
                />
              </Scrollbars>
            </Box>
          )}
        </AccordionPanel>
      </AccordionItem>
    );
  }
);

/**
 * Component to use to add drawer for more filters
 * @param {Object} props | React Props passed by parent component
 * @param {Boolean} props.isOpen | Drawer is open or close
 * @param {Function} props.onModalClose | Function to call on drawer open
 * @param {Function} props.setFilters | Function to call on user submit the updated filter value
 * @param {Object} props.moreFilterObj | Object contains all the filter data to show into the more filter
 * @param {String} props.moreFilterObj.title | Title to show on the filter accordion
 * @param {String} props.moreFilterObj.key | name we use as key into the filter object
 * @param {Boolean} props.moreFilterObj.isRadio | filter radio type or not
 * @param {Boolean} props.moreFilterObj.allowSearch | name we use as key into the filter object
 * @param {Boolean} props.moreFilterObj.hideFilter | to hide filter
 * @param {Object} props.moreFilterObj.options | Array of Object contains filter item data
 * @param {String} props.moreFilterObj.options.name | Item name to show into the filter list
 * @param {String} props.moreFilterObj.options.value | Item value to send on submit
 * @param {Boolean} props.moreFilterObj.options.isChecked | Item is selected by user or not
 * @param {String} props.moduleForId | to assign id to button for analytics purpose
 * @returns {React.ReactComponentElement}
 */
const MoreFilterDrawer = React.memo(
  ({ isOpen, onModalClose, moreFilters, setFilters, moduleForId }) => {
    const [localFilters, setLocalFilters] = useState([...moreFilters]);

    useEffect(() => {
      isOpen && setLocalFilters([...moreFilters]);
    }, [moreFilters, isOpen]);

    const handleSaveData = () => {
      setFilters((prev) => {
        const moreFilterObj = {};
        localFilters.forEach((filterObj) => {
          moreFilterObj[filterObj.key] = filterObj.options
            .filter((item) => item.isChecked)
            .map((item) => item.value);
        });
        return { ...prev, ...moreFilterObj };
      });
      onModalClose();
    };

    const clearFilterValues = () => {
      const updatedFilters = localFilters.map((filterObj) => ({
        ...filterObj,
        options: filterObj.options.map((item) => ({
          ...item,
          isChecked: false,
        })),
      }));
      setLocalFilters(updatedFilters);
    };

    return (
      <CustomDrawer
        size="xs"
        isOpen={isOpen}
        onClose={onModalClose}
        displayFooter={false}
        saveButtonTitle="Submit"
        discardTitle="Cancel"
        bodyPadding="0"
        borderBottomColor="var(--chakra-colors-brandGray-101)"
        title={
          <Flex w="100%" justifyContent={"space-between"} alignItems={"center"}>
            <Text fontSize={"16px"} fontWeight={"600px"}>
              Filters
            </Text>
            <Box p={"6px 12px"} cursor={"pointer"} onClick={onModalClose}>
              <CloseIcon w="12px" display={"inline-block"} color={"#5E6977"} />
            </Box>
          </Flex>
        }
        moduleForId={moduleForId}
      >
        <Box
          style={{ maxHeight: `calc(100vh - 130px)` }}
          overflow={"auto"}
          css={scrollbarCSS}
          borderBottom={"1px solid var(--chakra-colors-brandGray-101)"}
        >
          <Flex alignItems={"center"} py={1} px={6}>
            <Spacer />
            <Button
              border={"none"}
              bg={"none"}
              color={"#0C94AC"}
              variant="newThemePrimary"
              title={"Clear all"}
              onClick={clearFilterValues}
              padding="0"
              id={`${moduleForId}-clear-all-filter`}
            />
          </Flex>
          {localFilters.map((filterObj) =>
            filterObj?.hideFilter ? null : (
              <React.Fragment key={filterObj.key}>
                <Divider
                  borderColor={"var(--chakra-colors-brandGray-101)"}
                  borderBottomWidth={"1px"}
                />
                <Accordion
                  defaultIndex={[0, 1, 2, 3]}
                  allowMultiple={true}
                  p={"16px"}
                >
                  <MoreFilterAccrodian
                    name={filterObj.key}
                    title={filterObj.title}
                    Options={filterObj.options}
                    onFilterChange={(values) => {
                      setLocalFilters((prev) =>
                        prev.map((item) =>
                          item.key === filterObj.key
                            ? { ...item, options: values }
                            : item
                        )
                      );
                    }}
                    isRadio={filterObj?.isRadio || false}
                    allowSearch={filterObj?.allowSearch || false}
                  />
                </Accordion>
              </React.Fragment>
            )
          )}
        </Box>

        {/* <Divider /> */}
        <Box position={"absolute"} bottom={"10px"} width="100%" px={6}>
          <CustomButton
            bg="#0C94AC"
            border="none"
            rounded="4px"
            variant="newThemePrimary"
            title="Apply Filter"
            width="full"
            onClick={handleSaveData}
            id={`${moduleForId}-apply-filter`}
          />
        </Box>
      </CustomDrawer>
    );
  }
);

const MoreFilterButton = ({
  showMoreFilters,
  setShowMoreFilters,
  appliedMoreFilterCount,
  moduleForId,
  ...props
}) => {
  return (
    <Button
      leftIcon={<Image src={FilterIcon} />}
      onClick={() => {
        setShowMoreFilters(!showMoreFilters);
      }}
      title={
        <Flex alignItems={"center"}>
          {showMoreFilters ? (
            <Text>Hide Filters</Text>
          ) : (
            <Text>More Filters</Text>
          )}
          {appliedMoreFilterCount > 0 && (
            <Box
              w={6}
              h={6}
              p={0}
              ml="7px"
              bg="#E7FAFD"
              rounded={"50%"}
              fontSize={"12px"}
            >
              {appliedMoreFilterCount}
            </Box>
          )}
        </Flex>
      }
      height="36px"
      variant={buttonVariants.brandPrimaryLinkButton}
      _hover={{
        border: "1px solid var(--chakra-colors-cyprus-100)",
        background: "var(--chakra-colors-cyprus-50)",
      }}
      id={`${moduleForId}-clear-all-filter`}
      {...props}
    />
  );
};

export { MoreFilterAccrodian, MoreFilterButton };

export default MoreFilterDrawer;
