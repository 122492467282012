import React, { useEffect } from "react";
import { Flex, Stack } from "@chakra-ui/react";
import NotificationTable from "./NotificationTable";
import { scrollbarCSS } from "@coachbar/shared-components/src/constants/constantValues";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "@coachbar/shared-components";
import { getPersonalNotificationList } from "./personalNotificationActions";
import { resetPersonalNotificationState } from "./personalNotificationSlice";
import { notificationsModules } from "../../constants/notifications";

const PersonalNotifications = () => {
  const dispatch = useDispatch();
  const { loading, notificationSettingList } = useSelector(
    (state) => state.personalNotifications
  );
  const userId = useSelector((state) => state.auth.userInfo.id);

  useEffect(() => {
    dispatch(getPersonalNotificationList(userId));
    return () => dispatch(resetPersonalNotificationState());
  }, []);

  return (
    <Stack
      padding={"24px"}
      maxH="calc(100vh - 64px)"
      overflowY="scroll"
      css={scrollbarCSS}
    >
      {loading ? (
        <Flex h="80vh" alignItems={"center"} justifyContent={"center"}>
          <Loader />
        </Flex>
      ) : (
        notificationsModules.map((notificationsModule) => (
          <NotificationTable
            key={notificationsModule.key}
            cardTitle={notificationsModule.moduleTitle}
            moduleNotifications={
              notificationSettingList?.[notificationsModule.key] || []
            }
            isPersonalNotification
          />
        ))
      )}
    </Stack>
  );
};

export default PersonalNotifications;
