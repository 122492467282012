// serviceOfferingSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  addServiceOffering,
  updateServiceOffering,
  getServiceOffering,
  addServiceIndustry,
  getServiceIndustry,
  updateServiceIndustry,
  deleteServiceOffering,
} from "./serviceOfferingActions";

const initialState = {
  loading: false,
  industriesLoading: false,
  error: null,
  success: null,
  serviceOffering: [],
  serviceIndustries: [],
};

const serviceOfferingSlice = createSlice({
  name: "serviceOffering",
  initialState,
  reducers: {
    addServiceToList: (state, action) => {
      state.serviceOffering.unshift(action.payload);
    },
    removeServiceFromList: (state, action) => {
      let serviceId = action.payload;
      state.serviceOffering = state.serviceOffering.filter(
        (service) => service.id !== serviceId
      );
    },
    updateServiceList: (state, action) => {
      const updatedService = action.payload;
      const serviceId = updatedService.id;
      state.serviceOffering = state.serviceOffering.filter(
        (service) => service.id !== serviceId
      );
      state.serviceOffering.unshift(updatedService);
    },
    clearMessage: (state) => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: {
    // add service offering
    [addServiceOffering.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addServiceOffering.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [addServiceOffering.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // get service offering
    [getServiceOffering.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getServiceOffering.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
      if (payload.data && payload.success) {
        state.serviceOffering = payload.data?.serviceOfferingList || [];
      }
    },
    [getServiceOffering.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // update service offering
    [updateServiceOffering.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateServiceOffering.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [updateServiceOffering.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    [deleteServiceOffering.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteServiceOffering.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [deleteServiceOffering.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    //add service Industry
    [addServiceIndustry.pending]: (state) => {
      state.industriesLoading = true;
      state.error = null;
    },
    [addServiceIndustry.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.industriesLoading = false;
    },
    [addServiceIndustry.rejected]: (state, { payload }) => {
      state.industriesLoading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // get service Industry
    [getServiceIndustry.pending]: (state) => {
      state.industriesLoading = true;
      state.error = null;
    },
    [getServiceIndustry.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.industriesLoading = false;
      if (payload.data && payload.success) {
        state.serviceIndustries =
          payload.data?.serviceList?.partnerServicesList || null;
      }
    },
    [getServiceIndustry.rejected]: (state, { payload }) => {
      state.industriesLoading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // update service Industry
    [updateServiceIndustry.pending]: (state) => {
      state.industriesLoading = true;
      state.error = null;
    },
    [updateServiceIndustry.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.industriesLoading = false;
    },
    [updateServiceIndustry.rejected]: (state, { payload }) => {
      state.industriesLoading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
  },
});

export const {
  addServiceToList,
  removeServiceFromList,
  updateServiceList,
  clearMessage,
} = serviceOfferingSlice.actions;

export default serviceOfferingSlice.reducer;
