import { CalendarIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { format, subDays } from "date-fns";
import React, { useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { menuItemStyles } from "../../constants/constantValues";

const CustomSingleDayPicker = ({
  selectedDate,
  setSelectedDate,
  offsetRight = "54px",
}) => {
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [dateType, setDateType] = useState("Last 60 Days");

  const handleLastDaysClick = (days, type) => {
    const selectedPastDate = subDays(new Date(), days);
    setSelectedDate(format(selectedPastDate, "yyyy-MM-dd"));
    setDateType(type);
  };

  const handleCustomClick = () => {
    setOpenDatePicker(true);
  };

  const handleMenuButtonClick = () => {
    setOpenDatePicker(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(format(date, "yyyy-MM-dd"));
    setDateType(format(date, "yyyy-MM-dd"));
    setOpenDatePicker(false);
  };

  return (
    <Box>
      <Menu>
        <MenuButton
          border={"1px solid #E2E8F0"}
          rounded={"md"}
          bg={"white"}
          color={"brand.100"}
          fontWeight={400}
          as={Button}
          rightIcon={<ChevronDownIcon boxSize={5} />}
          leftIcon={<CalendarIcon />}
          onClick={handleMenuButtonClick}
          sx={{
            _hover: {
              bg: "inherit",
            },
          }}
        >
          {dateType}
        </MenuButton>
        <MenuList
          zIndex={"popover"}
          border={"1px solid #E6E7E9"}
          p={"4px"}
          minW={"155px"}
          w={"162px"}
        >
          <MenuItem
            onClick={() => handleLastDaysClick(30, "Last 30 days")}
            {...menuItemStyles}
          >
            Last 30 days
          </MenuItem>
          <MenuItem
            onClick={() => handleLastDaysClick(60, "Last 60 days")}
            {...menuItemStyles}
          >
            Last 60 days
          </MenuItem>
          <Divider border={"1px solid #E6E7E9"} my={"2px"} />
          <MenuItem onClick={handleCustomClick} {...menuItemStyles}>
            Custom
          </MenuItem>
        </MenuList>
      </Menu>
      {openDatePicker && (
        <Box
          position={"absolute"}
          bgColor={"#FFFFFF"}
          mt={"8px"}
          right={offsetRight}
          border={"1px solid"}
          borderColor={"brandGray.20"}
          borderRadius={"12px"}
          zIndex={1}
        >
          <DayPicker
            mode="single"
            selected={selectedDate}
            onSelect={handleDateChange}
            fromDate={subDays(new Date(), 60)}
            toDate={new Date()}
          />
        </Box>
      )}
    </Box>
  );
};

export default CustomSingleDayPicker;
