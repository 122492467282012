export const softwareEmpty = "Please select software from specialization tab";
export const clientEmpty = "Please add client in Client's Information menu";
export const industryEmpty = "Please select industry in Service Offering menu";

export const companyTypeArray = [
  { name: "B2B", isChecked: false },
  { name: "B2C", isChecked: false },
  { name: "C2C", isChecked: false },
  { name: "D2C", isChecked: false },
  { name: "B2B2C", isChecked: false },
];

export const companyTypeArrayReactSelect = [
  { label: "B2B", value: "B2B" },
  { label: "B2C", value: "B2C" },
  { label: "C2C", value: "C2C" },
  { label: "D2C", value: "D2C" },
  { label: "B2B2C", value: "B2B2C" },
];

export const growthOption = [
  { label: "0 - 10%", value: "0 - 10%" },
  { label: "10% - 20%", value: "10% - 20%" },
  { label: "20% - 30%", value: "20% - 30%" },
  { label: "30% - 40%", value: "30% - 40%" },
  { label: "40% - 50%", value: "40% - 50%" },
  { label: "50% - 60%", value: "50% - 60%" },
  { label: "60% - 70%", value: "60% - 70%" },
  { label: "70% - 80%", value: "70% - 80%" },
  { label: "80% - 90%", value: "80% - 90%" },
  { label: "90% - 100%", value: "90% - 100%" },
];
