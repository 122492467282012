// hubSpotSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { getHubSpotProperty, manualSync } from "./hubSpotAction.js";

const initialState = {
  loading: false,
  error: null,
  success: null,
  hubSpotFieldProperty: [],
  hubSpotStatusProperty: [],
  coachbarFieldProperty: [],
  referralsList: [],
  referralNextToken: null,
  manualSyncLoading: false,
};

const hubSpotSlice = createSlice({
  name: "hubspot",
  initialState,
  reducers: {
    clearMessage: (state) => {
      state.success = null;
      state.error = null;
    },
    setError: (state, action) => {
      let errorMsg = action.payload;
      state.error = errorMsg;
    },
    setSuccess: (state, action) => {
      let successMsg = action.payload;
      state.success = successMsg;
    },
  },
  extraReducers: {
    // get hubspot property list
    [getHubSpotProperty.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getHubSpotProperty.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
      if (payload.data && payload.success) {
        state.hubSpotFieldProperty =
          payload.data?.hubspotProperties?.fields || [];
        state.hubSpotStatusProperty =
          payload.data?.hubspotProperties?.status || [];
      }
    },
    [getHubSpotProperty.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },

    // add coachbar services
    [manualSync.pending]: (state) => {
      state.manualSyncLoading = true;
      state.error = null;
    },
    [manualSync.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.manualSyncLoading = false;
    },
    [manualSync.rejected]: (state, { payload }) => {
      state.manualSyncLoading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
  },
});

export const { setError, setSuccess, clearMessage } = hubSpotSlice.actions;

export default hubSpotSlice.reducer;
