import { Box, Center, Text } from "@chakra-ui/react";
import CellLoadingComponent from "@coachbar/shared-components/src/components/Loader/CellLoadingComponent";

const StatBox = ({
  title,
  isLoading,
  value,
  valuePrefix = "",
  valueSuffix = "",
}) => (
  <Box
    bg={"white"}
    boxShadow="0px 0px 4px 0px #E6E7E9"
    rounded={"8px"}
    padding={"16px 20px"}
  >
    <Box>
      <Text
        color={"#5E6977"}
        fontWeight={"600"}
        fontSize={"14px"}
        lineHeight={"20px"}
        mb="12px !important"
      >
        {title}
      </Text>
      {isLoading ? (
        <Center>
          <CellLoadingComponent />
        </Center>
      ) : (
        <Text
          fontWeight={"700"}
          fontSize={"16px"}
          lineHeight={"24px"}
          color={"#111A29"}
        >
          {valuePrefix}
          {value}
          {valueSuffix}
        </Text>
      )}
    </Box>
  </Box>
);

export default StatBox;
