import React, { useCallback, useMemo, useState } from "react";
import {
  Box,
  Center,
  Divider,
  Flex,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { notification } from "../services/utility";
import BlackHatIcon from "../images/black-hat-icon.svg";
import { Button } from "..";

const LMSListForSP = [
  {
    title: "Course Creation",
    subTitle:
      "Craft dynamic content tailored to your training needs, whether for partner training or certification.",
  },
  {
    title: "Tracking & Reporting",
    subTitle:
      "Gain insights into training impact with robust tracking and reporting. Monitor learner progress effortlessly.",
  },
  {
    title: "Video Assessments",
    subTitle:
      "Enable learners to upload videos for assessment, using AI to evaluate key communication skills.",
  },
  {
    title: "Customized Learning Paths",
    subTitle:
      "Personalize learning journeys by stringing together courses based on learner capabilities and goals.",
  },
];

const LMSListForSC = [
  {
    title: "Access Courses",
    subTitle:
      "Enroll in dynamic, tailored courses designed to meet your training needs and help you gain certifications.",
  },
  {
    title: "Track Progress",
    subTitle:
      "Monitor your learning journey with detailed progress tracking and reporting features.",
  },
  {
    title: "Video Assessments",
    subTitle:
      "Upload video assignments for AI-powered assessments that evaluate key communication skills.",
  },
  {
    title: "Customized Learning Paths",
    subTitle:
      "Follow personalized learning paths that cater to your skills and career goals, ensuring a comprehensive learning experience.",
  },
  {
    title: "Mobile Learning",
    subTitle:
      "Access training modules on the go via mobile devices, ensuring flexibility and convenience.",
  },
];

const LMSLogin = ({
  litmosLogin,
  isPartner = false,
  isLitmosUser,
  moduleForId,
}) => {
  const [loading, setLoading] = useState(false);
  const LMSList = useMemo(
    () => (isPartner ? LMSListForSC : LMSListForSP),
    [isPartner]
  );

  const goToLMS = useCallback(async () => {
    setLoading(true);
    const res = await litmosLogin();
    if (res.code === 200 && res.data?.url) {
      window.open(res.data?.url);
    } else {
      notification(res?.message ?? "Something went wrong");
    }
    setLoading(false);
  }, [litmosLogin]);

  return (
    <Flex
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      height={`calc(100vh - ${isPartner ? "64px" : "100px"})`}
    >
      <Stack gap={4} w={"58%"}>
        <Center>
          <Image
            src={BlackHatIcon}
            alt={"black-cap-icon"}
            width={"60px"}
            height={"60px"}
          />
        </Center>

        <Flex direction="column" gap={1}>
          <Text
            alignContent={"left"}
            fontSize={"16px"}
            fontWeight={600}
            color={"#111A29"}
          >
            {isPartner
              ? "Welcome to Your Learning Portal!"
              : `Welcome to Coachbar's LMS module!`}
          </Text>
          <Text fontSize={"14px"} fontWeight={450} color={"#556781"}>
            {isPartner
              ? `With Coachbar's integration with Litmos LMS, you now have access
              to a robust platform designed to enhance your training and
              development. Here’s what you can do within Litmos :`
              : `With our LMS, you'll access a powerful toolkit to enhance your
              Partners' training and development. Here's what's in store :`}
          </Text>
        </Flex>

        <VStack alignItems={"left"}>
          {LMSList.map((item, i) => (
            <Text key={i} fontSize={"14px"} fontWeight={700} color={"#111A29"}>
              {i + 1}. {item.title}:{" "}
              <span
                style={{ fontSize: "14px", fontWeight: 450, color: "#556781" }}
              >
                {item.subTitle}
              </span>
            </Text>
          ))}
        </VStack>

        <Text fontSize={"14px"} fontWeight={450} color={"#556781"}>
          {isPartner
            ? `Integrated into Coachbar, Litmos LMS provides everything you need
            for impactful training, from onboarding to advanced skill
            development. Let’s embark on this learning journey together and
            achieve success!`
            : `Integrated into Coachbar, our LMS provides all you need for
            impactful training and organizational success. Let's embark on this
            learning journey together!`}
        </Text>

        {!isPartner && (
          <Flex alignItems={"center"} gap={1}>
            <Text fontSize={"14px"} fontWeight={600} color={"#C81E1E"}>
              Attention:
            </Text>
            <Text fontSize={"14px"} fontWeight={450} color={"#556781"}>
              Please note that user limits are set to 10 on the PRM side and 15
              on the Partner Portal.
            </Text>
          </Flex>
        )}

        {isLitmosUser && (
          <>
            <Divider my={3} />
            <Box textAlign={"right"}>
              <Button
                title={"Go to LMS"}
                isLoading={loading}
                onClick={goToLMS}
                borderRadius={"8px"}
                id={`${moduleForId}-go-to-lms`}
              />
            </Box>
          </>
        )}
      </Stack>
    </Flex>
  );
};

export default LMSLogin;
