import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";

const CustomRequirementPopover = ({
  popoverTriggerChildren,
  popoverBody,
  popoverHeader,
}) => {
  return (
    <Popover placement="top">
      <PopoverTrigger>{popoverTriggerChildren}</PopoverTrigger>
      <Portal>
        <PopoverContent
          bg={"#162336"}
          opacity={"0.9 !important"}
          color="#0B121B"
          borderColor={"#162336"}
          px={"12px"}
          maxW={"fit-content"}
          minW={"150px"}
        >
          <PopoverHeader
            fontSize={"12px"}
            fontWeight={500}
            color={"white"}
            borderBottom={"2px solid #0B121B"}
            px={0}
          >
            {popoverHeader ? popoverHeader : "Recommendation"}
          </PopoverHeader>
          <PopoverArrow bg={"#162336"} />
          <PopoverCloseButton
            color={"white"}
            _hover={{ bg: "white", color: "#162336" }}
            mt={"2px"}
          />
          <PopoverBody
            fontSize={"12px"}
            color={"white"}
            fontWeight={450}
            px={0}
          >
            {popoverBody}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default CustomRequirementPopover;
