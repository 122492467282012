export const DEAL_STAGE = {
  NEW: "New",
  QUALIFIED_TO_BUY: "Qualified to Buy",
  CLOSED_WON: "Closed: Won",
  CLOSED_LOST: "Closed: Lost",
};

export const stageList = [
  DEAL_STAGE.NEW,
  DEAL_STAGE.QUALIFIED_TO_BUY,
  DEAL_STAGE.CLOSED_WON,
  DEAL_STAGE.CLOSED_LOST,
];
export const pendingStatus = "Pending";
export const acceptedStatus = "Accepted";
export const rejectedStatus = "Rejected";

export const dealImportFolderName = "/import";
export const dealImportFileNameSp = "Deals+Import+Provider.csv";
export const dealImportFileNameSc = "Deals+Import+Partner.csv";

export const dealsColumnOrder = "dealsColumnOrder";
export const dealsVisibleColumns = "dealsVisibleColumns";

export const dealStageColorCodesObj = {
  [DEAL_STAGE.NEW]: {
    status: DEAL_STAGE.NEW,
    background: "var(--chakra-colors-midNight-50)",
    color: "var(--chakra-colors-midNight-700)",
    borderColor: "var(--chakra-colors-midNight-100)",
  },
  [DEAL_STAGE.QUALIFIED_TO_BUY]: {
    status: DEAL_STAGE.QUALIFIED_TO_BUY,
    background: "var(--chakra-colors-sunRise-50)",
    color: "var(--chakra-colors-sunRise-700)",
    borderColor: "var(--chakra-colors-sunRise-100)",
  },
  [DEAL_STAGE.CLOSED_WON]: {
    status: DEAL_STAGE.CLOSED_WON,
    background: "var(--chakra-colors-palmGreen-50)",
    color: "var(--chakra-colors-palmGreen-700)",
    borderColor: "var(--chakra-colors-palmGreen-100)",
  },
  [DEAL_STAGE.CLOSED_LOST]: {
    status: DEAL_STAGE.CLOSED_LOST,
    background: "var(--chakra-colors-cherryRed-50)",
    color: "var(--chakra-colors-cherryRed-700)",
    borderColor: "var(--chakra-colors-cherryRed-100)",
  },
};

export const dealStageColorCodes = [
  dealStageColorCodesObj[DEAL_STAGE.NEW],
  dealStageColorCodesObj[DEAL_STAGE.QUALIFIED_TO_BUY],
  dealStageColorCodesObj[DEAL_STAGE.CLOSED_WON],
  dealStageColorCodesObj[DEAL_STAGE.CLOSED_LOST],
];

export const DEAL_TEMPLATE_PARAMETER = [
  "",
  "[Lead ID]",
  "[Lead Name]",
  "[Lead Email]",
  "[Lead Company]",
  "[Deal ID]",
  "[Deal Source]",
  "[Deal Created Date]",
  "[Deal Stage]",
  "[Deal Amount]",
  "[Deal Owner]",
  "[Partner User First/Last Name]",
  "[Partner Company Name]",
  "[Accept Link]",
  "[Reject Link]",
];
