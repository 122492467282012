// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  registerUser,
  userLogin,
  forgotPassword,
  newPassword,
  verifyUser,
  resendConfirmationCode,
  checkEmailExists,
  getPlanDetail,
  registerUserViaGoogle,
  acceptInvite,
  verifyResetPasswordOtp,
} from "./authActions";
import {
  getUserInfo,
  getUserToken,
  deleteSessionStorage,
} from "../../services/utility";

// initialize userToken from local storage
const userToken = getUserToken();
const userInfo = getUserInfo();

const initialState = {
  loading: false,
  userInfo: userInfo,
  userToken: userToken,
  error: null,
  success: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearState: (state) => {
      state.error = false;
      state.success = false;
      state.loading = false;

      return state;
    },
    logout: (state) => {
      deleteSessionStorage(); // deletes token from storage
      state.loading = false;
      state.userInfo = null;
      state.userToken = null;
      state.error = null;
      state.success = null;
    },
    setToken: (state) => {
      state.userToken = getUserToken();
    },
    setUserData: (state) => {
      state.userInfo = getUserInfo();
    },
    clearMessage: (state) => {
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: {
    // register user
    [registerUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload; // registration successful
      state.loading = false;
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // login user
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = payload.message ? payload.message : payload;
      state.userInfo = getUserInfo();
      state.userToken = getUserToken();
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message ? payload.message : payload;
    },
    // acceptInvite
    [acceptInvite.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [acceptInvite.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = payload.message ? payload.message : payload;
      state.userInfo = getUserInfo();
      state.userToken = getUserToken();
    },
    [acceptInvite.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload.message ? payload.message : payload;
    },
    // forgot password
    [forgotPassword.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [forgotPassword.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.success = payload.message ? payload.message : payload;
    },
    [forgotPassword.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // change password
    [newPassword.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [newPassword.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = payload.message ? payload.message : payload;
    },
    [newPassword.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // verify user
    [verifyUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [verifyUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = getUserInfo();
      state.userToken = getUserToken();
      state.success = payload.message ? payload.message : payload;
    },
    [verifyUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // resend code
    [resendConfirmationCode.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [resendConfirmationCode.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = payload.message ? payload.message : payload;
    },
    [resendConfirmationCode.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // registration email already exists
    [checkEmailExists.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [checkEmailExists.fulfilled]: (state) => {
      state.loading = false;
    },
    [checkEmailExists.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // get plan details
    [getPlanDetail.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPlanDetail.fulfilled]: (state) => {
      state.loading = false;
    },
    [getPlanDetail.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [registerUserViaGoogle.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [registerUserViaGoogle.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = getUserInfo();
      state.userToken = getUserToken();
      state.success = payload.message ? payload.message : payload;
    },
    [registerUserViaGoogle.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    // verify otp for reset password
    [verifyResetPasswordOtp.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [verifyResetPasswordOtp.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = payload.message ? payload.message : payload;
    },
    [verifyResetPasswordOtp.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export const { logout, clearState, setToken, setUserData, clearMessage } =
  authSlice.actions;
export default authSlice.reducer;
