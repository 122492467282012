import { notification } from "@coachbar/shared-components/src/services/utility";
import { axiosInstance } from "../../../services/axiosInstance";
import { getUserInfo, handleError } from "../../../services/utility";

export const getAssetList = async (requestData) => {
  try {
    let { data } = await axiosInstance.post(`/assets/list`, requestData);
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const downloadFolder = async (requestData) => {
  try {
    let { data } = await axiosInstance.post(
      `/assets/folder/download`,
      requestData
    );
    if (data?.code === 200 || 201) notification(data.message);
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const downloadFile = async (requestData) => {
  try {
    let { data } = await axiosInstance.post(`/assets/download`, requestData);
    if (data?.code === 200 || 201) notification(data.message);
    return data;
  } catch (error) {
    handleError(error);
  }
};

export const updateAssetTagList = async (tagList, assetId) => {
  const { tenantId } = getUserInfo();
  try {
    const { data } = await axiosInstance.put(
      "/assets/addTags/" + assetId,
      {
        tags: tagList,
      },
      { params: { tenantId } }
    );
    return data;
  } catch (error) {
    handleError(error);
  }
};
