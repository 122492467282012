import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const baseStyle = defineStyle({
  fontWeight: "500",
  textTransform: "capitalize",
  borderRadius: "4px",
  border: "1px solid",
});

const sm = defineStyle({
  fontSize: "12px",
  px: "15px", // <-- px is short for paddingLeft and paddingRight
  py: "3px", // <-- py is short for paddingTop and paddingBottom
  lineHeight: "24px",
});

const md = defineStyle({
  fontSize: "14px",
  px: "15px", // <-- these values are tokens from the design system
  py: "7px", // <-- these values are tokens from the design system
  lineHeight: "24px",
});

const brandPrimary = defineStyle({
  borderColor: "brand.50",
  color: "var(--chakra-colors-white)",
  bg: "brand.50",
});

const discard = defineStyle({
  borderColor: "brand.10",
  height: "2.5rem",
  lineHeight: "1",
  bg: "brandGray.10",
  color: "brandGray.200",
  border: 0,
  _hover: {
    bg: `brandGray.20`,
  },
});

const danger = defineStyle({
  borderColor: "brand.10",
  color: "brandGray.100",
  height: "2.5rem",
  lineHeight: "1",
  bg: "red.500",
  border: 0,
});

const modal = defineStyle({
  lineHeight: "1",
  fontSize: "14px",
  border: "2px solid",
  borderColor: "brand.100",
  height: "2rem",
  position: "absolute",
  right: "20px",
  top: "14px",
  color: "brand.100",
  _hover: {
    bg: `brandGray.20`,
  },
});

const imgDeleteBtn = defineStyle({
  bg: "brandGray.20",
  position: "relative",
  left: "240px",
  top: "20px",
  borderRadius: "20px",
  color: "white",
  height: "20px",
  width: "20px",
});

const specializationButton = defineStyle({
  border: "2px solid",
  borderColor: "brand.50",
  bg: "#FFFFFF",
  w: "74px",
  h: "30px",
  borderRadius: "40px",
  fontSize: "14px",
  fontWeight: "600",
  color: "brand.50",
  p: "0px",
});

const serviceButton = defineStyle({
  border: "2px solid",
  borderColor: "brand.50",
  w: "74px",
  h: "30px",
  borderRadius: "40px",
  fontSize: "14px",
  fontWeight: "600",
  p: "0px",
  bgColor: "brand.50",
  color: "white",
});

const newThemePrimary = defineStyle({
  border: "2px solid",
  borderColor: "brand.200",
  borderRadius: "4px",
  fontSize: "14px",
  fontWeight: "500",
  bgColor: "brand.200",
  color: "white",
});

const newColorThemePrimary = defineStyle({
  border: "2px solid",
  borderColor: "#0C94AC",
  borderRadius: "4px",
  fontSize: "14px",
  fontWeight: "500",
  bgColor: "#0C94AC",
  color: "white",
});

const spThemeOutline = defineStyle({
  border: "2px solid",
  borderColor: "brand.200",
  borderRadius: "4px",
  fontSize: "14px",
  fontWeight: "500",
  bgColor: "white",
  color: "brand.200",
});

const brandPrimaryOutline = defineStyle({
  borderColor: "brand.50",
  color: "brand.50",
  bg: "var(--chakra-colors-white)",
});

const brandPrimaryOutlineSecond = defineStyle({
  borderColor: "cyprus.100",
  color: "cyprus.500",
  bg: "cyprus.50",
});

const brandPrimaryLinkButton = defineStyle({
  borderColor: "transparent",
  color: "brand.50",
  bg: "transparent",
  textDecor: "none",
});

const greyFill = defineStyle({
  borderColor: "brandGray.600",
  color: "var(--chakra-colors-white)",
  bg: "brandGray.600",
});

const greyOutline = defineStyle({
  borderColor: "brandGray.101",
  color: "brandGray.400",
  bg: "var(--chakra-colors-white)",
});

const greyLinkButton = defineStyle({
  borderColor: "transparent",
  color: "brandGray.400",
  bg: "transparent",
  textDecor: "none",
});

const dangerFill = defineStyle({
  borderColor: "brandDanger.700",
  color: "var(--chakra-colors-white)",
  bg: "brandDanger.700",
});

const dangerOutline = defineStyle({
  borderColor: "brandDanger.700",
  color: "brandDanger.700",
  bg: "var(--chakra-colors-white)",
});
const dangerOutlineSecond = defineStyle({
  borderColor: "cherryRed.100",
  color: "cherryRed.700",
  bg: "cherryRed.50",
});

export const buttonTheme = defineStyleConfig({
  //   variants: { outline },
  baseStyle: baseStyle,
  // Two sizes: sm and md
  sizes: {
    sm: sm,
    md: md,
  },
  // variants list
  variants: {
    discard,
    brandPrimary,
    modal,
    danger,
    imgDeleteBtn,
    specializationButton,
    serviceButton,
    newThemePrimary,
    newColorThemePrimary,
    spThemeOutline,
    brandPrimaryOutline,
    brandPrimaryOutlineSecond,
    brandPrimaryLinkButton,
    greyOutline,
    greyLinkButton,
    greyFill,
    dangerFill,
    dangerOutline,
    dangerOutlineSecond,
  },
  // The default size and variant values
  defaultProps: {
    size: "md",
    variant: "brandPrimary",
  },
});
