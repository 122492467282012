import React, { useEffect, useMemo, useRef, useState } from "react";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Input,
  Skeleton,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import CustomDropdown from "@coachbar/shared-components/src/components/CustomDropDown/CustomDropDown";
import {
  scrollbarCSS,
  tableCss,
} from "@coachbar/shared-components/src/constants/constantValues";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { editCommission, getCommissionDetailList } from "./rewardsActions";
import SendInquiryModal from "./sendInquiryModal";
import { getUserInfo } from "../../services/utility";
import { getCurrency } from "@coachbar/shared-components/src/services/utility";
import ProductCommission from "./productCommissionList";

const statusOptions = [
  { value: "To Approve", label: "To Approve" },
  { value: "Approved", label: "Approved" },
  { value: "On Hold", label: "On Hold" },
  { value: "Reject", label: "Reject" },
];

const OverviewDetailsListing = ({
  fetchInquiryList,
  commissionDetail,
  programOverviewDetails,
  programOverviewDetailsLoader,
  planId,
  fetchCommissionDetails,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const tableContainerRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);
  const [nextToken, setNextToken] = useState(null);
  const [inquiryData, setInquiryData] = useState({});
  const [cellLoading, setCellLoading] = useState("");
  const userInfo = getUserInfo();

  const cellLoadingComponent = (
    <span style={{ position: "relative", display: "inline-block" }}>
      <Spinner
        position={"absolute"}
        top="40%"
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="brand.100"
        size={"xs"}
        ml="4px"
      />
    </span>
  );

  function IndeterminateCheckbox({ indeterminate, className = "", ...rest }) {
    const ref = React.useRef(null);

    useEffect(() => {
      if (typeof indeterminate === "boolean") {
        ref.current.indeterminate = !rest.checked && indeterminate;
      }
    }, [ref, indeterminate]);

    return (
      <input
        type="checkbox"
        ref={ref}
        className={className + " cursor-pointer"}
        {...rest}
      />
    );
  }

  useEffect(() => {
    refetchList();
  }, [planId]);

  const { data, fetchNextPage, isFetching } = useInfiniteQuery(
    ["table-data", refetch, commissionDetail],
    async () => {
      if (commissionDetail) {
        const fetchedData = await getCommissionDetailList({
          limit: 25,
          commissionIds: commissionDetail?.commissionIds,
          tenantId: commissionDetail?.spTenantId ?? "",
          planId: planId ?? "",
          nextToken: nextToken,
        });
        setLoading(false);
        if (fetchedData) {
          setNextToken(fetchedData?.nextToken || null);
          table.toggleAllRowsSelected(false);
          return fetchedData?.commissionDetailList || [];
        }
      }
      return [];
    },
    {
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      staleTime: 0,
      cacheTime: 1000, // set cachtime so when user come back to particular tab it refetch the latest data.
    }
  );

  const flatData = React.useMemo(() => {
    return data?.pages?.flatMap((page) => page) ?? [];
  }, [data]);

  const totalFetched = flatData.length;

  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        //once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        if (
          scrollHeight - scrollTop - clientHeight < 100 &&
          !isFetching &&
          nextToken
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched]
  );

  const defaultColumns = useMemo(() => {
    let cols = [
      {
        id: "expand",
        enableSorting: false,
        // accessorKey: "dealId",
        header: "",
        cell: ({ row }) => {
          const data = row.original;
          return (
            <>
              {data?.productCommission?.length > 0 ? (
                <Box
                  cursor={"pointer"}
                  fontSize={"20px"}
                  color={"#7E8792"}
                  {...{
                    onClick: row.getToggleExpandedHandler(),
                  }}
                >
                  {row.getIsExpanded() ? (
                    <ChevronDownIcon />
                  ) : (
                    <ChevronRightIcon />
                  )}
                </Box>
              ) : (
                ""
              )}
            </>
          );
        },
      },
      // {
      //   id: "select",
      //   enableSorting: false,
      //   header: ({ table }) => (
      //     <IndeterminateCheckbox
      //       {...{
      //         checked: table.getIsAllRowsSelected(),
      //         // disabled: getDisableRow(table),
      //         indeterminate: table.getIsSomeRowsSelected(),
      //         onChange: table.getToggleAllRowsSelectedHandler(),
      //       }}
      //     />
      //   ),
      //   cell: ({ row }) => (
      //     <div
      //       className="px-1"
      //       style={{ display: "flex", justifyContent: "flex-end" }}
      //     >
      //       <IndeterminateCheckbox
      //         {...{
      //           checked: row.getIsSelected(),
      //           // disabled:
      //           //   row.original.partnerMap && row.original.status !== "Rejected",
      //           indeterminate: row.getIsSomeSelected(),
      //           onChange: row.getToggleSelectedHandler(),
      //           onClick: (e) => {
      //             e.stopPropagation();
      //           },
      //         }}
      //       />
      //     </div>
      //   ),
      // },
      {
        id: "dealId",
        enableSorting: false,
        accessorKey: "dealId",
        header: "Deal ID",
        cell: ({ row }) => {
          const data = row.original;
          return (
            <Text color={"#111A29"} fontSize={"14px"} whiteSpace={"nowrap"}>
              {data?.dealReadableId || "-"}
            </Text>
          );
        },
      },
      {
        id: "leadId",
        enableSorting: false,
        accessorKey: "leadId",
        header: "Lead ID",
        cell: ({ row }) => {
          const data = row.original;
          return (
            <Text color={"#111A29"} fontSize={"14px"} whiteSpace={"nowrap"}>
              {data?.leadReadableId || "-"}
            </Text>
          );
        },
      },
      {
        id: "leadName",
        enableSorting: false,
        accessorKey: "leadName",
        header: "Lead Name",
        cell: ({ row }) => {
          const data = row.original;
          return (
            <Box>
              <Text color={"#111A29"} fontSize={"14px"} lineHeight={1}>
                {data?.leadName}
              </Text>
              <Text color={"#5E6977"} fontSize={"12px"} lineHeight={1}>
                {data?.leadEmail}
              </Text>
            </Box>
          );
        },
      },
      {
        id: "dealAmount",
        enableSorting: false,
        accessorKey: "dealAmount",
        header: "Deal Amount",
        cell: ({ row }) => {
          const data = row.original;
          return (
            <Text color={"#111A29"} fontSize={"14px"}>
              {data?.scDealAmount !== undefined && data?.scDealAmount !== null
                ? `${getCurrency().symbol}${data?.scDealAmount?.toFixed(2)}`
                : "-"}
            </Text>
          );
        },
      },
      {
        id: "rate",
        enableSorting: false,
        accessorKey: "scRateAmount",
        header: "Rate",
        cell: ({ row }) => {
          const data = row.original;
          const type =
            programOverviewDetails?.outcomeBaseObject?.rates[0]?.rateType ===
            "Fixed Amount";
          return (
            <Text color={"#111A29"} fontSize={"14px"}>
              {data?.productCommission?.length < 1 &&
              data?.scRateAmount !== undefined &&
              data?.scRateAmount !== null
                ? type
                  ? `${getCurrency().symbol}${data?.scRateAmount?.toFixed(2)}`
                  : `${data?.scRateAmount?.toFixed(2)}%`
                : "-"}
            </Text>
          );
        },
      },
      {
        id: "rewardAmount",
        enableSorting: false,
        accessorKey: "rewardAmount",
        header: "Reward",
        cell: ({ row }) => {
          const data = row.original;
          return (
            <Text color={"#2C885C"} fontSize={"14px"}>
              {data?.scRewardAmount !== undefined &&
              data?.scRewardAmount !== null
                ? `${getCurrency().symbol}${data?.scRewardAmount?.toFixed(2)}`
                : "-"}
            </Text>
          );
        },
      },
      {
        id: "transactions",
        enableSorting: false,
        accessorKey: "scTransaction",
        header: "Transactions",
        cell: ({ row }) => {
          const data = row.original;
          return (
            <Text color={"#111A29"} fontSize={"14px"}>
              {data?.scTransaction !== undefined && data?.scTransaction !== null
                ? `${getCurrency().symbol}${data?.scTransaction?.toFixed(2)}`
                : "-"}
            </Text>
          );
        },
      },
      {
        id: "dueAmount",
        enableSorting: false,
        accessorKey: "dueAmount",
        header: "Due",
        cell: ({ row }) => {
          const data = row.original;
          return (
            <Text color={"#C27803"} fontSize={"14px"}>
              {data?.scDueAmount !== undefined && data?.scDueAmount !== null
                ? `${getCurrency().symbol}${data?.scDueAmount?.toFixed(2)}`
                : "-"}
            </Text>
          );
        },
      },
      {
        id: "status",
        enableSorting: false,
        accessorKey: "status",
        header: "Status",
        cell: ({ row }) => {
          const data = row.original;
          return (
            <>
              {data?.productCommission?.length > 0 ? (
                "-"
              ) : (
                <Text color={"#111A29"} fontSize={"14px"}>
                  {data?.status === "To Approve"
                    ? "Pending Approval"
                    : data?.status}
                </Text>
              )}
            </>
          );
        },
      },
      {
        id: "inquiry",
        enableSorting: false,
        // accessorKey: "due",
        header: "",
        cell: ({ row }) => {
          const data = row.original;
          return (
            <Text
              color={"#0C94AC"}
              fontSize={"14px"}
              pl={"24px"}
              fontWeight={700}
              cursor={"pointer"}
              borderLeft={"2px solid #E6E7E9"}
              onClick={() => onClickSendInquiry(row)}
            >
              Send Inquiry
            </Text>
          );
        },
      },
    ];

    let filteredCols = cols;

    let rulesObj = programOverviewDetails?.outcomeBaseObject?.calculationRules;

    if (rulesObj) {
      const hasDealStatus = rulesObj.some((x) =>
        x?.rule?.hasOwnProperty("dealStatus")
      );
      const hasDealType = rulesObj.some((x) =>
        x?.rule?.hasOwnProperty("dealType")
      );
      const hasLeadStatus = rulesObj.some((x) =>
        x?.rule?.hasOwnProperty("leadStatus")
      );
      const hasLeadType = rulesObj.some((x) =>
        x?.rule?.hasOwnProperty("leadType")
      );

      if ((hasDealStatus || hasDealType) && !(hasLeadStatus || hasLeadType)) {
        filteredCols = filteredCols?.filter((x) => x.id !== "leadId");
      }

      if ((hasLeadStatus || hasLeadType) && !(hasDealStatus || hasDealType)) {
        if (
          programOverviewDetails?.outcomeBaseObject?.rates[0]?.rateType ===
          "Fixed Amount"
        ) {
          filteredCols = filteredCols?.filter(
            (x) =>
              x.id !== "dealId" &&
              x.id !== "dealAmount" &&
              x.id !== "transactions"
          );
        } else {
          filteredCols = filteredCols?.filter((x) => x.id !== "dealId");
        }
      }
    }

    if (
      programOverviewDetails?.outcomeBaseObject?.titles[0] === "Lead" &&
      programOverviewDetails?.outcomeBaseObject?.rates[0]?.rateType ===
        "Fixed Amount"
    ) {
      filteredCols = filteredCols?.filter((x) => x.id !== "dealAmount");
    }

    if (
      Object.entries(programOverviewDetails).length > 0 &&
      programOverviewDetails?.outcomeBaseObject?.rates[0]?.isRatePerProduct
    ) {
      filteredCols = filteredCols?.filter(
        (x) => x.id !== "rate" && x.id !== "status"
      );
    }

    return filteredCols;
  }, [cellLoading, programOverviewDetails]);

  let columns = [...defaultColumns];

  const table = useReactTable({
    data: flatData,
    columns,
    getRowCanExpand: () => true,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  let selectedRows = useMemo(() => {
    return table.getSelectedRowModel();
  }, [table.getSelectedRowModel()]);

  const onClickSendInquiry = (row) => {
    setInquiryData(row.original);
    onOpen();
  };

  const refetchList = (startLoading = true) => {
    setNextToken("");
    setLoading(startLoading);
    setRefetch((prevRefetch) => !prevRefetch);
  };

  const updateStatusInPriceOptions = (
    data,
    prodId,
    priceOptionId,
    newStatus
  ) => {
    const updatedProductCommission = data.productCommission.map((product) => {
      if (product.id === prodId) {
        return {
          ...product,
          priceOptions: product.priceOptions.map((option) =>
            option.id === priceOptionId
              ? { ...option, status: newStatus }
              : option
          ),
        };
      }
      return product;
    });

    return {
      ...data,
      productCommission: updatedProductCommission,
    };
  };

  const updateTransactionInPriceOptions = (
    data,
    prodId,
    priceOptionId,
    newTransaction
  ) => {
    const updatedProductCommission = data.productCommission.map((product) => {
      if (product.id === prodId) {
        return {
          ...product,
          priceOptions: product.priceOptions.map((option) =>
            option.id === priceOptionId
              ? { ...option, scTransaction: newTransaction }
              : option
          ),
        };
      }
      return product;
    });

    return {
      ...data,
      productCommission: updatedProductCommission,
    };
  };

  const onSelectChange = async (type, prodId, priceOptionId, row, value) => {
    setCellLoading(type === "product" ? priceOptionId : row.original.id);
    let requestData = {};
    if (type === "product") {
      requestData = updateStatusInPriceOptions(
        row?.original,
        prodId,
        priceOptionId,
        value
      );
    } else {
      requestData = { ...row.original, status: value };
    }
    try {
      const data = await editCommission(row.original.commissionId, requestData);
      if (data) {
        refetchList();
        setCellLoading("");
      } else {
        setCellLoading("");
      }
    } catch (error) {
      setCellLoading("");
    }
  };

  const handleTransactionsOnBlur = async (
    type,
    prodId,
    priceOptionId,
    row,
    value
  ) => {
    setCellLoading(type === "product" ? priceOptionId : row.original.id);
    let requestData = {};
    if (type === "product") {
      requestData = updateTransactionInPriceOptions(
        row?.original,
        prodId,
        priceOptionId,
        value
      );
    } else {
      requestData = { ...row.original, scTransaction: value };
    }
    try {
      const data = await editCommission(row.original.commissionId, requestData);
      if (data) {
        fetchCommissionDetails(
          commissionDetail.id,
          commissionDetail.spTenantId
        );
        refetchList();
        setCellLoading("");
      } else {
        setCellLoading("");
      }
    } catch (error) {
      setCellLoading("");
    }
  };

  return (
    <Box
      onScroll={(e) => fetchMoreOnBottomReached(e.target)}
      ref={tableContainerRef}
      overflow={"auto"}
      h={"365px"}
      css={scrollbarCSS}
    >
      {loading || programOverviewDetailsLoader ? (
        <Table variant="simple" css={tableCss} border={"1px solid #E6E7E9"}>
          <Thead>
            <Tr>
              <Th>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Th>
              <Th>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Th>
              <Th>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Th>
              <Th>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Th>
              <Th>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Th>
              <Th>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td borderColor={"#E6E7E9"}>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Td>
              <Td borderColor={"#E6E7E9"}>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Td>
              <Td borderColor={"#E6E7E9"}>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Td>
              <Td borderColor={"#E6E7E9"}>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Td>
              <Td borderColor={"#E6E7E9"}>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Td>
              <Td borderColor={"#E6E7E9"}>
                <Skeleton h={"16px"} w={"100px"} borderRadius={"6px"} />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      ) : (
        <>
          {table.getRowModel().rows && table.getRowModel().rows.length > 0 ? (
            <Table
              variant="simple"
              css={tableCss}
              border={"1px solid #E6E7E9"}
              style={{
                borderCollapse: "separate !important",
              }}
            >
              <Thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <Tr
                    bg={"#F9FAFB"}
                    key={headerGroup.id}
                    position={"sticky"}
                    top={0}
                    zIndex={12}
                  >
                    {headerGroup.headers.map((header, index) => {
                      return (
                        <Th
                          key={header.id}
                          w={index === 0 || index === 1 ? "10px" : "auto"}
                          pr={index === 0 ? "0" : "16px"}
                        >
                          {header.isPlaceholder ? null : (
                            <Box
                              display={"flex"}
                              justifyContent={
                                header.id === "select"
                                  ? "flex-end"
                                  : "flex-start"
                              }
                            >
                              <Text display={"flex"} alignItems={"center"}>
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                              </Text>
                            </Box>
                          )}
                        </Th>
                      );
                    })}
                  </Tr>
                ))}
              </Thead>
              <Tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <>
                      <Tr
                        key={row.id}
                        position={"relative"}
                        _hover={{ bg: "#F9FAFB" }}
                      >
                        {row.getVisibleCells().map((cell, index) => {
                          return (
                            <Td
                              pr={index === 0 ? "0 !important" : "16px"}
                              key={cell.id}
                              color={"#111A29"}
                              fontSize={"14px"}
                              fontWeight={"400"}
                              p={"12px"}
                              borderColor={"#E6E7E9"}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </Td>
                          );
                        })}
                      </Tr>
                      {row?.original?.productCommission?.length > 0 &&
                        row.getIsExpanded() && (
                          <Tr key={`${row.id}-expanded`} position={"relative"}>
                            <Td
                              key={`${row.id}-expanded-td`}
                              colSpan={row.getVisibleCells().length}
                              style={{
                                padding: "20px 50px",
                                background: "#F9FAFB",
                              }}
                            >
                              <Table
                                css={tableCss}
                                bg={"#fff"}
                                border={"1px solid #E6E7E9"}
                              >
                                <Thead>
                                  <Tr>
                                    <Th>Product</Th>
                                    <Th>Option</Th>
                                    <Th>Total</Th>
                                    <Th>Rate</Th>
                                    <Th>Reward</Th>
                                    <Th>Transactions</Th>
                                    <Th>Due</Th>
                                    <Th>Status</Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {row?.original?.productCommission?.length >
                                  0 ? (
                                    <ProductCommission
                                      row={row}
                                      programOverviewDetails={
                                        programOverviewDetails
                                      }
                                    />
                                  ) : (
                                    <Tr>
                                      <Td colSpan={8} textAlign={"center"}>
                                        No Product Found
                                      </Td>
                                    </Tr>
                                  )}
                                </Tbody>
                              </Table>
                            </Td>
                          </Tr>
                        )}
                    </>
                  );
                })}
              </Tbody>
            </Table>
          ) : (
            <>No Deal Found</>
          )}
        </>
      )}
      <SendInquiryModal
        planId={planId}
        fetchInquiryList={fetchInquiryList}
        requestData={inquiryData}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Box>
  );
};

export default OverviewDetailsListing;
