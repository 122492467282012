// videoSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { addVideos, updateVideos, getPartnerVideoList } from "./videoActions";

const initialState = {
  loading: false,
  error: null,
  success: null,
  videoList: [],
};

const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    addVideosToList: (state, action) => {
      state.videoList = state.videoList.concat(action.payload);
    },
    removeVideosFromList: (state, action) => {
      let locationId = action.payload;
      state.videoList = state.videoList.filter(
        (location) => location.id !== locationId
      );
    },
    updateVideosFromList: (state, action) => {
      let locationId = action.payload.id;
      state.videoList = state.videoList.map((location) => {
        return location.id === locationId
          ? { ...location, ...action.payload }
          : location;
      });
    },
    clearMessage: (state) => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: {
    // add videos
    [addVideos.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addVideos.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
      if (payload.data && payload.success) {
        // state.companyLocation = payload.data.profile;
      }
    },
    [addVideos.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // update videos
    [updateVideos.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateVideos.fulfilled]: (state, { payload }) => {
      state.success = payload.message ? payload.message : payload;
      state.loading = false;
    },
    [updateVideos.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
    // get partner videos
    [getPartnerVideoList.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.videoList = [];
    },
    [getPartnerVideoList.fulfilled]: (state, { payload }) => {
      state.success = null;
      state.loading = false;
      if (payload.data && payload.success) {
        state.videoList = payload.data?.videoList || [];
      }
    },
    [getPartnerVideoList.rejected]: (state, { payload }) => {
      state.loading = false;
      if (payload.status && payload.status >= 400 && payload.error) {
        state.error = payload.error;
      } else {
        state.error = payload;
      }
    },
  },
});

export const {
  addVideosToList,
  removeVideosFromList,
  updateVideosFromList,
  clearMessage,
} = videoSlice.actions;

export default videoSlice.reducer;
