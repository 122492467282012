import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "../../services/axiosInstance";
import { getErrorMessage } from "../../services/utility";

export const addClientInfo = createAsyncThunk(
  "add/clientInfo",
  async (requestData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("/client/add", requestData);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const getAllClientInfo = createAsyncThunk(
  "get/allClientInfo",
  async (clientId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/client?limit=100`);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const updateClientInfo = createAsyncThunk(
  "update/clientInfo",
  async ({ clientId, requestParams }, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(
        `/client/${clientId}`,
        requestParams
      );
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);

export const deleteClientInfo = createAsyncThunk(
  "delete/clientInfo",
  async (clientId, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.delete(`/client/${clientId}`);
      return data;
    } catch (error) {
      // return custom error message from API if any
      return rejectWithValue(getErrorMessage(error));
    }
  }
);
