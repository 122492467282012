import { AbsoluteCenter, Box, Flex, HStack, Text } from "@chakra-ui/react";
import {
  calculatePercentageDifference,
  formatPercentage,
  formatYAxisTicks,
} from "../../services/dashboardUtility/utility";
import { minusChange, plusChange } from "../Icons/SvgsConstants";

const CustomFunnel = ({
  data,
  selectedDate,
  prevDate,
  isCompared,
  daysBetweenDates,
}) => {
  const CustomTooltip = ({ stage, difference }) => {
    return (
      <Box>
        <Text color={"#B7C8E1"} fontSize={"12px"} fontWeight={700} mb={"4px"}>
          {selectedDate}
        </Text>
        <Flex alignItems={"center"} gap={"4px"}>
          <Box bg={stage.color} boxSize={"8px"} rounded={"2px"}></Box>
          <Text color="white" fontSize="12px" fontWeight={500}>
            {stage?.legend}
          </Text>
          <Text color="white" fontSize="14px" fontWeight={600} ml={"10px"}>
            {stage?.value}
          </Text>
          {isCompared && (
            <HStack ml={"10px"} spacing={"2px"}>
              <Box
                boxSize={"16px"}
                css={{
                  path: {
                    stroke: difference >= 0 ? "#37AA73" : "#F98080",
                  },
                }}
              >
                {difference >= 0 ? plusChange : minusChange}
              </Box>
              <Text
                color={difference >= 0 ? "#37AA73" : "#F98080"}
                fontSize="14px"
                fontWeight={600}
              >
                {difference ? `${formatPercentage(difference)}` : "0%"}
              </Text>
            </HStack>
          )}
        </Flex>
        {isCompared && (
          <Box mt={"8px"}>
            <Text
              color={"#B7C8E1"}
              fontSize={"12px"}
              fontWeight={700}
              mb={"4px"}
            >
              {prevDate}
            </Text>
            <Flex alignItems={"center"} gap={"4px"}>
              <Box bg={"#DEE8F8"} boxSize={"8px"} rounded={"2px"}></Box>
              <Text color="white" fontSize="12px" fontWeight={500}>
                {stage?.legend}
              </Text>
              <Text color="white" fontSize="14px" fontWeight={600} ml={"10px"}>
                {stage?.previousValue}
              </Text>
            </Flex>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Flex direction="column" gap="8px" px={"16px"} alignItems="center">
      {data.map((stage) => {
        const percentageDifference = calculatePercentageDifference(
          stage?.previousValue || 0,
          stage?.value || 0
        );

        return (
          // <Tooltip
          //   bg={"#1C2C44"}
          //   rounded={"4px"}
          //   p={"8px 12px"}
          //   label={
          //     <CustomTooltip stage={stage} difference={percentageDifference} />
          //   }
          //   placement="top"
          // >
          <Box
            key={stage?.id}
            w={stage.width}
            h="60px"
            cursor={"default"}
            position="relative"
            _before={{
              content: '""',
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "100%",
              bg: stage.color,
              clipPath: "polygon(0 0, 100% 0, 95% 100%, 5% 100%)",
            }}
          >
            <AbsoluteCenter>
              <Box>
                <Text
                  color="#111A29"
                  fontSize="14px"
                  fontWeight={600}
                  textAlign={"center"}
                >
                  {`${stage.label}: ${stage.symbol || ""}${
                    typeof stage.value === "number"
                      ? formatYAxisTicks(stage.value || 0)
                      : stage.value
                  }`}
                </Text>
                {isCompared && (
                  <Flex
                    w={"max-content"}
                    gap={"2px"}
                    mt={"2px"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Box
                      boxSize={"16px"}
                      css={{
                        path: {
                          stroke:
                            percentageDifference >= 0 ? "#37AA73" : "#C81E1E",
                        },
                      }}
                    >
                      {percentageDifference >= 0 ? plusChange : minusChange}
                    </Box>
                    <Text
                      color={percentageDifference >= 0 ? "#37AA73" : "#C81E1E"}
                      fontSize="14px"
                      fontWeight={600}
                    >
                      {percentageDifference
                        ? `${formatPercentage(percentageDifference)}`
                        : "0%"}
                    </Text>
                    {daysBetweenDates && (
                      <Text
                        ml={"4px"}
                        //   textAlign={"end"}
                        color={"#7E8792"}
                        fontSize={"12px"}
                        fontWeight={500}
                      >
                        vs prev {daysBetweenDates} days
                      </Text>
                    )}
                  </Flex>
                )}
              </Box>
            </AbsoluteCenter>
          </Box>
          // </Tooltip>
        );
      })}
    </Flex>
  );
};

export default CustomFunnel;
