import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import {
  dateConstants,
  menuItemStyles,
} from "@coachbar/shared-components/src/constants/constantValues";
import { format, isSameDay, parse, subDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

const DashboardDateFilter = ({ removeDateOption, moduleForId, ...props }) => {
  const [customRange, setSCustomRange] = useState({
    to: null,
    from: null,
  });
  const [openDatePicker, setOpenDatePicker] = useState(false);

  const [dateType, setDateType] = useState(() => {
    if (props.selectedDateRange?.to && props.selectedDateRange?.from) {
      const to = parse(props.selectedDateRange?.to, "yyyy-MM-dd", new Date());
      const from = parse(
        props.selectedDateRange?.from,
        "yyyy-MM-dd",
        new Date()
      );
      let currentType = dateConstants.find(
        (x) =>
          isSameDay(x.dateObj.startDate, from) &&
          isSameDay(x.dateObj.endDate, to)
      );
      let customDate = `${props.selectedDateRange?.from} To:
        ${props.selectedDateRange?.to}`;
      return currentType ? currentType.label : customDate;
    }
    if (
      props.selectedDateRange?.to === "" &&
      props.selectedDateRange?.from === ""
    )
      return "All Time";

    return "Select Date";
  });

  const {
    isOpen: isMenuOpen,
    onOpen: onMenuOpen,
    onClose: onMenuClose,
  } = useDisclosure();

  const applyDateChange = () => {
    let customDate = `${format(customRange.from, "MM/dd/yyyy")} To: 
      ${format(customRange.to, "MM/dd/yyyy")}`;
    setDateType(customDate);
    setOpenDatePicker(false);
    props.handleDateChange({
      to: format(customRange.to, "yyyy-MM-dd"),
      from: format(customRange.from, "yyyy-MM-dd"),
    });
  };

  const closePicker = () => {
    setSCustomRange({
      to: null,
      from: null,
    });
    setOpenDatePicker(false);
  };

  useEffect(() => {
    if (props.selectedDateRange?.to && props.selectedDateRange?.from) {
      const to = parse(props.selectedDateRange?.to, "yyyy-MM-dd", new Date());
      const from = parse(
        props.selectedDateRange?.from,
        "yyyy-MM-dd",
        new Date()
      );
      let currentType = dateConstants.find((x) => {
        return (
          isSameDay(x.dateObj.startDate, from) &&
          isSameDay(x.dateObj.endDate, to)
        );
      });
      let customDate = `${props.selectedDateRange?.from} To:
        ${props.selectedDateRange?.to}`;
      setDateType(currentType ? currentType.label : customDate);
    } else if (
      props.selectedDateRange?.to === "" &&
      props.selectedDateRange?.from === ""
    )
      setDateType("All Time");
    else setDateType("Select Date");
  }, [props.selectedDateRange]);

  const handleLastDaysClick = (dateObj, type) => {
    const to = dateObj.endDate;
    const from = dateObj.startDate;

    setDateType(type);
    if (type === "All Time") {
      props.handleDateChange({
        to: "",
        from: "",
      });
    } else {
      props.handleDateChange({
        to: format(to, "yyyy-MM-dd"),
        from: format(from, "yyyy-MM-dd"),
      });
    }
  };

  const handleCustomClick = () => {
    setOpenDatePicker(true);
  };

  const handleDayClick = (day) => {
    setSCustomRange((prevRange) => {
      if (!prevRange.from || (prevRange.from && prevRange.to)) {
        // If there is no selected range or both dates are selected, start a new range
        return { from: day, to: null };
      } else if (day < prevRange.from) {
        // If the selected day is before the existing 'from' date, update 'from'
        return { from: day, to: prevRange.to };
      } else {
        // If the selected day is after the existing 'from' date, update 'to'
        return { from: prevRange.from, to: day };
      }
    });
  };

  const handleMenuButtonClick = () => {
    setOpenDatePicker(false);
  };

  const { from, to } = customRange;
  const modifiers = { start: from, end: to };
  return (
    <Box className="date-filter" pos={"relative"}>
      <Menu isOpen={isMenuOpen} onClose={onMenuClose} onOpen={onMenuOpen}>
        <MenuButton
          border={props.customBorder ? props.customBorder : "1px solid #E6E7E9"}
          borderRadius={props.rounded ? props.rounded : "4px"}
          bg={props.bg ? props.bg : "inherit"}
          color={props.color ? props.color : "#7E8792"}
          fontWeight={500}
          fontSize={"14px"}
          p={"9px 12px"}
          lineHeight={1}
          h={"auto"}
          as={Button}
          rightIcon={
            isMenuOpen ? (
              <ChevronUpIcon
                color="var(--chakra-colors-brandGray-300)"
                height={"16px"}
                width="16px"
              />
            ) : (
              <ChevronDownIcon
                color="var(--chakra-colors-brandGray-300)"
                height={"16px"}
                width="16px"
              />
            )
          }
          leftIcon={
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 6.66658H2M10.6667 1.33325V3.99992M5.33333 1.33325V3.99992M5.2 14.6666H10.8C11.9201 14.6666 12.4802 14.6666 12.908 14.4486C13.2843 14.2569 13.5903 13.9509 13.782 13.5746C14 13.1467 14 12.5867 14 11.4666V5.86659C14 4.74648 14 4.18643 13.782 3.7586C13.5903 3.38228 13.2843 3.07632 12.908 2.88457C12.4802 2.66659 11.9201 2.66659 10.8 2.66659H5.2C4.0799 2.66659 3.51984 2.66659 3.09202 2.88457C2.71569 3.07632 2.40973 3.38228 2.21799 3.7586C2 4.18643 2 4.74648 2 5.86658V11.4666C2 12.5867 2 13.1467 2.21799 13.5746C2.40973 13.9509 2.71569 14.2569 3.09202 14.4486C3.51984 14.6666 4.0799 14.6666 5.2 14.6666Z"
                stroke="#7E8792"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          }
          onClick={handleMenuButtonClick}
          sx={{
            _hover: {
              bg: "white",
            },
          }}
        >
          {dateType}
        </MenuButton>
        <MenuList
          zIndex={"popover"}
          border={"1px solid #E6E7E9"}
          p={"4px"}
          minW={"155px"}
          w={"180px"}
        >
          {removeDateOption && (
            <MenuItem
              {...menuItemStyles}
              fontWeight={400}
              onClick={() => {
                props.handleDateChange({});
              }}
            >
              Select Date
            </MenuItem>
          )}
          <MenuItem
            {...menuItemStyles}
            fontWeight={400}
            onClick={() => handleLastDaysClick(0, "All Time")}
            bg={dateType === "All Time" ? "#E7FAFD" : "#FFF"}
          >
            All Time
          </MenuItem>
          {dateConstants.map((date) => {
            return (
              <MenuItem
                {...menuItemStyles}
                fontWeight={400}
                onClick={() => handleLastDaysClick(date.dateObj, date.label)}
                bg={dateType === date.label ? "#E7FAFD" : "#FFF"}
              >
                {date.label}
              </MenuItem>
            );
          })}
          <Divider border={"1px solid #E6E7E9"} my={"2px"} />
          <MenuItem {...menuItemStyles} onClick={handleCustomClick}>
            Custom
          </MenuItem>
        </MenuList>
      </Menu>
      {openDatePicker && (
        <Box
          zIndex={1300}
          position={"absolute"}
          bgColor={"#FFFFFF"}
          top={props.customPopoverTop || "unset"}
          right={props.customPopoverRight || "unset"}
          border={"1px solid"}
          borderColor={"brandGray.20"}
          borderRadius={"12px"}
          className="date-range-selection-popup"
        >
          <DayPicker
            mode={"range"}
            className=""
            selected={customRange}
            numberOfMonths={2}
            modifiers={modifiers}
            defaultMonth={subDays(new Date(), 30)}
            fromDate={subDays(new Date(), 365)}
            toDate={new Date()}
            onDayClick={handleDayClick}
          />
          <HStack justifyContent={"center"} mb={"1rem"}>
            <Button
              isDisabled={from && to ? false : true}
              onClick={applyDateChange}
              variant={"specializationButton"}
              id={`${moduleForId}date-filter-update`}
            >
              Apply
            </Button>
            <Button
              onClick={closePicker}
              variant={"specializationButton"}
              id={`${moduleForId}date-filter-cancel`}
            >
              Cancel
            </Button>
          </HStack>
        </Box>
      )}
    </Box>
  );
};

export default DashboardDateFilter;
