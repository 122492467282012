import React, { useEffect, useState } from "react";
import {
  Avatar,
  AvatarBadge,
  Box,
  Center,
  Circle,
  Flex,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  StackDivider,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { scrollbarCSS } from "../../constants/constantValues";
import { ReactComponent as BellIcon } from "../../images/bell-03.svg";
import { ReactComponent as DealIcon } from "../../images/coins-hand.svg";
import { ReactComponent as LeadIcon } from "../../images/users-check.svg";
import { ReactComponent as PartnerIcon } from "../../images/partners-logo.svg";
import { ReactComponent as LMSLogo } from "../../images/lms-logo.svg";
import { ReactComponent as FolderIcon } from "../../images/folder-icon.svg";
import { ReactComponent as ApplicationIcon } from "../../images/application-and-recruitment-icon.svg";
import { ReactComponent as NotificationNotFoundIcon } from "../../images/notifications-not-found.svg";
import { ReactComponent as HubspotIcon } from "../../images/hubspot.svg";
import { CloseIcon } from "@chakra-ui/icons";
import CellLoadingComponent from "../Loader/CellLoadingComponent";

const iconMap = {
  Deal: DealIcon,
  Lead: LeadIcon,
  Partner: PartnerIcon,
  Provider: PartnerIcon,
  Matchmaking_Request: FolderIcon,
  Application: ApplicationIcon,
  Recruitment: ApplicationIcon,
  Application_And_Recruitment: ApplicationIcon,
  Profile: PartnerIcon,
  lms: LMSLogo,
  Hubspot: HubspotIcon,
};

const NotificationIcon = ({ module }) => {
  const Icon = iconMap[module] || BellIcon;
  return (
    <Icon
      fill="var(--chakra-colors-cyprus-50)"
      stroke={module === "Hubspot" ? "" : "var(--chakra-colors-cyprus-500)"}
    />
  );
};

const NotificationPopover = ({
  count,
  notifications,
  markAsRead,
  refetch,
  nextToken,
}) => {
  const contentRef = React.useRef(null);
  const [allNotifications, setAllNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (isOpen && contentRef.current) {
      contentRef.current.scrollIntoView();
    }
  }, [isOpen]);

  useEffect(() => {
    setLoading(false);
    setAllNotifications(notifications);
    setUnreadCount(count);
  }, [count, notifications]);

  const handleMarkAsRead = () => {
    markAsRead();
    const updatedNotifications = allNotifications.map((n) => ({
      ...n,
      isNotificationRead: true,
    }));
    setAllNotifications(updatedNotifications);
    setUnreadCount(0);
  };

  const handleNotificationClick = (item) => {
    if (!item.isNotificationRead) {
      markAsRead(item.id);
      const index = allNotifications.findIndex((n) => n.id === item.id);
      const updatedNotifications = [...allNotifications];
      updatedNotifications[index] = { ...item, isNotificationRead: true };
      setAllNotifications(updatedNotifications);
      setUnreadCount((prevCount) => prevCount - 1);
    }
  };

  const loadMoreNotifications = () => {
    setLoading(true);
    refetch();
  };

  return (
    <Popover
      isLazy
      offset={[0, 8]}
      onClose={onClose}
      isOpen={isOpen}
      onOpen={onOpen}
    >
      <PopoverTrigger>
        <Box
          as="button"
          pos="relative"
          _focus={{ boxShadow: "none" }}
          outline="none"
          transition="0.6s ease all"
          padding="4px"
          border="1px solid"
          borderRadius="16px"
          bg="transparent"
          borderColor="transparent"
          fill="transparent"
          stroke="brandGray.500"
          _hover={{
            bg: "cyprus.50",
            borderColor: "cyprus.100",
            fill: "cyprus.50",
            stroke: "cyprus.500",
          }}
          onOpen={onOpen}
        >
          <BellIcon />
          {unreadCount > 0 && (
            <Circle
              color="white"
              bg="cherryRed.500"
              fontSize="9px"
              lineHeight="10px"
              fontWeight={700}
              position="absolute"
              top={unreadCount > 99 ? "0px" : "2px"}
              right={unreadCount > 99 ? "-2px" : "2px"}
              size={unreadCount > 99 ? "18px" : "14px"}
              cursor="pointer"
            >
              {unreadCount > 99 ? "99+" : unreadCount}
            </Circle>
          )}
        </Box>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          bg={useColorModeValue("white", "gray.900")}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          minWidth="420px"
        >
          <PopoverHeader
            p="16px 24px"
            justifyContent="space-between"
            display="flex"
            alignItems={"center"}
            borderBottom="1px solid var(--chakra-colors-atlantic-100)"
          >
            <Text fontSize="14px" fontWeight={700}>
              Notifications{" "}
              {unreadCount > 99
                ? "(99+)"
                : unreadCount > 0
                ? `(${unreadCount})`
                : ""}
            </Text>

            <HStack
              gap={"16px"}
              divider={
                <StackDivider border="2px solid var(--chakra-colors-cyprus-100)" />
              }
            >
              {unreadCount > 0 && (
                <Text
                  fontSize="14px"
                  fontWeight={500}
                  color="cyprus.500"
                  cursor="pointer"
                  onClick={handleMarkAsRead}
                >
                  Mark all as read
                </Text>
              )}
              <Box
                onClick={onClose}
                cursor={"pointer"}
                fontSize="12px"
                fontWeight={"bold"}
                p="4px 8px"
                _hover={{
                  borderRadius: "4px",
                  background: "#EDF2F7",
                }}
              >
                <CloseIcon />
              </Box>
            </HStack>
          </PopoverHeader>
          <PopoverBody p="0" maxH={535} overflow="auto" css={scrollbarCSS}>
            <Box ref={contentRef}>
              {allNotifications?.length > 0 ? (
                allNotifications.map((item, i) => (
                  <React.Fragment key={item.id}>
                    <Flex
                      gap="16px"
                      p="20px 24px"
                      justifyContent="flex-start"
                      alignItems="center"
                      borderBottom="1px solid var(--chakra-colors-brandGray-101)"
                      background={!item.isNotificationRead ? "#F3F7FF80" : ""}
                      onClick={() => handleNotificationClick(item)}
                      cursor="pointer"
                    >
                      <Avatar
                        p="8px"
                        icon={<NotificationIcon module={item.module} />}
                        bg={item.module === "Hubspot" ? "#fff" : "cyprus.50"}
                        border={
                          item.module === "Hubspot"
                            ? "1px solid #E6E7E9"
                            : "1px solid #CEEAEE"
                        }
                        flex="none"
                        h="32px"
                        w="32px"
                      >
                        {!item.isNotificationRead && (
                          <AvatarBadge
                            borderColor="white"
                            bg="#0C94AC"
                            boxSize="12px"
                            top="-6px"
                          />
                        )}
                      </Avatar>
                      <Box>
                        <div
                          className="notification-popover-dynamic-value"
                          dangerouslySetInnerHTML={{
                            __html: item.message || "",
                          }}
                        />
                        <Text
                          fontSize="12px"
                          fontWeight={500}
                          color="brandGray.300"
                          whiteSpace="nowrap"
                        >
                          {format(
                            new Date(item.created),
                            "MMM dd yyyy hh:mm:a"
                          )}
                        </Text>
                      </Box>
                    </Flex>
                    {i === allNotifications.length - 1 &&
                      (nextToken ? (
                        <Center>
                          <Box
                            p="3"
                            color="cyprus.500"
                            fontWeight={600}
                            fontSize={"16px"}
                            cursor={"pointer"}
                            onClick={loadMoreNotifications}
                            id="navbar-notification-popover-view-more"
                          >
                            {loading ? (
                              <CellLoadingComponent
                                size={"sm"}
                                color={"brand"}
                              />
                            ) : (
                              "Load more"
                            )}
                          </Box>
                        </Center>
                      ) : (
                        <Flex
                          p={2}
                          gap="14px"
                          alignItems="center"
                          border="none"
                          justifyContent="center"
                        >
                          <Box height="2px" bg="brandGray.101" width="50px" />
                          <Text
                            fontSize="12px"
                            fontWeight={500}
                            color="brandGray.201"
                            whiteSpace="nowrap"
                          >
                            The End
                          </Text>
                          <Box height="2px" bg="brandGray.101" width="50px" />
                        </Flex>
                      ))}
                  </React.Fragment>
                ))
              ) : (
                <Flex
                  h={325}
                  gap={2}
                  direction={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <NotificationNotFoundIcon />

                  <Text fontSize="14px" fontWeight={500} color="#7E8792">
                    There are no notifications.
                  </Text>
                </Flex>
              )}
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default NotificationPopover;
