import { Box, Center } from "@chakra-ui/react";
import { Loader } from "@coachbar/shared-components";
import ChartLegends from "@coachbar/shared-components/src/components/Charts/ChartLegends";
import Line from "@coachbar/shared-components/src/components/Charts/Line";
import { revenueLabelWithCurrencySymbol } from "@coachbar/shared-components/src/constants/dashboard";
import ChartContainer from "@coachbar/shared-components/src/screens/dashboard/ChartContainer";
import ChartGrid from "@coachbar/shared-components/src/screens/dashboard/ChartGrid";
import ChartHeader from "@coachbar/shared-components/src/screens/dashboard/ChartHeader";
import NoDataChart from "@coachbar/shared-components/src/screens/dashboard/NoDataChart";
import { useChartsProvider } from "@coachbar/shared-components/src/screens/dashboard/chartContext";
import {
  checkTrendSumsIs0,
  compareInnerAndGeneralFilters,
  formatPrevTrendData,
  formatTrenDate,
  formatTrendData,
  getAppliedFilters,
  getPreviousDateRange,
  getSpecificInnerFilters,
  removePrevData,
} from "@coachbar/shared-components/src/services/dashboardUtility/utility";
import { deepCopyObject } from "@coachbar/shared-components/src/services/utility";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getRevenueProductTrend } from "../financialActions";

const RevenueProductTrend = ({ chartData }) => {
  const { selectedDate, appliedFilters, fixFilters } = useChartsProvider();

  const [isLoading, setIsLoading] = useState(false);
  const [chartType, setChartType] = useState("");
  const [isCompared, setIsCompared] = useState(false);
  const [trendDataForChart, setTrendDataForChart] = useState([]);
  const [currentTrendData, setCurrentTrendData] = useState({});
  // const [previousTrendData, setPreviousTrendData] = useState({});
  // const [daysBetweenDates, setDaysBetweenDates] = useState(undefined);
  const [innerFilters, setInnerFilters] = useState(
    getSpecificInnerFilters(fixFilters, chartData.filters)
  );
  const [innerDate, setInnerDate] = useState(deepCopyObject({}));

  // const [formattedDateForChart] = useMemo(() => {
  //   let conDate = deepCopyObject(
  //     innerDate?.to && innerDate?.from ? innerDate : selectedDate
  //   );
  //   // Different date format to show in chart tooltip
  //   const formattedDate = formatDateRange(conDate || null, "chart");
  //   return [formattedDate];
  // }, [innerDate, selectedDate]);

  let innerAppliedFilters = useMemo(() => {
    return getAppliedFilters(innerFilters);
  }, [innerFilters]);

  const trendTableHeading = useMemo(() => {
    const maxLengthObject = trendDataForChart.reduce((maxObj, currentObj) => {
      return currentObj.data.length > (maxObj.data?.length || 0)
        ? currentObj
        : maxObj;
    }, {});

    return maxLengthObject.data?.map((data) => formatTrenDate(data.x)) || [];
  }, [trendDataForChart]);

  const formattedTrendDataForLegends = useMemo(
    () =>
      trendDataForChart.map((x) => ({
        legend: x?.id,
        color: x?.color,
        isPrev: x?.isPrev,
      })),
    [trendDataForChart]
  );

  const constructRequestData = ({ date }) => {
    let requestData = compareInnerAndGeneralFilters(
      appliedFilters,
      innerAppliedFilters,
      Object.keys(fixFilters),
      date,
      selectedDate
    );

    return requestData;
  };

  const clearPreviousData = () => {
    setIsCompared(false);
    const copyOfTrendData = [...trendDataForChart];
    setTrendDataForChart(removePrevData(copyOfTrendData));
    // setDaysBetweenDates(null);
  };

  const onToogleCompare = () => {
    if (isCompared) {
      clearPreviousData();
    } else {
      setIsCompared(true);
      let conDate = deepCopyObject(
        innerDate?.to && innerDate?.from ? innerDate : selectedDate
      );
      const previousDateRange = getPreviousDateRange(conDate);
      // const numberOfDays = calculateDaysBetweenDates(conDate);
      // setDaysBetweenDates(numberOfDays);
      const requestData = constructRequestData({ date: previousDateRange });
      fetchData(requestData, trendDataForChart, currentTrendData, true);
    }
  };

  const fetchData = useCallback(
    async (
      requestData,
      trendDataForChart,
      currentTrendData,
      isCompared = false
    ) => {
      setIsLoading(true);
      const data = await getRevenueProductTrend(requestData);
      setIsLoading(false);

      const apiData = data?.data?.dashboard?.revenueTrends;
      if (apiData) {
        let formattedData;

        if (isCompared) {
          formattedData = formatPrevTrendData(currentTrendData, apiData);

          const mergedData = formattedData.concat(trendDataForChart);
          setTrendDataForChart(mergedData);
          // setPreviousTrendData(deepCopyObject(apiData));
        } else {
          formattedData = formatTrendData(apiData);
          setTrendDataForChart(deepCopyObject(formattedData));
          setCurrentTrendData(deepCopyObject(apiData));
        }
      } else {
        setTrendDataForChart([]);
      }
    },
    []
  );

  let intialized = false;
  useEffect(() => {
    if (!intialized) {
      intialized = true;
      clearPreviousData();
      const requestData = constructRequestData({ date: innerDate });
      fetchData(requestData);
    }
  }, [fetchData, innerDate, selectedDate, appliedFilters, innerAppliedFilters]);

  let isDataEmpty = useMemo(() => {
    return checkTrendSumsIs0(trendDataForChart);
  }, [trendDataForChart]);

  return (
    <>
      <ChartContainer>
        <ChartHeader
          title={chartData?.title}
          showAll
          chartData={chartData}
          chartType={chartType}
          onChartTypeChange={() => {
            if (chartType === "") {
              setChartType("table");
            } else {
              setChartType("");
            }
          }}
          onToogleCompare={onToogleCompare}
          isCompared={isCompared}
          filters={innerFilters}
          onSubmitFilters={(newFilters, date) => {
            setInnerFilters(newFilters);
            setInnerDate(date);
          }}
          date={innerDate}
          generalDate={selectedDate}
        />
        {isLoading ? (
          <Center h={"80%"}>
            <Loader />
          </Center>
        ) : isDataEmpty ? (
          <Box h={"380px"}>
            <NoDataChart />
          </Box>
        ) : chartType === "table" ? (
          <ChartGrid
            data={trendDataForChart}
            headings={[["Product", ...trendTableHeading]]}
          />
        ) : (
          <>
            <Box h={"400px"}>
              <Line
                data={trendDataForChart}
                isCompared={isCompared}
                bottomLegend={"Date"}
                leftLegend={revenueLabelWithCurrencySymbol}
                chartFor="RevenueProductTrend"
                // currentTrendData={currentTrendData}
                // previousTrendData={previousTrendData}
                // selectedDate={formattedDateForChart}
                // daysBetweenDates={daysBetweenDates}
              />
            </Box>
            <ChartLegends
              data={
                isCompared
                  ? removePrevData(formattedTrendDataForLegends)
                  : formattedTrendDataForLegends
              }
            />
          </>
        )}
      </ChartContainer>
    </>
  );
};

export default RevenueProductTrend;
