import ReactQuill, { Editor } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./textEditor.css";
import {
  FormLabel,
  FormErrorMessage,
  FormControl,
  Text,
} from "@chakra-ui/react";
import { useMemo } from "react";

const TextEditor = ({
  content,
  handleEditorChange,
  hideLabel,
  innerref,
  validationErrors,
  displayCharacterCount = true,
  rejection = false,
  editorClassName = "",
  errorMsgClassName = "",
  ...props
}) => {
  const validation = validationErrors && validationErrors[innerref.name];
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike", "link"], // toggled buttons
          [{ list: "ordered" }, { list: "bullet" }],
          [
            props.showEmailPlaceHolders
              ? {
                  placeholder: !rejection
                    ? [
                        "",
                        "[inviteeName]",
                        "[inviterName]",
                        "[inviteUrl]",
                        "[inviteeCompanyName]",
                        "[inviterCompanyName]",
                      ]
                    : [
                        "",
                        "[inviteeName]",
                        "[inviterName]",
                        "[inviteeCompanyName]",
                        "[inviterCompanyName]",
                      ],
                }
              : props.insertParameters?.length
              ? { placeholder: props.insertParameters }
              : "",
          ], // my custom dropdown
        ],
        handlers: {
          placeholder: function (value) {
            if (value) {
              const cursorPosition = this.quill.getSelection().index;
              this.quill.insertText(cursorPosition, value);
              this.quill.setSelection(cursorPosition + value.length);
            }
          },
        },
      },
    }),
    []
  );

  return (
    <>
      <FormControl
        name={props.name}
        isInvalid={validation}
        isRequired={props.required}
      >
        {!hideLabel ? (
          <FormLabel
            textTransform={"capitalize"}
            fontWeight={600}
            color={props?.labelColor ? props.labelColor : "#5E6977"}
            as="legend"
            mb={"4px"}
          >
            {props.name || props.placeholder}
          </FormLabel>
        ) : null}

        <ReactQuill
          theme="snow"
          onChange={handleEditorChange}
          placeholder={props.placeholder}
          value={content}
          modules={modules}
          className={editorClassName}
          readOnly={props.isDisabled || false}
        ></ReactQuill>

        {displayCharacterCount && (
          <Text float={"right"} fontSize={"sm"}>
            {props.characterCount}/{props.maxLength}
          </Text>
        )}
        {validation ? (
          <FormErrorMessage className={errorMsgClassName}>
            {validationErrors[innerref.name].message}
          </FormErrorMessage>
        ) : (
          " "
        )}
      </FormControl>
    </>
  );
};

export default TextEditor;
