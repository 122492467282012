import React, { useMemo } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  MenuItem,
  Menu,
  MenuButton,
  Button,
  MenuList,
  Avatar,
  Text,
  Box,
} from "@chakra-ui/react";

const MyAvatar = ({ name }) => (
  <Avatar
    size={"xs"}
    name={name}
    border={"1px solid #CEEAEE"}
    bg={"#E7FAFD"}
    color={"#111A29"}
  />
);

const CustomDropdown = ({
  options,
  withIcon = false,
  withBg = false,
  selectedValue,
  onChange,
  placeholder,
  placeHolderValue,
  maxH,
  textContainerProps = {},
  ...props
}) => {
  let selected = useMemo(() => {
    return options.find((x) => x.value === selectedValue);
  }, [selectedValue]);

  return (
    <Menu>
      <MenuButton
        textAlign={"left"}
        css={{
          span: {
            display: "flex",
            alignItems: "center",
          },
        }}
        disabled={props.isDisable ? props.isDisable : false}
        className="selected-value"
      >
        <Box
          overflow={"hidden"}
          whiteSpace={"nowrap"}
          textOverflow={"ellipsis"}
          maxW={"80px"}
          title={
            selected ? selected.label : placeholder ? placeholder : "Select"
          }
          {...textContainerProps}
        >
          {selected ? selected.label : placeholder ? placeholder : "Select"}
        </Box>
        {!props.isDisable && (
          <ChevronDownIcon color="#7E8792" ml="8px" boxSize={"16px"} />
        )}
      </MenuButton>
      <MenuList
        maxH={maxH || "300px"}
        overflowY={"auto"}
        minW="max-content"
        width={"fit-content"}
        p={0}
        zIndex={"popover"}
      >
        {placeholder ? (
          <MenuItem
            onClick={() => onChange(placeHolderValue ? placeHolderValue : "")}
          >
            {" "}
            {placeholder}{" "}
          </MenuItem>
        ) : (
          ""
        )}
        {options?.map((option) => {
          return (
            <MenuItem
              key={option.id}
              className={selected?.value === option?.value && "active"}
              onClick={() => {
                if (selected?.value !== option.value) {
                  onChange(option.value);
                }
              }}
              fontWeight={"500"}
              css={{
                "&:hover": {
                  backgroundColor: "var(--chakra-colors-cyprus-50)",
                },
                backgroundColor:
                  selected?.value === option?.value
                    ? "var(--chakra-colors-cyprus-50)"
                    : "var(--chakra-colors-white)",
                ...(option?.style ? option.style : {}),
              }}
            >
              {option.label}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};

export default CustomDropdown;
