// install yarn add @nivo/pie (please try to align the version of installed @nivo packages)
import { Box, Flex, HStack, Text } from "@chakra-ui/react";
import { ResponsivePie } from "@nivo/pie";
import { useMemo } from "react";
import { calculateValueDifferences } from "../../services/dashboardUtility/utility";
import { minusChange, plusChange } from "../Icons/SvgsConstants";

// make sure parent container have a defined height when using responsive component, otherwise height will be 0 and no chart will be rendered
const Pie = ({
  data,
  prevData,
  total,
  prevTotal,
  selectedDate,
  daysBetweenDates,
}) => {
  const pieChartData = useMemo(() => data, [data]);
  // const totalCount = useMemo(() => total, [total]);

  const valueDifference = useMemo(() => {
    if (prevData?.length > 0) {
      return calculateValueDifferences(data, prevData);
    }
    return {}; // Return an empty object if prevData is not available
  }, [data, prevData]);

  const CustomArcLinkLabel = ({ datum, style }) => {
    const { path, textAnchor, textPosition, textColor } = style;
    const { id, label, value, color } = datum;

    const [x, y] = JSON.stringify(textPosition)
      .replace(/"/g, "")
      .replace("translate(", "")
      .replace(")", "")
      .split(",");
    if (value > 0)
      return (
        <g opacity="1">
          <path
            d={JSON.stringify(path).replace(/"/g, "")}
            fill="none"
            stroke={"#7284A0"}
            strokeWidth={style.thickness}
            stroke-dasharray="5"
          />
          <text
            transform={JSON.stringify(textPosition).replace(/"/g, "")}
            textAnchor={JSON.stringify(textAnchor).replace(/"/g, "")}
          >
            <tspan
              x={0}
              y={5}
              style={{
                fill: "#5E6977",
                fontSize: "14px",
                fontWeight: "500",
                backgroundColor: "white",
                padding: "2px 4px",
                borderRadius: "4px",
                pointerEvents: "none",
              }}
            >
              {value}
            </tspan>
            {prevData?.length > 0 && (
              <tspan
                x={0}
                y={22}
                style={{
                  fill: valueDifference[id] >= 0 ? "#37AA73" : "#C81E1E",
                  fontSize: "14px",
                  fontWeight: "700",
                  backgroundColor: "white",
                  padding: "2px 4px",
                  borderRadius: "4px",
                  pointerEvents: "none",
                }}
              >
                <tspan
                  style={{
                    marginLeft: "8px",
                    fill: valueDifference[id] >= 0 ? "#37AA73" : "#C81E1E",
                    fontSize: "14px",
                    fontWeight: "700",
                    backgroundColor: "white",
                    padding: "2px 4px",
                    borderRadius: "4px",
                    pointerEvents: "none",
                  }}
                >
                  {valueDifference[id] >= 0 ? `\u{1F869}` : `\u{1F86B}`}{" "}
                </tspan>

                {valueDifference[id]}
              </tspan>
            )}
          </text>
        </g>
      );
  };

  return (
    <ResponsivePie
      data={pieChartData}
      margin={{ top: 30, right: 50, bottom: 50, left: 50 }}
      innerRadius={0.6}
      padAngle={1}
      activeOuterRadiusOffset={4}
      arcLinkLabelComponent={CustomArcLinkLabel}
      arcLinkLabelsTextColor="#5E6977"
      arcLinkLabelsColor={{ from: "color" }}
      arcLinkLabelsThickness={2}
      enableArcLabels={false}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      colors={{
        datum: "data.color",
      }}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: 15,
          translateY: 50,
          itemsSpacing: 12,
          itemWidth: 70,
          itemHeight: 18,
          itemTextColor: "#5E6977",
          itemDirection: "left-to-right",
          itemOpacity: 1,
          symbolSize: 8,
          symbolShape: "square",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
      tooltip={({ datum }) => {
        const previousData = prevData?.find((data) => data.id === datum?.id);
        return (
          <Box padding="8px 12px" rounded="4px" bg="#1C2C44">
            {/* <Text
              color={"#B7C8E1"}
              fontSize={"12px"}
              fontWeight={700}
              mb={"4px"}
            >
              {selectedDate}
            </Text> */}
            <Flex alignItems={"center"} gap={"4px"}>
              <Box bg={datum.color} boxSize={"8px"} rounded={"2px"}></Box>
              <Text color="white" fontSize="12px" fontWeight={500}>
                {datum.id}
              </Text>

              <Text color="white" fontSize="14px" fontWeight={600} ml={"10px"}>
                {datum?.value}
              </Text>
            </Flex>
            {prevData?.length > 0 && (
              <Flex alignItems={"center"} gap={"4px"}>
                <Box bg={"#DEE8F8"} boxSize={"8px"} rounded={"2px"}></Box>
                <Text color="white" fontSize="12px" fontWeight={500}>
                  Previous Period
                </Text>

                <Text
                  color="white"
                  fontSize="14px"
                  fontWeight={600}
                  ml={"10px"}
                >
                  {previousData?.value}
                </Text>
              </Flex>
            )}
            {/* {prevData?.length > 0 && (
                <HStack ml={"10px"} spacing={"2px"}>
                  <Box
                    boxSize={"16px"}
                    css={{
                      path: {
                        stroke:
                          valueDifference[datum.id] >= 0
                            ? "#37AA73"
                            : "#F98080",
                      },
                    }}
                  >
                    {valueDifference[datum.id] >= 0 ? plusChange : minusChange}
                  </Box>
                  <Text
                    color={
                      valueDifference[datum.id] >= 0 ? "#37AA73" : "#F98080"
                    }
                    fontSize="14px"
                    fontWeight={600}
                  >
                    {valueDifference[datum.id]}
                  </Text>
                </HStack>
              )} */}
            {/* {daysBetweenDates && (
              <Text
                mt={"4px"}
                textAlign={"end"}
                color={"#7E8792"}
                fontSize={"12px"}
                fontWeight={500}
              >
                vs prev {daysBetweenDates} days
              </Text>
            )} */}
          </Box>
        );
      }}
      layers={[
        "arcs",
        "arcLabels",
        "arcLinkLabels",
        "legends",
        ({ centerX, centerY, ...props }) => {
          const totalDifference = (total || 0) - (prevTotal || 0);
          return (
            <g>
              <text
                x={prevData?.length > 0 ? centerX - 20 : centerX}
                y={centerY - 10}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                  fontSize: "24px",
                  fontWeight: 700,
                  lineHeight: "36px",
                }}
              >
                {total}
              </text>
              {prevData?.length > 0 && (
                <text
                  x={centerX + 20}
                  y={centerY - 8}
                  textAnchor="middle"
                  dominantBaseline="central"
                  fill={totalDifference >= 0 ? "#37AA73" : "#C81E1E"}
                  style={{
                    fontSize: "16px",
                    fontWeight: 700,
                    lineHeight: "18px",
                  }}
                >
                  {totalDifference >= 0 ? `\u{1F869}` : `\u{1F86B}`}{" "}
                  {totalDifference}
                </text>
              )}
              <text
                x={centerX}
                y={centerY + 18}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "#7E8792",
                  lineHeight: "18px",
                }}
                fill="#7E8792"
              >
                Total
              </text>
            </g>
          );
        },
      ]}
      theme={{
        labels: {
          text: {
            fontSize: "16px",
            fontWeight: 550,
            color: "#5E6977",
          },
        },
        legends: {
          text: {
            fontSize: "12px",
            fontWeight: 550,
            fill: "#5E6977",
          },
        },
      }}
    />
  );
};

export default Pie;
