import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Stack } from "@chakra-ui/react";
import {
  requiredField,
  whiteSpace,
} from "../../../constants/validationMessages";
import { Modal, TextArea, TextBox } from "@coachbar/shared-components";
import { whiteSpacePattern } from "../../../constants/validationPatterns";

const AddNewFolderModal = ({
  isOpen,
  onClose,
  portal,
  data,
  setSelectedData,
  handleAddEditAssetOrFolder,
  addFolderLoading,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const [descriptionLength, setDescriptionLength] = useState(0);

  const submitForm = async (formData) => {
    if (data) {
      handleAddEditAssetOrFolder(
        formData?.folderName,
        data?.id,
        true,
        data?.assetType,
        formData?.description
      );
    } else {
      handleAddEditAssetOrFolder(formData?.folderName);
    }
    reset();
  };

  const discardClick = () => {
    reset();
    clearErrors();
    onClose();
    setSelectedData(null);
    setDescriptionLength(0);
  };

  useEffect(() => {
    if (!isOpen) {
      reset();
      clearErrors();
    }
  }, [clearErrors, isOpen, reset]);

  useEffect(() => {
    if (data) {
      setValue("folderName", data?.name);
      setValue("description", data?.description);
      setDescriptionLength(data?.description?.length || 0);
    }
  }, [data, setValue]);

  const handleDescriptionChange = (event) => {
    setDescriptionLength(event.target.value.length);
  };

  return (
    <>
      <Modal
        title={
          data?.assetType === "Asset"
            ? "Rename File"
            : data
            ? "Rename Folder"
            : "Add Folder"
        }
        isOpen={isOpen}
        onClose={discardClick}
        displayFooter={true}
        allowCloseButton={true}
        loading={addFolderLoading}
        onSaveClick={() => handleSubmit(submitForm)()}
        saveButtonTitle={data ? "Save" : "Create"}
        discardTitle="Cancel"
        size="xl"
        spDesign={portal === "sp" ? true : false}
        isCentered
      >
        <form noValidate>
          <Stack>
            <TextBox
              name={data?.assetType === "Asset" ? "File Name" : "Folder Name"}
              maxLength="256"
              innerref={register("folderName", {
                required: requiredField.replace(
                  "$Field$",
                  data?.assetType === "Asset" ? "File Name" : "Folder Name"
                ),
                pattern: {
                  value: whiteSpacePattern,
                  message: whiteSpace,
                },
              })}
              required
              validation={true}
              validationErrors={errors}
              showPlaceHolder={true}
              placeholder={"Untitled Folder"}
            />
            {data?.assetType === "Asset" && (
              <TextArea
                placeholder={"Description"}
                innerref={register("description", {
                  onChange: (e) => handleDescriptionChange(e),
                })}
                maxLength={500}
                helperText={`${descriptionLength}/500`}
                helperTextPosition={"right"}
              />
            )}
          </Stack>
        </form>
      </Modal>
    </>
  );
};

export default AddNewFolderModal;
