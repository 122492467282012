import { Box, Flex, Text } from "@chakra-ui/react";
import { Table } from "@coachbar/shared-components";
import { useEffect, useState } from "react";
import { minusChange, plusChange } from "../../components/Icons/SvgsConstants";
import {
  findCorrespondingTrendValue,
  formatPercentage,
  formatYAxisTicks,
  gridPercentageCalculation,
  removePrevData,
} from "../../services/dashboardUtility/utility";
import { scrollbarCSS } from "../../constants/constantValues";

const ChartGrid = ({ data: gridData, headings, ...props }) => {
  const [tableBody, setTableBody] = useState([]);
  const [tableHead, setTableHead] = useState([]);

  const getStyledTexts = (text, percentage = null) => {
    if (!text) {
      return (
        <Text
          color={"#111A29"}
          fontSize={"14px"}
          fontWeight={500}
          lineHeight={"20px"}
        >
          0
        </Text>
      );
    }
    return (
      <Text
        color={"#111A29"}
        fontSize={"14px"}
        fontWeight={500}
        lineHeight={"20px"}
        minW={"100px"}
      >
        {typeof text === "number" ? formatYAxisTicks(text) : text}
        {percentage && (
          <span style={{ fontWeight: 400 }}>
            {text !== 0 ? `(${formatPercentage(percentage)})` : ""}
          </span>
        )}
      </Text>
    );
  };

  const getStyledPrevText = (text, difference = 0) => {
    return (
      <Flex alignItems={"center"} gap={"8px"} minW={"100px"}>
        <Text
          color={"#111A29"}
          fontSize={"14px"}
          fontWeight={500}
          lineHeight={"20px"}
        >
          {typeof text === "number" ? formatYAxisTicks(text) : text}
        </Text>
        {difference !== 0 && (
          <Flex alignItems={"center"}>
            <Box
              boxSize={"16px"}
              css={{
                path: {
                  stroke: difference >= 0 ? "#216645" : "#C81E1E",
                },
              }}
            >
              {difference >= 0 ? plusChange : minusChange}
            </Box>
            <Text
              color={difference >= 0 ? "#216645" : "#C81E1E"}
              fontSize="12px"
              fontWeight={600}
            >
              {typeof difference === "number"
                ? formatYAxisTicks(difference)
                : difference}
            </Text>
          </Flex>
        )}
      </Flex>
    );
  };

  useEffect(() => {
    setTableHead(headings);
  }, [headings]);

  useEffect(() => {
    let tBody = [];
    if (
      gridData.length > 0 &&
      typeof gridData[0] === "object" &&
      gridData[0].data
    ) {
      let updatedGridData;
      const isCompared = gridData?.some((grid) => grid?.isPrev);

      if (isCompared) {
        // Remove prev data
        updatedGridData = removePrevData(gridData);
      } else {
        updatedGridData = [...gridData];
      }

      // Dynamic Data format (multiple columns)
      tBody = updatedGridData.map((item) => [
        getStyledTexts(item.id),
        ...item.data.map((dataItem) => {
          if (isCompared) {
            const previousValue = findCorrespondingTrendValue(
              item?.id,
              dataItem?.x,
              gridData
            );
            const difference = dataItem?.y - previousValue;
            return getStyledPrevText(dataItem.y, difference);
          } else {
            return getStyledPrevText(dataItem.y);
          }
        }),
      ]);
    } else {
      // Static 3 column data format (id, value(%), prevValue(%) - optional)
      const dataWithPercentage = gridPercentageCalculation(gridData);
      dataWithPercentage.push({
        id: "Total",
        value: gridData.reduce((acc, curr) => acc + curr.value, 0),
        percentage: 100,
        previousValue: gridData.reduce(
          (acc, curr) => acc + curr.previousValue,
          0
        ),
        prevPercentage: 100,
      });

      for (let index = 0; index < dataWithPercentage?.length; index++) {
        let tableItem = [];
        tableItem.push(getStyledTexts(dataWithPercentage[index]["id"]));
        tableItem.push(
          getStyledTexts(
            dataWithPercentage[index]["value"],
            dataWithPercentage[index]["percentage"]
          )
        );
        if (headings[0]?.length === 3) {
          tableItem.push(
            getStyledTexts(
              dataWithPercentage[index]["previousValue"],
              dataWithPercentage[index]["prevPercentage"]
            )
          );
        }

        tBody.push(tableItem);
      }
    }

    setTableBody([...tBody]);
  }, [gridData, headings]);

  return (
    <Flex
      h={"400px"}
      direction={"column"}
      justifyContent={"flex-start"}
      overflow={"auto"}
      css={scrollbarCSS}
      mt={props?.marginTop || 0}
    >
      <Table
        tHead={tableHead}
        tBody={tableBody}
        headingStyles={{
          backgroundColor: "#EDF2F7",
          color: "#1C2C44 !important",
        }}
        isHovered
        keepBordersRounded
        keepLastColumnLeftAligned
        showTableBorders
      />
    </Flex>
  );
};

export default ChartGrid;
