// install yarn add @nivo/bar (please try to align the version of installed @nivo packages)
import { Box, Flex, Text } from "@chakra-ui/react";
import { ResponsiveBar } from "@nivo/bar";
import { useMemo } from "react";
import {
  calculatePercentageDifference,
  formatYAxisTicks,
} from "../../services/dashboardUtility/utility";
import { getCurrency } from "../../services/utility";
import { Tooltip } from "@chakra-ui/react";

// make sure parent container have a defined height when using responsive component, otherwise height will be 0 and no chart will be rendered.
const Bar = ({
  chartFor,
  keys,
  data,
  isCompared,
  targetTitle = "Target",
  selectedDate,
  prevDate,
  bottomLegend,
  daysBetweenDates,
  marginLeft,
  targetMarks,
}) => {
  const barChartData = useMemo(() => data, [data]);

  const tooltipTextMap = (id, currentValue, previousValue) => {
    switch (chartFor) {
      case "commissionCharts":
        return {
          tooltipText: `${
            id === "Estimated Commission" ? "Estimated Commission" : id
          } `,
          prevTooltipText: `Previous Period ${
            id === "Estimated Commission" ? "Estimated Commission" : id
          } `,
          formattedCurrentValue: `${getCurrency().symbol}${
            formatYAxisTicks(currentValue) || 0
          }`,
          formattedPreviousValue: `${getCurrency().symbol}${
            formatYAxisTicks(previousValue) || 0
          }`,
        };
      case "TopSellingProducts":
      case "RevenueByCustomerType":
        return {
          tooltipText: `${id} Revenue`,
          prevTooltipText: "Previous Period Revenue",
          formattedCurrentValue: `${getCurrency().symbol}${
            formatYAxisTicks(currentValue) || 0
          }`,
          formattedPreviousValue: `${getCurrency().symbol}${
            formatYAxisTicks(previousValue) || 0
          }`,
        };

      case "LeadByStatus":
        return {
          tooltipText: `Leads in ${id} Status`,
          prevTooltipText: "Previous Count",
          formattedCurrentValue: `${formatYAxisTicks(currentValue) || 0}`,
          formattedPreviousValue: `${formatYAxisTicks(previousValue) || 0}`,
        };
      case "DealByStatus":
        return {
          tooltipText: `Deals in ${id} Status`,
          prevTooltipText: "Previous Count",
          formattedCurrentValue: `${formatYAxisTicks(currentValue) || 0}`,
          formattedPreviousValue: `${formatYAxisTicks(previousValue) || 0}`,
        };

      default:
        return {
          tooltipText: id,
          prevTooltipText: "Previous Period Count",
          formattedCurrentValue: `${formatYAxisTicks(currentValue) || 0}`,
          formattedPreviousValue: `${formatYAxisTicks(previousValue) || 0}`,
        };
    }
  };

  const TooltipComponent = ({ datum }) => {
    const difference = calculatePercentageDifference(
      datum?.previousValue || 0,
      datum?.value || 0
    );

    const {
      tooltipText,
      prevTooltipText,
      formattedCurrentValue,
      formattedPreviousValue,
    } = tooltipTextMap(datum?.id, datum?.value, datum?.previousValue);

    return (
      <Box padding="8px 12px" rounded="4px" bg="#1C2C44">
        {/* <Text color={"#B7C8E1"} fontSize={"12px"} fontWeight={700} mb={"4px"}>
          {selectedDate}
        </Text> */}
        <Flex alignItems={"center"} gap={"4px"}>
          <Box bg={datum.color} boxSize={"8px"} rounded={"2px"}></Box>
          <Text color="white" fontSize="12px" fontWeight={500}>
            {tooltipText}
          </Text>
          <Text color="white" fontSize="14px" fontWeight={600} ml={"10px"}>
            {formattedCurrentValue}
          </Text>
          {/* {isCompared && (
            <HStack ml={"10px"} spacing={"2px"}>
              <Box
                boxSize={"16px"}
                css={{
                  path: {
                    stroke: difference >= 0 ? "#37AA73" : "#F98080",
                  },
                }}
              >
                {difference >= 0 ? plusChange : minusChange}
              </Box>
              <Text
                color={difference >= 0 ? "#37AA73" : "#F98080"}
                fontSize="14px"
                fontWeight={600}
              >
                {difference ? `${formatPercentage(difference)}` : "0%"}
              </Text>
            </HStack>
          )} */}
        </Flex>
        {isCompared && (
          <Box mt={"8px"}>
            {/* <Text
              color={"#B7C8E1"}
              fontSize={"12px"}
              fontWeight={700}
              mb={"4px"}
            >
              {prevDate}
            </Text> */}
            <Flex alignItems={"center"} gap={"4px"}>
              <Box bg={"#DEE8F8"} boxSize={"8px"} rounded={"2px"}></Box>
              <Text color="white" fontSize="12px" fontWeight={500}>
                {prevTooltipText}
              </Text>
              <Text color="white" fontSize="14px" fontWeight={600} ml={"10px"}>
                {formattedPreviousValue}
              </Text>
            </Flex>
          </Box>
        )}
        {/* {daysBetweenDates && (
        //       <Text
        //         mt={"4px"}
        //         textAlign={"end"}
        //         color={"#7E8792"}
        //         fontSize={"12px"}
        //         fontWeight={500}
        //       >
        //         vs prev {daysBetweenDates} days
        //       </Text>
        //     )} */}
      </Box>
    );
  };

  const CustomTotals = (props) => {
    return props.bars.map((bar) => {
      const value =
        bar?.data?.id === "previousValue"
          ? bar.data.data.previousValue
          : bar.data.data.value;
      const formattedValue = formatYAxisTicks(value);
      return (
        <text
          key={bar.key}
          x={bar.x + bar.width + 30}
          y={bar.y + bar.height / 2 + 4}
          textAnchor="middle"
          dominantBaseline="bottom"
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            fill: "#333",
          }}
        >
          {formattedValue}
        </text>
      );
    });
  };

  const CustomTooltipForLabels = ({ tick }) => (
    <g transform={`translate(${tick.x},${tick.y})`}>
      <Tooltip label={tick.value}>
        <text
          textAnchor="end"
          dy="0.16em"
          dx="-1em"
          style={{ fill: "#7E8792", fontWeight: 500, fontSize: 12 }}
          title={tick.value}
        >
          {tick.value.length > 15
            ? tick.value.substring(0, 15) + "..."
            : tick.value}
        </text>
      </Tooltip>
    </g>
  );

  const TargetLines = (x) => {
    if (targetMarks) {
      let highestValue = -Infinity;
      x.bars.forEach((bar) => {
        // Compare the current value with the highest value found so far
        if (bar.data.value > highestValue) {
          highestValue = bar.data.value;
        }
      });

      return targetMarks?.map((target) => {
        let gXPos = (x.innerWidth * target?.value) / highestValue;
        return (
          <g transform={`translate(${gXPos},0)`}>
            <Tooltip
              bg="#1C2C44"
              // isOpen={true}
              width={"fit-content"}
              label={
                <Box
                  p="8px 8px"
                  maxW="130px"
                  width={"130px"}
                  borderRadius={"4px"}
                  className="target-tooltip"
                >
                  <Text
                    color="#B7C8E1"
                    fontWeight={"700"}
                    fontSize={"10px"}
                    lineHeight={"16px"}
                    mb="5px"
                  >
                    {targetTitle}
                  </Text>
                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <Flex alignItems="center" gap="4px">
                      <Box
                        boxSize="8px"
                        bg={"#EC8CA1"}
                        rounded="2px"
                        boxShadow="0px 1px 2px 0px #1018281A"
                      />
                      <Text
                        color="#fff"
                        fontSize="10px"
                        fontWeight={500}
                        cursor="default"
                      >
                        {target?.legend}
                      </Text>
                    </Flex>
                    <Text fontSize={"12px"} fontWeight={"600"} color={"#fff"}>
                      {target?.legend !== "Quota target"
                        ? getCurrency().symbol
                        : ""}
                      {target.value}
                    </Text>
                  </Flex>
                </Box>
              }
              placement="auto"
            >
              <line
                x1="0"
                x2="0"
                y1="0"
                y2={x?.innerHeight}
                stroke="#EC8CA1"
                stroke-width="3"
                cursor={"pointer"}
                strokeDasharray={5}
                className="target-line"
              ></line>
            </Tooltip>
          </g>
        );
      });
    }
    return "";
  };

  return (
    <ResponsiveBar
      data={barChartData}
      keys={keys}
      margin={{ top: 0, right: 60, bottom: 45, left: marginLeft || 80 }}
      padding={barChartData?.length <= 4 ? 0.4 : 0.3}
      innerPadding={isCompared ? 2 : 3}
      layout="horizontal"
      groupMode="grouped"
      enableLabel={false}
      colors={(e) => (e?.id === "previousValue" ? "#DEE8F8" : e?.data?.color)}
      enableGridX
      borderRadius={2}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 4,
        tickRotation: 0,
        truncateTickAt: 0,
        legend: `${bottomLegend || "Count"} \u{1F862}`,
        legendPosition: "middle",
        legendOffset: 40,
        format: (e) => formatYAxisTicks(e),
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 13,
        tickRotation: 0,
        truncateTickAt: 0,
        format: (value) => value,
        renderTick: (tick) => <CustomTooltipForLabels tick={tick} />,
      }}
      tooltip={(e) => {
        const datum = e?.data;
        return <TooltipComponent datum={datum} />;
      }}
      layers={[
        "grid",
        "axes",
        "bars",
        "markers",
        "legends",
        "annotations",
        CustomTotals,
        TargetLines,
      ]}
      theme={{
        axis: {
          ticks: {
            text: {
              fontSize: "12px",
              fontWeight: 500,
              fill: "#7E8792",
            },
          },
          domain: {
            line: {
              stroke: "#E6E7E9",
              strokeWidth: "1px",
            },
          },
          legend: {
            text: {
              fontSize: "12px",
              fontWeight: 600,
              fill: "#5E6977",
            },
          },
        },
        grid: {
          line: {
            stroke: "#F3F4F6",
            strokeWidth: "1.4px",
            strokeDasharray: "3, 3",
          },
        },
        markers: {
          fontSize: "10px",
        },
      }}
      markers={targetMarks || []}
      barAriaLabel={(e) => e.id + ": " + e.formattedValue + e.indexValue}
    />
  );
};

export default Bar;
