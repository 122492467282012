import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import companyProfileReducer from "../features/profileSetup/companyProfile/companyProfileSlice";
import companyLocationReducer from "../features/profileSetup/companyLocation/companyLocationSlice";
import urlDirectoryReducer from "../features/profileSetup/urlDirectory/urlDirectorySlice";
import videoReducer from "../features/profileSetup/videos/videoSlice";
import mediaReducer from "../features/profileSetup/media/mediaSlice";
import clientInfoReducer from "../features/clientsInformation/clientsInformationSlice";
import serviceOfferingReducer from "../features/serviceOffering/serviceOfferingSlice";
import industryReducer from "../features/serviceOffering/IndustrySlice";
import softwareSpecialisationReducer from "../features/softwareSpecialisation/softwareSpecialisationSlice";
import referralReducer from "../features/referrals/referralSlice";
import coachbarServicesReducer from "../features/coachbarServices/coachbarServicesSlice";
import transactionHistoryReducer from "../features/transactionHistory/transactionHistoryslice";
import integrationReducer from "../features/integration/integrationSlice";
import hubSpotReducer from "../features/integration/hubSpot/hubSpotSlice";
import roadmapReducer from "../features/roadmap/roadmapSlice";
import icpReducer from "../features/icpSetup/icpSlice";
import profileMetricsReducer from "../features/profileSetup/profileMetrics/profileMetricsSlice";
import invitationReducer from "../features/myProviders/Invitations/invitationSlice";
import bankReducer from "../features/softwareSpecialisation/bank/bankSlice";
import CustomFormReducer from "../features/generalSettings/customForms/CustomFormSlice";
import generalNotificationReducer from "../features/generalNotifications/generalNotificationSlice";
import personalNotificationReducer from "../features/personalNotifications/personalNotificationSlice";

const combinedReducer = combineReducers({
  auth: authReducer,
  companyProfile: companyProfileReducer,
  companyLocation: companyLocationReducer,
  urlDirectory: urlDirectoryReducer,
  video: videoReducer,
  media: mediaReducer,
  clientInfo: clientInfoReducer,
  serviceOffering: serviceOfferingReducer,
  industry: industryReducer,
  softwareSpecialisation: softwareSpecialisationReducer,
  referral: referralReducer,
  coachbarServices: coachbarServicesReducer,
  transactionHistory: transactionHistoryReducer,
  integration: integrationReducer,
  hubSpot: hubSpotReducer,
  roadmap: roadmapReducer,
  icp: icpReducer,
  profileMetrics: profileMetricsReducer,
  invitations: invitationReducer,
  bank: bankReducer,
  customForm: CustomFormReducer,
  generalNotifications: generalNotificationReducer,
  personalNotifications: personalNotificationReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "auth/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});
