import { Image, Tooltip } from "@chakra-ui/react";
import React from "react";
import InfoIcon from "../../images/info-circle.svg";

const CustomTooltip = (props) => {
  return (
    <Tooltip
      mb="1px"
      padding={props?.padding || "12px"}
      placement={props?.placement || "top"}
      width={props?.width || "200px"}
      label={props.content}
      hasArrow
      openDelay={props?.openDelay || ""}
      borderRadius={"4px"}
      textAlign={props?.textAlign || "left"}
      isDisabled={props?.isDisabled || false}
      closeOnScroll={true}
      {...props}
    >
      <span>
        {props.children ? (
          props.children
        ) : (
          <Image
            ml="0px"
            display={"inline-block"}
            cursor={"pointer"}
            src={InfoIcon}
            maxW={"18px"}
            verticalAlign={props.verticalAlign ? props.verticalAlign : "bottom"}
          />
        )}
      </span>
    </Tooltip>
  );
};

export default CustomTooltip;
